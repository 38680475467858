import {
  TRatioPayload,
  TTurnOffReportVectors,
  TTurnReportVectors,
  TUpdateReportClients,
  TUpdateReportPlanPercent,
} from "src/pages/Stats/models";
import { BASE_URL } from "./constants";
import api from "./http";

export default class statsApi {
  // static async getBoost() {
  //   return api.get(`${BASE_URL}/boost`);
  // }
  // static async updateBoost(data) {
  //   return api.post(`${BASE_URL}/boost`, data);
  // }
  // static async deleteBoost(data) {
  //   return api.post(`${BASE_URL}/boost/delete`, data);
  // }
  static async getRatios() {
    return api.get(`${BASE_URL}/best-analytics/ratio/`);
  }
  static async createRatio(data: TRatioPayload) {
    return api.post(`${BASE_URL}/best-analytics/ratio/`, data);
  }
  static async deleteRatio(id: number) {
    return api.delete(`${BASE_URL}/best-analytics/ratio/${id}`);
  }
  // REPORTS
  static async getTurnedVectors() {
    return api.get(`${BASE_URL}/best-analytics/reports/turn_on_vectors/`);
  }
  static async turnVectors(payload: TTurnReportVectors) {
    return api.post(`${BASE_URL}/best-analytics/reports/turn_on_vectors/`, payload);
  }
  static async turnOffVectors(payload: TTurnOffReportVectors[]) {
    return api.patch(`${BASE_URL}/best-analytics/reports/turn_off_vectors/`, payload);
  }
  static async setClients(payload: TUpdateReportClients) {
    return api.patch(`${BASE_URL}/best-analytics/reports/set_clients/`, payload);
  }
  static async setPlanPercents(payload: TUpdateReportPlanPercent) {
    return api.patch(`${BASE_URL}/best-analytics/reports/set_plan_percents/`, payload);
  }
  static async setReportRatio(payload: { ratio_id: number }) {
    return api.patch(`${BASE_URL}/best-analytics/reports/set_ratio/`, payload);
  }
}
