import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect, { createFilter } from "react-select";
import { getArrValues, selectFilter } from "src/helpers/formHelpers";
import { LS } from "src/api/constants";
import {
  clearFlightsFilters,
  selectFlightsFilters,
  setFlightsFilters,
  setFullFlightsFilters,
  TFlightFilters,
  TFlights,
} from "src/store/statsReducer";

export const FlightsFilters = ({ items }: { items: TFlights }) => {
  const dispatch = useDispatch();
  const [storageFilters, setStorageFilters] = useState(localStorage.getItem(LS.FLIGHTS_FILTERS));
  const parsedFilters = useMemo(
    () =>
      storageFilters?.length
        ? (JSON.parse(storageFilters) as TFlightFilters)
        : {
            city: null,
            site: null,
            from: null,
            to: null,
          },
    [storageFilters]
  );
  const flightsFilters = useSelector(selectFlightsFilters);

  const citiesOptions = useMemo(
    () =>
      [...new Set(items?.data?.map((el) => el.city_name))]?.map((el: string) => ({
        label: el,
        value: el,
      })),
    [items?.data]
  );
  const sitesOptions = useMemo(
    () =>
      [...new Set(items?.data?.map((el) => el.site_name))]?.map((el: string) => ({
        label: el,
        value: el,
      })),
    [items?.data]
  );
  const fromOptions = useMemo(
    () =>
      [...new Set(items?.data?.map((el) => el.currency_from))]?.map((el: string) => ({
        label: el,
        value: el,
      })),
    [items?.data]
  );
  const toOptions = useMemo(
    () =>
      [...new Set(items?.data?.map((el) => el.currency_to))]?.map((el: string) => ({
        label: el,
        value: el,
      })),
    [items?.data]
  );

  const disabledFilters = !Object.keys(parsedFilters)
    ?.map(
      (el) =>
        JSON.stringify(getArrValues(parsedFilters, el)) ===
        JSON.stringify(getArrValues(flightsFilters, el))
    )
    ?.includes(false);

  const updateFilters = () => {
    //const filters = Object.keys(flightsFilters)?.map(el => JSON.stringify({[el]: flightsFilters[el] !== null ? flightsFilters[el].map(el2 => el2.value)?.join(",") : null})).join(";")
    const filters = JSON.stringify(flightFilter);
    setStorageFilters(filters);
    localStorage.setItem(LS.FLIGHTS_FILTERS, filters);
  };

  useEffect(() => {
    dispatch(setFullFlightsFilters(parsedFilters));
  }, []);

  return (
    <div className="flex flex-col gap-8 items-center text-xs w-[280px]">
      <div className="text-lightFont text-center whitespace-pre">{`Исключите уведомления по фильтрам.\nФильтры применяются для текущей сессии,\nсохраните их для использования\nв последующих сессиях`}</div>
      <ReactSelect
        className="w-full"
        value={flightsFilters.city}
        placeholder="город"
        options={citiesOptions}
        filterOption={createFilter(selectFilter)}
        onChange={(e) => dispatch(setFlightsFilters({ attr: "city", value: e }))}
        isMulti
      />
      <ReactSelect
        className="w-full"
        value={flightsFilters.site}
        placeholder="сайт"
        options={sitesOptions}
        filterOption={createFilter(selectFilter)}
        onChange={(e) => dispatch(setFlightsFilters({ attr: "site", value: e }))}
        isMulti
      />
      <ReactSelect
        className="w-full"
        value={flightsFilters.from}
        placeholder="from"
        options={fromOptions}
        filterOption={createFilter(selectFilter)}
        onChange={(e) => dispatch(setFlightsFilters({ attr: "from", value: e }))}
        isMulti
      />
      <ReactSelect
        className="w-full"
        value={flightsFilters.to}
        placeholder="to"
        options={toOptions}
        filterOption={createFilter(selectFilter)}
        onChange={(e) => dispatch(setFlightsFilters({ attr: "to", value: e }))}
        isMulti
      />
      <button
        className="text-lightGray border-b border-lightGray px-[28px] duration-300 hover:text-lightFont hover:border-lightFont"
        onClick={() => dispatch(clearFlightsFilters())}>
        Сбросить фильтры
      </button>
      <button
        className="prime-button-sm bg-font"
        disabled={disabledFilters}
        onClick={updateFilters}>
        Сохранить изменения
      </button>
    </div>
  );
};

export const flightFilter = (item, flightsFilters) => {
  return (
    !getArrValues(flightsFilters, "city")?.includes(item?.city_name) &&
    !getArrValues(flightsFilters, "site")?.includes(item?.site_name) &&
    !getArrValues(flightsFilters, "from")?.includes(item?.currency_from) &&
    !getArrValues(flightsFilters, "to")?.includes(item?.currency_to)
  );
};
