export const AddButton = ({ onClick, title }) => {
  return (
    <div
      onClick={onClick}
      className={`grid grid-cols-[1fr] md:grid-cols-[30px_1fr] md:gap-[20px] md:w-full w-fit mx-auto items-center rounded-lg duration-700 shadow-smooth hover:shadow-inset py-[5px] px-[17px] text-left text-xs cursor-pointer text-[#282c3477] opacity-80`}>
      <div
        className={`hidden md:flex w-[24px] h-[24px] items-center justify-center rounded-full bg-bg shadow-inset hover:shadow-none border border-[#DDD] text-xs font-black`}>
        +
      </div>
      <div className="font-bold ">{title}</div>
    </div>
  );
};
