import { ICity, OnOffSignal, OnOffSignalToDelete } from "src/types/OnOffTimeline/common";
import api from "./http";
import qs from "query-string";
import { OnOffSite } from "src/types/OnOffTimeline/store";
import { TCurrencyTypes } from "src/pages/Text/types";
import { DB_URL } from "src/api/constants";

export interface GetScheduleQuery {
  site_names: string;
  city_codes?: string;
  currency_from_codes?: string;
  currency_to_codes?: string;
}

export interface DeleteScheduleItem {
  city_code?: string;
  site_name?: string;
  currency_from_name?: string;
  currency_to_name?: string;
  currency_from_type?: TCurrencyTypes;
  currency_to_type?: TCurrencyTypes;
}

export interface PostScheduleBody {
  to_change: OnOffSignal[];
  to_delete: OnOffSignalToDelete[];
}

export interface PostScheduleResponse {
  is_changed: {
    updated: OnOffSignal[];
    skipped: OnOffSignal[];
  };
  is_deleted: {
    updated: OnOffSignalToDelete[];
    skipped: OnOffSignalToDelete[];
  };
}

export default class scheduleApi {
  static async getSchedule(query: GetScheduleQuery) {
    const url = qs.stringifyUrl(
      {
        url: `${DB_URL}/schedules/`,
        query: {
          city_codes: query.city_codes,
          site_names: query.site_names,
          currency_from_names: query.currency_from_codes,
          currency_to_names: query.currency_to_codes,
        },
      },
      { skipEmptyString: true, skipNull: true }
    );

    return api.get<ICity[]>(url);
  }

  static async postSchedule(body: PostScheduleBody) {
    return api.post<PostScheduleResponse>(`${DB_URL}/schedules/`, body);
  }

  static async deleteSchedule(data: DeleteScheduleItem[]) {
    return api.delete<ICity[]>(`${DB_URL}/schedules/`, {
      data,
    });
  }

  static async getSites() {
    return api.get<OnOffSite[]>(`${DB_URL}/sites/`);
  }
}
