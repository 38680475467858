import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { LS } from "src/api/constants";
import profApi from "src/api/prof";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import Table from "./table";
import update from "src/assets/images/update.svg";
import { fetchRequest } from "src/helpers/fetchRequest";
import { ProfItem } from "src/types/prof";
import { getChangedArrayItems } from "src/helpers/utils";
import { ErrorMessage } from "src/shadcn/ui/ui/error-message";

export type InputChangeHandler = (props: {
  city_name: string;
  currency_code_from: string;
  currency_code_to: string;
  value: string;
  field: "prof_form_origin_rate" | "prof_form_comment";
}) => void;

export const ProfPage = () => {
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const [data, setData] = useState<ProfItem[]>([]);
  const [initialData, setInitialData] = useState<ProfItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGood, setIsGood] = useState<boolean>(true);
  const [notif, setNotif] = useState("");

  const fetchData = async () => {
    setIsLoading(true);

    const { response, error } = await fetchRequest<ProfItem[]>(profApi.getProfRate());
    if (!response || error) {
      setIsGood(false);
    }
    if (response) {
      setData(response);
      setInitialData(response);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange: InputChangeHandler = ({
    city_name,
    value,
    currency_code_from,
    currency_code_to,
    field,
  }) => {
    setData((prev) =>
      prev.map((city) =>
        city.city_name === city_name
          ? {
              ...city,
              prof_bets: city.prof_bets.map((bet) =>
                bet.currency_code_to === currency_code_to &&
                bet.currency_code_from === currency_code_from
                  ? { ...bet, [field]: value }
                  : bet
              ),
            }
          : city
      )
    );
  };

  const submitHandler = async (e: FieldValues) => {
    e.preventDefault();

    const changedItems: ProfItem[] = [];
    for (let i = 0; i < data.length; i++) {
      const changedBets = getChangedArrayItems(initialData[i].prof_bets, data[i].prof_bets);
      if (changedBets.length > 0) changedItems.push({ ...data[i], prof_bets: changedBets });
    }

    if (changedItems.length === 0) return;

    const { response, error } = await fetchRequest(profApi.postProfRate(changedItems));
    if (!response || error) {
      setNotif("Ошибка при обновлении данных");
    }
    if (response) {
      const failedItems = [];

      // rollback changes that has been rejected by the server
      const newData = data.map((city) => {
        const rejectedCity = response.failed_list.find((item) => item.city_name === city.city_name);

        if (!rejectedCity) return city;

        const initialCity = initialData.find((item) => item.city_name === city.city_name);

        return {
          ...city,
          prof_bets: city.prof_bets.map((bet) => {
            if (
              !rejectedCity.prof_bets.some(
                (item) =>
                  item.currency_code_from === bet.currency_code_from &&
                  item.currency_code_to === bet.currency_code_to
              )
            ) {
              return bet;
            }

            failedItems.push(
              `${bet.currency_code_from} - ${bet.currency_code_to} (${rejectedCity.city_name})`
            );

            return initialCity.prof_bets.find(
              (item) =>
                item.currency_code_from === bet.currency_code_from &&
                item.currency_code_to === bet.currency_code_to
            );
          }),
        };
      });

      setData(newData);

      if (failedItems.length > 0) {
        setNotif("Ошибка обновления данных для следующих пар: \n" + failedItems.join("\n"));
      }
    }

    setTimeout(() => {
      setNotif("");
    }, 10000);
  };

  return (
    <div className="flex flex-col justify-center relative w-[calc(100% - 100px)] mr-[30px] mt-[20px] pb-[100px]">
      <form onSubmit={submitHandler}>
        {!isLoading &&
          data.length > 0 &&
          data?.map((item) => {
            return (
              <Table
                key={item?.city_name}
                item={item}
                handleInputChange={handleInputChange}
                isAccess={userAccess?.includes("bid_post")}
              />
            );
          })}

        {isLoading && <p>Загружаем данные...</p>}
        {userAccess?.includes("bid_post") && !isLoading && !!data?.length && (
          <button className="prime-button mt-[20px]">Отправить</button>
        )}
        {!isLoading && (
          <div className={`fixed top-[60px] lg:top-[96px] left-[10px] lg:left-[26px]`}>
            <button
              onClick={fetchData}
              className={`rounded-full p-2 bg-bg shadow-inner hover:shadow-smooth`}>
              <img src={update} alt="Обновить" width={40} height={40} />
            </button>
          </div>
        )}
        {!!notif && (
          <ErrorMessage className="max-w-[400px] mx-auto mt-[12px]">
            <pre>{notif}</pre>
          </ErrorMessage>
        )}
      </form>
      {!data?.length && !isLoading && (
        <div className="w-full h-full relative top-[80px] mx-auto">
          <Emptiness message={isGood ? "Нет данных" : "Ошибка сервиса"} />
        </div>
      )}
    </div>
  );
};
