import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import addApi from "src/api/add";
import switchApi from "src/api/onOff";
import { selectAdminSites, setAdminSites } from "src/store/commonReducer";
import { selectAllSites, setAllSites, TGlobalSite } from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { SiteRow } from "./SiteRow";
import { Switch } from "src/shadcn/ui/ui/switch";
import { cn } from "src/utils";
import { Button } from "src/shadcn/ui/ui/button";

type TProps = {
  access: boolean;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const badges = [
  "синхронизатор",
  "вылеты",
  "вкл/выкл",
  "show_file",
  "процедуры",
  "new",
  "DB",
  "PE",
  "структура",
  "Ядро",
];

export type BadgesFilters = Record<string, { selected: boolean; enabled: boolean }>;
const badgesFiltersInitial: BadgesFilters = {};
badges.forEach((badge) => {
  badgesFiltersInitial[badge] = {
    selected: false, // выбаран ли тег
    enabled: false, // значение тега для фильтров
  };
});

export const Sites = ({ setMessage, setStatus, setIsLoading, access }: TProps) => {
  const dispatch = useDispatch();
  const [isPopup, setPopup] = useState(false);
  const [isGood, setIsGood] = useState(true);
  const [selectedSite, setSelectedSite] = useState<TGlobalSite>();
  const sites = useSelector(selectAllSites);
  const adminSitesData = useSelector(selectAdminSites);
  const [badgesFilters, setBadgesFilters] = useState(badgesFiltersInitial);

  const fetchGlobals = async () => {
    const { response } = await fetchRequest(switchApi.getGlobals(), {
      request: "Глобальные данные",
    });
    if (response) {
      dispatch(setAllSites(response.sites));
    }
  };

  const fetchAdminSites = async () => {
    const { response } = await fetchRequest(addApi.getSites(), {
      request: "Кредо",
    });
    if (response) {
      dispatch(setAdminSites(response));
    }
  };

  const deleteSite = async () => {
    setIsLoading(true);
    const { response, error } = await fetchRequest(addApi.deleteSite(selectedSite?.site_id), {
      request: "Удаление сайтов",
    });
    if (response) {
      setMessage(`${selectedSite?.site_name} успешно удален`);
      setStatus(true);
      await fetchGlobals();
    }
    if (error) {
      setMessage("Не удалось удалить сайт");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      setPopup(false);
    }, 4600);
  };

  useEffect(() => {
    fetchAdminSites();
    const timer = setTimeout(() => setIsGood(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const toggleBadgeSelected = (badge: string) => {
    const oldValue = badgesFilters[badge];
    setBadgesFilters((prev) => ({
      ...prev,
      [badge]: {
        ...prev[badge],
        selected: !oldValue.selected,
      },
    }));
  };

  const toggleBadgeEnabled = (badge: string) => {
    setBadgesFilters((prev) => ({
      ...prev,
      [badge]: {
        ...prev[badge],
        enabled: !prev[badge].enabled,
      },
    }));
  };

  return (
    <div className="w-full sm:w-[600px] duration-300 flex flex-col gap-[10px] font-light pl-[20px] pb-[100px]">
      <div className="flex flex-col py-[20px] items-center">
        <div className="flex items-center gap-[20px] mb-[10px]">
          <Button
            size="sm"
            variant="outline"
            onClick={() => setBadgesFilters(badgesFiltersInitial)}>
            Сбросить фильтры
          </Button>
        </div>
        <div className={cn("grid grid-cols-2 gap-[5px] w-full py-[10px] sm:px-[50px]")}>
          {Object.entries(badgesFilters).map(([badge, { selected, enabled }]) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleBadgeSelected(badge);
              }}
              key={badge}
              className={cn(
                "cursor-pointer max-w-[180px] px-[10px] py-[2px] rounded-md flex items-center justify-between hover:bg-[#F4F5F7] hover:text-accent-foreground transition",
                selected && "bg-accent hover:bg-accent hover:bg-accent"
              )}>
              <span className="text-xs font-medium">{badge}</span>
              <Switch
                disabled={!selected}
                className={cn(
                  "w-[35px] h-[16px] data-[state=checked]:bg-input",
                  selected && "data-[state=unchecked]:bg-[#D7443E] data-[state=checked]:bg-green"
                )}
                thumbClassName="w-3 h-3"
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    e.stopPropagation();
                    toggleBadgeEnabled(badge);
                  }
                }}
                checked={enabled}
              />
            </div>
          ))}
        </div>
      </div>
      {sites?.map((el, index) => (
        <SiteRow
          key={index}
          item={el}
          data={adminSitesData?.find((el2) => el2.site_id === el?.site_id)}
          setIsLoading={setIsLoading}
          access={access}
          setMessage={setMessage}
          setStatus={setStatus}
          fetchAdminSites={fetchAdminSites}
          badgesFilters={badgesFilters}
          onDeleteClick={() => {
            setSelectedSite(el);
            setPopup(true);
          }}
        />
      ))}
      {isPopup && (
        <Popup closeModal={() => setPopup(false)} noPadding>
          <div className="table-header px-[30px] flex justify-center">УДАЛИТЬ ОБМЕННИК</div>
          <div className="px-[30px] py-[20px] text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nобменник `}
              <b>{selectedSite?.site_name}</b> безвозвратно?
            </div>
            <button className="prime-button-sm" onClick={deleteSite}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
      {!isGood && !sites?.length && <Emptiness message={"Ошибка сервиса"} />}
    </div>
  );
};
