import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { LS } from "src/api/constants";
import { SourcesForm } from "src/pages/Panel/OnOff/OnOffTimeline/components/Sources/SourcesForm";
import { NewDataType } from "src/pages/Panel/OnOff/OnOffTimeline/components/forms/ItemForm";
import { SelectedPairs } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/SelectedPairs";
import { ICity, ITimeSegment } from "src/types/OnOffTimeline/common";
import {
  ApplyingChangesMode,
  SubmittingChangesMode,
  TetrisSubmitPopup,
} from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/TetrisSubmitPopup";
import { initExpandedState, setOnOffTimelineData } from "src/store/onOffTimelineReducer/slice";
import {
  selectOnOffIsLoading,
  selectOnOffTimelineData,
} from "src/store/onOffTimelineReducer/selectors";
import { ESource, TSelectedPair } from "src/store/onOffReducer";
import { getCitiesWithGroups } from "src/helpers/onOffTimeline/getCititesWithGroups";
import { postOnOffData } from "src/store/onOffTimelineReducer/thunks/postOnOffData";
import { useAppDispatch } from "src/store/store";
import { convertTetrisData } from "src/helpers/onOffTimeline/convertTetrisData";
import { getDataAfterSubmit } from "src/helpers/onOffTimeline/getDataAfterSubmit";
import { addMissingGreenSegments } from "src/helpers/onOffTimeline/copyPaste";

export interface TetrisSubmitProps {
  applyingMode: ApplyingChangesMode;
  submittingMode: SubmittingChangesMode;
}

export const AddSummary = ({
  selectedPairs,
  sites,
  setSelectedPairs,
}: {
  selectedPairs: TSelectedPair[];
  sites?: string[];
  setSelectedPairs: Dispatch<SetStateAction<TSelectedPair[]>>;
}) => {
  const dispatch = useAppDispatch();
  const username = localStorage.getItem(LS.USERNAME);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [submitData, setSubmitData] = useState<ICity[]>();
  const timelineData = useSelector(selectOnOffTimelineData);

  const [state, setState] = useState<Record<ESource, ITimeSegment[]>>({
    bestchange: [{ start: "00:00", end: "23:59" }],
    site: [{ start: "00:00", end: "23:59" }],
  });

  const onStateChange = (val: Record<ESource, ITimeSegment[]>) => setState(val);

  const isLoading = useSelector(selectOnOffIsLoading);

  const openSubmitPopup = async (data: NewDataType) => {
    const converted = convertTetrisData({
      pairs: selectedPairs,
      ...addMissingGreenSegments(data),
    });

    setSubmitData(converted);
    setSubmitPopup(true);
  };

  const submit = async ({ applyingMode, submittingMode }: TetrisSubmitProps) => {
    const { oldDataUpdated, entitiesToExpand } = getDataAfterSubmit({
      newData: submitData,
      oldData: timelineData,
      applyingMode,
      username,
    });

    if (submittingMode === SubmittingChangesMode.PREVIEW) {
      const citiesWithGroups = getCitiesWithGroups(oldDataUpdated);
      dispatch(setOnOffTimelineData(oldDataUpdated));
      dispatch(initExpandedState({ data: citiesWithGroups, entitiesToExpand }));
    } else {
      await dispatch(postOnOffData({ data: oldDataUpdated, entitiesToExpand }));
    }
  };

  const deleteSelectedPair = (id: string) => {
    setSelectedPairs((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <div className="pt-[6px]">
      <div className="flex flex-col gap-[6px]">
        <SourcesForm
          state={state}
          setState={onStateChange}
          submit={openSubmitPopup}
          disabledBySelected={!selectedPairs?.length}
          isLoading={isLoading}
        />
      </div>
      <SelectedPairs
        selectedPairs={selectedPairs}
        removeItem={deleteSelectedPair}
        removeAll={() => setSelectedPairs([])}
      />
      {submitPopup && (
        <TetrisSubmitPopup
          isLoading={isLoading}
          closeModal={() => setSubmitPopup(false)}
          submit={submit}
          sites={sites}
        />
      )}
    </div>
  );
};
