import { EQuestion, ESectionType } from "./types";

export const moduleTypesOptions = [
  {
    label: "Теория",
    value: ESectionType.THEORY,
  },
  {
    label: "Практика",
    value: ESectionType.PRACTICE,
  },
  {
    label: "Аттестация",
    value: ESectionType.EXAMINATION,
  },
];

export const questionTypesOptions = [
  {
    label: "1 вариант",
    value: EQuestion.RADIO,
  },
  {
    label: "Несколько вариантов",
    value: EQuestion.MULTI,
  },
  {
    label: "Свой ответ",
    value: EQuestion.INPUT,
  },
];

export const defineModuleTitle = (module_type: ESectionType): string => {
  return moduleTypesOptions?.find((el) => el.value === module_type)?.label || "Неопределен";
};

export const defineAnswerInputType = (question_type: EQuestion) => {
  switch (question_type) {
    case EQuestion.RADIO:
      return "radio";
    case EQuestion.INPUT:
      return "text";
    case EQuestion.MULTI:
      return "checkbox";
  }
};
