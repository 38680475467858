import api from "./http";
import { BASE_URL } from "./constants";
import axios from "axios";

export default class controlApi {
  // Статистика
  static async getInfoStats() {
    return api.get(`${BASE_URL}/statistics_agregator/info`);
  }
  static async getInfoStatsPeriod(data) {
    return api.get(
      `${BASE_URL}/statistics_agregator/period_info/?from_date=${data?.from}&to_date=${data?.to}`
    );
  }
  static async addSectionStats(data) {
    return api.post(`${BASE_URL}/statistics_agregator/section`, data);
  }
  static async deleteSectionStats(id) {
    return api.delete(`${BASE_URL}/statistics_agregator/section/${id}`);
  }
  static async addIssueStats(data) {
    return api.post(`${BASE_URL}/statistics_agregator/issue`, data);
  }
  static async deleteIssueStats(id) {
    return api.delete(`${BASE_URL}/statistics_agregator/issue/${id}`);
  }
  static async addEventStats(data) {
    return api.post(`${BASE_URL}/statistics_agregator/event`, data);
  }
  static async getStaticStats() {
    return api.get(`${BASE_URL}/statistics_agregator/report`);
  }
  static async getStaticPeriodStats(data) {
    return api.get(
      `${BASE_URL}/statistics_agregator/period_report/?from_date=${data?.from}&to_date=${data?.to}`
    );
  }
  static async getReportStats(url) {
    return axios.get(`${url}`);
  }
  static async updateIssueStats(data) {
    return api.patch(`${BASE_URL}/statistics_agregator/issue`, data);
  }
  static async renameSectionStats(data) {
    return api.patch(`${BASE_URL}/statistics_agregator/section`, data);
  }
  static async getAutoControlIssues() {
    return api.get(`${BASE_URL}/statistics_agregator/autocontrol-issue`);
  }
  // Доска
  static async getEvents() {
    return api.get(`${BASE_URL}/violation-follower/event/`);
  }
  static async updateEventStatus(data) {
    return api.patch(`${BASE_URL}/violation-follower/event/status`, data);
  }
  static async updateEventDeadline(data) {
    return api.patch(`${BASE_URL}/violation-follower/event/deadline`, data);
  }
  static async updateEventPriority(data) {
    return api.patch(`${BASE_URL}/violation-follower/event/priority`, data);
  }
  static async updateEventResponsible(data) {
    return api.patch(`${BASE_URL}/violation-follower/event/responsible`, data);
  }
  static async updateEventStageResponsible(data) {
    return api.patch(`${BASE_URL}/violation-follower/event/stage_responsible`, data);
  }
  static async updateEventStageDeadline(data) {
    return api.patch(`${BASE_URL}/violation-follower/event/stage_deadline`, data);
  }
  static async deleteEvent(id) {
    return api.delete(`${BASE_URL}/violation-follower/event/${id}`);
  }
  // Регламенты
  static async getRegulations() {
    return api.get(`${BASE_URL}/regulations-api/regulation/`);
  }
  static async updateRegulations(data) {
    return api.patch(`${BASE_URL}/regulations-api/regulation/`, data);
  }
  static async getRegulationsChats() {
    return api.get(`${BASE_URL}/regulations-api/chat/`);
  }
  static async createRegulationsChat(data) {
    return api.post(`${BASE_URL}/regulations-api/chat/`, data);
  }
  static async updateRegulationsChat(data) {
    return api.patch(`${BASE_URL}/regulations-api/chat/`, data);
  }
  static async deleteRegulationsChat(id) {
    return api.delete(`${BASE_URL}/regulations-api/chat/${id}`);
  }
  // Процессинг
  static async getProcessingChats() {
    return api.get(`${BASE_URL}/process-control/chat/`);
  }
  static async createProcessingChat(data) {
    return api.post(`${BASE_URL}/process-control/chat/`, data);
  }
  static async updateProcessingChat(data) {
    return api.patch(`${BASE_URL}/process-control/chat/`, data);
  }
  static async deleteProcessingChat(id) {
    return api.delete(`${BASE_URL}/process-control/chat/${id}`);
  }
  // Кошельки
  static async getWallets() {
    return api.get(`${BASE_URL}/control-balance-checker/wallet/`);
  }
  static async createWallet(data) {
    return api.post(`${BASE_URL}/control-balance-checker/wallet/`, data);
  }
  static async updateWallet(data) {
    return api.patch(`${BASE_URL}/control-balance-checker/wallet/`, data);
  }
  static async deleteWallet(wallet_id) {
    return api.delete(`${BASE_URL}/control-balance-checker/wallet/${wallet_id}`);
  }
  static async getCoins() {
    return api.get(`${BASE_URL}/control-balance-checker/coin/`);
  }
  static async createCoin(data) {
    return api.post(`${BASE_URL}/control-balance-checker/coin/`, data);
  }
  static async updateCoin(data) {
    return api.patch(`${BASE_URL}/control-balance-checker/coin/`, data);
  }
  static async deleteCoin(coin_id) {
    return api.delete(`${BASE_URL}/control-balance-checker/coin/${coin_id}`);
  }
  static async getTracks() {
    return api.get(`${BASE_URL}/control-balance-checker/track/`);
  }
  static async createTrack(data) {
    return api.post(`${BASE_URL}/control-balance-checker/track/`, data);
  }
  static async updateTrack(data) {
    return api.patch(`${BASE_URL}/control-balance-checker/track/`, data);
  }
  static async deleteTrack(track_id) {
    return api.delete(`${BASE_URL}/control-balance-checker/track/${track_id}`);
  }
  static async getNotifications() {
    return api.get(`${BASE_URL}/control-balance-checker/notification/`);
  }
  static async createNotification(data) {
    return api.post(`${BASE_URL}/control-balance-checker/notification/`, data);
  }
  static async updateNotification(data) {
    return api.patch(`${BASE_URL}/control-balance-checker/notification/`, data);
  }
  static async deleteNotification(notification_id) {
    return api.delete(`${BASE_URL}/control-balance-checker/notification/${notification_id}`);
  }
  static async getConditions() {
    return api.get(`${BASE_URL}/control-balance-checker/condition/`);
  }
  static async getNetworks() {
    return api.get(`${BASE_URL}/control-balance-checker/network/`);
  }
  // Пользователи
  static async getControlUsers() {
    return api.get(`${BASE_URL}/statistics_agregator/user/`);
  }
  static async createControlUser(data) {
    return api.post(`${BASE_URL}/statistics_agregator/user/`, data);
  }
  static async updateControlUser(data) {
    return api.patch(`${BASE_URL}/statistics_agregator/user/`, data);
  }
  static async deleteControlUser(id) {
    return api.delete(`${BASE_URL}/statistics_agregator/user/${id}`);
  }
  // Пользователи
  static async getProcessUsers() {
    return api.get(`${BASE_URL}/process-control/user/`);
  }
  static async createProcessUser(data) {
    return api.post(`${BASE_URL}/process-control/user/`, data);
  }
  static async updateProcessUser(data) {
    return api.patch(`${BASE_URL}/process-control/user/`, data);
  }
  static async deleteProcessUser(id) {
    return api.delete(`${BASE_URL}/process-control/user/${id}`);
  }
  // Автотесты
  static async getSiteTests() {
    return api.get(`${BASE_URL}/site_text_validator/sites`);
  }
  static async getCitiesTests() {
    return api.get(`${BASE_URL}/site_text_validator/cities`);
  }
  static async getVectorsTests() {
    return api.get(`${BASE_URL}/site_text_validator/vectors`);
  }
  static async addSiteTests(data) {
    return api.post(`${BASE_URL}/site_text_validator/sites`, data);
  }
  static async updateSiteTests(data) {
    return api.patch(`${BASE_URL}/site_text_validator/sites`, data);
  }
  static async addCity(data) {
    return api.post(`${BASE_URL}/site_text_validator/cities`, data);
  }
  static async deleteCity(data) {
    return api.delete(
      `${BASE_URL}/site_text_validator/city_data/?city_id=${data.city_id}&city_code=${data.city_code}&city_lang=${data.city_lang}`
    );
  }
  static async addDirection(data) {
    return api.post(`${BASE_URL}/site_text_validator/vectors`, data);
  }
  static async deleteDirection(data) {
    return api.delete(
      `${BASE_URL}/site_text_validator/vector_data/?site_url=${data.site_url}&from_code=${data.from_code}&to_code=${data.to_code}&city_is_main=${data.city_is_main}&lang=${data.lang}`
    );
  }
  static async getAllReports() {
    // удалить
    return api.get(`${BASE_URL}/site_text_validator/get_all_reports`);
  }
  static async getSitesBadges() {
    return api.get(`${BASE_URL}/site_text_validator/badges`);
  }
  static async getSitesTextReports() {
    return api.get(`${BASE_URL}/site_text_validator/text/reports`);
  }
  static async getSitesCoursesReports() {
    return api.get(`${BASE_URL}/site_text_validator/courses/reports`);
  }
  static async getSitesMinMaxReports() {
    return api.get(`${BASE_URL}/site_text_validator/minmax/reports`);
  }
  static async deleteReport(data) {
    return api.delete(
      `${BASE_URL}/site_text_validator/report/?site_url=${data.site_url}&from_code=${data.from_code}&to_code=${data.to_code}&city_code=${data.city_code}&lang=${data.lang}`
    );
  }
  static async refreshReport(data) {
    return api.put(`${BASE_URL}/site_text_validator/report`, data);
  }
  static async markReadSites(data) {
    return api.patch(`${BASE_URL}/site_text_validator/read_report`, data);
  }
  // Переходы с Беста
  static async getBestTestsSites() {
    return api.get(`${BASE_URL}/redirect_checker/exchangers`);
  }
  static async toggleBestTestsSite(data) {
    return api.put(`${BASE_URL}/redirect_checker/exchanger`, data);
  }
  static async getBestTestsCurrencies() {
    return api.get(`${BASE_URL}/redirect_checker/currencies`);
  }
  static async addBestTestsCurrency(data) {
    return api.post(`${BASE_URL}/redirect_checker/currency`, data);
  }
  static async getBestTestsCities() {
    return api.get(`${BASE_URL}/redirect_checker/cities`);
  }
  static async getBestTestsReports() {
    return api.get(`${BASE_URL}/redirect_checker/reports`);
  }
  static async startBestTests() {
    return api.get(`${BASE_URL}/redirect_checker/redirect_check`);
  }
  static async refreshBestTestsReport(data) {
    return api.patch(`${BASE_URL}/redirect_checker/report`, data);
  }
  static async getAggregatorBadges() {
    return api.get(`${BASE_URL}/redirect_checker/badges`);
  }
  static async getAvailableReports() {
    return api.get(`${BASE_URL}/redirect_checker/availability/reports`);
  }
  static async startAvailableCheck() {
    return api.get(`${BASE_URL}/redirect_checker/availability_check`);
  }
  static async getCoursesReports() {
    return api.get(`${BASE_URL}/redirect_checker/courses/reports`);
  }
  static async getDelta() {
    return api.get(`${BASE_URL}/redirect_checker/courses/delta`);
  }
  static async updateDelta(data) {
    return api.patch(`${BASE_URL}/redirect_checker/courses/delta`, data);
  }
  static async getReserveDelta() {
    return api.get(`${BASE_URL}/redirect_checker/reserve/delta`);
  }
  static async updateReserveDelta(data) {
    return api.patch(`${BASE_URL}/redirect_checker/reserve/delta`, data);
  }
  static async getMinMaxReports() {
    return api.get(`${BASE_URL}/redirect_checker/minmax/reports`);
  }
  static async getReserveReports() {
    return api.get(`${BASE_URL}/redirect_checker/reserve/reports`);
  }
  static async getCarsReports() {
    return api.get(`${BASE_URL}/redirect_checker/delivery/reports`);
  }
  static async markReadAggregator(data) {
    return api.patch(`${BASE_URL}/redirect_checker/read_report`, data);
  }

  static async getAvailableReportsExnode() {
    return api.get(`${BASE_URL}/exnode_checker/availability/reports`);
  }
  static async getCoursesReportsExnode() {
    return api.get(`${BASE_URL}/exnode_checker/courses/reports`);
  }
  static async getMinMaxReportsExnode() {
    return api.get(`${BASE_URL}/exnode_checker/minmax/reports`);
  }
  static async getReserveReportsExnode() {
    return api.get(`${BASE_URL}/exnode_checker/reserve/reports`);
  }
}
