import { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { LS, Url } from "./api/constants";
import { notify } from "./components/SystemAlerts/SystemAlerts";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { fetchRequest } from "./helpers/fetchRequest";
import { createCentrifugeConnection } from "./api/centrifugo/centrifugo";
import {
  createStatisticsSubscription,
  createStatsByRatioSubscription,
} from "./api/centrifugo/statsCentrifugo";
import { selectStats } from "./store/statsReducer";
import { setLastAction, setReadedActions } from "./store/commonReducer";
import { selectCentrifugoConnection, selectIsLogged, setLogin } from "./store/mainReducer";
import switchApi from "./api/onOff";
import { IStat, IStats } from "./pages/Stats/models";
import { TAlert } from "./pages/Stats/Stats";
import {
  setAllCities,
  setAllCurrencies,
  setAllDirections,
  setAllSites,
  setAllRoles,
} from "./store/directionsReducer";
import Header from "./components/Header";
import Routing from "./pages/routes/Routing";
import { Viewers } from "./components/Viewers/Viewers";
import { SideBar } from "./components/SideBar/SideBar";
import { NotificQueue } from "./components/UI/notific/NotificQueue";
import "./App.css";
import { ErrorBoundary } from "@sentry/react";
import { ErrorFallback } from "src/components/ErrorHandling/ErrorFallback";
import { fetchUnreadReqCount } from "src/api/centrifugo/requestsCentrifugo";

const App = () => {
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = useLocation().pathname;
  const isLogged = useSelector(selectIsLogged);
  const isCentrifugoConnection = useSelector(selectCentrifugoConnection);
  const userName = localStorage.getItem(LS.USERNAME);
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");
  const stats = useSelector(selectStats) as IStats;
  const [isFirstDelay, setFirstDelay] = useState(true);
  const [blockStatsAlert, setBlockStats] = useState(false);
  const mAccess =
    user_access?.includes("minmax_get") || (user_access?.includes("minmax_post") as boolean);
  const sAccess =
    user_access?.includes("step_get") || (user_access?.includes("step_post") as boolean);
  const oAccess =
    user_access?.includes("on-off_get") || (user_access?.includes("on-off_post") as boolean);

  let alerts = [] as Array<TAlert>;

  if (stats) {
    stats?.stats?.forEach((el: IStat) => {
      alerts = [
        ...alerts,
        ...el.data
          .filter((el2) => el2.bad_status || false)
          .map((a) => ({ ...a, city_name: el?.city_name })),
      ];
    });
  }

  const fetchGlobals = async () => {
    const { response } = await fetchRequest(switchApi.getGlobals(), {
      request: "Глобальные данные",
    });
    if (response) {
      dispatch(setAllCurrencies(response.currencies));
      dispatch(setAllDirections(response.vectors));
      dispatch(setAllSites(response.sites));
      dispatch(setAllCities(response.cities));
      dispatch(setAllRoles(response.user_roles));
    }
  };

  useEffect(() => {
    if (userName) {
      dispatch(setLogin());
      fetchUnreadReqCount();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isLogged) {
        await createCentrifugeConnection();
        if (oAccess || user_access?.includes("cluster_get") || user_access?.includes("add_get")) {
          await fetchGlobals();
        }
        setTimeout(() => setFirstDelay(false), 3000);
      } else {
        setFirstDelay(true);
      }
    })();
  }, [isLogged]);

  useEffect(() => {
    if (page === Url.LOGIN) {
      navigate(Url.LOGIN, { replace: true });
    }
  }, [page]);

  useEffect(() => {
    const fpPromise = FingerprintJS.load();
    (async () => {
      const fp = await fpPromise;
      const result = await fp.get();
      const visitorId = result.visitorId;
      cookie.set("fingerprint", visitorId);
    })();
    notify.authorize();
  }, []);

  useEffect(() => {
    const blockAlertsTimer = setTimeout(() => setBlockStats(false), 300000);
    if (+new Date() / 1000 - +stats?.last_upd > 300 && !blockStatsAlert && isCentrifugoConnection) {
      notify.show("Статистика", "Последнее обновление данных было более 5 минут назад", Url.STATS);
      setBlockStats(true);
    }
    return () => {
      clearTimeout(blockAlertsTimer);
    };
  }, [stats?.last_upd, isCentrifugoConnection]);

  useEffect(() => {
    if (!isFirstDelay) {
      if (!isCentrifugoConnection) {
        notify.show("Centrifugo", "Соединение потеряно", "");
      } else {
        notify.show("Centrifugo", "Соединение установлено", "");
      }
    }
  }, [isCentrifugoConnection]);

  useEffect(() => {
    if (isLogged) {
      createStatisticsSubscription(false, false);
      createStatsByRatioSubscription(false, false);
      if (!!localStorage.getItem(LS.SUPPORT_LAST_ACTION)) {
        dispatch(setLastAction(+localStorage.getItem(LS.SUPPORT_LAST_ACTION)));
      }
      if (!!localStorage.getItem(LS.SUPPORT_READ_ACTIONS)) {
        const readedActions = localStorage.getItem(LS.SUPPORT_READ_ACTIONS) || "";
        dispatch(setReadedActions(readedActions.split(",")?.map((el) => +el)));
      }
      return () => {
        createStatisticsSubscription(false, true);
        createStatsByRatioSubscription(false, true);
      };
    }
  }, [isLogged]);

  return (
    <ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorFallback errorMessage={error.message} resetError={resetError} />
      )}>
      <div
        className={`App ${
          (page === Url.ANALYTICS || page === `${Url.CONTROL}/${Url.CONTROL_DASHBOARD}`) &&
          "bg-[#000]"
        }`}>
        <Header />
        <div className="h-[80px] w-full" />
        <Routing sAccess={sAccess} mAccess={mAccess} />
        {isLogged && <Viewers />}
        {isLogged && <SideBar alerts={alerts} />}
        <NotificQueue />
      </div>
    </ErrorBoundary>
  );
};

export default App;
