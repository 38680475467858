import image from "src/assets/images/image.svg";
import { ChangeEvent, useRef } from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";

export const ImageUploader = ({ handleUpload, handlePaste, filesLength }) => {
  const fileRef = useRef(null);
  const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpload(e);
    if (fileRef.current) {
      fileRef.current.value = "";
    }
  };
  return (
    <div className="flex flex-col">
      {filesLength < 10 && (
        <button type="button" className="relative">
          <div className="absolute left-[1px] duration-500 w-[32px] min-w-[32px] h-[25px] rounded-sm bg-[#EAEAF3] text-white text-[14px] font-bold  opacity-0 hover:opacity-100 cursor-pointer">
            <input
              ref={fileRef}
              type="file"
              className="absolute left-0 opacity-0 w-[32px] mr-8"
              onChange={onUpload}
              multiple
              disabled={filesLength >= 10}
            />
            +
          </div>
          <img
            src={image}
            alt="Загрузить изображение"
            width={40}
            height={40}
            className="mt-[-6px] min-w-[40px]"
          />
        </button>
      )}
      {filesLength < 10 && (
        <textarea
          readOnly
          value={`CTRL\n+ V`}
          className="flex focus:bg-lightGray p-4 items-center justify-center text-[8px] resize-none overflow-hidden leading-[8px] border border-lightGray w-[32px] h-[25px] rounded-sm mt-[-6px] whitespace-pre cursor-pointer"
          onPaste={handlePaste}
        />
      )}
      {filesLength > 9 && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div
                className={`mt-4 mr-8 text-center relative w-[16px] h-[16px] text-white font-bold leading-[15px] rounded-sm cursor-default ${
                  filesLength === 10 ? "bg-lightGray" : "bg-red"
                }`}>
                !
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Уведомления в Телеграмм не поддерживают более 10 файлов!</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
};
