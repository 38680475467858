import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { add, addAll, addCities } from "src/helpers/addHelper";
import { pascal } from "src/helpers/strHelper";
import tetrisApi from "src/api/tetris";
import { setNotific } from "src/store/mainReducer";
import {
  selectAllCities,
  selectAllCurrencies,
  selectAllDirections,
  selectAllSites,
  selectGlobalCurrencies,
  selectGlobalDirections,
  TSite,
} from "src/store/directionsReducer";
import { TClusterSchedule } from "src/store/clusterReducer";
import { selectRates } from "src/store/statsReducer";
import {
  TTetris,
  selectExistTetris,
  selectFilters,
  setExistTetris,
  setFilters,
} from "src/store/tetrisReduser";
import { AllButtonSquare } from "src/components/UI/buttons/AllButtonSquare";
import { MultiSelect } from "src/components/FormElements/MultiSelect";
import { Popup } from "src/components/Popup/Popup";
import { TT } from "../components/tetrisConstants";
import { Confirmation } from "./Confirmation";
import { CurrencyItem } from "./CurrencyItem";
import { TetrisTabs } from "../components/TetrisTabs";
import { allFilter } from "../components/allFilter";
import { findCurrentRate } from "src/helpers/tetrisHelper";

export const TetrisBoard = ({
  isAccess,
  fetchItems,
  page,
  setPage,
  searchParams,
  setSearchParams,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(selectExistTetris);
  const filters = useSelector(selectFilters);
  const rates = useSelector(selectRates);
  const globalsDirections = useSelector(selectAllDirections);
  const globalsFullDirections = useSelector(selectGlobalDirections);
  const globalSites = useSelector(selectAllSites);
  const globalCities = useSelector(selectAllCities);
  const globalCurrencies = useSelector(selectAllCurrencies);
  const globalFullCurrencies = useSelector(selectGlobalCurrencies);

  const currencies = page.page === TT.AUTOCORR ? globalFullCurrencies : globalCurrencies;

  const currenciesOptions = currencies?.map((el) => ({
    label: el.currency_name,
    value: el.currency_id,
  }));
  const [isAlien, setIsAlien] = useState(false);
  const [alienSites, setAlienSites] = useState<Array<TSite>>([]);
  const sitesOptions = isAlien
    ? alienSites?.map((el) => ({ label: el.site_name, value: el.site_id }))
    : globalSites?.map((el) => ({ label: el.site_name, value: el.site_id }));
  const citiesOptions = globalCities
    ?.filter((el) => el.city_name !== "all")
    ?.map((el) => ({ label: el.city_name, value: el.city_id }));
  const [vectors, setVectors] = useState<Array<string>>([]);
  const [sites, setSites] = useState<Array<string>>([]);
  const [cities, setCities] = useState<Array<string>>([]);
  const [percent, setPercent] = useState(1);
  const [resRate, setResRate] = useState(1);
  const [isEditAutocor, setIsEditAutocor] = useState(false);
  const [from, setFrom] = useState("11:00");
  const [to, setTo] = useState("21:00");
  const [skipped, setSkipped] = useState<Array<TClusterSchedule>>([]);
  const [isPopup, setPopup] = useState(false);
  const timeError = page.page === TT.SCHEDULE && (from > to || !from.length || !to.length);

  const directions = page.page === TT.AUTOCORR ? globalsFullDirections : globalsDirections;

  const isDisabledRate = useMemo(() => {
    if (page.page === TT.AUTOCORR && vectors?.length === 1) {
      const vectorData = vectors[0]?.split("-to-");
      const rate = findCurrentRate({
        rates,
        curFrom: vectorData[0],
        curTo: vectorData[1],
      });
      if (!!rate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [vectors, rates]);

  const CryptoDirections: Array<string> = useMemo(
    () =>
      directions?.length > 0
        ? directions
            ?.filter((el) => el?.crypto_first === true)
            ?.filter((el) => allFilter(el, "from", filters))
            ?.filter((el) => allFilter(el, "to", filters))
            ?.map((el) => `${el.currency_from}-to-${el.currency_to}`)
        : [],
    [directions, filters.from, filters.to]
  );
  const CashDirections: Array<string> = useMemo(
    () =>
      directions?.length > 0
        ? directions
            ?.filter((el) => el.crypto_first === false)
            ?.filter((el) => allFilter(el, "from", filters))
            ?.filter((el) => allFilter(el, "to", filters))
            ?.map((el) => `${el.currency_from}-to-${el.currency_to}`)
        : [],
    [directions, filters.from, filters.to]
  );
  const allDirections = directions
    ?.filter((el) => allFilter(el, "from", filters))
    ?.filter((el) => allFilter(el, "to", filters))
    ?.map((el) => `${el.currency_from}-to-${el.currency_to}`);

  const allSites = useMemo(
    () =>
      isAlien
        ? alienSites?.map((el) => el.site_name)?.filter((el) => allFilter(el, "sites", filters))
        : globalSites?.map((el) => el.site_name)?.filter((el) => allFilter(el, "sites", filters)),
    [globalSites, filters.sites, isAlien]
  );
  const allCities = useMemo(
    () =>
      globalCities
        ?.map((el) => el.city_name)
        ?.filter((el) => el !== "all")
        ?.filter((el) => allFilter(el, "cities", filters)),
    [globalCities, filters.cities]
  );

  const fetchAlienSites = async () => {
    const { response, error } = await fetchRequest(tetrisApi.getAlienSites(), {
      request: "Прочие сайты",
    });
    if (response) {
      setAlienSites(response);
    }
    if (error) {
      setAlienSites([]);
    }
  };

  const submit = async () => {
    setPopup(false);
    const preparedSites = sites?.map((el) => globalSites?.find((s) => s.site_name === el)?.site_id);
    const preparedCities = cities?.map(
      (el) => globalCities?.find((c) => c.city_name === el)?.city_id
    );
    const preparedVectors = vectors
      ?.map((el) => el.split("-to-"))
      ?.map((el) => directions?.find((d) => d.currency_from === el[0] && d.currency_to === el[1]));

    const payload = () => {
      switch (page.page) {
        case TT.AUTOCORR:
          return {
            vectors: vectors,
            sites: preparedSites,
            cities: preparedCities,
            percent: percent,
          };
        case TT.BLACKLIST:
          return {
            vectors: preparedVectors,
            sites: sites,
            cities: cities,
          };
        case TT.SCHEDULE:
          return {
            vectors: vectors,
            sites: preparedSites,
            cities: preparedCities,
            percent: +percent,
            time_from: from,
            time_to: to,
          };
        case TT.CLUSTER:
          return {
            vectors: preparedVectors,
            sites: preparedSites,
            cities: preparedCities,
            percent: +percent,
          };
      }
    };
    const { response } = await fetchRequest<{ updated: TTetris[]; skipped: TClusterSchedule[] }>(
      tetrisApi[`createTetris${pascal(page?.page)}`](payload()),
      { request: page.title }
    );
    if (response) {
      dispatch(setNotific({ type: "success", message: "успешно", request: page.title }));
      setVectors([]);
      setSites([]);
      setCities([]);
      if (page.page === TT.SCHEDULE) {
        dispatch(setExistTetris([...data, ...response.updated]));
        if (!!response.skipped.length) {
          setSkipped(response.skipped);
        }
      } else {
        await fetchItems();
      }
    }
  };

  useEffect(() => {
    setVectors([]);
    setSites([]);
    setCities([]);
  }, [isAlien, page.page]);

  useEffect(() => {
    setIsAlien(false);
    if (page.page === TT.BLACKLIST) {
      fetchAlienSites();
    }
  }, [page.page]);

  useEffect(() => {
    for (const key in filters) {
      if (!!searchParams?.get(key)) {
        dispatch(setFilters({ attr: key, value: JSON.parse(searchParams?.get(key)) }));
      }
    }
  }, []);

  useEffect(() => {
    for (const key in filters) {
      if (!!filters[key]) {
        if (filters[key] !== null && !!filters[key]?.length) {
          searchParams.set(key, JSON.stringify(filters[key]));
        }
        if (filters[key] === null || !filters[key]?.length) {
          searchParams.delete(key);
        }
      }
    }
    setSearchParams(searchParams);
  }, [filters]);

  useEffect(() => {
    if (page.page === TT.AUTOCORR && vectors?.length === 1) {
      if (isEditAutocor) {
        const vectorData = vectors[0]?.split("-to-");
        const rate = findCurrentRate({
          rates,
          curFrom: vectorData[0],
          curTo: vectorData[1],
        });
        const result = (resRate * 100) / rate - 100;
        setPercent(parseFloat(result.toFixed(6)));
      }
    } else {
      setIsEditAutocor(false);
    }
  }, [page.page, vectors, isEditAutocor, resRate, rates]);

  if (isAccess) {
    return (
      <div className="table rounded-sm lg:justify-self-end shadow-smooth-out mb-[40px] lg:mb-[100px] mx-auto lg:mx-[20px] mt-0 duration-300 max-w-[706px]">
        <div style={{ backgroundColor: page.color }} className="py-[6px] -mb-4 rounded-t-sm">
          <TetrisTabs page={page} setPage={setPage} />
        </div>
        {page.page === TT.BLACKLIST && (
          <div className="flex gap-8 items-center text-xs leading-3 font-semibold px-[10px] pt-[16px] text-lightFont">
            <div className="switcher flex justify-end">
              <label
                style={{ backgroundColor: !isAlien ? "green" : "lightgrey" }}
                className={`switcher__label-small cursor-pointer hover:opacity-[0.9]`}>
                <input
                  className="switcher__check-input"
                  type="checkbox"
                  checked={!isAlien}
                  onChange={() => setIsAlien(!isAlien)}
                />
                <span className="switcher__checkbox-small"></span>
              </label>
            </div>
            <div>{isAlien ? "не наши сайты" : "наши сайты"}</div>
          </div>
        )}
        <div className="frame h-[62vh] square-scroll rounded-sm bg-[#F5F5FD]">
          <div className="flex flex-col lg:flex-row pl-[10px] py-[10px]">
            <div>
              <div className="flex gap-4 text-[10px] pr-[10px] mb-4">
                <MultiSelect
                  options={currenciesOptions}
                  className={"w-[146px] lg:w-[156px]"}
                  placeholder="from"
                  obj={filters}
                  attr="from"
                  setFn={setFilters}
                />
                <MultiSelect
                  options={currenciesOptions}
                  className={"w-[146px] lg:w-[156px]"}
                  placeholder="to"
                  obj={filters}
                  attr="to"
                  setFn={setFilters}
                />
              </div>
              <AllButtonSquare
                addAll={addAll}
                allItems={allDirections}
                setFn={setVectors}
                type="вектор"
              />
              <div className="grid grid-cols-2 gap-2 mr-8">
                <div id="vectors" className="flex flex-col w-[148px] lg:w-[160px]">
                  {CryptoDirections?.map((el, index) => (
                    <CurrencyItem
                      key={index}
                      item={el}
                      rates={rates}
                      percent={percent}
                      vectors={vectors}
                      setVectors={setVectors}
                      page={page}
                    />
                  ))}
                </div>
                <div id="vectors" className="flex flex-col w-[148px] lg:w-[160px]">
                  {CashDirections?.map((el, index) => (
                    <CurrencyItem
                      key={index}
                      item={el}
                      rates={rates}
                      percent={percent}
                      vectors={vectors}
                      setVectors={setVectors}
                      page={page}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div id="sites" className="w-full lg:w-[170px]">
              <MultiSelect
                options={sitesOptions}
                className={"w-full lg:w-[160px] text-xs mb-4 pr-[10px] lg:pr-0"}
                placeholder="сайт"
                obj={filters}
                attr="sites"
                setFn={setFilters}
              />
              <AllButtonSquare addAll={addAll} allItems={allSites} setFn={setSites} type="сайт" />
              {allSites?.map((el, index) => (
                <button
                  key={index}
                  id={el}
                  type="button"
                  style={{ backgroundColor: sites?.includes(el) ? `${page.color}33` : "#FAFAFF" }}
                  className={`select_button w-[calc(100%-10px)] h-[30px] lg:w-[160px] text-sm ${
                    sites?.includes(el) ? "font-semibold" : ""
                  }`}
                  onClick={() => add(sites, setSites, el)}>
                  {el}
                </button>
              ))}
            </div>
            <div id="cities" className="w-full lg:w-[170px]">
              <MultiSelect
                options={citiesOptions}
                className={"w-full lg:w-[160px] text-xs mb-4 pr-[10px] lg:pr-0"}
                placeholder="город"
                obj={filters}
                attr="cities"
                setFn={setFilters}
              />
              <AllButtonSquare
                addAll={addAll}
                allItems={allCities}
                setFn={setCities}
                type="город"
              />
              {allCities?.map((el, index) => (
                <button
                  key={index}
                  id={el}
                  type="button"
                  style={{ backgroundColor: cities?.includes(el) ? `${page.color}33` : "#FAFAFF" }}
                  className={`select_button w-[calc(100%-10px)] h-[30px] lg:w-[160px] text-sm  ${
                    cities?.includes(el) ? "font-semibold" : ""
                  }`}
                  onClick={(e) => addCities(cities, setCities, e)}>
                  {el}
                </button>
              ))}
            </div>
          </div>
        </div>
        {page.page !== TT.BLACKLIST && (
          <div
            className={`w-full text-sm mx-auto flex justify-center items-center gap-4 px-[10px] py-8 ${
              page.page === TT.SCHEDULE
                ? "flex-wrap max-w-[300px] lg:max-w-full"
                : "max-w-[346px] lg:max-w-full"
            }`}>
            <div className="relative">
              {page.page === TT.SCHEDULE && (
                <div className="absolute -top-[16px] text-lightFont text-[10px]">
                  Процент таймлайна
                </div>
              )}
              <div className="flex flex-wrap gap-8 items-end">
                <div
                  className={`flex flex-col gap-2 text-left  p-4 rounded-sm ${
                    page.page === TT.AUTOCORR &&
                    vectors?.length === 1 &&
                    isEditAutocor &&
                    "hover:bg-[#4B7F6255] cursor-pointer"
                  }`}>
                  {page.page === TT.AUTOCORR && (
                    <div onClick={() => setIsEditAutocor(false)}>Процент</div>
                  )}
                  <input
                    className={`${page.page === TT.SCHEDULE ? "w-[244px]" : "w-[216px]"} ${
                      page.page === TT.AUTOCORR && isEditAutocor ? "bg-[#BABAC355]" : ""
                    } lg:w-[199px] border border-lightGray rounded outline-none h-[30px] py-4 px-8`}
                    type="number"
                    placeholder={page.page === TT.SCHEDULE ? "целое число" : "Процент..."}
                    defaultValue={page.page === TT.SCHEDULE ? 0 : 1}
                    value={percent}
                    disabled={page.page === TT.AUTOCORR && isEditAutocor}
                    step={page.page === TT.SCHEDULE ? 1 : 0.000001}
                    onChange={(e) => !Number.isNaN(e.target.value) && setPercent(+e.target.value)}
                    onWheel={(e) => {
                      e.currentTarget?.blur();
                    }}
                  />
                </div>
                {page.page === TT.AUTOCORR && vectors?.length === 1 && !isDisabledRate && (
                  <div
                    className={`flex flex-col gap-2 text-left p-4 rounded-sm ${
                      !isEditAutocor && "hover:bg-[#4B7F6255] cursor-pointer"
                    }`}>
                    <div onClick={() => setIsEditAutocor(true)}>Курс</div>
                    <input
                      className={`w-[216px] lg:w-[199px] border border-lightGray rounded outline-none h-[30px] py-4 px-8 ${
                        !isEditAutocor ? "bg-[#BABAC355]" : ""
                      }`}
                      type="number"
                      defaultValue={1}
                      disabled={!isEditAutocor}
                      step="any"
                      onChange={(e) => !Number.isNaN(e.target.value) && setResRate(+e.target.value)}
                      onWheel={(e) => {
                        e.currentTarget?.blur();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {isAccess && page.page === TT.SCHEDULE && (
              <input
                type="time"
                className="w-[120px] border border-lightGray rounded outline-none h-[30px] py-4 px-8"
                defaultValue={"11:00"}
                required
                onChange={(e) => setFrom(e.target.value)}
              />
            )}
            {isAccess && page.page === TT.SCHEDULE && (
              <input
                type="time"
                className="w-[120px] border border-lightGray rounded outline-none h-[30px] py-4 px-8"
                defaultValue={"21:00"}
                required
                onChange={(e) => setTo(e.target.value)}
              />
            )}
          </div>
        )}
        {isPopup && (
          <Popup noPadding closeModal={() => setPopup(false)}>
            <Confirmation
              sites={sites}
              cities={cities}
              vectors={vectors}
              percent={percent}
              submit={submit}
              setPopup={setPopup}
              path={page.page}
            />
          </Popup>
        )}
        {skipped?.length > 0 && (
          <Popup closeModal={() => setSkipped([])}>
            <div className="flex flex-col gap-[10px] text-xs font-light text-left max-w-[280px] mx-auto">
              <div>
                Обратите внимание, что следующие кластеры не создались, потому что для них уже
                назначено другое время
              </div>
              <div className="max-h-[60vh] overflow-y-scroll">
                {skipped?.map((el, index) => (
                  <div key={index}>
                    {el.site_name} - {el.vector} - {el.city_name} - {el.percent} - {el.time_from} -{" "}
                    {el.time_to}
                  </div>
                ))}
              </div>
              <button
                type="button"
                className="prime-button-md bg-font mt-[20px]"
                onClick={() => setSkipped([])}>
                Понятно
              </button>
            </div>
          </Popup>
        )}
        <button
          type="button"
          className="rounded-sm font-semibold text-sm px-[30px] py-[6px] bg-font text-white mb-[16px] mt-8"
          disabled={(page.page === TT.SCHEDULE ? percent < 0 : percent === 0) || timeError}
          onClick={() => setPopup(true)}>
          {page.page === TT.BLACKLIST
            ? `Занести в BLACKLIST`
            : `Выставить ${page.title.toLowerCase()?.split(" ")[0]}`}
        </button>
      </div>
    );
  } else {
    return (
      <div className={`pl-4 lg:pl-0 w-full pb-[16px]`}>
        <div
          style={{ backgroundColor: page.color }}
          className={`duration-300 w-full max-w-[350px] lg:max-w-[666px] lg:justify-self-end rounded-sm pb-[12px] mx-auto`}>
          <TetrisTabs page={page} setPage={setPage} />
        </div>
      </div>
    );
  }
};
