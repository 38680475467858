import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TSummarySite } from "src/store/commonReducer";
import {
  selectAllCities,
  selectAllCurrencies,
  setSelectedSite,
  TSite,
} from "src/store/directionsReducer";
import { Item } from "./Item";
import { OptionItem } from "./Option";
import { Exception } from "./Exception";
import on from "src/assets/images/check.svg";
import off from "src/assets/images/close.svg";
import arrow from "src/assets/images/arrow.svg";

export const Site = ({
  item,
  selectedSite,
}: {
  item: TSummarySite;
  selectedSite: TSite | null;
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const globalCities = useSelector(selectAllCities);
  const globalCurrencies = useSelector(selectAllCurrencies);

  const badges = [
    { title: "синхронизатор", value: item?.tumbler_site },
    { title: "вылеты", value: item?.tumbler_scam_checker },
    { title: "вкл/выкл", value: item?.update_turn_status },
    { title: "DB", value: item?.db_access },
    { title: "PE", value: item?.is_pe },
  ] as Array<{ title: string; value: boolean }>;

  useEffect(() => {
    if (!!selectedSite && selectedSite.site_id === item.site_id) {
      setIsOpen(true);
      const elem = document.getElementById(`${item.site_id}`);
      elem?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, []);

  return (
    <div
      id={`${item.site_id}`}
      className={`rounded-lg duration-700 shadow-smooth p-[10px] pl-[20px] text-left text-xs ${
        isOpen ? "max-h-[1000px] overflow-auto" : "max-h-[38px] overflow-hidden"
      }`}>
      <div className="grid grid-cols-[22px_1fr_16px] sm:grid-cols-[28px_1fr_250px_20px] gap-[10px] items-center">
        <div className="flex justify-center w-[18px]">
          <img src={item?.is_on_best ? on : off} alt="" width={16} height={16} />
        </div>
        <div>{item?.site_name}</div>
        <div className="hidden sm:flex flex-wrap gap-4 text-[9px] max-w-[250px] -my-[10px] whitespace-pre">
          {badges?.map(
            (el) =>
              el !== null && (
                <div
                  key={el.title}
                  className={`px-[6px] pb-[1px] rounded-lg outline outline-1 text-[#383C44] font-normal ${
                    el?.value
                      ? "bg-[#3BB54A11] outline-[#3BB54A55]"
                      : "bg-[#D7443E11] outline-[#D7443E33]"
                  }`}>
                  {el.title}
                </div>
              )
          )}
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (!isOpen) {
              dispatch(setSelectedSite({ site_id: item.site_id, site_name: item.site_name }));
            }
            setIsOpen(!isOpen);
          }}>
          <img
            src={arrow}
            alt="Открыть"
            width={10}
            height={10}
            className={`duration-300 w-[10px] h-[10px] ${isOpen ? "rotate-180" : ""}`}
          />
        </div>
      </div>
      <div className="grid sm:grid-cols-[2fr_1fr] sm:gap-[20px] mt-[12px]">
        <div className="flex flex-col gap-4">
          <Item title="BEST ID" value={item.site_id} />
          <Item title="EXNODE ID" value={item.exnode_id} />
          <Item title="site_name" value={item.site_name} />
          <Item title="site_url" value={item.site_url} />
          <Item title="site_xml" value={item.site_url_xml} />
          <Item title="xml" value={item.real_xml} />
        </div>
        <div className="flex flex-col gap-4">
          <OptionItem title="на бесте" value={item?.is_on_best} />
          <OptionItem title="вкл/вкл" value={item?.update_turn_status} />
          <OptionItem title="синхронизатор minmax, шаги" value={item?.tumbler_site} />
          <OptionItem title="проверка на вылеты" value={item?.tumbler_scam_checker} />
          <OptionItem title="доступ DB" value={item?.db_access} />
          <OptionItem title="PE" value={item?.is_pe} />
        </div>
      </div>
      {!!item?.exceptions?.length && (
        <div className="flex flex-col gap-4 mt-8">
          <div className="text-lightFont font-normal">Не работаем по направлениям</div>
          {item?.exceptions?.map((el) => (
            <Exception
              key={`${item?.site_url_xml}-${el.city_id}`}
              item={el}
              cities={globalCities}
              currencies={globalCurrencies}
            />
          ))}
        </div>
      )}
    </div>
  );
};
