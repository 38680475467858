import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchRequest } from "src/helpers/fetchRequest";
import { LS } from "src/api/constants";
import graphsApi from "src/api/graphs";
import {
  selectAllCurrencies,
  selectCity,
  selectCurFrom,
  selectCurTo,
  selectSite,
  setSelectedSite,
  TAlienOptions,
  TBestSite,
  TEntropy,
  TLastAvailable,
  TLinks,
  TGraphData,
  TGraphLevels,
  TSelect,
  TNode,
} from "src/store/directionsReducer";
import { Spinner } from "src/components/Spinner/Spinner";
import { Emptiness, GoodEmptiness } from "src/components/UI/loader/Emptiness";
import { GraphFilter } from "./components/GraphFilter";
import { VectorsGraph } from "./components/VectorsGraph";
import { Entropy } from "./Entropy/Entropy";
import { LastAvailable } from "./LastAvailable/LastAvailable";

export const DirectionsGraph = () => {
  const dispatch = useDispatch();
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const [searchParams, setSearchParams] = useSearchParams();
  const currencies = useSelector(selectAllCurrencies);
  const currenciesOptions = useMemo(
    () => currencies?.map((el) => ({ label: el.currency_name, value: el.currency_id })),
    [currencies]
  );
  const curFrom = useSelector(selectCurFrom);
  const curTo = useSelector(selectCurTo);
  const selectedSite = useSelector(selectSite);
  const selectedCity = useSelector(selectCity);
  const [isFilterOpen, setFilterOpen] = useState(true);
  const [sites, setSites] = useState<Array<TBestSite>>([]);
  const [page, setPage] = useState<TTab | null>(null);
  const [firstUpdate, setFirstUpdate] = useState(true);
  const [isGood, setIsGood] = useState<boolean | null>(null);
  const [isEntropyGood, setIsEntropyGood] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hierarchical, setHierarchical] = useState(false);
  const [edgeLength, setEdgeLength] = useState(100);
  const [data, setData] = useState<TGraphData | null>(null);
  const [entropy, setEntropy] = useState<TEntropy | null>(null);
  const [availableData, setAvailableData] = useState<Array<TLastAvailable>>([]);
  const [selectedNodes, setSelectedNodes] = useState<Array<string>>([]);

  const pages = useMemo(
    () =>
      [
        {
          title: "GRAPH",
          access: userAccess?.includes("direction_get"),
          url: "graph",
        },
        {
          title: "Последнее присутствие",
          access: userAccess?.includes("direction_get"),
          url: "last-available",
        },
      ] as Array<TTab>,
    []
  );

  const [filter, setFilter] = useState({
    sites: [] as Array<TSelect>,
    city: [] as Array<TSelect>,
    from: [] as Array<TSelect>,
    to: [] as Array<TSelect>,
    time_from: "", // 2023-06-30T19:57
    time_to: "",
    links: [] as Array<TLinks>,
    level: [] as Array<TLinks>,
    direction: "UD" as "LR" | "UD",
    type: "site" as "site" | "city",
    alien: "все" as TAlienOptions,
    is_on_best_now: false,
  });

  const selectedFilter = (el: TNode, filterLevel: TGraphLevels) => {
    if (filterLevel !== "to" && filterLevel !== "from_") {
      return filterLevel === filter.type
        ? !el.id?.includes("to")
        : !el?.id?.includes(filter.type) && !el.id?.includes("to");
    } else {
      if (filterLevel === "to") {
        return false;
      } else if (filterLevel === "from_") {
        return el.id?.includes("from");
      }
    }
  };

  const fetchBestSites = async () => {
    setIsLoading(true);
    const { response, error } = await fetchRequest(graphsApi.getBestSites(), { request: `Сайты` });
    if (response) {
      setSites(response);
    }
    if (error) {
      setSites([]);
    }
    setIsLoading(false);
  };

  const fetchGraph = async () => {
    setIsLoading(true);
    const { response, error, status } = await fetchRequest(
      graphsApi.directionsGraph({
        site_ids: filter?.sites?.map((el) => el.value)?.join(","),
        city_ids: filter?.city?.map((el) => el.value)?.join(","),
        currency_from_ids: filter?.from?.map((el) => el.value)?.join(","),
        currency_to_ids: filter?.to?.map((el) => el.value)?.join(","),
        links: filter.links?.join(","),
        type: filter.type,
        time_from: filter?.time_from,
      }),
      { request: `Граф направлений` }
    );
    if (response && status !== 204) {
      setIsGood(true);
      setData({
        nodes: response?.nodes,
        edges: response?.edges,
      });
      setSelectedNodes(
        response.nodes?.filter((el) => selectedFilter(el, filter?.level[0]))?.map((el) => el.id)
      );
    }
    if (status === 204) {
      setIsGood(true);
      setData(null);
    }
    if (error) {
      setIsGood(false);
      setData(null);
    }
    setIsLoading(false);
  };

  const fetchEntropy = async () => {
    setIsLoading(true);
    if (!filter?.sites?.length) {
      const bestSites = sites?.filter((el) => el?.is_our && el?.is_on_best_now);
      setFilter({
        ...filter,
        sites: bestSites?.map((el) => ({ label: el.site_name, value: el.best_id })),
      });
      dispatch(
        setSelectedSite({
          site_id: bestSites[bestSites.length - 1]?.best_id,
          site_name: bestSites[bestSites.length - 1]?.site_name,
        })
      );
    }
    const { response, error, status } = await fetchRequest(
      graphsApi.getEntropy({
        sites: !!filter?.sites?.length
          ? filter?.sites?.map((el) => el.value)?.join(",")
          : sites
              ?.filter((el) => el?.is_our && el?.is_on_best_now)
              ?.map((el) => el.best_id)
              ?.join(","),
        time_from: filter?.time_from,
        time_to: filter?.time_to,
        cities: filter?.city?.map((el) => el.value)?.join(","),
      }),
      { request: `Энтропия` }
    );
    if (response && status !== 204) {
      setIsEntropyGood(true);
      setEntropy(response);
    }
    if (status === 204) {
      setIsEntropyGood(true);
      setEntropy(null);
    }
    if (error) {
      setIsEntropyGood(false);
      setEntropy(null);
    }
    setIsLoading(false);
  };

  const fetchLastAvailable = async () => {
    setIsLoading(true);
    const { response, error, status } = await fetchRequest(
      graphsApi.getLastAvailable({
        sites: filter?.sites?.map((el) => el.value)?.join(","),
        time_from: filter?.time_from,
        time_to: filter?.time_to,
        cities: filter?.city?.map((el) => el.value)?.join(","),
        currency_from: filter?.from?.map((el) => el.value)?.join(","),
        currency_to: filter?.to?.map((el) => el.value)?.join(","),
      }),
      { request: `Последнее присутствие` }
    );
    if (response) {
      setIsGood(true);
      setAvailableData(response);
    }
    if (status === 204) {
      setIsGood(true);
      setAvailableData([]);
    }
    if (error) {
      setIsGood(false);
      setAvailableData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBestSites();
    let emptyFilter = { ...filter };
    for (const key in filter) {
      if (!!searchParams?.get(key)) {
        emptyFilter = {
          ...emptyFilter,
          [key]: key === "type" ? searchParams?.get(key) : JSON.parse(searchParams?.get(key)),
        };
      }
      if (!searchParams?.get(key)) {
        switch (key) {
          case "sites": {
            if (!!selectedSite) {
              emptyFilter = {
                ...emptyFilter,
                sites: [{ label: selectedSite?.site_name, value: selectedSite?.site_id }],
              };
            }
            break;
          }
          case "from": {
            if (!!curFrom && !!currenciesOptions?.length) {
              const currId = currenciesOptions?.find((el) => el.label === curFrom)?.value;
              if (!!currId) {
                emptyFilter = { ...emptyFilter, from: [{ label: curFrom, value: currId }] };
              }
            }
            break;
          }
          case "to": {
            if (!!curTo && !!currenciesOptions?.length) {
              const currId = currenciesOptions?.find((el) => el.label === curTo)?.value;
              if (!!currId) {
                emptyFilter = { ...emptyFilter, to: [{ label: curTo, value: currId }] };
              }
            }
            break;
          }
          case "city": {
            if (!!selectedCity) {
              emptyFilter = {
                ...emptyFilter,
                city: [{ label: selectedCity?.city_name, value: selectedCity?.city_id }],
              };
            }
            break;
          }
        }
      }
    }
    setFilter(emptyFilter);
    if (!!searchParams?.get("page")) {
      setPage(pages?.find((el) => el.url === searchParams.get("page")));
    } else {
      setPage(pages[0]);
    }
  }, []);

  useEffect(() => {
    setIsGood(null);
    setIsEntropyGood(null);
    if (!!page) {
      searchParams.set("page", page.url);
      setSearchParams(searchParams);
    }
  }, [page]);

  useEffect(() => {
    for (const key in filter) {
      if (!!filter[key] && key !== "type") {
        if (!!filter[key]?.length) {
          searchParams.set(key, JSON.stringify(filter[key]));
        }
        if (!filter[key]?.length) {
          searchParams.delete(key);
        }
      }
      if (key === "type" || key === "is_on_best_now") {
        if (!!filter[key]?.toString()?.length) {
          searchParams.set(key, filter[key]?.toString());
        } else {
          searchParams.delete(key);
        }
      }
      if (!filter[key]) {
        searchParams.delete(key);
      }
    }
    setSearchParams(searchParams);
  }, [filter]);

  useEffect(() => {
    if (firstUpdate) {
      setTimeout(() => setFirstUpdate(false), 2000);
    }
  }, [sites]);

  useEffect(() => {
    if (!firstUpdate) {
      if (filter.alien === "наши" && !!sites?.length) {
        const bestSites = sites
          ?.filter((el) => el?.is_our)
          ?.filter((el) => (filter.is_on_best_now ? el.is_on_best_now : true));
        setFilter({
          ...filter,
          sites: bestSites?.map((el) => ({ label: el.site_name, value: el.best_id })),
        });
        dispatch(
          setSelectedSite({
            site_id: bestSites[bestSites.length - 1]?.best_id,
            site_name: bestSites[bestSites.length - 1]?.site_name,
          })
        );
      }
      if (filter.alien !== "наши" && !!sites?.length) {
        setFilter({
          ...filter,
          sites: [],
        });
      }
    }
  }, [filter.alien, sites]);

  useEffect(() => {
    if (filter.is_on_best_now && !!sites?.length && !firstUpdate) {
      const bestSites = filter.sites?.filter(
        (el) => !!sites.find((el2) => el2?.best_id === el.value)?.is_on_best_now
      );
      setFilter({
        ...filter,
        sites: bestSites,
      });
      dispatch(
        setSelectedSite({
          site_id: bestSites[bestSites.length - 1]?.value,
          site_name: bestSites[bestSites.length - 1]?.label,
        })
      );
    }
  }, [filter.is_on_best_now, sites]);

  return (
    <div className="relative h-[90vh] w-full text-xs -mt-[20px]">
      <div className="relative -top-[12px] flex flex-wrap w-full gap-x-[18px] gap-y-8 px-[10px] lg:pt-[26px] py-4 text-sm bg-stale">
        {pages?.map((el) => (
          <button
            type="button"
            key={el.url}
            className={`px-8 py-4 rounded-sm duration-300 ${el?.access ? "block" : "hidden"} ${
              el.url === page?.url ? "bg-bg font-semibold" : "bg-[00000000] text-lightFont"
            }`}
            onClick={() => setPage(el)}>
            {el.title}
          </button>
        ))}
      </div>
      <div className="fixed top-[108px] right-[80px] p-2 rounded-sm border border-[#BABAC322] bg-[#F1F1FA] z-20">
        <button
          type="button"
          className={`bg-font rounded-sm w-[18px] h-[18px] text-xs text-white z-30`}
          onClick={() => setFilterOpen(!isFilterOpen)}>
          <div
            className={`duration-300 ${isFilterOpen ? "rotate-90" : "-rotate-90"}`}>{`\u25BC`}</div>
        </button>
      </div>
      <GraphFilter
        filter={filter}
        setFilter={setFilter}
        data={data}
        isFilterOpen={isFilterOpen}
        isLoading={isLoading}
        fetchGraph={fetchGraph}
        fetchEntropy={fetchEntropy}
        fetchLastAvailable={fetchLastAvailable}
        page={page}
        sites={sites}
        selectedFilter={selectedFilter}
        setSelectedNodes={setSelectedNodes}
        hierarchical={hierarchical}
        setHierarchical={setHierarchical}
        currenciesOptions={currenciesOptions}
        setEdgeLength={setEdgeLength}
        setEntropy={setEntropy}
      />
      {!isLoading &&
        isGood !== null &&
        ((!data?.nodes?.length && page?.url === "graph") ||
          (!availableData?.length && page?.url === "last-available")) && (
          <div className="w-full mx-auto absolute top-[50%] translate-y-[-50%]">
            {isGood ? (
              <GoodEmptiness message="Нет данных по выбранным параметрам" />
            ) : !isGood ? (
              <Emptiness message={"Ошибка сервиса"} />
            ) : null}
          </div>
        )}
      <div className="relative w-full h-[80vh]">
        {!!data && (
          <VectorsGraph
            data={data}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            hierarchical={hierarchical}
            filter={filter}
            edgeLength={edgeLength}
            isFilterOpen={isFilterOpen}
          />
        )}
        {!!entropy && page?.url !== "last-available" && (
          <div className="absolute top-0 backdrop-blur-sm rounded-lg pr-[20px] pl-[60px]">
            <Entropy entropy={entropy} isLoading={isLoading} isGood={isEntropyGood} />
          </div>
        )}
        {!!availableData?.length && page?.url === "last-available" && (
          <div className="absolute top-0">
            <LastAvailable data={availableData} filter={filter} sites={sites} />
          </div>
        )}
      </div>
      {isLoading && (
        <div className="absolute w-[95%] flex justify-center text-center top-[40%] z-20">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export type TTab = { title: string; access: boolean; url: string };
