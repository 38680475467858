import { ICity, ICurrencyPair, ISite } from "src/types/OnOffTimeline/common";

export enum ChangedSiteType {
  CREATED = "created",
  EDITED = "edited",
}

export interface ChangedSite extends ISite {
  group: string;
  pair: string;
  type: ChangedSiteType;
}

export interface ChangedPair extends ICurrencyPair {
  group: string;
}

interface GetNewCityFromChangesDataProps {
  sitesChanges: ChangedSite[];
  pairsChanges: ChangedPair[];
  existingCity: ICity;
}

export const getNewCityFromChangesData = ({
  sitesChanges,
  pairsChanges,
  existingCity,
}: GetNewCityFromChangesDataProps) => {
  const createdSites = sitesChanges.filter((item) => item.type === "created");
  const editedSites = sitesChanges.filter((item) => item.type === "edited");

  let pairs = [...existingCity.currency_pairs, ...pairsChanges];
  pairs = pairs.map((pair) => {
    let sites = [...pair.sites];
    const newSites = createdSites.filter(
      (item) =>
        item.pair === `${pair.currency_from.currency_name}_${pair.currency_to.currency_name}`
    );
    sites = sites.map((site) => {
      const index = editedSites.findIndex(
        (item) =>
          item.pair === `${pair.currency_from.currency_name}_${pair.currency_to.currency_name}` &&
          item.site_name === site.site_name
      );

      if (~index) {
        return editedSites[index];
      } else {
        return site;
      }
    });

    return { ...pair, sites: [...sites, ...newSites] };
  });

  const newCity: ICity = { ...existingCity, currency_pairs: pairs };

  return newCity;
};
