import { useEffect, useState } from "react";

type TProps = {
  label: string;
  arr: string[];
  value: string;
  edited?: boolean;
  updFn: (el: string) => void;
  placeholder: string;
  isColumn?: boolean;
  width?: number;
};

export const FieldTabs = ({
  label,
  arr,
  value,
  edited,
  updFn,
  placeholder,
  isColumn,
  width,
}: TProps) => {
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    setEdit(false);
  }, [value]);

  return (
    <div className={`${isColumn ? "flex flex-col" : "grid grid-cols-[110px_1fr] gap-8"}`}>
      <span className="text-lightFont">{label}</span>
      {edited ? (
        isEdit ? (
          <div className="relative -left-8 flex items-center flex-wrap gap-2 rounded-md border border-[#BABAC322] bg-[#BABAC311] px-4">
            {arr?.map((el) => (
              <button
                key={el}
                type="button"
                style={{
                  width: width ? `${width}px` : "fit-content",
                  backgroundColor: value === el ? `#40AEF055` : "transparent",
                }}
                className={`rounded-sm px-8 py-2`}
                onClick={async () => {
                  if (value !== el) {
                    updFn(el);
                  }
                  setEdit(false);
                }}>
                {el}
              </button>
            ))}
          </div>
        ) : (
          <button type="button" className="text-left" onClick={() => setEdit(true)}>
            {value || <span className="text-lightGray">{placeholder}</span>}
          </button>
        )
      ) : (
        <div>{value}</div>
      )}
    </div>
  );
};
