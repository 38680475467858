import { OnOffRow } from "./OnOffRow";

export const OnOffSite = ({
  items,
  siteId,
  allFilter,
  isAccess,
  add,
  isFull,
  sites,
  data,
  filter,
  setFilter,
  fetchSwitch,
}) => {
  const siteName = sites?.find((el) => el.site_id === siteId)?.site_name;

  return (
    <div className="flex flex-col gap-4">
      <div className="text-left px-8 font-semibold text-lightFont text-sm">{siteName}</div>
      <div className="frame4 p-2 -ml-2 max-h-[66vh] min-w-[230px] flex flex-col gap-4">
        {items
          ?.filter((el) => allFilter(el))
          ?.map((el) => (
            <OnOffRow
              key={`${el.site_id}-${el.vector?.join("-")}-${el.city_code}`}
              item={el}
              isAccess={isAccess}
              add={add}
              isFull={isFull}
              data={data}
            />
          ))}
      </div>
      {filter?.sites?.length !== 1 && (
        <button
          className="px-8 my-4 w-fit text-xs rounded-lg bg-light text-lightFont border border-lightGray"
          onClick={async () => {
            setFilter({ ...filter, sites: [{ label: siteName, value: siteId }] });
            await fetchSwitch({
              sites: [siteId],
              currencies_from: filter.from?.map((el) => el.value),
              currencies_to: filter.to?.map((el) => el.value),
              cities: filter.city?.map((el) => el.value),
            });
          }}>
          Показать больше...
        </button>
      )}
    </div>
  );
};
