type TProps = {
  firstTitle: string;
  first: string;
  haveSecond: boolean;
  haveThird: boolean;
  secondTitle: string;
  thirdTitle: string;
};

export const Progress = ({ firstTitle, first, haveSecond, secondTitle }: TProps) => {
  return (
    <div className="flex justify-between align-baseline m-auto header-sm text-center text-lightGray w-full mt-[10px]">
      <div
        className={`${
          firstTitle === "Вектор" || firstTitle === "Векторы" ? "w-[320px]" : "w-[275px]"
        } ${!!first?.length && "activeAnimation text-font"}`}>
        {first || firstTitle}
      </div>
      <div className={`w-[15px] ${first && "activeAnimation text-font"}`}>{`\u203A`}</div>
      <div
        className={`${
          secondTitle === "Вектор" || secondTitle === "Векторы" ? "w-[320px]" : "w-[275px]"
        } ${haveSecond && "activeAnimation text-font"}`}>
        {secondTitle}
      </div>
      {/*<div className={`w-[15px] ${haveSecond && "activeAnimation text-font"}`}>{`\u203A`}</div>*/}
      {/*<div className={`w-[255px] ${haveThird && "activeAnimation text-font"}`}>{thirdTitle}</div>*/}
    </div>
  );
};
