import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchRequest } from "src/helpers/fetchRequest";
import { pascal } from "src/helpers/strHelper";
import { LS } from "src/api/constants";
import tetrisApi from "src/api/tetris";
import switchApi from "src/api/onOff";
import { setGlobalDirections, setGlobalCurrencies } from "src/store/directionsReducer";
import { setNotific } from "src/store/mainReducer";
import { setExistTetris } from "src/store/tetrisReduser";
import { tabs, TTetrisTab } from "./components/tetrisConstants";
import { TetrisBoard } from "./TetrisBoard/TetrisBoard";
import { ExistList } from "./TetrisExist/ExistList";

export const Tetris = () => {
  const dispatch = useDispatch();
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const [page, setPage] = useState<TTetrisTab>();
  const updateAccess = !!page?.update_access && userAccess?.includes(page?.update_access);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGood, setIsGood] = useState(true);

  const fetchGlobalDirections = async () => {
    const { response, error } = await fetchRequest(switchApi.getGlobalDirections());
    if (response) {
      dispatch(setGlobalDirections(response.vectors));
      dispatch(setGlobalCurrencies(response.currencies));
    }
    if (error) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText, request: `Глобальные данные` })
      );
    }
  };

  const fetchItems = async () => {
    const { response, error, status } = await fetchRequest(
      tetrisApi[`getTetris${pascal(page?.page)}`](),
      {
        request: page?.title || "Ошибка",
      }
    );

    if (status === 204) {
      setIsGood(true);
      dispatch(setExistTetris([]));
      return;
    }

    if (response) {
      setIsGood(true);
      dispatch(setExistTetris(response));
    }
    if (error) {
      setIsGood(false);
      dispatch(setExistTetris([]));
    }
  };

  useEffect(() => {
    if (!!searchParams?.get("page")) {
      setPage(tabs?.find((el) => el.page === searchParams.get("page")));
    } else {
      setPage(tabs[0]);
    }
    fetchGlobalDirections();
  }, []);

  useEffect(() => {
    if (!!page) {
      fetchItems();
      searchParams.set("page", page.page);
      setSearchParams(searchParams);
      const timer = setInterval(async () => {
        await fetchItems();
      }, 15000);
      return () => clearInterval(timer);
    }
  }, [page]);

  return (
    <div
      className={`relative flex justify-center w-[calc(100% - 100px)] ml-[20px] mr-[70px] -mt-[16px] flex-col lg:grid lg:grid-cols-[4fr_3fr] items-start lg:top-[12px] 2xl:top-0`}>
      {!!page && (
        <TetrisBoard
          isAccess={updateAccess}
          fetchItems={fetchItems}
          page={page}
          setPage={setPage}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
      {!!page && (
        <ExistList
          isAccess={updateAccess}
          isGood={isGood}
          fetchItems={fetchItems}
          page={page}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
    </div>
  );
};
