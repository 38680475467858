"use client";

import { format, subDays } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "src/utils";
import { Button } from "src/shadcn/ui/ui/button";
import { Calendar } from "src/shadcn/ui/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "src/shadcn/ui/ui/popover";
import { DateRange } from "react-day-picker";
import { useState, useEffect } from "react";
import { getPreviousMonth } from "src/helpers/date";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/shadcn/ui/ui/select";
import { ru } from "date-fns/locale";

interface DatePickerWithRangeProps {
  className?: string;
  from?: Date;
  to?: Date;
  maxDate?: Date;
  withPresets?: boolean;
  preset?: Presets | null;
  onDateChange: (range: DateRange | undefined) => void;
}

export enum Presets {
  TODAY = "today",
  THIS_WEEK = "this_week",
  PREV_WEEK = "prev_week",
}

interface Preset {
  label: string;
  from: Date;
  to: Date;
}

export const dateRangePresets: Record<Presets, Preset> = {
  [Presets.TODAY]: {
    label: "За сегодня",
    from: new Date(),
    to: new Date(),
  },
  [Presets.THIS_WEEK]: {
    label: "За эту неделю",
    from: subDays(new Date(), 7),
    to: new Date(),
  },
  [Presets.PREV_WEEK]: {
    label: "За предыдущую неделю",
    from: subDays(new Date(), 14),
    to: subDays(new Date(), 7),
  },
};

export function DatePickerWithRange({
  className,
  from,
  maxDate,
  to,
  withPresets,
  preset,
  onDateChange,
}: DatePickerWithRangeProps) {
  const [date, setDate] = useState<DateRange | undefined>({
    from: from || dateRangePresets.this_week.from,
    to: to || dateRangePresets.this_week.to,
  });

  const handlePresetSelect = (val: Presets) => {
    setDate({
      from: dateRangePresets[val].from,
      to: dateRangePresets[val].to,
    });
  };

  useEffect(() => {
    onDateChange(date);
  }, [date]);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "max-w-[300px] w-full justify-start text-left font-normal text-grayDark px-[10px]",
              !date && "text-muted-foreground"
            )}>
            <CalendarIcon className="mr-[8px] h-[14px] w-[14px]" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "dd MMMM yyyy", { locale: ru })} -{" "}
                  {format(date.to, "dd MMMM yyyy", { locale: ru })}
                </>
              ) : (
                format(date.from, "LLL dd, y", { locale: ru })
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          {withPresets && (
            <div className="p-[6px] pb-0">
              <Select value={preset || ""} onValueChange={handlePresetSelect}>
                <SelectTrigger>
                  <SelectValue placeholder="Период" />
                </SelectTrigger>
                <SelectContent position="popper">
                  {Object.values(Presets).map((item) => (
                    <SelectItem key={item} value={item}>
                      {dateRangePresets[item].label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={getPreviousMonth()}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
            toDate={maxDate}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
