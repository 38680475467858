import { createSlice } from "@reduxjs/toolkit";
import { TCluster, TClusterSchedule } from "./clusterReducer";
import { StateT } from "./store";

export type TBlocks = {
  site_name: string;
  city_name: string;
  vector: string;
};

const tetrisSlice = createSlice({
  name: "tetris",
  initialState: {
    existTetris: [] as TTetris[],
    filters: {
      from: null,
      to: null,
      sites: null,
      cities: null,
    } as TFilters,
  },
  reducers: {
    setExistTetris: (state, action) => {
      state.existTetris = action.payload;
    },
    setFilters: (state, action) => {
      const attr = action.payload.attr;
      state.filters = {
        ...state.filters,
        [attr]: action.payload.value,
      };
    },
  },
});

export const { setExistTetris, setFilters } = tetrisSlice.actions;

export const selectExistTetris = (state: StateT): TTetris[] => state.tetris.existTetris;
export const selectFilters = (state: StateT): TFilters => state.tetris.filters;

export default tetrisSlice.reducer;

export type TTetris = TTetrisAutocorr | TBlocks | TCluster | TClusterSchedule;

export type TFilters = {
  cities: null | Array<{ label: string; value: string }>;
  sites: null | Array<{ label: string; value: string }>;
  from: null | Array<{ label: string; value: string }>;
  to: null | Array<{ label: string; value: string }>;
};

export type TTetrisAutocorr = {
  city_id: number;
  city_name: string;
  site_id: number;
  site_name: string;
  vector: string;
  percent: string;
  is_locked: boolean;
};
