import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { MinMaxCity } from "./MinMaxCity";
import { TGlobalCity } from "src/store/directionsReducer";

export const Sector = ({
  sector,
  globalsCities,
  setDirtyCities,
  updateFields,
  clearFields,
  dirtyCities,
  pending,
  isAccess,
}) => {
  const { watch, reset } = useFormContext();
  const sectorCities: TGlobalCity[] = useMemo(
    () =>
      globalsCities?.filter((city) =>
        sector === "Другие" ? city.sector === null : city.sector === sector
      ),
    [globalsCities]
  );
  const [generalCities, setGeneralCities] = useState<
    Array<TGlobalCity & { min_sum?: number; max_sum?: number }>
  >([]);
  const [regionCities, setRegionCities] = useState<
    Array<TGlobalCity & { min_sum?: number; max_sum?: number }>
  >([]);
  const [isMain, setIsMain] = useState<boolean>(true);
  const [isOpen, setOpen] = useState(true);

  const updateDirtyCities = (
    cities: Array<TGlobalCity & { min_sum?: number; max_sum?: number }>
  ) => {
    const arr = dirtyCities?.concat(cities?.map((el) => el.city_code));

    return [...new Set(arr)];
  };

  useEffect(() => {
    setIsMain(generalCities.length > 0);
  }, [generalCities]);

  const setAllMin = () => {
    if (isMain) {
      setDirtyCities(updateDirtyCities(generalCities));
      setGeneralCities(
        generalCities?.map((el) => ({
          ...el,
          min_sum: +watch(`${generalCities[0]?.city_code}.min_sum`),
          max_sum: +watch(`${el.city_code}.max_sum`),
        }))
      );
    }
    if (!isMain) {
      setDirtyCities(updateDirtyCities(regionCities));
      setRegionCities(
        regionCities?.map((el) => ({
          ...el,
          min_sum: +watch(`${regionCities[0]?.city_code}.min_sum`),
          max_sum: +watch(`${el.city_code}.max_sum`),
        }))
      );
    }
  };

  const setAllMax = () => {
    if (isMain) {
      setDirtyCities(updateDirtyCities(generalCities));
      setGeneralCities(
        generalCities?.map((el) => ({
          ...el,
          min_sum: +watch(`${el.city_code}.min_sum`),
          max_sum: +watch(`${generalCities[0]?.city_code}.max_sum`),
        }))
      );
    }
    if (!isMain) {
      setDirtyCities(updateDirtyCities(regionCities));
      setRegionCities(
        regionCities?.map((el) => ({
          ...el,
          min_sum: +watch(`${el.city_code}.min_sum`),
          max_sum: +watch(`${regionCities[0]?.city_code}.max_sum`),
        }))
      );
    }
  };

  useEffect(() => {
    if (sectorCities?.length > 0) {
      setGeneralCities(sectorCities?.filter((el) => el.is_region === false));
      setRegionCities(sectorCities?.filter((el) => el.is_region === true));
    }
    updateFields();
    reset();
  }, [sectorCities]);

  return (
    <div className={`shadow-base rounded-lg`}>
      <button
        onClick={() => setOpen(!isOpen)}
        type="button"
        className={`flex w-full ${
          isOpen ? "bg-font" : "bg-lightGray"
        } rounded-t lg:rounded-none text-white font-black mr-[5px] shadow`}>
        <div className="w-[100px]">{sector}</div>
        <div className="w-[calc(100%-200px)]">{!isOpen ? `\u25B2` : `\u25BC`}</div>
      </button>
      <div className="flex items-baseline pl-[10px] text-left">
        {isOpen && (
          <div>
            <div className="text-lightGray text-xs">{isMain ? "Основные" : "Регионы"}</div>
            <div className="switcher w-[50px] lg:w-[80px] mb-[10px]">
              <label
                style={{ backgroundColor: isMain ? "green" : "lightgrey" }}
                className={`switcher__label cursor-pointer hover:opacity-[0.9]`}>
                <input
                  className="switcher__check-input"
                  type="checkbox"
                  defaultChecked={isMain}
                  onClick={() => setIsMain(!isMain)}
                />
                <span className="switcher__checkbox"></span>
              </label>
            </div>
          </div>
        )}
        <div
          className={`w-[290px] sm:w-fit sm:min-w-[400px] ${
            isOpen ? "h-[100%] max-h-[100%] sm:pt-8" : "h-0 max-h-0"
          } overflow-hidden`}>
          {generalCities?.map((el, index) => (
            <MinMaxCity
              key={`${index}_general`}
              isVisible={isMain}
              city={sectorCities?.filter((el) => el.is_region === false)[index]}
              item={el}
              clearFields={clearFields}
              setAllMin={setAllMin}
              setAllMax={setAllMax}
              dirtyCities={dirtyCities}
              isAccess={isAccess}
              setDirtyCities={setDirtyCities}
              updateFields={updateFields}
              pending={pending}
              mainCity={generalCities[0]?.city_name}
            />
          ))}
          {regionCities?.map((el, index) => (
            <MinMaxCity
              key={`${index}_region`}
              isVisible={!isMain}
              city={sectorCities?.filter((el) => el.is_region === true)[index]}
              item={el}
              clearFields={clearFields}
              setAllMin={setAllMin}
              setAllMax={setAllMax}
              dirtyCities={dirtyCities}
              isAccess={isAccess}
              setDirtyCities={setDirtyCities}
              updateFields={updateFields}
              pending={pending}
              mainCity={regionCities[0]?.city_name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
