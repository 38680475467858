import { BASE_URL } from "./constants";

import api from "./http";
import {
  CopyAggPayload,
  IRates,
  UpdateBidResponse,
  UpdateBossRatePayload,
} from "src/pages/Bid/models";
import { TCurrencyTypes } from "src/pages/Text/types";

export default class bidApi {
  static async getLimits(cityId) {
    return api.get(`${BASE_URL}/rates/city_limit/${cityId}`);
  }
  static async setLimits(cityId, data) {
    return api.post(`${BASE_URL}/rates/city_limit/${cityId}`, data);
  }

  // new
  static async getRates(from: string, to: string, agg: string) {
    return api.get<IRates[]>(
      `${BASE_URL}/v1/bet_service/v1/bet/?currency_code_from=${from}&currency_code_to=${to}&aggregator_name=${agg}`
    );
  }

  static async updateBidData(payload: IRates) {
    return api.patch<UpdateBidResponse>(`${BASE_URL}/v1/bet_service/v1/bet/`, payload);
  }

  static async updateBossRate(payload: UpdateBossRatePayload) {
    return api.patch(`${BASE_URL}/v1/bet_service/v1/bet/boss-rate/`, payload);
  }

  static async copyAggregator(payload: CopyAggPayload) {
    return api.patch(`${BASE_URL}/v1/bet_service/v1/bet/aggregator-copy/`, payload);
  }
}

// todo: удалить моки
// моки были использованы как временное решение, пока нет ручки на globals
// как только ручка появится, моки будут заменены на данные с сервера
export type MockCurrency = {
  currency_code: string;
  currency_type: TCurrencyTypes;
};

export const curreniesMock: MockCurrency[] = [
  {
    currency_code: "CASHRUB",
    currency_type: "cash",
  },
  {
    currency_code: "CASHEUR",
    currency_type: "cash",
  },
  {
    currency_code: "CASHUSD",
    currency_type: "cash",
  },
  {
    currency_code: "USDTTRC",
    currency_type: "crypto",
  },
];

export const mockAgg = ["Exnode", "Bestchange"];
