import { useEffect, useState } from "react";
import { cutter } from "src/helpers/currencyHelper";
import { sourceColor } from "src/helpers/tetrisHelper";
import { MiniSpinner } from "src/components/Spinner/MiniSpinner";
import ok from "src/assets/images/check.svg";

type TProps = {
  item: string;
  favorites: Array<string>;
  removeItem: (el: string) => void;
};

export const FavRow = ({ item, favorites, removeItem }: TProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const favSubStr = item?.split(" - ");

  useEffect(() => {
    setIsLoading(false);
  }, [favorites]);

  return (
    <button
      className="w-full flex items-center justify-between"
      onClick={() => {
        setIsLoading(true);
        removeItem(item);
      }}>
      <div className="flex items-center">
        {isLoading ? (
          <div className="max-h-[20px] max-w-[20px] relative left-[-5px]">
            <MiniSpinner />
          </div>
        ) : (
          <span className="relative left-[-5px] hover:opacity-80">
            <img
              src={ok}
              alt="выключить"
              width={16}
              height={16}
              className="min-w-[16px] h-[16px]"
            />
          </span>
        )}
        <div className="flex items-center ml-4 text-sm">
          <div className="font-bold">{cutter(favSubStr[0])}</div>
          <div className="text-lightGray mx-4">{`\u279C`}</div>
          <div className="font-bold">{cutter(favSubStr[1])}</div>
        </div>
      </div>
      {!!favSubStr[2] && (
        <div
          className={`badge text-[9px] leading-3 py-[1px] px-[5px] mb-0`}
          style={{ backgroundColor: sourceColor(favSubStr[2]) }}>
          {favSubStr[2]}
        </div>
      )}
    </button>
  );
};
