import { useState } from "react";
import { TSelectedPair } from "src/store/onOffReducer";
import SelectedPairCard from "src/pages/Tetris/MiniTetris/components/SelectedCard";
import search from "src/assets/images/search.svg";
import { charFix, translit } from "src/helpers/formHelpers";
import { cn } from "src/utils";

const columns = ["Сайт", "Город", "From", "To"];

export const SelectedPairs = ({
  emptyMessage,
  selectedPairs,
  removeItem,
  removeAll,
  className,
}: {
  emptyMessage?: string;
  className?: string;
  selectedPairs: TSelectedPair[];
  removeItem: (id: string) => void;
  removeAll: () => void;
}) => {
  const [filterStr, setFilterStr] = useState("");

  return (
    <div
      className={cn(
        "w-[476px] frame square-scroll rounded-sm bg-[#F5F5FD] flex flex-col gap-8 py-8 overflow-y-auto h-full max-h-[300px] text-sm",
        className
      )}
      style={{
        height: "auto",
        margin: "10px 0",
      }}>
      <small className="text-lightFont leading-4 whitespace-pre opacity-80 pl-[6px]">
        {`Перед тем, как нажать "Подтверить",\nпроверьте выбранные пары`}
      </small>
      <div className="flex w-full relative">
        <input
          className="text-xs w-full border border-[#EEE] rounded-sm outline-none mx-4 px-8 h-[25px]"
          placeholder="Поиск"
          onChange={(e) => setFilterStr(e.target.value)}
        />
        <img
          src={search}
          alt="Найти"
          width={16}
          height={16}
          className="absolute top-4 right-[16px] opacity-30"
        />
      </div>
      <div className="flex items-center justify-between px-8">
        <h3 className="font-bold">Выбранные пары</h3>
        <button type="button" onClick={removeAll} className="text-lightFont">
          удалить все
        </button>
      </div>
      <div className="flex flex-col">
        <div className="grid grid-cols-[80px_160px_1fr_1fr] gap-8 w-full px-8">
          {columns.map((column) => (
            <span key={column} className="flex text-xs font-medium pl-[10px]">
              {column}
            </span>
          ))}
        </div>
        {selectedPairs.length === 0 && (
          <div className="flex w-full items-center justify-center py-[6px] text-[14px]">
            {emptyMessage || "Вы еще не создали пары"}
          </div>
        )}
        {selectedPairs
          ?.filter((pair: TSelectedPair) => {
            if (!!filterStr?.length) {
              const pairStr = JSON.stringify(pair)?.toLowerCase();
              const str = filterStr?.toLowerCase();
              return (
                pairStr?.includes(str) ||
                pairStr?.includes(charFix(str, true)) ||
                pairStr?.includes(charFix(str, false)) ||
                pairStr?.includes(translit(str))
              );
            } else {
              return true;
            }
          })
          ?.map((pair: TSelectedPair) => (
            <SelectedPairCard
              key={pair.id}
              selectedPair={pair}
              onDelete={() => removeItem(pair.id)}
            />
          ))}
      </div>
    </div>
  );
};
