import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LS } from "src/api/constants";
import { RateItem, selectFavorites, selectRates, setFavorites } from "src/store/statsReducer";
import { FavRow } from "./components/FavRow";
import { RateRow } from "./components/RatesRow";
import search from "src/assets/images/search.svg";
import { sourceColor } from "src/helpers/tetrisHelper";
import { fetchRequest } from "src/helpers/fetchRequest";
import exchangersApi from "src/api/exchangers";
import { Exchanger, ToggleExchangerResponse } from "src/types/exchangers";
import { Switch } from "src/shadcn/ui/ui/switch";

export const Rates = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectRates);
  const favorites = useSelector(selectFavorites);
  const [searchInput, setSearchInput] = useState<string>();
  const [filteredArr, setFilteredArr] = useState<RateItem[]>([]);
  const favItems = [0, 1, 2, 3, 4, 5];
  const [exchangers, setExchangers] = useState<Exchanger[]>([]);
  const [exchangersLoading, setExchangersLoading] = useState(false);
  const [pendingExchangers, setPendingExchangers] = useState<Set<number>>(new Set());

  const fetchExchangers = async () => {
    setExchangersLoading(true);
    const { response } = await fetchRequest<Exchanger[] | undefined>(
      exchangersApi.getExchangers(),
      {
        request: "Обменники",
      }
    );
    if (response && Array.isArray(response)) {
      setExchangers(response);
    }
    setExchangersLoading(false);
  };

  const toggleExchanger = async (payload: ToggleExchangerResponse) => {
    setPendingExchangers((prev) => {
      prev.add(payload.exchanger_id);
      return new Set(prev);
    });
    const { response } = await fetchRequest(exchangersApi.toggleExchanger(payload), {
      request: `${payload.exchanger_is_broadcast ? "Отключение" : "Включение"} биржи`,
    });
    if (response) {
      setExchangers((prev) =>
        prev.map((item) =>
          item.exchanger_id === response.exchanger_id
            ? { ...item, ...response }
            : {
                ...item,
                exchanger_is_broadcast: response.exchanger_is_broadcast
                  ? false
                  : item.exchanger_is_broadcast,
              }
        )
      );
    }
    setPendingExchangers((prev) => {
      prev.delete(payload.exchanger_id);
      return new Set(prev);
    });
  };

  const removeItem = (item: string) => {
    if (favorites?.length) {
      localStorage.setItem(LS.FAVORITES, favorites?.filter((el) => el !== item)?.join(","));
      dispatch(setFavorites(favorites?.filter((el) => el !== item)));
    }
  };

  useEffect(() => {
    fetchExchangers();
  }, []);

  useEffect(() => {
    if (data?.length) {
      if (searchInput?.length) {
        setFilteredArr(findInValues(data, searchInput));
      } else {
        setFilteredArr(data);
      }
    }
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInput?.length) {
        setFilteredArr(findInValues(data, searchInput));
      }
      if (searchInput?.length === 0) {
        setFilteredArr(data);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchInput]);

  function findInValues(arr: RateItem[], value: string) {
    value = String(value).toLowerCase();
    return arr.filter((o) =>
      Object.entries(o).some((entry) => String(entry[1]).toLowerCase().includes(value))
    );
  }

  const groupByExchanger = (data: RateItem[]) => {
    const groups: Record<string, RateItem[]> = {};

    for (const item of data) {
      if (!groups[item.exchanger_name]) groups[item.exchanger_name] = [];
      groups[item.exchanger_name].push(item);
    }

    return groups;
  };

  const groupedData = groupByExchanger(filteredArr);

  return (
    <div className="w-full md:mx-[20px] md:ml-[40px] md:mr-[80px] flex flex-col-reverse md:flex-row justify-between items-center md:items-start">
      {/*курсы*/}
      <div className="md:ml-[20px] flex flex-col items-center md:items-start">
        <div className="flex items-center mb-[10px]">
          <div className="w-[289px] md:w-[350px] flex items-center relative">
            <input
              className="text-sm w-full border border-lightGray rounded-lg outline-none h-[40px] py-4 px-8"
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <img
              src={search}
              alt="Найти"
              width={20}
              height={20}
              className="absolute right-[10px]"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:flex-wrap mb-[60px] gap-5">
          {Object.keys(groupedData)
            .sort((a) => {
              const selectedExchanger = exchangers.find(
                (item) => item.exchanger_is_broadcast
              )?.exchanger_name;
              return a === selectedExchanger ? -1 : 1;
            })
            .map((exchanger) => (
              <div key={exchanger} className="flex flex-col">
                <p
                  className="mb-[12px] badge text-[14px] font-semibold mr-5 max-w-[600px]"
                  style={{ backgroundColor: sourceColor(exchanger) }}>
                  {exchanger}
                </p>
                <div className="flex flex-col md:flex-row md:flex-wrap max-w-[1300px]">
                  {groupedData[exchanger].map((el, index) => (
                    <RateRow key={index} item={el} favorites={favorites} removeItem={removeItem} />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/*favorites*/}
      <div className="flex flex-col gap-5">
        <div className="w-[289px] md:min-w-[260px] md:w-[260px] h-[220px] mb-[15px] md:mr-[120px]">
          <div
            className="table w-full h-full p-[20px]"
            style={{ display: "flex", alignItems: "center" }}>
            {favorites?.length ? (
              <div className="w-full">
                {favItems?.map((el) => (
                  <div
                    key={el}
                    className="rounded-[13px] border border-select px-8 my-4 h-[26px] w-full flex items-center bg-glass-select">
                    {favorites && favorites[el] ? (
                      <FavRow item={favorites[el]} favorites={favorites} removeItem={removeItem} />
                    ) : (
                      <div className="text-[10px] text-lightGray w-full">Вектор не добавлен</div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full text-sm text-lightGray text-center">
                В Вашем избранном еще ничего нет. Добавьте до 6-ти векторов, чтобы подписаться на
                свежие курсы
              </div>
            )}
          </div>
        </div>
        <div className="table grid grid-cols-2 gap-2 w-[289px] md:min-w-[260px] md:w-[260px] md:mr-[120px] py-[16px] mb-4">
          {exchangersLoading && exchangers.length === 0 && <div>загрузка...</div>}
          {exchangers.map(({ exchanger_id, exchanger_name, exchanger_is_broadcast }) => (
            <div key={exchanger_id}>
              <div className="mb-4">{exchanger_name}</div>
              <Switch
                checked={exchanger_is_broadcast}
                disabled={pendingExchangers.has(exchanger_id)}
                onCheckedChange={(exchanger_is_broadcast) =>
                  toggleExchanger({ exchanger_id, exchanger_is_broadcast })
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
