import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LS } from "src/api/constants";
import { setLastViewed } from "src/store/mainReducer";
import { Feat } from "./components/Feat";
import { features } from "./features";

export const NewFeatures = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem(LS.LAST_VIEW, features[0].id);
    dispatch(setLastViewed(features[0].id));
  }, []);

  return (
    <div className="ml-[20px] mr-[50px] sm:mx-auto max-w-[600px] text-sm text-left -mt-[10px]">
      {features?.map((el) => (
        <Feat key={el.id} item={el} />
      ))}
    </div>
  );
};
