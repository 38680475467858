import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { LS } from "src/api/constants";
import minMaxApi from "src/api/minMax";
import { setNotific } from "src/store/mainReducer";
import {
  selectAllSites,
  selectMinMaxSheet,
  setMinMaxSheet,
  TMinMaxCurrency,
  TMinMaxRegion,
  TMinMaxSheet,
} from "src/store/directionsReducer";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { SheetSite } from "./components/SheetSite";
import { Regions } from "./components/Regions";

export const MinMaxSheet = () => {
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");
  const access = user_access?.includes("minmax_post") as boolean;
  const dispatch = useDispatch();
  const sheet = useSelector(selectMinMaxSheet);
  const globalSites = useSelector(selectAllSites);
  const [regions, setRegions] = useState<Array<TMinMaxRegion>>([]);
  const [isGood, setIsGood] = useState<boolean | null>(null);
  const [currentRegion, setCurrentRegion] = useState<TMinMaxRegion>();
  const [copiedItem, setCopiedItem] = useState<TMinMaxCurrency | null>(null);
  const [selectedToPaste, setSelectedToPaste] = useState<
    Array<TMinMaxCurrency & { site_id: number }>
  >([]);
  const [copiedTime, setCopiedTime] = useState<TMinMaxSheet | null>(null);
  const [selectedToPasteTime, setSelectedToPasteTime] = useState<Array<TMinMaxSheet>>([]);
  const [sitesToSynchronize, setSitesToSynchronize] = useState<Array<number>>([]);
  const [isFullSync, setFullSync] = useState(false);

  const fetchRegions = async () => {
    const { response, error } = await fetchRequest(minMaxApi.getMinMaxSheetRegions(), {
      request: "Регионы",
    });
    if (response) {
      const preparedRegions = response?.map((el) => ({
        region_id: el.sector_id,
        region_name: el.sector_name,
        currencies: el.currencies,
      }));
      setRegions(preparedRegions);
      setCurrentRegion(preparedRegions[0]);
    }
    if (error) {
      setIsGood(false);
      setRegions([]);
    }
  };

  const fetchSheet = async () => {
    setIsGood(null);
    const { response, error } = await fetchRequest(
      minMaxApi.getMinMaxSheet(currentRegion?.region_id),
      { request: "Таблица" }
    );
    if (response) {
      dispatch(setMinMaxSheet(response));
      setIsGood(true);
    }
    if (error) {
      dispatch(setMinMaxSheet([]));
      setIsGood(false);
    }
  };

  const synchronize = async () => {
    const { response } = await fetchRequest(
      minMaxApi.synchronizeMinMaxSheet(currentRegion?.region_id, sitesToSynchronize),
      { request: "Таблица" }
    );
    if (response) {
      dispatch(
        setNotific({ type: "success", message: "Данные синхронизированы", request: "Таблица" })
      );
    }
    setSitesToSynchronize([]);
    setFullSync(false);
  };

  const pasteFn = async () => {
    const updatedSites = [...new Set(selectedToPaste?.map((el) => el.site_id))];
    const payload = updatedSites?.map((el) => {
      const currentSite = sheet?.find((site) => site.site_id === el);
      return {
        ...currentSite,
        currencies: currentRegion?.currencies.map((cur) => {
          const currentCurrency = currentSite?.currencies?.find(
            (currentCur) => currentCur.currency_id === cur.currency_id
          );
          if (!!currentCurrency) {
            const currentSheetItem = selectedToPaste?.find(
              (selected) => selected.sheet_id === currentCurrency.sheet_id
            );
            if (!!currentSheetItem) {
              return {
                ...copiedItem,
                sheet_id: currentCurrency?.sheet_id,
                ...cur,
              };
            } else {
              return currentCurrency;
            }
          }
        }),
      };
    });
    const { response } = await fetchRequest(minMaxApi.updateMinMaxSheet(payload), {
      request: "Обновление данных",
    });
    if (response) {
      if (copiedItem?.is_exceptions) {
        const exceptionPayload = selectedToPaste
          ?.map((el) =>
            copiedItem.exception_block?.map((exc) => {
              const preparedException = { ...exc };
              delete preparedException.exception_id;
              return { sheet_id: el.sheet_id, ...preparedException };
            })
          )
          ?.reduce((acc, value) => acc?.concat(value));
        const { response } = await fetchRequest(minMaxApi.updateMinMaxException(exceptionPayload), {
          request: "Обновление исключения",
        });
        if (response) {
          await fetchSheet();
        }
      } else {
        await fetchSheet();
      }
    }
    setCopiedItem(null);
    setSelectedToPaste([]);
  };

  const pasteTime = async () => {
    const { work_time_start, work_time_end } = copiedTime;
    const payload = selectedToPasteTime?.map((el) => ({ ...el, work_time_start, work_time_end }));
    const { response } = await fetchRequest(minMaxApi.updateMinMaxSheet(payload), {
      request: "Обновление времени",
    });
    if (response) {
      await fetchSheet();
    }
    setCopiedTime(null);
    setSelectedToPasteTime([]);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    if (!!currentRegion) {
      fetchSheet();
      setCopiedItem(null);
      setSelectedToPaste([]);
      setCopiedTime(null);
      setSelectedToPasteTime([]);
      setSitesToSynchronize([]);
      setFullSync(false);
    }
  }, [currentRegion]);

  useEffect(() => {
    if (!!copiedItem) {
      setCopiedTime(null);
      setSelectedToPasteTime([]);
    }
  }, [copiedItem]);

  useEffect(() => {
    if (!!copiedTime) {
      setCopiedItem(null);
      setSelectedToPaste([]);
    }
  }, [copiedTime]);

  return (
    <div
      className={`text-center w-full -mt-[34px] ml-[10px] mr-[100px] md:mr-[70px] pb-[40px] font-light`}>
      <div
        className={`fixed duration-300 top-[60px] rounded-sm px-[16px] py-4 right-[90px] bg-[#3BC57A] text-[#BBFFDA] font-semibold ${
          !!selectedToPaste?.length || !!selectedToPasteTime?.length
            ? "cursor-pointer"
            : "opacity-0"
        }`}
        onClick={async () => {
          if (!!selectedToPaste?.length) {
            await pasteFn();
          }
          if (!!selectedToPasteTime?.length) {
            await pasteTime();
          }
        }}>
        Продублировать
      </div>
      <div className="w-fit bg-bg z-[1] pt-[10px] mr-[50px] md:mr-[78px]">
        {!!regions?.length && !!currentRegion && (
          <Regions
            regions={regions}
            currentRegion={currentRegion}
            setCurrentRegion={setCurrentRegion}
          />
        )}
        {!!sheet?.length && (
          <div
            className={`flex items-center bg-[#BABAC322] divide-x divide-lightGray border border-lightGray text-sm`}>
            <div className="w-[210px] min-w-[210px] flex justify-center items-center">
              {access && (
                <Checkbox
                  checked={isFullSync}
                  onChange={() => {
                    if (!isFullSync) {
                      setFullSync(true);
                      setSitesToSynchronize(
                        sheet
                          ?.map((el) => el.site_id)
                          ?.filter(
                            (el) =>
                              globalSites?.find((site) => site.site_id === el)?.is_changable ||
                              false
                          )
                      );
                    } else {
                      setFullSync(false);
                      setSitesToSynchronize([]);
                    }
                  }}
                />
              )}
              {access && (
                <button
                  type="button"
                  className={`duration-300 rounded-sm px-[16px] py-4 bg-[#3BC57A] text-[#BBFFDA] font-semibold ${
                    !!sitesToSynchronize?.length ? "cursor-pointer" : "opacity-0"
                  }`}
                  disabled={!sitesToSynchronize?.length}
                  onClick={synchronize}>
                  Синхронизировать
                </button>
              )}
            </div>
            <div className="grid grid-flow-col grid-cols-auto auto-cols-[200px] items-center divide-x divide-lightGray">
              {currentRegion?.currencies?.map((el) => (
                <div key={el.currency_id}>
                  <div className="py-4 font-semibold">{el.currency_name}</div>
                  <div className="pb-2 grid grid-cols-2 text-[10px] leading-3">
                    <div>ОФИС</div>
                    <div>РЕГИОН</div>
                  </div>
                </div>
              ))}
              <div>
                <div className="py-4 font-semibold">WORKTIME</div>
                <div className="pb-2 grid grid-cols-2 text-[10px] leading-3">
                  <div>START</div>
                  <div>END</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-fit xl:w-full max-h-[80vh] overflow-y-scroll">
        {!!currentRegion &&
          sheet?.map((el) => (
            <SheetSite
              key={el.site_name}
              item={el}
              region={currentRegion}
              fetchSheet={fetchSheet}
              access={access}
              copiedItem={copiedItem}
              setCopiedItem={setCopiedItem}
              selectedToPaste={selectedToPaste}
              setSelectedToPaste={setSelectedToPaste}
              sitesToSynchronize={sitesToSynchronize}
              setSitesToSynchronize={setSitesToSynchronize}
              copiedTime={copiedTime}
              setCopiedTime={setCopiedTime}
              selectedToPasteTime={selectedToPasteTime}
              setSelectedToPasteTime={setSelectedToPasteTime}
              isChangable={
                globalSites?.find((site) => site.site_id === el.site_id)?.is_changable || false
              }
            />
          ))}
      </div>
      {!sheet?.length && isGood === false && (
        <div className="mt-[80px]">
          <Emptiness message={"Ошибка сервиса"} />
        </div>
      )}
    </div>
  );
};
