import { OnOffDataEntityType } from "src/types/OnOffTimeline/store";
import { TGlobalCurrency } from "src/store/directionsReducer";
import { ESource } from "src/store/onOffReducer";

export interface ITimeSegment {
  id?: number;
  start: string;
  end: string;
  creator?: string;
  isUnsubmitted?: boolean;
  deleted_flag?: boolean;
}

export interface OnOffSignal {
  id?: ITimeSegment["id"];
  site_name: string;
  city_code: string;
  platform_name: ESource;
  start: string;
  end: string;
  currency_from_name: string;
  currency_to_name: string;
}

export interface OnOffSignalToDelete {
  id: ITimeSegment["id"];
  city_code: string;
  currency_from_name: string;
  currency_to_name: string;
  site_name: string;
  platform_name: ESource;
}

export interface ISite {
  site_id: number;
  site_name: string;
  segments_site: ITimeSegment[];
  segments_best: ITimeSegment[];
  unsubmitted_segments_site: ITimeSegment[];
  unsubmitted_segments_best: ITimeSegment[];
  is_off?: boolean;
}

export interface ICurrencyPair {
  currency_from: TGlobalCurrency;
  currency_to: TGlobalCurrency;
  sites: ISite[];
}

export interface ICity {
  city_code: string;
  city_name: string;
  currency_pairs: ICurrencyPair[];
}

export interface ICityWithGroups {
  city_code: string;
  city_name: string;
  groups: ICityPairsGroup[];
}

export interface ICityPairsGroup {
  groupName: CurrencyPairsGroup;
  groupedPairs: CurrencyPairsGroupedByFrom[];
}

export enum CurrencyPairsGroup {
  CASH_CRYPTO = "CASH-CRYPTO",
  CRYPTO_CASH = "CRYPTO-CASH",
  AUTO = "AUTO",
}

export interface CurrencyWithSites {
  currency: TGlobalCurrency;
  sites: ISite[];
}

export interface CurrencyPairsGroupedByFrom {
  currency_from: TGlobalCurrency;
  currencies_to: CurrencyWithSites[];
  groupName: string;
}

export enum OnOffItemToDeleteType {
  CITY = "city",
  GROUP = "group",
  CURRENCY_GROUP = "currency_group",
  PAIR = "pair",
  SITE = "site",
}

export interface OnOffItemToDelete {
  type: OnOffItemToDeleteType;
  name: string;
  label: string;
}

export interface SelectIsEditedBase {
  type: OnOffDataEntityType;
}

export interface SelectIsEditedCity extends SelectIsEditedBase {
  type: OnOffDataEntityType.CITY;
  cityCode: string;
}

export interface SelectIsEditedGroup extends SelectIsEditedBase {
  type: OnOffDataEntityType.GROUP;
  cityCode: string;
  groupName: string;
}

export interface SelectIsEditedFromGroup extends SelectIsEditedBase {
  type: OnOffDataEntityType.FROM_PAIR;
  cityCode: string;
  from: TGlobalCurrency;
}

export interface SelectIsEditedPair extends SelectIsEditedBase {
  type: OnOffDataEntityType.PAIR;
  cityCode: string;
  from: Partial<TGlobalCurrency>;
  to: Partial<TGlobalCurrency>;
}

export type SelectIsEditedProps =
  | SelectIsEditedCity
  | SelectIsEditedGroup
  | SelectIsEditedFromGroup
  | SelectIsEditedPair;
