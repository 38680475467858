import { ReactElement } from "react";

export type TTextGetPayload = {
  site_id: number;
  type_id: number;
  city_id?: number;
  currency_from_id?: number;
  currency_to_id?: number;
  lang: string;
};

export type TTemplatesGetPayload = {
  site_id: number;
  type_id: number;
  currency_from_type: TCurrencyTypes;
  currency_to_type: TCurrencyTypes;
};

export type TTextUpdateResponse = {
  id: number;
  created_at: string; //"2023-08-24 13:25:45.059454"
  created_by_user: string;
  text: string;
  currency_from: number;
  currency_to: number;
  city_id: number;
  site_id: number;
  type_id: number;
  is_deleted: boolean;
  lang: string;
  lang_id: number;
};

export type TTextTypeExist = {
  type_id: number;
  text_subtype: TextSubType | null;
};

export enum TextType {
  BEFORE = "before",
  AFTER = "after",
  MAIL = "mail",
  DETAILS = "bid_details",
  MAIL_SUBJECT = "mail_subject",
}

export type TTextType = {
  id: number;
  name_ru: string;
  name_en: TextType;
};

export enum TextSubType {
  REGISTRATION = "REGISTRATION",
  ACCOUNT_RECOVERY = "ACCOUNT RECOVERY",
  SUPPORT_AUTO_RESPONSE = "SUPPORT AUTO RESPONSE",
  EXCHANGE_REQUEST = "EXCHANGE REQUEST",
  COMPLETED_AUTO_BID = "COMPLETED AUTO BID",
}

export type TTextSubType = {
  id: number;
  text_subtype_name_ru: string;
  text_subtype_name_en: TextSubType;
};

export type TTextSubTypeList = {
  name: TextType;
  data: TTextSubType[];
};

export type TTextNotDirections = {
  site_id: number;
  languages: TTextLang[];
};

export type TTextLang = {
  lang: string;
  types: TTextTypeExist[];
};

export type TCurrencyTypes = "cash" | "crypto" | "card" | "cash-in";

export type TVectorType = {
  currency_from_type: TCurrencyTypes;
  currency_to_type: TCurrencyTypes;
};

export type TTextVectors = {
  currency_from_id: number;
  currency_from_name: string;
  currency_from_type: TCurrencyTypes;
  currency_to_id: number;
  currency_to_name: string;
  currency_to_type: TCurrencyTypes;
  is_on_site: boolean;
  languages: TTextLang[];
};

export type TTextCities = {
  city_id: number;
  city_code: string;
  city_name: string;
  vectors: TTextVectors[];
};

export type TTextDirectionGet = {
  site_id: number;
  cities: TTextCities[];
};

export type TCitySelect = {
  label: ReactElement;
  title: string;
  value: number;
  isNotOnSite: boolean;
  isNoTexts: boolean;
};

export type TDirectionSelect = {
  label?: ReactElement;
  title: string;
  value: {
    title: string;
    city_id: number;
    lang: string;
    currency_from_id: number;
    currency_from_name: string;
    currency_from_type: TCurrencyTypes;
    currency_to_id: number;
    currency_to_name: string;
    currency_to_type: TCurrencyTypes;
  };
};

export type TPreparedVector = TTextVectors & {
  city_name: string;
  city_id: number;
  city_code: string;
};

export type TTemplate = {
  id: number;
  text: string;
  created_at?: string; // "2023-05-15T14:09:05.248Z"
  created_by_user?: string;
  site_id?: number;
  type_id?: number;
  from_type: TCurrencyTypes;
  to_type: TCurrencyTypes;
};
export type TText = {
  id?: number;
  created_at?: string; // "2023-05-15T17:39:27.572Z"
  created_by_user: string;
  text: string;
};
export type TAlias = {
  id: number;
  title: string;
  value: string;
};
