import { BASE_URL } from "./constants";
import api from "./http";

export default class addApi {
  static async addCurrency(data) {
    return api.post(`${BASE_URL}/pair_builder/currency_builder/`, data);
  }
  static async deleteCurrency(currency_id) {
    return api.delete(`${BASE_URL}/pair_builder/currency_builder/${currency_id}`);
  }
  static async getSites() {
    return api.get(`${BASE_URL}/pair_builder/site_builder/sites`);
  }
  static async updateSite(data) {
    return api.patch(`${BASE_URL}/pair_builder/site_builder/`, data);
  }
  static async updateSitePassword(data) {
    return api.patch(`${BASE_URL}/pair_builder/site_builder/password`, data);
  }
  static async updateSiteCoreToken(data) {
    return api.patch(`${BASE_URL}/pair_builder/site_builder/core-api-token`, data);
  }
  static async deleteSite(site_id) {
    return api.delete(`${BASE_URL}/pair_builder/site_builder/${site_id}`);
  }
  static async addSite(data) {
    return api.post(`${BASE_URL}/pair_builder/site_builder/`, data);
  }
  static async addException(data) {
    return api.post(
      `${BASE_URL}/pair_builder/site_builder/exceptions/?site_id=${data.site_id}&city_id=${data.city_id}`
    );
  }
  static async updateException(data) {
    return api.post(`${BASE_URL}/pair_builder/site_builder/exceptions/?site_id=${data.site_id}`, [
      {
        city_id: data.city_id,
        data: data.data,
      },
    ]);
  }
  static async deleteException(data) {
    return api.delete(
      `${BASE_URL}/pair_builder/site_builder/exceptions/?site_id=${data.site_id}&city_id=${data.city_id}`
    );
  }
  static async addCity(data) {
    return api.post(`${BASE_URL}/pair_builder/city_builder/`, data);
  }
  static async deleteCity(city_id) {
    return api.delete(`${BASE_URL}/pair_builder/city_builder/${city_id}`);
  }
  static async checkSiteDistributor(site_id) {
    return api.get(`${BASE_URL}/pair_builder/site_builder/check-distributor/${site_id}`);
  }
  static async getCountries() {
    return api.get(`${BASE_URL}/pair_builder/country/`);
  }
  static async getCountriesToCitiesAndCurrencies() {
    return api.get(`${BASE_URL}/pair_builder/country/cities_and_currencies`);
  }
  static async createCountry(body) {
    return api.post(`${BASE_URL}/pair_builder/country/`, body);
  }
  static async deleteCountry(country) {
    return api.delete(`${BASE_URL}/country-builder/${country}`);
  }
  static async getUsers() {
    return api.get(`${BASE_URL}/user/`);
  }
  static async addUser(data) {
    return api.post(`${BASE_URL}/user/`, data);
  }
  static async updateUser(data) {
    return api.patch(`${BASE_URL}/user/`, data);
  }
  static async deleteUser(userId) {
    return api.delete(`${BASE_URL}/user/${userId}`);
  }
  static async createVectors(body) {
    return api.post(`${BASE_URL}/pair_builder/vectors/`, body);
  }
}
