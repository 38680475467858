import { Dispatch, SetStateAction, memo } from "react";
import { TTimeline, TTimelineDeletePayload } from "src/store/clusterReducer";
import { TimelineVector } from "./TimelineVector";
import { AxisX } from "./AxisX";

type TProps = {
  item: TTimeline;
  axisX: Array<number>;
  items: Array<TTimeline>;
  isAdded: string;
  setIsAdded: Dispatch<SetStateAction<string>>;
  zoom: number;
  step: number;
  expand: boolean;
  deleteIt: (payload: TTimelineDeletePayload, saveRows: boolean) => void;
  visibleCities: Array<number>;
  visibleVectors: Array<string>;
  isBest: boolean;
  nowTime: number;
  username: string;
  actionsCount: number;
  setActionsCount: Dispatch<SetStateAction<number>>;
  activeTemplate: number | null;
  setTemplateData: Dispatch<SetStateAction<Array<TTimeline>>>;
};

export const TimelineCity = memo(
  ({
    item,
    axisX,
    items,
    isAdded,
    setIsAdded,
    zoom,
    step,
    expand,
    deleteIt,
    isBest,
    visibleCities,
    visibleVectors,
    nowTime,
    username,
    actionsCount,
    setActionsCount,
    activeTemplate,
    setTemplateData,
  }: TProps) => {
    const opened = visibleCities?.includes(item?.city_id);

    return (
      <div id={`${item.city_id}_city`} className={`min-h-[67px]`}>
        <AxisX axisX={axisX} nowTime={nowTime} zoom={zoom} step={step} />
        {opened &&
          item?.vectors
            ?.filter((unfilteredEl, unfilteredIndex) => {
              const isNotFirstTo =
                unfilteredEl.currency_from_id !==
                item?.vectors[unfilteredIndex - 1]?.currency_from_id;
              const isVectorVisible = visibleVectors?.includes(
                `${item.city_id}_${unfilteredEl?.currency_from_id}`
              );
              return opened && (isVectorVisible || isNotFirstTo || +unfilteredEl?.percent === 0);
            })
            ?.map((el, index) => {
              const key = `${item.city_id}_${el.currency_from_id}_${el.currency_to_id}_${el.percent}_${index}_board`;
              return (
                <TimelineVector
                  key={key}
                  id={index}
                  item={el}
                  cityId={item.city_id}
                  axisX={axisX}
                  items={items}
                  isBest={isBest}
                  isAdded={isAdded}
                  setIsAdded={setIsAdded}
                  zoom={zoom}
                  step={step}
                  expand={expand}
                  deleteIt={deleteIt}
                  opened={opened}
                  visibleVectors={visibleVectors}
                  isNotFirstFrom={
                    el.currency_from_id === item?.vectors[index - 1]?.currency_from_id
                  }
                  username={username}
                  actionsCount={actionsCount}
                  setActionsCount={setActionsCount}
                  activeTemplate={activeTemplate}
                  setTemplateData={setTemplateData}
                />
              );
            })}
        <div className="h-[25px] max-h-[25px]" />
      </div>
    );
  }
);
