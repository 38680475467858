import { useState } from "react";
import { TNetwork, TWallet } from "src/store/commonReducer";
import { AddButton } from "src/components/UI/buttons/AddButton";

type TProps = {
  isLoading: boolean;
  updFn: (TWallet) => void;
  networks: TNetwork[];
  networkColor: (TNetwork) => string;
};

export const CreateWallet = ({ isLoading, updFn, networks, networkColor }: TProps) => {
  const [isAdd, setIsAdd] = useState(false);
  const [newWallet, setNewWallet] = useState<TWallet>({
    wallet_name: "",
    wallet_address: "",
    wallet_network: "btc",
  });
  const disabled = isLoading || !newWallet?.wallet_name.length || !newWallet.wallet_address.length;

  return (
    <div className="w-full pb-[10px] text-left">
      <AddButton onClick={() => setIsAdd(!isAdd)} title={"Добавить кошелек"} />
      <div
        className={`duration-500 overflow-hidden flex flex-col gap-8 text-xs px-[10px] ${
          isAdd ? "max-h-[1500px] opacity-100 my-8 py-[12px]" : "max-h-0 opacity-0 mt-0 -mb-8 py-4"
        }`}>
        <div className="grid grid-cols-[110px_1fr] gap-8 items-baseline">
          <div className="font-medium text-lightFont whitespace-pre">назовите кошелек</div>
          <input
            className={`w-full h-[20px] px-4 font-light rounded-sm border border-lightGray`}
            value={newWallet.wallet_name}
            onChange={(e) => setNewWallet({ ...newWallet, wallet_name: e.target.value })}
          />
        </div>
        <div className="grid grid-cols-[110px_1fr] gap-8 items-baseline">
          <div className="font-medium text-lightFont whitespace-pre">адрес</div>
          <input
            className={`w-full h-[20px] px-4 font-light rounded-sm border border-lightGray`}
            value={newWallet.wallet_address}
            onChange={(e) => setNewWallet({ ...newWallet, wallet_address: e.target.value })}
          />
        </div>
        <div className="grid grid-cols-[110px_1fr] gap-8 items-baseline">
          <div className="font-medium text-lightFont whitespace-pre">сеть</div>
          <div className="flex gap-4">
            {networks?.map((el) => (
              <button
                key={el}
                type="button"
                style={{
                  backgroundColor:
                    newWallet.wallet_network === el ? `${networkColor(el)}55` : "transparent",
                }}
                className={`rounded-sm px-8 py-2`}
                onClick={() => setNewWallet({ ...newWallet, wallet_network: el })}>
                {el}
              </button>
            ))}
          </div>
        </div>
        <button
          className={`bg-light self-end px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
            disabled ? "opacity-0" : ""
          }`}
          disabled={disabled}
          onClick={async () => {
            await updFn(newWallet);
            setIsAdd(false);
          }}>
          Добавить
        </button>
      </div>
    </div>
  );
};
