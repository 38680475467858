import AuthForm from "./components/Form";

const Auth = () => {
  return (
    <div className="pb-[50px]">
      <span className="text-[50px] font-bold text-[#282c3433]">Войти</span>
      <AuthForm />
    </div>
  );
};
export default Auth;
