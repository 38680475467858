export const ErrorTab = ({ item, count, activeTab, setActiveTab }) => {
  return (
    <button
      type="button"
      style={{ color: item[1].color }}
      className={`flex gap-8 items-center duration-300 pl-[10px] py-2 rounded-sm hover:bg-bg ${
        activeTab === item[0] ? "font-bold" : ""
      } ${count > 0 ? "flex" : "hidden"}`}
      onClick={() => {
        if (activeTab === item[0]) {
          setActiveTab("");
        } else {
          setActiveTab(item[0]);
        }
      }}>
      <div className="text-[18px] font-black leading-3">{item[1].icon}</div>
      <div className="text-lightFont font-light">{count}</div>
      <div
        className={`whitespace-pre duration-300 ${
          activeTab === item[0]
            ? "max-w-[200px] overflow-auto pr-[10px]"
            : "max-w-0 overflow-hidden pr-0"
        }`}>
        {item[1].title}
      </div>
    </button>
  );
};
