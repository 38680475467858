import {
  ICityWithGroups,
  ICityPairsGroup,
  CurrencyPairsGroupedByFrom,
} from "src/types/OnOffTimeline/common";
import {
  ExpandedEntity,
  ExpandedStateLevel,
  OnOffExpandedState,
} from "src/types/OnOffTimeline/store";

interface CreateStateBase {
  level: ExpandedStateLevel;
  entitiesToExpand: string[];
  current: object;
  selector?: string;
}

interface CreateStateRoot extends CreateStateBase {
  level: ExpandedStateLevel.ROOT;
  current: OnOffExpandedState;
  data: ICityWithGroups[];
}

interface CreateStateCity extends CreateStateBase {
  level: ExpandedStateLevel.CITY;
  current: ExpandedEntity;
  data: ICityWithGroups;
}

interface CreateStateGroup extends CreateStateBase {
  level: ExpandedStateLevel.GROUP;
  current: ExpandedEntity;
  data: ICityPairsGroup;
}

interface CreateStateGropedPair extends CreateStateBase {
  level: ExpandedStateLevel.GROUPED_PAIR;
  current: ExpandedEntity;
  data: CurrencyPairsGroupedByFrom;
}

type CreateStateProps =
  | CreateStateGropedPair
  | CreateStateGroup
  | CreateStateCity
  | CreateStateRoot;

export const createExpandedState = ({
  level,
  current,
  data,
  selector,
  entitiesToExpand,
}: CreateStateProps) => {
  switch (level) {
    case ExpandedStateLevel.ROOT:
      data.forEach((city) => {
        current[city.city_code] = {
          isExpanded: entitiesToExpand.includes(city.city_code),
        };

        createExpandedState({
          level: ExpandedStateLevel.CITY,
          data: city,
          current: current[city.city_code],
          entitiesToExpand,
          selector: city.city_code,
        });
      });
      break;

    case ExpandedStateLevel.CITY:
      if (!current.children) {
        current.children = {};
      }
      data.groups.forEach((group) => {
        current.children[group.groupName] = {
          isExpanded: entitiesToExpand.includes(`${selector}_${group.groupName}`),
        };

        createExpandedState({
          level: ExpandedStateLevel.GROUP,
          data: group,
          current: current.children[group.groupName],
          entitiesToExpand,
          selector: `${selector}_${group.groupName}`,
        });
      });
      break;

    case ExpandedStateLevel.GROUP:
      if (!current.children) {
        current.children = {};
      }
      data.groupedPairs.forEach((pair) => {
        const pairName = `${pair.currency_from.currency_name}`;
        current.children[pairName] = {
          isExpanded: entitiesToExpand.some((item) => item.startsWith(`${selector}_${pairName}`)),
        };

        createExpandedState({
          level: ExpandedStateLevel.GROUPED_PAIR,
          data: pair,
          current: current.children[pairName],
          entitiesToExpand,
          selector: `${selector}_${pairName}`,
        });
      });
      break;

    case ExpandedStateLevel.GROUPED_PAIR:
      if (!current.children) {
        current.children = {};
      }
      data.currencies_to.forEach((item) => {
        current.children[item.currency.currency_name] = {
          isExpanded: entitiesToExpand.includes(`${selector}_${item.currency.currency_name}`),
        };
      });
  }
};
