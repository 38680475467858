import { FC } from "react";
import { Url } from "src/api/constants";
// import { Stats } from "../Stats/Stats";
// import { Board } from "../Board/Board";
// import { Processing } from "../Processing/Processing";
// import { Reports } from "../Reports/Reports";
// import { ReportTab } from "../Reports/ReportTab";
import { Users } from "../Users/Users";
// import { Regulations } from "../Regulations/Regulations";
import { Wallets } from "../Wallets/Wallets";

export const controlRoutes = {
  // stats: {
  //   route: ["Статистика", Url.CONTROL_STATS, Stats],
  //   access: ["control_get", "merch_get"],
  //   children: null,
  // },
  // board: {
  //   route: ["Доска", Url.CONTROL_BOARD, Board],
  //   access: ["control_get"],
  //   children: null,
  // },
  // regulations: {
  //   route: ["Регламенты", Url.CONTROL_REGULATIONS, Regulations],
  //   access: ["control_get"],
  //   children: null,
  // },
  // bigbro: {
  //   route: ["Контроль чатов", Url.CONTROL_PROCESSING, Processing],
  //   access: ["merch_get"],
  //   children: null,
  // },
  wallets: {
    route: ["Кошельки", Url.CONTROL_WALLETS, Wallets],
    access: ["control_post"],
    children: null,
  },
  // site: {
  //   route: ["Сайты", Url.OUTLET_REPORTS, Reports],
  //   access: ["cash_control_get"],
  //   children: {
  //     text: {
  //       route: ["Тексты", `text`, ReportTab],
  //       children: null,
  //     },
  //     courses: {
  //       route: ["Курсы", `courses`, ReportTab],
  //       children: null,
  //     },
  //     minmax: {
  //       route: ["МинМаксы", `minmax`, ReportTab],
  //       children: null,
  //     },
  //   },
  // },
  // exnode: {
  //   route: ["ExNode", Url.EXNODE, Reports],
  //   access: ["cash_control_get"],
  //   children: {
  //     availability: {
  //       route: ["Наличие", `availability`, ReportTab],
  //       children: null,
  //     },
  //     courses: {
  //       route: ["Курсы", `courses`, ReportTab],
  //       children: null,
  //     },
  //     minmax: {
  //       route: ["МинМаксы", `minmax`, ReportTab],
  //       children: null,
  //     },
  //     reserve: {
  //       route: ["Резервы", `reserve`, ReportTab],
  //       children: null,
  //     },
  //   },
  // },
  // bestchange: {
  //   route: ["BestChange", Url.BESTCHANGE, Reports],
  //   access: ["cash_control_get"],
  //   children: {
  //     redirect: {
  //       route: ["Редиректы", `redirect`, ReportTab],
  //       children: null,
  //     },
  //     availability: {
  //       route: ["Наличие", `availability`, ReportTab],
  //       children: null,
  //     },
  //     delivery: {
  //       route: ["Доставка", `delivery`, ReportTab],
  //       children: null,
  //     },
  //     courses: {
  //       route: ["Курсы", `courses`, ReportTab],
  //       children: null,
  //     },
  //     minmax: {
  //       route: ["МинМаксы", `minmax`, ReportTab],
  //       children: null,
  //     },
  //     reserve: {
  //       route: ["Резервы", `reserve`, ReportTab],
  //       children: null,
  //     },
  //   },
  // },
  users: {
    route: ["Пользователи и чаты", Url.CONTROL_USERS, Users],
    access: ["control_post"],
    children: null,
  },
} as NestedObjects<TRoute>;

export type NestedObjects<T> = {
  [key: string]: T;
};

export type TRoute = {
  route: Array<string | FC>;
  access?: Array<string>;
  children: NestedObjects<{
    route: Array<string | FC>;
    children: null;
  }> | null;
};
