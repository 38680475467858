import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { LS } from "src/api/constants";
import { ESource, TSwitchSegment } from "src/store/onOffReducer";
import { addMissingGreenSegments, getChangesAfterCopy } from "src/helpers/onOffTimeline/copyPaste";
import { updateSiteSegments } from "src/store/onOffTimelineReducer/slice";
import Select, { MultiValue } from "react-select";
import { SourcesForm } from "src/pages/Panel/OnOff/OnOffTimeline/components/Sources/SourcesForm";
import { SelectedItem, UpdateSelectedSegmentsPayload } from "src/types/OnOffTimeline/store";
import { ITimeSegment } from "src/types/OnOffTimeline/common";
import { useAppSelector } from "src/store/store";
import {
  selectItemFormAllData,
  selectOpenAllSites,
} from "src/store/onOffTimelineReducer/selectors";
import { joinWithUnsubmitted } from "src/helpers/onOffTimeline/combine";
import { cn } from "src/helpers/cn";
import { setNotific } from "src/store/mainReducer";
import { areSegmentsEqual } from "src/helpers/onOffTimeline/areSegmentsEqual";
import { CheckCheck } from "lucide-react";

export type NewDataType = { segments_best: TSwitchSegment[]; segments_site: TSwitchSegment[] };

export const ItemForm = ({
  selectedItem,
  closeModal,
}: {
  selectedItem: SelectedItem;
  closeModal: () => void;
}) => {
  const dispatch = useDispatch();
  const username = localStorage.getItem(LS.USERNAME);

  const selector = `${selectedItem.city.city_code}_${selectedItem.currencyFrom.currency_name}_${selectedItem.currencyTo.currency_name}`;

  const all = useAppSelector((state) => selectItemFormAllData(state, selector));
  const allData = [...all]?.filter(
    (site) =>
      site.segments_site.length > 0 ||
      site.segments_best.length > 0 ||
      site.unsubmitted_segments_best.length > 0 ||
      site.unsubmitted_segments_site.length > 0
  );
  const openAllSites = useAppSelector(selectOpenAllSites);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [inited, setInited] = useState(false);

  const [state, setState] = useState<Record<string, Record<ESource, ITimeSegment[]>>>({});
  const [offValues, setOffValues] = useState<Record<string, Record<ESource, boolean>>>({});
  const [isLoading, setLoading] = useState(false);
  const [submitTried, setSubmitTried] = useState(false);

  const [selectedSites, setSelectedSites] = useState<MultiValue<{ label: string; value: string }>>(
    openAllSites
      ? allData.map((item) => ({ label: item.site_name, value: item.site_name }))
      : [{ label: selectedItem.site_name, value: selectedItem.site_name }]
  );

  const allSitesSelected = selectedSites.length === allData.length;

  const toggleSelectAll = () => {
    setSelectedSites(
      allSitesSelected
        ? [{ label: selectedItem.site_name, value: selectedItem.site_name }]
        : allData.map((item) => ({ label: item.site_name, value: item.site_name }))
    );
  };

  useEffect(() => {
    setInited(true);
  }, []);

  useEffect(() => {
    setState((prev) => {
      const newState = { ...prev };
      for (const item of selectedSites) {
        const site = allData.find((el) => el.site_name === item.value);
        if (site && !prev[site.site_name]) {
          newState[site.site_name] = {
            bestchange: joinWithUnsubmitted(
              site.segments_best || [],
              site.unsubmitted_segments_best || []
            ),
            site: joinWithUnsubmitted(
              site.segments_site || [],
              site.unsubmitted_segments_site || []
            ),
          };
        }
      }
      return newState;
    });
    setOffValues((prev) => {
      const newState = { ...prev };
      for (const item of selectedSites) {
        const site = allData.find((el) => el.site_name === item.value);
        if (site && !prev[site.site_name]) {
          newState[site.site_name] = {
            bestchange: false,
            site: false,
          };
        }
      }
      return newState;
    });
  }, [selectedSites]);

  const submit = () => {
    if (selectedSites.length === 0) {
      setSubmitTried(true);
      return;
    }

    setLoading(true);

    let addedGreen = false;

    for (const [key, val] of Object.entries(state)) {
      const oldData = {
        segments_best: allData.find((item) => item.site_name === key)?.segments_best,
        segments_site: allData.find((item) => item.site_name === key)?.segments_site,
      };

      const newData: NewDataType = {
        segments_best: offValues[key].bestchange ? [] : val.bestchange,
        segments_site: offValues[key].site ? [] : val.site,
      };

      const newDataWithAddedGreenSegments = addMissingGreenSegments(newData);

      const site = allData.find((item) => item.site_name === key);

      if (!areSegmentsEqual(newData.segments_site, newDataWithAddedGreenSegments.segments_site)) {
        addedGreen = true;
      }

      const payload: UpdateSelectedSegmentsPayload = {
        city: selectedItem.city,
        currencyFrom: selectedItem.currencyFrom,
        currencyTo: selectedItem.currencyTo,
        ...site,
        ...getChangesAfterCopy(oldData, newDataWithAddedGreenSegments, username),
      };

      dispatch(updateSiteSegments(payload));
    }

    if (addedGreen) {
      dispatch(
        setNotific({
          type: "success",
          message: "Зеленые отрезки были добавлены автоматически",
        })
      );
    }

    setLoading(false);
    closeModal();
  };

  const onSelectChange = (
    e: MultiValue<{
      label: string;
      value: string;
    }>
  ) => {
    setSelectedSites(e);
    setSubmitTried(false);
  };

  const siteOptions = useMemo(
    () =>
      [
        selectedItem.site_name,
        ...selectedItem.otherSites
          .filter(
            (site) =>
              site.segments_best.length > 0 ||
              site.segments_site.length > 0 ||
              site.unsubmitted_segments_best.length > 0 ||
              site.unsubmitted_segments_site.length > 0
          )
          .map((site) => site.site_name),
      ].map((item) => ({
        label: item,
        value: item,
      })),
    [selectedItem]
  );

  const getColumns = (amount: number) => (amount ? `repeat(${amount}, 1fr)` : "1fr");

  if (!inited) {
    return null;
  }

  return (
    <div className="flex flex-col gap-8 min-w-[280px]">
      <div>
        {siteOptions.length > 1 ? (
          <div
            className={cn(
              "w-[335px] flex items-center gap-[4px] mb-[6px] mx-auto",
              selectedSites.length > 2 && "w-full"
            )}>
            <Select
              options={siteOptions}
              className="w-full"
              value={selectedSites}
              noOptionsMessage={() => "Нет сайтов"}
              onChange={(e) => {
                if (!e || e.length === 0) {
                  onSelectChange([selectedSites[0]]);
                  return;
                }
                onSelectChange(e);
              }}
              isMulti
            />
            <button
              onClick={toggleSelectAll}
              className={cn(
                "flex items-center justify-center h-[37px] w-[37px] aspect-square rounded-md border text-gray/60 border-gray/60 hover:text-green hover:border-green group duration-300",
                allSitesSelected &&
                  "bg-green border-green text-white hover:opacity-80 hover:text-white"
              )}>
              <CheckCheck className="w-[15px] h-[15px]" />
            </button>
          </div>
        ) : (
          <div className="pb-[4px] font-bold">{selectedSites[0].label}</div>
        )}
        <div className="text-sm text-lightFont font-semibold">{selectedItem?.city.city_name}</div>
        <div className="flex gap-8 text-sm text-lightFont">
          {selectedItem?.currencyFrom.currency_name} - {selectedItem?.currencyTo.currency_name}
        </div>
      </div>
      <div className="flex flex-col gap-[15px]">
        <div
          className="grid grid- gap-[10px]  grid-rows-2 overflow-x-auto rounded-lg"
          style={{
            gridTemplateColumns: getColumns(Math.ceil(selectedSites.length / 2) || 1),
            gridTemplateRows: selectedSites.length > 1 ? "1fr 1fr" : "1fr",
          }}>
          {selectedSites.map((site) =>
            state[site.value] ? (
              <div
                key={site.value}
                className="flex bg-[#BABAC322] p-[10px] pt-[6px] font-medium flex-col gap-[4px] rounded-md">
                <p className="text-grayDark text-[15px] pt-[2px] bg-bg mx-auto px-[10px] flex items-center text-center justify-center py-[3px] rounded-md">
                  {site.label}
                </p>
                <SourcesForm
                  setSubmitDisabled={setSubmitDisabled}
                  state={state[site.value]}
                  setState={(val: Record<ESource, ITimeSegment[]>) =>
                    setState((prev) => ({
                      ...prev,
                      [site.value]: val,
                    }))
                  }
                  off={offValues[site.value]}
                  setOff={(val: Record<ESource, boolean>) =>
                    setOffValues((prev) => ({
                      ...prev,
                      [site.value]: val,
                    }))
                  }
                  isLoading={isLoading}
                  noSubmit
                />
              </div>
            ) : null
          )}
        </div>
        <button
          style={{
            boxShadow: isLoading ? "0 0 10px #40AEF088" : "",
            backgroundColor: isLoading ? "#40AEF055" : "",
            borderColor: submitDisabled ? "#BABAC355" : "#3BC57AAA",
            color: submitDisabled ? "#BABAC3" : "#2BB56A",
          }}
          className={cn(
            "mx-auto self-end shrink-0 w-[130px] h-[28px] flex items-center justify-center text-xs py-4 bg-[#BBFFDA] border font-semibold rounded-sm disabled:bg-[#EEE] duration-300"
          )}
          onClick={submit}>
          Подтвердить
        </button>
      </div>
      {submitTried && <p className="text-red text-xs text-center">Выберите хотя бы 1 сайт</p>}
    </div>
  );
};
