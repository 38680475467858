import { TTimelineSite, TTimelineVector } from "src/store/clusterReducer";

const isIntersecting = (site1: TTimelineSite, site2: TTimelineSite) => {
  const start1 = site1?.start_time?.substring(0, 5);
  const start2 = site2?.start_time?.substring(0, 5);
  const end1 = site1?.end_time?.substring(0, 5);
  const end2 = site2?.end_time?.substring(0, 5);

  return end1 > start2 && end2 > start1;
};

const isIntersectingSome = (site: TTimelineSite, sites: TTimelineSite[]) => {
  return sites.some((item) => isIntersecting(item, site));
};

export const createOverlaysVectors = (item: TTimelineVector): TTimelineVector[] => {
  const sites = [...item.sites];

  const rows: TTimelineSite[][] = [[]];

  sites.forEach((site) => {
    let i = 0;
    while (i < rows.length && isIntersectingSome(site, rows[i])) {
      i++;
    }
    if (i === rows.length) {
      rows.push([]);
    }
    rows[i].push(site);
  });
  return rows.map((row) => ({
    ...item,
    sites: row,
  }));
};
