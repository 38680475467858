import { Dispatch, SetStateAction, useState } from "react";
import { fetchRequest } from "src/helpers/fetchRequest";
import minMaxApi from "src/api/minMax";
import { TMinMaxSheet } from "src/store/directionsReducer";

type TProps = {
  site: TMinMaxSheet;
  fetchSheet: () => void;
  closeFn: Dispatch<SetStateAction<boolean>>;
};

export const SiteUrlForm = ({ site, fetchSheet, closeFn }: TProps) => {
  const [url, setUrl] = useState(site?.site_text_url);
  const disabled = !url?.length || url === site?.site_text_url;

  const updateSiteUrl = async () => {
    const payload = [
      {
        ...site,
        site_text_url: url,
      },
    ];
    const { response } = await fetchRequest(minMaxApi.updateMinMaxSheet(payload), {
      request: "Ссылка на текста",
    });
    if (response) {
      closeFn(false);
      await fetchSheet();
    }
  };

  return (
    <div className="flex flex-col gap-8 text-xs w-[260px]">
      <div>
        Обновите ссылку на документ с текстами для сайта <b>{site.site_name}</b>
      </div>
      <input
        value={url}
        className="border border-lightGray"
        onChange={(e) => setUrl(e.target.value)}
      />
      <button
        type="button"
        className={`${
          disabled ? "opacity-30" : ""
        } self-end rounded-sm font-semibold text-lightFont border border-lightGray bg-stale px-[16px] pb-2`}
        disabled={disabled}
        onClick={updateSiteUrl}>
        Сохранить
      </button>
    </div>
  );
};
