import { DB_URL } from "./constants";
import api from "./http";

export default class directionsApi {
  static async getSteps(site_id) {
    return api.get(`${DB_URL}/steps/${site_id}`);
  }
  static async setSteps(site_id, data) {
    return api.post(`${DB_URL}/steps/${site_id}`, data);
  }
  static async setGlobalSteps(data) {
    return api.post(`${DB_URL}/set_global_steps`, data);
  }
}
