import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "./MinMaxInput";

export const MinMaxCity = ({
  item,
  isVisible,
  city,
  setAllMin,
  clearFields,
  setAllMax,
  dirtyCities,
  setDirtyCities,
  updateFields,
  pending,
  mainCity,
  isAccess,
}) => {
  const {
    setValue,
    formState: { dirtyFields },
  } = useFormContext();
  const dirtyLength = Object.keys(dirtyFields)?.length;

  useEffect(() => {
    for (const key in dirtyFields) {
      if (dirtyCities?.length === 0) {
        setDirtyCities([...dirtyCities, key]);
      } else {
        if (!dirtyCities?.includes(key)) {
          setDirtyCities([...dirtyCities, key]);
        }
      }
    }
  }, [dirtyLength]);

  useEffect(() => {
    setValue(`${item.city_code}.min_sum`, item.min_sum);
    setValue(`${item.city_code}.max_sum`, item.max_sum);
  }, [item]);

  return (
    <div
      className={`flex flex-col sm:flex-row items-start sm:mb-0 sm:p-0 shadow-base rounded-lg sm:shadow-none w-full ${
        isVisible ? "h-[100%] max-h-[100%] p-[10px] mb-4" : "h-0 max-h-0"
      } overflow-hidden`}>
      <div className="w-full sm:w-[170px]">
        <div className="text text-center sm:text-left sm:w-[200px] sm:pl-10 mb-[10px] sm:mb-0">
          {item?.city_name}
        </div>
      </div>
      <div className="grid grid-cols-2 w-full sm:w-fit">
        {item?.city_name === mainCity ? (
          <div className="text-center">
            <div>
              <Input
                name={`${item?.city_code}.min_sum`}
                sum={item?.min_sum}
                pending={pending}
                citySum={city?.min_sum}
                clearFields={clearFields}
                isGeneral
                updateFields={updateFields}
                isAccess={isAccess}
              />
            </div>
            {isAccess && (
              <button
                className="prime-button-sm bg-grayDark px-[20px] my-8 sm:px-8"
                type="button"
                onClick={setAllMin}>
                Применить ко всем
              </button>
            )}
          </div>
        ) : (
          <div>
            <Input
              name={`${item?.city_code}.min_sum`}
              sum={item?.min_sum}
              pending={pending}
              citySum={city?.min_sum}
              clearFields={clearFields}
              isAccess={isAccess}
            />
          </div>
        )}
        {item?.city_name === mainCity ? (
          <div className="text-center">
            <div>
              <Input
                name={`${item?.city_code}.max_sum`}
                sum={item?.max_sum}
                pending={pending}
                citySum={city?.max_sum}
                clearFields={clearFields}
                isGeneral
                updateFields={updateFields}
                isAccess={isAccess}
              />
            </div>
            {isAccess && (
              <button
                className="prime-button-sm bg-grayDark px-[20px] my-8 sm:px-8"
                type="button"
                onClick={setAllMax}>
                Применить ко всем
              </button>
            )}
          </div>
        ) : (
          <div>
            <Input
              name={`${item?.city_code}.max_sum`}
              sum={item?.max_sum}
              pending={pending}
              citySum={city?.max_sum}
              clearFields={clearFields}
              isAccess={isAccess}
            />
          </div>
        )}
      </div>
    </div>
  );
};
