import {
  ICurrencyPair,
  CurrencyPairsGroup,
  CurrencyPairsGroupedByFrom,
} from "src/types/OnOffTimeline/common";

export function groupCurrencyPairsByFrom(
  pairs: ICurrencyPair[],
  groupName: CurrencyPairsGroup
): CurrencyPairsGroupedByFrom[] {
  const map: Record<string, CurrencyPairsGroupedByFrom> = {};

  pairs.forEach(({ currency_from, currency_to, sites }) => {
    const key = currency_from.currency_name;
    if (!map[key]) {
      map[key] = {
        currency_from,
        currencies_to: [{ currency: currency_to, sites }],
        groupName,
      };
    } else {
      if (map[key].currencies_to.some((item) => item.currency === currency_to)) {
        return;
      }

      map[key].currencies_to.push({ currency: currency_to, sites });
    }
  });

  return Object.values(map);
}
