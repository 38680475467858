import { centrifuge } from "./centrifugo";
import { store } from "../../store/store";
import { setViewers } from "../../store/mainReducer";

export const setViewersByCentrifugo = async (allViewers) => {
  const updItemVal = {
    key: "SET_VIEWERS",
    viewers: allViewers,
  };
  try {
    await centrifuge.publish(`bid:viewers`, JSON.stringify(updItemVal));
  } catch {
    store.dispatch(setViewers(allViewers));
  }
};

export const createViewersSubscription = (resubscribe, unsubscribe) => {
  let subscription = centrifuge.subscribe(`bid:viewers`);
  if (resubscribe) {
    subscription.unsubscribe();
    subscription = centrifuge.subscribe(`bid:viewers`);
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("publish", function (data) {
    const msg = JSON.parse(data.data);
    const { key, viewers } = msg;
    switch (key) {
      case "SET_VIEWERS":
        store.dispatch(setViewers(viewers));
        break;
      default: {
        console.log(msg);
      }
    }
  });
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        store.dispatch(setViewers(JSON.parse(res.publications[0].data).viewers));
      }
    } catch (e) {
      console.log(e);
    }
  });
};
