import { Dispatch, SetStateAction, useEffect, useState } from "react";
import devApi from "src/api/developers";
import { fetchRequest } from "src/helpers/fetchRequest";
import { NestedObjects } from "src/pages/Control/components/routes";

export const Countries = ({
  versionName,
  country,
  setCountry,
}: {
  versionName: string;
  country: string;
  setCountry: Dispatch<SetStateAction<string>>;
}) => {
  const [countriesByVersion, setCountries] = useState<NestedObjects<string[]>>({});
  const countries = countriesByVersion[versionName] || [];

  const fetchCountries = async () => {
    const { response } = await fetchRequest(devApi.getProxyCountries(versionName), {
      request: "Страны для покупки",
    });
    if (response) {
      setCountries({
        ...countriesByVersion,
        [versionName]: response?.map((el: { name: string }) => el?.name),
      });
    }
  };

  useEffect(() => {
    if (!countriesByVersion[versionName]) {
      fetchCountries();
    }
  }, [versionName]);

  return (
    <div className={`grid grid-cols-[66px_1fr] items-baseline gap-[10px]`}>
      <div className="text-right">country</div>
      {!!countries?.length && (
        <div className="flex flex-wrap gap-4">
          {countries?.map((el) => (
            <button
              key={el}
              type="button"
              onClick={() => setCountry(el)}
              className={`rounded-sm text-[#FFF] text-[10px] px-4 font-medium h-[15px] ${
                country === el ? "bg-[#FCC815]" : "bg-[#BABAC3]"
              }`}>
              {el?.toUpperCase()}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
