import { centrifuge } from "./centrifugo";
import { notify } from "../../components/SystemAlerts/SystemAlerts";
import { Url } from "../constants";
import { store } from "../../store/store";
import { createRequest, setUnredReqCount, updateRequest } from "src/store/commonReducer";
import { fetchRequest } from "src/helpers/fetchRequest";
import requestApi from "src/api/requests";
import { setNotific } from "src/store/mainReducer";
import { LS } from "../constants";

const NEW_DATA_CHANNEL = "it_requests:new_data";
const username = localStorage.getItem(LS.USERNAME);
const THROTTLE_DELAY = 300000; // 5 min

export const fetchUnreadReqCount = async () => {
  const { response, error } = await fetchRequest(requestApi.getUnreadReqCount());

  if (!response || error) {
    store.dispatch(
      setNotific({
        type: "error",
        message: "Не удалось запросить информацию о непрочитанных сообщениях",
      })
    );
    return;
  }

  store.dispatch(setUnredReqCount(response.count));
};

export const createRequestsSubscription = (resubscribe, unsubscribe) => {
  let isThrottled = false;

  const throttledRequest = async () => {
    if (!isThrottled) {
      isThrottled = true;

      await fetchUnreadReqCount();

      setTimeout(() => {
        isThrottled = false;
      }, THROTTLE_DELAY);
    }
  };

  const handler = async (res) => {
    notify.show(
      `🌈🌈🌈Запросы в IT🌈🌈🌈`,
      res.data?.message,
      Url.REQUESTS + "?id=" + res.data?.message?.split(" - ")[1]
    );
    throttledRequest();
  };

  const subscription = centrifuge.subscribe(`it_requests:update`, handler);

  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`it_requests:update`, handler);
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
};

export const createNewReqDataSub = (resubscribe, unsubscribe) => {
  const handler = async function ({ data }) {
    data = JSON.parse(data);
    const type = data.type;
    const item = data.item;

    if (!type || !item) {
      throw new Error("Переданы неверные данные");
    }

    switch (type) {
      case "create":
        store.dispatch(createRequest({ ...item, is_viewed: item.creator === username }));
        break;
      case "update":
        store.dispatch(updateRequest({ ...item, is_viewed: item.updator === username }));
        break;
    }
  };

  const subscription = centrifuge.subscribe(NEW_DATA_CHANNEL, handler);

  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(NEW_DATA_CHANNEL, handler);
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
};

export const pushNewReqDataToCentrifuge = (data, onError) => {
  try {
    if (typeof data !== "object" || !data.hasOwnProperty("type") || !data.hasOwnProperty("item")) {
      throw new Error(JSON.stringify(data));
    }
    centrifuge.publish(NEW_DATA_CHANNEL, JSON.stringify(data)).catch(() => onError());
  } catch (e) {
    console.log(e);
  }
};
