import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ICountryData } from "../models";
import { SectorRow } from "./SectorRow";
import arrow from "src/assets/images/whiteArrow.svg";
import { useDebounceTimer } from "src/hooks/useDebounceTimer";
import { MAX_BET_DELTA } from "src/constants/bid";

type TProps = {
  access: boolean;
  item: ICountryData;
  staticCountries: Array<ICountryData>;
  redactorDisabled: boolean;
  isLoading: boolean;
  updateBossRate: () => Promise<void>;
  initialBossRate: Record<string, string>;
};

export const CountryRow = ({
  access,
  item,
  staticCountries,
  redactorDisabled,
  isLoading,
  updateBossRate,
  initialBossRate,
}: TProps) => {
  const { register, setValue, unregister, watch } = useFormContext();
  const [isOpen, setOpen] = useState(true);
  const [isRegion, setIsRegion] = useState(false);

  useEffect(() => {
    setValue(item?.country_name, item.bet_boss_rate);
    return () => {
      unregister(item?.country_name);
    };
  }, [item]);

  const bossRate = watch(item?.country_name); // trigger rerender on field change
  const { blocked } = useDebounceTimer(600, bossRate);

  useEffect(() => {
    if (!blocked) {
      const current = +bossRate;
      const initial = +initialBossRate[item?.country_name];
      const multiplier = current < initial ? -1 : 1;

      if (Math.abs(initial - current) > MAX_BET_DELTA) {
        setValue(item.country_name, String(multiplier * MAX_BET_DELTA + initial));
      }
    }
  }, [bossRate, blocked]);

  return (
    <div>
      <div
        className={`flex justify-between gap-[10px] items-center w-full ${
          isOpen ? "bg-font" : "bg-lightFont"
        } rounded-t-lg sm:rounded-none text-white text-sm font-bold mr-[5px] px-[10px] sm:px-[20px] py-[3px] shadow`}>
        <div className="text-left">{item.country_name}</div>
        <button onClick={() => setOpen(!isOpen)} type="button">
          <img
            src={arrow}
            alt="скрыть"
            width={12}
            height={12}
            className={`duration-700 ${isOpen ? "rotate-0" : "rotate-180"}`}
          />
        </button>
      </div>
      <div className={`duration-700 overflow-hidden ${isOpen ? "max-h-[470px]" : "max-h-0"}`}>
        <div className="flex items-center bg-stale px-[10px] sm:px-[18px]">
          <div className="switcher w-[50px]">
            <label
              style={{ backgroundColor: !isRegion ? "green" : "lightgrey" }}
              className={`switcher__label-small cursor-pointer hover:opacity-[0.9]`}>
              <input
                checked={!isRegion}
                className="switcher__check-input"
                type="checkbox"
                onChange={() => setIsRegion(!isRegion)}
              />
              <span className="switcher__checkbox-small"></span>
            </label>
          </div>
          <div className="text-xs font-light">{isRegion ? "Регионы" : "Основные города"}</div>
        </div>
        <div className="flex items-center justify-between sm:gap-[20px] px-[10px] sm:px-[20px] bg-stale text-xs">
          <div className="text-[9px] sm:text-xs">BOSS STAVKA</div>
          {access ? (
            <div className="flex items-center justify-end w-fit gap-1">
              <input
                className={`block px-4 border-lightGray border-[1px] my-[2px] outline-lightGray rounded-md w-[120px]`}
                type="number"
                step={0.000001}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                {...register(item?.country_name)}
              />
              <button
                type="button"
                className="rounded-md bg-font text-[10px] text-white px-[10px] hover:opacity-[0.8]"
                onClick={updateBossRate}
                disabled={blocked}>
                Сохранить
              </button>
            </div>
          ) : (
            <div className={`px-4 my-[2px] w-[145px] sm:w-[178px] sm:mr-[59px]`}>
              {item?.bet_boss_rate}
            </div>
          )}
        </div>
        <SectorRow
          item={item}
          isRegion={isRegion}
          access={access}
          staticCountries={staticCountries}
          redactorDisabled={redactorDisabled}
          isOpen={isOpen}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
