import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { pascal } from "src/helpers/strHelper";
import { add } from "src/helpers/addHelper";
import tetrisApi from "src/api/tetris";
import clusterApi from "src/api/cluster";
import { TBlocks } from "src/store/onOffReducer";
import { TCluster, TClusterSchedule } from "src/store/clusterReducer";
import { setExistTetris, TTetrisAutocorr } from "src/store/tetrisReduser";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { LockButton } from "src/components/UI/buttons/LockButton";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";
import { TT, TTetrisTab } from "../components/tetrisConstants";
import { TFocus } from "./ExistList";
import infinity from "src/assets/images/infinity.svg";
import pumpkin from "src/assets/images/pumpkin.svg";

type TProps = {
  item: TTetrisAutocorr | TBlocks | TCluster | TClusterSchedule;
  data: TTetrisAutocorr[] | TBlocks[] | TCluster[] | TClusterSchedule[];
  isAccess: boolean;
  deleteArr: Array<string>; // Array<JSON.stringify>
  setDeleteArr: Dispatch<SetStateAction<Array<string>>>;
  page: TTetrisTab;
  focus: TFocus;
  setFocus: Dispatch<SetStateAction<TFocus>>;
  fetchItems: () => void;
};

export const ExistRow = ({
  item,
  data,
  isAccess,
  deleteArr,
  setDeleteArr,
  page,
  focus,
  setFocus,
  fetchItems,
}: TProps) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  const checkToDelete = () => {
    setChecked(!checked);
    add(deleteArr, setDeleteArr, JSON.stringify(item));
  };

  const update = async (payload) => {
    const { response } = await fetchRequest(
      tetrisApi[`updateTetris${pascal(page?.page)}`](
        page.page === TT.AUTOCORR ? payload : [payload]
      ),
      { request: page.title }
    );
    if (response) {
      dispatch(
        setExistTetris(
          data?.map((el) => (JSON.stringify(el) === JSON.stringify(item) ? payload : el))
        )
      );
    }
  };

  const updateInfinity = async (payload) => {
    const { response } = await fetchRequest(clusterApi.updateClusterScheduleInfinity([payload]), {
      request: "Сохранение расписания",
    });
    if (response) {
      await fetchItems();
    }
  };

  return (
    <div
      className={`flex flex-wrap w-fit gap-2 items-center py-[1px] px-4 rounded-sm text-xs ${
        "is_locked" in item && item?.is_locked ? "bg-[#D6F1FF]" : checked ? "bg-lightGray" : "bg-bg"
      }`}>
      {page.page === TT.SCHEDULE && isAccess && "is_infinity" in item && (
        <div className={`font-bold mr-2 relative top-[1px]`}>
          <Tooltip
            content={
              !item?.is_infinity
                ? `Сохраните для использования\nболее чем 1 день, иначе после\n0:00 расписание удалится`
                : `Расписание сохранено`
            }
            direction="right">
            <button
              type="button"
              className={`${
                item?.is_infinity ? "bg-[#40AEF0]" : "bg-[#F7931A]"
              } flex items-center justify-center rounded-full h-[20px] w-[20px] relative`}
              onClick={() =>
                updateInfinity({
                  ...item,
                  is_infinity: !item?.is_infinity,
                })
              }>
              <img
                src={item?.is_infinity ? infinity : pumpkin}
                alt={item?.is_infinity ? "сохранено" : "тыква"}
                width={15}
                height={15}
              />
            </button>
          </Tooltip>
        </div>
      )}
      {isAccess && page?.page !== TT.BLACKLIST && "is_locked" in item && (
        <div className={`font-bold mr-2 relative top-[1px]`}>
          <LockButton
            stat={item.is_locked}
            content={item.is_locked ? `Заблокировано\nдля удаления` : `Защитите\nот удаления`}
            active={true}
            isSmall
            confirm={() =>
              update({
                ...item,
                is_locked: !item.is_locked,
              })
            }
          />
        </div>
      )}
      {isAccess && !("is_locked" in item && item.is_locked) && (
        <div className="relative top-[2.5px] -mr-4">
          <Checkbox checked={checked} onChange={checkToDelete} />
        </div>
      )}
      <Badge title={item.site_name} attr="site" focus={focus} setFocus={setFocus} />
      <Badge title={item.vector} attr="vector" focus={focus} setFocus={setFocus} />
      <Badge title={item.city_name} attr="city" focus={focus} setFocus={setFocus} />
      {"percent" in item && (
        <Badge title={item.percent} attr="percent" focus={focus} setFocus={setFocus} />
      )}
      {"big_percent" in item && item?.on_timeline && (
        <Badge
          title={`TL ${item.big_percent} %`}
          attr="big_percent"
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {}
      {"time_from" in item && (
        <Badge
          title={item.time_from?.substring(0, 5)}
          attr="time_from"
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {}
      {"time_to" in item && (
        <Badge
          title={item.time_to?.substring(0, 5)}
          attr="time_to"
          focus={focus}
          setFocus={setFocus}
        />
      )}
    </div>
  );
};

const Badge = ({ title, attr, focus, setFocus }) => {
  return (
    <div
      style={{
        backgroundColor: focus[attr] === title ? "#3BB54A" : "#FFFFFF88",
        color: focus[attr] === title ? "#FFF" : attr === "big_percent" ? "#7777DD" : "",
      }}
      className={`rounded-sm px-4 whitespace-pre cursor-pointer bg-[#3BB54A] ${
        attr === "big_percent" ? "font-bold" : "font-medium"
      }`}
      onClick={() => setFocus({ ...focus, [attr]: focus[attr] === title ? "" : title })}>
      {title}
    </div>
  );
};
