import { useMemo } from "react";
import { navigation } from "./navigation";
import { IconNavItem } from "./IconNavItem";

type TProps = {
  pinned: Array<string>;
  page: string;
  userAccess: Array<string>;
  hidePin: boolean;
};

export const Pinned = ({ pinned, page, userAccess, hidePin }: TProps) => {
  const arr = useMemo(
    () =>
      Object.values(navigation)
        ?.map((el) => Object.values(el))
        ?.reduce((a, b) => a?.concat(b)),
    []
  );
  const childrenArr = useMemo(
    () =>
      arr
        ?.map((block) => block?.children)
        ?.filter((el) => !!el?.length)
        ?.reduce((a, b) => a?.concat(b)),
    [arr]
  );
  const pinnedChildrenArr = useMemo(
    () => pinned?.map((el) => childrenArr?.find((route) => route?.path === el) || ""),
    [childrenArr, pinned]
  );
  const pinnedArr = useMemo(
    () =>
      pinned
        ?.filter((el) => !pinnedChildrenArr?.find((found) => found?.path === el))
        ?.map((el) => arr?.find((route) => route.path === el) || "")
        ?.filter((el) => el !== ""),
    [pinnedChildrenArr, arr, pinned]
  );

  return (
    <>
      {pinnedArr?.map((el) => {
        if (el !== "" && userAccess?.some((access) => el?.access?.includes(access))) {
          return (
            <IconNavItem
              key={`${el.path}_pin`}
              item={el}
              page={page}
              isTitle
              isOpenTabs=""
              setIsOpenTabs={() => {}}
              pinned={pinned}
              hidePin={hidePin}
              userAccess={userAccess}
            />
          );
        }
      })}
      {pinnedChildrenArr?.map((el) => {
        if (el !== "" && userAccess?.some((access) => el?.access?.includes(access))) {
          return (
            <IconNavItem
              key={`${el.path}_pin`}
              item={el}
              page={page}
              isTitle
              isOpenTabs=""
              setIsOpenTabs={() => {}}
              pinned={pinned}
              hidePin={hidePin}
              userAccess={userAccess}
            />
          );
        }
      })}
    </>
  );
};
