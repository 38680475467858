import ReactSelect, { createFilter } from "react-select";
import { useMemo } from "react";
import { selectFilter } from "src/helpers/formHelpers";

export const TagsEditor = ({
  tags,
  selectedTags,
  setSelectedTags,
  setTags,
  isAddVisible,
  access,
}) => {
  const tagsOptions = useMemo(
    () =>
      Array.from(tags)?.map((el: { tag: string; id: number }) => ({ label: el.tag, value: el.id })),
    [tags]
  );

  return (
    <div className={`flex flex-col gap-4`}>
      {access && (
        <div
          className={`flex gap-4 items-center ${
            isAddVisible ? "max-h-[24px] mt-4" : "max-h-0 mt-0 overflow-hidden"
          }`}>
          <div
            className={`h-[23px] w-[23px] min-w-[23px] rounded-sm justify-center items-center flex bg-[#3BC57A] text-[#BBFFDA] text-[16px] font-extrabold`}>
            #
          </div>
          <ReactSelect
            options={tagsOptions}
            classNamePrefix="SquareSelect"
            className="w-full outline-none"
            filterOption={createFilter(selectFilter)}
            onChange={(e) => setTags(e)}
          />
        </div>
      )}
      {!!selectedTags?.length && (
        <div className="flex gap-4 items-center flex-wrap">
          {selectedTags?.map((el) => (
            <div
              key={el.id}
              className="flex gap-4 items-center whitespace-pre rounded-sm bg-[#3BC57A] text-[#BBFFDA] text-[10px] pl-4 pr-8 font-medium h-[15px]">
              <div className="text-[12px] font-bold">#</div>
              {el.tag}
              {access && isAddVisible && (
                <button
                  type="button"
                  className="relative left-2 rotate-45 text-[12px]"
                  onClick={() => {
                    setSelectedTags(selectedTags?.filter((tag) => tag.id !== el.id));
                  }}>
                  +
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
