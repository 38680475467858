import { BASE_URL, DB_URL } from "./constants";
import api from "./http";

export default class minMaxApi {
  static async getMinMax(data) {
    return api.post(`${DB_URL}/minmax/get_cities`, data);
  }
  static async updateMinMax(data) {
    return api.post(`${DB_URL}/minmax/set_cities`, data);
  }
  // Таблица
  static async getMinMaxSheetRegions() {
    return api.get(`${BASE_URL}/sheets_keeper/sheets/sectors`);
  }
  static async getMinMaxSheet(region_id) {
    return api.get(`${BASE_URL}/sheets_keeper/sheets/${region_id}`);
  }
  static async updateMinMaxSheet(data) {
    return api.post(`${BASE_URL}/sheets_keeper/sheets/`, data);
  }
  static async updateMinMaxException(data) {
    return api.post(`${BASE_URL}/sheets_keeper/sheets/exception`, data);
  }
  static async deleteMinMaxException(exception_id) {
    return api.delete(`${BASE_URL}/sheets_keeper/sheets/exception/${exception_id}`);
  }
  static async synchronizeMinMaxSheet(region_id, data) {
    return api.post(`${BASE_URL}/sheets_keeper/sheets/synchronize/${region_id}`, data);
  }
}
