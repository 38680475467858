import { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider, FieldValues } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import { LS } from "src/api/constants";
import minMaxApi from "src/api/minMax";
import { setNotific } from "src/store/mainReducer";
import {
  selectAllCurrencies,
  selectAllSites,
  selectCurFrom,
  selectCurTo,
  selectMinMax,
  selectSite,
  setMinMax,
  setSelectedFrom,
  setSelectedSite,
  setSelectedTo,
  TSelect,
  TStrSelect,
} from "src/store/directionsReducer";
import ReactSelect, { createFilter } from "react-select";
import { Sector } from "./components/Sector";
import { Emptiness } from "src/components/UI/loader/Emptiness";

export const MinMax = () => {
  const dispatch = useDispatch();
  const methods = useForm({ shouldUnregister: true, mode: "onChange" });
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const isAccess = userAccess?.includes("minmax_post");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGood, setIsGood] = useState<boolean>(true);
  const globalsCurrencies = useSelector(selectAllCurrencies);
  const globalsSites = useSelector(selectAllSites);

  const currenciesOptions: Array<TStrSelect> = useMemo(
    () =>
      globalsCurrencies?.map((el) => ({
        label: el.currency_name,
        value: el.currency_name,
      })),
    [globalsCurrencies]
  );
  const sitesOptions = useMemo(
    () =>
      globalsSites
        ?.filter((el) => el?.is_changable)
        ?.map((el) => ({ label: el.site_name, value: el.site_id })) as Array<TSelect>,
    [globalsSites]
  );
  const globalsCities = useSelector(selectMinMax);
  const curFrom = useSelector(selectCurFrom);
  const curTo = useSelector(selectCurTo);
  const storeSite = useSelector(selectSite);
  const [dirtyCities, setDirtyCities] = useState<Array<string>>([]);

  const sectors = useMemo(
    () => [...new Set(globalsCities?.map((el) => (el.sector === null ? "Другие" : el.sector)))],
    [globalsCities]
  );
  const [clearFields, setClearFields] = useState(false);
  const [pending, setPending] = useState(false);

  const updateD = (e: TStrSelect) => {
    searchParams.set("cur", e.label);
    dispatch(setSelectedFrom(e.label));
    !!curTo && e.label === curTo && dispatch(setSelectedTo(null));
    setSearchParams(searchParams);
  };
  const updateS = (e: TSelect) => {
    searchParams.set("site", e?.label);
    setSearchParams(searchParams);
    dispatch(setSelectedSite({ site_id: e?.value, site_name: e?.label }));
  };

  const updateFields = () => {
    setClearFields(true);
    setTimeout(() => setClearFields(false), 2000);
  };

  const fetchMinMax = async () => {
    const { response, error } = await fetchRequest(
      minMaxApi.getMinMax({
        site_name: storeSite?.site_name,
        currency: curFrom,
      }),
      { request: "Получение MinMax" }
    );
    if (response) {
      dispatch(setMinMax(response?.cities));
      setIsGood(true);
    }
    if (error) {
      setIsGood(false);
    }
  };

  const onSubmit = async (e: FieldValues) => {
    e.preventDefault();
    setPending(true);

    if (dirtyCities.length === 0) {
      dispatch(
        setNotific({
          type: "success",
          message: "МинМакс суммы успешно обновлены",
          request: "MinMax",
        })
      );
      return;
    }

    const payloadCities = dirtyCities?.map((item) => ({
      city_name: globalsCities?.find((el) => el.city_code === item)?.city_name,
      city_code: item,
      min_sum: methods.watch(
        `${globalsCities?.find((el) => el.city_code === item).city_code}.min_sum`
      )
        ? +methods.watch(`${globalsCities?.find((el) => el.city_code === item).city_code}.min_sum`)
        : globalsCities?.find((el) => el.city_code === item).min_sum,
      max_sum: methods.watch(
        `${globalsCities?.find((el) => el.city_code === item).city_code}.max_sum`
      )
        ? +methods.watch(`${globalsCities?.find((el) => el.city_code === item).city_code}.max_sum`)
        : globalsCities?.find((el) => el.city_code === item).max_sum,
    }));
    const payload = {
      site_name: storeSite?.site_name,
      currency: curFrom,
      cities: payloadCities,
    };
    const { response } = await fetchRequest(minMaxApi.updateMinMax(payload), { request: "MinMax" });
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: "МинМакс суммы успешно обновлены",
          request: "MinMax",
        })
      );
      setDirtyCities([]);
      methods.reset();
      updateFields();
      await fetchMinMax();
    }
  };

  useEffect(() => {
    if (!!searchParams?.get("cur") && !!globalsCurrencies?.length) {
      dispatch(setSelectedFrom(searchParams?.get("cur")));
    }
  }, [globalsCurrencies]);

  useEffect(() => {
    if (!!searchParams?.get("site") && !!globalsSites?.length) {
      const site = globalsSites?.find((el) => el.site_name === searchParams?.get("site"));
      dispatch(setSelectedSite({ site_name: site.site_name, site_id: site.site_id }));
    }
  }, [globalsSites]);

  useEffect(() => {
    if (!!curFrom && !!storeSite?.site_name?.length) {
      updateFields();
      methods.reset();
      fetchMinMax();
    }
    if (!!storeSite) {
      searchParams.set("site", storeSite?.site_name);
    }
    if (!!curFrom) {
      searchParams.set("cur", curFrom);
    }
    setSearchParams(searchParams);
  }, [curFrom, storeSite?.site_name]);

  useEffect(() => {
    if (globalsCities?.length) {
      dispatch(
        setNotific({
          type: "success",
          message: "Данные с сервера обновились",
          request: "Получение MinMax",
        })
      );
      setPending(false);
    }
  }, [globalsCities]);

  return (
    <FormProvider {...methods}>
      <div className="flex items-center flex-col w-[calc(100% - 80px)] mb-[100px]">
        <div className="w-[290px] sm:w-[400px] flex flex-col mr-[20px]">
          {!!currenciesOptions?.length && (
            <ReactSelect
              className="text sm:text-[24px] font-bold mb-8"
              classNamePrefix="Select"
              options={currenciesOptions}
              filterOption={createFilter(selectFilter)}
              placeholder={!!curFrom ? curFrom : "Валюта..."}
              value={!!curFrom ? { label: curFrom, value: curFrom } : ""}
              onChange={updateD}
            />
          )}
          {sitesOptions?.length > 0 && (
            <ReactSelect
              className="text sm:text-[24px] font-bold mb-8"
              classNamePrefix="Select"
              options={sitesOptions}
              filterOption={createFilter(selectFilter)}
              placeholder={!!storeSite ? storeSite.site_name : "Сайт..."}
              value={!!storeSite ? { label: storeSite.site_name, value: storeSite.site_id } : ""}
              onChange={updateS}
            />
          )}
        </div>
        <form onSubmit={onSubmit}>
          {!!sectors?.length && (
            <div className="table my-5 mr-[20px]">
              <div className="table-header hidden sm:grid grid-cols-[110px_170px_1fr_1fr]">
                {headers?.map((el, index) => (
                  <div key={index}>{el.toUpperCase()}</div>
                ))}
              </div>
              {sectors?.map((el, index) => {
                return (
                  <Sector
                    key={index}
                    sector={el}
                    globalsCities={globalsCities}
                    setDirtyCities={setDirtyCities}
                    isAccess={isAccess}
                    updateFields={updateFields}
                    dirtyCities={dirtyCities}
                    clearFields={clearFields}
                    pending={pending}
                  />
                );
              })}
            </div>
          )}
          {!!sectors?.length && isAccess && (
            <button disabled={dirtyCities.length === 0} className="prime-button">
              Обновить
            </button>
          )}
        </form>
        {!isGood && !sectors?.length && <Emptiness message={"Ошибка сервиса"} />}
      </div>
    </FormProvider>
  );
};

const headers = ["Сектор", "Город", "Минимум", "Маскимум"];
