import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/store";
import academyApi from "src/api/academy";
import { ROLE, Url } from "src/api/constants";
import { fetchRequest } from "src/helpers/fetchRequest";
import { TStrSelect, selectAllRoles } from "src/store/directionsReducer";
import { selectTest, selectTests, setTest, setTests } from "src/store/academyReducer";
import { TPerms, TTest } from "../types";
import { Button } from "src/shadcn/ui/ui/button";
import { Perm } from "../components/Perm";
import { setNotific } from "src/store/mainReducer";
import Select from "react-select";
import { convertToOptions } from "src/helpers/formHelpers";

export const EditCommon = () => {
  const dispatch = useAppDispatch();
  const tests = useSelector(selectTests);
  const test = useSelector(selectTest);
  const [searchParams, setSearchParams] = useSearchParams();
  const isCreate = !test?.course_id;
  const roles = useSelector(selectAllRoles)?.filter(
    (el) => el !== ROLE.ADMIN && el !== ROLE.MINI_ADMIN
  );
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(test?.course_name || "");
  const [description, setDescription] = useState(test?.course_description || "");
  const [perms, setPerms] = useState<TPerms[]>(test?.perms || []);
  const [isAutoGrant, setIsAutoGrant] = useState(test?.course_auto_grant || false);
  const [fromRole, setFromRole] = useState<TStrSelect | null>(
    test?.course_required_role
      ? {
          label: test.course_required_role,
          value: test.course_required_role,
        }
      : null
  );
  const [toRole, setToRole] = useState<TStrSelect | null>(
    test?.course_grant_role
      ? {
          label: test.course_grant_role,
          value: test.course_grant_role,
        }
      : null
  );
  const [autoPercent, setAutoPercent] = useState<number | null>(
    test?.course_required_percentage || null
  );

  const noAdminPerms = !perms?.some((el) => el.admin_rights);
  const disabled = !name?.length || isLoading || !perms?.length;

  const saveTest = async () => {
    setIsLoading(true);
    const permsExistAdmin = [...perms];
    if (!perms?.some((el) => el.user_role === ROLE.ADMIN)) {
      permsExistAdmin.push({
        user_role: ROLE.ADMIN,
        admin_rights: true,
      });
    }
    if (!perms?.some((el) => el.user_role === ROLE.MINI_ADMIN)) {
      permsExistAdmin.push({
        user_role: ROLE.MINI_ADMIN,
        admin_rights: true,
      });
    }

    const payload: TTest = {
      course_name: name,
      course_description: description,
      perms: permsExistAdmin,
      course_auto_grant: isAutoGrant,
      course_required_role: fromRole?.value || null,
      course_grant_role: toRole?.value || null,
      course_required_percentage: autoPercent,
    };
    if (isCreate) {
      const { response } = await fetchRequest(academyApi.createTest(payload), {
        request: "Создание теста",
      });
      if (response) {
        dispatch(setTests([...tests, response]));
        dispatch(setTest(response));
        searchParams.set("id", response.course_id);
        searchParams.set("module_id", Url.ACADEMY_MODULE_PARAM);
        setSearchParams(searchParams);
      }
    } else {
      const { response } = await fetchRequest(academyApi.updateTest(test.course_id, payload), {
        request: "Обновление теста",
      });
      if (response) {
        dispatch(
          setTests(
            tests.map((el) =>
              el.course_id === test.course_id
                ? {
                    ...el,
                    ...response,
                  }
                : el
            )
          )
        );
        dispatch(
          setTest({
            ...test,
            ...response,
          })
        );
        dispatch(
          setNotific({
            type: "success",
            message: "Данные успешно обновлены",
            request: "Обновление теста",
          })
        );
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!!test) {
      setName(test.course_name);
      setDescription(test.course_description);
      setPerms(test.perms);
      setIsAutoGrant(test.course_auto_grant);
      setFromRole({
        label: test.course_required_role,
        value: test.course_required_role,
      });
      setToRole({
        label: test.course_grant_role,
        value: test.course_grant_role,
      });
      setAutoPercent(test.course_required_percentage);
    }
  }, [test]);

  return (
    <div className="mx-auto max-w-[500px] lg:max-w-[900px] h-full p-5 flex flex-col lg:grid grid-cols-2 gap-5">
      <div className="flex flex-col gap-5 row-span-1">
        <label className="flex flex-col gap-8">
          <p className="text-slate-500 font-semibold">Назовите тест</p>
          <input
            className="border border-slate-300 rounded py-8 px-3"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label className="flex flex-col gap-8">
          <p className="text-slate-500 font-semibold">Описание</p>
          <textarea
            className="border border-slate-300 rounded py-8 px-3 text-sm"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Опционально"
          />
        </label>
        {!disabled && noAdminPerms && (
          <small className="rounded-md border border-rose-500 bg-rose-50 text-rose-500 px-8 w-fit text-center py-2">
            Не забудьте назначить хотя одну роль для редактирования
          </small>
        )}
        <div className="flex flex-col gap-8">
          <p className="text-slate-500 font-semibold">Автоматическое повышение</p>
          <Button
            variant="secondary"
            className={`px-5 ${
              isAutoGrant ? "bg-emerald-500 hover:bg-emerald-400 text-white" : ""
            }`}
            onClick={() => setIsAutoGrant(!isAutoGrant)}>
            {isAutoGrant ? "Включено" : "Включить"}
          </Button>
          {isAutoGrant && (
            <>
              <div className="grid grid-cols-[1fr_20px_1fr] items-center gap-4">
                <Select
                  placeholder="Кого повышаем?"
                  options={convertToOptions(roles)}
                  value={fromRole}
                  onChange={(e: TStrSelect) => setFromRole(e)}
                />
                <div className="text-center text-slate-400">{`\u2192`}</div>
                <Select
                  placeholder="До кого?"
                  options={convertToOptions(roles)}
                  value={toRole}
                  onChange={(e: TStrSelect) => setToRole(e)}
                />
              </div>
              <small className="text-slate-500 font-semibold pt-8 pb-4">Процент прохождения</small>
              <input
                type="number"
                placeholder="0 - 100"
                className="border border-slate-300 rounded py-8 px-3"
                value={autoPercent}
                onChange={(e) => setAutoPercent(+e.target.value)}
              />
            </>
          )}
        </div>
        <Button
          className="w-fit self-end px-5"
          disabled={disabled || noAdminPerms}
          onClick={saveTest}>
          {isCreate ? "Создать тест" : "Сохранить"}
        </Button>
      </div>
      <div className="flex flex-col gap-8">
        <p className="text-slate-500 font-semibold">Доступы</p>
        {roles?.map((el, index) => (
          <Perm key={index} role={el} perms={perms} setPerms={setPerms} />
        ))}
      </div>
    </div>
  );
};
