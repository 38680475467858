import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { NestedObjects } from "../components/routes";
import controlApi from "src/api/control";
import {
  TCoin,
  TCondition,
  TTrack,
  TTrackNotification,
  TWallet,
  TNetwork,
} from "src/store/commonReducer";
import { convertToDate } from "src/helpers/date";
import { checkCondition } from "src/helpers/strHelper";
import { Popup } from "src/components/Popup/Popup";
import { FieldRow } from "src/components/FormElements/FieldRow";
import { FieldTabs } from "src/components/FormElements/FieldTabs";
import { Notification } from "./Notification";
import { EMethod } from "./Wallets";

type TProps = {
  item: TTrack;
  setTracks: Dispatch<SetStateAction<TTrack[]>>;
  coins: TCoin[];
  conditions: TCondition[];
  notifications?: TTrackNotification[];
  setNotifications?: Dispatch<SetStateAction<TTrackNotification[]>>;
  setIsAddTrack: Dispatch<SetStateAction<boolean>>;
  baseRequest: (data: {
    method: Promise<AxiosResponse>;
    methodType: EMethod;
    title: string;
    name: string;
    setter:
      | Dispatch<SetStateAction<TTrackNotification[] | TTrack[] | TCoin[] | TWallet[]>>
      | ((res: NestedObjects<TCondition | TNetwork>) => void);
  }) => void;
  isLoading: boolean;
};

export const Track = ({
  item,
  setTracks,
  coins,
  notifications,
  conditions,
  baseRequest,
  setNotifications,
  setIsAddTrack,
  isLoading,
}: TProps) => {
  const [track, setTrack] = useState(item);
  const [isAdd, setIsAdd] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const coin = useMemo(
    () => coins?.find((el) => el.coin_id === track.coin_id),
    [track.coin_id, coins]
  );
  const itemNotifications = useMemo(
    () => notifications?.filter((el) => el.track_id === item.track_id) || [],
    [notifications]
  );
  const isBad = useMemo(
    () => checkCondition(item.condition, item?.actual_amount, item.amount),
    [item]
  );
  const disabled =
    isLoading ||
    !track.coin_id ||
    (track.track_name === item?.track_name &&
      track.amount === item.amount &&
      track.coin_id === item.coin_id &&
      track.condition === item.condition);
  return (
    <div
      className={`relative flex flex-col rounded-md border border-[#BABAC322] bg-[#BABAC311] px-[10px] py-8 -ml-[10px]`}>
      {!!item?.track_name?.length && (
        <button
          type="button"
          className={`absolute -top-[6px] -right-4 flex items-center justify-center h-[16px] w-[16px] bg-bg rounded-full shadow-sm text-[10px] text-lightFont`}
          onClick={() => setPopup(true)}>
          {`\u2715`}
        </button>
      )}
      {!!coins?.length ? (
        <div>
          <FieldRow
            label="название"
            placeholder="Назовите отслеживание"
            value={track.track_name}
            defaultValue={track.track_name}
            edited
            updFn={(fieldValue: string) => {
              setTrack({
                ...track,
                track_name: fieldValue,
              });
            }}
          />
          <FieldTabs
            label="coin"
            placeholder="Выберите монету"
            arr={coins?.map((el) => el.coin_name)}
            value={coin?.coin_name || ""}
            edited
            updFn={(fieldValue) => {
              setTrack({
                ...track,
                coin_id: coins?.find((el) => el.coin_name === fieldValue)?.coin_id || "",
              });
            }}
          />
          <div
            className={`${
              !!item.track_id ? "grid grid-cols-[1fr_76px_1fr] gap-[10px]" : "flex flex-col"
            }`}>
            {"actual_amount" in item && (
              <FieldRow
                label="баланс"
                isColumn
                value={
                  <b className={`${isBad ? "error" : "text-[#32A56E]"}`}>
                    {}
                    {item?.actual_amount.toString()}
                  </b>
                }
              />
            )}
            <FieldTabs
              label="условие"
              arr={conditions}
              placeholder="Выберите условие"
              value={track.condition}
              edited
              width={30}
              isColumn={!!item.track_id}
              updFn={(fieldValue: TCondition) => {
                setTrack({
                  ...track,
                  condition: fieldValue,
                });
              }}
            />
            <FieldRow
              label="триггер баланса"
              value={track.amount.toString()}
              defaultValue={track.amount}
              edited
              isColumn={!!item.track_id}
              placeholder="Введите значение отслеживания"
              updFn={(fieldValue) => {
                setTrack({
                  ...track,
                  amount: +fieldValue,
                });
              }}
            />
          </div>
          {!!item.updated_at && (
            <FieldRow label="обновлено" value={convertToDate(item.updated_at)} />
          )}
          {!disabled && (
            <button
              className={`bg-light self-end px-[16px] my-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300`}
              onClick={async () => {
                setIsAddTrack(false);
                await baseRequest({
                  method: !!item.track_id
                    ? controlApi.updateTrack(track)
                    : controlApi.createTrack(track),
                  methodType: !!item.track_id ? EMethod.UPDATE : EMethod.CREATE,
                  title: `${!!item.track_id ? "Обновление" : "Создание"} монеты`,
                  name: "Track",
                  setter: setTracks,
                });
              }}>
              {!!item.track_id ? "Обновить" : "Создать"}
            </button>
          )}
        </div>
      ) : (
        <div>Нет монет в сети. Создайте монеты</div>
      )}
      {!!item.track_id && (
        <div className="flex flex-col gap-8">
          <div className="font-bold text-lightFont">Уведомления:</div>
          <button
            type="button"
            className={`flex items-center gap-[16px] rounded-md border border-[#72E5AE33] bg-[#72E5AE22] text-[#32A56E] px-[10px] py-2`}
            onClick={() => setIsAdd(!isAdd)}>
            <div className="relative -top-px text-[16px] leading-3 font-bold">+</div>
            Добавить уведомление
          </button>
          {isAdd && !!setNotifications && (
            <Notification
              setNotifications={setNotifications}
              baseRequest={baseRequest}
              isLoading={isLoading}
              setIsAddNotific={setIsAdd}
              item={{
                track_id: item.track_id,
                chat_type: "user",
                chat_id: 0,
                content: "",
              }}
            />
          )}
          {!!itemNotifications.length && !!setNotifications && (
            <div className="flex flex-col gap-8">
              {itemNotifications?.map((el) => (
                <Notification
                  key={el.notification_id}
                  item={el}
                  setNotifications={setNotifications}
                  baseRequest={baseRequest}
                  isLoading={isLoading}
                  setIsAddNotific={setIsAdd}
                />
              ))}
            </div>
          )}
        </div>
      )}
      {isPopup && (
        <Popup closeModal={() => setPopup(false)}>
          <div className="text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nотслеживание по монете `}
              <b>{coin?.coin_name}</b>?
            </div>
            <button
              type="button"
              className="prime-button-sm bg-font"
              disabled={isLoading}
              onClick={async () => {
                await baseRequest({
                  method: controlApi.deleteTrack(item?.track_id),
                  methodType: EMethod.DELETE,
                  title: "Удаление отслеживания",
                  name: "Track",
                  setter: setTracks,
                });
                setPopup(false);
              }}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
