import { centrifuge } from "./centrifugo";
import { store } from "../../store/store";
import {
  setTimeline,
  setTimelineCity,
  setTimelineCities,
  setTimelineLastUpd,
} from "../../store/clusterReducer";

export const setTimelineCitiesByCentrifugo = async (items) => {
  const updItemVal = {
    key: "SET_TIMELINE_CITIES",
    cities: [...new Set(items)]?.sort((a, b) => (a > b ? 1 : -1)) || [],
  };
  try {
    await centrifuge.publish(`timeline:cities`, JSON.stringify(updItemVal));
  } catch (e) {
    console.log(e);
  }
};

export const setTimelineByCentrifugo = async (items, cityId) => {
  const updItemVal = {
    key: "SET_TIMELINE",
    city_id: cityId,
    timelineItems: items,
  };
  try {
    await centrifuge.publish(`timeline:${cityId}`, JSON.stringify(updItemVal));
  } catch (e) {
    console.log(e);
  }
};

export const setTimelineUpdatesByCentrifugo = async (dateString) => {
  const updItemVal = {
    key: "SET_TIMELINE_UPDATES",
    lastUpd: dateString,
  };
  try {
    await centrifuge.publish(`timeline:updates`, JSON.stringify(updItemVal));
  } catch (e) {
    console.log(e);
  }
};

export const createTimelineUpdatesSubscription = (resubscribe, unsubscribe) => {
  let subscription = centrifuge.subscribe(`timeline:updates`);
  if (resubscribe) {
    subscription.unsubscribe();
    subscription = centrifuge.subscribe(`timeline:updates`);
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("publish", function (data) {
    const msg = JSON.parse(data.data);
    const { key, lastUpd } = msg;
    switch (key) {
      case "SET_TIMELINE_UPDATES":
        store.dispatch(setTimelineLastUpd(lastUpd));
        break;
      default: {
        console.log(msg);
      }
    }
  });
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        const data = JSON.parse(res.publications[0].data);
        store.dispatch(setTimelineLastUpd(data?.lastUpd || "empty"));
      } else {
        store.dispatch(setTimelineLastUpd("empty"));
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const createTimelineCitiesSubscription = (resubscribe, unsubscribe) => {
  let subscription = centrifuge.subscribe(`timeline:cities`);
  if (resubscribe) {
    subscription.unsubscribe();
    subscription = centrifuge.subscribe(`timeline:cities`);
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("publish", function (data) {
    const msg = JSON.parse(data.data);
    const { key, cities } = msg;
    switch (key) {
      case "SET_TIMELINE_CITIES":
        store.dispatch(setTimelineCities(cities));
        break;
      default: {
        console.log(msg);
      }
    }
  });
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        const data = JSON.parse(res.publications[0].data);
        store.dispatch(setTimelineCities(data.cities));
      } else {
        store.dispatch(setTimeline([]));
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const createTimelineSubscription = (cityId, resubscribe, unsubscribe) => {
  let subscription = centrifuge.subscribe(`timeline:${cityId}`);
  if (resubscribe) {
    subscription.unsubscribe();
    subscription = centrifuge.subscribe(`timeline:${cityId}`);
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("publish", function (data) {
    const msg = JSON.parse(data.data);
    const { key, city_id, timelineItems } = msg;
    switch (key) {
      case "SET_TIMELINE":
        store.dispatch(
          setTimelineCity({
            city_id: city_id,
            items: !!timelineItems
              ? timelineItems
              : {
                  city_id: city_id,
                  vectors: [],
                },
          })
        );
        break;
      default: {
        console.log(msg);
      }
    }
  });
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (!!res.publications[0]?.data) {
        const item = JSON.parse(res.publications[0].data);
        store.dispatch(
          setTimelineCity({
            city_id: item.city_id,
            items: item.timelineItems,
          })
        );
      } else {
        store.dispatch(
          setTimelineCity({
            city_id: cityId,
            items: {
              city_id: cityId,
              vectors: [],
            },
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  });
};
