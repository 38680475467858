export const AdderButton = ({
  title,
  isExist,
  fn,
}: {
  title: string;
  isExist: boolean;
  fn: () => void;
}) => {
  return (
    <button
      type="button"
      className={`badge ${isExist ? "bg-font" : "bg-lightGray"}`}
      onClick={() => fn()}>
      {title}
    </button>
  );
};
