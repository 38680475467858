import { centrifuge } from "./centrifugo";
import { store } from "../../store/store";
import {
  setFlights,
  setOffset,
  setReviews,
  setStats,
  setStatsData,
  updateStats,
} from "../../store/statsReducer";

export const createStatisticsSubscription = (resubscribe, unsubscribe) => {
  const subscription = centrifuge.subscribe(`analytics:statistics`, async function (res) {
    !!res?.data && store.dispatch(setStats(res.data));
    !!res?.offset && store.dispatch(setOffset(res.offset));
  });
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`analytics:statistics`, async function (res) {
      !!res?.data && store.dispatch(setStats(res.data));
      !!res?.offset && store.dispatch(setOffset(res.offset));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        store.dispatch(setStats(res.publications[0].data));
        store.dispatch(setOffset(res.offset));
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const createStatsByRatioSubscription = (resubscribe, unsubscribe) => {
  const subscription = centrifuge.subscribe(`analytics:statistics_ratio`, async function (res) {
    !!res?.data && store.dispatch(setStatsData(res.data));
    !!res?.offset && store.dispatch(setOffset(res.offset));
  });
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`analytics:statistics_ratio`, async function (res) {
      !!res?.data && store.dispatch(setStatsData(res.data));
      !!res?.offset && store.dispatch(setOffset(res.offset));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        store.dispatch(setStatsData(res.publications[0].data));
        store.dispatch(setOffset(res.offset));
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const createBoostSubscription = (resubscribe, unsubscribe) => {
  const subscription = centrifuge.subscribe(`analytics:boost`, async function (res) {
    store.dispatch(updateStats(res.data));
  });
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`analytics:boost`, async function (res) {
      store.dispatch(updateStats(res.data));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        store.dispatch(updateStats(res.publications[0].data));
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const createBoostByRatioSubscription = (resubscribe, unsubscribe) => {
  const subscription = centrifuge.subscribe(`analytics:boost_ratio`, async function (res) {
    store.dispatch(updateStats(res.data));
  });
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`analytics:boost_ratio`, async function (res) {
      store.dispatch(updateStats(res.data));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        store.dispatch(updateStats(res.publications[0].data));
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const createAlertsSubscription = (resubscribe, unsubscribe) => {
  const subscription = centrifuge.subscribe(`analytics:alerts`, async function (res) {
    store.dispatch(setReviews(res.data.reviews.negative));
  });
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`analytics:alerts`, async function (res) {
      store.dispatch(setReviews(res.data.reviews.negative));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        store.dispatch(setReviews(res.publications[0].data.reviews.negative));
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const createFlightsSubscription = (resubscribe, unsubscribe) => {
  const subscription = centrifuge.subscribe(`alerts:xml`, async function (res) {
    store.dispatch(setFlights(res.data));
  });
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`alerts:xml`, async function (res) {
      store.dispatch(setFlights(res.data));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        store.dispatch(setFlights(res.publications[0].data));
      }
    } catch (e) {
      console.log(e);
    }
  });
};
