export const CreateButton = ({ title, isAdd, setIsAdd }) => {
  return (
    <button
      type="button"
      className="flex gap-8 text-[#282c3466] duration-300 hover:opacity-70"
      onClick={() => setIsAdd(!isAdd)}>
      <div className="flex justify-center items-center w-[16px] min-w-[16px] h-[16px] rounded-sm bg-stale font-normal text-base pb-[3px]">
        <span
          className={`duration-300 relative ${isAdd ? "rotate-45 left-[1px]" : "rotate-0 left-0"}`}>
          +
        </span>
      </div>
      <div>{title}</div>
    </button>
  );
};
