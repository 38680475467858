import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { fetchRequest } from "src/helpers/fetchRequest";
// import controlApi from "src/api/control";
import {
  // setControlBadges,
  selectControlBadges,
  setRouteMap,
  selectRouteMap,
} from "src/store/commonReducer";
import { NavLink } from "react-router-dom";
import { MiniNotice } from "src/components/UI/notice/Notice";
import { TRoute } from "./routes";
import arrow from "src/assets/images/arrow.svg";
import { LS } from "src/api/constants";

export const ControlNav = ({ routes }) => {
  const dispatch = useDispatch();
  const badges = useSelector(selectControlBadges);
  const [isOpen, setOpen] = useState(false);
  const routeMap = useSelector(selectRouteMap);
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");

  // const fetchBadges = async () => {
  //   const { response: sitesBadges } = await fetchRequest(controlApi.getSitesBadges(), {
  //     request: "Всего отчетов по сайтам",
  //   });
  //   if (sitesBadges) {
  //     dispatch(setControlBadges({ tab: "site", data: sitesBadges }));
  //   }
  //   const { response: aggregatorBadges } = await fetchRequest(controlApi.getAggregatorBadges(), {
  //     request: "Всего отчетов в агрегаторах",
  //   });
  //   if (aggregatorBadges) {
  //     dispatch(setControlBadges({ tab: "aggregator", data: aggregatorBadges }));
  //   }
  // };

  // useEffect(() => {
  //   if (!isMerch) {
  //     fetchBadges();
  //   }
  // }, []);

  return (
    <div
      className={`fixed bg-light h-full h-[96vh] duration-500 z-[20] md:z-[1] overflow-hidden ${
        isOpen ? "max-w-[200px] w-[200px] px-[20px]" : "max-w-[30px] w-[30px] px-0"
      } md:min-w-[200px] md:w-[200px] md:max-w-[200px] md:px-[20px] pt-[70px] md:pt-[50px] pb-0 mt-[-48px] font-light text-sm border-r border-stale text-left`}>
      <div
        className={`relative w-full h-full flex flex-col gap-8 duration-500 ${
          isOpen ? "opacity-100" : "pl-[50px] opacity-50 md:pl-0 md:opacity-100"
        }`}>
        <button
          className={`block md:hidden absolute top-[-20px] left-8`}
          onClick={() => setOpen(!isOpen)}>
          <img
            src={arrow}
            alt="меню"
            width={16}
            height={16}
            className={`duration-500 relative ${!isOpen ? "-rotate-90" : "left-[-22px] rotate-90"}`}
          />
        </button>
        {Object.values(routes)?.map((el1: TRoute, index1: number) => {
          if (user_access?.some((access) => el1?.access?.includes(access))) {
            return (
              <div key={index1}>
                <NavLink
                  to={`${el1.route[1]}`}
                  className={({ isActive }) => (!isActive ? "hover:font-medium" : "font-bold")}
                  onClick={() =>
                    dispatch(
                      setRouteMap([
                        {
                          title: el1.route[0],
                          route: el1.route[1],
                        },
                      ])
                    )
                  }>
                  {el1.route[0]}
                </NavLink>
                {el1?.children !== null && routeMap[0]?.title === el1.route[0] && (
                  <div className=" ml-3 my-8">
                    {Object.values(el1?.children)?.map((el2: TRoute, index2: number) => (
                      <div key={`${index1}_${index2}`} className="text-xs my-4 relative">
                        {badges &&
                          typeof el1?.route[1] === "string" &&
                          badges[el1?.route[1]?.replace("/", "")]?.[el2?.route[1]] > 0 && (
                            <MiniNotice
                              count={badges[el1?.route[1]?.replace("/", "")]?.[el2.route[1]]}
                              isVisible={isOpen}
                            />
                          )}
                        <NavLink
                          to={`${el1.route[1]}${el2.route[1]}`}
                          className={({ isActive }) =>
                            !isActive ? "hover:font-medium" : "font-bold"
                          }
                          onClick={() =>
                            dispatch(
                              setRouteMap([
                                {
                                  title: el1.route[0],
                                  route: el1.route[1],
                                },
                                {
                                  title: el2.route[0],
                                  route: `${el1.route[1]}${el2.route[1]}`,
                                },
                              ])
                            )
                          }>
                          {el2.route[0]}
                        </NavLink>
                        {el2?.children !== null && (
                          <div className="pl-[10px] my-8">
                            {Object.values(el2?.children)?.map((el3: TRoute, index3: number) => (
                              <div
                                key={`${index1}_${index2}_${index3}`}
                                className="relative text-[10px]">
                                <NavLink
                                  to={`${el1.route[1]}${el2.route[1]}${el3.route[1]}`}
                                  className={({ isActive }) =>
                                    !isActive ? "hover:font-medium" : "font-bold"
                                  }
                                  onClick={() =>
                                    dispatch(
                                      setRouteMap([
                                        {
                                          title: el1.route[0],
                                          route: el1.route[1],
                                        },
                                        {
                                          title: el2.route[0],
                                          route: `${el1.route[1]}${el2.route[1]}`,
                                        },
                                        {
                                          title: el3.route[0],
                                          route: `${el1.route[1]}${el2.route[1]}${el3.route[1]}`,
                                        },
                                      ])
                                    )
                                  }>
                                  {el3.route[0]}
                                </NavLink>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
