import { Dispatch, SetStateAction, useState } from "react";
import { TBaseCurrency, TMinMaxCurrency, TMinMaxSheet } from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { CurrencyForm } from "./CurrencyForm";
import copy from "src/assets/images/copy.svg";
import paste from "src/assets/images/empty.svg";

type TProps = {
  item: TMinMaxCurrency | null;
  copiedItem: TMinMaxCurrency | null;
  setCopiedItem: Dispatch<SetStateAction<TMinMaxCurrency | null>>;
  currency: TBaseCurrency;
  site: TMinMaxSheet;
  fetchSheet: () => void;
  access: boolean;
  selectedToPaste: Array<TMinMaxCurrency & { site_id: number }>;
  setSelectedToPaste: Dispatch<SetStateAction<Array<TMinMaxCurrency & { site_id: number }>>>;
};

export const SheetCurrencyItem = ({
  item,
  currency,
  site,
  fetchSheet,
  access,
  copiedItem,
  setCopiedItem,
  selectedToPaste,
  setSelectedToPaste,
}: TProps) => {
  const [isForm, setIsForm] = useState(false);
  const itPaste = !!selectedToPaste?.find((el) => el.sheet_id === item?.sheet_id);

  const copyFn = () => {
    if (!copiedItem) {
      setCopiedItem(item);
    }
    if (!!copiedItem && copiedItem?.sheet_id === item?.sheet_id) {
      setCopiedItem(null);
      setSelectedToPaste([]);
    }
    if (!!copiedItem && copiedItem?.sheet_id !== item?.sheet_id) {
      if (!!selectedToPaste?.find((el) => el.sheet_id === item?.sheet_id)) {
        setSelectedToPaste(selectedToPaste?.filter((el) => el.sheet_id !== item?.sheet_id));
      } else {
        setSelectedToPaste([...selectedToPaste, { ...item, site_id: site.site_id }]);
      }
    }
  };

  return (
    <div className={`relative`}>
      {access && (
        <button type="button" className="absolute right-2 top-2 z-[1]" onClick={copyFn}>
          <img
            src={!!copiedItem && copiedItem?.sheet_id !== item?.sheet_id ? paste : copy}
            alt="copy"
            width={16}
            height={16}
          />
        </button>
      )}
      {!!item ? (
        <div
          className={`relative text-xs cursor-pointer duration-300  h-full ${
            itPaste
              ? "bg-[#BBFFDA]"
              : copiedItem?.sheet_id === item?.sheet_id
              ? "bg-[#3BC57A]"
              : !!copiedItem && copiedItem?.sheet_id !== item?.sheet_id
              ? "bg-glass-gray opacity-30"
              : ""
          }`}
          onClick={() => setIsForm(true)}>
          {item?.is_exceptions && (
            <div
              className={`absolute right-2 ${
                access ? "top-[18px]" : "top-2"
              } flex justify-center bg-[#3BC57A] h-[16px] w-[16px] rounded-sm text-[#BBFFDA] font-bold text-[20px] leading-[21px]`}>
              *
            </div>
          )}
          <div className={`grid grid-cols-2 divide-x divide-lightGray`}>
            <div className="px-8">
              <div>{item?.min_summ_office || 0}</div>
              <div>{item?.max_summ_office || 0}</div>
              <div>{item?.reserve_office || 0}</div>
            </div>
            <div className="px-8">
              <div>{item?.min_summ_region || 0}</div>
              <div>{item?.max_summ_region || 0}</div>
              <div>{item?.reserve_region || 0}</div>
            </div>
          </div>
          <div className="px-8 text-lightFont text-[10px] pt-2">{item?.fix_course_count}</div>
        </div>
      ) : (
        <div className="h-full w-full cursor-pointer" onClick={() => setIsForm(true)}></div>
      )}
      {isForm && (
        <Popup closeModal={() => setIsForm(false)}>
          <CurrencyForm
            site={site}
            currency={currency}
            item={item}
            fetchSheet={fetchSheet}
            closeFn={setIsForm}
            access={access}
          />
        </Popup>
      )}
    </div>
  );
};
