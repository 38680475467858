import { ReactElement } from "react";

export const OptionLabel = ({
  label,
  isFirstError,
  textFirstError,
  firstColor,
  isSecondError,
  textSecondError,
  secondColor,
}: {
  label: string;
  isFirstError: boolean;
  textFirstError: string;
  firstColor: string;
  isSecondError?: boolean;
  textSecondError?: string;
  secondColor?: string;
}): ReactElement => {
  return (
    <div className="flex flex-col gap-4 justify-center">
      <div>{label}</div>
      {isFirstError && <small style={{ color: firstColor || "inherit" }}>{textFirstError}</small>}
      {isSecondError && (
        <small style={{ color: secondColor || "inherit" }}>{textSecondError || ""}</small>
      )}
    </div>
  );
};
