import { Dispatch, SetStateAction, useState } from "react";
import devApi from "src/api/developers";
import { fetchRequest } from "src/helpers/fetchRequest";
import { TProxy, TSourceVersion } from "../types";

export const Prolong = ({
  item,
  updateItem,
  version,
  isLoading,
  setLoading,
}: {
  item: TProxy;
  updateItem: (item: TProxy) => void;
  version: TSourceVersion;
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const [period, setPeriod] = useState(0);
  const [isUpdated, setUpdated] = useState<boolean | null>(null);
  const disabled = isLoading || period < 1;

  const prolong = async () => {
    setLoading(true);
    const payload = {
      proxy_id: item.proxy_id,
      proxy_period: period,
    };
    const { response, error } = await fetchRequest(devApi.prolongProxy(payload), {
      request: "Продление прокси",
    });
    if (response) {
      updateItem(response);
      setUpdated(true);
    }
    if (error) {
      setUpdated(false);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col p-8 -mx-8 rounded-md border border-lightGray bg-lightStale">
      <div className={`grid grid-cols-[60px_1fr] items-baseline gap-[10px]`}>
        <div>period:</div>
        <div className="flex flex-wrap gap-4">
          {version.prolong_periods?.map((el) => (
            <button
              key={el}
              type="button"
              onClick={() => setPeriod(el)}
              className={`w-fit rounded-sm text-[#FFF] text-[10px] px-4 font-medium h-[15px] ${
                period === el ? "bg-[#FCC815]" : "bg-[#BABAC3]"
              }`}>
              {el}d
            </button>
          ))}
        </div>
      </div>
      <button
        type="button"
        onClick={() => prolong()}
        className={`prime-button-sm flex gap-8 text-xs pl-8 mt-8 mb-0 self-end duration-300 ${
          disabled ? "opacity-30" : ""
        } ${isUpdated ? "bg-pistachio" : isUpdated === null ? "bg-font" : "bg-[#D7443E88]"}`}
        disabled={disabled}>
        <span>{`\u23F1`}</span>
        <span>
          {isUpdated ? "Успешно продлена" : isUpdated === null ? "Продлить" : "Ошибка продления"}
        </span>
      </button>
    </div>
  );
};
