/* Works only with static arrays with mutated items */
export const getChangedArrayItems = <T>(initialData: T[], currentData: T[]): T[] => {
  if (initialData.length !== currentData.length) throw "Data lengths must be equal.";

  const changedItems: T[] = [];

  /* Returns true if items are not equal and false otherwise */
  const check = <R>(initial: R, current: R): boolean => {
    // compare primitives
    if (typeof initial !== "object" && typeof current !== "object") return initial !== current;

    // primitive and object
    if (typeof initial !== "object" || typeof current !== "object") return true;

    for (const key of Object.keys(current)) {
      if (check(initial[key], current[key])) return true;
    }

    return false;
  };

  for (let i = 0; i < currentData.length; i++) {
    if (check(initialData[i], currentData[i])) changedItems.push(currentData[i]);
  }

  return changedItems;
};
