import "../UI/loader/Loader.css";

export const Spinner = () => {
  return (
    <div className="spinner d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <div className="loader" />
      </div>
    </div>
  );
};
