import { TCurrencyTypesFilter } from "src/store/directionsReducer";
import { ICity } from "src/types/OnOffTimeline/common";

export const filterOnOffData = (data: ICity[], filters: TCurrencyTypesFilter): ICity[] => {
  return [...data]
    .map((city) => {
      if (city.currency_pairs.length === 0) {
        return;
      }

      return {
        ...city,
        currency_pairs: city.currency_pairs
          .map((pair) => {
            if (pair.sites.length === 0) {
              return;
            }

            return {
              ...pair,
              sites: pair.sites.filter((site) => {
                if (
                  !site.segments_site.length &&
                  !site.segments_best.length &&
                  !site.unsubmitted_segments_best?.length &&
                  !site.unsubmitted_segments_site?.length
                ) {
                  return false;
                }

                return true;
              }),
            };
          })
          .filter(
            (pair) =>
              pair &&
              pair.sites.length > 0 &&
              filters.from.includes(pair.currency_from.type) &&
              filters.to.includes(pair.currency_to.type)
          ),
      };
    })
    .filter((item) => item && item.currency_pairs.length > 0);
};
