import { getArrLabels } from "src/helpers/formHelpers";
import { TGlobalCurrency, TGlobalDirection } from "src/store/directionsReducer";
import { TFilters } from "src/store/tetrisReduser";

export const allFilter = (
  el: string | TGlobalDirection | TGlobalCurrency,
  attr: "sites" | "cities" | "from" | "to",
  filters: TFilters
) => {
  switch (attr) {
    case "sites": {
      if (filters?.sites !== null && !!filters?.sites?.length) {
        return getArrLabels(filters, "sites")?.includes(el as string);
      } else {
        return el;
      }
    }
    case "from": {
      if (filters?.from !== null && !!filters?.from?.length && typeof el !== "string") {
        return getArrLabels(filters, "from")?.includes(
          "currency_from" in el ? el.currency_from : el.currency_name
        );
      } else {
        return el;
      }
    }
    case "to": {
      if (filters?.to !== null && !!filters?.to?.length && typeof el !== "string") {
        return getArrLabels(filters, "to")?.includes(
          "currency_to" in el ? el.currency_to : el.currency_name
        );
      } else {
        return el;
      }
    }
    case "cities": {
      if (filters?.cities !== null && !!filters?.cities?.length) {
        return getArrLabels(filters, "cities")?.includes(el as string);
      } else {
        return el;
      }
    }
    default: {
      return el;
    }
  }
};
