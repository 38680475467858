import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/store";
import { fetchRequest } from "src/helpers/fetchRequest";
import academyApi from "src/api/academy";
import { selectQuestions, setQuestions } from "src/store/academyReducer";
import { ESectionType, TBlock } from "../../types";
import { Question } from "../Question/Question";
import { EditQuestion } from "../Question/EditQuestion";
import arrow from "src/assets/images/arrow.svg";

export const Block = ({
  item,
  isEditPage,
  isExpired,
  isFirst,
}: {
  item: TBlock;
  isEditPage: boolean;
  isExpired?: boolean;
  isFirst?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const block_id = searchParams.get("block_id");
  const module_type = searchParams.get("type");
  const questions = useSelector(selectQuestions);
  const currentQuestions = questions?.[0]?.block_id === item.block_id ? questions : [];
  const [isCreate, setIsCreate] = useState(false);
  const question_attr_name = module_type === ESectionType.THEORY ? "пункт" : "вопрос";

  useEffect(() => {
    if (isFirst) {
      searchParams.set("block_id", item.block_id?.toString());
      setSearchParams(searchParams);
    }
  }, []);

  const fetchQuestions = async () => {
    const { response } = await fetchRequest(academyApi.getQuestions(item.block_id, isEditPage), {
      request: `${question_attr_name}ы`,
    });
    if (response) {
      dispatch(setQuestions(response));
    }
  };

  useEffect(() => {
    if (+block_id === item.block_id) {
      fetchQuestions();
    }
  }, [block_id]);

  return (
    <div className={`flex flex-col gap-5`}>
      <div
        className={`flex justify-between items-center text-xl text-left font-semibold duration-300 w-full border-l-4 p-5 w-full ${
          +block_id === item.block_id
            ? isEditPage
              ? "bg-purple-100 border-purple-300"
              : "bg-emerald-100 border-emerald-300"
            : "bg-slate-100 border-slate-300 hover:opacity-60 cursor-pointer"
        }`}
        onClick={() => {
          searchParams.set("block_id", item.block_id?.toString());
          setSearchParams(searchParams);
        }}>
        {item.block_name}
        {+block_id !== item.block_id && <img src={arrow} alt="открыть" width={10} height={10} />}
      </div>
      {!!currentQuestions?.length && (
        <div className="capitalize text-slate-400">{`${question_attr_name}ы`}</div>
      )}
      <div className="flex flex-col gap-10">
        {!!currentQuestions?.length &&
          currentQuestions?.map((el, index) => {
            const key = !!el.question_id
              ? `${el.question_id}_question_id`
              : `${index}_new_question`;
            return (
              <Question key={key} question={el} isEditPage={isEditPage} isExpired={isExpired} />
            );
          })}
      </div>
      {isEditPage &&
        (!isCreate ? (
          <button
            className="relative w-[280px] sm:w-[360px] py-8 bg-slate-50 shadow-md rounded-md flex justify-center items-center duration-300 hover:scale-105 hover:shadow-lg font-semibold text-blue"
            onClick={() => setIsCreate(true)}>
            Создать {question_attr_name}
          </button>
        ) : (
          <div className="bg-purple-50 shadow-md rounded-md p-5">
            <h3>Новый {question_attr_name}</h3>
            <EditQuestion setIsEdit={setIsCreate} />
          </div>
        ))}
    </div>
  );
};
