import { useSelector } from "react-redux";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { Url } from "src/api/constants";
import { Button } from "src/shadcn/ui/ui/button";
import { ESectionType, TNav } from "../types";
import { useAppDispatch } from "src/store/store";
import { reset, selectAttempt, setBlocks, setQuestions } from "src/store/academyReducer";

export const TestNav = ({ tabs, isEdit }: { tabs: TNav[]; isEdit: boolean }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const course_id = searchParams.get("id");
  const tabType = searchParams.get("type");
  const module_id = searchParams.get("module_id");
  const tab = useLocation().pathname?.split("/")[2];
  const attempt = useSelector(selectAttempt);

  const navTabs = !tabs?.length
    ? []
    : isEdit
    ? [
        {
          title: "Общие данные",
          path: Url.ACADEMY + Url.ACADEMY_EDIT + `?id=${course_id}`,
          module_id: null,
        },
        ...tabs,
      ]
    : tabs;

  return (
    <div className="flex gap-4 -mt-5 w-full overflow-x-auto pb-8 ml-[16px] md:ml-0">
      <Button asChild variant="outline">
        <NavLink
          to={Url.ACADEMY}
          className={`px-5 py-4 h-fit duration-300`}
          onClick={() => {
            dispatch(reset());
          }}>
          На главную
        </NavLink>
      </Button>
      {navTabs?.map((el) => {
        const disabled =
          !isEdit && !!el.module_id && !!attempt && attempt?.module?.module_id !== +el.module_id;
        const isActive =
          tab === el.path ||
          (!!module_id && module_id === el.module_id) ||
          (!tabType && !module_id && el.module_id === null);

        return (
          <Button
            key={el.path}
            className={disabled ? "bg-stone-200" : ""}
            asChild
            variant={isActive ? "default" : "outline"}
            disabled={disabled}>
            {!disabled ? (
              <NavLink
                to={el.path}
                className={`px-5 py-4 h-fit ${isActive ? "font-bold" : ""} flex gap-3 duration-300`}
                onClick={() => {
                  dispatch(setBlocks([]));
                  dispatch(setQuestions([]));
                }}>
                {el.module_type === ESectionType.THEORY && "📖"}
                {el.module_type === ESectionType.PRACTICE && "🖊"}
                {el.module_type === ESectionType.EXAMINATION && "🎓"}
                <span>{el.title}</span>
              </NavLink>
            ) : (
              <span
                className={`px-5 py-4 h-fit ${
                  isActive ? "font-bold" : ""
                } flex gap-3 duration-300`}>
                {el.title}
              </span>
            )}
          </Button>
        );
      })}
    </div>
  );
};
