import { createSlice } from "@reduxjs/toolkit";
import { ICountryData, IRates, IRedactors, ISites, IVectorPE, TLimits } from "src/pages/Bid/models";
import { StateT } from "./store";

const bidSlice = createSlice({
  name: "bid",
  initialState: {
    rates: [],
    limits: [] as TLimits[],
    countries: [] as ICountryData[],
    staticCountries: [] as ICountryData[],
    redactors: [] as Array<IRedactors>,
    sites: [] as Array<ISites>,
    vectorsPE: [] as Array<IVectorPE>,
  },
  reducers: {
    setRates: (state, action) => {
      state.rates = action.payload;
    },
    setLimits: (state, action) => {
      state.limits = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setStaticCountries: (state, action) => {
      state.staticCountries = action.payload;
    },
    setRedactor: (state, { payload }) => {
      state.redactors = [...state.redactors, { vector: payload.vector, user: payload.user }];
    },
    deleteRedactor: (state, { payload }) => {
      state.redactors = state.redactors?.filter((el) => el.vector !== payload.vector);
    },
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setVectorsPE: (state, action) => {
      state.vectorsPE = action.payload;
    },
  },
});

export const {
  setRates,
  setLimits,
  setCountries,
  setStaticCountries,
  setRedactor,
  deleteRedactor,
  setSites,
  setVectorsPE,
} = bidSlice.actions;

export const selectRates = (state: StateT): IRates[] => state.bid.rates;
export const selectLimits = (state: StateT): TLimits[] => state.bid.limits;
export const selectCountries = (state: StateT): ICountryData[] => state.bid.countries;
export const selectStaticCountries = (state: StateT): ICountryData[] => state.bid.staticCountries;
export const selectRedactors = (state: StateT): Array<IRedactors> => state.bid.redactors;
export const selectSites = (state: StateT): Array<ISites> => state.bid.sites;
export const selectVectorsPE = (state: StateT): Array<IVectorPE> => state.bid.vectorsPE;

export default bidSlice.reducer;
