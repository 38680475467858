import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { LS } from "src/api/constants";
import { cn } from "src/helpers/cn";
import { timeToSeconds } from "src/helpers/date";
import { getChangesAfterCopy, getThePromised } from "src/helpers/onOffTimeline/copyPaste";
import { Segment } from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/Segment";
import {
  confirmDelete,
  confirmUndo,
} from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/TimelineData";
import { ICity, ISite, OnOffItemToDeleteType } from "src/types/OnOffTimeline/common";
import { SelectedItem } from "src/types/OnOffTimeline/store";
import { TGlobalCurrency } from "src/store/directionsReducer";
import {
  selectOnOffDeleteMode,
  selectOnOffCopiedItem,
  selectOnOffIsCopy,
  selectOnOffTimelineStep,
  selectOnOffTimelineZoom,
} from "src/store/onOffTimelineReducer/selectors";
import {
  onOffTimeLineUndo,
  setCopiedItem,
  setOnOffConfirmation,
  setSelectedItem,
  updateSiteSegments,
} from "src/store/onOffTimelineReducer/slice";
import { deleteOnOffEntity } from "src/store/onOffTimelineReducer/thunks/deleteOnOffEntity";
import { ESource } from "src/store/onOffReducer";
import { useAppDispatch } from "src/store/store";
import { v4 } from "uuid";
import { DeleteConfirmText } from "src/pages/Panel/OnOff/OnOffTimeline/components/popups/OnOffConfirmPopup";

interface SiteWithSegmentsProps {
  data: ISite;
  city: Partial<ICity>;
  groupName: string;
  currencyFrom: TGlobalCurrency;
  currencyTo: TGlobalCurrency;
  index: number;
}

export const SiteWithSegments = memo(
  ({ data, city, groupName, currencyFrom, currencyTo, index }: SiteWithSegmentsProps) => {
    const isAccess = !!localStorage.getItem(LS.ACCESS)?.includes("on-off_post");

    const dispatch = useAppDispatch();

    const deleteMode = useSelector(selectOnOffDeleteMode);

    const username = localStorage.getItem(LS.USERNAME);

    const copiedItem = useSelector(selectOnOffCopiedItem);
    const isCopy = useSelector(selectOnOffIsCopy);
    const step = useSelector(selectOnOffTimelineStep);
    const zoom = useSelector(selectOnOffTimelineZoom);
    const axisX = useMemo(() => Array.from(Array(1440 / step).keys()), [step]);
    const now = new Date();
    const nowTime = Math.round(timeToSeconds(`${now.getHours()}:${now.getMinutes()}`) / 60);
    const past = nowTime % 3;
    const nowEl = Math.ceil(nowTime / step);

    const isEdited =
      data.unsubmitted_segments_best?.length > 0 || data.unsubmitted_segments_site?.length > 0;

    const isNew = data.segments_best.length === 0 && data.segments_site.length === 0 && isEdited;

    const onClick = () => {
      if (isAccess) {
        dispatch(
          setSelectedItem({
            cityCode: city.city_code,
            siteName: data.site_name,
            currencyFrom,
            currencyTo,
          })
        );
      }
    };

    const isCopied =
      copiedItem &&
      copiedItem.city.city_code === city.city_code &&
      copiedItem.currencyFrom.currency_name === currencyFrom.currency_name &&
      copiedItem.currencyTo.currency_name === currencyTo.currency_name &&
      copiedItem.currencyFrom.type === currencyFrom.type &&
      copiedItem.currencyTo.type === currencyTo.type &&
      copiedItem.site_name === data.site_name;

    const copyNoItem = isCopy && !copiedItem;
    const copyNotSelected = isCopy && copiedItem && !isCopied;

    const paste = () => {
      if (copiedItem) {
        let newData: Partial<SelectedItem> = { ...copiedItem };
        if (
          newData.unsubmitted_segments_best?.length > 0 ||
          newData.unsubmitted_segments_site?.length > 0
        ) {
          newData = getThePromised({
            segments_best: newData.segments_best,
            segments_site: newData.segments_site,
            unsubmitted_segments_best: newData.unsubmitted_segments_best,
            unsubmitted_segments_site: newData.unsubmitted_segments_site,
          });
        }

        dispatch(
          updateSiteSegments({
            ...data,
            currencyFrom,
            currencyTo,
            city,
            ...getChangesAfterCopy(
              {
                segments_best: data.segments_best,
                segments_site: data.segments_site,
              },
              { segments_best: newData.segments_best, segments_site: newData.segments_site },
              username
            ),
          })
        );
      }
    };

    const label = `${city.city_name} ${currencyFrom.currency_name}-${currencyTo.currency_name} ${data.site_name}?`;
    const name = `${city.city_code}_${groupName}_${currencyFrom.currency_name}_${currencyTo.currency_name}_${data.site_name}`;

    const onSiteNameClick = () => {
      if (deleteMode) {
        dispatch(
          setOnOffConfirmation({
            title: confirmDelete,
            subtitle: label,
            content: (
              <DeleteConfirmText text="Сайт сохранит свои последние данные, которые будут доступны здесь" />
            ),
            cb: () =>
              dispatch(
                deleteOnOffEntity({
                  type: OnOffItemToDeleteType.SITE,
                  name,
                  isNew,
                })
              ),
          })
        );
        return;
      }

      if (isCopy) {
        if (copiedItem) {
          if (isCopied) {
            dispatch(setCopiedItem(null));
          } else {
            paste();
          }
        } else {
          dispatch(
            setCopiedItem({
              cityCode: city.city_code,
              siteName: data.site_name,
              currencyFrom,
              currencyTo,
            })
          );
        }
      }
    };

    const undo = () => {
      dispatch(
        setOnOffConfirmation({
          title: confirmUndo,
          subtitle: label,
          cb: () => {
            dispatch(onOffTimeLineUndo(name));
          },
        })
      );
    };

    return (
      <div className={`grid grid-cols-[150px_1fr] duration-300`}>
        <div
          className={cn(
            "text-xs whitespace-nowrap bg-bg border-transparent text-right duration-300 sticky border left-[160px] pr-8 pl-[4px] ml-[1px] z-[7] h-[30px] flex items-center justify-end shadow-[20px_0px_30px_-15px_rgba(0,0,0,0.2)]",
            index % 2 && "bg-gradient-to-r from-[#BABAC305] via-[#BABAC320] to-[#BABAC325]",
            (isCopy || deleteMode) && "cursor-pointer",
            copyNoItem && "hover:bg-[#B2FFDE]",
            (isCopied || copyNoItem) && "border-green bg-[#B2FFDE55]",
            copyNotSelected && "border-green bg-white hover:bg-bg hover:opacity-70",
            deleteMode && "border border-red bg-[#F6ECF1] hover:bg-[#F5D7DA] duration-300",
            isEdited && !isNew && "onOffEdited after:hidden",
            isNew && "onOffNew after:hidden"
          )}
          onClick={onSiteNameClick}>
          {data.site_name}
          {isEdited && !deleteMode && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                undo?.();
              }}
              className={cn(
                "ml-[4px] hover:bg-gray/10 text-[12px] rounded-sm transition px-[6px] aspect-square flex items-center justify-center cursor-pointer"
              )}>
              <span>{`\u2936`}</span>
            </button>
          )}
        </div>
        <div
          className={`flex flex-col h-[30px] border-t-[0.5px] border-gray/30 relative cursor-pointer ${
            isCopied || copyNoItem
              ? "border-green bg-[#B2FFDE55]"
              : `${copyNotSelected ? "border-green bg-white" : "bg-bg"}`
          }`}
          onClick={onClick}>
          <div className="flex justify-start w-fit h-full">
            {axisX &&
              axisX?.map((_, index) => {
                return (
                  <div
                    key={v4()}
                    className={`h-[30px] duration-300 hover:bg-glass-gray border-l border-glass-gray`}
                    style={{
                      background:
                        index < nowEl - 1
                          ? "#BABAC344"
                          : index === nowEl - 1
                          ? `linear-gradient(to right, #BABAC344 ${(100 / 3) * past}%, #FAFAFF00 ${
                              (100 / 3) * (3 - past)
                            }%)`
                          : "#FAFAFF00",
                      width: `${zoom}px`,
                      maxWidth: `${zoom}px`,
                    }}></div>
                );
              })}
          </div>
          {data.segments_best.map((segment) => (
            <Segment key={v4()} attr={ESource.BEST} segment={segment} />
          ))}
          {data.segments_site.map((segment) => (
            <Segment key={v4()} attr={ESource.SITE} segment={segment} />
          ))}
          {data.unsubmitted_segments_best?.map((segment) => (
            <Segment key={v4()} attr={ESource.BEST} segment={segment} />
          ))}
          {data.unsubmitted_segments_site?.map((segment) => (
            <Segment key={v4()} attr={ESource.SITE} segment={segment} />
          ))}
        </div>
      </div>
    );
  }
);
