import { createAsyncThunk } from "@reduxjs/toolkit";
import scheduleApi, { GetScheduleQuery } from "src/api/schedule";
import { fetchRequest } from "src/helpers/fetchRequest";
import { getCitiesWithGroups } from "src/helpers/onOffTimeline/getCititesWithGroups";
import { setNotific } from "src/store/mainReducer";
import {
  setOnOffIsLoading,
  setOnOffErrorMessage,
  setOnOffTimelineData,
  initExpandedState,
  setOnOffLastAppliedFilters,
} from "src/store/onOffTimelineReducer/slice";

export const getOnOffData = createAsyncThunk(
  "onOff/getData",
  async (query: GetScheduleQuery, { dispatch }) => {
    try {
      dispatch(setOnOffIsLoading(true));

      const { response, error, status } = await fetchRequest(scheduleApi.getSchedule(query));

      if (status === 204) {
        dispatch(setOnOffIsLoading(false));
        dispatch(setOnOffTimelineData([]));
        dispatch(
          setOnOffLastAppliedFilters({
            sites: query.site_names?.split(",") || [],
            cities: query.city_codes?.split(",") || [],
            from: query.currency_from_codes?.split(",") || [],
            to: query.currency_to_codes?.split(",") || [],
          })
        );
        return;
      }

      if (error || !response) {
        dispatch(
          setNotific({ type: "error", message: error?.statusText || "Ошибка при получении данных" })
        );
        dispatch(setOnOffErrorMessage("Ошибка сервиса"));
        dispatch(setOnOffIsLoading(false));
        return;
      }

      if (Array.isArray(response)) {
        const groupedCities = getCitiesWithGroups(response);
        dispatch(initExpandedState({ data: groupedCities }));
        dispatch(setOnOffTimelineData(response));
        dispatch(
          setOnOffLastAppliedFilters({
            sites: query.site_names?.split(",") || [],
            cities: query.city_codes?.split(",") || [],
            from: query.currency_from_codes?.split(",") || [],
            to: query.currency_to_codes?.split(",") || [],
          })
        );
      }

      dispatch(setOnOffIsLoading(false));
    } catch (e) {
      dispatch(setNotific({ type: "error", message: "Ошибка при загрузке данных" }));
    }
  }
);
