import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CreateProxy } from "./CreateProxy";
import { allProxyFilter, ProxyFilter } from "./ProxyFilter";
import { ProxyRow } from "./ProxyRow";
import { TProxyService, TProlongProxy, TProxy, TProxyFilter, TSourceModel } from "../types";
import search from "src/assets/images/search.svg";
import { BuyProxy } from "./BuyProxy";

export const ProxyPage = ({
  proxies,
  setProxies,
  sources,
  services,
  fetchProxy,
}: {
  proxies: TProxy[];
  setProxies: Dispatch<SetStateAction<TProxy[]>>;
  sources: TSourceModel[];
  services: TProxyService[];
  fetchProxy: () => Promise<void>;
}) => {
  const [searchInput, setSearchInput] = useState<string>();
  const [filteredArr, setFilteredArr] = useState<Array<TProxy>>([]);

  const sourcesOptions = sources?.map((el) => ({ label: el.source_name, value: el.source_id }));
  const servicesOptions = services?.map((el) => ({ label: el.service_name, value: el.service_id }));

  const [filter, setFilter] = useState<TProxyFilter>({
    sources: [],
    services: [],
    soon_exp: false,
  });

  const updateItem = (response: TProxy | TProlongProxy) => {
    const arr = proxies?.map((el) =>
      el.proxy_id === response.proxy_id
        ? {
            ...el,
            ...response,
          }
        : el
    );
    setProxies(arr);
  };

  const pushProxies = (arr: TProxy[]) => {
    const resArr: TProxy[] = [];
    !!proxies?.length && resArr.push(...proxies);
    !!arr?.length && resArr.push(...arr);
    setProxies(resArr);
  };

  const findInValues = (arr: TProxy[], value: string) => {
    value = String(value).toLowerCase();
    return arr.filter((o) => o?.full_view?.toLowerCase().includes(value));
  };

  useEffect(() => {
    if (proxies?.length) {
      if (!!searchInput?.length) {
        setFilteredArr(findInValues(proxies, searchInput));
      } else {
        setFilteredArr(proxies);
      }
    }
  }, [proxies, searchInput]);

  return (
    <div className="flex flex-col-reverse md:flex-row justify-center gap-[20px] ml-[20px] mr-[50px]">
      <div className="flex flex-col items-center w-[300px] sm:w-[512px] text-left pb-[100px]">
        <h1 className="w-full text-xl font-black">Proxy</h1>
        {!!sources && (
          <>
            <BuyProxy sources={sources} pushProxies={pushProxies} />
            <CreateProxy
              fetchProxy={fetchProxy}
              sourcesOptions={sourcesOptions}
              servicesOptions={servicesOptions}
            />
          </>
        )}
        {!!proxies?.length && (
          <div className="flex w-full relative mb-[10px]">
            <input
              className="text-xs w-full border border-[#EEE] rounded-sm outline-none px-8"
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <img
              src={search}
              alt="Найти"
              width={16}
              height={16}
              className="absolute top-2 right-4 opacity-30"
            />
          </div>
        )}
        <div className="flex flex-col gap-8 w-[300px] sm:w-[512px]">
          {filteredArr
            ?.filter((el) => allProxyFilter(el, filter))
            ?.map((el) => (
              <ProxyRow
                key={el?.proxy_id}
                item={el}
                sourcesOptions={sourcesOptions}
                servicesOptions={servicesOptions}
                fetchProxy={fetchProxy}
                updateItem={updateItem}
                sources={sources}
              />
            ))}
        </div>
      </div>
      <ProxyFilter
        proxies={proxies}
        filter={filter}
        setFilter={setFilter}
        sources={sources}
        services={services}
      />
    </div>
  );
};
