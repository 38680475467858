import { LS } from "src/api/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  createViewersSubscription,
  setViewersByCentrifugo,
} from "src/api/centrifugo/viewerCentrifugo";
import { selectViewers } from "src/store/mainReducer";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import { Avatar } from "./Avatar";

export const Viewers = () => {
  const location = useLocation();
  const page = location.pathname?.slice(1);
  const userName = localStorage.getItem(LS.USERNAME);
  const viewers = useSelector(selectViewers);

  const viewersWithMe = {};
  const viewersWithoutMe = {};
  for (const key in viewers) {
    if (key === page) {
      viewersWithMe[key] = viewers[key]?.length > 0 ? [...viewers[key], userName] : [userName];
      viewersWithoutMe[key] =
        viewers[key]?.length > 0 ? viewers[key]?.filter((el) => el !== userName) : [];
    } else {
      viewersWithMe[key] =
        viewers[key]?.length > 0 ? viewers[key]?.filter((el) => el !== userName) : [];
      viewersWithoutMe[key] =
        viewers[key]?.length > 0 ? viewers[key]?.filter((el) => el !== userName) : [];
    }
  }

  useEffect(() => {
    createViewersSubscription(false, false);
    return () => {
      setViewersByCentrifugo(viewersWithoutMe);
      createViewersSubscription(false, true);
    };
  }, []);

  useEffect(() => {
    createViewersSubscription(false, false);
    const timer = setTimeout(() => {
      if (!viewers[page]?.includes(userName)) {
        setViewersByCentrifugo(viewersWithMe);
      }
    }, 1000);
    return () => {
      setViewersByCentrifugo(viewersWithoutMe);
      createViewersSubscription(false, true);
      clearTimeout(timer);
    };
  }, [page]);

  return (
    <div className="fixed top-[60px] right-[80px] flex gap-8 w-fit justify-end text-base font-bold z-30">
      {viewers[page]?.map((el, index) => {
        return (
          <Tooltip content={el === userName ? "Вы" : el} direction="bottom" key={index}>
            <div
              className={`relative h-[40px] w-[40px] rounded-full border border-stale ${
                el === userName ? "bg-font" : "bg-bg"
              }`}>
              <Avatar username={el} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
