import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import {
  setTimelineByCentrifugo,
  setTimelineCitiesByCentrifugo,
  createTimelineSubscription,
  createTimelineCitiesSubscription,
  createTimelineUpdatesSubscription,
  setTimelineUpdatesByCentrifugo,
} from "src/api/centrifugo/timelineCentrifugo";
import { LS } from "src/api/constants";
import clusterApi from "src/api/cluster";
import {
  selectTimeline,
  selectTimelineCities,
  selectTimelineLastUpd,
  TTimeline,
  TTimelineSite,
  TTimelineVector,
} from "src/store/clusterReducer";
import { selectAllSites } from "src/store/directionsReducer";
import { SiteMenuItem } from "./components/SiteMenuItem";
import { TimelineBoard } from "./components/TimelineBoard";
import { stashEmptyRows } from "./components/TimelineFunctions/DeleteTimelineFunctions";
import { resetTimezone } from "src/helpers/date";

export const Timeline = () => {
  const username = localStorage.getItem(LS.USERNAME) || "";
  const globalSites = useSelector(selectAllSites);
  const timeline = useSelector(selectTimeline);
  const cities = useSelector(selectTimelineCities);
  const lastUpdate = useSelector(selectTimelineLastUpd);
  const [isFirstUpd, setIsFirstUpd] = useState(true);
  const [isAdded, setIsAdded] = useState("");
  const [isBest, setIsBest] = useState(false);
  const [isSitesMenuOpen, setIsSitesMenuOpen] = useState(false);

  const getVectorStr = (vectorEl: TTimelineVector) =>
    `${vectorEl?.currency_from_id}_${vectorEl?.currency_to_id}_${vectorEl?.percent}`;

  const fetchTimeline = async (isClearMyChanges: boolean) => {
    const { response } = await fetchRequest(clusterApi.getTimeline(), { request: "Таймлайн" });
    if (response) {
      const resCities = response?.map((el: TTimeline) => el.city_id);
      const newResCities = resCities?.filter((el) => !cities.includes(el)) || [];
      const updatedCities = [...cities, ...newResCities]?.sort((a, b) => (a > b ? 1 : -1));
      updatedCities?.forEach(async (el) => await createTimelineSubscription(el, false, false));
      await setTimelineCitiesByCentrifugo(updatedCities);
      const unconfirmedChanges =
        timeline?.map((city) => ({
          ...city,
          vectors: city?.vectors?.map((vector) => ({
            ...vector,
            sites: vector?.sites?.filter((site) =>
              isClearMyChanges ? "creator" in site && site?.creator !== username : "creator" in site
            ),
          })),
        })) || [];

      const unconfirmedCities: TTimeline[] =
        unconfirmedChanges?.filter(
          (cityEl) =>
            !response
              ?.filter((res: TTimeline) => res.city_id === cityEl?.city_id)
              ?.map((cityEl: TTimeline) => ({
                ...cityEl,
                vectors: cityEl.vectors?.map((vectorEl) => ({
                  ...vectorEl,
                  sites:
                    vectorEl.sites?.filter((siteEl) => !("id" in siteEl)) ||
                    ([] as Array<TTimelineSite>),
                })),
              }))
        ) || ([] as Array<TTimeline>);

      const arr = [
        ...response.map((city: TTimeline) => {
          const unconfirmedCity = unconfirmedChanges?.find((el) => el?.city_id === city.city_id);
          const newVectors =
            unconfirmedCity?.vectors?.filter(
              (vectorEl) =>
                !city?.vectors?.find((res) => getVectorStr(res) === getVectorStr(vectorEl))
            ) || ([] as Array<TTimelineVector>);

          if (!!unconfirmedCity) {
            return {
              ...city,
              vectors: [
                ...city.vectors.map((vector) => {
                  const unconfirmedVector = unconfirmedCity?.vectors?.find(
                    (el) => getVectorStr(el) === getVectorStr(vector)
                  );
                  const newSites =
                    unconfirmedVector?.sites?.filter((siteEl) => !("id" in siteEl)) ||
                    ([] as Array<TTimelineSite>);
                  if (!!unconfirmedVector) {
                    return {
                      ...vector,
                      sites: [
                        ...vector.sites.map((site) => {
                          const unconfirmedSite = unconfirmedVector?.sites?.find(
                            (el) => el?.id === site?.id
                          );
                          if (!!unconfirmedSite) {
                            return {
                              ...site,
                              creator: unconfirmedSite?.creator,
                              start_time: unconfirmedSite?.start_time,
                              end_time: unconfirmedSite?.end_time,
                            };
                          } else {
                            return site;
                          }
                        }),
                        ...newSites,
                      ],
                    };
                  } else {
                    return vector;
                  }
                }),
                ...newVectors,
              ]
                ?.sort((a, b) => (a.percent < b.percent ? 1 : -1))
                ?.sort((a, b) =>
                  `${a.currency_from_id}_${a.currency_to_id}` >
                  `${b.currency_from_id}_${b.currency_to_id}`
                    ? 1
                    : -1
                ),
            };
          } else {
            return city;
          }
        }),
        ...unconfirmedCities,
      ];
      updatedCities?.map((el) => {
        const item = arr.find((arrEl) => arrEl.city_id === el);
        if (!!item) {
          setTimelineByCentrifugo(item, el);
        } else {
          setTimelineByCentrifugo(
            {
              city_id: el,
              vectors: [],
            },
            el
          );
        }
      });
    }
  };

  useEffect(() => {
    (async () => {
      await createTimelineCitiesSubscription(false, false);
      await createTimelineUpdatesSubscription(false, false);
    })();
  }, []);

  useEffect(() => {
    cities?.forEach((el) => createTimelineSubscription(el, false, false));
  }, [cities]);

  useEffect(() => {
    if (isFirstUpd && !!timeline && timeline?.length === cities?.length) {
      setIsFirstUpd(false);
      setTimeout(async () => await fetchTimeline(false), 1000);
    }
  }, [cities, timeline]);

  useEffect(() => {
    (async () => {
      const today = resetTimezone(new Date(), true)?.split(",")[0];
      if (lastUpdate === "empty" || lastUpdate !== today) {
        await setTimelineUpdatesByCentrifugo(today);
        if (!!lastUpdate && lastUpdate !== today) {
          stashEmptyRows(timeline);
        }
      }
    })();
  }, [lastUpdate]);

  return (
    <div
      className={`relative grid duration-300 ${
        isSitesMenuOpen ? "grid-cols-[200px_1fr]" : "grid-cols-[20px_1fr]"
      }`}
      onKeyUp={(e) => {
        if (e.keyCode === 13) {
          setIsAdded("");
        }
      }}>
      <div className={`relative w-full`}>
        <button
          type="button"
          className={`fixed top-[56px] left-[20px] bg-stale rounded-sm w-[18px] h-[18px] text-xs text-white z-30`}
          onClick={() => setIsSitesMenuOpen(!isSitesMenuOpen)}>
          <div
            className={`duration-300 ${
              isSitesMenuOpen ? "rotate-90" : "-rotate-90"
            }`}>{`\u25BC`}</div>
        </button>
        <div className={`fixed`}>
          <div
            className={`flex flex-col gap-4 text-left pb-[100px] max-h-[90vh] overflow-y-scroll duration-300 ${
              isSitesMenuOpen ? "max-w-[200px] px-[20px]" : "max-w-0 px-0"
            }`}>
            {!!globalSites?.length &&
              globalSites
                ?.filter((el) => (isBest ? el.is_on_best : true))
                ?.map((el) => (
                  <SiteMenuItem
                    key={`site_${el.site_id}`}
                    item={el}
                    isAdded={isAdded}
                    setIsAdded={setIsAdded}
                  />
                ))}
          </div>
        </div>
      </div>
      <TimelineBoard
        centrifugoCities={cities}
        timeline={timeline}
        isAdded={isAdded}
        setIsAdded={setIsAdded}
        fetchTimeline={fetchTimeline}
        isBest={isBest}
        setIsBest={setIsBest}
        username={username}
        isSitesMenuOpen={isSitesMenuOpen}
      />
    </div>
  );
};
