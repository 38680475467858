import { SVG } from "src/assets/images/SVG";

export const VectorLabelMaker = ({ vector }) => {
  const string = vector?.split("-to-");

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col items-center">
        <SVG id={string[0]} />
        <div className="text-[10px] w-[55px] lg:w-[60px]">
          {string[0]?.replace("CASH", "")?.replace("20", "")}
        </div>
      </div>
      <div style={{ color: "#AAA" }}>{`\u279C`}</div>
      <div className="flex flex-col items-center">
        <SVG id={string[1]} />
        <div className="text-[10px] w-[55px] lg:w-[60px]">
          {string[1]?.replace("CASH", "")?.replace("20", "")}
        </div>
      </div>
    </div>
  );
};
