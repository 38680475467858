import { ReactNode } from "react";
import { cn } from "src/utils";

interface Props {
  children?: ReactNode;
  className?: string;
}

export const ErrorMessage = ({ children = "Произошла ошибка", className }: Props) => {
  return <div className={cn("text-rose-500 text-[14px] text-center", className)}>{children}</div>;
};
