import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import controlApi from "src/api/control";
import { setControlChats, TChat } from "src/store/commonReducer";
import { Popup } from "src/components/Popup/Popup";

export const Chat = ({ item, chats }: { item: TChat; chats: Array<TChat> }) => {
  const dispatch = useDispatch();
  const [chatName, setChatName] = useState<string>(item.chat_name);
  const [isUpd, setUpd] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const inputRef = useRef(null);

  const updateChat = async (value, attr) => {
    const { response } = await fetchRequest(
      controlApi.updateRegulationsChat({
        ...item,
        chat_name: attr === "chat_name" ? value : chatName,
      }),
      { request: "Обновление чата ТГ" }
    );
    if (response) {
      dispatch(
        setControlChats(
          chats?.map((el) =>
            el.id === item.id
              ? {
                  ...el,
                  chat_name: attr === "chat_name" ? value : chatName,
                }
              : el
          )
        )
      );
    }
  };

  const deleteChat = async () => {
    const { response } = await fetchRequest(controlApi.deleteRegulationsChat(item.id), {
      request: "Исключение чата ТГ",
    });
    if (response) {
      dispatch(setControlChats(chats?.filter((el) => el.id !== item.id)));
    }
  };

  return (
    <div className={`rounded-lg duration-700 shadow-smooth p-[10px] pl-[20px] text-left`}>
      <div className="grid  grid-cols-[1fr_16px] sm:grid-cols-[1fr_20px] gap-[10px] items-center mb-8">
        <div className="relative top-2 w-full">
          <div className="relative w-full h-[20px] cursor-pointer text-xs">
            <div
              className={`absolute ${!isUpd ? "" : "-z-10 opacity-0"} w-fit cursor-pointer`}
              onClick={() => {
                setUpd(true);

                inputRef.current.focus();
              }}>
              {item.chat_name}
            </div>
            <input
              className={`absolute w-full ${isUpd ? "" : "-z-10 opacity-0"} px-4 font-light`}
              ref={inputRef}
              defaultValue={item?.chat_name}
              onBlur={() => setUpd(false)}
              onChange={(e) => setChatName(e.target.value)}
              onKeyDown={async (e) => {
                if (e.keyCode === 13 && !!e.currentTarget.value?.length) {
                  await updateChat(e.currentTarget.value, "chat_name");
                  setUpd(false);
                }
              }}
            />
          </div>
          <div className="text-[9px] text-[#40AEF0]">ID: {item.telegram_chat_id}</div>
        </div>
        <button
          className={`duration-300 text-lightGray hover:text-lightFont text-left`}
          onClick={() => setPopup(true)}>{`\u2716`}</button>
      </div>
      {isPopup && (
        <Popup closeModal={() => setPopup(false)}>
          <div className="text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nчат `}
              <b>{item?.chat_name}</b>?
            </div>
            <button className="prime-button-sm" onClick={deleteChat}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
