import { Field } from "src/components/FormElements/Field";
import { TAdminSite } from "src/store/commonReducer";

export const PEForm = ({ item, isPE }: { item: TAdminSite; isPE: boolean }) => {
  return (
    <div className="grid sm:grid-cols-2 gap-[10px]">
      <div className="flex flex-col gap-4">
        <Field title="pe_login" name="pe_login" defaultValue={item?.pe_login} required={isPE} />
        <Field
          title="password"
          name="pe_password"
          defaultValue={item?.pe_password}
          required={isPE}
        />
      </div>
      <div className="flex flex-col gap-4">
        <Field title="link" name="pe_link" defaultValue={item?.pe_link} required={isPE} />
        <Field title="version" name="pe_version" defaultValue={item?.pe_version} required={isPE} />
      </div>
    </div>
  );
};
