import { convertToTime, convertUnixDate, OFFSET } from "src/helpers/date";
import { Tooltip } from "../Tooltip/Tooltip";

export const TimeStamp = ({
  item,
  isGreen,
}: {
  item: { course_created_at: number };
  isGreen?: boolean;
}) => {
  const today = new Date();
  const timestamp = new Date(item?.course_created_at * 1000);
  const isToday = today.getDate() === timestamp.getDate();
  const isExpired = +today - (item?.course_created_at - OFFSET) * 1000 > 600000;

  return (
    <Tooltip content="По Московскому времени">
      <div
        className={`text-left badge relative top-[3px] py-[1px] px-8 ${
          isToday ? "w-[63px] text-[10px]" : "w-[100px] text-[8px]"
        } ${isExpired ? "bg-red" : isGreen && !isExpired ? "" : "bg-lightGray"}`}>
        {isToday
          ? convertToTime(item?.course_created_at)
          : convertUnixDate(item?.course_created_at)}
      </div>
    </Tooltip>
  );
};
