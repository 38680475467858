import { Dispatch, SetStateAction, useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { TRequest, TTag, TTypeEditorType } from "src/store/commonReducer";
import { requestFilter, requestSort, TRequestFilter } from "../components/RequestsFilters";
import { Request } from "../components/Request";

type TProps = {
  requests: Array<TRequest>;
  zeroSavedAction: number;
  username: string;
  userAccess: Array<string>;
  isDelay: boolean;
  setDelay: Dispatch<SetStateAction<boolean>>;
  delayTime: number;
  status: Array<string>;
  types: Array<TTypeEditorType>;
  priorities: Array<TTypeEditorType>;
  tags: Array<TTag>;
  filters: TRequestFilter;
  currentStatus: string;
  setCurrentStatus: Dispatch<SetStateAction<string>>;
  searchParams: URLSearchParams;
  setSearchParams;
  role: string | null;
};

export const Column = ({
  requests,
  status,
  username,
  userAccess,
  isDelay,
  setDelay,
  delayTime,
  types,
  priorities,
  tags,
  filters,
  currentStatus,
  setCurrentStatus,
  searchParams,
  setSearchParams,
  role,
}: TProps) => {
  const items = useMemo(
    () =>
      Array.from(requests)
        ?.filter((req) => req?.status === status[0])
        ?.filter((el) => requestFilter(el, filters.filter))
        ?.sort((a, b) => requestSort(a, b, filters.sort)),
    [requests, filters]
  );

  return (
    <div
      className={`rounded-sm bg-[#CACAD355] overflow-hidden p-4 xl:min-h-[80vh] h-full text-left duration-300 ${
        currentStatus === status[0]
          ? "w-full md:w-[600px]"
          : currentStatus === ""
          ? "min-w-[260px]"
          : "w-0 p-0 -mx-4 my-0 opacity-0"
      }`}>
      <button
        type="button"
        className="p-4 pl-[6px] text-lightFont text-[10px] w-full text-left font-medium duration-300 rounded-sm hover:bg-[#FFFFFF77]"
        onClick={() => {
          if (currentStatus === "") {
            setCurrentStatus(status[0]);
            searchParams.set("status", status[1]);
            setSearchParams(searchParams);
          } else {
            setCurrentStatus("");
            searchParams.delete("status");
            setSearchParams(searchParams);
          }
        }}>
        {status[0]?.toUpperCase()}
      </button>
      <div className={`flex flex-col gap-4 max-h-[78vh] overflow-y-scroll px-4 visibleScroll`}>
        {items?.map((el, index) => (
          <Draggable
            key={el.id?.toString()}
            draggableId={el.id?.toString()}
            isDragDisabled={currentStatus !== ""}
            index={index}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                <Request
                  key={el.id}
                  item={el}
                  requests={requests}
                  username={username}
                  userAccess={userAccess}
                  role={role}
                  isDelay={isDelay}
                  setDelay={setDelay}
                  delayTime={delayTime}
                  types={types}
                  priorities={priorities}
                  tags={tags}
                  dndProvider={provided}
                  isFull={currentStatus === status[0]}
                />
              </div>
            )}
          </Draggable>
        ))}
      </div>
    </div>
  );
};
