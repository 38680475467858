import MarkdownEditor from "@uiw/react-markdown-editor";
import { Dispatch, SetStateAction, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import split_right from "src/assets/images/splitRight.svg";

export const MdEditor = ({
  text,
  setText,
}: {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
}) => {
  const [isSplitRight, setIsSplitRight] = useState(true);

  return (
    <div>
      <button
        type="button"
        className={`relative left-0 top-0 flex justify-center items-center rounded-sm h-7 w-7 max-w-7 p-4 pt-8 duration-300 hover:shadow-inset ${
          isSplitRight ? "bg-slate-200" : "bg-bg"
        }`}
        onClick={() => {
          setIsSplitRight(!isSplitRight);
        }}>
        <img src={split_right} alt="открыть справа" width={24} height={24} />
      </button>
      <div className={`flex gap-4 ${isSplitRight ? "grid grid-cols-2" : "flex-col"}`}>
        <Markdown className={`w-full h-full pb-8 px-5 min-h-[30vh]`} remarkPlugins={[remarkGfm]}>
          {text}
        </Markdown>
        <MarkdownEditor
          className={`w-full h-full min-h-[30vh] pb-10 ${isSplitRight ? "max-w-1/2" : ""}`}
          value={text}
          onChange={(value) => {
            setText(value);
          }}
        />
      </div>
      <div className="p-5 text-slate-400 markdown flex flex-col gap-4">
        <p>Как добавить картинку в текст?</p>
        <ol>
          <li>
            Сохраните картинку в галерее. В редакторе наведите курсор на нужное место и нажмите
            кнопку с изображением.
          </li>
          <li>
            Появится шаблон: <b>![]()</b>
          </li>
          <li>
            Над картинкой нажмите кнопку "скопировать ссылку" (доступен только если картинка
            сохранена)
          </li>
          <li>Вставьте ссылку на картинку в круглые скобки</li>
          <li>Готово! Картинка в тексте отобразится в своем исходном размере</li>
        </ol>
      </div>
    </div>
  );
};
