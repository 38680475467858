import authApi from "../api/auth";
import { LS } from "../api/constants";
import { store } from "../store/store";
import { setLogout } from "../store/mainReducer";

export const logout = async () => {
  try {
    await authApi.logout();
  } catch (error) {
    console.log(error.message);
  } finally {
    localStorage.removeItem(LS.USERNAME);
    localStorage.removeItem(LS.ACCESS);
    localStorage.removeItem(LS.ROLE);
    store.dispatch(setLogout());
  }
};
