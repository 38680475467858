import { Spinner } from "../../Spinner/Spinner";
import { useEffect } from "react";

export const WaitingLoader = ({ message, isLoading, setIsLoading, status }) => {
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 4000);
    return () => clearTimeout(timer);
  }, [status]);

  return (
    <div
      onClick={() => {
        if (status !== null) {
          setIsLoading(false);
        }
      }}
      className={`fixed top-0 left-0 w-full h-[100vh] bg-[#585c64AA] backdrop-blur-sm flex flex-col gap-[40px] justify-center items-center text-sm text-white duration-500 ${
        isLoading ? "z-[110] opacity-100" : "-z-10 opacity-0"
      }`}>
      <div
        className={`absolute duration-500 ${
          status === null ? "opacity-100 z-20" : "opacity-0 -z-10"
        }`}>
        <Spinner />
      </div>
      {status === true && (
        <div
          className={`absolute d-flex justify-content-center duration-500 ${
            isLoading ? "opacity-100 z-20" : "opacity-0 -z-10"
          }`}>
          <div
            className={`relative backdrop-blur-2xl rounded-full w-[150px] h-[150px] duration-500 ${
              isLoading
                ? "bg-[#FFFFFF77] opacity-100 shadow-main-double"
                : "bg-[#FFFFFF11] opacity-0 shadow-none"
            }`}>
            <div id="check-part-1" className="check-sign"></div>
            <div id="check-part-2" className="check-sign"></div>
          </div>
        </div>
      )}
      {status === false && (
        <div
          className={`absolute d-flex justify-content-center duration-500 ${
            isLoading ? "opacity-100 z-20" : "opacity-0 -z-10"
          }`}>
          <div
            className={`relative backdrop-blur-2xl rounded-full w-[150px] h-[150px] duration-500 ${
              isLoading
                ? "bg-[#FFFFFF77] opacity-100 shadow-main-double"
                : "bg-[#FFFFFF11] opacity-0 shadow-none"
            }`}>
            <div id="error-part-1" className="error-sign"></div>
            <div id="error-part-2" className="error-sign"></div>
          </div>
        </div>
      )}
      <div
        className={`max-w-[280px] whitespace-pre relative ${
          status === null ? "top-[100px]" : "top-[140px]"
        }`}>
        {message}
      </div>
    </div>
  );
};
