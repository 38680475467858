import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { selectFlightsFilters, TFlights, TReview } from "src/store/statsReducer";
import { Notice } from "src/components/UI/notice/Notice";
import { TAlert } from "src/pages/Stats/Stats";
import { AlertsGroup } from "./AlertsGroup";
import { RatesGroup } from "../Rates/RatesGroup";
import { Popup } from "../../Popup/Popup";
import { flightFilter, FlightsFilters } from "src/pages/Flights/components/FlightsFilters";

export const SideBarSection = ({
  count,
  icon,
  items,
  tab,
  isOpen,
  isRatesFull,
  setIsRatesFull,
}: {
  count: number;
  icon: string;
  items?: TFlights | TReview[] | TAlert[];
  tab: "review" | "alert" | "rates" | "flights";
  isOpen: boolean;
  isRatesFull?: boolean;
  setIsRatesFull?: Dispatch<SetStateAction<boolean>>;
}) => {
  const flightsFilters = useSelector(selectFlightsFilters);
  const [isFlightsFilters, openFlightsFilters] = useState(false);

  const filteredCount =
    tab === "flights" && "data" in items
      ? items?.data?.filter((el) => flightFilter(el, flightsFilters))?.length
      : count;

  return (
    <div className={`flex ml-[5px] mr-[10px] min-h-0 relative h-full`}>
      {!!filteredCount && filteredCount > 0 && <Notice count={filteredCount} />}
      {!isOpen && (
        <img
          src={icon}
          alt={tab}
          className="relative top-[2px] sm:left-[15px] w-[30px] h-[30px] sm:w-[40px] sm:h-[40px]"
        />
      )}
      {isOpen && tab !== "rates" && (
        <AlertsGroup
          items={items}
          tab={tab}
          openFlightsFilters={openFlightsFilters}
          flightFilter={flightFilter}
          flightsFilters={flightsFilters}
        />
      )}
      {isOpen && tab === "rates" && <RatesGroup isFull={isRatesFull} setIsFull={setIsRatesFull} />}
      {isFlightsFilters && "data" in items && (
        <Popup closeModal={() => openFlightsFilters(false)}>
          <FlightsFilters items={items} />
        </Popup>
      )}
    </div>
  );
};
