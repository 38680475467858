import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { TTimelineDeletePayload } from "src/store/clusterReducer";
import { selectAllCities, selectAllCurrencies } from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";

type TProps = {
  deletedEl: TTimelineDeletePayload;
  setDeletedEl: Dispatch<SetStateAction<TTimelineDeletePayload | null>>;
  isLoading: boolean;
  deleteIt: (payload: TTimelineDeletePayload, saveRows: boolean) => void;
};

export const DeleteConfirm = ({ deletedEl, setDeletedEl, isLoading, deleteIt }: TProps) => {
  const globalCities = useSelector(selectAllCities);
  const globalCurrencies = useSelector(selectAllCurrencies);

  return (
    <Popup closeModal={() => setDeletedEl(null)}>
      <div className="text-xs flex flex-col gap-8 items-center max-w-[300px]">
        <div>Вы уверены, что хотите удалить таймлайны для</div>
        <div className="flex flex-wrap gap-4">
          <b>{globalCities?.find((el) => el.city_id === deletedEl.city_id)?.city_name}</b>
          {"currency_from_id" in deletedEl && (
            <b>
              {
                globalCurrencies?.find((el) => el.currency_id === deletedEl.currency_from_id)
                  ?.currency_name
              }
            </b>
          )}
          {"currency_to_id" in deletedEl && (
            <b>
              -{" "}
              {
                globalCurrencies?.find((el) => el.currency_id === deletedEl.currency_to_id)
                  ?.currency_name
              }
            </b>
          )}
          {"percent" in deletedEl && <b>{deletedEl?.percent} %</b>}?
        </div>
        <button
          className="prime-button-sm rounded-sm mt-8"
          disabled={isLoading}
          onClick={() => deleteIt(deletedEl, true)}>
          Удалить
        </button>
        или
        <button
          className="secondary-button rounded-sm -mb-4 text-lightFont hover:bg-lightStale py-2 px-8 border-b border-stale"
          disabled={isLoading}
          onClick={() => deleteIt(deletedEl, false)}>
          Удалить и очистить пустые строки
        </button>
      </div>
    </Popup>
  );
};
