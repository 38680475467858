import { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch } from "src/store/store";
import { useSearchParams } from "react-router-dom";
import { ESectionType, TModule } from "../../types";
import { Button } from "src/shadcn/ui/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/shadcn/ui/ui/select";
import { Popup } from "src/components/Popup/Popup";
import { moduleTypesOptions } from "../../constants";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectBlocks, selectModules, setModules } from "src/store/academyReducer";
import { setNotific } from "src/store/mainReducer";
import { useSelector } from "react-redux";
import { TimeEstimate } from "src/components/FormElements/TimeEstimate";
import bin from "src/assets/images/bin.svg";

export const EditModule = ({
  item,
  setIsEdit,
}: {
  item?: TModule;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const course_id = searchParams.get("id");
  const modules = useSelector(selectModules);
  const blocks = useSelector(selectBlocks);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(item?.module_name || "");
  const [type, setType] = useState<ESectionType | null>(item?.module_type || null);
  const [deadline, setDeadline] = useState<number | null>(item?.module_deadline_in_minutes || null);
  const [newUserName, setNewUserName] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const [users, setUsers] = useState(item?.module_allowed_users || []);
  const [isDeleteConfirmPopup, setIsDeleteConfirmPopup] = useState(false);
  const isUserExist = users?.includes(newUserName);

  const disabled = !name?.length || !type || isLoading;

  const submit = async () => {
    setIsLoading(true);
    const payload: TModule = {
      course_id: +course_id,
      module_name: name,
      module_type: type,
      module_order: item?.module_order || modules?.length,
      module_deadline_in_minutes: !deadline ? null : deadline,
      module_blocks: item?.module_blocks || [],
      module_allowed_users: users,
    };
    if (!item) {
      const { response } = await fetchRequest(academyApi.createModule(payload), {
        request: "Создание модуля",
      });
      if (response) {
        dispatch(setModules([...modules, response]));
      }
    } else {
      const { response } = await fetchRequest(academyApi.updateModule(item.module_id, payload), {
        request: "Обновление модуля",
      });
      if (response) {
        dispatch(
          setModules(
            modules.map((el) =>
              el.module_id === item.module_id
                ? {
                    ...el,
                    ...response,
                  }
                : el
            )
          )
        );
        dispatch(
          setNotific({
            type: "success",
            message: "Данные успешно обновлены",
            request: "Обновление модуля",
          })
        );
      }
    }
    setIsLoading(false);
    setIsEdit(false);
  };

  const deleteModule = async () => {
    if (!!item.module_id) {
      const { response } = await fetchRequest(academyApi.deleteModule(item.module_id), {
        request: "Удаление модуля",
      });
      if (response) {
        dispatch(setModules(modules?.filter((el) => el.module_id !== item.module_id)));
      }
    } else {
      dispatch(setModules(modules?.filter((el) => el.module_name !== item.module_name)));
    }
  };

  return (
    <div className="flex flex-col gap-8 items-stretch w-full text-sm">
      <div className="absolute top-3 right-3 flex gap-4">
        <Button
          variant="ghost"
          className="h-7 w-7 text-slate-500 bg-rose-50"
          onClick={() => setIsDeleteConfirmPopup(true)}>
          <img src={bin} alt="удалить" width={15} height={15} />
        </Button>
        <Button variant="ghost" className="h-7 w-7 text-slate-500" onClick={() => setIsEdit(false)}>
          {`\u2716`}
        </Button>
      </div>
      <div className="text-slate-400">Название модуля</div>
      <input
        className="border border-input rounded-sm py-8 px-3"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="text-slate-400">Тип модуля</div>
      {(!item?.module_type || item?.module_type === ESectionType.THEORY) && (
        <small>
          {item?.module_type === ESectionType.THEORY
            ? `Тип "Теория" неименяем`
            : "Выбирая теорию - после тип невозможно будет изменить. Практику можно будет сменить на Аттестацию"}
        </small>
      )}
      <Select
        value={type}
        onValueChange={(e: ESectionType) => setType(e)}
        disabled={item?.module_type === ESectionType.THEORY}>
        <SelectTrigger>
          <SelectValue placeholder="Тип модуля" />
        </SelectTrigger>
        <SelectContent position="popper">
          {moduleTypesOptions
            .filter((option) =>
              !!item?.module_type && item?.module_type !== ESectionType.THEORY
                ? option.value !== ESectionType.THEORY
                : true
            )
            .map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
      <div className="text-slate-400">Время на прохождение</div>
      <div className="self-start">
        <TimeEstimate value={deadline} setValue={setDeadline} isAccess />
      </div>
      {type === ESectionType.EXAMINATION && (
        <>
          <div className="text-slate-400">Разрешить доступ</div>
          <div className="flex flex-col text-xs text-slate-400">
            {users?.map((el, index) => (
              <div key={`${index}_allowed_user`} className="flex gap-3">
                {el}
                <button
                  className={`duration-300 text-lightGray hover:text-lightFont`}
                  onClick={() => {
                    setUsers(users?.filter((user) => el !== user));
                  }}>
                  {`\u2716`}
                </button>
              </div>
            ))}
            <button
              className="rounded-sm border border-[#40AEF055] bg-[#40AEF011] text-blue my-8"
              onClick={() => setIsNewUser(!isNewUser)}>
              <div
                className={`relative duration-300 w-fit mx-auto ${
                  isNewUser ? "rotate-0" : "rotate-45"
                }`}>
                {`\u2716`}
              </div>
            </button>
            {isNewUser && (
              <>
                <div className="flex gap-4">
                  <input
                    placeholder="Введите имя пользователя"
                    className="border border-input rounded-sm py-8 px-3 w-full text-font"
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                  />
                  <Button
                    className="px-3 bg-emerald-50 border-emerald-300 text-emerald-500 font-extrabold duration-300 disabled:text-input disabled:border-input"
                    variant="outline"
                    disabled={isUserExist || !newUserName?.length}
                    onClick={() => {
                      setUsers([...users, newUserName]);
                      setNewUserName("");
                    }}>
                    {`\u2713`}
                  </Button>
                </div>
                {isUserExist && (
                  <small className="text-rose-500">Пользователь уже имеет доступ</small>
                )}
              </>
            )}
          </div>
        </>
      )}
      <div className="text-slate-400">Блоки</div>
      <div className="flex flex-col text-xs text-slate-400">
        {blocks?.length
          ? blocks?.map((el) => (
              <div key={`${el.block_id}_block`} className="flex gap-3">
                {el.block_name}
              </div>
            ))
          : "блоки можно будет добавить после"}
      </div>
      <Button className="px-5 mt-8 self-end" disabled={disabled} onClick={submit}>
        Сохранить
      </Button>
      {isDeleteConfirmPopup && (
        <Popup closeModal={() => setIsDeleteConfirmPopup(false)}>
          <div className="flex flex-col gap-7 p-3">
            <div>
              Вы уверены что хотите <b>удалить</b> модуль?
            </div>
            <Button className="bg-rose-500" onClick={deleteModule}>
              Удалить модуль
            </Button>
          </div>
        </Popup>
      )}
    </div>
  );
};
