import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedFrom } from "src/store/directionsReducer";
import { VectorRow } from "./VectorRow";

export const StepsGroup = ({
  items,
  title,
  dirtyVectors,
  setDirtyVectors,
  isRequired,
  isAccess,
  curFrom,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const groupVectors = useMemo(
    () => items?.filter((el) => el.vector?.split("-to-")[0] === title),
    [items]
  );

  useEffect(() => {
    if (!!curFrom && curFrom === title) {
      setOpen(true);
      const elem = document.getElementById(title);
      elem?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, []);

  return (
    <div id={title} className={`shadow-base rounded-lg`}>
      <button
        type="button"
        className={`flex w-full ${
          isOpen ? "bg-font" : "bg-lightGray"
        } rounded-t lg:rounded-none text-white font-black mr-[5px] shadow`}
        onClick={() => {
          if (!isOpen) {
            dispatch(setSelectedFrom(title));
          }
          setOpen(!isOpen);
        }}>
        <div className="w-[100px] text-left pl-[10px]">{title}</div>
        <div className="w-[calc(100%-200px)]">{!isOpen ? `\u25B2` : `\u25BC`}</div>
      </button>
      <div className="flex items-baseline pl-[10px] text-left">
        <div
          className={`w-[290px] sm:w-fit sm:min-w-[400px] ${
            isOpen ? "h-[100%] max-h-[100%] sm:pt-8" : "h-0 max-h-0"
          } overflow-hidden`}>
          {groupVectors?.map((el, index) => (
            <VectorRow
              key={index}
              item={el}
              dirtyVectors={dirtyVectors}
              setDirtyVectors={setDirtyVectors}
              isRequired={isRequired}
              isAccess={isAccess}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
