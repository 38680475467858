export const WideButtons = ({ value, setValue }) => {
  return (
    <div className="flex gap-2 rounded-sm bg-stale p-2 w-fit text-[10px] text-lightFont">
      <button
        type="button"
        className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] min-w-[22px] bg-bg duration-300 hover:shadow-inset ${
          value ? "" : "opacity-70 brightness-[1.4]"
        }`}
        onClick={() => setValue(true)}>
        <div className="rotate-90">{`\u25BC`}</div>
        <div className="-rotate-90">{`\u25BC`}</div>
      </button>
      <button
        type="button"
        className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] min-w-[22px] bg-bg duration-300 hover:shadow-inset ${
          !value ? "" : "opacity-70 brightness-[1.4]"
        }`}
        onClick={() => setValue(false)}>
        <div className="-rotate-90">{`\u25BC`}</div>
        <div className="rotate-90">{`\u25BC`}</div>
      </button>
    </div>
  );
};
