import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { colors } from "src/helpers/colors";
import { Popup } from "src/components/Popup/Popup";

export const QueueCard = ({
  item,
  index,
  date,
  setActiveTemplate,
  openTemplates,
  updateQueue,
  addDate,
  setAddDate,
  isLoading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDeleteConfirm, setDeleteConfirm] = useState(false);
  const shortDate = useMemo(() => date?.value.toString().split("T")[0], []);

  return (
    <div
      className={`flex flex-col gap-4 text-[10px] border rounded-md p-8 -m-[9px] ${
        index === 0 ? "bg-lightStale" : ""
      } ${addDate === shortDate ? "border-[#72E5AE55] bg-glass-select" : "border-bg"}`}>
      <div>{index === 0 ? "СЕГОДНЯ" : date?.label?.toUpperCase()}</div>
      <div
        style={{
          backgroundColor: item?.id >= 0 ? `${colors[item?.id % 30]}33` : "#BABAC333",
          color: item?.id >= 0 ? "inherit" : "#FFF",
          fontSize: item?.title?.length > 20 ? 10 : item?.id >= 0 ? 12 : 24,
          fontWeight: item?.id >= 0 ? 300 : 500,
        }}
        className={`relative w-[100px] h-[60px] rounded-md flex justify-center items-center hover:opacity-70 ${
          item?.id >= 0 ? "" : "pb-2"
        }`}
        onMouseMove={() => setIsDeleteVisible(true)}
        onMouseLeave={() => setIsDeleteVisible(false)}>
        {isDeleteVisible && item?.id >= 0 && index !== 0 && (
          <button
            type="button"
            className={`absolute -top-4 -right-4 flex justify-center items-center h-[14px] w-[14px] rounded-full shadow-base text-[8px] bg-bg text-lightFont disabled:bg-transparent`}
            disabled={isLoading}
            onClick={() => setDeleteConfirm(true)}>
            {`\u2716`}
          </button>
        )}
        <button
          type="button"
          className={`leading-4 disabled:bg-transparent ${
            index === 0 && item?.id === -1 ? "cursor-default" : ""
          }`}
          disabled={isLoading}
          onClick={() => {
            if (item?.id === -1 && index !== 0) {
              setAddDate(shortDate);
            }
            if (item?.id >= 0) {
              setActiveTemplate(item.id);
              searchParams.set("template", item.id);
              setSearchParams(searchParams);
              openTemplates(false);
            }
          }}>
          {!!item?.title ? item?.title : index === 0 ? "" : "+"}
        </button>
      </div>
      {isDeleteConfirm && (
        <Popup closeModal={() => setDeleteConfirm(false)}>
          <div className="flex flex-col items-center gap-[20px] text-xs text-center whitespace-pre">
            <div>{`Вы уверены, что хотите снять с очереди\n${item?.title}?`}</div>
            <button
              type="button"
              className={`w-fit mx-auto prime-button-sm bg-font rounded-sm disabled:bg-transparent`}
              disabled={isLoading}
              onClick={() => {
                const currentDate = item?.dates?.find((el) => el === shortDate);
                if (!!currentDate) {
                  updateQueue({
                    id: item.id,
                    dates_to_create: [],
                    dates_to_delete: [currentDate],
                  });
                }
                setDeleteConfirm(false);
              }}>
              Снять с очереди
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
