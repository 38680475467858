import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { NestedObjects } from "../components/routes";
import controlApi from "src/api/control";
import {
  TCoin,
  TCondition,
  TNetwork,
  TTrack,
  TTrackNotification,
  TWallet,
} from "src/store/commonReducer";
import { FieldRow } from "src/components/FormElements/FieldRow";
import { Popup } from "src/components/Popup/Popup";
import { EMethod } from "./Wallets";
import { Track } from "./Track";
import bell from "src/assets/images/bell.svg";
import search from "src/assets/images/search.svg";

type TProps = {
  item: TWallet;
  tracks: TTrack[];
  coins: TCoin[];
  setTracks: Dispatch<SetStateAction<TTrack[]>>;
  conditions: TCondition[];
  notifications: TTrackNotification[];
  networks: TNetwork[];
  setNotifications: Dispatch<SetStateAction<TTrackNotification[]>>;
  baseRequest: (data: {
    method: Promise<AxiosResponse>;
    methodType: EMethod;
    title: string;
    name: string;
    setter:
      | Dispatch<SetStateAction<TTrackNotification[] | TTrack[] | TCoin[] | TWallet[]>>
      | ((res: NestedObjects<TCondition | TNetwork>) => void);
  }) => void;
  networkColor: (TNetwork) => string;
  isLoading: boolean;
  setWallets: Dispatch<SetStateAction<TWallet[]>>;
};

export const Wallet = ({
  item,
  baseRequest,
  networkColor,
  isLoading,
  setWallets,
  tracks,
  coins,
  networks,
  setTracks,
  conditions,
  notifications,
  setNotifications,
}: TProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isRenamed, setRenamed] = useState(false);
  const [isUpdNetwork, setUpdNetwork] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [name, setName] = useState(item?.wallet_name || "");

  const itemTracks = useMemo(
    () => tracks?.filter((el) => el.wallet_id === item.wallet_id) || [],
    [tracks]
  );
  const itemCoins = useMemo(
    () =>
      [...new Set(itemTracks?.map((el) => el.coin_id))]?.map((coinId) =>
        itemTracks?.find((el) => el.coin_id === coinId)
      ),
    [coins, itemTracks]
  );
  const itemNotifications = useMemo(
    () =>
      notifications?.filter(
        (el) => !!itemTracks?.find((trackEl) => trackEl?.track_id === el.track_id)
      ) || [],
    [itemTracks, notifications]
  );

  return (
    <div
      style={{ backgroundColor: `${networkColor(item.wallet_network)}55` }}
      className={`relative w-[200px] flex flex-col rounded-lg duration-700 shadow-smooth-out py-[10px] px-[20px] text-left text-xs`}>
      <button
        type="button"
        className={`absolute -top-[6px] -right-4 flex items-center justify-center h-[16px] w-[16px] bg-bg rounded-full shadow-sm text-[10px] text-lightFont`}
        onClick={() => setPopup(true)}>
        {`\u2715`}
      </button>
      <div className={`flex flex-col h-full gap-8 justify-between`}>
        <div className={`flex flex-col h-full gap-8`}>
          {isUpdNetwork ? (
            <div className="flex gap-4 -ml-8">
              {networks?.map((el) => (
                <button
                  key={el}
                  type="button"
                  style={{
                    color: `${networkColor(item.wallet_network)}AA`,
                    backgroundColor:
                      item.wallet_network === el ? `${networkColor(el)}55` : "transparent",
                  }}
                  className={`rounded-sm px-8 py-2`}
                  onClick={async () => {
                    if (item.wallet_network !== el) {
                      await baseRequest({
                        method: controlApi.updateWallet({ ...item, wallet_network: el }),
                        methodType: EMethod.UPDATE,
                        title: "Обновление кошелька",
                        name: "Wallet",
                        setter: setWallets,
                      });
                    }
                    setUpdNetwork(false);
                  }}>
                  {el}
                </button>
              ))}
            </div>
          ) : (
            <button
              type="button"
              className="text-left"
              style={{ color: `${networkColor(item.wallet_network)}AA` }}
              onClick={() => setUpdNetwork(true)}>
              <b>{item.wallet_network}</b>
            </button>
          )}
          <div className="flex gap-8 justify-between">
            {isRenamed ? (
              <input
                className={`relative -left-8 min-w-[100px] max-h-[18px] border border-[#282c3433] rounded-sm py-0 px-8`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={async (e) => {
                  if (e.keyCode === 13 && !!e.currentTarget.value?.length) {
                    await baseRequest({
                      method: controlApi.updateWallet({ ...item, wallet_name: name }),
                      methodType: EMethod.UPDATE,
                      title: "Обновление кошелька",
                      name: "Wallet",
                      setter: setWallets,
                    });
                    setRenamed(false);
                  }
                }}
              />
            ) : (
              <button
                type="button"
                className="text-left text-[16px] text-white font-semibold rounded-md px-8 py-2 -mx-8 bg-[#282c3455]"
                onClick={() => setRenamed(true)}>
                {item.wallet_name?.toUpperCase()}
              </button>
            )}
            <button type="button" onClick={() => setRenamed(!isRenamed)}>
              {`\u270E`}
            </button>
          </div>
          {!!itemTracks?.length && (
            <div className="flex gap-8 -mx-8 text-lightFont font-semibold">
              <div className="flex gap-8 rounded-full bg-[#52C58E55] pr-8">
                <div className="flex items-center justify-center w-[18px] h-[18px] min-w-[18px] rounded-full bg-[#52C58E]">
                  <img
                    src={search}
                    alt="Отслеживание"
                    width={12}
                    height={12}
                    className="brightness-[2]"
                  />
                </div>
                {itemTracks.length}
              </div>
              {!!itemNotifications?.length && (
                <div className="flex gap-8 rounded-full bg-[#FCC81555] pr-8">
                  <div className="flex items-center justify-center w-[18px] h-[18px] min-w-[18px] rounded-full bg-[#FCC815]">
                    <img src={bell} alt="Уведомление" width={14} height={14} />
                  </div>
                  {itemNotifications.length}
                </div>
              )}
            </div>
          )}
          {!!itemCoins?.length &&
            itemCoins?.map((el) => {
              const coin = coins?.find((coinEl) => coinEl.coin_id === el.coin_id)?.coin_name || "";
              return (
                <div
                  key={el.track_id}
                  className="w-fit flex gap-8 font-bold text-green rounded-full bg-[#FFFFFF77] -mx-8 px-8">
                  <span>{coin}</span>
                  <span>{el.actual_amount}</span>
                </div>
              );
            })}
        </div>
        <button
          type="button"
          className={`flex justify-center items-center hover:text-lightFont ${
            isOpen ? "text-lightFont" : "text-[#AAAAB3]"
          }`}
          onClick={() => setIsOpen(!isOpen)}>
          <span className={`rounded-lg px-8 ${isOpen ? "bg-bg" : "bg-[#FFFFFF44]"}`}>
            {`\u2022 \u2022 \u2022`}
          </span>
        </button>
      </div>
      {isOpen && (
        <div className="relative">
          <div className="absolute min-w-[280px] sm:min-w-[330px] top-8 flex flex-col gap-8 mt-8 w-fit bg-bg rounded-lg duration-700 shadow-smooth p-[10px] pl-[20px] -ml-[20px] z-10">
            <FieldRow
              label="адрес"
              value={item.wallet_address}
              defaultValue={item.wallet_address}
              edited
              placeholder="Введите адрес кошелька"
              updFn={(fieldValue: string) => {
                baseRequest({
                  method: controlApi.updateWallet({ ...item, wallet_address: fieldValue }),
                  methodType: EMethod.UPDATE,
                  title: "Обновление кошелька",
                  name: "Wallet",
                  setter: setWallets,
                });
              }}
            />
            <div className="font-bold text-lightFont">Отслеживания:</div>
            <button
              type="button"
              className={`flex items-center gap-[16px] rounded-md border border-[#BABAC322] bg-[#BABAC311] text-lightFont px-[10px] py-2 -ml-[10px]`}
              onClick={() => setIsAdd(!isAdd)}>
              <div className="relative -top-px text-[16px] leading-3 font-bold">+</div>
              Добавить отслеживание
            </button>
            {isAdd && (
              <Track
                coins={coins?.filter((el) => el.coin_network === item.wallet_network)}
                baseRequest={baseRequest}
                conditions={conditions}
                isLoading={isLoading}
                setTracks={setTracks}
                setIsAddTrack={setIsAdd}
                item={{
                  track_name: "",
                  coin_id: "",
                  amount: 0,
                  condition: "<",
                  wallet_id: item?.wallet_id || "",
                }}
              />
            )}
            {!!itemTracks.length && (
              <div className="flex flex-col gap-8">
                {itemTracks?.map((el) => (
                  <Track
                    key={el.track_id}
                    item={el}
                    coins={coins?.filter((el) => el.coin_network === item.wallet_network)}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    isLoading={isLoading}
                    setTracks={setTracks}
                    conditions={conditions}
                    baseRequest={baseRequest}
                    setIsAddTrack={setIsAdd}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {isPopup && (
        <Popup closeModal={() => setPopup(false)}>
          <div className="text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nкошелек `}
              <b>{item?.wallet_name}</b>?
            </div>
            <button
              type="button"
              className="prime-button-sm bg-font"
              disabled={isLoading}
              onClick={async () => {
                await baseRequest({
                  method: controlApi.deleteWallet(item?.wallet_id),
                  methodType: EMethod.DELETE,
                  title: "Удаление кошелька",
                  name: "Wallet",
                  setter: setWallets,
                });
                setPopup(false);
              }}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
