import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const Tumbler = ({
  title,
  name,
  defaultValue,
  disabled,
}: {
  title: string;
  name: string;
  defaultValue: boolean;
  disabled?: boolean;
}) => {
  const { register, watch, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, defaultValue);
  }, []);

  return (
    <label className={`grid grid-cols-[68px_1fr] gap-[10px]`}>
      <div className="switcher flex justify-end">
        <label
          style={{ backgroundColor: watch(name) ? "green" : "lightgrey" }}
          className={`switcher__label-small ${
            !disabled ? "cursor-pointer" : ""
          } hover:opacity-[0.9]`}>
          <input
            className="switcher__check-input"
            type="checkbox"
            defaultChecked={defaultValue}
            disabled={disabled}
            {...register(`${name}`)}
          />
          <span className="switcher__checkbox-small"></span>
        </label>
      </div>
      <div>{title}</div>
    </label>
  );
};
