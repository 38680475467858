import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect, { createFilter } from "react-select";
import { selectFilter } from "src/helpers/formHelpers";
import {
  selectAllCities,
  setSelectedCity,
  setSelectedFrom,
  setSelectedSite,
  setSelectedTo,
  TAlienOptions,
  TBestSite,
  TGraphData,
  TGraphLevels,
  TEntropy,
  TGraphFilter,
  TLinks,
  TSelect,
  TNode,
} from "src/store/directionsReducer";
import { DropEditor, TDropType } from "src/components/UI/dropModal/DropEditor";
import { Popup } from "src/components/Popup/Popup";
import { SadCat } from "src/components/UI/loader/SadCat";
import { TTab } from "../DirectionsGraph";
import hierarchy from "src/assets/images/hierarchical.svg";
import eye from "src/assets/images/eye.svg";
import office from "src/assets/images/office.svg";
import chart from "src/assets/images/chart.svg";
import change from "src/assets/images/arrows.svg";

type TProps = {
  filter: TGraphFilter;
  setFilter: Dispatch<SetStateAction<TGraphFilter>>;
  data: TGraphData | null;
  isFilterOpen: boolean;
  sites: Array<TBestSite>;
  isLoading: boolean;
  hierarchical: boolean;
  setHierarchical: Dispatch<SetStateAction<boolean>>;
  selectedFilter: (node: TNode, level: TGraphLevels) => void;
  setSelectedNodes: Dispatch<SetStateAction<Array<string>>>;
  fetchGraph: () => void;
  fetchEntropy: () => void;
  fetchLastAvailable: () => void;
  page: TTab | null;
  currenciesOptions: Array<TSelect>;
  setEdgeLength: Dispatch<SetStateAction<number>>;
  setEntropy: Dispatch<SetStateAction<TEntropy | null>>;
};

export const GraphFilter = ({
  filter,
  setFilter,
  data,
  isFilterOpen,
  sites,
  isLoading,
  hierarchical,
  setHierarchical,
  selectedFilter,
  setSelectedNodes,
  fetchGraph,
  fetchEntropy,
  fetchLastAvailable,
  page,
  currenciesOptions,
  setEdgeLength,
  setEntropy,
}: TProps) => {
  const dispatch = useDispatch();
  const [isSadCat, setSadCat] = useState(false);
  const [isFirstUpdate, setFirstUpdate] = useState(true);
  const cities = useSelector(selectAllCities);
  const sitesOptions = useMemo(
    () =>
      sites
        ?.filter(
          (el) =>
            filter.alien === "все" ||
            (filter.alien === "наши" && el?.is_our) ||
            (filter.alien === "не наши" && !el?.is_our)
        )
        ?.filter((el) => !filter?.is_on_best_now || el?.is_on_best_now)
        ?.map((el) => ({ label: el.site_name, value: el.best_id })),
    [sites, page?.url, filter.alien, filter?.is_on_best_now]
  );
  const cityOptions = useMemo(
    () =>
      cities
        ?.filter((el) => el.city_code !== "all")
        ?.map((el) => ({ label: el.city_name, value: el.city_id })),
    [cities]
  );
  const alienOptions = useMemo(() => ["все", "наши", "не наши"] as Array<TAlienOptions>, []);
  const isMoreThanTwoHours = +new Date(filter?.time_from) < +new Date() - 2 * 60 * 60 * 1000;
  const disabled =
    isLoading ||
    (isMoreThanTwoHours &&
      (filter.time_to === "" || +new Date(filter.time_from) > +new Date(filter.time_to)));

  const linksTypes = useMemo(
    () =>
      [
        {
          title: filter.type === "city" ? "город" : "сайт",
          value: filter.type === "city" ? "city" : "site",
          color: "#2E9",
        },
        {
          title: filter.type === "city" ? "сайт" : "город",
          value: filter.type === "city" ? "site" : "city",
          color: "#40AEF0",
        },
        { title: "from", value: "from_", color: "#9999FF" },
        { title: "to", value: "to", color: "#FF1020DD" },
      ] as Array<TDropType>,
    [filter.type]
  );

  const reverse = () => {
    const acc = [...new Set([...filter.from, ...filter.to])];
    setFilter({ ...filter, from: acc, to: acc });
  };

  useEffect(() => {
    if (
      isFirstUpdate &&
      !!filter.time_from.length &&
      !!filter.time_to.length &&
      +new Date(filter.time_from) > +new Date(filter.time_to)
    ) {
      setSadCat(true);
      setFirstUpdate(false);
    }
  }, [filter.time_from, filter.time_to]);

  return (
    <div
      className={`fixed right-0 pr-[50px] md:pr-[80px] pt-[10px] z-10 duration-300 ${
        isFilterOpen ? "max-w-[376px] opacity-100" : "max-w-0 opacity-0"
      }`}>
      <div className="w-[300px] max-h-[80vh] overflow-auto flex flex-col gap-4 pl-4 pb-[100px] pr-[16px] -mr-[16px]">
        <div className="flex flex-col gap-4 p-4 -mx-4 rounded-md border border-[#BABAC322] bg-[#BABAC311]">
          <div className="flex gap-4">
            {alienOptions?.map((el, index) => (
              <button
                key={index}
                type="button"
                className={`w-full rounded-[4px] px-[16px] py-[2px] bg-stale hover:opacity-80 border border-lightGray ${
                  filter.alien === el ? "bg-[#C6FBDE]" : "bg-stale text-lightFont"
                }`}
                onClick={() => setFilter({ ...filter, alien: el })}>
                {el}
              </button>
            ))}
          </div>
          <button
            type="button"
            className={`flex w-full rounded-[4px] px-[16px] py-[2px] bg-stale justify-center hover:opacity-80 border border-lightGray ${
              filter.is_on_best_now ? "bg-[#C6FBDE]" : "bg-stale text-lightFont"
            }`}
            onClick={() => setFilter({ ...filter, is_on_best_now: !filter.is_on_best_now })}>
            {filter.is_on_best_now ? "сейчас на бесте" : "показать только сейчас на бесте"}
          </button>
          <ReactSelect
            placeholder="сайты..."
            options={sitesOptions}
            value={filter.sites}
            filterOption={createFilter(selectFilter)}
            onChange={(e: TSelect[]) => {
              setFilter({ ...filter, sites: e });
              const justOurs = e?.filter(
                (el) => !!sites?.find((site) => site.best_id === el.value)?.is_our
              );
              if (!!justOurs.length) {
                dispatch(
                  setSelectedSite({
                    site_id: justOurs[justOurs.length - 1]?.value,
                    site_name: justOurs[justOurs.length - 1]?.label,
                  })
                );
              }
            }}
            isMulti
          />
          {page?.url === "graph" && (
            <div className="text-[10px] text-lightFont whitespace-pre">
              {`Если не выбраны сайты - графы покажут все\nвключенные направления по нашим сайтам\n\nЭнтропия отобразится при 2+ сайтах`}
            </div>
          )}
        </div>
        <ReactSelect
          placeholder="города..."
          options={cityOptions}
          value={filter.city}
          filterOption={createFilter(selectFilter)}
          onChange={(e: TSelect[]) => {
            setFilter({ ...filter, city: e });
            !!e.length &&
              dispatch(
                setSelectedCity({
                  city_id: e[e.length - 1]?.value,
                  city_name: e[e.length - 1]?.label,
                })
              );
          }}
          isMulti
        />
        <ReactSelect
          placeholder="from..."
          options={currenciesOptions}
          value={filter.from}
          filterOption={createFilter(selectFilter)}
          onChange={(e: TSelect[]) => {
            setFilter({ ...filter, from: e });
            !!e.length && dispatch(setSelectedFrom(e[e.length - 1]?.label));
          }}
          isMulti
        />
        <div className="grid grid-cols-[1fr_38px] gap-4 w-full">
          <ReactSelect
            placeholder="to..."
            options={currenciesOptions}
            value={filter.to}
            filterOption={createFilter(selectFilter)}
            className="w-full"
            onChange={(e: TSelect[]) => {
              setFilter({ ...filter, to: e });
              !!e.length && dispatch(setSelectedTo(e[e.length - 1]?.label));
            }}
            isMulti
          />
          <button
            type="button"
            className="flex justify-center items-center w-[38px] min-w-[38px] min-h-[38px] border border-lightGray rounded-[4px] bg-stale hover:opacity-80"
            onClick={reverse}>
            <img src={change} alt="Реверс" width={20} height={20} />
          </button>
        </div>
        <div className="flex flex-col gap-4 p-4 -mx-4 rounded-md border border-[#BABAC322] bg-[#BABAC311]">
          <div className="flex gap-4 w-full">
            <input
              className={`px-8 py-2 font-light rounded-[4px] border border-lightGray w-full`}
              id={"from_filter"}
              type={"datetime-local"}
              defaultValue={filter.time_from || ""}
              onBlur={async (e) => {
                setFilter({ ...filter, time_from: e.target.value });
              }}
            />
            <button
              type="button"
              className={`flex justify-center items-center w-[26px] h-[26px] min-w-[26px] rounded-[4px] hover:opacity-80 text-[#282c3455] border border-lightGray bg-stale`}
              onClick={() => {
                const elem = document.getElementById("from_filter") as HTMLInputElement;
                if (!!elem) {
                  elem.value = "";
                }
                setFilter({ ...filter, time_from: "" });
              }}>
              {`\u2716`}
            </button>
          </div>
          <div
            className={`flex gap-4 w-full duration-300 ${
              isMoreThanTwoHours || page?.url !== "graph" || filter.time_to !== ""
                ? "max-h-[100px]"
                : "max-h-0 overflow-hidden"
            }`}>
            <input
              className={`px-8 py-2 font-light rounded-[4px] border border-lightGray w-full`}
              id={"to_filter"}
              type={"datetime-local"}
              defaultValue={filter.time_to || ""}
              onBlur={async (e) => {
                setFilter({ ...filter, time_to: e.target.value });
              }}
            />
            <button
              type="button"
              className={`flex justify-center items-center w-[26px] h-[26px] min-w-[26px] rounded-[4px] hover:opacity-80 text-[#282c3455] border border-lightGray bg-stale`}
              onClick={() => {
                const elem = document.getElementById("to_filter") as HTMLInputElement;
                if (!!elem) {
                  elem.value = "";
                }
                setFilter({ ...filter, time_to: "" });
              }}>
              {`\u2716`}
            </button>
          </div>
          <div className="text-[10px] text-lightFont">
            Если не выбрано - {page?.url === "graph" ? "за сейчас" : "за последние 30 минут"}
          </div>
          {page?.url === "graph" && isMoreThanTwoHours && (
            <div className="text-[10px] text-lightFont mt-8">
              Выберите дату окончания для рассчета Энтропии
            </div>
          )}
        </div>
        {!!data?.nodes?.length && (
          <div className="relative w-full h-[30px] min-h-[30px]">
            <input
              type="range"
              min={0}
              max={100}
              step={5}
              className={`absolute left-0 top-[12px] thumb w-[280px]`}
              defaultValue={50}
              onChange={(e) => setEdgeLength(+e.target.value * 4 + 10)}
            />
          </div>
        )}
        <div className="flex gap-4">
          {!!page && page.url === "graph" && (
            <div className="flex gap-4">
              <button
                type="button"
                className={`flex justify-center items-center w-[26px] h-[26px] min-w-[26px] pt-4 rounded-[4px] bg-stale hover:opacity-80 border border-lightGray ${
                  hierarchical ? "bg-[#C6FBDE]" : "bg-stale"
                }`}
                onClick={() => {
                  if (!hierarchical) {
                    setHierarchical(true);
                  }
                  if (hierarchical && filter?.direction === "UD") {
                    setFilter({ ...filter, direction: "LR" });
                  }
                  if (hierarchical && filter?.direction === "LR") {
                    setHierarchical(false);
                    setFilter({ ...filter, direction: "UD" });
                  }
                }}>
                <img
                  src={hierarchy}
                  alt="hierarchy"
                  width={20}
                  height={20}
                  className={`relative opacity-60 duration-300 ${
                    filter?.direction === "LR" ? "-rotate-90 -top-px left-2" : "rotate-0 -left-px"
                  }`}
                />
              </button>
              <div
                className={`flex justify-center items-center w-[26px] h-[26px] min-w-[26px] rounded-[4px] bg-stale border border-lightGray`}>
                <div className="relative -top-px -left-px">
                  <DropEditor
                    currentTypes={filter?.level}
                    setCurrentTypes={(e: Array<TLinks>) => {
                      setFilter({ ...filter, level: e });
                      setSelectedNodes(
                        data?.nodes?.filter((el) => selectedFilter(el, e[0]))?.map((el) => el.id)
                      );
                    }}
                    isAccess
                    isOnlyValue
                    types={linksTypes}>
                    <img
                      src={eye}
                      alt="показать все"
                      width={16}
                      height={16}
                      className="brightness-75 relative top-px left-px"
                    />
                  </DropEditor>
                </div>
              </div>
              <button
                type="button"
                className={`flex justify-center items-center w-[26px] h-[26px] min-w-[26px] rounded-[4px] hover:opacity-80 border border-lightGray ${
                  filter.type === "city" ? "bg-[#C6FBDE]" : "bg-stale"
                }`}
                onClick={() =>
                  setFilter({ ...filter, type: filter.type === "site" ? "city" : "site" })
                }>
                <img src={office} alt="от сайта" width={16} height={16} className="brightness-50" />
              </button>
              <div
                className={`flex justify-center items-center w-[26px] h-[26px] min-w-[26px] rounded-[4px] border border-lightGray ${
                  !filter.links?.length ? "bg-stale" : "bg-[#C6FBDE]"
                }`}>
                <div className="relative -top-px -left-px">
                  <DropEditor
                    currentTypes={filter.links}
                    setCurrentTypes={(e: Array<TLinks>) => setFilter({ ...filter, links: e })}
                    isAccess
                    types={linksTypes?.filter((el, index) => index !== 0)}>
                    <img src={chart} alt="связи" width={16} height={16} className="brightness-50" />
                  </DropEditor>
                </div>
              </div>
            </div>
          )}
          <button
            type="button"
            className={`w-full rounded-[4px] px-[16px] py-2 h-[26px] bg-stale hover:opacity-80 border border-lightGray ${
              disabled ? "opacity-30" : ""
            }`}
            disabled={disabled}
            onClick={async () => {
              if (!!page) {
                if (page.url === "graph") {
                  fetchGraph();
                  if (filter.sites?.length !== 1) {
                    fetchEntropy();
                  } else {
                    setEntropy(null);
                  }
                }
                if (page.url === "last-available") {
                  await fetchLastAvailable();
                }
              }
            }}>
            Запросить
          </button>
        </div>
      </div>
      {isSadCat && (
        <Popup closeModal={() => setSadCat(false)}>
          <SadCat
            message={`Котики грустят, когда вы выставляете\nдату "с" больше даты "по"`}
            setFn={setSadCat}
          />
        </Popup>
      )}
    </div>
  );
};
