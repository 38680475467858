import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Url } from "src/api/constants";
import { selectModules } from "src/store/academyReducer";
import { TNav } from "../types";
import { TestNav } from "../components/TestNav";
import { EditCommon } from "./EditCommon";
import { EditModules } from "./EditModules";
import { EditModuleBlocks } from "./EditModuleBlocks";

export const EditTest = () => {
  const [searchParams] = useSearchParams();
  const course_id = searchParams.get("id");
  const tabType = searchParams.get("type");
  const module_id = searchParams.get("module_id");
  const modules = useSelector(selectModules);

  const modulesTab = {
    title: "Модули",
    module_id: Url.ACADEMY_MODULE_PARAM,
    path:
      Url.ACADEMY +
      Url.ACADEMY_EDIT +
      `?id=${course_id}` +
      `&module_id=${Url.ACADEMY_MODULE_PARAM}`,
  };

  const modulesTabs = modules?.map((el) => ({
    title: el.module_name,
    module_id: el.module_id?.toString(),
    module_type: el.module_type,
    path:
      Url.ACADEMY +
      Url.ACADEMY_EDIT +
      `?id=${course_id}` +
      `&module_id=${el.module_id}` +
      `&type=${el.module_type}`,
  }));

  const tabs: TNav[] = !!modulesTabs?.length
    ? [modulesTab, ...modulesTabs]
    : !!course_id
    ? [modulesTab]
    : [];

  return (
    <div>
      <TestNav isEdit tabs={tabs} />
      {!tabType && !module_id && <EditCommon />}
      {module_id === Url.ACADEMY_MODULE_PARAM && <EditModules />}
      {!!tabType && <EditModuleBlocks />}
    </div>
  );
};
