import { joinSegments } from "src/helpers/onOffTimeline/combine";
import { getUnsubmittedSegments } from "src/helpers/onOffTimeline/copyPaste";
import { ApplyingChangesMode } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/TetrisSubmitPopup";
import { ChangedSite, ChangedSiteType } from "src/helpers/onOffTimeline/getNewCityFromChangesData";
import { ISite, ITimeSegment } from "src/types/OnOffTimeline/common";

interface GetEditedSiteDataProps {
  site: ISite;
  existingSite: ISite;
  group: string;
  pair: string;
  applyingMode: ApplyingChangesMode;
  username: string;
}

/**
 по переданным данным получает новые данные 
 для указанного сайта
 */
export const getEditedSiteData = ({
  site,
  existingSite,
  group,
  pair,
  applyingMode,
  username,
}: GetEditedSiteDataProps) => {
  let newSegmentsSite: ITimeSegment[] = [];
  let newSegmentsBest: ITimeSegment[] = [];

  switch (applyingMode) {
    case ApplyingChangesMode.MERGE:
      newSegmentsBest = joinSegments([...existingSite.segments_best, ...site.segments_best]);
      newSegmentsSite = joinSegments([...existingSite.segments_site, ...site.segments_site]);
      break;
    case ApplyingChangesMode.MASH:
      newSegmentsBest = [...site.segments_best];
      newSegmentsSite = [...site.segments_site];
      break;
  }

  const newSite: ChangedSite = {
    ...existingSite,
    unsubmitted_segments_site: getUnsubmittedSegments(
      existingSite.segments_site,
      newSegmentsSite,
      username
    ),
    unsubmitted_segments_best: getUnsubmittedSegments(
      existingSite.segments_best,
      newSegmentsBest,
      username
    ),
    group,
    pair,
    type: ChangedSiteType.EDITED,
  };

  return newSite;
};
