import { Dispatch, SetStateAction, useState } from "react";
import { TGlobalSite } from "src/store/directionsReducer";
import { TAdminSite } from "src/store/commonReducer";
import { SiteForm } from "./CreateSite/SiteForm";
import on from "src/assets/images/check.svg";
import off from "src/assets/images/close.svg";
import { cn } from "src/utils";
import { BadgesFilters } from "src/pages/Add/components/Sites/Sites";

const getMatchesFilters = (
  badgesFilters: BadgesFilters,
  badges: Array<{ title: string; value: boolean } | null>
) => {
  if (Object.values(badgesFilters).every((item) => !item.selected)) {
    return false;
  }

  let res = true;

  for (const [key, val] of Object.entries(badgesFilters)) {
    for (const badge of badges) {
      if (badge.title === key && val.selected && val.enabled !== badge.value) {
        res = false;
      }
    }
  }

  return res;
};

export const SiteRow = ({
  item,
  access,
  badgesFilters,
  onDeleteClick,
  data,
  setMessage,
  isSelected,
  setStatus,
  setIsLoading,
  fetchAdminSites,
  onAddSite,
}: {
  item: TGlobalSite;
  access: boolean;
  badgesFilters: BadgesFilters;
  onDeleteClick: (e: React.MouseEvent) => void;
  data?: TAdminSite;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  fetchAdminSites: () => void;
  isSelected?: boolean;
  onAddSite?: (site: TGlobalSite) => void;
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const badges = [
    data ? { title: "синхронизатор", value: data?.tumbler_site } : null,
    data ? { title: "вылеты", value: data?.tumbler_scam_checker } : null,
    data ? { title: "вкл/выкл", value: data?.update_turn_status } : null,
    data ? { title: "show_file", value: data?.has_show_file } : null,
    data ? { title: "процедуры", value: data?.has_procedure } : null,
    data ? { title: "new", value: data?.update_new } : null,
    data ? { title: "DB", value: data?.db_access } : null,
    data ? { title: "PE", value: data?.is_pe } : null,
    data ? { title: "Ядро", value: !!data?.core_api_url?.length } : null,
    data ? { title: "структура", value: data?.new_db_structure } : null,
  ] as Array<{ title: string; value: boolean } | null>;

  const handleClick = () => {
    onAddSite?.(item);
  };

  if (
    Object.values(badgesFilters).some((item) => item.selected) &&
    !getMatchesFilters(badgesFilters, badges)
  ) {
    return null;
  }

  return (
    <div
      className={cn(
        `cursor-pointer rounded-lg duration-700 shadow-smooth p-[10px] pl-[20px] text-left text-xs ${
          isEdit ? "max-h-[1000px] overflow-auto" : "max-h-[38px] overflow-hidden"
        }`,
        isSelected && "bg-[#72E5AE77]"
      )}
      onClick={handleClick}>
      <div className="grid grid-cols-[22px_1fr_20px_16px] sm:grid-cols-[28px_1fr_260px_20px_20px] gap-[10px] items-center">
        {data ? (
          <div className="flex justify-center w-[18px]">
            <img src={data?.is_on_best ? on : off} alt="" width={16} height={16} />
          </div>
        ) : (
          <div />
        )}
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
          {data?.site_name || item?.site_name}
        </div>
        <div className="hidden sm:flex flex-wrap gap-4 justify-center text-[9px] -my-[10px] whitespace-pre bg-bg/50 rounded-lg">
          {badges?.map(
            (el) =>
              el !== null && (
                <div
                  key={el.title}
                  className={`px-[6px] pb-[1px] rounded-lg outline outline-1 text-[#383C44] font-normal ${
                    el?.value
                      ? "bg-[#3BB54A11] outline-[#3BB54A55]"
                      : "bg-[#D7443E11] outline-[#D7443E33]"
                  }`}>
                  {el.title}
                </div>
              )
          )}
        </div>
        {data ? (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setIsEdit(!isEdit);
            }}>
            {`\u270E`}
          </div>
        ) : (
          <div />
        )}
        <div>
          {access && (
            <button
              className={`duration-300 text-lightGray hover:text-lightFont`}
              onClick={onDeleteClick}>{`\u2716`}</button>
          )}
        </div>
      </div>
      {data && (
        <SiteForm
          data={data}
          setMessage={setMessage}
          setStatus={setStatus}
          setIsLoading={setIsLoading}
          fetchAdminSites={fetchAdminSites}
          access={access}
        />
      )}
    </div>
  );
};
