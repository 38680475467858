import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LS } from "src/api/constants";
import { selectLSRatios, setLSRatios } from "src/store/statsReducer";
import { TRatio } from "../models";
import trophy from "src/assets/images/trophy.svg";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";

type TProps = {
  cityId?: number;
  cities?: number[];
  ratios: TRatio[];
  ratio: TRatio | null;
  setRatio?: Dispatch<SetStateAction<TRatio | null>>;
};

export const TopRatios = ({ cityId, cities, ratio, ratios, setRatio }: TProps) => {
  const dispatch = useDispatch();
  const LSRatios = useSelector(selectLSRatios);
  const [isUpd, setUpd] = useState(false);

  return (
    <div className="flex w-full text-xs text-lightFont relative top-2 rounded-md bg-bg py-2">
      <Tooltip content="Добавьте рассчет в X / Y">
        {isUpd ? (
          <div className="flex flex-col">
            {!!ratios?.length ? (
              ratios?.map((el) => (
                <button
                  key={el.ratio_id}
                  type="button"
                  className="w-full flex items-center gap-[16px] py-2 duration-300 hover:bg-lightStale rounded-md px-[10px]"
                  onClick={() => {
                    let preparedRatios = [];
                    if (!!cityId) {
                      const savedRatio = LSRatios?.find((ratioEl) => ratioEl?.city_id === cityId);
                      if (!!savedRatio) {
                        preparedRatios = LSRatios?.map((ratioEl) =>
                          ratioEl.city_id === cityId
                            ? {
                                ...ratioEl,
                                ratio_id: el.ratio_id,
                              }
                            : ratioEl
                        );
                      } else if (!!LSRatios) {
                        preparedRatios = [
                          ...LSRatios,
                          {
                            city_id: cityId,
                            ratio_id: el.ratio_id,
                          },
                        ];
                      } else {
                        preparedRatios = [
                          {
                            city_id: cityId,
                            ratio_id: el.ratio_id,
                          },
                        ];
                      }
                    } else {
                      preparedRatios = cities?.map((cityEl) => ({
                        city_id: cityEl,
                        ratio_id: el.ratio_id,
                      }));
                    }
                    dispatch(setLSRatios(preparedRatios));
                    localStorage.setItem(
                      LS.STATS_RATIOS,
                      preparedRatios
                        ?.map((ratioEl) => `${ratioEl.city_id}_${ratioEl.ratio_id}`)
                        ?.join(",")
                    );
                    !!setRatio && setRatio(el);
                    setUpd(false);
                  }}>
                  <img src={trophy} alt="топ" width={10} height={10} className="relative top-px" />
                  <div>{el?.target_percent || "?"}</div> / <div>{el?.range_percent || "?"}</div>
                </button>
              ))
            ) : (
              <button
                type="button"
                className="py-4 px-8 text-lightFont"
                onClick={() => setUpd(false)}>
                Соотношения не добавлены
              </button>
            )}
          </div>
        ) : (
          <button
            type="button"
            className="w-full flex items-center gap-[16px] px-[10px] font-bold"
            onClick={() => setUpd(true)}>
            <img src={trophy} alt="топ" width={10} height={10} className="relative top-px" />
            <div>{ratio?.target_percent || "?"}</div> / <div>{ratio?.range_percent || "?"}</div>
          </button>
        )}
      </Tooltip>
    </div>
  );
};
