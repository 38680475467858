import { memo, useEffect, useMemo, useRef } from "react";
import { CityTimeline } from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/CityTimeline";
import { useDispatch, useSelector } from "react-redux";
import {
  setCityTimelineWidth,
  setTimelineContainerScrollLeft,
  setOnOffAxisFixed,
  setZoom,
} from "src/store/onOffTimelineReducer/slice";
import { timeToSeconds } from "src/helpers/date";
import { Emptiness, GoodEmptiness } from "src/components/UI/loader/Emptiness";
import { AxisX } from "src/pages/Clusters/Timeline/components/TimelineItems/AxisX";
import { cn } from "src/helpers/cn";
import useDebounce from "src/hooks/useDebounce";
import {
  selectOnOffIsLoading,
  selectTimelineContainerScrollLeft,
  selectOnOffIsSitesMenuOpen,
  selectOnOffTimelineStep,
  selectOnOffTimelineZoom,
  selectCityTimelineWidth,
  selectOnOffAxisFixed,
  selectOnOffLastAppliedFilters,
} from "src/store/onOffTimelineReducer/selectors";
import { getCitiesWithGroups } from "src/helpers/onOffTimeline/getCititesWithGroups";
import { ICity } from "src/types/OnOffTimeline/common";

export const STICKY_BLOCK_WIDTH = 310;
export const confirmDelete = "Вы уверены, что хотите удалить таймлайны для";
export const confirmUndo = "Вы уверены, что хотите отменить все изменения для";

interface TimeLineDataProps {
  data: ICity[];
  filteredData: ICity[];
}

export const useAxisX = () => {
  const now = new Date();
  const nowTime = Math.round(timeToSeconds(`${now.getHours()}:${now.getMinutes()}`) / 60);
  const step = useSelector(selectOnOffTimelineStep);
  const zoom = useSelector(selectOnOffTimelineZoom);
  const axisX = useMemo(() => Array.from(Array(1440 / step).keys()), [step]);

  return {
    nowTime,
    step,
    zoom,
    axisX,
  };
};

export const TimeLineData = memo(({ data, filteredData }: TimeLineDataProps) => {
  const dispatch = useDispatch();

  const xAxisContainerRef = useRef<HTMLDivElement>(null);
  const timelineContainerRef = useRef<HTMLDivElement>(null);

  const isLoading = useSelector(selectOnOffIsLoading);
  const scrollLeft = useSelector(selectTimelineContainerScrollLeft);
  const isSitesMenuOpen = useSelector(selectOnOffIsSitesMenuOpen);
  const cityTimelineWidth = useSelector(selectCityTimelineWidth);
  const { nowTime, step, zoom, axisX } = useAxisX();

  const lastAppliedFilters = useSelector(selectOnOffLastAppliedFilters);

  const axisFixed = useSelector(selectOnOffAxisFixed);

  useEffect(() => {
    const diff = isSitesMenuOpen ? 640 : 490;
    dispatch(setZoom((window.innerWidth - diff) / 24));
  }, [isSitesMenuOpen]);

  useEffect(() => {
    dispatch(setCityTimelineWidth(zoom * axisX.length + STICKY_BLOCK_WIDTH));
  }, [zoom, axisX.length]);

  useEffect(() => {
    const handleScroll = () => {
      const targetElement = xAxisContainerRef.current;

      if (targetElement) {
        const targetRect = targetElement.getBoundingClientRect();
        const isScrolled = targetRect.top <= 50;

        dispatch(setOnOffAxisFixed(!!isScrolled));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [xAxisContainerRef]);

  const debouncedHandleScrollLeft = useDebounce((val: number) => {
    dispatch(setTimelineContainerScrollLeft(val));
  }, 200);

  useEffect(() => {
    const targetElement = timelineContainerRef.current;

    const handleScroll = () => {
      debouncedHandleScrollLeft(targetElement?.scrollLeft);
    };

    targetElement?.addEventListener("scroll", handleScroll);
  }, [timelineContainerRef, debouncedHandleScrollLeft]);

  if (isLoading && (!filteredData || !filteredData.length)) {
    return <div>загрузка...</div>;
  }

  if (!data || data.length === 0) {
    if (Object.values(lastAppliedFilters).some((array) => array.length > 0)) {
      return <Emptiness message="Данных не найдено" />;
    } else {
      return <GoodEmptiness message="Вы еще не запрашивали данные" />;
    }
  }

  if (!filteredData || filteredData.length === 0) {
    return <GoodEmptiness message="Для этих фильтров данных нет" />;
  }

  const groupedCities = getCitiesWithGroups(filteredData);

  return (
    <div ref={xAxisContainerRef} className="pr-[150px] md:pr-[80px] mt-[30px] relative">
      <div className="overflow-x-auto grid grid-cols-1 relative" ref={timelineContainerRef}>
        <div
          style={{
            left: 203 - scrollLeft - (isSitesMenuOpen ? 30 : 180),
          }}
          className={cn(
            "grid grid-cols-[330px_1fr] z-[10]",
            axisFixed && "fixed top-[72px] transition-all"
          )}>
          <div />
          <AxisX
            nowTime={nowTime}
            axisX={axisX}
            step={step}
            zoom={zoom}
            containerClassName="h-[40px] max-h-[40px]"
            blockClassName={cn(
              "flex items-center justify-center",
              zoom >= 40 && "text-[10px] font-bold",
              zoom >= 50 && "text-[12px]",
              zoom >= 60 && "text-[14px]"
            )}
          />
        </div>
        {data && data.length > 0 ? (
          <div
            className={cn(
              "relative flex flex-col gap-[12px] mx-[20px] pb-[30px]",
              axisFixed && "mt-[40px]"
            )}
            style={{ width: cityTimelineWidth + 40 }}>
            {isLoading && (
              <div className="absolute z-[100] flex items-center justify-center -indent-0 w-full h-full">
                <div className="absolute -inset-0 opacity-50 bg-white z-[101]" />
                <span className="z-[102]">загрузка....</span>
              </div>
            )}
            {groupedCities.map((item) => (
              <CityTimeline key={item.city_code} item={item} width={cityTimelineWidth} />
            ))}
          </div>
        ) : (
          <Emptiness message="Данных не найдено. Возможно, они еще не были добавлены" />
        )}
      </div>
    </div>
  );
});
