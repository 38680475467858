import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cn } from "src/helpers/cn";
import { TimelineFilters } from "src/pages/Panel/OnOff/OnOffTimeline/components/header/TimelineFilters";
import {
  setOnOffCurrencyFilters,
  toggleExpandEverything,
} from "src/store/onOffTimelineReducer/slice";
import arrow from "src/assets/images/arrow.svg";
import { ExpandActionType } from "src/types/OnOffTimeline/store";
import {
  selectOnOffAllExpanded,
  selectTimelineContainerScrollLeft,
  selectOnOffAxisFixed,
  selectOnOffCurrencyFilters,
} from "src/store/onOffTimelineReducer/selectors";
import { TimelineMenu } from "src/pages/Panel/OnOff/OnOffTimeline/components/header/TimelineMenu";
import { ICity } from "src/types/OnOffTimeline/common";
import { CurrensyTypesFilter } from "src/pages/Tetris/MiniTetris/components/CurrencyTypesFilter";

export const TimelineHeader = ({ data }: { data: ICity[] }) => {
  const dispatch = useDispatch();

  const currencyFilters = useSelector(selectOnOffCurrencyFilters);
  const isEverythingExpanded = useSelector(selectOnOffAllExpanded);
  const timelineContainerScrollLeft = useSelector(selectTimelineContainerScrollLeft);
  const stickToTop = useSelector(selectOnOffAxisFixed);

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded((prev) => !prev);

  const isExpanded = stickToTop ? expanded : true;

  return (
    <div
      className={cn(
        "flex flex-col rounded-md w-fit max-w-[87vw] sticky z-[32] transition-all top-[110px] left-[-100px] pointer-events-none",
        timelineContainerScrollLeft < 20 && "ml-[22px]",
        isExpanded && "shadow-[0px_0px_30px_-10px_rgba(0,0,0,0.3)] pointer-events-auto"
      )}>
      {data && data.length > 0 && (
        <button
          onClick={() =>
            dispatch(
              toggleExpandEverything(
                isEverythingExpanded ? ExpandActionType.COLLAPSE : ExpandActionType.EXPAND
              )
            )
          }
          className={cn(
            "absolute right-[10px] top-[16px] pointer-events-none opacity-0 text-sm text-grayDark hover:bg-gray/10 rounded-sm transition px-[12px] py-[6px]",
            isExpanded && "pointer-events-auto opacity-100"
          )}>
          {isEverythingExpanded ? "Закрыть все" : "Раскрыть все"}
        </button>
      )}
      {stickToTop && (
        <div
          className={cn(
            "pointer-events-auto flex items-center justify-center bg-bg w-[311px] h-[39px] absolute left-0 top-[-38px] shadow-[0px_0px_30px_-10px_rgba(0,0,0,0.3)]"
          )}>
          <button
            onClick={toggleExpanded}
            className="flex items-center gap-[8px] rounded-md py-[1px] px-[10px] hover:bg-gray/10 transition">
            <span>Меню</span>
            <span
              className={`relative duration-300 ${
                isExpanded ? "rotate-180" : ""
              } w-[10px] h-[10px] min-w-[10px] mt-[3px]`}>
              <img src={arrow} alt="раскрыть" width={10} height={10} />
            </span>
          </button>
        </div>
      )}
      <div
        className={cn(
          "rounded-md bg-bg p-[20px] opacity-[0]",
          stickToTop && "rounded-[0_0_6px_6px]",
          isExpanded && "opacity-100"
        )}>
        <TimelineMenu data={data} />
        <TimelineFilters />
        {data && data.length > 0 && (
          <div className="flex flex-col mt-[15px] items-start text-sm">
            <h3 className="mb-[2px]">Фильтры отображаемых данных</h3>
            <div
              className={`flex flex-col xl:flex-row gap-y-4 gap-x-8 text-xs text-lightFont font-semibold`}>
              <div className="flex items-center gap-x-[20px]">
                <div>from:</div>
                <div className="flex gap-4 mt-4">
                  <CurrensyTypesFilter
                    color="#40AEF055"
                    directionType="from"
                    currencyFilters={currencyFilters}
                    onSelect={(type, side) => dispatch(setOnOffCurrencyFilters({ type, side }))}
                  />
                </div>
                <div>to:</div>
                <div className="flex gap-4 mt-4">
                  <CurrensyTypesFilter
                    color="#40AEF055"
                    directionType="to"
                    currencyFilters={currencyFilters}
                    onSelect={(type, side) => dispatch(setOnOffCurrencyFilters({ type, side }))}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
