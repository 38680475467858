import { useEffect, useState } from "react";
import requestApi from "src/api/requests";
import { fetchRequest } from "src/helpers/fetchRequest";
import { Button } from "src/shadcn/ui/ui/button";
import { Popover, PopoverTrigger, PopoverContent } from "src/shadcn/ui/ui/popover";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/shadcn/ui/ui/table";
import { Loader2 } from "lucide-react";
import { setNotific } from "src/store/mainReducer";
import { useAppDispatch } from "src/store/store";
import { BarChart3 } from "lucide-react";
import fire from "src/assets/images/fire.svg";
import question from "src/assets/images/question.svg";
import lightning from "src/assets/images/lightning.svg";
import { DatePickerWithRange, Presets, dateRangePresets } from "src/components/Date/DatePicker";
import { DateRange } from "react-day-picker";
import { getStartOfTheDate } from "src/helpers/date";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";

const thisWeek = dateRangePresets.this_week;

interface TStatistics {
  request: number;
  fire: number;
  task: number;
}

const dict = {
  request: {
    title: "Запросы",
    icon: question,
    bg: "#40AEF0",
  },
  fire: {
    title: "Пожары",
    icon: fire,
    bg: "#FF1020DD",
  },
  task: {
    title: "Задачи",
    icon: lightning,
    bg: "#9999FF",
  },
};

const getCurrentPreset = ({ from, to }: { from: Date; to: Date }): Presets | null => {
  for (const [preset, val] of Object.entries(dateRangePresets)) {
    if (
      getStartOfTheDate(from.getTime()) === getStartOfTheDate(val.from.getTime()) &&
      getStartOfTheDate(to.getTime()) === getStartOfTheDate(val.to.getTime())
    ) {
      return preset as Presets;
    }
  }
  return null;
};

export const Statistics = () => {
  const dispatch = useAppDispatch();

  const [period, setPeriod] = useState({
    from: Math.floor(thisWeek.from.getTime() / 1000),
    to: Math.floor(thisWeek.to.getTime() / 1000),
    // дополнительные поля для хранения изначальных значений в формате даты
    // и последующего сравнения их со значениями пресетов
    initialFrom: thisWeek.from,
    initialTo: thisWeek.to,
  });

  const [data, setData] = useState<TStatistics | null>(null);
  const [loading, setLoading] = useState(true);

  const onPeriodChange = (range: DateRange | undefined) => {
    if (range && range.from && range.to) {
      const from = Math.floor(range.from.getTime() / 1000);
      const to = Math.floor(range.to.getTime() / 1000);
      setPeriod({ from, to, initialFrom: range.from, initialTo: range.to });
    }
  };

  const fetchData = async () => {
    setLoading(true);

    const { response, error } = await fetchRequest(requestApi.getStatistics(period));

    if (error || !response) {
      dispatch(
        setNotific({
          type: "error",
          message: "Ошибка при получении статистики",
        })
      );
      setData(null);
      setLoading(false);
      return;
    }

    setData(response);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [period]);

  const preset = getCurrentPreset({
    from: period.initialFrom,
    to: period.initialTo,
  });

  return (
    <div className="absolute left-[20px] top-[-20px]">
      <Popover>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <PopoverTrigger asChild>
                <Button variant="outline" size="icon" className="text-black border-[#40AEF0]">
                  <BarChart3 className="w-[18px] h-[18px]" />
                </Button>
              </PopoverTrigger>
            </TooltipTrigger>
            <TooltipContent side="right">
              <p>Статистика</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <PopoverContent side="bottom" className="ml-[20px] flex flex-col w-fit">
          <div className="mb-[5px]">
            <DatePickerWithRange
              from={new Date(Math.floor(period.from * 1000))}
              to={new Date(Math.floor(period.to * 1000))}
              onDateChange={onPeriodChange}
              preset={preset}
              maxDate={new Date()}
              withPresets
            />
          </div>
          {!loading ? (
            <Table className="relative">
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">Показатель</TableHead>
                  <TableHead className="text-right">Значение</TableHead>
                </TableRow>
              </TableHeader>
              {data ? (
                <TableBody>
                  {Object.entries(data).map(([key, val]) => (
                    <TableRow key={key}>
                      <TableCell className="font-medium flex items-center gap-[10px]">
                        <div
                          style={{ backgroundColor: dict[key].bg }}
                          className="h-[24px] w-[24px] rounded-sm flex items-center justify-center">
                          <img src={dict[key].icon} width={20} height={20} alt={dict[key].title} />
                        </div>
                        <p>{dict[key].title}</p>
                      </TableCell>
                      <TableCell className="text-right">{val}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableCaption className="w-full">Данные не найдены</TableCaption>
              )}
            </Table>
          ) : (
            <div className="w-full h-[40px] flex items-center justify-center">
              <Loader2 className="animate-spin mt-[4px] text-[#40AEF0]" />
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};
