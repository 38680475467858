import { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import clusterApi from "src/api/cluster";
import { fetchRequest } from "src/helpers/fetchRequest";
import { setNotific } from "src/store/mainReducer";
import { TTemplate, TTimeline } from "src/store/clusterReducer";
import { Popup } from "src/components/Popup/Popup";

type TProps = {
  items: TTimeline[];
  templates: TTemplate[];
  setTemplates: Dispatch<SetStateAction<TTemplate[]>>;
  templateName: string;
  setTemplateName: Dispatch<SetStateAction<string>>;
  setNameConfirm: Dispatch<SetStateAction<boolean>>;
  setActiveTemplate: Dispatch<SetStateAction<number | null>>;
  setTemplateData: Dispatch<SetStateAction<TTimeline[]>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setActionsCount: Dispatch<SetStateAction<number>>;
  username: string;
  isRight: boolean;
};

export const SaveAsTemplateConfirm = ({
  items,
  templates,
  setTemplates,
  templateName,
  setTemplateName,
  setNameConfirm,
  setActiveTemplate,
  setTemplateData,
  setIsLoading,
  setActionsCount,
  username,
  isRight,
}: TProps) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const createTemplate = async () => {
    setActionsCount(0);
    setIsLoading(true);
    const payload = {
      title: templateName,
      creator: username,
      data: items,
    };
    const { response } = await fetchRequest(clusterApi.createTemplate(payload), {
      request: "Новый шаблон",
    });
    if (response) {
      setTemplates([...templates, response]);
      dispatch(
        setNotific({
          type: "success",
          message: `${templateName} успешно создан`,
          request: "Новый шаблон",
        })
      );
      setActiveTemplate(response?.id);
      searchParams.set(isRight ? "right-template" : "template", response?.id);
      setSearchParams(searchParams);
      setTemplateName("");
    }
    setIsLoading(false);
  };

  return (
    <Popup closeModal={() => setNameConfirm(false)}>
      <div className="flex flex-col items-center gap-[20px] text-xs">
        <input
          className={`border border-lightGray w-[240px] px-8`}
          placeholder="Назовите шаблон"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
        <button
          type="button"
          className={`bg-light w-fit px-[16px] h-[26px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300 hover:opacity-70 ${
            !templateName?.length ? "opacity-30" : ""
          }`}
          disabled={!templateName?.length}
          onClick={async () => {
            await createTemplate();
            setNameConfirm(false);
          }}>
          Сохранить
        </button>
        <button
          type="button"
          className="text-lightFont border-b border-[#BABAC3AA] px-[20px] pb-2 hover:opacity-70 -mt-8"
          onClick={() => {
            setTemplateData(
              items?.map((el: TTimeline) => ({
                ...el,
                vectors: el.vectors?.map((vector) => ({
                  ...vector,
                  percent_position: 0,
                  cluster_percent: 0,
                  cluster_course: 0,
                  sites: [],
                })),
              }))
            );
            setActiveTemplate(-1);
          }}>
          <div className="text-[10px] pb-8">или</div>
          <div>Скопировать без сайтов</div>
        </button>
      </div>
    </Popup>
  );
};
