import { Dispatch, SetStateAction, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { TStep } from "../Step";
import min from "src/assets/images/min.svg";
import max from "src/assets/images/max.svg";

type TProps = {
  item: TStep;
  dirtyVectors: string[];
  setDirtyVectors: Dispatch<SetStateAction<string[]>>;
  isRequired: boolean;
  isAccess: boolean;
};

export const VectorRow = ({
  item,
  dirtyVectors,
  setDirtyVectors,
  isRequired,
  isAccess,
}: TProps) => {
  const {
    register,
    setValue,
    formState: { dirtyFields, errors },
  } = useFormContext();
  const dirtyLength = Object.keys(dirtyFields)?.length;

  useEffect(() => {
    if (item?.vector) {
      setValue(`${item.vector}.min_step`, item.min_step);
      setValue(`${item.vector}.max_step`, item.max_step);
    }
  }, [item?.vector, item?.min_step, item?.max_step]);

  useEffect(() => {
    for (const key in dirtyFields) {
      if (dirtyVectors?.length === 0) {
        setDirtyVectors([...dirtyVectors, key]);
      } else {
        if (!dirtyVectors?.includes(key)) {
          setDirtyVectors([...dirtyVectors, key]);
        }
      }
    }
  }, [dirtyLength]);

  return (
    <div
      className={`relative grid sm:grid-rows-1 grid-cols-1 px-[20px] py-8 sm:py-0 gap-4 items-center min-h-[40px] shadow-base rounded-lg sm:shadow-none ${
        "step" in item
          ? " grid-rows-4 sm:grid-cols-[2fr_1fr_1fr_1fr]"
          : " grid-rows-3 sm:grid-cols-[2fr_1fr_1fr]"
      }`}>
      <div className={`text w-full text-center sm:text-left sm:pl-1`}>
        {item.vector?.replace("-to-", "-")}
      </div>
      <div className="text flex items-center">
        <div className="inline sm:hidden w-[18px] mr-[5px] opacity-50 text-lightGray text-xs">
          <img src={min} className="w-[18px] h-[18px] relative top-[2px] mr-[5px]" alt="" />
        </div>
        <div className="w-full sm:w-[120px] relative mb-4">
          <input
            className="small-text w-full text-left border border-lightGray rounded outline-none h-[30px] py-4 px-8"
            defaultValue={item.min_step}
            type="number"
            disabled={!isAccess}
            min={isRequired ? 0.000001 : 0}
            step={0.000001}
            onWheel={(e) => {
              e.currentTarget?.blur();
            }}
            {...register(`${item.vector}.min_step`, {
              required: isRequired,
              minLength: isRequired ? 1 : 0,
            })}
          />
          {errors && errors[`${item?.vector}.min_step`]?.type === "required" && (
            <div className="text-red text-[10px] absolute top-[26px]">не заполнено</div>
          )}
        </div>
      </div>
      <div className="text flex items-center">
        <div className="inline sm:hidden w-[18px] mr-[5px] opacity-50 text-lightGray text-xs">
          <img src={max} className="w-[18px] h-[18px] relative top-[2px] mr-[5px]" alt="" />
        </div>
        <div className="w-full sm:w-[120px] relative mb-4">
          <input
            className="small-text w-full text-left border border-lightGray rounded outline-none h-[30px] py-4 px-8"
            defaultValue={item.max_step}
            type="number"
            disabled={!isAccess}
            min={isRequired ? 0.000001 : 0}
            step={0.000001}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            {...register(`${item.vector}.max_step`, { required: isRequired, minLength: 1 })}
          />
          {errors && errors[`${item.vector}.max_step`]?.type === "required" && (
            <div className="text-red text-[10px] absolute top-[26px]">не заполнено</div>
          )}
        </div>
      </div>
      {"step" in item && (
        <div className="flex items-center small-text w-full text-left border border-stale bg-lightStale rounded h-[30px] py-4 px-8 mb-[4px]">
          {item.step}
        </div>
      )}
    </div>
  );
};
