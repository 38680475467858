export const ExceptionVector = ({ item, currencies }) => {
  const from = currencies?.find((el) => el.currency_id === item.currency_from_id)?.currency_name;
  const to = currencies?.find((el) => el.currency_id === item.currency_to_id)?.currency_name;

  return (
    <div className="flex gap-4">
      <div>{from}</div> - <div>{to}</div>
    </div>
  );
};
