import { Dispatch, SetStateAction, useMemo } from "react";
import { useSelector } from "react-redux";
import { TGlobalCurrency, selectAllCities } from "src/store/directionsReducer";
import { TPairs } from "src/store/onOffReducer";
import { CurrencyColumn } from "./components/CurrencyColumn";
import { MultiSelect } from "src/components/FormElements/MultiSelect";
import { AllButtonSquare } from "src/components/UI/buttons/AllButtonSquare";
import { addAll, addCities } from "src/helpers/addHelper";
import { selectFilters, setFilters } from "src/store/tetrisReduser";
import { allFilter } from "../components/allFilter";
import { MiniNotice } from "src/components/UI/notice/Notice";
import { ICity } from "src/types/OnOffTimeline/common";
import { selectOnOffSites } from "src/store/onOffTimelineReducer/selectors";

export const MiniTetris = ({
  sites,
  cities,
  setCities,
  setSites,
  globalCurrencies,
  pairs,
  setPairs,
}: {
  cities: ICity[];
  sites?: string[];
  setCities: Dispatch<SetStateAction<ICity[]>>;
  setSites?: Dispatch<SetStateAction<string[]>>;
  globalCurrencies: TGlobalCurrency[];
  pairs: TPairs;
  setPairs: Dispatch<SetStateAction<TPairs>>;
}) => {
  const filters = useSelector(selectFilters);
  const globalCities = useSelector(selectAllCities);
  const onOffSites = useSelector(selectOnOffSites);

  const citiesOptions = globalCities
    ?.filter((el) => el.city_name !== "all")
    ?.map((el) => ({ label: el.city_name, value: el.city_id }));

  const sitesOptions =
    onOffSites?.map((el) => ({ label: el.site_name, value: el.site_name })) || [];

  const allCities = useMemo(
    () =>
      globalCities
        ?.filter((el) => el.city_name !== "all")
        ?.filter((el) => allFilter(el.city_name, "cities", filters)),
    [globalCities, filters.cities]
  );

  const allSites = sitesOptions
    .map((option) => option.label)
    .filter((el) => allFilter(el, "sites", filters));

  const page = {
    page: "",
    title: "",
    read_access: "",
    update_access: "",
    color: "#72E5AE",
    text: "#BABAC3",
  };

  const cityAdd = (city: ICity) => {
    const newData = [...cities];
    const existingCityIndex = newData.findIndex((item) => item.city_code === city.city_code);
    if (~existingCityIndex) {
      newData.splice(existingCityIndex, 1);
    } else {
      newData.push(city);
    }
    setCities(newData);
  };

  return (
    <div className="relative min-w-fit frame h-[70vh] square-scroll rounded-sm bg-[#F5F5FD] mr-0">
      <div className="flex flex-col lg:flex-row px-[10px] pb-[10px]">
        {/*sites*/}
        {!!sites && !!setSites && (
          <div id="sites" className="w-full lg:w-[170px]">
            <div className="sticky top-0 flex flex-col bg-bg z-10 pt-[10px] shadow-inset-top">
              {!!sites?.length && (
                <MiniNotice
                  count={sites?.length}
                  isVisible
                  right={6}
                  bg="#BBFFDA"
                  color="#2BB56A"
                />
              )}
              <MultiSelect
                options={sitesOptions}
                className={"w-full lg:w-[160px] text-xs mb-4 pr-[10px] lg:pr-0"}
                placeholder="сайт"
                obj={filters}
                attr="sites"
                setFn={setFilters}
              />
              <AllButtonSquare addAll={addAll} allItems={allSites} setFn={setSites} type="валюта" />
            </div>
            <div className="flex flex-col h-full overflow-y-auto pt-8">
              {allSites?.map((el, index) => (
                <button
                  key={index}
                  id={el}
                  type="button"
                  style={{ backgroundColor: sites?.includes(el) ? `${page.color}77` : "#FAFAFF" }}
                  className={`select_button w-[calc(100%-10px)] h-[30px] lg:w-[160px] text-sm  ${
                    sites?.includes(el) ? "font-semibold" : ""
                  }`}
                  onClick={(e) => addCities(sites, setSites, e)}>
                  {el}
                </button>
              ))}
            </div>
          </div>
        )}
        {/*sites end*/}
        <div id="cities" className="w-full lg:w-[170px]">
          <div className="sticky top-0 flex flex-col bg-bg z-10 pt-[10px] shadow-inset-top">
            {!!cities?.length && (
              <MiniNotice count={cities?.length} isVisible right={6} bg="#BBFFDA" color="#2BB56A" />
            )}
            <MultiSelect
              options={citiesOptions}
              className={"w-full lg:w-[160px] text-xs mb-4 pr-[10px] lg:pr-0"}
              placeholder="город"
              obj={filters}
              attr="cities"
              setFn={setFilters}
            />
            <AllButtonSquare addAll={addAll} allItems={allCities} setFn={setCities} type="валюта" />
          </div>
          <div className="flex flex-col h-full overflow-y-auto pt-8">
            {allCities?.map((el, index) => (
              <button
                key={index}
                id={el.city_code}
                type="button"
                style={{
                  backgroundColor: cities?.some((item) => item.city_code === el.city_code)
                    ? `${page.color}77`
                    : "#FAFAFF",
                }}
                className={`select_button w-[calc(100%-10px)] h-[30px] lg:w-[160px] text-sm  ${
                  cities?.some((item) => item.city_code === el.city_code) ? "font-semibold" : ""
                }`}
                onClick={() =>
                  cityAdd({
                    city_code: el.city_code,
                    city_name: el.city_name,
                    currency_pairs: [],
                  })
                }>
                {el.city_name}
              </button>
            ))}
          </div>
        </div>
        <div id="vectors" className="grid grid-cols-2 gap-4 w-[298px] lg:w-[322px]">
          <CurrencyColumn
            directionType="from"
            globalCurrencies={globalCurrencies}
            pairs={pairs}
            setPairs={setPairs}
            color={`${page.color}77`}
          />
          <CurrencyColumn
            directionType="to"
            globalCurrencies={globalCurrencies}
            pairs={pairs}
            setPairs={setPairs}
            color={`${page.color}77`}
          />
        </div>
      </div>
    </div>
  );
};
