import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { addAll } from "src/helpers/addHelper";
import { selectFilters, setFilters } from "src/store/tetrisReduser";
import { TCurrencyTypesFilter, TGlobalCurrency } from "src/store/directionsReducer";
import { TCurrencyTypes } from "src/pages/Text/types";
import { TPairs } from "src/store/onOffReducer";
import { AllButtonSquare } from "src/components/UI/buttons/AllButtonSquare";
import { MiniNotice } from "src/components/UI/notice/Notice";
import { allFilter } from "../../components/allFilter";
import CurrencyCard from "./CurrencyCard";
import { MultiSelect } from "src/components/FormElements/MultiSelect";
import { CurrensyTypesFilter } from "src/pages/Tetris/MiniTetris/components/CurrencyTypesFilter";
import { cn } from "src/utils";

export const CurrencyColumn = ({
  globalCurrencies,
  pairs,
  setPairs,
  directionType,
  color,
  withSelect = true,
  onDeleteCurrency,
  noPlusButton,
}: {
  globalCurrencies: TGlobalCurrency[];
  pairs: TPairs;
  setPairs: Dispatch<SetStateAction<TPairs>>;
  directionType: "from" | "to";
  color: string;
  withSelect?: boolean;
  onDeleteCurrency?: (el: TGlobalCurrency) => void;
  noPlusButton?: boolean;
}) => {
  const filters = useSelector(selectFilters);
  const [currencyFilters, setCurrencyFilters] = useState<TCurrencyTypesFilter>({
    from: [],
    to: [],
  });

  const currencies = useMemo(
    () => globalCurrencies?.filter((el) => allFilter(el, directionType, filters)),
    [globalCurrencies, filters[directionType]]
  );

  const currenciesOptions = globalCurrencies?.map((el) => ({
    label: el.currency_name,
    value: el.currency_id,
  }));

  const handleCurrencyClick = (currency: TGlobalCurrency, type: "from" | "to") => {
    setPairs((prev) => ({
      ...prev,
      [type]: prev[type].some((item) => item.id === currency.id)
        ? prev[type].filter((item) => item.id !== currency.id)
        : [...prev[type], currency],
    }));
  };

  const onCurrencyFilterClick = (type: TCurrencyTypes, side: "from" | "to") => {
    setCurrencyFilters((prev) => ({
      ...prev,
      [side]: prev[side].includes(type)
        ? prev[side].filter((item) =>
            type === "cash" ? item !== type && item !== "cash-in" : item !== type
          )
        : [...prev[side], ...(type === "cash" ? [type, "cash-in"] : [type])],
    }));
  };

  const getFilteredCurrencies = (type: "from" | "to") => {
    return currencies.filter((item) => {
      if (currencyFilters[type].length === 0) {
        return true;
      }

      return currencyFilters[type].includes(item.type);
    });
  };

  const selectAll = () => {
    setPairs((prev) => ({
      ...prev,
      [directionType]:
        prev[directionType].length === getFilteredCurrencies(directionType).length
          ? []
          : getFilteredCurrencies(directionType),
    }));
  };

  return (
    <div className="w-full lg:w-[160px] flex flex-col">
      <div
        className={cn(
          "sticky top-0 flex flex-col z-10 shadow-inset-top",
          !noPlusButton && "bg-bg pt-[10px]"
        )}>
        {!!pairs[directionType]?.length && (
          <MiniNotice
            count={pairs[directionType]?.length}
            isVisible
            right={-4}
            bg="#BBFFDA"
            color="#2BB56A"
          />
        )}
        {withSelect && (
          <MultiSelect
            options={currenciesOptions}
            className={"w-full lg:w-[160px] text-xs mb-4 pr-[10px] lg:pr-0"}
            placeholder={directionType}
            obj={filters}
            attr={directionType}
            setFn={setFilters}
          />
        )}
        {!noPlusButton && (
          <AllButtonSquare addAll={addAll} allItems={currencies} setFn={selectAll} type="валюта" />
        )}
        <div className="flex gap-4 mt-4">
          <CurrensyTypesFilter
            color={color}
            directionType={directionType}
            currencyFilters={currencyFilters}
            onSelect={onCurrencyFilterClick}
          />
        </div>
      </div>
      <div className="flex flex-col h-full overflow-y-auto pt-8">
        {getFilteredCurrencies(directionType).map((currency) => {
          const isSelected = pairs[directionType].some((item) => item.id === currency.id);
          return (
            <CurrencyCard
              key={currency.id}
              item={currency}
              isSelected={isSelected}
              onClick={() => handleCurrencyClick(currency, directionType)}
              color={color}
              deleteCurrency={onDeleteCurrency ? () => onDeleteCurrency(currency) : undefined}
            />
          );
        })}
      </div>
    </div>
  );
};
