import { BASE_URL } from "src/api/constants";
import api from "src/api/http";
import { FailedList } from "src/types/common";
import { ProfItem } from "src/types/prof";

export default class profApi {
  static async getProfRate() {
    return api.get<ProfItem[]>(`${BASE_URL}/v1/bet_service/v1/prof-form/`);
  }

  static async postProfRate(payload: ProfItem[]) {
    return api.patch<FailedList<ProfItem>>(`${BASE_URL}/v1/bet_service/v1/prof-form/`, payload);
  }
}
