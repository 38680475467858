import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactSelect, { createFilter } from "react-select";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import { LS } from "src/api/constants";
import switchApi from "src/api/onOff";
import { setNotific } from "src/store/mainReducer";
import {
  selectAllCities,
  selectAllSites,
  selectCity,
  selectCurFrom,
  selectCurTo,
  selectGlobalCurrencies,
  selectSite,
  setGlobalCurrencies,
  setSelectedCity,
  setSelectedFrom,
  setSelectedSite,
  setSelectedTo,
  TSelect,
  TStrSelect,
} from "src/store/directionsReducer";
import {
  pushSwitchVectors,
  selectSwitchVectors,
  setSwitchVectors,
  TSwitchVector,
  updateSwitchVectors,
} from "src/store/onOffReducer";
import { Emptiness, GoodEmptiness } from "src/components/UI/loader/Emptiness";
import { Popup } from "src/components/Popup/Popup";
import { Spinner } from "src/components/Spinner/Spinner";
import { PaginationLoader } from "src/components/UI/loader/PaginationLoader";
import { ColorTip } from "src/components/UI/items/ColorTip";
import { OnOffSite } from "./components/OnOffSite";
import change from "src/assets/images/arrows.svg";

export const OnOff = () => {
  const dispatch = useDispatch();
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const isAccess = userAccess?.includes("on-off_post");
  const sites = useSelector(selectAllSites)?.filter((el) => el?.is_changable);
  const currencies = useSelector(selectGlobalCurrencies);
  const cities = useSelector(selectAllCities);
  const data = useSelector(selectSwitchVectors);
  const curFrom = useSelector(selectCurFrom);
  const curTo = useSelector(selectCurTo);
  const selectedSite = useSelector(selectSite);
  const selectedCity = useSelector(selectCity);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGood, setIsGood] = useState<boolean | null>(null);
  const [isPopup, setPopup] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedArr, setCheckedArr] = useState<Array<TSwitchVector>>([]);
  const [switchState, setSwitchState] = useState(false);
  const [bestState, setBestState] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getArrValues = (arr, attr) => {
    return arr[attr]?.map((el) => (el !== null ? Object.values(el)[1] : null));
  };

  const [filter, setFilter] = useState({
    sites: [] as Array<TSelect>,
    from: [] as Array<TStrSelect>,
    to: [] as Array<TStrSelect>,
    city: [] as Array<TStrSelect>,
    enabled: null as "вкл" | "сайт" | "бест" | "выкл" | null,
    best: false,
  });

  const sitesOptions = useMemo(
    () =>
      sites
        ?.filter((el) => !filter.best || el.is_on_best)
        ?.map((el) => ({ label: el.site_name, value: el.site_id })),
    [sites, filter.best]
  );
  const currenciesOptions = useMemo(
    () =>
      currencies?.map((el) => ({
        label: el.currency_name.replace("20", ""),
        value: el.currency_name.replace("20", ""),
      })),
    [currencies]
  );
  const cityOptions = useMemo(
    () =>
      cities
        ?.filter((el) => el.city_code !== "all")
        ?.map((el) => ({ label: el.city_name, value: el.city_code })),
    [cities]
  );

  const [lastResponse, setLastResponse] = useState({
    sites: [] as Array<number>,
    from: [] as Array<string>,
    to: [] as Array<string>,
    city: [] as Array<string>,
    enabled: null as "вкл" | "сайт" | "бест" | "выкл" | null,
    best: false,
  });

  const allFilter = (item) =>
    (getArrValues(filter, "sites")?.includes(item?.site_id) || !filter.sites.length) &&
    (getArrValues(filter, "from")?.includes(item?.vector[0]) || !filter.from.length) &&
    (getArrValues(filter, "to")?.includes(item?.vector[2]) || !filter.to.length) &&
    (getArrValues(filter, "city")?.includes(item?.city_code) || !filter.city.length) &&
    ((filter.enabled === "вкл" && item.vector_is_enabled) ||
      (filter.enabled === "сайт" && item.vector_is_enabled && !item?.best_is_enabled) ||
      (filter.enabled === "бест" && item.vector_is_enabled && item?.best_is_enabled) ||
      (filter.enabled === "выкл" && !item.vector_is_enabled) ||
      filter.enabled === null);

  const reverse = () => {
    const acc = [...new Set([...filter.from, ...filter.to])];
    setFilter({ ...filter, from: acc, to: acc });
  };

  const add = (vector: TSwitchVector) => {
    const vectors = checkedArr?.map((el) => `${el.vector.join("-")}-${el.city_code}`);
    if (checkedArr?.length > 0) {
      if (vectors?.includes(`${vector.vector.join("-")}-${vector.city_code}`)) {
        setCheckedArr(checkedArr?.filter((el) => el.vector !== vector.vector));
      } else {
        setCheckedArr([...checkedArr, vector]);
      }
    } else {
      setCheckedArr([vector]);
    }
  };

  const getSwitch = async (payload, page) => {
    const { response, error, status } = await fetchRequest(
      switchApi.getSwitchByFilters(payload, 1000, page * 1000),
      { request: "Получение ВклВыкл" }
    );
    if (response) {
      const preparedVectors = response?.data?.map((el) => ({
        ...el,
        vector: el.vector?.split("-"),
      }));
      dispatch(pushSwitchVectors(preparedVectors));
      setCheckedArr([]);
      setIsGood(true);
      if (totalPages === 0) {
        setTotalPages(response.total);
      }
    }
    if (error) {
      setIsGood(false);
    }
    if (status === 204) {
      setIsGood(true);
      setCheckedArr([]);
    }
  };

  const fetchGlobalCurrencies = async () => {
    const { response } = await fetchRequest(switchApi.getGlobalDirections(), {
      request: `Глобальные данные`,
    });
    if (response) {
      dispatch(setGlobalCurrencies(response.currencies));
    }
  };

  const fetchSwitch = async (payload) => {
    dispatch(setSwitchVectors([]));
    setIsLoading(true);
    if (!filter?.sites?.length) {
      setFilter({ ...filter, sites: sitesOptions });
      dispatch(
        setSelectedSite({
          site_id: sitesOptions[sitesOptions.length - 1]?.value,
          site_name: sitesOptions[sitesOptions.length - 1]?.label,
        })
      );
    }
    setLastResponse(payload);
    await getSwitch(payload, 0);
    setIsLoading(false);
  };

  const turnSwitch = async () => {
    const payload = checkedArr
      ?.filter((el) => allFilter(el))
      ?.map((el) => ({
        ...el,
        vector: el.vector?.join("-"),
        vector_is_enabled: switchState,
        best_is_enabled: bestState,
      }));
    const { response } = await fetchRequest(switchApi.turnSwitchSite(payload), {
      request: switchState ? "Включение" : "Выключение",
    });
    if (response) {
      setCheckedArr([]);
      const preparedVectors = response?.map((el) => ({ ...el, vector: el.vector?.split("-") }));
      dispatch(updateSwitchVectors(preparedVectors));
      dispatch(
        setNotific({
          type: "success",
          message: "успешно",
          request: switchState ? "Включение" : "Выключение",
        })
      );
    }
    setPopup(false);
  };

  useEffect(() => {
    isFull ? setCheckedArr(data?.filter((el) => allFilter(el))) : setCheckedArr([]);
  }, [isFull]);

  useEffect(() => {
    setIsFull(false);
  }, [filter]);

  useEffect(() => {
    (async () => {
      if (totalPages > 1) {
        setIsLoading(true);
        const payload = {
          sites: lastResponse?.sites || [],
          currencies_from: lastResponse?.from || [],
          currencies_to: lastResponse?.to || [],
          cities: lastResponse?.city || [],
        };
        for (let i = 1; i < totalPages; i++) {
          setCurrentPage(i);
          await getSwitch(payload, i);
        }
        setIsLoading(false);
        setCurrentPage(1);
      }
      setTotalPages(0);
    })();
  }, [totalPages]);

  useEffect(() => {
    fetchGlobalCurrencies();
    let emptyFilter = { ...filter };
    for (const key in filter) {
      if (!!searchParams?.get(key)) {
        emptyFilter = {
          ...emptyFilter,
          [key]:
            key !== "enabled" && key !== "best"
              ? JSON.parse(searchParams?.get(key))
              : key !== "best"
              ? searchParams?.get(key)
              : searchParams?.get(key) === "true",
        };
      }
      if (!searchParams?.get(key)) {
        switch (key) {
          case "sites": {
            if (!!selectedSite) {
              emptyFilter = {
                ...emptyFilter,
                sites: [{ label: selectedSite?.site_name, value: selectedSite?.site_id }],
              };
            }
            break;
          }
          case "from": {
            if (!!curFrom) {
              emptyFilter = { ...emptyFilter, from: [{ label: curFrom, value: curFrom }] };
            }
            break;
          }
          case "to": {
            if (!!curTo) {
              emptyFilter = { ...emptyFilter, to: [{ label: curTo, value: curTo }] };
            }
            break;
          }
          case "city": {
            if (!!selectedCity && !!cityOptions?.length) {
              const cityCode = cityOptions?.find(
                (el) => el.label === selectedCity?.city_name
              )?.value;
              if (!!cityCode) {
                emptyFilter = {
                  ...emptyFilter,
                  city: [{ label: selectedCity?.city_name, value: cityCode }],
                };
              }
            }
            break;
          }
        }
      }
    }

    setFilter({
      ...emptyFilter,
      from: emptyFilter.from.map((item) => ({
        ...item,
        label: item.label?.replace?.("20", "") || item.label,
        value: item.value?.replace?.("20", "") || item.value,
      })),
      to: emptyFilter.to.map((item) => ({
        ...item,
        label: item.label?.replace?.("20", "") || item.label,
        value: item.value?.replace?.("20", "") || item.value,
      })),
    });
  }, []);

  useEffect(() => {
    for (const key in filter) {
      if (!!filter[key] && key !== "enabled" && key !== "best") {
        if (!!filter[key]?.length) {
          searchParams.set(
            key,
            JSON.stringify(
              filter[key]?.map((item) =>
                item.value === "USDTERC" || item.value === "USDTTRC"
                  ? { ...item, label: item.label + "20", value: item.value + "20" }
                  : item
              )
            )
          );
        }
        if (!filter[key]?.length) {
          searchParams.delete(key);
        }
      }
      if (key === "enabled") {
        if (!!filter?.enabled) {
          searchParams.set(key, filter?.enabled);
        } else {
          searchParams.delete(key);
        }
      }
      if (key === "best") {
        if (filter?.best) {
          searchParams.set(key, "true");
        } else {
          searchParams.delete(key);
        }
      }
    }
    setSearchParams(searchParams);
  }, [filter]);

  useEffect(() => {
    if (filter.best && !!sites?.length) {
      if (!!filter.sites?.length) {
        const bestSites = filter.sites?.filter(
          (el) => sites.find((el2) => el2?.best_id === el.value)?.is_on_best
        );
        setFilter({
          ...filter,
          sites: bestSites,
        });
        dispatch(
          setSelectedSite({
            site_id: bestSites[bestSites.length - 1]?.value,
            site_name: bestSites[bestSites.length - 1]?.label,
          })
        );
      } else {
        setFilter({
          ...filter,
          sites: sitesOptions,
        });
        dispatch(
          setSelectedSite({
            site_id: sitesOptions[sitesOptions.length - 1]?.value,
            site_name: sitesOptions[sitesOptions.length - 1]?.label,
          })
        );
      }
    }
  }, [filter.best]);

  return (
    <div className="relative flex flex-col items-start justify-center mx-[20px] pb-[100px] pl-[1px] pr-[50px] text-xs">
      <div className="flex gap-8 self-end flex-wrap mb-8">
        <ColorTip color="#40AEF055" title="Включен на бесте" />
        <ColorTip color="#3BB54A33" title="Включен только на сайте" />
        <ColorTip color="#D7443E33" title="Выключен" />
      </div>
      <div className="flex flex-col md:flex-row gap-[20px] items-start w-full">
        <div className="flex flex-col gap-[10px] font-light">
          <div className="w-[280px] h-full flex flex-col gap-4 relative mx-auto sm:mx-0 text-left">
            <button
              type="button"
              className={`flex w-full rounded-[4px] px-[16px] py-[2px] bg-stale justify-center hover:opacity-80 border border-lightGray ${
                filter.best ? "bg-[#C6FBDE]" : "bg-stale text-lightFont"
              }`}
              onClick={() => setFilter({ ...filter, best: !filter.best })}>
              {filter.best ? "сайты на бесте" : "показать только сайты на бесте"}
            </button>
            <ReactSelect
              placeholder="сайты..."
              options={sitesOptions}
              value={filter.sites}
              filterOption={createFilter(selectFilter)}
              onChange={(e: TSelect[]) => {
                setFilter({ ...filter, sites: e });
                dispatch(
                  setSelectedSite({
                    site_id: e[e.length - 1]?.value,
                    site_name: e[e.length - 1]?.label,
                  })
                );
              }}
              isMulti
            />
            <ReactSelect
              placeholder="from..."
              options={currenciesOptions}
              value={filter.from}
              filterOption={createFilter(selectFilter)}
              onChange={(e: TStrSelect[]) => {
                setFilter({ ...filter, from: e });
                !!e.length && dispatch(setSelectedFrom(e[e.length - 1]?.label));
              }}
              isMulti
            />
            <div className="grid grid-cols-[1fr_38px] gap-4 w-full">
              <ReactSelect
                placeholder="to..."
                options={currenciesOptions}
                value={filter.to}
                filterOption={createFilter(selectFilter)}
                className="w-full"
                onChange={(e: TStrSelect[]) => {
                  setFilter({ ...filter, to: e });
                  !!e.length && dispatch(setSelectedTo(e[e.length - 1]?.label));
                }}
                isMulti
              />
              <button
                type="button"
                className="flex justify-center items-center w-[38px] min-w-[38px] min-h-[38px] border border-lightGray rounded-[4px] bg-stale hover:opacity-80"
                onClick={reverse}>
                <img src={change} alt="Реверс" width={20} height={20} />
              </button>
            </div>
            <ReactSelect
              placeholder="города..."
              options={cityOptions}
              value={filter.city}
              filterOption={createFilter(selectFilter)}
              onChange={(e: TStrSelect[]) => {
                setFilter({ ...filter, city: e });
                !!e.length &&
                  dispatch(
                    setSelectedCity({
                      city_id: e[e.length - 1]?.value,
                      city_name: e[e.length - 1]?.label,
                    })
                  );
              }}
              isMulti
            />
            <div className="flex flex-col">
              <button
                type="button"
                className={`flex items-center gap-8 w-fit pl-4 pr-8 py-2 rounded-sm ${
                  filter.enabled === null ? "bg-[#C6FBDE] hover:opacity-70" : "hover:bg-lightStale"
                }`}
                onClick={() => setFilter({ ...filter, enabled: null })}>
                <span className="flex gap-4">
                  <span className="h-[16px] w-[16px] min-w-[16px] border border-lightGray rounded-sm bg-[#40AEF055]" />
                  <span className="h-[16px] w-[16px] min-w-[16px] border border-lightGray rounded-sm bg-[#3BB54A33]" />
                  <span className="h-[16px] w-[16px] min-w-[16px] border border-lightGray rounded-sm bg-[#D7443E33]" />
                </span>
                Все векторы
              </button>
              <button
                type="button"
                className={`flex items-center gap-8 w-fit pl-4 pr-8 py-2 rounded-sm ${
                  filter.enabled === "вкл" ? "bg-[#C6FBDE] hover:opacity-70" : "hover:bg-lightStale"
                }`}
                onClick={() => setFilter({ ...filter, enabled: "вкл" })}>
                <span className="flex gap-4">
                  <span className="h-[16px] w-[16px] min-w-[16px] border border-lightGray rounded-sm bg-[#40AEF055]" />
                  <span className="h-[16px] w-[16px] min-w-[16px] border border-lightGray rounded-sm bg-[#3BB54A33]" />
                </span>
                Включенные
              </button>
              <button
                type="button"
                className={`flex items-center gap-8 w-fit pl-4 pr-8 py-2 rounded-sm ${
                  filter.enabled === "бест"
                    ? "bg-[#C6FBDE] hover:opacity-70"
                    : "hover:bg-lightStale"
                }`}
                onClick={() => setFilter({ ...filter, enabled: "бест" })}>
                <span className="h-[16px] w-[16px] min-w-[16px] border border-lightGray rounded-sm bg-[#40AEF055]" />
                Включенные на бесте
              </button>
              <button
                type="button"
                className={`flex items-center gap-8 w-fit pl-4 pr-8 py-2 rounded-sm ${
                  filter.enabled === "сайт"
                    ? "bg-[#C6FBDE] hover:opacity-70"
                    : "hover:bg-lightStale"
                }`}
                onClick={() => setFilter({ ...filter, enabled: "сайт" })}>
                <span className="h-[16px] w-[16px] min-w-[16px] border border-lightGray rounded-sm bg-[#3BB54A33]" />
                Включенные только на сайте
              </button>
              <button
                type="button"
                className={`flex items-center gap-8 w-fit pl-4 pr-8 py-2 rounded-sm ${
                  filter.enabled === "выкл" ? "bg-[#C6FBDE]" : "hover:bg-lightStale"
                }`}
                onClick={() => setFilter({ ...filter, enabled: "выкл" })}>
                <span className="h-[16px] w-[16px] min-w-[16px] border border-lightGray rounded-sm bg-[#D7443E33]" />
                Отключенные
              </button>
            </div>
            <button
              className={`prime-button-sm self-start rounded-[4px]`}
              onClick={() =>
                fetchSwitch({
                  sites: filter.sites?.map((el) => el.value),
                  currencies_from: filter.from?.map((el) => el.value),
                  currencies_to: filter.to?.map((el) => el.value),
                  cities: filter.city?.map((el) => el.value),
                })
              }
              disabled={isLoading}>
              Запросить данные
            </button>
            {!!lastResponse && (
              <div className="flex flex-col gap-8 my-[20px] font-semibold text-lightFont">
                <div>Последний запрос:</div>
                {!!lastResponse?.sites?.length && (
                  <div>
                    <div>сайты:</div>
                    <div className="font-light">
                      {lastResponse?.sites?.map((el: number) => {
                        const site = sitesOptions?.find((site) => site.value === el);
                        return <div key={el}>{site?.label || ""}</div>;
                      })}
                    </div>
                  </div>
                )}
                {!!lastResponse?.from?.length && (
                  <div>
                    <div>from:</div>
                    <div className="font-light">
                      {lastResponse?.from?.map((el: string) => (
                        <div key={el}>{el}</div>
                      ))}
                    </div>
                  </div>
                )}
                {!!lastResponse?.to?.length && (
                  <div>
                    <div>to:</div>
                    <div className="font-light">
                      {lastResponse?.to?.map((el: string) => (
                        <div key={el}>{el}</div>
                      ))}
                    </div>
                  </div>
                )}
                {!!lastResponse?.city?.length && (
                  <div>
                    <div>города:</div>
                    <div className="font-light">
                      {lastResponse?.city?.map((el: string) => (
                        <div key={el}>{el}</div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="text-left w-full">
          <div className="px-8 -mt-[24px] mb-4">
            <PaginationLoader
              currentPage={currentPage + 1}
              totalPages={totalPages}
              isComplete={totalPages === 0}
            />
          </div>
          {isAccess && !!data.length && (
            <button
              className={`mt-8 hover:opacity-60 w-fit px-8 mb-8`}
              onClick={() => setIsFull(!isFull)}>
              {!isFull ? "Выбрать все ..." : "Отжать все ..."}
            </button>
          )}
          {isAccess && (
            <div
              className={`flex gap-4 text-[#383C44] duration-300 overflow-hidden ${
                !!checkedArr?.length ? "opacity-100 max-h-[40px] mb-[14px]" : "opacity-0 max-h-0"
              }`}>
              <button
                className="mt-8 bg-[#40AEF055] rounded-sm hover:opacity-60 w-fit px-8 py-2"
                onClick={() => {
                  setSwitchState(true);
                  setBestState(true);
                  setPopup(true);
                }}>
                Включить на бесте
              </button>
              <button
                className="mt-8 bg-[#3BB54A55] rounded-sm hover:opacity-60 w-fit px-8 py-2"
                onClick={() => {
                  setSwitchState(true);
                  setBestState(false);
                  setPopup(true);
                }}>
                Включить только на сайте
              </button>
              <button
                className="mt-8 bg-[#D7443E33] rounded-sm hover:opacity-60 w-fit px-8 py-2"
                onClick={() => {
                  setSwitchState(false);
                  setBestState(false);
                  setPopup(true);
                }}>
                Отключить
              </button>
            </div>
          )}
          <div className="w-full flex flex-wrap gap-[12px]">
            {!!data?.length &&
              filter.sites?.map((site) => {
                const siteData = data?.filter((el) => el.site_id === site.value);
                if (
                  !!siteData?.length &&
                  (!filter.best || sites.find((el2) => el2?.site_id === site.value)?.is_on_best)
                ) {
                  return (
                    <OnOffSite
                      key={site.value}
                      siteId={site.value}
                      items={siteData}
                      data={data}
                      filter={filter}
                      setFilter={setFilter}
                      sites={sites}
                      allFilter={allFilter}
                      isAccess={isAccess}
                      add={add}
                      isFull={isFull}
                      fetchSwitch={fetchSwitch}
                    />
                  );
                }
              })}
          </div>
          {isLoading && (
            <div className="w-full md:w-[70%] flex justify-center text-center relative top-[60px]">
              <Spinner />
            </div>
          )}
          {!isLoading && isGood !== null && !data?.length && (
            <div className="w-full md:w-[50%] mx-auto md:mx-[100px] relative top-[60px]">
              {isGood ? (
                <GoodEmptiness message="Нет данных по выбранным параметрам" />
              ) : !isGood ? (
                <Emptiness message={"Ошибка сервиса"} />
              ) : null}
            </div>
          )}
        </div>
        {isPopup && (
          <Popup closeModal={() => setPopup(false)}>
            <div className="text-xs w-[240px] flex flex-col items-center">
              <div className="w-full text-sm">
                Уверены что хотите{" "}
                {bestState ? "включить на бесте" : switchState ? "включить на сайте" : "отключить"}{" "}
                выбранные векторы?
              </div>
              <div className="my-[10px] max-h-[70vh] w-full overflow-y-scroll flex flex-col gap-4">
                {checkedArr
                  ?.filter((el) => allFilter(el))
                  ?.map((el2) => {
                    const siteName = sites?.find((el3) => el3.site_id === el2.site_id)?.site_name;
                    return (
                      <div key={`${el2.site_id}-${el2.vector?.join("-")}-${el2.city_code}`}>
                        {siteName}-{el2.vector.join("-")}-{el2.city_code}
                      </div>
                    );
                  })}
              </div>
              <button className="prime-button-sm bg-font text-white" onClick={turnSwitch}>
                {bestState
                  ? "Включить на бесте"
                  : switchState
                  ? "Включить только на сайте"
                  : "Отключить"}
              </button>
            </div>
          </Popup>
        )}
      </div>
    </div>
  );
};
