export const TT = {
  AUTOCORR: "autocorr",
  CLUSTER: "cluster",
  SCHEDULE: "schedule",
  BLACKLIST: "blacklist",
};

export const tabs = [
  {
    page: TT.AUTOCORR,
    title: "Автокор",
    read_access: "direction_get",
    update_access: "direction_post",
    color: "#4B7F62",
    text: "#FFF",
  },
  {
    page: TT.CLUSTER,
    title: "Кластеры",
    read_access: "cluster_get",
    update_access: "cluster_post",
    color: "#AFADA5",
    text: "#282C34",
  },
  {
    page: TT.SCHEDULE,
    title: "Расписание кластеров",
    read_access: "cluster_get",
    update_access: "cluster_post",
    color: "#9999FF",
    text: "#FFF",
  },
  {
    page: TT.BLACKLIST,
    title: "BLACKLIST",
    read_access: "on-off_get",
    update_access: "on-off_post",
    color: "#282C34",
    text: "#FFF",
  },
];

export type TTetrisTab = {
  page: string;
  title: string;
  read_access: string;
  update_access: string;
  color: string;
  text: string;
};
