import { PostScheduleBody } from "src/api/schedule";
import { getUpdatedSegmentsForServer } from "src/helpers/onOffTimeline/getUpdatedSegmentsForServer";
import { ICity, OnOffSignal, OnOffSignalToDelete } from "src/types/OnOffTimeline/common";
import { ESource } from "src/store/onOffReducer";

/**
 собирает все неподтвержденные изменения
 и конвертирует их для отправки на сервер
 */
export const getTetrisPostData = (data: ICity[]): PostScheduleBody => {
  const to_change: OnOffSignal[] = [];
  const to_delete: OnOffSignalToDelete[] = [];

  data.forEach((city) => {
    city.currency_pairs.forEach((pair) => {
      pair.sites.forEach((site) => {
        const { to_change: to_change_site, to_delete: to_delete_site } =
          getUpdatedSegmentsForServer({
            city_code: city.city_code,
            currency_from_name: pair.currency_from.currency_name,
            currency_to_name: pair.currency_to.currency_name,
            platform_name: ESource.SITE,
            segments: site.segments_site,
            unsubSegments: site.unsubmitted_segments_site || [],
            site_name: site.site_name,
          });

        const { to_change: to_change_best, to_delete: to_delete_best } =
          getUpdatedSegmentsForServer({
            city_code: city.city_code,
            currency_from_name: pair.currency_from.currency_name,
            currency_to_name: pair.currency_to.currency_name,
            platform_name: ESource.BEST,
            segments: site.segments_best,
            unsubSegments: site.unsubmitted_segments_best || [],
            site_name: site.site_name,
          });

        to_change.push(...to_change_site, ...to_change_best);
        to_delete.push(...to_delete_site, ...to_delete_best);
      });
    });
  });

  return {
    to_change,
    to_delete,
  };
};
