import { Dispatch, SetStateAction, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactSelect, { createFilter } from "react-select";
import { convertToOptions, selectFilter } from "src/helpers/formHelpers";
import {
  selectGlobalCurrencies,
  selectGlobalDirections,
  TStrSelect,
} from "src/store/directionsReducer";
import { TCitySelect, TDirectionSelect, TTextDirectionGet, TVectorType } from "../../types";
import { OptionLabel } from "src/components/FormElements/CustomOption";

type TProps = {
  title: string;
  citiesOptions: Array<TCitySelect>;
  city: TCitySelect | null;
  setCity: Dispatch<SetStateAction<TCitySelect | null>>;
  lang: TStrSelect | null;
  setLang: Dispatch<SetStateAction<TStrSelect | null>>;
  selectedVectors: Array<TDirectionSelect>;
  setSelectedVectors: Dispatch<SetStateAction<Array<TDirectionSelect>>>;
  isVisible: boolean;
  setIsVisible: () => void;
  directionsData: TTextDirectionGet | null;
  currencyType: TVectorType;
  isGlobals: boolean;
  languages: Array<string>;
  existLanguages: Array<string>;
  isNotNeededVectors: boolean;
};

export const TextFilters = ({
  title,
  citiesOptions,
  city,
  setCity,
  lang,
  setLang,
  selectedVectors,
  setSelectedVectors,
  isVisible,
  setIsVisible,
  directionsData,
  currencyType,
  isGlobals,
  languages,
  existLanguages,
  isNotNeededVectors,
}: TProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const globalCurrencies = useSelector(selectGlobalCurrencies);
  const globalDirections = useSelector(selectGlobalDirections);
  const isCashDirection =
    currencyType?.currency_from_type === "cash" || currencyType?.currency_to_type === "cash";

  const directionsOptions = useMemo(
    () =>
      (!!directionsData &&
        directionsData?.cities
          ?.find((el) => city?.value === el?.city_id)
          ?.vectors?.filter(
            (el) =>
              currencyType?.currency_from_type === el?.currency_from_type &&
              currencyType?.currency_to_type === el?.currency_to_type
          )
          ?.map((el) => {
            const vectorsArrays = el?.languages?.map((langEl) =>
              langEl?.types?.map((typeEl) => typeEl?.type_id)
            );

            const vectorTextTypes = !!vectorsArrays?.length
              ? vectorsArrays?.reduce((a, b) => a.concat(b))
              : [];

            const isFullText =
              vectorTextTypes?.includes(1) &&
              vectorTextTypes?.includes(2) &&
              vectorTextTypes?.includes(3);

            return {
              label: OptionLabel({
                label: `${el.currency_from_name}-to-${el.currency_to_name}`,
                isFirstError: !el?.is_on_site,
                textFirstError: "Не включен на сайте",
                firstColor: "inherit",
                isSecondError: !isFullText,
                textSecondError: "Есть незаполненные тексты (язык / тип)",
                secondColor: "#D7443E",
              }),
              title: `${el.currency_from_name}-to-${el.currency_to_name}`,
              value: {
                title: `${city?.title}-${el.currency_from_name}-to-${el.currency_to_name}_${lang?.label}`,
                lang: lang?.label || "RU",
                city_id: city?.value || 0,
                currency_from_id: el.currency_from_id,
                currency_from_name: el.currency_from_name,
                currency_from_type: el?.currency_from_type,
                currency_to_id: el.currency_to_id,
                currency_to_name: el.currency_to_name,
                currency_to_type: el?.currency_to_type,
              },
            };
          })) ||
      ([] as Array<TDirectionSelect>),
    [directionsData, currencyType, city?.title, lang?.label]
  );

  const globalDirectionsOptions = useMemo(
    () =>
      globalDirections
        ?.map((el) => {
          const resVector = directionsOptions?.find(
            (vectorEl: TDirectionSelect) =>
              vectorEl.title === `${el.currency_from}-to-${el.currency_to}`
          );
          const from = globalCurrencies?.find((cur) => cur.currency_name === el.currency_from);
          const to = globalCurrencies?.find((cur) => cur.currency_name === el.currency_to);

          return !!resVector
            ? resVector
            : {
                label: OptionLabel({
                  label: `${el.currency_from}-to-${el.currency_to}`,
                  isFirstError: true,
                  textFirstError: "Не включен на сайте",
                  firstColor: "inherit",
                  isSecondError: true,
                  textSecondError: "Нет текстов",
                  secondColor: "#D7443E",
                }),
                title: `${el.currency_from}-to-${el.currency_to}`,
                value: {
                  title: `${city?.title}-${el.currency_from}-to-${el.currency_to}`,
                  lang: lang?.label,
                  city_id: city?.value,
                  currency_from_id: from?.currency_id,
                  currency_from_name: el.currency_from,
                  currency_from_type: from?.type,
                  currency_to_id: to?.currency_id,
                  currency_to_name: el.currency_to,
                  currency_to_type: to?.type,
                },
              };
        })
        ?.filter(
          (el) =>
            currencyType?.currency_from_type === el?.value?.currency_from_type &&
            currencyType?.currency_to_type === el?.value?.currency_to_type
        ),
    [
      directionsOptions,
      globalDirections,
      globalCurrencies,
      currencyType?.currency_from_type,
      currencyType?.currency_to_type,
      city?.title,
      lang?.label,
    ]
  );

  const langOptions = useMemo(() => convertToOptions(languages), [languages]);

  useEffect(() => {
    if (!isCashDirection) {
      const autoCity = {
        label: OptionLabel({
          label: "Автообмены",
          isFirstError: false,
          textFirstError: "Нет включенных векторов на сайте",
          firstColor: "inherit",
          isSecondError: false,
          textSecondError: "Есть векторы с незаполненными текстами",
          secondColor: "#D7443E",
        }),
        title: "Автообмены",
        value: 0,
        isNotOnSite: false,
        isNoTexts: false,
      };
      setCity(autoCity);
    } else if (!!searchParams?.get("city")) {
      setCity(citiesOptions?.find((el) => el.value === +searchParams.get("city")));
    } else {
      setCity(null);
    }
  }, [isCashDirection]);

  return (
    <div
      className={`relative md:max-w-[300px] text-left rounded-sm -my-8 p-8 border border-[#BABAC322] duration-300 ${
        !isVisible ? "opacity-60 bg-stale" : "bg-select"
      }`}>
      <div
        className={`duration-300 ${
          !isNotNeededVectors || (!!directionsData?.cities?.length && !isGlobals)
            ? "-z-10 opacity-0"
            : ""
        } w-full h-full bg-stale rounded-sm`}
      />
      <div
        className={`duration-300 ${
          !isNotNeededVectors && !directionsData?.cities?.length && !isGlobals
            ? "-z-10 opacity-0"
            : ""
        } flex flex-col gap-8`}>
        <button
          type="button"
          className={`text-left font-semibold text-lightFont`}
          onClick={setIsVisible}>
          {title}
        </button>
        <ReactSelect
          classNamePrefix="SquareSelect"
          options={langOptions}
          styles={{
            option: (baseStyles, state) => ({
              ...baseStyles,
              color: "#282c34",
              backgroundColor: existLanguages?.includes(state.label)
                ? state?.isFocused
                  ? "#BABAC311"
                  : "inherit"
                : state?.isFocused
                ? "#BABAC3AA"
                : "#BABAC366",
            }),
          }}
          placeholder="Язык..."
          filterOption={createFilter(selectFilter)}
          value={lang}
          isDisabled={!isVisible}
          onChange={(e: TStrSelect) => {
            setLang(e);
            searchParams.set("lang", e?.value);
            setSearchParams(searchParams);
          }}
        />
        {!isNotNeededVectors && (
          <ReactSelect
            classNamePrefix="SquareSelect"
            options={citiesOptions}
            filterOption={createFilter(selectFilter)}
            styles={{
              singleValue: (base) => ({
                ...base,
                paddingTop: "1px",
                maxHeight: "20px",
                overflow: "hidden",
              }),
              option: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  color: "#282c34",
                  backgroundColor: !state?.data?.isNotOnSite
                    ? state?.isFocused
                      ? "#BABAC311"
                      : "inherit"
                    : state?.isFocused
                    ? "#BABAC3AA"
                    : "#BABAC366",
                };
              },
            }}
            placeholder="Город..."
            value={city}
            isDisabled={!isVisible || !isCashDirection}
            onChange={(e: TCitySelect) => {
              setCity(e);
              searchParams.set("city", e?.value?.toString());
              setSearchParams(searchParams);
            }}
          />
        )}
        {!!selectedVectors && !isNotNeededVectors && (
          <>
            <div className="w-full flex gap-4">
              {}
              <ReactSelect
                classNamePrefix="SquareSelect"
                options={isGlobals ? globalDirectionsOptions : directionsOptions}
                className="w-full"
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    paddingTop: "1px",
                    maxHeight: "20px",
                    overflow: "hidden",
                  }),
                  option: (baseStyles, state) => {
                    return {
                      ...baseStyles,
                      color: "#282c34",
                      backgroundColor: state?.isFocused ? "#BABAC311" : "inherit",
                    };
                  },
                }}
                placeholder="Векторы..."
                filterOption={createFilter(selectFilter)}
                value={selectedVectors[selectedVectors?.length - 1] || ""}
                isDisabled={
                  !isVisible ||
                  !lang?.label ||
                  !city?.label ||
                  (!isGlobals && !directionsOptions?.length) ||
                  (isGlobals && !globalDirectionsOptions?.length)
                }
                onChange={(e: TDirectionSelect) => {
                  if (!selectedVectors?.find((el) => el.value.title === e.value.title)) {
                    setSelectedVectors([...selectedVectors, e]);
                  }
                }}
              />
              <button
                type="button"
                className="flex justify-center items-center min-h-[20px] border border-lightGray text-lightFont rounded-sm bg-stale px-8 hover:opacity-80"
                onClick={() => {
                  const group = isGlobals
                    ? globalDirectionsOptions
                    : (directionsOptions as TDirectionSelect[]);
                  const added = group?.filter(
                    (el) =>
                      !selectedVectors?.find((vectorEl) => vectorEl.value.title === el.value.title)
                  ) as TDirectionSelect[];
                  setSelectedVectors([...selectedVectors, ...added]);
                }}>
                Все
              </button>
            </div>
            {!!selectedVectors?.length && (
              <div className="flex justify-between gap-8 bg-[#282c3411] rounded-sm -mx-2 p-2">
                <div>
                  <span className="px-4">{`\u00D7`}</span> сбросить
                </div>
                <div className="flex gap-4">
                  <button
                    type="button"
                    className={`bg-light px-[16px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300`}
                    onClick={() => {
                      setSelectedVectors(
                        selectedVectors?.filter(
                          (el) =>
                            !(el.value.city_id === city?.value && el.value.lang === lang?.label)
                        )
                      );
                    }}>
                    {city?.title || ""}_{lang?.label || ""}
                  </button>
                  <button
                    type="button"
                    className={`bg-light px-[16px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300`}
                    onClick={() => {
                      setSelectedVectors([]);
                    }}>
                    Все
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        {!!city?.title?.length &&
          (isGlobals ? !globalDirectionsOptions?.length : !directionsOptions?.length) && (
            <small className="error text-center">Нет векторов по городу / типу валют</small>
          )}
        {!!selectedVectors && !!setSelectedVectors && !isNotNeededVectors && (
          <div className="flex gap-4 items-center flex-wrap">
            {selectedVectors?.map((el, index) => (
              <div
                key={index}
                className="flex gap-4 items-center whitespace-pre rounded-sm bg-[#FCC815] text-[12px] text-[#FFF] pl-4 pr-8 font-medium h-[18px]">
                {el.value.title}
                <button
                  type="button"
                  className="relative left-2 rotate-45"
                  onClick={() => {
                    setSelectedVectors(
                      selectedVectors?.filter((vector) => vector?.value.title !== el?.value.title)
                    );
                  }}>
                  +
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
