import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Url } from "src/api/constants";
import {
  selectIsOldStats,
  selectLSRatios,
  selectLSTops,
  selectStatsData,
} from "src/store/statsReducer";
import { IStat, IVector, TRatio } from "../models";
import { PairRow } from "./PairRow";
import { TopRatios } from "./TopRatios";
import location from "src/assets/images/location.svg";
import { TopButtons } from "./TopButtons";

type TProps = {
  itemOld: IStat;
  //  boosts: TBoost[];
  // fetchBoost: () => void;
  isConnect: boolean | "";
  cityCode: string;
  ratios: TRatio[];
};

export const CityRow = ({ itemOld, isConnect, cityCode, ratios }: TProps) => {
  const isOldStats = useSelector(selectIsOldStats);
  const statsData = useSelector(selectStatsData);
  const LSRatios = useSelector(selectLSRatios);
  const LSTops = useSelector(selectLSTops);
  const [top, setTop] = useState<1 | 2>(1);
  const [ratio, setRatio] = useState<TRatio | null>(null);

  const data = useMemo(
    () =>
      statsData?.stats?.find(
        (el) =>
          el.range_percent === ratio?.range_percent && el.target_percent === ratio?.target_percent
      )?.data || [],
    [statsData?.last_upd, ratio?.ratio_id]
  );

  const item = useMemo(() => {
    if (isOldStats) {
      return itemOld;
    } else {
      const currentData = data?.find((el) => el.city_name === itemOld?.city_name);
      if (!!currentData) {
        return currentData;
      } else {
        return {
          ...itemOld,
          data: [],
        };
      }
    }
  }, [itemOld, isOldStats, data]);

  const isAttention =
    top === 1 || !isOldStats
      ? +item?.mean_percent_vector_today < 85 && +item?.mean_percent_vector_today >= 80
      : +item?.mean_percent_vector_today_two < 85 && +item?.mean_percent_vector_today_two >= 80;
  const isAlert =
    top === 1 || !isOldStats
      ? +item?.mean_percent_vector_today < 80
      : +item?.mean_percent_vector_today_two < 80;
  const isAttention30 =
    top === 1 || !isOldStats
      ? +item?.mean_percent_30 < 85 && +item?.mean_percent_30 >= 80
      : +item?.mean_percent_30_two < 85 && +item?.mean_percent_30_two >= 80;
  const isAlert30 =
    top === 1 || !isOldStats ? +item?.mean_percent_30 < 80 : +item?.mean_percent_30_two < 80;

  const chunk = (arr: IVector[], size: number) => {
    const result = [];

    for (let i = 0; i < Math.ceil(arr?.length / size); i++) {
      result.push(arr.slice(i * size, i * size + size));
    }

    return result;
  };

  useEffect(() => {
    if (!!ratios?.length && !!LSRatios?.length && !!item?.city_id) {
      const currentCity = LSRatios?.find((el) => el?.city_id === item?.city_id);
      const currentRatio = ratios?.find((el) => el?.ratio_id === currentCity?.ratio_id);

      if (!!currentRatio) {
        setRatio(currentRatio);
      } else {
        setRatio(ratios[0]);
      }
    } else {
      setRatio(ratios[0]);
    }
  }, [LSRatios, ratios, item?.city_name]);

  useEffect(() => {
    if (!!LSTops?.length && !!item?.city_id) {
      const currentCity = LSTops?.find((el) => el?.city_id === item?.city_id);

      if (!!currentCity) {
        setTop(currentCity.top);
      }
    }
  }, [LSTops, item?.city_id]);

  return (
    <div className="lg:grid lg:grid-cols-[200px_1fr_4fr] mb-[8px] mx-auto">
      <div className="text-left pr-[10px] text-xs pb-[20px] lg:pb-0">
        {!!item?.turned_only_site?.length && (
          <div className="flex flex-col gap-4 rounded-md lg:p-[12px] lg:shadow-base h-full">
            <div className="text-lightFont">Направления не на бесте, но включены на сайтах:</div>
            <NavLink
              to={`${Url.ONOFF}?city=${JSON.stringify([
                { label: item?.city_name, value: cityCode },
              ])}&enabled=сайт&best=true`}
              className={`bg-stale flex items-center gap-8 shadow-base w-fit py-2 px-8 rounded-sm hover:opacity-70 my-8 text-[10px]`}>
              <span className="text-sm">{`\u2196`}</span>
              во Вкл/Выкл
            </NavLink>
            <div className="flex flex-col gap-4 overflow-auto h-[150px] visibleScrollWide resize-y visibleResize">
              {item?.turned_only_site?.map((el, index) => (
                <a
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={el.best_link}
                  className="hover:opacity-70">
                  {el.vector}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
      <div
        className="bg-stale rounded-t-md flex justify-between items-center p-[10px] lg:hidden font-bold text-right"
        style={{
          backgroundColor:
            isAttention || isAttention30 ? "#989cA0" : isAlert || isAlert30 ? "#282c34" : "white",
          color: isAlert30 || isAttention30 || isAlert || isAttention ? "white" : "#282c34",
        }}>
        <div className="text-left">
          {item?.city_name?.toUpperCase()}
          {isOldStats ? (
            <TopButtons top={top} cityId={item?.city_id} />
          ) : (
            <TopRatios cityId={item?.city_id} ratio={ratio} ratios={ratios} />
          )}
        </div>
        <div
          className="code leading-5 rounded-md m-[-5px] p-[5px]"
          style={{
            backgroundColor: isAttention30 ? "#989cA0" : isAlert30 ? "#282c34" : "white",
            color: isAlert30 || isAttention30 ? "white" : "#282c34",
          }}>
          <div className="text-[18px] font-bold">
            {top === 1 || !isOldStats ? item?.mean_percent_30 : item?.mean_percent_30_two}
          </div>
          <div className="text-xs">% 30 минут</div>
        </div>
        <div
          className="code leading-5 rounded-md m-[-5px] p-[5px]"
          style={{
            backgroundColor: isAttention ? "#989cA0" : isAlert ? "#282c34" : "white",
            color: isAlert || isAttention ? "white" : "#282c34",
          }}>
          <div className="text-[18px] font-bold">
            {top === 1 || !isOldStats
              ? item?.mean_percent_vector_today
              : item?.mean_percent_vector_today_two}
          </div>
          <div className="text-xs">% Сегодня</div>
        </div>
      </div>
      <div
        className="bg-stale rounded-l-md p-[10px] pr-[5px] w-[180px] hidden lg:block"
        style={{ minWidth: "185px" }}>
        <div className="flex font-extrabold text-sm text-left">
          <img src={location} className="w-[18px] h-[18px] relative top-[2px] mr-[5px]" alt="" />
          {item?.city_name?.toUpperCase()}
        </div>
        {isOldStats ? (
          <TopButtons top={top} cityId={item?.city_id} />
        ) : (
          <TopRatios cityId={item?.city_id} ratio={ratio} ratios={ratios} />
        )}
        <div
          style={{
            backgroundColor:
              isAttention || isAttention30 ? "#989cA0" : isAlert || isAlert30 ? "#282c34" : "white",
          }}
          className="h-[94px] overflow-hidden pt-[5px] px-[10px] mb-[5px] rounded-lg text-xs text-left relative top-[8px]">
          <div
            className="code px-[10px] leading-5 mx-[-10px] pt-[10px] mt-[-5px] rounded-t-md"
            style={{
              backgroundColor: isAttention30 ? "#989cA0" : isAlert30 ? "#282c34" : "white",
              color: isAlert30 || isAttention30 ? "white" : "#282c34",
            }}>
            <div className="text-[18px] font-bold">
              {top === 1 || !isOldStats ? item?.mean_percent_30 : item?.mean_percent_30_two}
            </div>
            <div>% 30 минут</div>
          </div>
          <div
            className="code px-[10px] leading-5 mx-[-10px] pb-[10px] pt-[3px] mb-[10px] rounded-b-md"
            style={{
              backgroundColor: isAttention ? "#989cA0" : isAlert ? "#282c34" : "white",
              color: isAlert || isAttention ? "white" : "#282c34",
            }}>
            <div className="text-[18px] font-bold">
              {top === 1 || !isOldStats
                ? item?.mean_percent_vector_today
                : item?.mean_percent_vector_today_two}
            </div>
            <div>% Сегодня</div>
          </div>
        </div>
      </div>
      <div className="flex bg-stale rounded-t-none rounded-b-md lg:rounded-l-none lg:rounded-r-md w-full pt-[5px]">
        <div className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 w-full mr-[10px]`}>
          {chunk(item?.data, 2)?.map((el, index) => (
            <PairRow
              key={index}
              item={el}
              // boosts={boosts}
              // fetchBoost={fetchBoost}
              city={{ label: item?.city_name, value: item?.city_id }}
              isConnect={isConnect}
              top={top}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
