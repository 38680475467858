import { Popup } from "src/components/Popup/Popup";
import { AssetClient, ContentType } from "src/pages/Requests/components/Request";

type CurrentImage = {
  src: string;
  index: number;
};

interface GalleryProps {
  photos: AssetClient[];
  isPopup: boolean;
  setPopup: React.Dispatch<React.SetStateAction<boolean>>;
  currentImage: CurrentImage;
  setCurrentImage: React.Dispatch<React.SetStateAction<CurrentImage>>;
}

export const Gallery = ({
  photos,
  isPopup,
  setPopup,
  currentImage,
  setCurrentImage,
}: GalleryProps) => {
  if (!(isPopup && currentImage?.src?.length > 0)) {
    return null;
  }

  return (
    <Popup
      noPadding
      closeModal={() => {
        setPopup(false);
        setCurrentImage({ src: "", index: 0 });
      }}>
      <div className="text-[28px] max-h-[80vh]">
        {currentImage.index > 0 && (
          <button
            type="button"
            className="-rotate-90 z-[1] text-grayDark bg-white px-[4px] rounded-md absolute left-[10px] top-[50%] -translate-y-[50%]"
            disabled={currentImage.index === 0}
            onClick={() =>
              setCurrentImage({
                src: photos[currentImage.index - 1].path,
                index: currentImage.index - 1,
              })
            }>
            {`\u25B2`}
          </button>
        )}
        {photos[currentImage.index].content_type === ContentType.VIDEO ? (
          <video controls className="relative z-[0]">
            <source src={currentImage.src} type="video/mp4" />
            Ваш браузер не поддерживает видео
          </video>
        ) : (
          <img src={currentImage.src} alt="" width={700} height={700} />
        )}
        {currentImage.index < photos.length - 1 && (
          <button
            type="button"
            className="rotate-90 z-[1] text-grayDark px-[4px] bg-white rounded-md absolute right-[15px] top-[50%] -translate-y-[50%]"
            onClick={() =>
              setCurrentImage({
                src: photos[currentImage.index + 1].path,
                index: currentImage.index + 1,
              })
            }>
            {`\u25B2`}
          </button>
        )}
      </div>
    </Popup>
  );
};
