import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StateT } from "./store";
import {
  IStat,
  IStats,
  IVector,
  TRatio,
  TSavedOldTop,
  TSavedRatios,
  TStatsData,
  TStatsReport,
} from "../pages/Stats/models";
import { getRatesArr } from "src/helpers/rateHelper";

const statsSlice = createSlice({
  name: "stats",
  initialState: {
    stats: {} as IStats,
    statsData: null as TStatsData | null,
    statsReports: null as TStatsReport,
    isOldStats: false,
    ratios: [] as TRatio[],
    LSRatios: null as TSavedRatios[] | null,
    LSTops: null as TSavedOldTop[] | null,
    boosts: [] as Array<TBoost>,
    reviews: [] as Array<TReview>,
    flights: {
      last_upd: 0,
      data: [],
    } as TFlights,
    flightsFilters: {
      city: null,
      site: null,
      from: null,
      to: null,
    } as TFlightFilters,
    favorites: [] as Array<string>,
    rates: {} as TRates,
    offset: 0 as number,
  },
  reducers: {
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setStatsData: (state, action) => {
      state.statsData = action.payload;
    },
    setStatsReports: (state, action) => {
      state.statsReports = action.payload;
    },
    setIsOldStats: (state, action) => {
      state.isOldStats = action.payload;
    },
    setRatios: (state, action) => {
      state.ratios = action.payload;
    },
    setLSRatios: (state, action) => {
      state.LSRatios = action.payload;
    },
    setLSTops: (state, action) => {
      state.LSTops = action.payload;
    },
    updateStats: (state, action) => {
      if (!!state.stats.stats?.length)
        action.payload?.stats?.map((el: IStat) => {
          const cityIndex = state.stats?.stats?.findIndex(
            (item) => item.city_name === el.city_name
          );
          el.data?.map((vector: IVector) => {
            const vectorIndex = state.stats?.stats[cityIndex]?.data?.findIndex(
              (item: IVector) => item.vector === vector.vector
            );
            if (state.stats?.stats[cityIndex]?.data[vectorIndex]) {
              state.stats.stats[cityIndex].data[vectorIndex] = vector;
            }
          });
        });
    },
    setBoosts: (state, action) => {
      state.boosts = action.payload;
    },
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
    setFlights: (state, action) => {
      state.flights = action.payload;
    },
    setFullFlightsFilters: (state, action) => {
      state.flightsFilters = action.payload;
    },
    setFlightsFilters: (state, action) => {
      const attr = action.payload.attr;
      state.flightsFilters = {
        ...state.flightsFilters,
        [attr]: action.payload.value,
      };
    },
    clearFlightsFilters: (state) => {
      state.flightsFilters = {
        city: null,
        site: null,
        from: null,
        to: null,
      };
    },
    setFavorites: (state, action) => {
      state.favorites = action.payload;
    },
    setRates: (state, action: PayloadAction<RateItem>) => {
      const newState = { ...state.rates };
      const item = action.payload;
      if (!newState[item.exchanger_name]) newState[item.exchanger_name] = {};
      newState[item.exchanger_name][item.pair_name] = item;
      state.rates = newState;
    },
    initRates: (state, { payload }: PayloadAction<TRates>) => {
      state.rates = payload;
    },
    setOffset: (state, action) => {
      state.offset = action.payload;
    },
  },
});

export const {
  setStats,
  setStatsData,
  setStatsReports,
  setIsOldStats,
  setRatios,
  setLSRatios,
  setLSTops,
  setBoosts,
  setReviews,
  setFlights,
  setFullFlightsFilters,
  setFlightsFilters,
  clearFlightsFilters,
  setFavorites,
  setRates,
  initRates,
  setOffset,
  updateStats,
} = statsSlice.actions;

export const selectStats = (state: StateT) => state.stats.stats;
export const selectStatsData = (state: StateT): TStatsData | null => state.stats.statsData;
export const selectStatsReports = (state: StateT): TStatsReport | null => state.stats.statsReports;
export const selectIsOldStats = (state: StateT): boolean => state.stats.isOldStats;
export const selectRatios = (state: StateT): TRatio[] => state.stats.ratios;
export const selectLSRatios = (state: StateT): TSavedRatios[] | null => state.stats.LSRatios;
export const selectLSTops = (state: StateT): TSavedOldTop[] | null => state.stats.LSTops;
export const selectBoosts = (state: StateT) => state.stats.boosts;
export const selectReviews = (state: StateT): TReview[] => state.stats.reviews;
export const selectFlights = (state: StateT) => state.stats.flights;
export const selectFlightsFilters = (state: StateT) => state.stats.flightsFilters;
export const selectFavorites = (state: StateT) => state.stats.favorites;
export const selectRates = (state: StateT) => getRatesArr(state.stats.rates);
export const selectOffset = (state: StateT) => state.stats.offset;

export default statsSlice.reducer;

export type TBoost = {
  city_id: number;
  currency_from_id: number;
  currency_to_id: number;
};
export type TRates = {
  [key: string]: {
    [key: string]: RateItem;
  };
};
export interface RateItem {
  exchanger_name: string;
  pair_name: string;
  currency_code_to?: string;
  currency_code_from?: string;
  course_price: number;
  course_created_at: number;
}
export type TFlight = {
  site_name: string;
  city_name: string;
  currency_from: string;
  currency_to: string;
  course: number;
  limit: number;
  diff: number;
  is_region: boolean;
};
export type TFlights = {
  last_upd: number;
  data: Array<TFlight>;
};
export type TFlightFilters = {
  city: null | Array<{ label: string; value: string }>;
  site: null | Array<{ label: string; value: string }>;
  from: null | Array<{ label: string; value: string }>;
  to: null | Array<{ label: string; value: string }>;
};

export type TReview = {
  site: string;
  count: number;
};
