import {
  TAnswerPayload,
  TBlock,
  TModule,
  TPagination,
  TQuestion,
  TTest,
  TUpdateTest,
} from "src/pages/Academy/types";
import { BASE_URL } from "./constants";
import api from "./http";

const ACADEMY_BASE_URL = BASE_URL + "/courser-exam";

export default class academyApi {
  // Тесты
  static async getTests(pagination: TPagination) {
    return api.get(
      `${ACADEMY_BASE_URL}/courses/?limit=${pagination.limit}&offset=${pagination.offset}`
    );
  }
  static async createTest(payload: TTest) {
    return api.post(`${ACADEMY_BASE_URL}/courses/`, payload);
  }
  static async updateTest(course_id: number, payload: TUpdateTest) {
    return api.patch(`${ACADEMY_BASE_URL}/courses/${course_id}`, payload);
  }
  static async deleteTest(course_id: number) {
    return api.delete(`${ACADEMY_BASE_URL}/courses/${course_id}`);
  }

  // Модули
  static async getModules(course_id: number) {
    return api.get(`${ACADEMY_BASE_URL}/modules/${course_id}`);
  }
  static async createModule(payload: TModule) {
    return api.post(`${ACADEMY_BASE_URL}/modules/`, payload);
  }
  static async updateModule(module_id: number, payload: TModule) {
    return api.patch(`${ACADEMY_BASE_URL}/modules/${module_id}`, payload);
  }
  static async deleteModule(module_id: number) {
    return api.delete(`${ACADEMY_BASE_URL}/modules/${module_id}`);
  }

  // Блоки
  static async createBlock(payload: TBlock) {
    return api.post(`${ACADEMY_BASE_URL}/blocks/`, payload);
  }
  static async updateBlock(block_id: number, payload: TBlock) {
    return api.patch(`${ACADEMY_BASE_URL}/blocks/${block_id}`, payload);
  }
  static async deleteBlock(block_id: number) {
    return api.delete(`${ACADEMY_BASE_URL}/blocks/${block_id}`);
  }

  // Вопросы
  static async getQuestions(block_id: number, include_correct?: boolean) {
    return api.get(
      `${ACADEMY_BASE_URL}/questions/${block_id}${include_correct ? "?include_correct=true" : ""}`
    );
  }
  static async createQuestion(payload: TQuestion) {
    return api.post(`${ACADEMY_BASE_URL}/questions/`, payload);
  }
  static async updateQuestion(question_id: number, payload: TQuestion) {
    return api.patch(`${ACADEMY_BASE_URL}/questions/${question_id}`, payload);
  }
  static async deleteQuestion(question_id: number) {
    return api.delete(`${ACADEMY_BASE_URL}/questions/${question_id}`);
  }
  static async addAttachment(question_id: number, payload: FormData) {
    return api.post(`${ACADEMY_BASE_URL}/questions/${question_id}/attachments`, payload);
  }
  static async deleteAttachment(question_id: number, attachment_ids: number[]) {
    return api.patch(`${ACADEMY_BASE_URL}/questions/${question_id}/attachments`, {
      attachment_ids: attachment_ids,
    });
  }

  // Попытки
  static async getAttempts() {
    return api.get(`${ACADEMY_BASE_URL}/attempts/`);
  }
  static async startAttempt(module_id: number) {
    return api.get(`${ACADEMY_BASE_URL}/attempts/${module_id}/start`);
  }
  static async answerAttempt(attempt_id: number, data: TAnswerPayload) {
    return api.post(`${ACADEMY_BASE_URL}/attempts/${attempt_id}/answer`, data);
  }
  static async finishAttempt(attempt_id: number) {
    return api.get(`${ACADEMY_BASE_URL}/attempts/${attempt_id}/finish`);
  }
}
