import { useState } from "react";
import { fetchRequest } from "src/helpers/fetchRequest";
import controlApi from "src/api/control";
import { AddButton } from "src/components/UI/buttons/AddButton";

export const CreateUser = ({ fetchUsers }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [userName, setUserName] = useState<string>("");
  const [userId, setUserId] = useState<number | null>(null);
  const disabled = !userName?.length || !userId || userId === 0;

  const createUser = async () => {
    const { response } = await fetchRequest(
      controlApi.createControlUser({
        telegram_user_id: userId,
        username: userName,
      }),
      { request: "Добавление пользователя ТГ" }
    );
    if (response) {
      await fetchUsers();
      setIsAdd(false);
      setUserName("");
      setUserId(null);
    }
  };

  return (
    <>
      <AddButton onClick={() => setIsAdd(!isAdd)} title={"Добавить пользователя"} />
      <div
        className={`duration-500 overflow-hidden flex flex-col gap-8 text-xs px-[10px] ${
          isAdd ? "max-h-[1500px] opacity-100 my-8 py-[12px]" : "max-h-0 opacity-0 mt-0 -mb-8 py-4"
        }`}>
        <div className="grid grid-cols-[80px_1fr] gap-8 items-baseline">
          <div className="font-semibold text-lightFont whitespace-pre">Username</div>
          <input
            className={`w-full h-[20px] px-4 font-light rounded-sm border border-lightGray`}
            value={userName}
            placeholder={"@username"}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-[80px_1fr] gap-8 items-baseline">
          <div className="font-semibold text-lightFont whitespace-pre">TG ID</div>
          <input
            className={`w-full h-[20px] px-4 font-light rounded-sm border border-lightGray`}
            value={userId || ""}
            type="number"
            placeholder={"1234567890"}
            step={1}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onChange={(e) => setUserId(+e.target.value)}
          />
        </div>
        <button
          className={`bg-light self-end px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
            disabled ? "opacity-0" : ""
          }`}
          disabled={disabled}
          onClick={createUser}>
          Добавить
        </button>
      </div>
    </>
  );
};
