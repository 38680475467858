import { ReactNode } from "react";
import { cn } from "src/helpers/cn";
import arrow from "src/assets/images/arrow.svg";
import { ChevronsUpDown, ChevronsDownUp } from "lucide-react";

interface AccordionLabelProps {
  label?: string;
  customLabelComponent?: ReactNode;
  labelClassname?: string;
  isFullyExpanded: boolean;
  isExpandable?: boolean;
  plusClassname?: string;
  undoClassname?: string;
  classicExpand?: boolean;
  isExpanded?: boolean;
  undoable?: boolean;
  containerClassname?: string;
  undo?: () => void;
  onClick?: () => void;
  onClickClassic?: () => void;
  notExpandableOnClick?: () => void;
}

export const AccordionLabel = ({
  label,
  labelClassname,
  isFullyExpanded,
  isExpanded,
  isExpandable = true,
  classicExpand,
  undoClassname,
  customLabelComponent,
  plusClassname,
  undoable,
  containerClassname,
  undo,
  onClick,
  notExpandableOnClick,
  onClickClassic,
}: AccordionLabelProps) => {
  return (
    <div
      onClick={isExpandable ? onClickClassic : undefined}
      className={cn(
        "flex items-center cursor-pointer",
        classicExpand && isExpandable && "hover:underline",
        containerClassname
      )}>
      {isExpandable && classicExpand && (
        <button
          className={`relative duration-300 mr-[6px] ${
            isExpanded ? "rotate-180" : ""
          } w-[10px] h-[10px] min-w-[10px]`}>
          <img src={arrow} alt="раскрыть" width={10} height={10} />
        </button>
      )}
      <span
        className={labelClassname || ""}
        onClick={!isExpandable ? notExpandableOnClick : undefined}>
        {customLabelComponent || label}
      </span>
      {isExpandable && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onClick?.();
          }}
          className={cn(
            "hover:bg-gray/10 rounded-sm transition ml-[6px] px-[6px] aspect-square flex items-center justify-center cursor-pointer",
            plusClassname
          )}>
          {isFullyExpanded ? (
            <ChevronsDownUp className="w-[14px] h-[14px] mt-[1px]" />
          ) : (
            <ChevronsUpDown className="w-[16px] h-[16x] mt-[3px]" />
          )}
        </div>
      )}
      {undoable && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            undo?.();
          }}
          className={cn(
            "hover:bg-gray/10 text-sm rounded-sm transition px-[6px] aspect-square flex items-center justify-center cursor-pointer",
            undoClassname
          )}>
          <span className="mt-[8px]">{`\u2936`}</span>
        </button>
      )}
    </div>
  );
};
