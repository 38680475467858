import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

type TProps = {
  title: string;
  name: string;
  defaultValue: string | number;
  width?: number;
  disabled?: boolean;
  required?: boolean;
  isNumber?: boolean;
  isPositive?: boolean;
  placeholder?: string;
};

export const Field = ({
  title,
  name,
  defaultValue,
  width,
  disabled,
  required,
  isNumber,
  isPositive,
  placeholder,
}: TProps) => {
  const { register, setValue } = useFormContext();
  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue]);

  return (
    <label className={`grid grid-cols-[${width ? width : 66}px_1fr] items-baseline gap-[10px]`}>
      <div className="text-right">{title}</div>
      <input
        className={`border border-[#EEE] outline-lightGray rounded-sm px-[8px] ${
          disabled && "bg-light"
        }`}
        type={isNumber ? "number" : "text"}
        step={"any"}
        placeholder={placeholder || ""}
        min={isPositive ? 0 : ""}
        {...register(`${name}`, { required: required, disabled: disabled })}
      />
    </label>
  );
};
