import { useState } from "react";
import { defineModuleTitle } from "../../constants";
import { TModule } from "../../types";
import { Button } from "src/shadcn/ui/ui/button";
import { EditModule } from "./EditModule";
import { EstimateBadge } from "src/components/FormElements/TimeEstimate";

export const Module = ({ item }: { item: TModule }) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className="relative w-[280px] sm:w-[360px] lg:w-full lg:h-full shadow-md p-5 rounded-md flex flex-col gap-8 duration-300 hover:scale-105 hover:shadow-lg">
      {!isEdit ? (
        <>
          <Button
            variant="ghost"
            className="absolute top-3 right-3 h-7 w-7 text-slate-500 rotate-90"
            onClick={() => setIsEdit(true)}>
            {`\u270E`}
          </Button>
          <div className="text-lg font-semibold">{item.module_name}</div>
          <div>{defineModuleTitle(item.module_type)}</div>
          <div className="text-slate-400">Время на прохождение</div>
          {!!item.module_deadline_in_minutes ? (
            <div className="w-fit">
              <EstimateBadge value={item.module_deadline_in_minutes} />
            </div>
          ) : (
            <small>Не ограничено</small>
          )}
          <div className="text-slate-400">Разрешить доступ</div>
          <div className="flex flex-col text-xs text-slate-400">
            {item.module_allowed_users?.map((el, index) => (
              <div key={`${index}_allowed_user`}>{el}</div>
            ))}
          </div>
          {!!item.module_blocks?.length && (
            <>
              <div className="text-slate-400">Блоки</div>
              <div className="flex flex-col text-xs text-slate-400">
                {item.module_blocks?.map((el) => (
                  <div key={`${el.block_id}_block`}>{el.block_name}</div>
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <EditModule item={item} setIsEdit={setIsEdit} />
      )}
    </div>
  );
};
