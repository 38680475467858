import { useSelector } from "react-redux";
import { IVector } from "../models";
import { selectIsOldStats } from "src/store/statsReducer";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";

export const VectorRow = ({
  item,
  top,
}: {
  item: IVector;
  isBoosted?: boolean;
  fetchBoost?: () => void;
  city?: { label: string; value: number };
  fromId?: number;
  toId?: number;
  isConnect?: boolean | "";
  top: 1 | 2;
}) => {
  // const dispatch = useDispatch();
  const isOldStats = useSelector(selectIsOldStats);
  // const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  // const isAccess = userAccess?.includes("stats_post");
  const isAttention = !isOldStats
    ? item?.all_target_exch_top != 100
    : top === 1
    ? item?.two_exch_top != 100
    : item?.two_exch_top_two != 100;
  // const [time, setTime] = useState("");
  // const [disabled, setDisabled] = useState(false);

  // const updateBoost = async (vector: string, cityId: number, isOn: boolean) => {
  //   setDisabled(true);
  //   const data = {
  //     city_id: cityId,
  //     currency_from_id: fromId,
  //     currency_to_id: toId,
  //   };
  //   const request = isOn ? statsApi.updateBoost : statsApi.deleteBoost;
  //   const { response, error } = await fetchRequest(request(data));
  //   if (response) {
  //     dispatch(
  //       setNotific({
  //         type: "success",
  //         message: `Бустер в г. ${city.label} по ${vector} ${isOn ? "активирован" : "отключен"}`,
  //         request: "Обновление Селениума",
  //       })
  //     );
  //     await fetchBoost();
  //   }
  //   if (error) {
  //     dispatch(
  //       setNotific({ type: "error", message: error?.statusText, request: "Обновление Селениума" })
  //     );
  //   }
  //   setTimeout(() => setDisabled(false), 3000);
  // };

  // useEffect(() => {
  //   if (item?.timestamp) {
  //     const deltaTime = Math.round(+new Date() / 1000) - item?.timestamp;
  //     if (deltaTime < 86400) {
  //       const timerId = setInterval(() => {
  //         setTime(convertToTime(Math.round(+new Date() / 1000) - item.timestamp));
  //       }, 1000);
  //       return () => clearInterval(timerId);
  //     }
  //     if (deltaTime >= 86400 && deltaTime < 172800) {
  //       setTime(`Более 1 дня`);
  //     }
  //     if (deltaTime >= 172800) {
  //       setTime(`${Math.ceil(deltaTime / 86400)} дня`);
  //     }
  //   }
  // }, [item?.timestamp]);

  // useEffect(() => {
  //   if (isConnect !== true) {
  //     setDisabled(true);
  //   } else {
  //     setDisabled(false);
  //   }
  // }, [isConnect]);

  return (
    <div
      style={{
        backgroundColor: isAttention ? "#282c34" : "white",
        color: isAttention ? "white" : "#282c34",
      }}
      className="stats_pairs flex flex-col rounded-t-[7px] justify-center w-full h-[64px] p-[5px] mx-[5px] mb-[5px]">
      <div className="flex justify-center cursor-pointer relative">
        {!item.two_exch_top ? (
          <Tooltip content="Нет обменников в топе">
            <a
              className="font-bold text-[13px] hover:opacity-[0.7] hover:bg-glass-select"
              target="_blank"
              rel="noopener noreferrer"
              href={item.link}>
              {item.vector?.replace("_TO_", "-")?.replace("CASH", "")?.replace("20", "")}
            </a>
          </Tooltip>
        ) : (
          <Tooltip content={`На BESTCHANGE \u279C`}>
            <a
              className="font-bold text-[13px] hover:opacity-[0.7] hover:bg-glass-select"
              target="_blank"
              rel="noopener noreferrer"
              href={item.link}>
              {item.vector?.replace("_TO_", "-")?.replace("CASH", "")?.replace("20", "")}
            </a>
          </Tooltip>
        )}
        {/* <div
          className={`absolute top-[3px] right-[-3px] ${
            !isAccess || disabled ? "cursor-default" : "cursor-pointer"
          } ${disabled && "grayscale opacity-60"}`}
          onClick={async () => {
            if (isAccess && !disabled) {
              await updateBoost(item?.vector, city?.value, !isBoosted);
            }
          }}>
          {isBoosted ? (
            <Tooltip content={time}>
              <svg
                width="20px"
                height="20px"
                version="1.1"
                viewBox="0 0 700 500"
                fill="#FF8A00"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m140.62 243.88 64.961 26.938h-0.003906c-7.3242 18.492-13.164 37.535-17.469 56.953l-2.4648 11.199
                    93.129 93.184 11.199-2.4062v-0.003906c19.418-4.3047 38.461-10.145 56.953-17.469l26.938 64.961-0.003906-0.003906c0.53516
                    1.3086 1.6953 2.2578 3.082 2.5312 1.3867 0.26953 2.8164-0.17578 3.8047-1.1875l40.711-40.711h0.003906c13.434-13.434
                    20.555-31.926 19.598-50.902l-1.4531-26.152c75.375-55.664 151.64-153.27 172.42-323.62
                    1.1953-8.6562-1.7227-17.375-7.8867-23.574-6.1602-6.1953-14.863-9.1562-23.527-8.0078-170.3 20.941-267.96 97.27-323.62
                    172.42l-26.098-1.3438c-18.934-1.0312-37.422 5.9883-50.902 19.32l-40.711 40.711c-1.1484 0.97656-1.6875 2.4922-1.4102
                    3.9766 0.27734 1.4805 1.3281 2.6992 2.7539 3.1914zm261.18-99.23c9.4609-9.4297 22.277-14.715 35.633-14.699 13.359 0.015625
                    26.164 5.332 35.602 14.781s14.742 22.262 14.742 35.617c0 13.355-5.3047 26.168-14.742 35.617-9.4375 9.4492-22.242 14.77-35.602
                    14.781-13.355 0.015625-26.172-5.2695-35.633-14.699-9.4922-9.457-14.824-22.301-14.824-35.699 0-13.398 5.332-26.242
                    14.824-35.699zm-273.34 294.95c-10.781-3.7852-22.355-4.7109-33.602-2.6914-2.0312"
                />
              </svg>
            </Tooltip>
          ) : (
            <Tooltip content="Включить бустер">
              <svg
                width="18px"
                height="18px"
                version="1.1"
                viewBox="0 0 700 500"
                fill={isAttention ? "white" : "#282c34"}
                xmlns="http://www.w3.org/2000/svg">
                <path d="m420.15 489.77c0 38.656-31.336 69.996-69.992 69.996s-69.992-31.34-69.992-69.996c0-38.652 31.336-69.992 69.992-69.992s69.992 31.34 69.992 69.992" />
                <path d="m420.15 280.04c0 38.652-31.336 69.992-69.992 69.992s-69.992-31.34-69.992-69.992c0-38.656 31.336-69.992 69.992-69.992s69.992 31.336 69.992 69.992" />
                <path d="m420.15 70.301c0 38.656-31.336 69.992-69.992 69.992s-69.992-31.336-69.992-69.992 31.336-69.992 69.992-69.992 69.992 31.336 69.992 69.992" />
              </svg>
            </Tooltip>
          )}
        </div> */}
      </div>
      <div className="text-xs">
        <div className="code flex justify-between flex-nowrap items-center">
          <div>30 минут</div>
          <div>{top === 1 || !isOldStats ? item?.top_percent_30 : item?.top_percent_30_two}</div>
        </div>
        <div className="code flex justify-between flex-nowrap items-center">
          <div>Сегодня</div>
          <div>
            {top === 1 || !isOldStats ? item?.top_percent_today : item?.top_percent_today_two}
          </div>
        </div>
      </div>
    </div>
  );
};
