import { LS } from "src/api/constants";
import { ESource, TSwitchSegment } from "src/store/onOffReducer";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { Avatar } from "src/components/Viewers/Avatar";
import bin from "src/assets/images/bin.svg";

export const SegmentForm = ({
  item,
  index,
  sourceKey,
  onInputChange,
  deleteFn,
}: {
  item: TSwitchSegment;
  sourceKey: ESource;
  index: number;
  onInputChange: (index: number, field: "start" | "end", value: string, sourceKey: ESource) => void;
  deleteFn?: () => void;
}) => {
  const username = localStorage.getItem(LS.USERNAME);
  const isYou = !!item
    ? item.creator === username ||
      (!!item.start && !!item.end && !(item.start === item.start && item.end === item.end))
    : true;

  return (
    <div className="relative grid grid-cols-[1fr_1fr_28px] items-center gap-4 my-8 text-xs">
      <input
        type="time"
        name="start"
        className="w-full border border-lightGray rounded outline-none h-[30px] py-4 px-8"
        value={item.start}
        onChange={(e) => onInputChange(index, "start", e.target.value, sourceKey)}
      />
      <input
        type="time"
        name="end"
        className="w-full border border-lightGray rounded outline-none h-[30px] py-4 px-8"
        value={item.end}
        onChange={(e) => onInputChange(index, "end", e.target.value, sourceKey)}
      />
      {!!deleteFn && (
        <button
          type="button"
          className={`flex justify-center items-center rounded-sm h-[28px] w-[28px] max-w-[28px] bg-bg duration-300 bg-[#E7B4AE] hover:shadow-inset`}
          onClick={deleteFn}>
          <img src={bin} alt="удалить" width={16} height={16} />
        </button>
      )}
      {!!item.creator && (
        <div className={`absolute -top-8 -right-4 text-base font-bold`}>
          <Tooltip content={isYou ? "Вы" : item?.creator || ""} direction="left">
            <div
              className={`relative h-[18px] w-[18px] rounded-full border z-10 border-stale ${
                isYou ? "bg-font" : "bg-bg"
              }`}>
              <Avatar username={item?.creator || username} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
