import { createAsyncThunk } from "@reduxjs/toolkit";
import scheduleApi from "src/api/schedule";
import { fetchRequest } from "src/helpers/fetchRequest";
import { getScheduleDeleteBody } from "src/helpers/onOffTimeline/getScheduleDeleteBody";
import { OnOffDeleteItemProps } from "src/types/OnOffTimeline/store";
import { setNotific } from "src/store/mainReducer";
import { setOnOffIsLoading, onOffDeleteItem } from "src/store/onOffTimelineReducer/slice";

export const deleteOnOffEntity = createAsyncThunk(
  "onOff/deleteOnOffEntity",
  async (props: OnOffDeleteItemProps, { dispatch }) => {
    if (props.isNew) {
      dispatch(onOffDeleteItem(props));
      return;
    }

    try {
      dispatch(setOnOffIsLoading(true));

      const body = getScheduleDeleteBody(props);
      const { response, error } = await fetchRequest(scheduleApi.deleteSchedule(body));

      if (!response || !response.length || error) {
        dispatch(
          setNotific({
            type: "error",
            message: error?.statusText || "при удалении произошла ошибка",
          })
        );
        dispatch(setOnOffIsLoading(false));
        return;
      }

      dispatch(onOffDeleteItem(props));
    } catch (e) {
      console.log("ошибка при удалении", e);
    }

    dispatch(setOnOffIsLoading(false));
  }
);
