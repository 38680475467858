import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchRequest } from "src/helpers/fetchRequest";
import devApi from "src/api/developers";
import { ProxyPage } from "./Proxy/ProxyPage";
import { Balance } from "./Balance/Balance";
import { SourcesServices } from "./SourcesServices/SourcesServices";
import { EProxyTab, TProxyService, TProxy, TSourceModel } from "./types";

export const Developers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activePage = searchParams.get("tab") || EProxyTab.PROXY;
  const [proxies, setProxies] = useState<Array<TProxy>>([]);
  const [sources, setSources] = useState<Array<TSourceModel>>([]);
  const [services, setServices] = useState<Array<TProxyService>>([]);

  const fetchProxy = async () => {
    const { response } = await fetchRequest(devApi.getProxy(), { request: "Прокси" });
    if (response) {
      setProxies(response.proxies);
      setSources(response.sources);
      setServices(response.services);
    }
  };

  useEffect(() => {
    fetchProxy();
  }, []);

  return (
    <>
      <div className="flex gap-4 text-xs relative -top-8 pl-[12px]">
        {Object.entries(EProxyTab)?.map((el) => {
          return (
            <button
              key={el[1]}
              type="button"
              className={`rounded-sm duration-300 py-4 px-[16px] lg:whitespace-pre ${
                activePage === el[1]
                  ? "bg-font text-white hover:shadow-inset-black"
                  : "bg-[#EAEAF3] text-[#282c3466] hover:shadow-inset"
              }`}
              onClick={() => {
                searchParams.set("tab", el[1]);
                setSearchParams(searchParams);
              }}>
              {el[0]}
            </button>
          );
        })}
      </div>
      {activePage === EProxyTab.PROXY && (
        <ProxyPage
          proxies={proxies}
          setProxies={setProxies}
          sources={sources}
          services={services}
          fetchProxy={fetchProxy}
        />
      )}
      {activePage === EProxyTab.BALANCE && <Balance sources={sources} />}
      {activePage === EProxyTab.SERVICES && (
        <SourcesServices
          sources={sources}
          setSources={setSources}
          services={services}
          setServices={setServices}
        />
      )}
    </>
  );
};
