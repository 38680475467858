import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/store";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectBlocks, setBlocks } from "src/store/academyReducer";
import { TBlock } from "../../types";
import { Button } from "src/shadcn/ui/ui/button";
import { Popup } from "src/components/Popup/Popup";
import { EditBlock } from "./EditBlock";

import bin from "src/assets/images/bin.svg";

export const BlockCard = ({ item }: { item: TBlock }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const block_id = searchParams.get("block_id");
  const blocks = useSelector(selectBlocks);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteConfirmPopup, setIsDeleteConfirmPopup] = useState(false);

  const deleteBlock = async () => {
    const { response } = await fetchRequest(academyApi.deleteBlock(+block_id), {
      request: "Удаление блока",
    });
    if (response) {
      dispatch(setBlocks(blocks?.filter((el) => el.block_id !== +block_id)));
    }
    setIsDeleteConfirmPopup(false);
  };

  return (
    <div
      className={`relative w-[280px] sm:w-[360px] shadow-md pl-5 pr-8 py-8 rounded-md flex flex-col gap-8 duration-300 hover:scale-105 hover:shadow-lg ${
        +block_id === item.block_id ? "bg-purple-200 text-font" : "text-slate-500"
      }`}>
      {!isEdit ? (
        <>
          <div className="flex gap-4 items-center">
            <button
              className="text-xl text-left font-semibold duration-300 w-full hover:bg-slate-100 rounded-md px-8 py-4 -ml-8"
              onClick={() => {
                searchParams.set("block_id", item.block_id.toString());
                setSearchParams(searchParams);
              }}>
              {item.block_name}
            </button>
            <Button
              variant="ghost"
              className="h-9 w-9 flex justify-center items-center aspect-square shrink-1 text-slate-500 bg-rose-200"
              onClick={() => setIsDeleteConfirmPopup(true)}>
              <img src={bin} alt="удалить" width={15} height={15} />
            </Button>
            <Button
              variant="ghost"
              className="h-9 w-9 flex justify-center items-center aspect-square shrink-1 text-slate-500 rotate-90"
              onClick={() => setIsEdit(true)}>
              {`\u270E`}
            </Button>
          </div>
        </>
      ) : (
        <EditBlock item={item} setIsEdit={setIsEdit} />
      )}
      {isDeleteConfirmPopup && (
        <Popup closeModal={() => setIsDeleteConfirmPopup(false)}>
          <div className="flex flex-col gap-7 p-3">
            <div>
              Вы уверены что хотите <b>удалить</b> блок?
            </div>
            <Button className="bg-rose-500" onClick={deleteBlock}>
              Удалить модуль
            </Button>
          </div>
        </Popup>
      )}
    </div>
  );
};
