import { useEffect, useState } from "react";
import addApi from "src/api/add";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { setNotific } from "src/store/mainReducer";
import {
  selectAllCountries,
  TGlobalCountry,
  TGlobalSite,
  TSite,
} from "src/store/directionsReducer";
import { TCurrencyTypes } from "src/pages/Text/types";
import { AddCurrency } from "./AddCurrency";
import { Adder } from "../../Adder";
import { Progress } from "../../Progress";
import { TAddTab } from "src/pages/Add/Add";

type TProps = {
  setActiveTab: React.Dispatch<React.SetStateAction<TAddTab>>;
};

export const CreateCurrency = ({ setActiveTab }: TProps) => {
  const dispatch = useDispatch();
  const countries = useSelector(selectAllCountries);
  /*const emptyCredo = {
        name: "",
        username: "",
        password: "",
        port: 0,
        host: "",
        prefix: "",
        driver: "postgres"
    }*/
  const [step, setStep] = useState(0);
  //const [isPopup, setIsPopup] = useState(false)
  const [selectedCountries, setSelectedCountries] = useState<Array<TGlobalCountry>>([]);
  const [selectedSites, setSelectedSites] = useState<Array<TSite>>([]);
  const [selectedSitesNames] = useState<Array<TGlobalSite>>([]);
  const [currencyId, setCurrencyId] = useState<number>(-1);
  const [exnodeId, setExnodeId] = useState<string>("");
  const [currencyName, setCurrencyName] = useState<string>("");
  const [currencyType, setCurrencyType] = useState<TCurrencyTypes>("crypto");
  const [transcript, setTranscript] = useState<string>("");
  const [translation, setTranslation] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [fullNameEn, setFullNameEn] = useState<string>("");
  const [createVectors, setCreateVectors] = useState(false);
  const [loading, setLoading] = useState(false);
  //const [credo, setCredo] = useState<TCredo>(emptyCredo)
  const disabled1Step =
    (currencyType === "cash" && currencyName?.substring(0, 4) !== "CASH") ||
    currencyName?.length < 3 ||
    currencyId < 1 ||
    transcript?.length < 3 ||
    translation?.length < 3 ||
    fullName?.length < 3 ||
    exnodeId?.length < 1 ||
    +exnodeId < 1;

  /*const addCredo = () => {
        const site = selectedSitesNames?.find(el => !siteNames?.includes(el.site_name))
        setSelectedSites([...selectedSites, {
            site_id: site?.site_id,
            site_name: site?.site_name,
            database_credo: {
                name: credo?.name,
                username: credo?.username,
                password: credo?.password,
                port: credo?.port,
                host: credo?.host,
                prefix: credo?.prefix,
                driver: credo?.driver
            }
        }])
        setCredo(emptyCredo)
        setIsPopup(false)
    }*/

  const submit = async () => {
    const data = {
      currency_id: currencyId,
      currency_exnode_id: exnodeId,
      currency_name: currencyName,
      currency_full_name: fullName,
      currency_full_name_en: fullNameEn,
      is_crypto: currencyType === "crypto",
      type: currencyType,
      transcript,
      translation,
      countries: currencyType === "card" ? ["AUTO"] : selectedCountries?.map((el) => el?.name_ru),
      create_vectors: createVectors,
    };

    setLoading(true);

    const { response, error } = await fetchRequest(addApi.addCurrency(data), {
      request: "Создание валюты",
    });

    setLoading(false);
    if (response) {
      setCurrencyName("");
      setCurrencyId(-1);
      setTimeout(() => {
        setActiveTab(null);
      }, 5000);
    }
    if (error) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText, request: "Создание валюты" })
      );
    }
  };

  useEffect(() => {
    if (selectedCountries?.length > 0) {
      setStep(2);
    }
    if (selectedSites?.length > 0) {
      setStep(3);
    }
  }, [selectedCountries, selectedSites]);

  useEffect(() => {
    if (selectedSites?.length > selectedSitesNames?.length) {
      const names = selectedSitesNames?.map((el) => el.site_name);
      setSelectedSites(selectedSites?.filter((el) => names?.includes(el?.site_name)));
    }
  }, [selectedSites, selectedSitesNames]);

  useEffect(() => {
    setSelectedSites(
      selectedSitesNames?.map((el) => ({ site_name: el.site_name, site_id: el.site_id }))
    );
  }, [selectedSitesNames]);

  return (
    <div className="max-h-[85vh] text-center pb-[20px] overflow-y-auto">
      <div className="table-header justify-center">НОВАЯ ВАЛЮТА</div>
      {currencyType !== "card" && (
        <div className="hidden lg:block">
          <Progress
            firstTitle="Валюта"
            first={currencyName}
            haveSecond={selectedCountries?.length > 0}
            secondTitle="Страны"
            haveThird={false}
            thirdTitle=""
          />
        </div>
      )}
      <div className="flex flex-col lg:flex-row">
        <div className="lg:flex-1">
          <AddCurrency
            currencyName={currencyName}
            setCurrencyName={setCurrencyName}
            setCurrencyId={setCurrencyId}
            setExnodeId={setExnodeId}
            currencyType={currencyType}
            setCurrencyType={setCurrencyType}
            setTranscript={setTranscript}
            setTranslation={setTranslation}
            setFullName={setFullName}
            setFullNameEn={setFullNameEn}
            setCreateVectors={setCreateVectors}
            setStep={setStep}
            disabled={disabled1Step}
          />
        </div>
        {currencyType !== "card" && (
          <div
            className={`lg:flex-1 heightAnimation overflow-y-hidden relative ml-[10px] top-[22px] ${
              step > 0 ? "h-[400px]" : "h-0"
            }`}>
            <Adder
              selectedItems={selectedCountries}
              setSelectedItems={setSelectedCountries}
              allItems={countries}
              itemName={`name_ru`}
            />
          </div>
        )}
      </div>
      {(currencyType !== "card" ? step > 1 : true) && (
        <button onClick={submit} disabled={loading} className="prime-button">
          {loading ? "Загрузка..." : "Создать валюту"}
        </button>
      )}
    </div>
  );
};
