export const CloseButton = ({ setFn }) => {
  return (
    <button
      type="button"
      className={`w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#282c3466] font-normal text-base`}
      onClick={() => setFn(false)}>
      <span className={`relative top-[-1px] rotate-45 left-[1px]`}>+</span>
    </button>
  );
};
