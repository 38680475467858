import { ProfDealType, ProfItem } from "src/types/prof";
import { InputChangeHandler } from "src/pages/Bid/ProfPage/index";

interface Props {
  item: ProfItem;
  handleInputChange: InputChangeHandler;
  isAccess: boolean;
}

const Table = ({ item, handleInputChange, isAccess }: Props) => {
  const countValue = (
    asPercent: boolean,
    percent: number | null,
    action: ProfDealType,
    value: number | null
  ) => {
    if (!percent) return value?.toFixed(3) || 0;

    const multiplier = action === ProfDealType.SELL ? -1 : 1;
    let val: number | null;

    if (asPercent) {
      val = value + multiplier * percent;
    } else {
      val = value + (multiplier * value * percent) / 100;
    }

    return val?.toFixed(3) || 0;
  };

  return (
    <div className="table mx-auto w-fit mb-8 p-[10px] sm:p-0">
      <div className="hidden table-header sm:grid grid-cols-[130px_130px_130px_180px] px-[30px]">
        <div>{item.city_name}</div>
        <div>Проф курс</div>
        <div>В телегу</div>
        <div>Комментарий</div>
      </div>
      <div className="flex sm:hidden font-semibold">{item.city_name}</div>
      <div>
        {item.prof_bets?.map(
          (
            {
              prof_form_percent,
              prof_form_as_percentage,
              prof_form_comment,
              prof_form_origin_rate,
              prof_form_deal_type,
              currency_code_from,
              currency_code_to,
            },
            index
          ) => {
            return (
              <div
                key={`${index}_${item}_${currency_code_from}_${currency_code_to}`}
                className="grid grid-cols-[100px_100px_100px] sm:grid-cols-[130px_130px_130px_180px] items-center justify-around sm:px-[20px] py-[3px] text-xs font-light">
                <div className={"text-left sm:text-center"}>
                  {currency_code_from} - {currency_code_to}
                </div>
                <div>
                  {isAccess ? (
                    <input
                      className="max-w-[100px] sm:max-w-[120px] px-4 border-lightGray border-[1px] my-4 rounded-lg outline-none"
                      type="number"
                      step="any"
                      value={prof_form_origin_rate || ""}
                      onChange={(e) =>
                        handleInputChange({
                          city_name: item.city_name,
                          currency_code_from,
                          currency_code_to,
                          field: "prof_form_origin_rate",
                          value: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <div>{prof_form_as_percentage ? prof_form_percent : prof_form_origin_rate}</div>
                  )}
                </div>
                <div>
                  {prof_form_percent !== null && (
                    <div className="max-w-[100px] sm:max-w-[120px]">
                      {countValue(
                        prof_form_as_percentage,
                        prof_form_percent,
                        prof_form_deal_type,
                        +prof_form_origin_rate
                      )}
                    </div>
                  )}
                </div>
                <textarea
                  className="px-4 col-span-3 sm:col-span-1 border-lightGray border-[1px] my-4 rounded-lg outline-none"
                  disabled={!isAccess}
                  value={prof_form_comment || ""}
                  onChange={(e) =>
                    handleInputChange({
                      city_name: item.city_name,
                      currency_code_from,
                      currency_code_to,
                      field: "prof_form_comment",
                      value: e.target.value,
                    })
                  }
                />
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default Table;
