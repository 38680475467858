import { Loader2 } from "lucide-react";
import { useState } from "react";

export type TStatsReportEditField = "percent" | "clients_30" | "clients_12h";

export const EditField = ({
  defaultValue,
  updateFn,
  attr,
  isLoading,
  isAccess,
}: {
  defaultValue: string;
  updateFn: (value: string, attr: TStatsReportEditField) => void;
  attr: TStatsReportEditField;
  isLoading: boolean;
  isAccess: boolean;
}) => {
  const [isEdit, setEdit] = useState(false);
  const [value, setValue] = useState<string>(defaultValue);
  const disabled =
    attr === "percent"
      ? Number.isNaN(+value) || +value < 0 || +value > 100
      : !value?.length || Number.isNaN(+value);

  return (
    <>
      {isEdit && !isLoading && isAccess ? (
        <div className="flex gap-4 items-center pl-4">
          <input
            className="border border-input rounded-sm p-8 w-full"
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            value={value}
            placeholder="Введите процент плана"
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <button
            type="button"
            className="bg-emerald-100 w-5 h-5 shrink-0 flex items-center justify-center duration-300 hover:bg-lightStale rounded-md disabled:grayscale disabled:bg-slate-100"
            disabled={disabled}
            onClick={() => {
              updateFn(value, attr);
              setEdit(false);
            }}>
            <span className="text-emerald-400 text-xs">{`\u2713`}</span>
          </button>
          <button
            type="button"
            className="bg-yellow-100 w-5 h-5 shrink-0 flex items-center justify-center duration-300 hover:bg-lightStale rounded-md"
            onClick={() => setEdit(false)}>
            <span className="text-yellow-400">{`\u00D7`}</span>
          </button>
        </div>
      ) : isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader2 className="animate-spin w-[26px] h-[26px] text-[#40CEF0]" />
        </div>
      ) : isAccess ? (
        <button
          type="button"
          className={`flex gap-3 justify-center items-center w-full h-full text-center gap-[16px] px-[10px] rounded-md font-bold duration-300 hover:bg-glass-md ${
            attr !== "percent" ? "bg-sky-50" : ""
          }`}
          onClick={() => setEdit(true)}>
          <span>{defaultValue}</span>
          <span className="rotate-[110deg] text-yellow-400">{`\u270E`}</span>
        </button>
      ) : (
        <div
          className={`flex gap-3 justify-center items-center w-full h-full text-center gap-[16px] px-[10px] rounded-md font-bold duration-300 hover:bg-glass-md ${
            attr !== "percent" ? "bg-sky-50" : ""
          }`}>
          {defaultValue}
        </div>
      )}
    </>
  );
};
