import { useDispatch } from "react-redux";
import ReactSelect, { createFilter } from "react-select";
import { selectFilter } from "src/helpers/formHelpers";
import { TStrSelect } from "src/store/directionsReducer";

export const MultiSelect = ({ options, className, placeholder, obj, attr, setFn }) => {
  const dispatch = useDispatch();

  return (
    <div className={className}>
      <ReactSelect
        classNamePrefix="SquareSelect"
        placeholder={placeholder}
        options={options}
        filterOption={createFilter(selectFilter)}
        onChange={(e: TStrSelect) => {
          if (!obj[attr]?.find((el) => el.value === e.value)) {
            dispatch(setFn({ attr: attr, value: obj[attr] ? [...obj[attr], e] : [e] }));
          }
        }}
      />
      <div className="flex flex-wrap gap-4 ml-[1px] mt-4">
        {!!obj[attr] &&
          obj[attr]?.map((el) => (
            <div
              key={el.value}
              className="flex gap-4 items-center whitespace-pre rounded-sm bg-[#3BC57A] text-[#BBFFDA] text-[10px] pl-4 pr-8 font-medium h-[15px]">
              {el.label}
              <button
                type="button"
                className="relative left-2 rotate-45 text-[12px]"
                onClick={() => {
                  dispatch(
                    setFn({ attr: attr, value: obj[attr]?.filter((el2) => el2.value !== el.value) })
                  );
                }}>
                +
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};
