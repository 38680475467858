import { IVector } from "../models";
import { TSelect } from "src/store/directionsReducer";
import { VectorRow } from "./VectorRow";

type TProps = {
  item: IVector[];
  // boosts: TBoost[];
  // fetchBoost: () => void;
  isConnect?: boolean | "";
  city?: TSelect;
  top: 1 | 2;
};

export const PairRow = ({ item, top }: TProps) => {
  // const globalCurrencies = useSelector(selectAllCurrencies);

  return (
    <div>
      {item?.map((el, index) => {
        // const vectorData = el?.vector?.split("_TO_");
        // const fromId = globalCurrencies?.find(
        //   (cur) => cur?.currency_name === vectorData[0]
        // )?.currency_id;
        // const toId = globalCurrencies?.find(
        //   (cur) => cur?.currency_name === vectorData[1]
        // )?.currency_id;
        return (
          <VectorRow
            key={index}
            item={el}
            // fetchBoost={fetchBoost}
            // city={city}
            // isBoosted={
            //   !!boosts?.find((el) => el?.currency_from_id === fromId && el?.currency_to_id === toId)
            // }
            // fromId={fromId}
            // toId={toId}
            // isConnect={isConnect}
            top={top}
          />
        );
      })}
    </div>
  );
};
