import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import textApi from "src/api/text";
import { setNotific } from "src/store/mainReducer";
import { Popup } from "src/components/Popup/Popup";
import { TTemplate, TText, TVectorType } from "../types";
import ghost from "src/assets/images/ghost.svg";

type TProps = {
  data: Array<TTemplate>;
  template: TTemplate | null;
  setTemplate: Dispatch<SetStateAction<TTemplate | null>>;
  isTemplate: boolean;
  setIsTemplate: Dispatch<SetStateAction<boolean>>;
  setText: Dispatch<SetStateAction<string>>;
  isGood: boolean;
  isGoodTemp: boolean | null;
  fetchTemplate: () => void;
  resText: TText | null;
  isOpen: boolean;
  currencyType: TVectorType;
};

export const Templates = ({
  data,
  template,
  setTemplate,
  isTemplate,
  setIsTemplate,
  setText,
  isGood,
  isGoodTemp,
  fetchTemplate,
  resText,
  isOpen,
  currencyType,
}: TProps) => {
  const dispatch = useDispatch();
  const [popup, setPopup] = useState<number | null>(null);

  const deleteTemplate = async (id) => {
    setPopup(null);
    const { response, error, status } = await fetchRequest(textApi.deleteTemplates(id));
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: "Шаблон успешно удален",
          request: "Удаление шаблона",
        })
      );
      await fetchTemplate();
    }
    if (error && status !== 404) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText, request: "Удаление шаблона" })
      );
    }
  };

  return (
    <div
      className={`rounded-sm duration-300 h-full md:min-h-[360px] text-left  ${
        isGood ? "bg-[#FAFAFFAA] border border-stale" : "bg-stale opacity-60"
      } ${
        isOpen
          ? "max-h-[360px] max-w-[768px] md:max-w-[160px] opacity-100"
          : "max-h-[0px] md:max-w-0 opacity-0"
      }
    md:max-h-[1000px]`}>
      {isGood && (
        <button
          className={`w-full flex items-center gap-[16px] hover:opacity-80 p-[16px] text-left font-normal text-lightFont whitespace-pre ${
            template?.id === 0 ? "bg-select" : ""
          }`}
          onClick={() => {
            if (!template) {
              setTemplate({
                id: 0,
                text: "",
                from_type: currencyType.currency_from_type,
                to_type: currencyType.currency_to_type,
              });
              setText("");
              setIsTemplate(true);
            } else {
              setTemplate(null);
              setIsTemplate(false);
            }
          }}>
          <div className={`text-[16px] leading-[10px]`}>+</div>
          Новый шаблон
        </button>
      )}
      {!data?.length && isGoodTemp && (
        <div className="flex flex-col gap-[10px] md:gap-[20px] h-full min-h-[140px] md:min-h-[260px] items-center justify-center text-center text-[#BABAC3] text-[10px]">
          <img src={ghost} alt="пусто" width={50} height={50} />
          <div className="px-[20px]">Нет готовых шаблонов для сайта. Создайте новый шаблон</div>
        </div>
      )}
      {data?.map((el) => (
        <div
          key={el.id}
          className={`grid grid-cols-[1fr_20px] items-center h-[60px] ${
            template?.id === el?.id ? "bg-select" : "hover:bg-stale"
          }`}>
          <div
            className={`cut-text cursor-pointer py-2 px-[16px] h-fit max-h-[60px]`}
            onClick={() => {
              if (template?.id === el?.id) {
                setTemplate(null);
                setText(resText?.text || "");
              } else {
                setTemplate(el);
                setText(el?.text);
              }
            }}>
            {el?.text}
          </div>
          <div className="flex flex-col gap-8 justify-center">
            {!isTemplate && (
              <button
                className={`duration-300 text-lightGray font-extrabold hover:text-lightFont`}
                onClick={() => {
                  if (isTemplate && template?.id === el?.id) {
                    setIsTemplate(false);
                    setTemplate(null);
                    setText(resText?.text || "");
                  } else if (isTemplate && template?.id !== el?.id) {
                    setTemplate(el);
                    setText(el?.text);
                  } else {
                    setIsTemplate(true);
                    setTemplate(el);
                    setText(el?.text);
                  }
                }}>
                {`\u270E`}
              </button>
            )}
            <button
              className={`duration-300 text-lightGray hover:text-lightFont`}
              onClick={() => setPopup(el?.id)}>
              {`\u2716`}
            </button>
          </div>
        </div>
      ))}
      {!!popup && (
        <Popup closeModal={() => setPopup(null)}>
          <div className="flex flex-col gap-[30px] max-w-[280px] font-light text-sm items-center">
            <div>
              Вы уверены, что хотите удалить шаблон{" "}
              <span className="text-blue font-semibold">
                {data?.find((el) => el.id === popup)?.text?.substring(0, 100) || "" + "..."}
              </span>
              ?
            </div>
            <button
              className="w-fit px-[20px] py-4 font-semibold text-lightFont rounded-sm duration-300 bg-[#EAEAF3] hover:bg-[#D7443E77]"
              onClick={() => deleteTemplate(popup)}>
              Удалить шаблон
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
