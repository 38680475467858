import { useState } from "react";
import { Timer } from "../timestamp/Timer";

export const UpdateButton = ({ updFn, isDark, disabled, time, dependence }: TProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex relative">
      <button
        type="button"
        className="flex absolute -top-4 -left-4 lg:hidden justify-center items-center rounded-full duration-700 shadow-top hover:shadow-smooth-top bg-bg text-xs text-lightFont w-6 h-6"
        onClick={() => setIsOpen(!isOpen)}>
        <div className={`relative ${isOpen ? "right-[1px]" : "left-[1px]"}`}>
          {isOpen ? `\u276E` : `\u276F`}
        </div>
      </button>
      <button
        type="button"
        onClick={updFn}
        disabled={disabled}
        className={`${
          isOpen ? "flex" : "hidden"
        } lg:flex items-center justify-center rounded-lg duration-700 shadow-top hover:shadow-smooth-top w-[220px] h-[90px] lg:h-[90px] text-left p-[10px] pl-[15px] ${
          isDark ? "bg-font" : "bg-bg"
        } ${disabled ? "opacity-40" : ""}`}>
        <Timer time={time} dependence={dependence} />
        <div className="text-xs ml-[12px] font-light">До обновления данных с сервера</div>
      </button>
    </div>
  );
};

type TProps = {
  updFn: () => void;
  isDark?: boolean;
  disabled?: boolean;
  time?: number;
  dependence?;
};
