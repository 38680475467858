import { Tooltip } from "src/components/UI/Tooltip/Tooltip";

export const ReviewCard = ({ item }: { item: { site: string; count: number } }) => {
  return (
    <div
      className={`px-[7px] text-[0.8rem] flex flex-wrap flex-col justify-center w-[225px] max-w-[225px] scaleAnimation rounded-md`}>
      <div className={`table shadow-sm w-[225px]`}>
        <div className="font-light justify-between flex flex-nowrap items-center my-[10px] mx-[15px]">
          <div>{item?.site}</div>
          <Tooltip content="Негативные отзывы на BESTCHANGE">
            <div className="rounded-[14px] h-[26px] min-w-[26px] bg-font font-bold text-white pt-[1px] px-2 text-[15px]">
              {item?.count}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
