import { TSource, ESource, TSwitchSegment } from "src/store/onOffReducer";
import close from "src/assets/images/close.svg";
import copy from "src/assets/images/copy.svg";
import { SegmentForm } from "src/pages/Panel/OnOff/OnOffTimeline/components/forms/SegmentForm";
import { Tooltip, TooltipProvider, TooltipContent, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";

export const SourceBlock = ({
  source,
  sourceKey,
  segments,
  offValues,
  isInvalid,
  addSegment,
  removeSegment,
  onSegmentChange,
  makeDouble,
  handleOffAll,
}: {
  segments: TSwitchSegment[];
  source: TSource;
  sourceKey: ESource;
  offValues: Record<ESource, boolean>;
  isInvalid: boolean;
  addSegment: (sourceKey: ESource) => void;
  removeSegment: (index: number, sourceKey: ESource) => void;
  onSegmentChange: (
    index: number,
    field: "start" | "end",
    value: string,
    sourceKey: ESource
  ) => void;
  makeDouble?: () => void;
  handleOffAll: (sourceKey: ESource) => void;
}) => {
  const isOff = offValues[sourceKey];
  const isSiteOff = offValues.site;

  const makeDoubleShown = sourceKey !== ESource.SITE && !!makeDouble && !isSiteOff;

  return (
    <div
      className="text-xs rounded-md border w-[315px] duration-300"
      style={{
        maxHeight: "1000px",
        backgroundColor: `${source.color}55`,
        borderColor: source.color,
        padding: "8px",
      }}>
      <div className="w-full flex justify-between items-baseline font-semibold text-left">
        <div>{source.title?.toUpperCase()}</div>
        {makeDoubleShown && (
          <button
            type="button"
            className="flex gap-8 items-center -mx-2 py-2 px-4 text-lightFont rounded-sm duration-300 hover:bg-glass"
            onClick={() => {
              isOff && handleOffAll(sourceKey);
              makeDouble?.();
            }}>
            Копия от сайта
            <img src={copy} height={14} width={14} alt="copy" />
          </button>
        )}
      </div>
      {!isOff && (
        <>
          {segments?.map((el, index) => {
            return (
              <SegmentForm
                key={`${sourceKey}_${index}`}
                index={index}
                item={el}
                sourceKey={sourceKey}
                deleteFn={segments.length > 1 ? () => removeSegment(index, sourceKey) : null}
                onInputChange={onSegmentChange}
              />
            );
          })}
        </>
      )}
      {!isOff && isInvalid && (
        <small className="error">Выставите отрезки последовательно, без наслоений</small>
      )}
      {!isOff && (
        <div className="w-full flex justify-end">
          <button
            type="button"
            className="flex gap-8 items-center -mx-2 py-2 px-4 text-lightFont rounded-sm duration-300 hover:bg-glass"
            onClick={() => addSegment(sourceKey)}>
            Добавить отрезок
            <div className="w-[14px] h-[14px] rounded-full bg-green text-white font-bold text-sm leading-2">
              <span className="relative -top-4">+</span>
            </div>
          </button>
        </div>
      )}
      <div className="w-full flex justify-between items-center gap-8">
        {isOff && !(sourceKey !== ESource.SITE && isSiteOff) ? (
          <button
            type="button"
            onClick={() => handleOffAll(sourceKey)}
            className="max-w-full -ml-4 py-2 px-4 text-lightFont rounded-sm duration-300 hover:bg-glass">
            Вернуть выставленные значения
          </button>
        ) : (
          <div />
        )}
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <button
                type="button"
                className={`min-w-fit justify-between whitespace-nowrap flex gap-8 items-center -mr-2 py-2 pl-4 pr-[4px] rounded-sm duration-300 ${
                  isOff
                    ? "bg-red text-white cursor-default"
                    : "bg-transparent text-red hover:bg-glass"
                }`}
                onClick={!isOff ? () => handleOffAll(sourceKey) : undefined}>
                {isOff ? "Нет отрезков" : "Удалить все"}
                {!isOff && <img src={close} height={14} width={14} alt="off" />}
              </button>
            </TooltipTrigger>
            <TooltipContent hidden={!isOff} side="left">
              <p className="text-[12px] max-w-[160px] text-center">
                У сайта не будет отрезков в расписании вкл/выкл
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};
