import { useEffect } from "react";
import { FieldErrorsImpl, useFormContext } from "react-hook-form";
import { ICityData, ICountryData } from "../models";
import Delta from "src/assets/images/delta.svg";
import { cn } from "src/utils";

export const CityRow = ({
  item,
  boss,
  index,
  regionLength,
  access,
  staticCountries,
  country,
  redactorDisabled,
  setAll,
  isRegion,
  isLoading,
}: {
  item: ICityData;
  boss: number;
  index: number;
  regionLength: number;
  access: boolean;
  staticCountries: ICountryData[];
  isRegion: boolean;
  country: string;
  redactorDisabled: boolean;
  setAll: ({ rate, isDelta }: { rate: number; isDelta: boolean }) => void;
  isLoading: boolean;
}) => {
  const {
    register,
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = useFormContext();

  const checkbox = watch(`${item?.city_name}.bet_is_delta`);
  const staticItem = staticCountries
    ?.find((el) => el?.country_name === country)
    ?.cities_data?.find((el2) => el2?.city_name === item?.city_name);
  const watchRate = +watch(`${item?.city_name}.bet_value_front`);

  const cityErrors = errors[item?.city_name] as FieldErrorsImpl<ICityData>;

  useEffect(() => {
    setValue(`${item?.city_name}`, { ...item });
    return () => unregister(`${item?.city_name}`);
  }, [item]);

  return (
    <div
      className={`${
        isRegion === item.city_is_region
          ? "relative z-[1] opacity-100"
          : "absolute -z-10 opacity-0 w-2"
      } flex flex-col sm:flex-row items-start sm:items-center mb-4 sm:mt-[20px] sm:mb-0 py-[10px] sm:py-0 shadow-base rounded-lg sm:shadow-none w-full text-xs`}>
      <div className="w-[70px] mx-[10px] sm:mx-[20px] text-left">{item?.city_name}</div>
      <div className="relative w-full sm:w-fit sm:ml-[20px]">
        <div className="absolute left-[61%] -top-[18px] sm:-top-[12px] text-center">
          {checkbox ? "Дельта" : "Ставка"}
        </div>
        <div className={"flex items-center justify-between sm:justify-start w-full sm:w-fit"}>
          <div className="font-semibold mr-5 grid grid-cols-[20px_80px] w-[100px]">
            {staticItem?.bet_is_delta ? (
              <img
                className="mr-3 ml-[10px] sm:ml-0"
                src={Delta}
                width="20px"
                height="20px"
                alt="дельта"
              />
            ) : (
              <div className="hidden sm:block w-[20px]" />
            )}
            <div className="ml-[10px] sm:ml-0">{staticItem?.bet_value_front}</div>
          </div>
          {access && !redactorDisabled && !isLoading ? (
            <div className="relative mr-[10px]">
              <input
                className={cn(
                  "block font-medium px-4 border-lightGray outline-lightGray border-[1px] my-4 rounded-md w-[145px] sm:w-44",
                  cityErrors?.bet_value_front && "border-rose-500"
                )}
                type="number"
                step={0.000001}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                {...register(`${item?.city_name}.bet_value_front`, {
                  required: true,
                  minLength: 1,
                })}
              />
              {checkbox && (
                <div className="absolute top-[25px] bg-lightGray text-white text-[8px] font-bold px-8 rounded-md">
                  {isNaN(+watch(`${item?.city_name}.bet_value_front`))
                    ? ""
                    : (+boss + watchRate).toLocaleString("ru")}
                </div>
              )}
              {index === 0 && regionLength > 1 && (
                <button
                  type="button"
                  className="rounded-md bg-font text-[10px] text-white px-[10px] hover:opacity-[0.8]"
                  onClick={() =>
                    setAll({
                      rate: watch(`${item?.city_name}.bet_value_front`),
                      isDelta: watch(`${item?.city_name}.bet_is_delta`),
                    })
                  }>
                  Применить ко всем
                </button>
              )}
              {errors && errors[`${item?.city_name}.bet_value_front`]?.type === "required" && (
                <div className="text-red absolute top-[6px] left-[25px] sm:left-[50px] z-20">
                  не заполнено
                </div>
              )}
            </div>
          ) : (
            <div className="mr-[10px] font-medium px-4 bg-[#EEE] my-4 rounded-md w-[100px] sm:w-44 cursor-default">
              {item?.bet_value_front}
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between w-full sm:w-fit">
        <div className="switcher w-[50px] mx-[10px]">
          <label
            style={{ backgroundColor: checkbox ? "green" : "lightgrey" }}
            className={`switcher__label ${
              access && !redactorDisabled && !isLoading
                ? "cursor-pointer hover:opacity-[0.9]"
                : "cursor-default"
            }`}>
            <input
              className="switcher__check-input"
              type="checkbox"
              disabled={!access || redactorDisabled || isLoading}
              {...register(`${item?.city_name}.bet_is_delta`)}
            />
            <span className="switcher__checkbox"></span>
          </label>
        </div>
      </div>
    </div>
  );
};
