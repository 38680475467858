import { Dispatch, SetStateAction, useMemo, memo, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { convertToShortTime, timeToSeconds } from "src/helpers/date";
import { setTimelineByCentrifugo } from "src/api/centrifugo/timelineCentrifugo";
import { colors } from "src/helpers/colors";
import clusterApi from "src/api/cluster";
import {
  TTimeline,
  TTimelineDeletePayload,
  TTimelineSite,
  TTimelineUpdateLock,
  TTimelineVector,
} from "src/store/clusterReducer";
import { setNotific } from "src/store/mainReducer";
import { TGlobalSite } from "src/store/directionsReducer";
import { Avatar } from "src/components/Viewers/Avatar";
import { Popup } from "src/components/Popup/Popup";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { TimeLineItemForm } from "./TimeLineItemForm";
import infinity from "src/assets/images/infinity.svg";
import pumpkin from "src/assets/images/pumpkin.svg";
import copy from "src/assets/images/copy.svg";

type TProps = {
  item: TTimelineSite;
  globalSites: Array<TGlobalSite>;
  items: Array<TTimeline>;
  cityId: number;
  vector: TTimelineVector;
  zoom: number;
  step: number;
  expand: boolean;
  deleteIt: (payload: TTimelineDeletePayload, saveRows: boolean) => void;
  isBest: boolean;
  isAdded: string;
  setIsAdded: Dispatch<SetStateAction<string>>;
  getInterval: (siteEl: TTimelineSite) => number;
  getStart: (siteEl: TTimelineSite) => number;
  username: string;
  activeTemplate: number | null;
  setTemplateData: Dispatch<SetStateAction<Array<TTimeline>>>;
};

export const TimelineItem = memo(
  ({
    item,
    globalSites,
    items,
    getInterval,
    getStart,
    activeTemplate,
    setTemplateData,
    cityId,
    vector,
    zoom,
    step,
    expand,
    deleteIt,
    isBest,
    isAdded,
    setIsAdded,
    username,
  }: TProps) => {
    const dispatch = useDispatch();
    const [isPopup, setPopup] = useState(false);
    const site = useMemo(
      () => globalSites?.find((siteEl) => siteEl.site_id === item.site_id),
      [globalSites]
    );
    const id = useMemo(() => item?.site_id % 30 || 0, [item]);
    const interval = useMemo(() => getInterval(item), [item, zoom, step]);
    const start = useMemo(() => getStart(item), [item, zoom, step]);
    const getVectorStr = (vectorEl: TTimelineVector) =>
      `${vectorEl?.currency_from_id}_${vectorEl?.currency_to_id}_${vectorEl?.percent}`;
    const getSiteStr = (siteEl: TTimelineSite) =>
      `${siteEl?.site_id}_${timeToSeconds(siteEl?.start_time)}_${timeToSeconds(siteEl?.end_time)}`;

    const updateTime = async (startValue: number, endValue: number) => {
      const findItem = items?.find((el) => el.city_id === cityId);
      if (!!findItem) {
        const preparedItems = {
          ...findItem,
          vectors: findItem?.vectors?.map((vectorEl) => {
            if (getVectorStr(vectorEl) === getVectorStr(vector)) {
              return {
                ...vectorEl,
                sites: vectorEl?.sites?.map((siteEl) => {
                  if (
                    siteEl?.site_id === item?.site_id &&
                    siteEl?.start_time === item?.start_time &&
                    siteEl?.end_time === item?.end_time
                  ) {
                    return {
                      ...siteEl,
                      creator: username,
                      start_time:
                        convertToShortTime(timeToSeconds(siteEl.start_time) + startValue) ||
                        "00:00",
                      end_time:
                        timeToSeconds(siteEl.end_time) + endValue >= 86400
                          ? "23:59"
                          : convertToShortTime(timeToSeconds(siteEl.end_time) + endValue),
                    };
                  } else {
                    return siteEl;
                  }
                }),
              };
            } else {
              return vectorEl;
            }
          }),
        };
        if (!!activeTemplate) {
          setTemplateData(items?.map((city) => (city.city_id === cityId ? preparedItems : city)));
        } else {
          await setTimelineByCentrifugo(preparedItems, cityId);
        }
      }
      setPopup(false);
    };

    const updateInfinity = async () => {
      const payload = [
        {
          ...item,
          city_id: cityId,
          currency_from_id: vector.currency_from_id,
          currency_to_id: vector.currency_to_id,
          is_infinity: !item?.is_infinity,
        },
      ];
      const findItem = items?.find((el) => el.city_id === cityId);
      const preparedItems = {
        ...findItem,
        vectors: findItem?.vectors?.map((vectorEl) =>
          getVectorStr(vectorEl) === getVectorStr(vector)
            ? {
                ...vectorEl,
                sites: vectorEl?.sites?.map((siteEl) =>
                  getSiteStr(siteEl) === getSiteStr(item)
                    ? {
                        ...siteEl,
                        is_infinity: !siteEl?.is_infinity,
                      }
                    : siteEl
                ),
              }
            : vectorEl
        ),
      };
      if (!!findItem) {
        if (!!activeTemplate) {
          setTemplateData(items?.map((city) => (city.city_id === cityId ? preparedItems : city)));
        } else {
          const { response } = await fetchRequest(clusterApi.updateTimelineInfinity(payload), {
            request: "Сохранение расписания",
          });
          if (response) {
            await setTimelineByCentrifugo(preparedItems, cityId);
          }
        }
      }
    };

    const updateLock = async (payload: TTimelineUpdateLock) => {
      const findItem = items?.find((el) => el.city_id === cityId);
      if (!!findItem) {
        const preparedItems = {
          ...findItem,
          vectors: findItem?.vectors?.map((vectorEl) =>
            getVectorStr(vectorEl) === getVectorStr(vector)
              ? {
                  ...vectorEl,
                  sites: vectorEl?.sites?.map((siteEl) =>
                    getSiteStr(siteEl) === getSiteStr(item)
                      ? {
                          ...siteEl,
                          is_locked: !siteEl?.is_locked,
                        }
                      : siteEl
                  ),
                }
              : vectorEl
          ),
        };
        if (!!findItem) {
          if (!!activeTemplate) {
            setTemplateData(items?.map((city) => (city.city_id === cityId ? preparedItems : city)));
          } else {
            const { response } = await fetchRequest(clusterApi.updateClusterSchedule([payload]), {
              request: "Защита от удаления",
            });
            if (response) {
              await setTimelineByCentrifugo(preparedItems, cityId);
            }
          }
        }
      }
    };

    const updatePercent = async (percent: number) => {
      const payload = {
        city_id: cityId,
        currency_from_id: vector?.currency_from_id,
        currency_to_id: vector?.currency_to_id,
        percent: percent,
        percent_old: vector?.percent,
        sites: [item?.id],
      };
      const findItem = items?.find((el) => el.city_id === cityId);
      if (!!findItem) {
        const isNewVector = !findItem?.vectors?.find(
          (el) =>
            getVectorStr(el) === `${vector?.currency_from_id}_${vector?.currency_to_id}_${percent}`
        );

        const preparedVectors = findItem?.vectors?.map((vectorEl) => {
          if (getVectorStr(vectorEl) === getVectorStr(vector)) {
            return {
              ...vectorEl,
              sites: vectorEl?.sites?.filter((siteEl) => siteEl?.id !== item?.id),
            };
          } else if (
            getVectorStr(vectorEl) ===
            `${vector?.currency_from_id}_${vector?.currency_to_id}_${percent}`
          ) {
            return {
              ...vectorEl,
              sites: [...vectorEl.sites, item],
            };
          } else {
            return vectorEl;
          }
        });

        const preparedItems = {
          ...findItem,
          vectors: isNewVector
            ? [
                ...preparedVectors,
                {
                  ...vector,
                  percent: percent,
                  sites: [item],
                },
              ]
                ?.sort((a, b) => (a.percent < b.percent ? 1 : -1))
                ?.sort((a, b) =>
                  `${a.currency_from_id}_${a.currency_to_id}` >
                  `${b.currency_from_id}_${b.currency_to_id}`
                    ? 1
                    : -1
                )
            : preparedVectors,
        };

        if (!!activeTemplate) {
          setTemplateData(items?.map((city) => (city.city_id === cityId ? preparedItems : city)));
        } else {
          const { response } = await fetchRequest(clusterApi.updateTimelinePercent([payload]), {
            request: "Обновление процента",
          });
          if (response) {
            await setTimelineByCentrifugo(preparedItems, cityId);
            dispatch(
              setNotific({
                type: "success",
                message: "Изменения успешно сохранены",
                request: "Обновление процента",
              })
            );
          }
        }
      }
      setPopup(false);
    };

    return (
      <div
        className={`${isBest && !site?.is_on_best ? "hidden" : "block"} ${
          isAdded !== "" && !!item?.id ? "grayscale" : ""
        } absolute h-[28px] grid grid-cols-[8px_1fr_8px] min-w-[10px]`}
        style={{
          left: `${start}px`,
          width: `${interval}px`,
        }}>
        <button
          type="button"
          style={{ backgroundColor: colors[id] }}
          className={`relative text-white min-w-8 text-[8px] ${
            "creator" in item && item?.creator !== username ? "grayscale opacity-30" : ""
          }`}
          onClick={async () => await updateTime(expand ? -(step * 60) : step * 60, 0)}>
          <div className={`${expand ? "rotate-90" : "-rotate-90"}`}>{`\u25BC`}</div>
        </button>
        <div
          className={`flex w-full h-full items-center justify-center gap-4 overflow-hidden ${
            "creator" in item && item?.creator !== username ? "grayscale opacity-30" : ""
          }`}
          style={{
            backgroundColor: `${colors[id]}77`,
          }}>
          <div className={`font-bold mx-2 relative`}>
            <button
              type="button"
              className={`${
                item?.is_infinity ? "bg-[#40AEF0]" : "bg-[#F7931A]"
              } flex items-center justify-center rounded-full h-[16px] w-[16px] relative`}
              onClick={updateInfinity}>
              <img
                src={item?.is_infinity ? infinity : pumpkin}
                alt={item?.is_infinity ? "сохранено" : "тыква"}
                width={13}
                height={13}
              />
            </button>
          </div>
          <div
            className={`whitespace-pre cursor-pointer px-4 ${"creator" in item ? "font-bold" : ""}`}
            onClick={() => setPopup(true)}>
            {site?.site_name || item?.site_id}
          </div>
          <button
            type="button"
            className="rounded-sm w-[18px] h-[18px] min-w-[18px] ml-8 duration-300 bg-glass-md hover:bg-glass"
            onClick={() => setIsAdded(item.site_id?.toString())}>
            <img src={copy} alt="скопировать" width={16} height={16} />
          </button>
        </div>
        <button
          type="button"
          style={{ backgroundColor: colors[id] }}
          className={`relative text-white min-w-8 text-[8px] ${
            "creator" in item && item?.creator !== username ? "grayscale opacity-30" : ""
          }`}
          onClick={async () => await updateTime(0, expand ? step * 60 : -(step * 60))}>
          <div className={`${expand ? "-rotate-90" : "rotate-90"}`}>{`\u25BC`}</div>
        </button>
        {"creator" in item && (
          <div className={`absolute -top-8 -right-4 text-base font-bold`}>
            <Tooltip
              content={item?.creator === username ? "Вы" : item?.creator || ""}
              direction="bottom">
              <div
                className={`relative h-[18px] w-[18px] rounded-full border z-10 border-stale ${
                  username === item?.creator ? "bg-font" : "bg-bg"
                }`}>
                <Avatar username={item?.creator} />
              </div>
            </Tooltip>
          </div>
        )}
        {isPopup && (
          <Popup closeModal={() => setPopup(false)}>
            <TimeLineItemForm
              cityId={cityId}
              vector={vector}
              site={site}
              item={item}
              deleteIt={deleteIt}
              updatePercent={updatePercent}
              updateLock={updateLock}
              updateInfinity={updateInfinity}
              updateTime={updateTime}
              setPopup={setPopup}
              activeTemplate={activeTemplate}
            />
          </Popup>
        )}
      </div>
    );
  }
);
