import { useEffect, useMemo, useState } from "react";
import ReactSelect, { createFilter } from "react-select";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  selectAllDirections,
  selectAllSites,
  selectCurFrom,
  selectSite,
  setSelectedSite,
} from "src/store/directionsReducer";
import { setNotific } from "src/store/mainReducer";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import { LS } from "src/api/constants";
import directionsApi from "src/api/directions";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { StepsGroup } from "./components/StepsGroup";

export type TStep = {
  vector: string;
  min_step: number;
  max_step: number;
  step?: number;
};

export const Step = () => {
  const dispatch = useDispatch();
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const isAccess = userAccess?.includes("step_post");
  const methods = useForm({ shouldUnregister: true, mode: "onChange" });
  const [searchParams, setSearchParams] = useSearchParams();
  const [dirtyVectors, setDirtyVectors] = useState<Array<string>>([]);
  const [isGood, setIsGood] = useState<boolean>(true);
  const [firstUpdate, setFirstUpdate] = useState(true);
  const selectedSite = useSelector(selectSite);
  const curFrom = useSelector(selectCurFrom);
  const globalsDirections = useSelector(selectAllDirections);
  const globalsSites = useSelector(selectAllSites);
  const [steps, setSteps] = useState<Array<TStep>>([]);
  const globalSteps = useMemo(
    () =>
      globalsDirections?.map((el) => ({
        vector: `${el.currency_from}-to-${el.currency_to}`,
        min_step: 0,
        max_step: 0,
      })),
    [globalsDirections]
  );
  const sitesOptions = useMemo(
    () =>
      globalsSites
        ?.filter((el) => el?.is_changable)
        ?.map((el, index) => ({
          label: el.site_name,
          value: { site_id: el.site_id, index: index },
        })),
    [globalsSites]
  );
  const [site, setSite] = useState<{ label: string; value: { site_id: number; index: number } }>({
    label: "",
    value: { site_id: -1, index: -1 },
  });
  const [isAllSites, setIsAllSites] = useState<boolean>(false);
  const storeTrigger = !!selectedSite?.site_id;

  const allGroups = useMemo(
    () => [...new Set(steps?.map((el) => el.vector?.split("-to-")[0]))],
    [steps]
  );

  const allDirectionsGroup = useMemo(
    () => [...new Set(globalsDirections?.map((el) => el.currency_from))],
    [globalsDirections]
  );
  const cryptoGroups = useMemo(
    () => allGroups?.filter((el) => el.substring(0, 4) !== "CASH"),
    [allGroups]
  );
  const cashGroups = useMemo(
    () => allGroups?.filter((el) => el.substring(0, 4) === "CASH"),
    [allGroups]
  );
  const groups = useMemo(() => cryptoGroups?.concat(cashGroups), [cryptoGroups, cashGroups]);

  const updateS = (e) => {
    dispatch(setSelectedSite({ site_id: e?.value?.site_id, site_name: e?.label }));
    setSite(e);
    searchParams.set("site", e?.label);
    setSearchParams(searchParams);
    setIsAllSites(false);
  };

  const fetchSteps = async () => {
    const { response, error } = await fetchRequest(directionsApi.getSteps(site?.value?.site_id), {
      request: "Получение шагов",
    });
    if (response) {
      setSteps(response);
      setIsGood(true);
    }
    if (error) {
      setIsGood(false);
    }
  };

  const onSubmit = async () => {
    if (!dirtyVectors?.length) {
      dispatch(
        setNotific({
          type: "success",
          message: "Шаги успешно обновлены",
          request: "Обновление шагов",
        })
      );
      return;
    }

    if (!isAllSites) {
      const payload = dirtyVectors?.map((item) => ({
        vector: item,
        min_step: +methods.watch(`${item}.min_step`),
        max_step: +methods.watch(`${item}.max_step`),
      }));
      const { response } = await fetchRequest(
        directionsApi.setSteps(site?.value?.site_id, payload),
        { request: "Шаги" }
      );
      if (response) {
        dispatch(
          setNotific({
            type: "success",
            message: "Шаги успешно обновлены",
            request: "Обновление шагов",
          })
        );
        setDirtyVectors([]);
        methods.reset();
        await fetchSteps();
      }
    } else {
      const payload = dirtyVectors
        ?.map((item) => ({
          vector: item,
          min_step: +methods.watch(`${item}.min_step`),
          max_step: +methods.watch(`${item}.max_step`),
        }))
        ?.filter((el) => el.min_step !== 0 || el.max_step !== 0);
      const { response } = await fetchRequest(directionsApi.setGlobalSteps(payload), {
        request: "Все шаги",
      });
      if (response) {
        dispatch(
          setNotific({
            type: "success",
            message: "Шаги успешно обновлены",
            request: "Обновление всех шагов",
          })
        );
      }
    }
  };

  useEffect(() => {
    if (site?.value?.site_id >= 0) {
      fetchSteps();
    }
  }, [site?.value?.site_id]);

  useEffect(() => {
    if (storeTrigger && firstUpdate) {
      setFirstUpdate(false);
      searchParams.set("site", selectedSite?.site_name);
      setSearchParams(searchParams);
      setSite({
        label: selectedSite?.site_name,
        value: {
          site_id: selectedSite?.site_id,
          index: globalsSites?.findIndex((el) => el.site_id === selectedSite?.site_id),
        },
      });
    }
  }, [selectedSite?.site_id, firstUpdate]);

  useEffect(() => {
    if (steps?.length > 0 || isAllSites) {
      setDirtyVectors([]);
      methods.reset();
    }
  }, [steps, isAllSites]);

  useEffect(() => {
    if (!!searchParams?.get("site") && !!sitesOptions?.length && !isAllSites) {
      setSite(sitesOptions?.find((el) => el.label === searchParams?.get("site")));
    }
  }, [sitesOptions]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="flex lg:grid lg:grid-cols-[1fr_2fr] items-center lg:items-start flex-col lg:flex-row w-[calc(100% - 80px)] gap-[20px]">
        <div className="flex flex-col mr-[20px] sm:mr-0 lg:items-end">
          <button
            className={`w-[290px] sm:w-[300px] border border-lightGray rounded-[12px] mb-2 px-[32px] py-[6px] sm:py-[8px] text sm:text-mdl hover:bg-grayDark hover:text-white hover:border-grayDark ${
              isAllSites ? "bg-grayDark text-white border-grayDark" : "bg-white text-font"
            }`}
            type="button"
            onClick={() => {
              setIsAllSites(true);
              searchParams.delete("site");
              setSearchParams(searchParams);
            }}>
            Все сайты
          </button>
          <button
            className={`w-[290px] sm:w-[300px] border border-lightGray rounded-[12px] mt-8 mb-2 px-[32px] py-[6px] sm:py-[8px] text sm:text-mdl hover:bg-grayDark hover:text-white hover:border-grayDark ${
              !isAllSites ? "bg-grayDark text-white border-grayDark" : "bg-white text-font"
            }`}
            type="button"
            onClick={() => setIsAllSites(false)}>
            Выбрать сайт
          </button>
          {!isAllSites && (
            <ReactSelect
              className="w-[290px] sm:w-[300px] text font-bold mt-8"
              classNamePrefix="Select"
              options={sitesOptions}
              filterOption={createFilter(selectFilter)}
              placeholder={!!site?.label?.length ? site.label : "Сайт..."}
              value={!!site?.label?.length ? site : ""}
              onChange={updateS}
            />
          )}
        </div>
        <div className="mr-[20px] sm:mr-0">
          <div className="flex flex-col items-center w-fit">
            {!isAllSites && !!steps?.length && (
              <div className="table w-[290px] sm:w-fit lg:mx-0 my-5 lg:mt-0">
                <div
                  className={`table-header hidden sm:grid -mb-4 px-[20px] ${
                    !!steps?.[0] && "step" in steps[0]
                      ? "grid-cols-[2fr_1fr_1fr_1fr]"
                      : "grid-cols-[2fr_1fr_1fr]"
                  }`}>
                  {headers?.map((el, index) => (
                    <div key={index}>{el.toUpperCase()}</div>
                  ))}
                  {!!steps?.[0] && "step" in steps[0] ? <div>ШАГ</div> : null}
                </div>
                {groups?.map((el, index) => (
                  <StepsGroup
                    key={index}
                    title={el}
                    items={steps}
                    dirtyVectors={dirtyVectors}
                    setDirtyVectors={setDirtyVectors}
                    isAccess={isAccess}
                    isRequired={true}
                    curFrom={curFrom}
                  />
                ))}
              </div>
            )}
            {isAllSites && !!globalSteps?.length && (
              <div className="table w-[290px] sm:w-fit lg:mx-0 my-5 lg:mt-0">
                <div className="table-header hidden sm:grid grid-cols-[2fr_1fr_1fr] -mb-4 px-[20px]">
                  {headers?.map((el, index) => (
                    <div key={index}>{el.toUpperCase()}</div>
                  ))}
                </div>
                {allDirectionsGroup?.map((el, index) => (
                  <StepsGroup
                    key={index}
                    title={el}
                    items={globalSteps}
                    dirtyVectors={dirtyVectors}
                    setDirtyVectors={setDirtyVectors}
                    isAccess={isAccess}
                    isRequired={false}
                    curFrom={curFrom}
                  />
                ))}
              </div>
            )}
            {isAccess && (isAllSites || (!isAllSites && !!steps?.length)) && (
              <button disabled={!dirtyVectors?.length} className="prime-button">
                Обновить
              </button>
            )}
          </div>
        </div>
      </form>
      {!isGood &&
        ((!isAllSites && !groups?.length) || (isAllSites && !allDirectionsGroup?.length)) && (
          <Emptiness message={"Ошибка сервиса"} />
        )}
      <div className="h-[100px]" />
    </FormProvider>
  );
};

export const headers = ["Вектор", "Min Шаг", "Max Шаг"];
