import { ReactNode, useEffect, useState } from "react";

type TProps = {
  label: string;
  defaultValue?: string | number;
  value: string | ReactNode;
  edited?: boolean;
  updFn?: (fieldValue: string | number) => void;
  placeholder?: string;
  isColumn?: boolean;
};

export const FieldRow = ({
  label,
  value,
  defaultValue,
  edited,
  updFn,
  placeholder,
  isColumn,
}: TProps) => {
  const [isEdit, setEdit] = useState(false);
  const [fieldValue, setFieldValue] = useState(defaultValue || "");
  const disabled = !fieldValue?.toString().length;

  useEffect(() => {
    setEdit(false);
  }, [defaultValue]);

  return (
    <div className={`${isColumn ? "flex flex-col" : "grid grid-cols-[110px_1fr] gap-8 "}`}>
      <span className="text-lightFont">{label}</span>
      {edited ? (
        isEdit ? (
          <div className="relative -left-8 flex items-center gap-2">
            <input
              className={`min-w-[100px] max-h-[18px] border border-[#282c3433] rounded-sm py-0 px-8`}
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              onKeyDown={async (e) => {
                if (e.keyCode === 13 && !!e.currentTarget.value?.length && !!updFn) {
                  await updFn(fieldValue);
                  setEdit(false);
                }
              }}
            />
            <button
              className="w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#989CA4] text-base hover:brightness-90"
              onClick={() => setEdit(false)}>
              <span className={`relative top-[-1px] rotate-45 left-[1px]`}>+</span>
            </button>
            {!!updFn && (
              <button
                className={`w-[16px] min-w-[16px] h-[16px] bg-[#62F5B3] flex justify-center items-center rounded-sm text-white text-base hover:brightness-110 ${
                  disabled ? "opacity-20" : ""
                }`}
                disabled={disabled}
                onClick={async () => {
                  await updFn(fieldValue);
                  setEdit(false);
                }}>
                <span className={`relative top-[-0.5px] font-bold text-xs`}>✓</span>
              </button>
            )}
          </div>
        ) : (
          <button type="button" className="text-left" onClick={() => setEdit(true)}>
            {value || <span className="text-lightGray">{placeholder || ""}</span>}
          </button>
        )
      ) : (
        <div>{value}</div>
      )}
    </div>
  );
};
