import { useMemo, useState } from "react";
import ReactSelect, { createFilter } from "react-select";
import { useSelector } from "react-redux";
import { selectFilter } from "src/helpers/formHelpers";
import {
  selectAllCities,
  selectAllCurrencies,
  selectAllSites,
  TSelect,
} from "src/store/directionsReducer";

export const VectorsEditor = ({
  savedSites,
  setSavedSites,
  savedVectors,
  setSavedVectors,
  isAddVisible,
  access,
  isFull,
}) => {
  const globalsSites = useSelector(selectAllSites);
  const globalsCities = useSelector(selectAllCities);
  const globalCurrencies = useSelector(selectAllCurrencies);
  const sitesOptions = useMemo(
    () => globalsSites?.map((el) => ({ label: el.site_name, value: el.site_id })),
    [globalsSites]
  );
  const citiesOptions = useMemo(
    () =>
      globalsCities
        ?.map((el) => ({ label: el.city_name, value: el.city_code }))
        ?.filter((el) => el.label !== "all"),
    [globalsCities]
  );
  const currenciesOptions = useMemo(
    () => globalCurrencies?.map((el) => ({ label: el.currency_name, value: el.currency_name })),
    [globalCurrencies]
  );
  const [currentSite, setCurrentSite] = useState<TSelect | null>(null);
  const [currentVector, setCurrentVector] = useState({ from: "", to: "", city: "" });
  const disabledVector =
    currentVector.from === "" ||
    currentVector.to === "" ||
    currentVector.city === "" ||
    savedVectors?.includes(`${currentVector.from}-to-${currentVector.to}-${currentVector.city}`);

  const saveSite = (site) => {
    if (!savedSites?.includes(site)) {
      setSavedSites([...savedSites, site]);
      setCurrentSite(null);
    }
  };

  const saveVector = () => {
    if (
      !savedVectors?.includes(`${currentVector.from}-to-${currentVector.to}-${currentVector.city}`)
    ) {
      setSavedVectors([
        ...savedVectors,
        `${currentVector.from}-to-${currentVector.to}-${currentVector.city}`,
      ]);
    }
  };

  return (
    <div className={`flex flex-col duration-300 ${isAddVisible ? "gap-8" : "gap-4"}`}>
      {access && (
        <div
          className={`flex gap-4 duration-300 ${
            isAddVisible ? "max-h-[1000px]" : "max-h-0 overflow-hidden"
          } ${isFull ? "" : "flex-col"}`}>
          <div className="flex gap-4 w-full items-center">
            <ReactSelect
              options={sitesOptions}
              value={currentSite}
              placeholder="сайт"
              classNamePrefix="SquareSelect"
              className="w-full outline-none"
              filterOption={createFilter(selectFilter)}
              onChange={(e) => setCurrentSite(e)}
            />
            <button
              type="button"
              style={{ background: "#3BC57A" }}
              className={`h-[23px] w-[23px] min-w-[23px] duration-500 rounded-sm justify-center items-center flex text-white font-extrabold ${
                !currentSite ? "grayscale opacity-30" : ""
              }`}
              disabled={!currentSite}
              onClick={() => {
                if (currentSite) {
                  saveSite(currentSite?.label);
                }
              }}>
              <div>{`\u2713`}</div>
            </button>
          </div>
          <ReactSelect
            options={currenciesOptions}
            placeholder="from"
            classNamePrefix="SquareSelect"
            className="w-full outline-none"
            filterOption={createFilter(selectFilter)}
            onChange={(e) => setCurrentVector({ ...currentVector, from: e.label })}
          />
          <ReactSelect
            options={currenciesOptions}
            placeholder="to"
            classNamePrefix="SquareSelect"
            className="w-full outline-none"
            filterOption={createFilter(selectFilter)}
            onChange={(e) => setCurrentVector({ ...currentVector, to: e.label })}
          />
          <div className="flex gap-4 w-full items-center">
            <ReactSelect
              options={citiesOptions}
              placeholder="город"
              classNamePrefix="SquareSelect"
              className="w-full outline-none"
              filterOption={createFilter(selectFilter)}
              onChange={(e) => setCurrentVector({ ...currentVector, city: e.value })}
            />
            <button
              type="button"
              style={{ background: "#3BC57A" }}
              className={`h-[23px] w-[23px] min-w-[23px] duration-500 rounded-sm justify-center items-center flex text-white font-extrabold ${
                disabledVector ? "grayscale opacity-30" : ""
              }`}
              disabled={disabledVector}
              onClick={() => saveVector()}>
              <div>{`\u2713`}</div>
            </button>
          </div>
        </div>
      )}
      {(!!savedSites?.length || !!savedVectors?.length) && (
        <div className="flex gap-4 items-center flex-wrap">
          {savedSites?.map((el) => (
            <div
              key={el}
              className="flex gap-4 items-center whitespace-pre rounded-sm bg-[#FCC815] text-[10px] text-[#FFF] pl-4 pr-8 font-medium h-[15px]">
              {el}
              {access && isAddVisible && (
                <button
                  type="button"
                  className="relative left-2 rotate-45 text-[12px]"
                  onClick={() => {
                    setSavedSites(savedSites?.filter((site) => site !== el));
                  }}>
                  +
                </button>
              )}
            </div>
          ))}
          {savedVectors?.map((el) => (
            <div
              key={el}
              className="flex gap-4 items-center whitespace-pre rounded-sm bg-[#FCC815] text-[10px] text-[#FFF] pl-4 pr-8 font-medium h-[15px]">
              {el}
              {access && isAddVisible && (
                <button
                  type="button"
                  className="relative left-2 rotate-45 text-[12px]"
                  onClick={() => {
                    setSavedVectors(savedVectors?.filter((vector) => vector !== el));
                  }}>
                  +
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
