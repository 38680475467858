import { Dispatch, FC, SetStateAction, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { fetchRequest } from "src/helpers/fetchRequest";
import addApi from "src/api/add";
import { Field } from "src/components/FormElements/Field";
import { Tumbler } from "src/components/FormElements/Tumbler";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";

type Diver = "postgres" | "mysql";

export const CreateSite: FC<{
  setActiveTab: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSiteMessage: Dispatch<SetStateAction<string>>;
  setSiteStatus: Dispatch<SetStateAction<boolean | null>>;
}> = ({ setActiveTab, setIsLoading, setSiteMessage, setSiteStatus }) => {
  const methods = useForm();
  const errors = Object.keys(methods.formState.errors);
  const dirtyFields = Object.keys(methods.formState.dirtyFields);
  const [drivers, setDrivers] = useState<Diver[]>([]);
  const disabled = !!errors.length || !dirtyFields.length || !drivers.length;

  const submit = async (e: FieldValues) => {
    setIsLoading(true);

    const dataCredos = drivers.map((driver) => ({
      username: e[`${driver}_username`],
      name: e[`${driver}_name`],
      password: e[`${driver}_password`],
      port: +e[`${driver}_port`],
      host: e[`${driver}_host`],
      prefix: e[`${driver}_prefix`],
      driver,
      create_database: driver === "mysql" ? e[`create_database`] || false : false,
    }));

    const data = !e.site_id?.length
      ? {
          site_name: e.site_name,
          database_credos: dataCredos,
          create_vectors: e.create_vectors,
        }
      : {
          site_id: +e.site_id,
          site_name: e.site_name,
          database_credos: dataCredos,
          create_vectors: e.create_vectors,
        };

    const { response, error } = await fetchRequest(addApi.addSite(data), {
      request: "Создание Обменника",
      errorMessage: "Нет ответа от сервиса",
    });
    if (response) {
      setSiteMessage("Успешно");
      setSiteStatus(true);
      methods.reset();
    }
    if (error) {
      setSiteMessage("Не удалось добавить обменник");
      setSiteStatus(false);
    }
    setTimeout(() => {
      setSiteStatus(null);
      setSiteMessage("");
      setIsLoading(false);
      if (response) {
        setActiveTab(null);
      }
    }, 4600);
  };

  const handleSelectDriver = (driver: Diver) => {
    const newDrivers = [...drivers];
    const index = newDrivers.findIndex((item) => item === driver);
    if (~index) {
      newDrivers.splice(index, 1);
    } else {
      newDrivers.push(driver);
    }
    setDrivers(newDrivers);
  };

  return (
    <div
      className={`w-full sm:w-[600px] flex flex-col gap-8 text-xs font-light px-[10px] mb-[20px]`}>
      <FormProvider {...methods}>
        <form
          className="pt-[10px] pl-[30px] pr-[6px] flex flex-col gap-[20px]"
          onSubmit={methods.handleSubmit(submit)}>
          <div className="grid sm:grid-cols-2 gap-[10px] text-left">
            <div className="flex flex-col gap-4">
              <Field title="Название" name="site_name" defaultValue={""} required />
              <Field
                title="BEST ID"
                name="site_id"
                defaultValue={""}
                isNumber
                isPositive
                required
              />
              <div className="h-8" />
            </div>
            <div className="flex flex-col gap-4">
              <div className="w-full flex gap-4 mb-[5px] pl-[38px] font-semibold">
                <button
                  type="button"
                  className={`rounded-sm border px-8 hover:opacity-80 ${
                    drivers.includes("mysql")
                      ? "bg-font text-white border-font"
                      : "bg-light text-lightFont border-lightGray"
                  }`}
                  onClick={() => handleSelectDriver("mysql")}>
                  MySQL
                </button>
                <button
                  type="button"
                  className={`rounded-sm border px-8 hover:opacity-80 ${
                    drivers.includes("postgres")
                      ? "bg-font text-white border-font"
                      : "bg-light text-lightFont border-lightGray"
                  }`}
                  onClick={() => handleSelectDriver("postgres")}>
                  POSTGRES
                </button>
              </div>
              {drivers.includes("mysql") && (
                <Tumbler title="создать DB MySQL" name="create_database" defaultValue={false} />
              )}
            </div>
          </div>
          <div className="flex gap-[10px] flex-wrap justify-center">
            {drivers.map((driver) => (
              <div key={driver} className="flex flex-col items-center">
                <p className="font-medium mb-[5px]">{driver}</p>
                <Field title="DB name" name={`${driver}_name`} defaultValue={""} required />
                <Field title="login" name={`${driver}_username`} defaultValue={""} required />
                <Field title="password" name={`${driver}_password`} defaultValue={""} required />
                <Field title="host" name={`${driver}_host`} defaultValue={""} required />
                <Field
                  title="port"
                  name={`${driver}_port`}
                  defaultValue={""}
                  required
                  isNumber
                  isPositive
                />
                <Field title="prefix" name={`${driver}_prefix`} defaultValue={""} required />
              </div>
            ))}
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <label className="flex items-center justify-start my-[8px] gap-[10px] cursor-pointer w-fit">
                  <input
                    type="checkbox"
                    className="border border-lightGray rounded outline-none h-[16px] w-[16px]"
                    {...methods.register("create_vectors")}
                  />
                  <div className="text-[14px] leading-[1]">Автоматически создать вектора</div>
                </label>
              </TooltipTrigger>
              <TooltipContent className="text-center max-w-[200px]">
                Если опция выбрана, будут автоматически созданы все возможные векторы
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <button
            disabled={disabled}
            className={`bg-light self-end px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
              disabled ? "opacity-0" : ""
            }`}>
            Создать обменник
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
