import { Dispatch, SetStateAction } from "react";
import { Popup } from "src/components/Popup/Popup";

type TProps = {
  setIsSaveConfirm: Dispatch<SetStateAction<boolean>>;
  isTemplate: boolean;
  updateTimeline: () => Promise<void>;
};

export const SaveConfirm = ({ setIsSaveConfirm, isTemplate, updateTimeline }: TProps) => {
  return (
    <Popup closeModal={() => setIsSaveConfirm(false)}>
      <div className={"max-w-[300px] flex flex-col gap-[20px] text-center text-sm"}>
        <div>
          Подтвердите, что хотите <b>сохранить</b> изменения {isTemplate ? "в шаблоне" : ""}
        </div>
        <button
          type="button"
          className="w-fit mx-auto prime-button-sm bg-font rounded-sm"
          onClick={async () => {
            setIsSaveConfirm(false);
            await updateTimeline();
          }}>
          Сохранить
        </button>
      </div>
    </Popup>
  );
};
