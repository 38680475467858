import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { LS } from "src/api/constants";
import { setPinned } from "src/store/commonReducer";
import pin from "src/assets/images/pin.svg";

export const DropListItem = ({ item, page, activeColor, pinned }) => {
  const dispatch = useDispatch();
  const [isPinVisible, setPinVisible] = useState(false);
  const isPinned = useMemo(() => pinned?.includes(item.path) || false, [pinned]);

  return (
    <div
      className={`flex justify-between hover:opacity-100 text-xs pr-4 ${
        item?.path === page ? "opacity-100" : "opacity-60"
      } ${item.access ? "block" : "hidden"}`}
      onMouseMove={() => setPinVisible(true)}
      onMouseLeave={() => setPinVisible(false)}>
      <NavLink to={item?.path} style={{ color: activeColor }}>
        {item.title}
      </NavLink>
      <button
        type="button"
        className={`flex justify-center items-center rounded-sm p-4 min-w-[20px] hover:bg-bg ${
          isPinned ? "bg-bg" : ""
        }`}
        onClick={() => {
          if (isPinned) {
            localStorage.setItem(LS.PIN, pinned?.filter((el) => el !== item.path)?.join(", "));
            dispatch(setPinned(pinned?.filter((el) => el !== item.path)));
          } else {
            localStorage.setItem(LS.PIN, [...pinned, item.path]?.join(", "));
            dispatch(setPinned([...pinned, item.path]));
          }
        }}>
        <img
          src={pin}
          alt="pin"
          width={12}
          height={12}
          className={`brightness-50 duration-300 ${isPinVisible || isPinned ? "" : "opacity-0"}`}
        />
      </button>
    </div>
  );
};
