import { Dispatch, SetStateAction, useState } from "react";
import { fetchRequest } from "src/helpers/fetchRequest";
import {
  TCityReport,
  TTurnOffReportVectors,
  TUpdateReportClients,
  TUpdateReportPlanPercent,
} from "../models";
import statsApi from "src/api/stats";
import { setNotific } from "src/store/mainReducer";
import { useAppDispatch } from "src/store/store";
import { EditField } from "./EditField";
import { Popup } from "src/components/Popup/Popup";
import { Button } from "src/shadcn/ui/ui/button";
import bin from "src/assets/images/bin.svg";

type TProps = {
  item: TCityReport;
  ratio_id: number;
  isAccess: boolean;
  selectedPairs: TTurnOffReportVectors[];
  setSelectedPairs: Dispatch<SetStateAction<TTurnOffReportVectors[]>>;
};

export const StatsReport = ({
  item,
  ratio_id,
  isAccess,
  selectedPairs,
  setSelectedPairs,
}: TProps) => {
  const dispatch = useAppDispatch();
  const [isPercentLoading, setIsPercentLoading] = useState(false);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteConfirm, setDeleteConfirm] = useState(false);

  const updatePercent = async (percent: string, _: "percent") => {
    setIsPercentLoading(true);
    const payload: TUpdateReportPlanPercent = {
      city_name: item.city_name,
      ratio_id: ratio_id,
      plan_percent: +percent,
    };
    const { response } = await fetchRequest(statsApi.setPlanPercents(payload), {
      request: `Процент плана в г. ${item.city_name}`,
    });
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: "Успешно обновлен.  Данные появятся в новом отчете",
          request: `Процент плана в г. ${item.city_name}`,
        })
      );
    }
    setIsPercentLoading(false);
  };

  const updateClientsCount = async (count: string, attr: "clients_30" | "clients_12h") => {
    setIsClientsLoading(true);
    const payload: TUpdateReportClients =
      attr === "clients_30"
        ? {
            city_name: item.city_name,
            clients_count_30: +count,
          }
        : {
            city_name: item.city_name,
            clients_count_12h: +count,
          };
    const { response } = await fetchRequest(statsApi.setClients(payload), {
      request: `Клиенты в г. ${item.city_name}`,
    });
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: "Успешно обновлен.  Данные появятся в новом отчете",
          request: `Клиенты в г. ${item.city_name}`,
        })
      );
    }
    setIsClientsLoading(false);
  };

  const deleteCity = async () => {
    setDeleteLoading(true);
    const { response } = await fetchRequest(
      statsApi.turnOffVectors([{ city_name: item.city_name }]),
      {
        request: "Удаление векторов",
      }
    );
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: "Успешно удалены. Данные появятся в новом отчете",
          request: "Удаление векторов",
        })
      );
      setSelectedPairs(
        selectedPairs?.filter(
          (el) =>
            !response.some(
              (resEl: TTurnOffReportVectors) => resEl.id === el.id && resEl.in_report === false
            )
        )
      );
    }
    setDeleteLoading(true);
    setDeleteConfirm(false);
  };

  return (
    <div className="divide-y divide-input">
      <div className="grid grid-cols-6 *:py-3 font-semibold *:content-center divide-x divide-input *:h-full *:content-center">
        <div className="w-full flex justify-between items-center px-8">
          <div className="text-left">{item.city_name}</div>
          {isAccess && (
            <Button
              variant="ghost"
              className="h-6 w-6 flex justify-center items-center aspect-square shrink-1 text-slate-500"
              onClick={() => setDeleteConfirm(true)}>
              <img src={bin} alt="удалить" width={15} height={15} />
            </Button>
          )}
        </div>
        <div
          className={`${
            +item.city_mean_percent_30 < item.plan_percent ? "bg-slate-800 text-white" : ""
          }`}>
          {item.city_mean_percent_30}
        </div>
        <div
          className={`${
            +item.city_mean_percent_12h < item.plan_percent ? "bg-slate-800 text-white" : ""
          }`}>
          {item.city_mean_percent_12h}
        </div>
        <EditField
          defaultValue={item?.plan_percent?.toString()}
          updateFn={updatePercent}
          attr="percent"
          isLoading={isPercentLoading}
          isAccess={isAccess}
        />
        <EditField
          defaultValue={item?.clients_count_30?.toString()}
          updateFn={updateClientsCount}
          attr="clients_30"
          isLoading={isClientsLoading}
          isAccess={isAccess}
        />
        <EditField
          defaultValue={item?.clients_count_12h?.toString()}
          updateFn={updateClientsCount}
          attr="clients_12h"
          isLoading={isClientsLoading}
          isAccess={isAccess}
        />
      </div>
      {item.vectors_reports?.map((vector, vector_index) => {
        return (
          <div
            key={`${item.city_id}_${vector_index}`}
            className="grid grid-cols-6 *:py-8 divide-x divide-input *:h-full *:content-center">
            <div className="text-left px-8">
              {vector.currency_from} - {vector.currency_to}
            </div>
            <div
              className={`${
                +vector.vector_mean_percent_30 < item.plan_percent ? "bg-slate-800 text-white" : ""
              }`}>
              {vector.vector_mean_percent_30}
            </div>
            <div
              className={`${
                +vector.vector_mean_percent_12h < item.plan_percent ? "bg-slate-800 text-white" : ""
              }`}>
              {vector.vector_mean_percent_12h}
            </div>
            <div />
            <div className="bg-sky-50" />
            <div className="bg-sky-50" />
          </div>
        );
      })}
      {isDeleteConfirm && (
        <Popup closeModal={() => setDeleteConfirm(false)}>
          <div className="flex flex-col gap-7 items-center max-w-[300px]">
            <div className="text-center">
              Вы уверены что хотите удалить из отчетов <b>все векторы</b> в г.{" "}
              <b>{item.city_name}</b>?
            </div>
            <Button
              variant="destructive"
              className="px-5"
              disabled={isDeleteLoading}
              onClick={deleteCity}>
              Удалить
            </Button>
          </div>
        </Popup>
      )}
    </div>
  );
};
