import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import ReactSelect, { createFilter } from "react-select";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import minMaxApi from "src/api/minMax";
import { selectAllCities, TSelect, TMinMaxException } from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { Field } from "src/components/FormElements/Field";
import { SadCat } from "src/components/UI/loader/SadCat";
import bin from "src/assets/images/bin.svg";

type TProps = {
  item: TMinMaxException | null;
  sheetId: number | null;
  fetchSheet: () => void;
  access: boolean;
};

export const ExceptionsBlock = ({ item, sheetId, fetchSheet, access }: TProps) => {
  const methods = useForm({ mode: "onChange" });
  const globalCities = useSelector(selectAllCities);
  const [currentCity, setCurrentCity] = useState<TSelect>();
  const [isError, setError] = useState(false);
  const citiesOptions = useMemo(
    () =>
      globalCities
        ?.filter((el) => el.city_code !== "all")
        ?.map((el) => ({ label: el.city_name, value: el.city_id })),
    [globalCities]
  );
  const dirtyFields = Object.keys(methods.formState.dirtyFields);
  const errors = Object.keys(methods.formState.errors);
  const disabled =
    (!item?.city_id && !currentCity) ||
    !!errors.length ||
    !dirtyFields.length ||
    (!!item && !dirtyFields?.map((el) => methods.watch(el) === item[el]).includes(false));
  const defaultCityName =
    globalCities?.find((el) => el.city_id === item?.city_id)?.city_name || "new";

  const updateException = async (e) => {
    if (+e.min_summ_office <= +e.max_summ_office && +e.min_summ_region <= +e.max_summ_region) {
      const payload = !!item
        ? [
            {
              sheet_id: sheetId,
              ...item,
              ...e,
            },
          ]
        : [
            {
              sheet_id: sheetId,
              city_id: currentCity?.value,
              ...e,
            },
          ];
      const { response } = await fetchRequest(minMaxApi.updateMinMaxException(payload), {
        request: "Обновление исключения",
      });
      if (response) {
        await fetchSheet();
      }
    } else {
      setError(true);
    }
  };

  const deleteException = async () => {
    const { response } = await fetchRequest(minMaxApi.deleteMinMaxException(item?.exception_id), {
      request: "Удаление исключения",
    });
    if (response) {
      await fetchSheet();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(updateException)}
        className="border-t border-lightGray text-xs flex flex-col gap-4 pt-8 mt-8 text-lightFont">
        {!!item?.city_id ? (
          <div className="flex justify-between">
            <div className="font-semibold text-sm text-font">{defaultCityName}</div>
            {access && (
              <button
                type="button"
                className={`relative top-2 w-[16px] h-[16px] hidden sm:block bg-[#EAEAF3] hover:bg-[#D7443E77] flex justify-self-end justify-center items-center rounded-sm duration-300`}
                onClick={deleteException}>
                <img src={bin} alt="удалить" width={15} height={15} />
              </button>
            )}
          </div>
        ) : (
          <ReactSelect
            classNamePrefix="SquareSelect"
            options={citiesOptions}
            filterOption={createFilter(selectFilter)}
            onChange={(e: TSelect) => setCurrentCity(e)}
          />
        )}
        <div className="flex flex-col gap-4 lg:gap-0 lg:grid lg:grid-cols-2 lg:divide-x divide-lightGray">
          <div className="lg:pr-8 flex flex-col gap-4">
            <div className="font-semibold">ОФИС</div>
            <Field
              title="MIN"
              name={`min_summ_office`}
              defaultValue={item?.min_summ_office}
              required
              disabled={!access}
              isNumber
              isPositive
            />
            <Field
              title="MAX"
              name={`max_summ_office`}
              defaultValue={item?.max_summ_office}
              required
              disabled={!access}
              isNumber
              isPositive
            />
            <Field
              title="RESERVE"
              name={`reserve_office`}
              defaultValue={item?.reserve_office}
              required
              disabled={!access}
              isNumber
              isPositive
            />
          </div>
          <div className="lg:pl-8 flex flex-col gap-4">
            <div className="font-semibold">РЕГИОН</div>
            <Field
              title="MIN"
              name={`min_summ_region`}
              defaultValue={item?.min_summ_region}
              required
              disabled={!access}
              isNumber
              isPositive
            />
            <Field
              title="MAX"
              name={`max_summ_region`}
              defaultValue={item?.max_summ_region}
              required
              disabled={!access}
              isNumber
              isPositive
            />
            <Field
              title="RESERVE"
              name={`reserve_region`}
              defaultValue={item?.reserve_region}
              required
              disabled={!access}
              isNumber
              isPositive
            />
          </div>
        </div>
        {access && (
          <button
            className={`${
              disabled ? "opacity-30" : ""
            } self-end rounded-sm font-semibold text-lightFont border border-lightGray bg-stale mt-8 px-[16px] pb-2`}
            disabled={disabled}>
            {!!item ? "Обновить исключение" : "Создать исключение"}
          </button>
        )}
        {isError && (
          <Popup closeModal={() => setError(false)}>
            <SadCat
              message={`Котики грустят, когда вы выставляете\nMIN больше MAX`}
              setFn={setError}
            />
          </Popup>
        )}
      </form>
    </FormProvider>
  );
};
