import { Dispatch, SetStateAction } from "react";
import { LS } from "src/api/constants";
import { tabs, TTetrisTab } from "./tetrisConstants";

type TProps = {
  page: TTetrisTab;
  setPage: Dispatch<SetStateAction<TTetrisTab>>;
};

export const TetrisTabs = ({ page, setPage }: TProps) => {
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");

  return (
    <button className="flex flex-wrap max-w-[346px] lg:max-w-full gap-x-[18px] gap-y-8 px-[10px] py-2 text-sm">
      {tabs?.map((el) => (
        <div
          key={el.page}
          className={`rounded-sm duration-300 ${
            userAccess?.includes(el?.read_access) ? "block" : "hidden"
          } ${
            el.page === page.page
              ? "bg-bg text-lightFont font-semibold px-8"
              : "bg-[00000000] text-white"
          }`}
          onClick={() => setPage(el)}>
          {el.title}
        </div>
      ))}
    </button>
  );
};
