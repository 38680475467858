import { Dispatch, SetStateAction, useState } from "react";
import statsApi from "src/api/stats";
import { fetchRequest } from "src/helpers/fetchRequest";
import { CreateButton } from "src/components/UI/buttons/CreateButton";
import { TRatio, TRatioPayload } from "../../models";
import bin from "src/assets/images/bin.svg";
import trophy from "src/assets/images/trophy.svg";
import { Popup } from "src/components/Popup/Popup";

type TProps = {
  ratios: TRatio[];
  setRatios: Dispatch<SetStateAction<TRatio[]>>;
};

export const Ratios = ({ ratios, setRatios }: TProps) => {
  const [isAdd, setIsAdd] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<TRatio | null>(null);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const existError = !!ratios?.find((el) => el.target_percent === from && el.range_percent === to);
  const dismissError = from >= 1 && to >= 1 && from > to;
  const disabled = from < 1 || to < 1 || dismissError || existError;

  const creteRatio = async () => {
    const payload: TRatioPayload = {
      target_percent: from,
      range_percent: to,
    };
    const { response } = await fetchRequest(statsApi.createRatio(payload), {
      request: "Создание соотношения",
    });
    if (response) {
      setRatios([...ratios, response]);
      setIsAdd(false);
    }
  };

  const deleteRatio = async (id: number) => {
    const { response } = await fetchRequest(statsApi.deleteRatio(id), {
      request: "Удаление соотношения",
    });
    if (response) {
      setRatios(ratios?.filter((el) => el.ratio_id !== id));
      setDeleteConfirmation(null);
    }
  };

  return (
    <div className="flex flex-col gap-4 w-[280px] text-sm px-8">
      <h2 className="text-center font-semibold text-lightFont pb-8">Рассчеты</h2>
      {ratios?.map((el) => (
        <div
          key={el.ratio_id}
          className="grid grid-cols-[20px_30px_6px_30px_1fr] py-2 px-8 -mx-8 items-center gap-8 text-center rounded-sm hover:bg-lightStale duration-300">
          <img src={trophy} alt="top" width={18} height={18} className="relative top-[3px]" />
          <div>{el.target_percent}</div>
          <div>/</div>
          <div>{el.range_percent}</div>
          <button
            type="button"
            className={`w-[16px] h-[16px] hidden sm:block bg-[#EAEAF3] hover:bg-[#D7443E77] flex justify-self-end justify-center items-center rounded-sm duration-300`}
            onClick={() => {
              setDeleteConfirmation(el);
            }}>
            <img src={bin} alt="удалить" width={15} height={15} />
          </button>
        </div>
      ))}
      <div className="text-xs pt-8">
        <CreateButton title="Добавить рассчет" isAdd={isAdd} setIsAdd={setIsAdd} />
      </div>
      {isAdd && (
        <>
          <div className="flex gap-4">
            <input
              type="number"
              className={`w-[120px] h-[24px] border border-lightGray outline-lightGray rounded-sm px-[8px]`}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              onChange={(e) => setFrom(+e.target.value)}
            />
            <input
              type="number"
              className={`w-[120px] h-[24px] border border-lightGray outline-lightGray rounded-sm px-[8px]`}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              onChange={(e) => setTo(+e.target.value)}
            />
          </div>
          {dismissError && <small className="error">Цель должна быть меньше диапозона</small>}
          {existError && <small className="error">Рассчет уже существует</small>}
          <button
            type="button"
            disabled={disabled}
            className="mt-[16px] text-white bg-font font-semibold rounded-sm px-[20px] py-4 w-fit mx-auto duration-300 hover:opacity-70"
            onClick={creteRatio}>
            Создать рассчет
          </button>
        </>
      )}
      {deleteConfirmation !== null && (
        <Popup closeModal={() => setDeleteConfirmation(null)}>
          <div className="flex flex-col gap-4 w-[280px] text-sm text-sm text-center">
            <div>Вы уверены, что хотите прекратить подсчет статистики по соотношению</div>
            <div>
              {deleteConfirmation.target_percent} / {deleteConfirmation.range_percent} ?
            </div>
            <button
              type="button"
              className="mt-[16px] text-white bg-font font-semibold rounded-sm px-[20px] py-4 w-fit mx-auto duration-300 hover:opacity-70"
              onClick={async () => {
                await deleteRatio(deleteConfirmation.ratio_id);
              }}>
              Удалить рассчет
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
