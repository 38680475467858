import { Dispatch, SetStateAction, useState } from "react";
import { EQuestion, TAnswer } from "../../types";
import { Button } from "src/shadcn/ui/ui/button";
import bin from "src/assets/images/bin.svg";
import { v4 } from "uuid";

export const EditAnswer = ({
  question_type,
  answer,
  setIsEdit,
  saveAnswer,
}: {
  question_type: EQuestion;
  answer?: TAnswer;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  saveAnswer: (payload: {
    id: number | string;
    isDelete?: boolean;
    name?: string;
    answer_is_correct?: boolean;
  }) => void;
}) => {
  const [name, setName] = useState(answer?.answer_title || "");
  const [isCorrect, setIsCorrect] = useState(answer?.answer_is_correct || false);
  const disabled = !name?.length;

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex justify-between items-end">
        <div className="text-slate-400">Ответ</div>
        <div className="flex gap-4">
          <Button
            variant="ghost"
            className="h-7 w-7 text-slate-500 bg-rose-50"
            onClick={() => {
              saveAnswer({
                id: answer.answer_id,
                isDelete: true,
              });
            }}>
            <img src={bin} alt="удалить" width={15} height={15} />
          </Button>
          <Button
            variant="ghost"
            className="h-7 w-7 text-slate-500"
            onClick={() => setIsEdit(false)}>
            {`\u2716`}
          </Button>
        </div>
      </div>
      <input
        className="border border-input rounded-sm py-8 px-3"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {question_type !== EQuestion.INPUT && (
        <div className="flex gap-4">
          <Button
            variant="secondary"
            className={`px-5 ${!isCorrect ? "bg-font hover:bg-slate-700 text-white" : ""}`}
            onClick={() => setIsCorrect(false)}>
            Неверный ответ
          </Button>
          <Button
            variant="secondary"
            className={`px-5 ${isCorrect ? "bg-emerald-500 hover:bg-emerald-400 text-white" : ""}`}
            onClick={() => setIsCorrect(true)}>
            Верный ответ
          </Button>
        </div>
      )}
      <Button
        className="px-5 self-end"
        disabled={disabled}
        onClick={() => {
          saveAnswer({
            id: !!answer?.answer_id ? answer.answer_id : v4(),
            name,
            answer_is_correct: question_type === EQuestion.INPUT ? true : isCorrect,
          });
          setName("");
          setIsCorrect(false);
          setIsEdit(false);
        }}>
        Сохранить
      </Button>
    </div>
  );
};
