import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./src/store/store";
import App from "./src/App";
import "./src/index.css";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { sentryConfig } from "./src/sentry";

Sentry.init(sentryConfig);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </>
);
