export const Count = ({ rgb, percent, count }) => {
  const length = count?.toString()?.length;
  const fontSize =
    /*length > 5 ? 5 : length > 4 ? 6 : length > 3 ? 7 : length > 2 ? 8 : */ length > 1 ? 12 : 13;
  return (
    <div
      style={{ backgroundColor: `rgba(${rgb}, ${percent}%)`, fontSize: `${fontSize}px` }}
      className={`flex relative top-0 duration-300 justify-center items-center min-w-[17px] w-fit h-[17px] mx-auto px-4 rounded-sm ${
        percent > 50 && "text-white"
      }`}>
      {count}
    </div>
  );
};
