import { Dispatch, SetStateAction, useState } from "react";
import addApi from "src/api/add";
import { fetchRequest } from "src/helpers/fetchRequest";
import { TAddTab } from "src/pages/Add/Add";

type TProps = {
  setActiveTab: Dispatch<SetStateAction<TAddTab>>;
  fetchCountries: () => Promise<void>;
  setMessage: (value: string) => void;
  setStatus: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
};

export const CreateCountry = ({
  setActiveTab,
  fetchCountries,
  setStatus,
  setIsLoading,
  setMessage,
}: TProps) => {
  const [name_ru, setName_ru] = useState("");
  const [name_en, setName_en] = useState("");

  const createCountry = async () => {
    setIsLoading(true);
    const data = {
      name_ru,
      name_en,
    };
    const { response, error } = await fetchRequest(addApi.createCountry(data), {
      request: "Создание страны",
    });
    if (response) {
      setMessage("Успешно");
      setStatus(true);
      fetchCountries();
    }
    if (error) {
      setMessage("Не удалось добавить страну");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      if (response) {
        setActiveTab(null);
      }
    }, 4600);
  };

  return (
    <div className="w-[300px]">
      <div className="table-header justify-center">НОВАЯ СТРАНА</div>
      <div className="p-[20px] m-auto">
        <div>
          <div className="text text-left text-font">Название на русском</div>
          <input
            value={name_ru}
            onChange={({ target }) => setName_ru(target.value)}
            className="text-sm w-[260px] border border-lightGray rounded-[10px] outline-none h-[40px] py-4 px-8 my-[10px]"
          />
        </div>
        <div>
          <div className="text text-left text-font">Название на английском</div>
          <input
            value={name_en}
            onChange={({ target }) => setName_en(target.value)}
            className="text-sm w-[260px] border border-lightGray rounded-[10px] outline-none h-[40px] py-4 px-8 my-[10px]"
          />
        </div>
        <button onClick={createCountry} className="prime-button" disabled={!name_ru || !name_en}>
          Добавить страну
        </button>
      </div>
    </div>
  );
};
