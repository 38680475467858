import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { navigation } from "./navigation";

export const NavHints = ({ userAccess, page }) => {
  const arr = useMemo(
    () =>
      Object.values(navigation)
        ?.map((el) => Object.values(el))
        ?.reduce((a, b) => a?.concat(b)),
    []
  );

  const parent = useMemo(
    () => arr?.find((el) => !!el.children?.find((child) => child?.path === page)),
    [page, arr]
  );

  return (
    <div className="flex gap-4">
      {!!parent &&
        parent.children
          ?.filter((el) => el?.path !== page)
          ?.map((el, index) => {
            if (userAccess?.includes(el?.access)) {
              return (
                <NavLink
                  key={index}
                  to={el?.path}
                  className="rounded-sm bg-blue text-white text-[10px] px-8 pb-px opacity-50 hover:opacity-80">
                  {el?.title}
                </NavLink>
              );
            }
          })}
    </div>
  );
};
