import { TGlobalCity } from "src/store/directionsReducer";

export const BASE_URL = import.meta.env.REACT_APP_API_ENDPOINT;
export const BASE_URL_OLD = import.meta.env.REACT_APP_OLD_API_ENDPOINT;
export const CENTRIFUGO_URL = import.meta.env.REACT_APP_CENTRIFUGO_ENDPOINT;
export const DB_URL = BASE_URL + "/database-manager";
export const STATIC_TOKEN = import.meta.env.REACT_APP_STATIC_TOKEN;
export const ACADEMY_STATIC_TOKEN = import.meta.env.REACT_APP_ACADEMY_STATIC_TOKEN;
// todo: remove
export const X_API_TOKEN = import.meta.env.REACT_APP_BACKEND_X_API_TOKEN;

export const Url = {
  DEFAULT: `/`,
  LOGIN: `/login`,
  BID: `/bid`,
  LIMITS: `/limits`,
  PROF: `/prof`,
  ONOFF: `/on-off`,
  ONOFF_TIMELINE: `/on-off-timeline`,
  STEP: `/step`,
  DIRECTION_GRAPH: `/direction-graph`,
  MINMAX: `/minmax`,
  MINMAX_SHEET: `/minmax-sheet`,
  SUMMARY: `/summary`,
  STATS: `/stats`,
  STATS_REPORTS: `/stats-reports`,
  ANALYTICS: `/analytics`,
  TETRIS: `/tetris`,
  CLUSTER_SCHEDULE: `/cluster-schedule`,
  CLUSTER_TIMELINE: `/timeline`,
  SITE_CLUSTERS: `/cluster-site`,
  REPORTS: `/reports`,
  CONTROL: `/control`,
  TEXT: `/text`,
  CONTROL_STATS: `stats/`,
  CONTROL_DASHBOARD: `control-dashboard/`,
  CONTROL_BOARD: `board/`,
  CONTROL_REGULATIONS: `regulations/`,
  CONTROL_PROCESSING: `bigbro/`,
  CONTROL_WALLETS: `wallets/`,
  CONTROL_SITE: `site/`,
  CONTROL_AGGREGATOR: `aggregator/`,
  OUTLET_SITES: `sites`,
  OUTLET_CITIES: `cities`,
  OUTLET_VECTORS: `vectors`,
  OUTLET_CURRENCIES: `currencies`,
  OUTLET_REPORTS: `site/`,
  CONTROL_USERS: `users/`,
  ADD: `/add`,
  ACADEMY: `/cryptarium/`,
  ACADEMY_SOLVE: `solve`,
  ACADEMY_EDIT: `edit`,
  ACADEMY_RESULT: `result`,
  ACADEMY_MODULE_PARAM: `module`,
  ACADEMY_EXAM_PARAM: `exam`,
  RATES: `/rates`,
  FLIGHTS: `/flights`,
  REQUESTS: `/requests`,
  DEVELOPERS: `/developers`,
  NEWS: `/new-features`,
  EXNODE: `exnode/`,
  BESTCHANGE: `bestchange/`,
};

export const FILTERED_urls = Object.fromEntries(
  Object.entries(Url)
    ?.filter((el) => el[1][0] === "/" && el[1]?.length > 1)
    ?.map((el) => [el[1]?.substring(1), []])
) as { [p: string]: string[] };

export const LS = {
  USERNAME: "username",
  ROLE: "role",
  ACCESS: "access",
  FAVORITES: "fav",
  FLIGHTS_FILTERS: "flights",
  LAST_VIEW: "last_view",
  STATS_CITIES: "stats",
  STATS_OLD_TOP: "stats_old_top",
  STATS_RATIOS: "ratios",
  PIN: "pin",
  SUPPORT_LAST_ACTION: "sup_action",
  SUPPORT_READ_ACTIONS: "sup_read",
  OLD_STATS: "old_stats",
  SITES_MENU_OPEN: "sites_menu_open",
  LAST_ADD_TAB: "last_add_tab",
  ACADEMY_MY_ANSWERS: "my_answers",
};

export const ROLE = {
  ADMIN: "admin",
  MINI_ADMIN: "mini_admin",
  COURSER_RECRUIT: "courser_recruit",
  COURSER: "courser",
  ANALYTICS: "analytic",
  CONTROLLER: "controller",
  CONTROLLER_ADMIN: "controller_admin",
  COURSER_SUPER_ADMIN: "courser_super_admin",
  COURSER_ADMIN: "courser_admin",
  SUPPORT: "support",
};

export const IS_DEV = import.meta.env.MODE === "development";
export const AUTO_CITY_NAME = "Auto City";
export const AUTO_CITY_CODE = "auto";
export const AUTO_COUNTRY_NAME = "AUTO";

export const autoCity: Partial<TGlobalCity> = {
  city_code: AUTO_CITY_CODE,
  city_name: AUTO_CITY_NAME,
  country: AUTO_COUNTRY_NAME,
  is_main: false,
  is_region: false,
};
