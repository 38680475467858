import { useState, ReactNode } from "react";
import "./Tooltip.css";
import { cn } from "src/helpers/cn";

export const Tooltip = (props: {
  className?: string;
  delay?: number;
  content: string;
  children: ReactNode;
  direction?: string;
  show?: boolean;
}) => {
  const { show = true, className, direction = "top" } = props;

  let timeout: ReturnType<typeof setTimeout>;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className={cn("Tooltip-Wrapper", className)}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}>
      {/* Wrapping */}
      {props.children}
      {active && show && (
        <div className={cn("Tooltip-Tip z-[10]", direction)}>
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  );
};
