import { Dispatch, SetStateAction } from "react";
import {
  setTimelineByCentrifugo,
  setTimelineCitiesByCentrifugo,
} from "src/api/centrifugo/timelineCentrifugo";
import { store } from "src/store/store";
import {
  TTimeline,
  TTimelineDeletePayload,
  TTimelineVector,
  setTimeline,
} from "src/store/clusterReducer";
import { TTimelineSubmit, checkSite, getSiteStr } from "./TimelineFunctions";
import { setNotific } from "src/store/mainReducer";

export type TTimelineLocalDeletePayload = {
  point?: "city" | "from" | "to" | "percent" | "site";
  item: TTimeline;
  payload: TTimelineDeletePayload;
  response: Array<TTimeline> | null;
  items: TTimeline[];
  isTemplate: boolean;
  setTemplateData: Dispatch<SetStateAction<TTimeline[]>>;
  saveRows: boolean;
  submitFn: (payload: TTimelineSubmit) => void;
};

export const deleteNewItem = async ({
  point,
  item,
  payload,
  response,
  items,
  isTemplate,
  setTemplateData,
  saveRows,
  submitFn,
}: TTimelineLocalDeletePayload) => {
  const preparedItem = {
    ...item,
    vectors: item.vectors
      ?.map((vector) => {
        if (
          (point !== "city" ? payload.currency_from_id === vector.currency_from_id : true) &&
          (point !== "city" && point !== "from"
            ? payload.currency_to_id === vector.currency_to_id
            : true) &&
          (point !== "city" && point !== "from" && point !== "to"
            ? payload.percent === vector.percent
            : true)
        ) {
          return {
            ...vector,
            sites:
              point !== "site"
                ? vector?.sites?.filter((site) => checkSite(response, vector, site))
                : vector?.sites?.filter((site) =>
                    (!!response ? !response?.length : site?.is_locked)
                      ? true
                      : getSiteStr(site) !== getSiteStr(payload)
                  ),
          };
        } else {
          return vector;
        }
      })
      ?.filter((vector) => (!saveRows ? !!vector?.sites?.length : true)),
  };
  await submitFn({
    item: preparedItem,
    items: items,
    isTemplate: isTemplate,
    setTemplateData: setTemplateData,
  });
  if (!preparedItem?.vectors?.length && !saveRows) {
    if (isTemplate) {
      setTemplateData(items?.filter((el) => el.city_id !== payload?.city_id));
    } else {
      const cities = items?.map((el) => el.city_id);
      await setTimelineCitiesByCentrifugo(cities?.filter((el) => el !== payload?.city_id));
      store.dispatch(setTimeline(items?.filter((el) => el.city_id !== payload.city_id)));
    }
  }
};

export const checkEmpty = (deletePayload: TTimelineLocalDeletePayload): TTimeline[] => {
  const { payload, items } = deletePayload;

  const checkLengthStashUnconfirmed = (vector: TTimelineVector) => {
    return !!vector.sites?.filter((el) => !("creator" in el))?.length;
  };

  const checkedItems = items
    ?.map((city) => ({
      ...city,
      vectors: city.vectors?.filter((vector) => {
        if (!("currency_from_id" in payload)) {
          return checkLengthStashUnconfirmed(vector);
        } else if (!("currency_to_id" in payload)) {
          return vector.currency_from_id === payload.currency_from_id
            ? checkLengthStashUnconfirmed(vector)
            : true;
        } else if (!("percent" in payload)) {
          return vector.currency_from_id === payload.currency_from_id &&
            vector.currency_to_id === payload.currency_to_id
            ? checkLengthStashUnconfirmed(vector)
            : true;
        } else {
          return vector.currency_from_id === payload.currency_from_id &&
            vector.currency_to_id === payload.currency_to_id &&
            vector.percent === payload.percent
            ? checkLengthStashUnconfirmed(vector)
            : true;
        }
      }),
    }))
    ?.filter((el) => (!("currency_from_id" in payload) ? !!el.vectors.length : true));

  return checkedItems;
};

export const stashEmptyRows = async (items: TTimeline[]) => {
  const newArr = items
    ?.map((el) => ({
      ...el,
      vectors: el.vectors?.filter((vectorEl) => !!vectorEl?.sites?.length),
    }))
    ?.filter((el) => !!el?.vectors?.length);

  items?.forEach(async (el) => {
    const item = newArr.find((arrEl) => arrEl.city_id === el.city_id);
    if (!!item) {
      await setTimelineByCentrifugo(item, item.city_id);
    } else {
      setTimelineByCentrifugo(
        {
          city_id: el?.city_id,
          vectors: [],
        },
        el
      );
    }
  });

  const citiesArr = newArr?.map((el) => el?.city_id)?.sort((a, b) => (a > b ? 1 : -1));

  await setTimelineCitiesByCentrifugo(citiesArr);
  store.dispatch(
    setNotific({ type: "success", message: "Успешно проверены и очищены", request: "Пустые ряды" })
  );
};
