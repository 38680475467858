import { ExpandedEntity, OnOffExpandedState } from "src/types/OnOffTimeline/store";

export function compareExpandedStates(
  tree1: OnOffExpandedState,
  tree2: OnOffExpandedState
): OnOffExpandedState {
  const resultTree: OnOffExpandedState = {};

  const allKeys = Array.from(new Set([...Object.keys(tree1), ...Object.keys(tree2)]));

  for (const cityKey of allKeys) {
    if (tree1.hasOwnProperty(cityKey) && tree2.hasOwnProperty(cityKey)) {
      const node1 = tree1[cityKey];
      const node2 = tree2[cityKey];

      const resultNode: ExpandedEntity = {
        isExpanded: node1.isExpanded || node2.isExpanded,
        children: compareChildNodes(node1.children, node2.children),
      };

      resultTree[cityKey] = resultNode;
    } else if (tree1.hasOwnProperty(cityKey)) {
      resultTree[cityKey] = tree1[cityKey];
    } else if (tree2.hasOwnProperty(cityKey)) {
      resultTree[cityKey] = tree2[cityKey];
    }
  }

  return resultTree;
}

function compareChildNodes(
  children1: Record<string, ExpandedEntity> | undefined,
  children2: Record<string, ExpandedEntity> | undefined
): Record<string, ExpandedEntity> | undefined {
  if (!children1 && !children2) {
    return undefined;
  }

  const resultChildren: Record<string, ExpandedEntity> = {};

  if (children1) {
    for (const childKey in children1) {
      if (children1.hasOwnProperty(childKey)) {
        const node1 = children1[childKey];
        const existingNode = resultChildren[childKey];

        const resultNode: ExpandedEntity = {
          isExpanded: existingNode ? existingNode.isExpanded || node1.isExpanded : node1.isExpanded,
          children: compareChildNodes(node1.children, existingNode?.children),
        };

        resultChildren[childKey] = resultNode;
      }
    }
  }

  if (children2) {
    for (const groupKey in children2) {
      if (children2.hasOwnProperty(groupKey)) {
        const node2 = children2[groupKey];
        const existingNode = resultChildren[groupKey];

        const resultNode: ExpandedEntity = {
          isExpanded: existingNode ? existingNode.isExpanded || node2.isExpanded : node2.isExpanded,
          children: compareChildNodes(existingNode?.children, node2.children),
        };

        resultChildren[groupKey] = resultNode;
      }
    }
  }

  return resultChildren;
}
