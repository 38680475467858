import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { fetchRequest } from "src/helpers/fetchRequest";
import { Url } from "src/api/constants";
import controlApi from "src/api/control";
import { setNotific } from "src/store/mainReducer";
import {
  selectControlUsers,
  selectProcessUsers,
  setControlUsers,
  setProcessUsers,
} from "src/store/commonReducer";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { CreateUser } from "./CreateUser";
import { User } from "./User";
import { Chats } from "./Chats/Chats";

export const Users = () => {
  const dispatch = useDispatch();
  const page = useLocation().pathname;
  const users = useSelector(selectControlUsers);
  const processUsers = useSelector(selectProcessUsers);
  const [isGood, setIsGood] = useState<boolean>(true);

  const fetchUsers = async () => {
    const { response, error, status } = await fetchRequest(controlApi.getControlUsers());
    if (response) {
      dispatch(setControlUsers(response));
      setIsGood(true);
    }
    if (error && status !== 404) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText, request: "Пользователи чатов" })
      );
      setIsGood(false);
    }
    if (status === 404) {
      setIsGood(true);
    }
  };

  const fetchProcessUsers = async () => {
    const { response, error, status } = await fetchRequest(controlApi.getProcessUsers());
    if (response) {
      dispatch(setProcessUsers(response));
      setIsGood(true);
    }
    if (error && status !== 404) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText, request: "Пользователи чатов" })
      );
      setIsGood(false);
    }
    if (status === 404) {
      setIsGood(true);
    }
  };

  return (
    <div className="w-full flex flex-col md:flex-row gap-[20px] text-left font-light">
      <div className="md:min-w-[300px] max-w-[400px] border-t border-[#BABAC366] md:border-none pt-[20px] md:pt-0">
        <CreateUser
          fetchUsers={
            page === `${Url.CONTROL}/${Url.CONTROL_PROCESSING}` ? fetchProcessUsers : fetchUsers
          }
        />
        <div className="flex flex-col gap-4 mt-8">
          {(page === `${Url.CONTROL}/${Url.CONTROL_PROCESSING}` ? processUsers : users)?.map(
            (el) => (
              <User
                isProcessing={page === `${Url.CONTROL}/${Url.CONTROL_PROCESSING}`}
                key={el.telegram_user_id}
                item={el}
                users={page === `${Url.CONTROL}/${Url.CONTROL_PROCESSING}` ? processUsers : users}
              />
            )
          )}
        </div>
        {!isGood && <Emptiness message="Ошибка сервиса" />}
      </div>
      {page === `${Url.CONTROL}/${Url.CONTROL_USERS}` && <Chats />}
    </div>
  );
};
