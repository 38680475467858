export enum EProxyTab {
  PROXY = "proxy",
  BALANCE = "balance",
  SERVICES = "service",
}

export type TProxy = {
  proxy_id: number;
  source_id: number;
  proxy_host: string;
  proxy_port: number;
  proxy_user: string;
  proxy_password: string;
  full_view: string;
  proxy_expiration_time: string;
  proxy_is_soon_expirated: boolean;
  proxy_reserved_by: Array<number>;
  problems: Array<number>;

  proxy_id_from_source?: number;
  proxy_type?: EProxyType;
  proxy_version?: string;
  proxy_created_at?: string;
};
export type TProlongProxy = {
  proxy_expiration_time: string;
  proxy_id: number;
};
export type TProxyService = {
  service_id: number;
  service_name: string;
};
export type TSourceVersion = {
  version_name: string;
  prolong_periods: number[];
  comment: string;
};
export type TSourceModel = {
  source_id: number;
  source_name: string;
  source_versions: TSourceVersion[];
};
export type TProxyFilter = {
  sources: Array<number>;
  services: Array<number>;
  soon_exp: boolean;
};

export type TProxySourceBalance = {
  source_id: number;
  account_email: string;
  account_balance: number;
};

export type TCreateProxyPayload = {
  proxy_count: number;
  proxy_period: number;
  proxy_country: string;
  proxy_version: string;
  proxy_type: EProxyType;
};

export enum EProxyType {
  HTTP = "HTTPS",
  SOCKSS = "SOCKS5",
}
export type ProxyFilterType = "services" | "sources";
