import { Dispatch, SetStateAction, useEffect, useMemo, memo, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { cutCurrency } from "src/helpers/currencyHelper";
import { createOverlaysVectors } from "src/helpers/timelineHelper";
import { setTimelineByCentrifugo } from "src/api/centrifugo/timelineCentrifugo";
import clusterApi from "src/api/cluster";
import { TCurSelect } from "src/store/directionsReducer";
import {
  TTimeline,
  TTimelineVector,
  TTimelineDeletePayload,
  TTimelineSite,
} from "src/store/clusterReducer";
import { setNotific } from "src/store/mainReducer";
import { EditedField } from "src/components/FormElements/EditedField";
import arrow from "src/assets/images/arrow.svg";
import trophy from "src/assets/images/trophy.svg";

type TProps = {
  vectorIndex: number;
  cityId: number;
  item: TTimelineVector;
  items: Array<TTimeline>;
  vectors: Array<TTimelineVector>;
  currenciesOptions: Array<TCurSelect>;
  isNotFirstFrom: boolean;
  isNotFirstTo: boolean;
  isCopy: boolean;
  isDelete: boolean;
  setDeletedEl: Dispatch<SetStateAction<TTimelineDeletePayload | null>>;
  visibleVectors: Array<string>;
  setVisibleVectors: Dispatch<SetStateAction<Array<string>>>;
  opened: boolean;
  activeTemplate: number | null;
  setTemplateData: Dispatch<SetStateAction<Array<TTimeline>>>;
  username: string;
  isSplitRight: boolean;
};

export const VectorLine = memo(
  ({
    vectorIndex,
    cityId,
    item,
    items,
    vectors,
    currenciesOptions,
    isNotFirstFrom,
    isNotFirstTo,
    username,
    visibleVectors,
    setVisibleVectors,
    opened,
    isCopy,
    isDelete,
    setDeletedEl,
    activeTemplate,
    setTemplateData,
    isSplitRight,
  }: TProps) => {
    const dispatch = useDispatch();
    const [editPercent, setEditPercent] = useState(false);
    const [isAddPercent, setIsAddPercent] = useState(false);
    const [vector, setVector] = useState<Array<TTimelineVector>>([]);
    const [percent, setPercent] = useState(+item?.percent);
    const [newPercent, setNewPercent] = useState<string>("0");

    const isExistPercent = useMemo(
      () => !!vectors?.find((el) => el.percent === +newPercent),
      [newPercent, vectors]
    );
    const from = useMemo(
      () => currenciesOptions?.find((el) => el.value.id === item.currency_from_id),
      [currenciesOptions, item]
    );
    const to = useMemo(
      () => currenciesOptions?.find((el) => el.value.id === item.currency_to_id),
      [currenciesOptions, item]
    );
    const isVectorVisible = useMemo(
      () => visibleVectors?.includes(`${cityId}_${item?.currency_from_id}`),
      [visibleVectors]
    );
    const getVectorStr = (vectorEl: TTimelineVector) =>
      `${vectorEl?.currency_from_id}_${vectorEl?.currency_to_id}_${vectorEl?.percent}`;

    useEffect(() => {
      if (!!item?.sites?.length) {
        const arr = createOverlaysVectors(item);
        setVector(arr);
      } else {
        setVector([item]);
      }
    }, [item]);

    const updatePercent = async () => {
      const findItem = items?.find((el) => el.city_id === cityId);
      if (!!findItem) {
        const preparedItems = {
          ...findItem,
          vectors: findItem?.vectors?.map((vectorEl) => {
            if (getVectorStr(vectorEl) === getVectorStr(item)) {
              return {
                ...vectorEl,
                percent: percent,
                sites: vectorEl?.sites?.map((siteEl: TTimelineSite) => ({
                  ...siteEl,
                  creator: username,
                })),
              };
            } else {
              return vectorEl;
            }
          }),
        };
        if (!!activeTemplate) {
          setTemplateData(items?.map((city) => (city?.city_id === cityId ? preparedItems : city)));
        } else {
          const payload = [
            {
              city_id: cityId,
              currency_from_id: item.currency_from_id,
              currency_to_id: item.currency_to_id,
              percent: percent,
              percent_old: item?.percent,
            },
          ];
          const { response } = await fetchRequest(clusterApi.updateTimelinePercent(payload), {
            request: "Обновление процента",
          });
          if (response) {
            dispatch(
              setNotific({
                type: "success",
                message: "Процент успешно обновлен",
                request: "Обновление процента",
              })
            );
            await setTimelineByCentrifugo(preparedItems, cityId);
          }
        }
      }
      setEditPercent(false);
    };

    const addPercent = async () => {
      const findItem = items?.find((el) => el.city_id === cityId);
      if (!!findItem) {
        const preparedItems = {
          ...findItem,
          vectors: [
            ...findItem.vectors,
            {
              currency_from_id: item.currency_from_id,
              currency_to_id: item.currency_to_id,
              percent: +newPercent,
              cluster_percent: 0,
              cluster_course: 0,
              sites: [],
            },
          ]
            ?.sort((a, b) => (a.percent < b.percent ? 1 : -1))
            ?.sort((a, b) =>
              `${a.currency_from_id}_${a.currency_to_id}` >
              `${b.currency_from_id}_${b.currency_to_id}`
                ? 1
                : -1
            ),
        };
        if (!!activeTemplate) {
          setTemplateData(items?.map((city) => (city?.city_id === cityId ? preparedItems : city)));
        } else {
          await setTimelineByCentrifugo(preparedItems, cityId);
        }
      }
      setIsAddPercent(false);
    };

    if (opened && (isVectorVisible || !isNotFirstFrom || +item?.percent === 0)) {
      return (
        <div className="flex flex-col">
          {vector?.map((_, index) => (
            <div
              key={index}
              className={`w-full grid ${
                isSplitRight
                  ? "grid-cols-[46px_30px_40px_20px]"
                  : isDelete || isCopy
                  ? "grid-cols-[46px_40px_30px_10px_20px]"
                  : "grid-cols-[46px_30px_40px_20px_38px]"
              } items-center gap-x-8 h-[29px] max-h-[29px] ${
                opened && (isVectorVisible || !isNotFirstFrom || +item?.percent === 0)
                  ? ""
                  : "hidden"
              } ${
                !isNotFirstFrom && vectorIndex !== 0 && vectorIndex !== vectors?.length - 1
                  ? "border-t border-glass-gray"
                  : ""
              }`}>
              <div
                className={`px-2 border rounded-sm ${
                  isDelete && !isNotFirstFrom && index === 0
                    ? "border-red bg-[#D7443E11] hover:bg-[#D7443E33] w-fit cursor-pointer mx-2"
                    : isCopy
                    ? "opacity-0"
                    : "border-[#FFFFFF00] w-full cursor-pointer -mx-2"
                }`}
                onClick={() => {
                  if (!isDelete && !isCopy) {
                    if (isVectorVisible) {
                      setVisibleVectors(
                        visibleVectors?.filter((el) => el !== `${cityId}_${item?.currency_from_id}`)
                      );
                    } else {
                      setVisibleVectors([...visibleVectors, `${cityId}_${item?.currency_from_id}`]);
                    }
                  }
                  if (isDelete) {
                    setDeletedEl({
                      city_id: cityId,
                      currency_from_id: item?.currency_from_id,
                    });
                  }
                }}>
                {!isNotFirstFrom && index === 0 ? (
                  <div
                    className={`flex justify-between gap-8 items-baseline ${
                      from?.label?.includes("CASH") ? "text-[#84BC9C] font-semibold" : ""
                    }`}>
                    {opened && (
                      <div
                        className={`${
                          isCopy || isDelete ? "hidden" : "block"
                        } relative duration-300 ml-[5px] ${
                          isVectorVisible ? "rotate-180" : "top-2"
                        } w-[10px] h-[10px] min-w-[10px]`}>
                        <img src={arrow} alt="раскрыть" width={10} height={10} />
                      </div>
                    )}
                    <div>{cutCurrency(from?.label)}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`w-fit pl-4 ${
                  to?.label?.includes("CASH") ? "text-[#84BC9C] font-semibold" : ""
                } ${
                  isCopy
                    ? "opacity-0"
                    : isDelete && !(isNotFirstTo && isNotFirstFrom) && index === 0
                    ? "pr-4 -mr-4 border rounded-sm border-red cursor-pointer bg-[#D7443E11] hover:bg-[#D7443E33]"
                    : ""
                } ${
                  !isNotFirstTo && isNotFirstFrom && !isDelete
                    ? "border-t border-glass-gray pt-[5px] -mr-[40px] pr-[40px]"
                    : ""
                }`}
                onClick={() => {
                  if (isDelete) {
                    setDeletedEl({
                      city_id: cityId,
                      currency_from_id: item?.currency_from_id,
                      currency_to_id: item?.currency_to_id,
                    });
                  }
                }}>
                {!(isNotFirstTo && isNotFirstFrom) && index === 0 ? cutCurrency(to?.label) : ""}
              </div>
              {!isDelete && !isCopy && index === 0 ? (
                <div className={`relative flex ${editPercent ? "z-30 bg-bg" : ""}`}>
                  <EditedField
                    item={item}
                    attr="percent"
                    value={percent}
                    setValue={setPercent}
                    isEdit={editPercent}
                    setEdit={setEditPercent}
                    rgb={"0, 0, 0"}
                    percent={0}
                    updFn={updatePercent}
                    isAccess
                  />
                  {editPercent ? "" : "%"}
                </div>
              ) : isDelete ? (
                <div
                  className="flex whitespace-pre px-4 rounded-sm border border-red bg-[#D7443E11] hover:bg-[#D7443E33] cursor-pointer w-fit"
                  onClick={() =>
                    setDeletedEl({
                      city_id: cityId,
                      currency_from_id: item?.currency_from_id,
                      currency_to_id: item?.currency_to_id,
                      percent: item?.percent,
                    })
                  }>
                  {item?.percent} %
                </div>
              ) : (
                <div />
              )}
              {!isDelete &&
              !isCopy &&
              !(isNotFirstTo && isNotFirstFrom) &&
              isVectorVisible &&
              index === 0 ? (
                <div className="relative">
                  <button
                    type="button"
                    className="flex justify-center items-center w-[16px] min-w-[16px] h-[16px] rounded-sm bg-stale font-normal text-base text-[#282c3466] pb-[3px] duration-300 hover:opacity-70"
                    onClick={() => setIsAddPercent(true)}>
                    +
                  </button>
                  {isAddPercent && (
                    <div className="absolute -top-[7px] z-30 -left-8 flex items-center gap-4 rounded-sm bg-bg py-[3px] px-8">
                      <input
                        className={`w-[120px] h-[24px] border border-lightGray outline-lightGray rounded-sm px-[8px] duration-300 ${
                          isExistPercent ? "shadow-error" : ""
                        }`}
                        placeholder="percent"
                        type="number"
                        step="any"
                        defaultValue={newPercent}
                        onWheel={(e) => {
                          e.currentTarget.blur();
                        }}
                        onChange={(e) => setNewPercent(e.target.value)}
                        onKeyDown={(e) => {
                          if (
                            e.keyCode === 13 &&
                            !!e.currentTarget?.value.trim().length &&
                            !e.ctrlKey &&
                            !isExistPercent
                          ) {
                            addPercent();
                          }
                        }}
                      />
                      <button
                        type="button"
                        className={`w-[16px] min-w-[16px] h-[16px] bg-[#62F5B3] flex justify-center items-center rounded-sm text-white text-base hover:brightness-110`}
                        disabled={isExistPercent}
                        onClick={addPercent}>
                        <span className={`relative top-[-0.5px] font-bold text-xs`}>✓</span>
                      </button>
                      <button
                        type="button"
                        className="w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#989CA4] text-base hover:brightness-90"
                        onClick={() => setIsAddPercent(false)}>
                        <span className={`relative top-[-1px] rotate-45 left-[1px]`}>+</span>
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {!isSplitRight &&
                (!isDelete &&
                !isCopy &&
                isVectorVisible &&
                index === 0 &&
                !!item?.percent_position ? (
                  <div className="h-[20px] flex gap-[6px] items-baseline font-bold text-[#282C3488] whitespace-pre">
                    <img
                      src={trophy}
                      alt="место"
                      width={10}
                      height={10}
                      className="relative top-[3px]"
                    />
                    {item?.percent_position} / {item?.percent_position_max}
                  </div>
                ) : (
                  <div />
                ))}
            </div>
          ))}
        </div>
      );
    }
  }
);
