import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import textApi from "src/api/text";
import { setNotific } from "src/store/mainReducer";

type TProps = {
  isLang: boolean;
  languages: Array<string>;
  setLanguages: Dispatch<SetStateAction<Array<string>>>;
};

export const Languages = ({ isLang, languages, setLanguages }: TProps) => {
  const dispatch = useDispatch();
  const [isNew, setIsNew] = useState<boolean>(false);
  const [newLang, setNewLang] = useState<string>("");
  const langExist = languages?.includes(newLang?.toUpperCase());
  const disableCreate = langExist || !newLang?.length;

  const createLang = async () => {
    const { response } = await fetchRequest(
      textApi.addLang({
        name: newLang?.toUpperCase(),
      }),
      { request: "Не удалось создать язык" }
    );
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: `Язык ${newLang?.toUpperCase()} успешно создан`,
          request: "Создание языка",
        })
      );
      setLanguages([...languages, newLang?.toUpperCase()]);
      setIsNew(false);
      setNewLang("");
    }
  };

  return (
    <div
      className={`flex flex-col gap-4 rounded-sm border border-[#BABAC322] bg-select relative md:max-w-[300px] text-left rounded-sm p-8 duration-300 ${
        !isLang
          ? "opacity-0 max-h-0 overflow-hidden -my-[20px]"
          : "opacity-100 max-h-[300px] overflow-auto -my-8"
      }`}>
      <button
        className={`w-full flex items-center gap-[16px] hover:opacity-80 px-4 text-left font-normal text-lightFont`}
        onClick={() => {
          setIsNew(!isNew);
        }}>
        <div className={`text-[16px] leading-[10px]`}>+</div>
        Новый язык
      </button>
      <div className="pb-8">
        <div
          className={`flex gap-4 duration-300 ${
            !isNew
              ? "opacity-0 max-h-0 pt-0 overflow-hidden"
              : "opacity-100 max-h-[40px] pt-8 overflow-auto"
          }`}>
          <input
            className={`w-full rounded-sm border border-lightGray px-8 py-2`}
            value={newLang}
            onChange={(e) => setNewLang(e.target.value?.trim())}
          />
          <button
            type="button"
            disabled={disableCreate}
            className={`w-[24px] min-w-[24px] h-[24px] bg-[#62F5B3] flex justify-center items-center rounded-sm text-white text-base hover:brightness-110 ${
              disableCreate ? "opacity-20" : ""
            }`}
            onClick={async () => {
              await createLang();
            }}>
            <span className={`relative top-[-0.5px] font-bold text-xs`}>✓</span>
          </button>
        </div>
        {langExist && <small className="text-lightFont">Такой язык уже есть</small>}
      </div>
      {languages?.map((el: string, index: number) => (
        <div key={index} className={`w-fit px-8 rounded-md border border-[#BABAC322] bg-bg`}>
          {el}
        </div>
      ))}
    </div>
  );
};
