import { TCurrencyTypesFilter } from "src/store/directionsReducer";
import { TCurrencyTypes } from "src/pages/Text/types";
import { currencyFilter } from "./currencyFilter";

export const CurrensyTypesFilter = ({
  color,
  iconColor,
  directionType,
  currencyFilters,
  onSelect,
}: {
  color: string;
  iconColor?: string;
  directionType: "from" | "to";
  currencyFilters: TCurrencyTypesFilter;
  onSelect: (type: TCurrencyTypes, side: "from" | "to") => void;
}) => {
  return (
    <>
      {currencyFilter.map((item) => {
        const isSelected = currencyFilters[directionType].includes(item.type);
        return (
          <button
            key={item.type}
            className={`flex-1 flex items-center justify-center p-4 shadow-base rounded-sm`}
            style={{
              backgroundColor: isSelected ? color : "",
              color: iconColor && isSelected ? iconColor : "",
            }}
            onClick={() => onSelect(item.type, directionType)}>
            {item.icon}
          </button>
        );
      })}
    </>
  );
};
