import { useState } from "react";
import { fetchRequest } from "src/helpers/fetchRequest";
import controlApi from "src/api/control";
import { AddButton } from "src/components/UI/buttons/AddButton";

export const CreateChat = ({ fetchChats }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [chatName, setChatName] = useState<string>("");
  const [chatId, setChatId] = useState<number | null>(null);

  const disabled = !chatName?.length || !chatId || chatId === 0;

  const createChat = async () => {
    const { response } = await fetchRequest(
      controlApi.createRegulationsChat({
        chat_name: chatName,
        telegram_chat_id: chatId,
      }),
      {
        request: "Добавление чата ТГ",
      }
    );
    if (response) {
      await fetchChats();
      setChatName("");
      setChatId(null);
      setIsAdd(false);
    }
  };

  return (
    <>
      <AddButton onClick={() => setIsAdd(!isAdd)} title={"Добавить чат"} />
      <div
        className={`duration-500 overflow-hidden flex flex-col gap-8 text-xs px-[10px] ${
          isAdd ? "max-h-[1500px] opacity-100 my-8 py-[12px]" : "max-h-0 opacity-0 mt-0 -mb-8 py-4"
        }`}>
        <div className="grid grid-cols-[110px_1fr] gap-8 items-baseline">
          <div className="font-semibold text-lightFont whitespace-pre">Имя чата</div>
          <input
            className={`w-full h-[20px] px-4 font-light rounded-sm border border-lightGray`}
            value={chatName}
            onChange={(e) => setChatName(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-[110px_1fr] gap-8 items-baseline">
          <div className="font-semibold text-lightFont whitespace-pre">TG ID</div>
          <input
            className={`w-full h-[20px] px-4 font-light rounded-sm border border-lightGray`}
            type="number"
            value={chatId || ""}
            placeholder={"1234567890"}
            step={1}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onChange={(e) => setChatId(+e.target.value)}
          />
        </div>
        <button
          className={`bg-light self-end px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
            disabled ? "opacity-0" : ""
          }`}
          disabled={disabled}
          onClick={createChat}>
          Добавить
        </button>
      </div>
    </>
  );
};
