import { useEffect, useState } from "react";

export const Timer = ({ time, dependence }) => {
  const timerPercent = 360 / (time - 1) / 2;
  const circumference = 30 * 2 * Math.PI;
  const [timer, setTimer] = useState(0);
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    let count = time - 1;
    setTimer(count);
    const timerId = setInterval(() => {
      if (count > 0) {
        count -= 1;
        setTimer(count);
      } else {
        count = time - 1;
        setTimer(time - 1);
      }
    }, 1000);
    return () => clearInterval(timerId);
  }, [dependence]);

  useEffect(() => {
    let count = time * 20 - 1;
    const timerId = setInterval(() => {
      if (count > 0) {
        setAngle(Math.round((timerPercent * count) / 20));
        count -= 1;
      } else {
        setAngle(Math.round((timerPercent * count) / 20));
        count = time * 20 - 1;
      }
    }, 50);
    return () => clearInterval(timerId);
  }, [dependence]);

  return (
    <div
      className={`flex items-center text-lightFont justify-center w-[70px] h-[70px] min-w-[70px] min-h-[70px] rounded-[50%] font-bold bg-bg shadow-inset`}>
      {timer > 60
        ? `${Math.floor(timer / 60)}:${timer % 60 > 9 ? timer % 60 : `0${timer % 60}`}`
        : timer > 9
        ? timer
        : `0${timer}`}
      {/* eslint-disable react/no-unknown-property */}
      <div
        x-data="scrollProgress"
        className="fixed inline-flex items-center justify-center overflow-hidden rounded-full">
        <svg className="w-20 h-20">
          <circle
            className="text-[#EEE]"
            strokeWidth="5"
            stroke="currentColor"
            fill="transparent"
            r="30"
            cx="40"
            cy="40"
          />
          <circle
            className={`text-green duration-[${time}ms] -rotate-90`}
            strokeWidth="5"
            strokeDasharray={circumference}
            strokeDashoffset={angle}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r="30"
            cx="-40"
            cy="40"
          />
        </svg>
      </div>
    </div>
  );
};
