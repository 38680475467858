import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { LS } from "src/api/constants";
import statsApi from "src/api/stats";
import { fetchRequest } from "src/helpers/fetchRequest";
import {
  selectStats,
  setIsOldStats,
  setLSRatios,
  setLSTops,
  selectStatsData,
  selectIsOldStats,
  selectRatios,
  setRatios,
} from "src/store/statsReducer";
import { selectAllCities } from "src/store/directionsReducer";
import { Spinner } from "src/components/Spinner/Spinner";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { CityRow } from "./components/CityRow";
import { StatsMenu } from "./components/StatsMenu";

export const Stats = () => {
  const dispatch = useDispatch();
  const storeCities = localStorage.getItem(LS.STATS_CITIES);
  const ratios = useSelector(selectRatios);
  const [customCities, setCustomCities] = useState<Array<number>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isConnect, setIsConnect] = useState<boolean | "">("");
  const isOldStats = useSelector(selectIsOldStats);
  const data = useSelector(selectStats);
  const statsData = useSelector(selectStatsData);
  //  const boosts = useSelector(selectBoosts);
  const globalCities = useSelector(selectAllCities);
  const stats = useMemo(
    () =>
      data?.stats?.map((el) => ({
        ...el,
        city_id: globalCities?.find((el2) => el2?.city_name === el.city_name)?.city_id,
      })),
    [data, globalCities]
  );

  // const fetchBoost = async () => {
  //   const { response, error } = await fetchRequest(statsApi.getBoost());
  //   if (response) {
  //     dispatch(setBoosts(response));
  //   }
  //   if (error) {
  //     dispatch(setBoosts([]));
  //     dispatch(setNotific({ type: "error", message: error?.statusText, request: "Селениум" }));
  //   }
  // };

  const fetchRatios = async () => {
    const { response } = await fetchRequest(statsApi.getRatios(), {
      request: "Соотношения",
    });
    if (response) {
      dispatch(setRatios(response));
    }
  };

  useEffect(() => {
    fetchRatios();
    // fetchBoost();
    if (localStorage.getItem(LS.OLD_STATS) === "true") {
      dispatch(setIsOldStats(true));
    }
  }, []);

  useEffect(() => {
    if (!!localStorage.getItem(LS.STATS_RATIOS)) {
      const newRatios = localStorage
        .getItem(LS.STATS_RATIOS)
        ?.split(",")
        ?.map((el) => {
          const ratioEl = el.split("_");
          return {
            city_id: +ratioEl[0],
            ratio_id: +ratioEl[1],
          };
        });
      dispatch(setLSRatios(newRatios));
    }
    if (!!localStorage.getItem(LS.STATS_OLD_TOP)) {
      const newTops = localStorage
        .getItem(LS.STATS_OLD_TOP)
        ?.split(",")
        ?.map((el) => {
          const topEl = el.split("_");
          return {
            city_id: +topEl[0],
            top: +topEl[1],
          };
        });
      dispatch(setLSTops(newTops));
    }
    if (!!storeCities) {
      setCustomCities(storeCities?.split(",")?.map((el) => +el));
    }
    setTimeout(() => setIsLoading(false), 3000);
  }, []);

  return (
    <div className="mx-auto ">
      <div className="mx-auto mt-[70px] lg:max-w-[1230px] pb-[100px]">
        <StatsMenu
          last_upd={isOldStats ? data?.last_upd : statsData?.last_upd}
          isConnect={isConnect}
          setIsConnect={setIsConnect}
          stats={isOldStats ? stats : statsData?.stats[0]?.data}
          customCities={customCities}
          setCustomCities={setCustomCities}
          ratios={ratios}
          setRatios={setRatios}
        />
        {isLoading && !stats?.length && (
          <div className="flex relative justify-center top-[50px]">
            <Spinner />
          </div>
        )}
        {((!!stats?.length && isOldStats) || (!!statsData && !isOldStats)) && (
          <div className="table relative sm:right-[10px] lg:right-[30px] p-5 mx-auto sm:ml-[40px] sm:mr-[30px] lg:mr-[50px] w-[calc(100%-40px)] sm:w-[calc(100%-100px)]">
            {(isOldStats ? stats : statsData?.stats[0]?.data)
              ?.filter((el) => !customCities.length || customCities.includes(el?.city_id))
              ?.map((item, index) => (
                <CityRow
                  key={index}
                  itemOld={item}
                  // fetchBoost={fetchBoost}
                  cityCode={
                    globalCities?.find((el) => el.city_id === item.city_id)?.city_code || ""
                  }
                  // boosts={boosts?.filter((el) => el?.city_id === item?.city_id)}
                  isConnect={isConnect}
                  ratios={ratios}
                />
              ))}
          </div>
        )}
      </div>
      {!isLoading && !stats?.length && <Emptiness message={"Ошибка сервиса"} />}
    </div>
  );
};

export type TAlert = {
  link: string;
  top_percent_30: number;
  top_percent_today: number;
  two_exch_top?: number;
  vector: string;
  bad_status: boolean;
};
