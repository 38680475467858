import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AxiosResponse } from "axios";
import { NestedObjects } from "../components/routes";
import { fetchRequest } from "src/helpers/fetchRequest";
import controlApi from "src/api/control";
import { setNotific } from "src/store/mainReducer";
import {
  TCoin,
  TCondition,
  TNetwork,
  TTrack,
  TTrackNotification,
  TWallet,
} from "src/store/commonReducer";
import { Emptiness, GoodEmptiness } from "src/components/UI/loader/Emptiness";
import { Spinner } from "src/components/Spinner/Spinner";
import { Wallet } from "./Wallet";
import { CreateWallet } from "./CreateWallet";
import { CreateCoin } from "./CreateCoin";
import { Coin } from "./Coin";

export enum EMethod {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export const Wallets = () => {
  const dispatch = useDispatch();
  const [isGood, setIsGood] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coins, setCoins] = useState<TCoin[]>([]);
  const [wallets, setWallets] = useState<TWallet[]>([]);
  const [tracks, setTracks] = useState<TTrack[]>([]);
  const [notifications, setNotifications] = useState<TTrackNotification[]>([]);
  const [conditions, setConditions] = useState<TCondition[]>([]);
  const [networks, setNetworks] = useState<TNetwork[]>([]);

  const networkColor = (network) => {
    switch (network) {
      case "btc": {
        return "#40AEF0";
      }
      case "eth": {
        return "#9999FF";
      }
      case "trx": {
        return "#D7443E";
      }
      default:
        return "#BABAC3";
    }
  };

  const baseFetch = async ({
    setter,
    getMethod,
    name,
  }: {
    getMethod: Promise<AxiosResponse>;
    name: string;
    setter:
      | Dispatch<SetStateAction<TTrackNotification[] | TTrack[] | TCoin[] | TWallet[]>>
      | ((res: NestedObjects<TCondition | TNetwork>) => void);
  }) => {
    name === "Wallet" && setIsGood(null);
    setIsLoading(true);
    const { response, error } = await fetchRequest(getMethod, { request: `${name}s` });
    if (response) {
      name === "Wallet" && setIsGood(true);
      setter(response);
    }
    if (error) {
      name === "Wallet" && setIsGood(false);
    }
    setIsLoading(false);
  };

  const baseRequest = async ({
    method,
    methodType,
    name,
    title,
    setter,
  }: {
    method: Promise<AxiosResponse>;
    methodType: EMethod;
    title: string;
    name: string;
    setter: Dispatch<SetStateAction<TTrackNotification[] | TTrack[] | TCoin[] | TWallet[]>>;
  }) => {
    setIsLoading(true);
    const { response, error } = await fetchRequest(method);
    if (response) {
      const idField = `${name?.toLowerCase()}_id`;

      if (methodType === EMethod.CREATE) {
        setter((prev) => {
          if (!!prev?.length) {
            return [...prev, response];
          } else {
            return [response];
          }
        });
      }
      if (methodType === EMethod.UPDATE) {
        setter((prev) => {
          return prev.map((el) => (el[idField] === response[idField] ? response : el));
        });
      }
      if (methodType === EMethod.DELETE) {
        // eslint-disable-next-line
        setter((prev: any) => {
          if (idField in prev[0] && idField in response) {
            const arr = [...prev];
            return arr?.filter((el) => el[idField] !== response[idField]);
          }
        });
      }
    }
    if (error) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText || "Не удалось", request: title })
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    baseFetch({
      getMethod: controlApi.getConditions(),
      setter: (res) => setConditions(Object.values(res)),
      name: "Condition",
    });
    baseFetch({
      getMethod: controlApi.getNetworks(),
      setter: (res) => setNetworks(Object.values(res)),
      name: "Network",
    });
    baseFetch({ getMethod: controlApi.getWallets(), setter: setWallets, name: "Wallet" });
    baseFetch({ getMethod: controlApi.getCoins(), setter: setCoins, name: "Coin" });
    baseFetch({
      getMethod: controlApi.getNotifications(),
      setter: setNotifications,
      name: "Notification",
    });
    baseFetch({ getMethod: controlApi.getTracks(), setter: setTracks, name: "Track" });
  }, []);

  return (
    <div className="flex flex-col lg:grid lg:grid-cols-[2fr_1fr] gap-[20px] pb-[200px]">
      <div>
        <CreateWallet
          isLoading={isLoading}
          networks={networks}
          networkColor={networkColor}
          updFn={async (newWallet) => {
            await baseRequest({
              method: controlApi.createWallet(newWallet),
              methodType: EMethod.CREATE,
              title: "Создание кошелька",
              name: "Wallet",
              setter: setWallets,
            });
          }}
        />
        <div className={`flex flex-wrap gap-8 mt-8`}>
          {wallets?.map((el) => (
            <Wallet
              key={el.wallet_id}
              item={el}
              baseRequest={baseRequest}
              networkColor={networkColor}
              isLoading={isLoading}
              setWallets={setWallets}
              networks={networks}
              tracks={tracks}
              setTracks={setTracks}
              coins={coins}
              conditions={conditions}
              notifications={notifications}
              setNotifications={setNotifications}
            />
          ))}
        </div>
      </div>
      <div className="w-fit">
        <CreateCoin
          isLoading={isLoading}
          networks={networks}
          networkColor={networkColor}
          updFn={async (newCoin) => {
            await baseRequest({
              method: controlApi.createCoin(newCoin),
              methodType: EMethod.CREATE,
              title: "Создание монеты",
              name: "Coin",
              setter: setCoins,
            });
          }}
        />
        <div className={`flex flex-col gap-8 mt-8`}>
          {coins?.map((el) => (
            <Coin
              key={el.coin_id}
              item={el}
              baseRequest={baseRequest}
              networkColor={networkColor}
              isLoading={isLoading}
              setCoins={setCoins}
              networks={networks}
            />
          ))}
        </div>
      </div>
      {isLoading && (
        <div className="fixed w-[95%] flex justify-center text-center top-[40%] left-[50%] z-20 translate-x-[-50%]">
          <Spinner />
        </div>
      )}
      {!wallets?.length && isGood !== null && (
        <div className="mt-[80px]">
          {isGood ? (
            <GoodEmptiness message="Все хорошо. Нет данных по запросу" />
          ) : (
            <Emptiness message="Ошибка сервиса" />
          )}
        </div>
      )}
    </div>
  );
};
