import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import { OFFSET, convertToShortDate } from "src/helpers/date";
import { fetchRequest } from "src/helpers/fetchRequest";
import { BASE_URL, ROLE, STATIC_TOKEN } from "src/api/constants";
import requestApi from "src/api/requests";
import {
  updateRequest as updateRequestRedux,
  TPriorityType,
  TRequest,
  TRequestType,
  TTag,
} from "src/store/commonReducer";
import { CloseButton } from "src/components/UI/buttons/CloseButton";
import { TypeEditor } from "./TypeEditor";
import { VectorsEditor } from "./VectorsEditor";
import { TagsEditor } from "./TagsEditor";
import { Deadline } from "./Deadline";
import arrow from "src/assets/images/arrow.svg";
import whiteArrow from "src/assets/images/whiteArrow.svg";
import fresh from "src/assets/images/fresh.svg";
import admin from "src/assets/images/admin.svg";
import support from "src/assets/images/support.svg";
import { ImageUploader } from "src/components/FormElements/ImageUploader";
import AssetPreview from "src/pages/Requests/components/AssetPreview";
import { setNotific } from "src/store/mainReducer";
import { v4 } from "uuid";
import {
  fetchUnreadReqCount,
  pushNewReqDataToCentrifuge,
} from "src/api/centrifugo/requestsCentrifugo";
import { Loader2 } from "lucide-react";
import { Gallery } from "src/pages/Requests/components/Gallery";
import api from "src/api/http";
import { cn } from "src/utils";

const downloadAssetsOptions = {
  headers: {
    "X-STATIC-TOKEN": STATIC_TOKEN,
  },
  responseType: "blob" as const,
};

export enum ContentType {
  IMAGE = "image",
  VIDEO = "video",
  APPLICATION = "application",
}

export interface Asset {
  id?: number | string;
  path: string;
  content_type: ContentType;
  name?: string;
  url?: string;
}

export interface AssetClient extends Asset {
  file?: File;
}

export const getVideoDuration = async (file: File): Promise<number> => {
  const blobUrl = URL.createObjectURL(file);

  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.src = blobUrl;

    video.onloadedmetadata = () => {
      resolve(video.duration);
      URL.revokeObjectURL(blobUrl);
    };
  });
};

export const getAssetFromFile = async (file: File): Promise<AssetClient> => {
  let content_type: ContentType = ContentType.APPLICATION;
  let path: string | undefined;

  if (file.type.includes("image")) {
    content_type = ContentType.IMAGE;
    if (file.type.includes("svg")) {
      path = URL.createObjectURL(file);
    } else {
      path = URL.createObjectURL(new Blob([file]));
    }
  } else if (file.type.includes("video")) {
    path = URL.createObjectURL(new Blob([file]));
    content_type = ContentType.VIDEO;
  } else {
    path = URL.createObjectURL(new Blob([file], { type: "application/pdf" }));
  }

  return {
    id: v4(),
    content_type,
    name: file.name?.replaceAll(" ", ""),
    file,
    path,
  };
};

export const Request = ({
  item,
  requests,
  userAccess,
  isDelay,
  setDelay,
  delayTime,
  types,
  priorities,
  tags,
  dndProvider,
  isFull,
  username,
  role,
}) => {
  const dispatch = useDispatch();

  const adminCamp = [ROLE.ADMIN, ROLE.SUPPORT, ROLE.MINI_ADMIN];
  const isAdminCampCreator = !!item?.creator_role && adminCamp?.includes(item?.creator_role);
  const { register, setValue, watch } = useFormContext();
  const now = Math.floor((Date.now() - OFFSET * 1000) / 1000);
  const [isOpen, setOpen] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [currentImage, setCurrentImage] = useState({ src: "", index: 0 });
  const [isEditTitle, setEditTitle] = useState(false);
  const [isEditTags, setEditTags] = useState(false);
  const [isEditDescription, setEditDescription] = useState(false);
  const [currentType, setCurrentType] = useState<TRequestType>(item?.type);
  const [currentPriority, setCurrentPriority] = useState<TPriorityType>(item?.priority);
  const [selectedTags, setSelectedTags] = useState<Array<TTag>>(item?.tags);
  const [savedSites, setSavedSites] = useState<Array<string>>(item?.sites);
  const itemVectors = useMemo(() => item?.vectors?.map((el) => el.vector), [item]);
  const [savedVectors, setSavedVectors] = useState<Array<string>>(itemVectors);
  const [assets, setAssets] = useState<AssetClient[]>([]);
  const editAccess = userAccess?.includes("it_requests_patch") || item?.creator === username;
  const approveAccess = userAccess?.includes("it_requests_approve");
  const [assetsLoading, setAssetsLoading] = useState(true);

  const assetsModified =
    assets.length !== (item.assets || []).length ||
    assets.some((asset) => typeof asset.id === "string");

  const photos = assets.filter(
    (item) => item.content_type === ContentType.IMAGE || item.content_type === ContentType.VIDEO
  );
  const notModified = useMemo(() => {
    return (
      (watch(`${item.id}.title`) === undefined ||
        item?.title === watch(`${item.id}.title`) ||
        !watch(`${item.id}.title`)?.length) &&
      (watch(`${item.id}.description`) === undefined ||
        item?.description === watch(`${item.id}.description`) ||
        !watch(`${item.id}.description`)?.length) &&
      (watch(`${item.id}.deadline`) === undefined ||
        +item?.deadline === +new Date(watch(`${item.id}.deadline`)) ||
        !watch(`${item.id}.deadline`)?.length) &&
      (currentType === undefined || currentType === item?.type) &&
      (currentPriority === undefined || currentPriority === item?.priority) &&
      JSON.stringify(selectedTags) === JSON.stringify(item?.tags) &&
      JSON.stringify(savedSites) === JSON.stringify(item?.sites) &&
      JSON.stringify(savedVectors) === JSON.stringify(itemVectors)
    );
  }, [
    watch(`${item.id}.title`),
    watch(`${item.id}.description`),
    watch(`${item.id}.deadline`),
    item,
    currentType,
    assets,
    currentPriority,
    selectedTags,
    savedSites,
    savedVectors,
    assetsModified,
  ]);

  const disabled = (!assetsModified && notModified) || isDelay;
  const commentExist = !!watch(`${item.id}.comment`)?.length;
  const id = item.id?.toString();

  const addTags = (e) => {
    if (!selectedTags?.find((el) => el.id === e.value)) {
      setSelectedTags(
        !!selectedTags
          ? [...selectedTags, { id: e.value, tag: e.label }]
          : [{ id: e.value, tag: e.label }]
      );
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const { files } = e.target;

    if (files?.length) {
      const newAssets = await Promise.all(Object.values(files).map(getAssetFromFile));
      setAssets((prev) => {
        return [
          ...prev.filter((item) => !newAssets.some((asset) => asset.name === item.name)),
          ...newAssets,
        ];
      });
    }
  };

  const handlePaste = async (e) => {
    if (e?.clipboardData?.items?.length) {
      const file = e?.clipboardData?.items[0]?.getAsFile();
      if (!!file) {
        const newFile = await getAssetFromFile(file);
        setAssets((prev) => [...prev, newFile]);
      }
    }
  };

  const updateRequest = async () => {
    const new_assets = assets.filter((asset) => !!asset.file);
    const deleted_assets =
      item.assets?.filter((asset) => !assets.some((el) => el.id === asset.id)) || [];

    if (deleted_assets.length > 0) {
      const { error } = await fetchRequest(
        requestApi.deleteAsssets(deleted_assets.map((item) => item.id))
      );
      if (!error) {
        setAssets((prev) => {
          const newAssets = prev.filter((item) => !deleted_assets.some((el) => el.id === item.id));
          return newAssets;
        });
      }
    }

    if (assets?.length <= 10) {
      setDelay(true);
      const payload = {
        ...item,
        assets: [],
        title: watch(`${item.id}.title`) === undefined ? item?.title : watch(`${item.id}.title`),
        description:
          watch(`${item.id}.description`) === undefined
            ? item?.description
            : watch(`${item.id}.description`),
        deadline:
          watch(`${item.id}.deadline`) === undefined
            ? item?.deadline
            : +new Date(watch(`${item.id}.deadline`)),
        type: currentType,
        priority: currentPriority,
        status: item.status,
        has_assets: new_assets.length > 0,
        is_tags: !!selectedTags?.length,
        tags: selectedTags || [],
        sites: savedSites,
        is_all_vectors: !savedVectors?.length,
        vectors: savedVectors?.map((el) => ({ vector: el })),
        updator: username,
      };

      const { response, error } = await fetchRequest(requestApi.updateRequest(payload), {
        request: "Обновление запроса",
      });
      if (response) {
        // сразу обновляем реквест, не учитывая ассеты
        pushNewReqDataToCentrifuge({ type: "update", item: { ...response, assets: [] } }, () =>
          dispatch(updateRequestRedux({ ...response, assets: [] }))
        );
        if (new_assets.length > 0) {
          try {
            const { clientAssets, serverAssets } = await uploadAssets(response.id, new_assets);
            // если ассеты загрузились успешно - добавляем их к измененному реквесту
            pushNewReqDataToCentrifuge(
              { type: "update", item: { ...response, assets: serverAssets } },
              () => dispatch(updateRequestRedux({ ...response, assets: clientAssets }))
            );
            setAssets(clientAssets);
          } catch {
            dispatch(
              setNotific({
                type: "error",
                message: "Ошибка при загрузке файлов на сервер, попробуйте снова",
              })
            );
          }
        } else {
          const newData = { ...response, assets: response.assets || [] };
          pushNewReqDataToCentrifuge({ type: "update", item: newData }, () =>
            dispatch(updateRequestRedux(newData))
          );
        }
      }
      if (error) {
        setCurrentType(item?.type);
        setCurrentPriority(item?.priority);
      }
      setDelay(false);
      setEditTitle(false);
      setEditDescription(false);
      setTimeout(() => setDelay(false), delayTime);
    }
  };

  const addComment = async () => {
    setDelay(true);
    const payload = {
      author: username,
      author_role: role,
      comment: watch(`${item.id}.comment`),
      request_id: item.id,
    };
    const { response } = await fetchRequest(requestApi.addComment(payload), {
      request: "Отправка комментария",
    });
    if (response) {
      const oldRequest = requests?.find((item) => item.id === response.request_id);
      if (oldRequest) {
        const newReq = {
          ...oldRequest,
          updator: username,
          comments: true,
          comments_objects: [...oldRequest.comments_objects, response],
        };
        pushNewReqDataToCentrifuge({ type: "update", item: newReq }, () =>
          dispatch(updateRequestRedux(newReq))
        );
        setValue(`${item.id}.comment`, "");
      } else {
        dispatch(
          setNotific({
            type: "error",
            message: "Запрос не найден",
          })
        );
      }
    }
    setTimeout(() => setDelay(false), delayTime);
  };

  const deleteAsset = (id: number | string) => {
    setAssets((prev) => prev.filter((asset) => asset.id !== id));
  };

  const markAsViewed = async (id: TRequest["id"]) => {
    try {
      const { response, error } = await fetchRequest(requestApi.markAsViwed(id));
      if (error || !response) {
        dispatch(
          setNotific({
            type: "error",
            message: "Ошибка при обновлении статуса просмотра запроса",
          })
        );
        return;
      }
      fetchUnreadReqCount();

      dispatch(updateRequestRedux({ id, is_viewed: true }));
    } catch {
      dispatch(
        setNotific({
          type: "error",
          message: "Ошибка при обновлении статуса просмотра запроса",
        })
      );
    }
  };

  const downloadImage = async (assetUrl: string): Promise<string> => {
    try {
      const { data } = await api.get(
        (BASE_URL + assetUrl).replace("api/", ""),
        downloadAssetsOptions
      );
      const img = URL.createObjectURL(data);
      return img;
    } catch {
      dispatch(
        setNotific({
          type: "error",
          message: "Ошибка при загрузке картинки",
        })
      );
      return "";
    }
  };

  const downloadFile = async (assetUrl: string): Promise<string> => {
    try {
      const response = await api.get(
        (BASE_URL + assetUrl).replace("api/", ""),
        downloadAssetsOptions
      );
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const dataUrl = URL.createObjectURL(blob);
      return dataUrl;
    } catch {
      dispatch(
        setNotific({
          type: "error",
          message: "Ошибка при загрузке файла",
        })
      );
      return "";
    }
  };

  useEffect(() => {
    if (!!item) {
      currentType !== item?.type && setCurrentType(item?.type);
      currentPriority !== item?.priority && setCurrentPriority(item?.priority);

      setEditTitle(false);
      setEditDescription(false);
    }
    if (isOpen) {
      (async () => {
        if (
          item.assets &&
          ((item.assets.length > 0 && item.assets.length !== assets.length && isOpen) ||
            item.assets.some((asset) => !assets.some((item) => item.name === asset.name)))
        ) {
          setAssetsLoading(true);
          const newAssets = await downloadAssets(item.assets);
          setAssets(newAssets);
        }
        setAssetsLoading(false);
      })();
    }
  }, [item, isOpen]);

  const downloadAssets = async (assets: Asset[]) => {
    if (!assets || assets.length === 0) return [];
    if (assets && assets.length > 0) {
      const finalAssets: AssetClient[] = [];

      for (const asset of assets) {
        let src: string;
        const type = asset.content_type?.includes("image")
          ? ContentType.IMAGE
          : asset.content_type?.includes("video")
          ? ContentType.VIDEO
          : ContentType.APPLICATION;
        if (asset.content_type === ContentType.IMAGE || asset.content_type === ContentType.VIDEO) {
          src = asset.path?.includes("blob") ? asset.path : await downloadImage(asset.path);
        } else if (asset.content_type === ContentType.APPLICATION) {
          src = asset.path?.includes("blob") ? asset.path : await downloadFile(asset.path);
        }
        finalAssets.push({
          ...asset,
          path: src,
          content_type: type,
        });
      }
      return finalAssets;
    }
  };

  useEffect(() => {
    if (isOpen && !!item?.comments_objects?.length) {
      const commentsBlock = document.getElementById(`comments_${item.id}`);
      if (!!commentsBlock) {
        commentsBlock?.scroll(0, commentsBlock.scrollHeight);
      }
    }
  }, [isOpen, item?.comments_objects]);

  const uploadAssets = async (requestId: number, assets: AssetClient[]) => {
    const videos = assets.filter((asset) => asset.content_type === ContentType.VIDEO);
    for (const vid of videos) {
      const duration = await getVideoDuration(vid.file);
      if (duration > 600) {
        dispatch(
          setNotific({
            type: "error",
            message: "Видео не должны быть длиннее 10 минут",
          })
        );
        return;
      }
    }
    const formData = new FormData();
    assets.forEach((asset) => {
      formData.append("files", asset.file);
    });
    const { response, error } = await fetchRequest(requestApi.uploadAssets(requestId, formData));
    if (!response || error) {
      throw new Error("Ошибка при загрузке файлов на сервер, попробуйте снова");
    }
    const clientAssets = await downloadAssets(response);
    return {
      serverAssets: response,
      clientAssets,
    };
  };

  const toggleIsOpen = async () => {
    if (!isOpen && !item.is_viewed) {
      await markAsViewed(item.id);
    }
    setOpen((prev) => !prev);
  };

  return (
    <div
      className={`relative rounded-sm flex flex-col duration-300 text-xs w-full text-left p-8 ${
        isOpen ? "outline outline-2 outline-[#FCC815] outline-offset-2 my-4" : ""
      } ${
        !item.is_viewed
          ? "bg-[#D5FBEC]"
          : !notModified
          ? "bg-[#E6F1FF]"
          : isAdminCampCreator
          ? "bg-[#EAF0FD]"
          : "bg-bg"
      }`}
      {...dndProvider.dragHandleProps}>
      <div
        className={`flex items-center top-0 left-0 w-full h-full bg-stale backdrop-blur-[1px] rounded-lg duration-500 ${
          isDelay ? "z-30 opacity-70" : "-z-10 opacity-0"
        }`}
      />
      <div className="flex flex-col gap-8 w-full overflow-hidden">
        {isEditTitle ? (
          <div className="flex items-center gap-4 relative -top-[3px] w-full overflow-hidden">
            <input
              className="w-full border border-[#282c3433] rounded-sm py-4 px-8 max-h-[24px]"
              defaultValue={item?.title}
              onKeyDown={async (e) => {
                if (e.keyCode === 13) {
                  await updateRequest();
                }
              }}
              {...register(`${item.id}.title`, { required: true })}
            />
            <CloseButton setFn={setEditTitle} />
          </div>
        ) : (
          <div className="flex gap-[6px] justify-between">
            <div className="flex gap-[6px] items-start">
              <div className="flex gap-[6px] items-center">
                <div className="flex items-center justify-center w-[20px] min-w-[20px] h-[16px] text-center rounded-full text-lightFont bg-[#E0E0E8] z-[1] text-[10px] font-semibold">
                  {id.substring(id.length - 3)}
                </div>
                <TypeEditor
                  disabled={!isOpen}
                  currentType={currentType}
                  setCurrentType={setCurrentType}
                  isAccess={editAccess}
                  types={types}
                />
                <TypeEditor
                  disabled={!isOpen}
                  currentType={currentPriority}
                  setCurrentType={setCurrentPriority}
                  isAccess={editAccess}
                  types={priorities}
                />
              </div>
              <div
                className={`font-bold w-full overflow-hidden pt-2 ${
                  editAccess && isOpen ? "cursor-pointer" : "cursor-default"
                }`}>
                <p
                  className="w-full max-w-[160px] md:max-w-none"
                  onClick={() => {
                    if (editAccess && isOpen) {
                      setEditTitle(true);
                    }
                  }}>
                  {item?.title}
                </p>
              </div>
            </div>
            <div className="flex gap-2 absolute right-[4px]">
              {now - item?.updated_timestamp < 3600 && (
                <div className="h-[16px] min-w-[16px] w-fit rounded-[10px] justify-center items-center flex gap-4 bg-[#40AEF0]">
                  <img
                    src={fresh}
                    alt="Обновлено"
                    width={14}
                    height={14}
                    className="relative right-[0.5px]"
                  />
                </div>
              )}
              {!item.is_viewed && (
                <div className="relative top-[3px] h-[10px] min-w-[10px] w-fit rounded-[10px] bg-[#F33] ml-4" />
              )}
              <button
                type="button"
                className={cn(
                  "flex bg-bg justify-center px-[4px] items-center w-[22px] h-[22px] min-w-[2px] -mt-2",
                  !item.is_viewed && "bg-[#D5FBEC]",
                  !notModified && "bg-[#E6F1FF]"
                )}
                onClick={toggleIsOpen}>
                <img
                  src={arrow}
                  alt="раскрыть"
                  width={10}
                  height={10}
                  className={`duration-300 ${isOpen ? "rotate-180" : ""}`}
                />
              </button>
            </div>
          </div>
        )}
      </div>
      {currentType === "задача" && item.status !== "готово" && item.status !== "отклонено" && (
        <Deadline item={item} now={now} updateRequest={updateRequest} />
      )}
      <div className="flex gap-8 items-center justify-between flex-nowrap whitespace-pre text-[10px] pt-8">
        <div>{convertToShortDate(item?.updated_timestamp)}</div>
        <div className="font-semibold leading-3">{item.updator}</div>
      </div>
      <div
        className={`duration-700 overflow-hidden -mx-4 px-4 ${
          isOpen ? "max-h-[2000px] mt-[10px]" : "max-h-0"
        }`}>
        <div
          className={`flex flex-col duration-300 ${!editAccess || isEditTags ? "gap-4" : "gap-0"}`}>
          {isOpen && editAccess && (
            <button
              type="button"
              className="self-start font-semibold text-lightFont text-[10px] -mb-8 py-[6px] hover:opacity-80"
              onClick={() => setEditTags(!isEditTags)}>
              Редактировать
            </button>
          )}
          <TagsEditor
            tags={tags}
            setTags={addTags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            isAddVisible={isOpen && isEditTags}
            access={editAccess}
          />
          <VectorsEditor
            savedSites={savedSites}
            setSavedSites={setSavedSites}
            savedVectors={savedVectors}
            setSavedVectors={setSavedVectors}
            isAddVisible={isOpen && isEditTags}
            access={editAccess}
            isFull={isFull}
          />
        </div>
        <div className="grid grid-cols-[36px_1fr] gap-4 my-[16px] pr-[18px]">
          {editAccess && (
            <ImageUploader
              handleUpload={handleUpload}
              handlePaste={handlePaste}
              filesLength={assets.length}
            />
          )}
          <div className="flex items-start flex-wrap gap-4">
            {isOpen &&
              !!assets?.length &&
              assets?.map((asset) => (
                <AssetPreview
                  key={asset.id}
                  canEdit={editAccess}
                  notModified={notModified}
                  deleteAsset={deleteAsset}
                  photosIndex={photos.findIndex((item) => item.id === asset.id)}
                  asset={asset}
                  setCurrentImage={setCurrentImage}
                  setPopup={setPopup}
                  isNew={typeof asset.id === "string"}
                />
              ))}
            {assetsLoading && (
              <div className="w-[68px] h-[68px] flex items-center justify-center">
                <Loader2 className="animate-spin w-[26px] h-[26px] text-[#40CEF0]" />
              </div>
            )}
          </div>
        </div>
        <div
          className={`font-semibold text-lightFont text-[10px] leading-3 ${
            editAccess && "cursor-pointer"
          }`}
          onClick={() => {
            if (editAccess) {
              setEditDescription(true);
            }
          }}>
          Описание
        </div>
        {isEditDescription ? (
          <div className="flex gap-4">
            <textarea
              className="w-full border border-[#282c3433] rounded-sm py-4 px-8 h-[100px] mb-8"
              defaultValue={item?.description}
              onKeyDown={async (e) => {
                if (e.keyCode === 13) {
                  await updateRequest();
                }
              }}
              {...register(`${item.id}.description`)}
            />
            <CloseButton setFn={setEditDescription} />
          </div>
        ) : (
          <div
            className={`${editAccess && "cursor-pointer"}`}
            onClick={() => {
              if (editAccess) {
                setEditDescription(true);
              }
            }}>
            {item?.description || "-"}
          </div>
        )}
        <div id={`request_${item.id}`} className="relative flex flex-col gap-4 my-8 w-full">
          <div className="relative max-w-full">
            {item?.comments && (
              <div
                id={`comments_${item.id}`}
                className="max-w-full relative bg-[#EAEAF3] rounded-sm align-bottom flex flex-col flex-end items-end gap-4 p-8 mr-[20px] max-h-[40vh] overflow-y-auto">
                {item?.comments_objects?.map((el) => {
                  const isAdminComment = adminCamp?.includes(el?.author_role);
                  return (
                    <div
                      key={el.id}
                      className={`relative max-w-full ${
                        isAdminComment ? "self-start" : ""
                      } rounded-sm bg-bg px-8 py-4 h-fit`}>
                      {el.comment?.split(" ")?.map((substr, id) => (
                        <span
                          key={`${el.id}_${id}`}
                          className={`w-full break-words ${
                            substr?.includes("@")
                              ? "text-[#3BC57A] pb-2 px-4 rounded-lg bg-[#BBFFDAAA] font-semibold"
                              : ""
                          }`}>
                          {substr + " "}
                        </span>
                      ))}
                      <div className="flex gap-[10px] items-baseline justify-end text-lightFont text-right">
                        <div className="text-[10px]">{convertToShortDate(el.time)}</div>
                        <div
                          className={`font-semibold ${
                            el.author === username ? "text-[#3BC57A]" : ""
                          }`}>
                          {el.author}
                        </div>
                        {isAdminComment && (
                          <div className={`relative top-4 opacity-40 -mx-2`}>
                            <img
                              src={
                                el?.author_role === ROLE.ADMIN ||
                                el?.author_role === ROLE.MINI_ADMIN
                                  ? admin
                                  : support
                              }
                              alt="role"
                              width={16}
                              height={16}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {(editAccess || approveAccess) && (
            <div className="flex gap-4 items-end">
              <textarea
                className="w-full border border-[#282c3433] rounded-sm py-4 px-8 h-full"
                placeholder="Добавьте комментарий"
                onKeyDown={async (e) => {
                  if (e.keyCode === 13 && commentExist) {
                    await addComment();
                  }
                }}
                {...register(`${item.id}.comment`)}
              />
              <button
                type="button"
                className={`flex items-center justify-center rounded-sm w-[16px] min-w-[16px] h-[16px] duration-300 ${
                  commentExist ? "bg-[#3BC57A]" : "bg-[#EAEAF3] opacity-25"
                }`}
                disabled={!commentExist || isDelay}
                onClick={addComment}>
                <img
                  src={whiteArrow}
                  alt="отправить"
                  width={9}
                  height={9}
                  className="relative left-[1px] -rotate-90"
                />
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-between items-baseline">
          <button
            type="button"
            onClick={updateRequest}
            className={`rounded-sm px-8 bg-font text-white font-semibold py-0 duration-500 hover:opacity-80`}
            disabled={disabled}>
            {isDelay ? (
              <Loader2 className="text-blue h-[16px] w-[16px] animate-spin mx-[20px]" />
            ) : (
              "Обновить"
            )}
          </button>
          <div className="flex items-baseline gap-8 text-[10px]">
            <div>Создано: {convertToShortDate(item?.created_timestamp)}</div>
            <div className="font-semibold leading-3">{item.creator}</div>
          </div>
        </div>
      </div>
      <Gallery
        photos={photos}
        isPopup={isPopup}
        setPopup={setPopup}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
      />
    </div>
  );
};
