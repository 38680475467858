import { NavLink } from "react-router-dom";

export const BreadCrumbs = ({ routeMap }) => {
  return (
    <div className="flex gap-4 text-xs text-lightGray font-light mb-[10px] items-baseline">
      {routeMap?.map((el, index) => (
        <NavLink key={index} to={el?.route} className={`hover:text-lightFont`}>
          / {el?.title}
        </NavLink>
      ))}
    </div>
  );
};
