import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { LS, ROLE } from "src/api/constants";
import clusterApi from "src/api/cluster";
import { setTimelineByCentrifugo } from "src/api/centrifugo/timelineCentrifugo";
import { setTimeline, TTimeline } from "src/store/clusterReducer";
import { Popup } from "src/components/Popup/Popup";
import { ZoomButtons } from "src/components/UI/buttons/ZoomButtons";
import { WideButtons } from "src/components/UI/buttons/WideButtons";
import { Info } from "./Info";
import copy from "src/assets/images/copy.svg";
import bin from "src/assets/images/bin.svg";
import close from "src/assets/images/close.svg";
import paper from "src/assets/images/paper.svg";

type TProps = {
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
  isCopy: boolean;
  setIsCopy: Dispatch<SetStateAction<boolean>>;
  isDelete: boolean;
  setIsDelete: Dispatch<SetStateAction<boolean>>;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  isOpenCities: boolean;
  setIsOpenCities: Dispatch<SetStateAction<boolean>>;
  expand: boolean;
  setExpand: Dispatch<SetStateAction<boolean>>;
  openTemplates: Dispatch<SetStateAction<boolean>>;
  setActionsCount: Dispatch<SetStateAction<number>>;
  isBest: boolean;
  setIsBest: Dispatch<SetStateAction<boolean>>;
  fetchTimeline: (isClearMyChanges: boolean) => void;
  isSitesMenuOpen: boolean;
  isTemplate: boolean;
};

export const BoardMenu = ({
  zoom,
  setZoom,
  isCopy,
  setIsCopy,
  isDelete,
  setIsDelete,
  fetchTimeline,
  isBest,
  setIsBest,
  step,
  setStep,
  isOpenCities,
  setIsOpenCities,
  expand,
  setExpand,
  isSitesMenuOpen,
  openTemplates,
  setActionsCount,
  isTemplate,
}: TProps) => {
  const dispatch = useDispatch();
  const role = localStorage.getItem(LS.ROLE);
  const [isInfo, setIsInfo] = useState(false);
  const [isCentrifugoConfirm, setCentrifugoConfirm] = useState(false);

  const [isClearConfirm, setClearConfirm] = useState(false);
  const steps = [10, 30, 60];

  const fetchClearTimeline = async () => {
    const { response } = await fetchRequest(clusterApi.getTimeline(), { request: "Таймлайн" });
    if (response) {
      dispatch(setTimeline(response));
      response?.forEach((el: TTimeline) => setTimelineByCentrifugo(el, el.city_id));
    }
  };

  return (
    <div
      className={`fixed rounded-b-md duration-300 ${
        !isSitesMenuOpen ? "w-[calc(100%-20px)]" : "w-[calc(100%-200px)]"
      } flex flex-col xl:flex-row gap-y-4 gap-x-8 bg-bg z-20 pt-[32px] pr-[20px] pl-8 -ml-8 pb-8 -mt-[32px]`}>
      <div className="flex gap-4">
        {(role === ROLE.ADMIN || role === ROLE.MINI_ADMIN) && !isTemplate && (
          <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
            <button
              type="button"
              className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px] bg-bg duration-300 hover:bg-[#E7B4AE]`}
              onClick={() => {
                setCentrifugoConfirm(true);
                setActionsCount(0);
              }}>
              <img
                src={close}
                alt="обновить"
                width={18}
                height={18}
                className="grayscale brightness-90"
              />
            </button>
          </div>
        )}
        {!isTemplate && (
          <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
            <button
              type="button"
              className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px] bg-bg duration-300 hover:bg-[#E7B4AE]`}
              onClick={() => {
                setClearConfirm(true);
                setActionsCount(0);
              }}>
              <img src={close} alt="обновить" width={18} height={18} />
            </button>
          </div>
        )}
        <div className="rounded-sm bg-lightGray p-2 w-fit text-[10px] text-lightFont">
          <button
            type="button"
            className={`flex justify-center items-center gap-4 rounded-sm h-[22px] pl-8 pr-4 bg-bg duration-300 font-bold hover:shadow-inset`}
            onClick={() => openTemplates(true)}>
            ШАБЛОНЫ
            <img src={paper} alt="ШАБЛОНЫ" width={16} height={16} />
          </button>
        </div>
        <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
          <button
            type="button"
            className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px] px-8 bg-bg duration-300 hover:shadow-inset`}
            onClick={() => setIsInfo(true)}>
            i
          </button>
        </div>
        <ZoomButtons value={zoom} setValue={setZoom} step={10} min={30} />
        <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
          <button
            type="button"
            className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px] bg-bg duration-300 hover:shadow-inset ${
              isCopy ? "bg-[#B2FFDE]" : ""
            }`}
            onClick={() => {
              setIsCopy(!isCopy);
              setIsDelete(false);
            }}>
            <img src={copy} alt="скопировать" width={14} height={14} className="opacity-70" />
          </button>
        </div>
        <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
          <button
            type="button"
            className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px] duration-300 hover:shadow-inset ${
              isDelete ? "bg-[#E7B4AE]" : "bg-bg"
            }`}
            onClick={() => {
              setIsDelete(!isDelete);
              setIsCopy(false);
            }}>
            <img src={bin} alt="удалить" width={14} height={14} />
          </button>
        </div>
        <div className="flex gap-2 rounded-sm bg-stale p-2 w-fit text-[10px] text-lightFont">
          <button
            type="button"
            className={`flex justify-center items-center rounded-sm h-[22px] px-8 bg-bg duration-300 hover:shadow-inset ${
              isBest ? "" : "opacity-70 brightness-[1.4]"
            }`}
            onClick={() => setIsBest(!isBest)}>
            BEST
          </button>
        </div>
        <div className="flex gap-2 rounded-sm bg-stale p-2 w-fit text-[10px] text-lightFont">
          <button
            type="button"
            className={`flex justify-center items-center rounded-sm h-[22px] min-w-[120px] px-8 bg-bg duration-300 hover:shadow-inset`}
            onClick={() => setIsOpenCities(!isOpenCities)}>
            {isOpenCities ? "скрыть все города" : "показать все города"}
          </button>
        </div>
        <div className="flex gap-2 rounded-sm bg-stale p-2 w-fit text-[10px] text-lightFont">
          {steps?.map((el) => (
            <button
              key={el}
              type="button"
              className={`flex justify-center items-center rounded-sm h-[22px] w-fit min-w-[22px] px-4 bg-bg duration-300 hover:shadow-inset ${
                step === el ? "font-bold" : "opacity-70 brightness-[1.4]"
              }`}
              onClick={() => setStep(el)}>
              {el}m
            </button>
          ))}
        </div>
        <WideButtons value={expand} setValue={setExpand} />
      </div>
      {isInfo && (
        <Popup closeModal={() => setIsInfo(false)}>
          <Info />
        </Popup>
      )}
      {isCentrifugoConfirm && (
        <Popup closeModal={() => setCentrifugoConfirm(false)}>
          <div className={"max-w-[300px] flex flex-col gap-[20px] text-sm"}>
            <div>
              Вы уверены, что хотите <b>сбросить</b> все несохраненные изменения всех пользователей?
            </div>
            <div className="flex flex-col gap-8 text-xs text-lightFont">
              <div>Обмен изменениямии пользователей сейчас находится в стадии тестирования.</div>
              <div>
                Перед сбросом предупреждайте коллег, чтобы они успели сохранить свои изменения.
              </div>
            </div>
            <button
              type="button"
              className="w-fit mx-auto prime-button-sm bg-font rounded-sm"
              onClick={async () => {
                setCentrifugoConfirm(false);
                await fetchClearTimeline();
              }}>
              Сбросить все изменения
            </button>
          </div>
        </Popup>
      )}
      {isClearConfirm && (
        <Popup closeModal={() => setClearConfirm(false)}>
          <div className={"max-w-[300px] flex flex-col gap-[20px] text-sm"}>
            <div>
              Вы уверены, что хотите <b>сбросить</b> все свои несохраненные изменения?
            </div>
            <div className="flex flex-col gap-8 text-xs text-lightFont">
              <div>Обмен изменениямии пользователей сейчас находится в стадии тестирования.</div>
              <div>Изменения других пользователей останутся.</div>
            </div>
            <button
              type="button"
              className="w-fit mx-auto prime-button-sm bg-font rounded-sm"
              onClick={async () => {
                setClearConfirm(false);
                await fetchTimeline(true);
              }}>
              Сбросить свои изменения
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
