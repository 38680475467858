import { Dispatch, SetStateAction } from "react";
import { TMinMaxRegion } from "src/store/directionsReducer";
import { ColorTip } from "src/components/UI/items/ColorTip";

type TProps = {
  regions: Array<TMinMaxRegion>;
  currentRegion: TMinMaxRegion;
  setCurrentRegion: Dispatch<SetStateAction<TMinMaxRegion>>;
};

export const Regions = ({ regions, currentRegion, setCurrentRegion }: TProps) => {
  return (
    <div className="flex flex-col gap-8 text-xs text-lightFont whitespace-pre">
      <div className="flex gap-8 self-end">
        <div className="flex gap-8 items-center">
          <div className="h-[16px] w-[16px] flex justify-center bg-[#3BC57A] rounded-sm text-[#BBFFDA] font-bold text-[20px] leading-[22px]">
            *
          </div>
          <div>Есть исключения</div>
        </div>
        <ColorTip color="#DADDD8" title="На бесте и на exnode" />
        <ColorTip color="#E1E7F2" title="На бесте" />
        <ColorTip color="#E1F2E7" title="На exnode" />
        <ColorTip color="#FAFAFF" title="Не на бесте и не на exnode" />
      </div>
      <div className="flex gap-8 self-start">
        {regions?.map((el) => (
          <button
            type="button"
            key={el.region_id}
            className={`${
              currentRegion.region_id === el.region_id ? "bg-bg font-semibold" : "bg-[#BABAC322]"
            } rounded-t-sm border-t border-x border-lightGray px-8 duration-300 hover:opacity-70`}
            onClick={() => setCurrentRegion(el)}>
            {el.region_name === "Объединенные Арабские Эмираты" ? "ОАЭ" : el.region_name}
          </button>
        ))}
      </div>
    </div>
  );
};
