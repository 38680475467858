import { ICity, ITimeSegment } from "src/types/OnOffTimeline/common";
import { TSelectedPair } from "src/store/onOffReducer";
import { AUTO_CITY_CODE, AUTO_CITY_NAME } from "src/api/constants";

type Data = {
  pairs: TSelectedPair[];
  segments_site: ITimeSegment[];
  segments_best: ITimeSegment[];
};

export const convertTetrisData = (data: Data): ICity[] => {
  const map: Record<string, ICity> = {};

  data.pairs = data.pairs.map((pair) => {
    if (!pair.city) {
      pair.city = {
        city_code: AUTO_CITY_CODE,
        city_name: AUTO_CITY_NAME,
        currency_pairs: [],
      };
    }

    return pair;
  });

  data.pairs.forEach((pair) => {
    // информация по 1 сайту,
    // которую можно составить
    // по данным из pair
    const site = {
      segments_best: data.segments_best,
      segments_site: data.segments_site,
      unsubmitted_segments_best: [],
      unsubmitted_segments_site: [],
      site_id: 123,
      site_name: pair.site,
    };

    // информация по 1 паре валют,
    // которую можно составить
    // по данным из pair
    const currencyPair = {
      currency_from: pair.from,
      currency_to: pair.to,
      sites: [site],
    };

    // если город уже учтен в мапе,
    // проводим преобразования с его
    // массивом currency_pairs
    if (map[pair.city.city_code]) {
      // проверяем, учитывается ли уже пара в рамках города
      const existingPairIndex = map[pair.city.city_code].currency_pairs.findIndex(
        (item) =>
          item.currency_from.currency_name === pair.from.currency_name &&
          item.currency_to.currency_name === pair.to.currency_name
      );

      // если пара уже учитывается в городе,
      // дополняем массив ее сайтов
      if (~existingPairIndex) {
        map[pair.city.city_code].currency_pairs[existingPairIndex].sites.push(site);
      }
      // если пара еще не учитывается в городе,
      // добавляем ее в его массив currency_pairs
      else {
        map[pair.city.city_code].currency_pairs.push(currencyPair);
      }
    }
    // если города еще нет нашем мапе,
    // создаем его объект с новой парой валют
    else {
      map[pair.city.city_code] = {
        city_code: pair.city.city_code,
        city_name: pair.city.city_name,
        currency_pairs: [currencyPair],
      };
    }
  });

  return Object.values(map);
};
