import { Url } from "src/api/constants";
import { TChildrenRoute } from "../../Header/components/navigation";
import { DropListItem } from "./DropListItem";

export const DropList = ({
  tabs,
  page,
  isOpen,
  pinned,
  color,
  userAccess,
}: {
  tabs: Array<TChildrenRoute>;
  page: string;
  isOpen: boolean;
  pinned: Array<string>;
  color: string;
  userAccess: Array<string>;
}) => {
  const activeColor = page !== Url.ANALYTICS && page !== Url.CONTROL_DASHBOARD ? color : "#FFF";

  return (
    <div
      className={`flex flex-col relative pl-[34px] duration-500 overflow-hidden ${
        isOpen
          ? "h-fit max-h-fit py-[14px] gap-[10px] opacity-100"
          : "h-0 max-h-0 py-0 gap-0 opacity-0"
      }`}>
      {tabs
        ?.filter((el) => userAccess?.some((access) => el?.access?.includes(access)))
        ?.map((el, index) => (
          <DropListItem
            key={index}
            item={el}
            page={page}
            activeColor={activeColor}
            pinned={pinned}
          />
        ))}
    </div>
  );
};
