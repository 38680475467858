import { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RadioButtons, RadioOption } from "src/components/FormElements/RadioButtons/RadioButtons";
import { Popup } from "src/components/Popup/Popup";
import { TetrisSubmitProps } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/AddSummary";
import { OnOffFiltersType } from "src/pages/Panel/OnOff/OnOffTimeline/components/header/TimelineFilters";
import { selectOnOffLastAppliedFilters } from "src/store/onOffTimelineReducer/selectors";
import { getOnOffData } from "src/store/onOffTimelineReducer/thunks/getOnOffData";
import { useAppDispatch } from "src/store/store";
import useHover from "src/hooks/useHover";
import { cn } from "src/helpers/cn";
import { GlobalFiltersType } from "src/hooks/useGlobalFilters";

interface TetrisSubmitPopupProps {
  sites?: string[];
  closeModal: () => void;
  submit: (props: TetrisSubmitProps) => void;
  isLoading: boolean;
}

export enum ApplyingChangesMode {
  MERGE = "merge",
  MASH = "mash",
}

export enum SubmittingChangesMode {
  PREVIEW = "preview",
  BACKED_IMMEDIATELY = "backed_immediately",
}

enum SubmitParam {
  APPLY = "apply",
  SUBMIT = "submit",
}

export const TetrisSubmitPopup = memo(
  ({ sites, closeModal, submit, isLoading }: TetrisSubmitPopupProps) => {
    const dispatch = useAppDispatch();

    const [isDisabledOptionHovered, { onMouseLeave, onMouseEnter }] = useHover();

    const [submitParams, setSubmitParams] = useState<{
      [SubmitParam.APPLY]: ApplyingChangesMode;
      [SubmitParam.SUBMIT]: SubmittingChangesMode;
    }>({
      [SubmitParam.APPLY]: ApplyingChangesMode.MASH,
      [SubmitParam.SUBMIT]: SubmittingChangesMode.BACKED_IMMEDIATELY,
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const lastAppliedFilters = useSelector(selectOnOffLastAppliedFilters);

    const onParamChange = (
      param: SubmitParam,
      value: ApplyingChangesMode | SubmittingChangesMode
    ) => {
      setSubmitParams((prev) => ({
        ...prev,
        [param]: value,
      }));
    };

    const onSubmit = async () => {
      await submit({
        applyingMode: submitParams[SubmitParam.APPLY],
        submittingMode: submitParams[SubmitParam.SUBMIT],
      });
      closeModal();
    };

    const loadSitesData = () => {
      const sitesFromParams = searchParams.get(GlobalFiltersType.SITES)?.split(",") || [];
      const totalSites = Array.from(new Set([...sitesFromParams, ...sites])).join(",");
      dispatch(getOnOffData({ site_names: totalSites }));
      searchParams.delete(OnOffFiltersType.CITIES);
      searchParams.delete(OnOffFiltersType.FROM);
      searchParams.delete(OnOffFiltersType.TO);
      searchParams.set(OnOffFiltersType.SITES, totalSites);
      setSearchParams(searchParams);
    };

    const notAllSitesAreLoaded =
      !!lastAppliedFilters.cities.length ||
      !!lastAppliedFilters.from.length ||
      !!lastAppliedFilters.to.length ||
      !(sites.length > 0 && sites.every((site) => lastAppliedFilters.sites.includes(site)));

    const submittingChangesOptions: RadioOption<SubmittingChangesMode>[] = useMemo<
      RadioOption<SubmittingChangesMode>[]
    >(
      () => [
        {
          label: "Сначала посмотреть на результат",
          value: SubmittingChangesMode.PREVIEW,
          description:
            "Изменения отобразятся на таймлайне для просмотра, сохранить их можно будет отдельно",
          disabled: notAllSitesAreLoaded,
          disabledReasonCustomComponent: (
            <p className="text-[14px] leading-[14px] text-grayDarka">
              Сначала необходимо запросить все данные по выбранным сайтам
            </p>
          ),
        },
        {
          label: "Отправить данные сразу",
          value: SubmittingChangesMode.BACKED_IMMEDIATELY,
          description: "Изменения сразу отправятся на сервер и будут сохранены",
        },
      ],
      [notAllSitesAreLoaded]
    );

    const applyingChangesOptions: RadioOption<ApplyingChangesMode>[] = [
      {
        label: "Объединить данные",
        value: ApplyingChangesMode.MERGE,
        description:
          "Если какие-то из пар уже есть на таймлайне, новые данные будут объединены со старыми",
        disabled: notAllSitesAreLoaded,
        disabledReasonCustomComponent: (
          <p className="text-[14px] leading-[14px] text-grayDarka">
            Сначала необходимо запросить все данные по выбранным сайтам
          </p>
        ),
      },
      {
        label: "Заменить данные",
        value: ApplyingChangesMode.MASH,
        description:
          "Если какие-то из пар уже есть на таймлайне, старые данные будут полностью заменены новыми",
      },
    ];

    return (
      <Popup closeModal={closeModal}>
        <div className="flex flex-col">
          <h3 className="text-[22px] text-center leading-[20px] mb-[24px]">
            Выберите, как внесенные изменения будут применены
          </h3>
          <div className="flex flex-col gap-[14px] mb-[15px]">
            <RadioButtons
              label="Способ применения изменений"
              options={applyingChangesOptions}
              value={submitParams[SubmitParam.APPLY]}
              onChange={(value) => onParamChange(SubmitParam.APPLY, value)}
              onDisabledOptionMouseEnter={onMouseEnter}
              onDisabledOptionMouseLeave={onMouseLeave}
              readonly={isLoading}
            />
            <RadioButtons
              label="Способ отправки изменений"
              options={submittingChangesOptions}
              value={submitParams[SubmitParam.SUBMIT]}
              onChange={(value) => onParamChange(SubmitParam.SUBMIT, value)}
              onDisabledOptionMouseEnter={onMouseEnter}
              onDisabledOptionMouseLeave={onMouseLeave}
              readonly={isLoading}
            />
          </div>
          <div className="flex items-center justify-center gap-[25px]">
            {notAllSitesAreLoaded && (
              <button
                type="button"
                className={cn(
                  "hover:bg-gray/10 rounded-md duration-100 px-[8px] py-[4px] text-[14px]",
                  isLoading && "pointer-events-none",
                  isDisabledOptionHovered &&
                    "shadow-[0_10px_15px_4px_rgba(0,0,0,0.2)] -translate-y-[1px]"
                )}
                disabled={isLoading}
                onClick={loadSitesData}>
                {isLoading ? "Загрузка..." : "Запросить данные по выбранным сайтам"}
              </button>
            )}
            <button
              type="button"
              className={`w-fit prime-button-sm bg-font rounded-sm ${
                isLoading ? "pointer-events-none" : ""
              }`}
              disabled={isLoading}
              onClick={onSubmit}>
              {isLoading ? "Загрузка..." : "Сохранить"}
            </button>
          </div>
        </div>
      </Popup>
    );
  }
);
