import { addMissingGreenSegments } from "src/helpers/onOffTimeline/copyPaste";
import { ICity, ICurrencyPair, ISite } from "src/types/OnOffTimeline/common";

export const getUnsubmittedCity = (city: ICity, username: string): ICity => {
  return {
    ...city,
    currency_pairs: city.currency_pairs.map((pair) => getUnsubmittedPair(pair, username)),
  };
};

export function getUnsubmittedPair(pair: ICurrencyPair, username: string) {
  return {
    ...pair,
    currency_to: {
      ...pair.currency_to,
    },
    sites: pair.sites.map((site) => getUnsubmittedSite(site, username)),
  };
}

/** конвертирует все сегменты сайта в неподтвержденные изменения */
export function getUnsubmittedSite(site: ISite, username: string) {
  // на всякий случай прогоняем данные через функцию,
  // котора добавит пропущенные сегменты для сайта
  const { segments_best, segments_site } = addMissingGreenSegments({
    segments_site: site.segments_site,
    segments_best: site.segments_best,
  });

  return {
    ...site,
    segments_best: [],
    segments_site: [],
    unsubmitted_segments_best: segments_best.map((segment) => ({ ...segment, creator: username })),
    unsubmitted_segments_site: segments_site.map((segment) => ({ ...segment, creator: username })),
  };
}
