import { useState } from "react";
import { useSelector } from "react-redux";
import { Droppable, Draggable, DragDropContext, DropResult } from "react-beautiful-dnd";
import { selectModules, setModules } from "src/store/academyReducer";
import { Module } from "../components/Module/Module";
import { EditModule } from "../components/Module/EditModule";
import { useAppDispatch } from "src/store/store";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";

export const EditModules = () => {
  const dispatch = useAppDispatch();
  const modules = useSelector(selectModules);
  const [isCreate, setIsCreate] = useState(false);
  const [isDrag, setIsDrag] = useState(false);

  const reorderModules = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const currentModule = modules[result.source.index];
    const { response } = await fetchRequest(
      academyApi.updateModule(currentModule.module_id, {
        ...currentModule,
        module_order: result.destination.index + 1 <= 0 ? 1 : result.destination.index + 1,
      }),
      {
        request: "Обновление модуля",
      }
    );
    if (response) {
      dispatch(
        setModules(
          modules?.map((el, index) =>
            index === result.source.index
              ? {
                  ...el,
                  module_order: response.module_order,
                }
              : el.module_order > response.module_order
              ? {
                  ...el,
                  module_order: el.module_order + 1,
                }
              : el
          )
        )
      );
    }
    setIsDrag(false);
  };

  return (
    <div className="p-5">
      <h2>Модули</h2>
      <DragDropContext onDragStart={() => setIsDrag(true)} onDragEnd={reorderModules}>
        <Droppable droppableId={"modules_list"} type={"section"} key={`modules_list`}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={`flex flex-col ${
                isDrag ? "" : "lg:grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
              } gap-3 lg:mx-auto`}
              {...provided.droppableProps}>
              {!!modules?.length &&
                modules?.map((el, index) => {
                  const key = !!el.module_id ? `${el.module_id}_module_id` : `${index}_new_module`;

                  return (
                    <Draggable key={key} draggableId={key} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <Module item={el} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {!isCreate ? (
                <button
                  className="relative w-[280px] sm:w-[360px] lg:w-[140px] lg:min-h-[240px] lg:h-full bg-slate-50 shadow-md rounded-md flex justify-center items-center duration-300 hover:scale-105 hover:shadow-lg text-[32px] font-bold text-blue"
                  onClick={() => setIsCreate(true)}>
                  +
                </button>
              ) : (
                <div className="relative w-[280px] sm:w-[360px] lg:w-full lg:h-full bg-slate-50 shadow-md rounded-md flex justify-center items-center p-5">
                  <EditModule setIsEdit={setIsCreate} />
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
