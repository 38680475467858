import { useState } from "react";
import { cn } from "src/utils";

export const AllButtonSquare = ({
  addAll,
  allItems,
  setFn,
  type,
  className = "",
  onAddAll = undefined,
}) => {
  const [isAll, setIsAll] = useState(false);

  const setAll = () => {
    if (isAll) {
      setIsAll(false);
      addAll(allItems, setFn, "off");
      onAddAll?.("off");
    } else {
      setIsAll(true);
      addAll(allItems, setFn, "on");
      onAddAll?.("on");
    }
  };

  return (
    <button
      type="button"
      className={cn(
        "relative rounded-sm m-0 bg-font text-sm pb-2 h-[20px] font-bold text-white",
        type === "вектор" ? "w-[calc(100%-10px)] lg:w-[315px]" : "w-[calc(100%-10px)] lg:w-[160px]",
        type === "валюта" ? "" : "-left-[5px] -top-[3px]",
        className
      )}
      onClick={setAll}>
      {isAll ? "-" : "+"}
    </button>
  );
};
