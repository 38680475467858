import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectGlobalCurrencies } from "src/store/directionsReducer";
import {
  TAvailableDirectionsTypes,
  TPairs,
  TSelectedPair,
  baseUnavailableDirections,
} from "src/store/onOffReducer";
import { MiniTetris } from "src/pages/Tetris/MiniTetris/MiniTetris";
import { Settings } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/Settings";
import { AddSummary } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/AddSummary";
import { CreateBlockTools } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/CreateBlockTools";
import { v4 } from "uuid";
import { ICity } from "src/types/OnOffTimeline/common";
import { selectOnOffIsAddMode } from "src/store/onOffTimelineReducer/selectors";
import { Popup } from "src/components/Popup/Popup";
import { setPairsNotFresh, toggleOnOffIsAddMode } from "src/store/onOffTimelineReducer/slice";
import { useAppDispatch } from "src/store/store";
import { AUTO_CITY_CODE } from "src/api/constants";
import { setNotific } from "src/store/mainReducer";

export enum ChangesAcceptMode {
  MASH = "mash",
  MERGE = "merge",
}

export const CreateBlock = () => {
  const dispatch = useAppDispatch();

  const isOpen = useSelector(selectOnOffIsAddMode);

  const globalCurrencies = useSelector(selectGlobalCurrencies);
  const [isSettings, setIsSettings] = useState(false);
  const [cities, setCities] = useState<Array<ICity>>([]);
  const [sites, setSites] = useState<Array<string>>([]);
  const [selectedPairs, setSelectedPairs] = useState<TSelectedPair[]>([]);
  const [pairs, setPairs] = useState<TPairs>({
    from: [],
    to: [],
  });
  const [unavailableDirections, setUnavailableDirections] =
    useState<TAvailableDirectionsTypes[]>(baseUnavailableDirections);

  useEffect(() => {
    const selectedSites = [];
    const selectedCities = [];
    const selectedFrom = [];
    const selectedTo = [];
    selectedPairs.forEach((pair) => {
      selectedCities.push(pair.city?.city_code || AUTO_CITY_CODE);
      selectedSites.push(pair.site);
      selectedFrom.push(pair.from.currency_name);
      selectedTo.push(pair.to.currency_name);
    });

    const isPairsFresh =
      cities.every((item) => selectedCities.includes(item.city_code)) &&
      sites.every((item) => selectedSites.includes(item)) &&
      pairs.from.every((item) => selectedFrom.includes(item.currency_name)) &&
      pairs.to.every((item) => selectedTo.includes(item.currency_name));

    dispatch(setPairsNotFresh(!isPairsFresh));
  }, [sites, cities, pairs, selectedPairs]);

  const isPairComplete = !!pairs.from.length && !!pairs.to.length;

  const submitDisabled = !(
    isPairComplete &&
    ([...pairs.to, ...pairs.from].some((currency) => currency.type === "cash")
      ? !!cities?.length
      : true)
  );

  const sitesInPairs = Array.from(new Set(selectedPairs.map((pair) => pair.site)));

  const submitPairs = () => {
    // переменная, которая говорит нам
    // есть ли среди среди всех выбранных
    // пар хоть одна валюта с типом cash
    let anyCashInPairs = false;
    const allPairs: { from: TSelectedPair["from"]; to: TSelectedPair["to"] }[] = [];

    // формируем все объекты пар пока без сайтов и городов
    pairs.from.forEach((from) => {
      pairs.to.forEach((to) => {
        // заодно с формированием пар меняем значение нашего флага
        // если вдруг была найдена кэшовая валюта
        if (to.type === "cash" || from.type === "cash") {
          anyCashInPairs = true;
        }

        allPairs.push({
          from,
          to,
        });
      });
    });

    const pairsWithSitesAndCities: TSelectedPair[] = [];

    // добавляем к парам сайты и города
    for (const site of sites) {
      // если ни одна из выбранных пар не имеет кэшовой валюты,
      // города не нужны, подставляем вместо города null

      const finalCities = anyCashInPairs ? cities : [null];

      for (const city of finalCities) {
        for (const pair of allPairs) {
          const isAuto = pair.from.type !== "cash" && pair.to.type !== "cash";

          const res = {
            ...pair,
            id: v4(),
            site,
            city: isAuto ? null : city,
          };

          // если выбрана хоть одна кэшовая валюта,
          // то будет учтен массив городов,
          // по которому будут итерации,
          // из-за чего авто валюта может быть учтена столько раз,
          // сколько городов
          // чтобы этого избежать - проверяем, не выбрана ли она уже
          const alreadyExists = !!pairsWithSitesAndCities.find(
            (item) =>
              ((item.city && res.city && item.city.city_code === res.city.city_code) ||
                (!item.city && !res.city)) &&
              item.site === res.site &&
              item.from.currency_name === res.from.currency_name &&
              item.to.currency_name === res.to.currency_name
          );

          if (pairsWithSitesAndCities.length === 1000) {
            dispatch(setNotific({ type: "error", message: "Превышен лимит пар" }));
            break;
          }
          if (!alreadyExists) {
            pairsWithSitesAndCities.push(res);
          }
        }

        if (pairsWithSitesAndCities.length === 1000) {
          break;
        }
      }

      if (pairsWithSitesAndCities.length === 1000) {
        break;
      }
    }

    // фильтруем все получившиеся пары,
    // чтобы откинуть все запрещенные сочетания
    // и все пары, которые уже выбраны
    const filteredPairs = pairsWithSitesAndCities.filter((el) => {
      const defaultUnavailable = unavailableDirections?.find(
        (dir) => dir.from_type === el.from?.type && dir.to_type === el.to?.type
      );

      const alreadySelected = selectedPairs.find(
        (pair) =>
          pair.city === el.city &&
          pair.site === el.site &&
          pair.from.currency_name === el.from.currency_name &&
          pair.to.currency_name === el.to.currency_name
      );

      return (!defaultUnavailable || defaultUnavailable?.available) && !alreadySelected;
    });

    setSelectedPairs([...selectedPairs, ...filteredPairs]);
  };

  const cancel = () => {
    setPairs({
      from: [],
      to: [],
    });
    setCities([]);
    setSites([]);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Popup closeModal={() => dispatch(toggleOnOffIsAddMode())}>
      <div>
        <h2 className="text-2xl font-medium text-center mb-[5px]]">
          Добавление данных на таймлайн
        </h2>
        <div className="flex gap-8 h-full overflow-auto pb-[10px]">
          <div className="-ml-[10px]">
            <MiniTetris
              sites={sites}
              cities={cities}
              setCities={setCities}
              setSites={setSites}
              globalCurrencies={globalCurrencies}
              pairs={pairs}
              setPairs={setPairs}
            />
            <CreateBlockTools
              submitDisabled={submitDisabled}
              setIsSettings={setIsSettings}
              cancel={cancel}
              submitPairs={submitPairs}
            />
          </div>
          <AddSummary
            selectedPairs={selectedPairs}
            setSelectedPairs={setSelectedPairs}
            sites={sitesInPairs}
          />
        </div>
        {isSettings && (
          <Settings
            anavailableDirections={unavailableDirections}
            setAnavailableDirections={setUnavailableDirections}
            close={() => setIsSettings(false)}
          />
        )}
      </div>
    </Popup>
  );
};
