import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { NestedObjects } from "../components/routes";
import controlApi from "src/api/control";
import {
  selectControlChats,
  selectControlUsers,
  TTrackNotification,
  TTrack,
  TCoin,
  TWallet,
  TCondition,
  TNetwork,
} from "src/store/commonReducer";
import { EditedTextarea } from "src/components/FormElements/EditedTextarea";
import { Popup } from "src/components/Popup/Popup";
import { FieldTabs } from "src/components/FormElements/FieldTabs";
import { EMethod } from "./Wallets";

type TProps = {
  item: TTrackNotification;
  setNotifications: Dispatch<SetStateAction<TTrackNotification[]>>;
  setIsAddNotific: Dispatch<SetStateAction<boolean>>;
  baseRequest: (data: {
    method: Promise<AxiosResponse>;
    methodType: EMethod;
    title: string;
    name: string;
    setter:
      | Dispatch<SetStateAction<TTrackNotification[] | TTrack[] | TCoin[] | TWallet[]>>
      | ((res: NestedObjects<TCondition | TNetwork>) => void);
  }) => void;
  isLoading: boolean;
};

export const Notification = ({
  item,
  setNotifications,
  baseRequest,
  isLoading,
  setIsAddNotific,
}: TProps) => {
  const users = useSelector(selectControlUsers);
  const chats = useSelector(selectControlChats);
  const [notif, setNotif] = useState(item);
  const [isPopup, setPopup] = useState(false);
  const [content, setContent] = useState(item?.content || "");
  const tgAccount = useMemo(
    () =>
      notif.chat_type === "user"
        ? users?.find((el) => el.telegram_user_id === notif.chat_id)?.username || ""
        : chats?.find((el) => el.telegram_chat_id === notif.chat_id)?.chat_name || "",
    [notif, chats, users]
  );
  const disabled =
    isLoading ||
    !notif.chat_id ||
    (notif.chat_id === item?.chat_id &&
      notif.chat_type === item.chat_type &&
      notif.content === item.content);
  const tgArr = useMemo(
    () =>
      notif.chat_type === "user"
        ? users?.map((el) => el.username)
        : chats?.map((el) => el.chat_name),
    [notif.chat_type, users, chats]
  );

  return (
    <div
      className={`relative flex flex-col gap-4 rounded-md border border-[#72E5AE33] bg-[#72E5AE22] text-[#32A56E] px-[10px] py-8`}>
      <button
        type="button"
        className={`absolute -top-[6px] -right-4 flex items-center justify-center h-[16px] w-[16px] bg-bg rounded-full shadow-sm text-[10px] text-lightFont`}
        onClick={() => setPopup(true)}>
        {`\u2715`}
      </button>
      <FieldTabs
        label="уведомить"
        arr={tgArr}
        value={tgAccount}
        placeholder="Выберите назначение уведомления"
        edited
        updFn={(fieldValue) => {
          setNotif({
            ...notif,
            chat_id:
              notif.chat_type === "user"
                ? users?.find((el) => el.username === fieldValue)?.telegram_user_id || 0
                : chats?.find((el) => el.chat_name === fieldValue)?.telegram_chat_id || 0,
          });
        }}
      />
      <FieldTabs
        label="тип"
        arr={["Пользователь", "Группа"]}
        placeholder="Выберите тип"
        value={notif.chat_type === "user" ? "Пользователь" : "Группа"}
        edited
        updFn={(fieldValue) => {
          setNotif({
            ...notif,
            chat_id: 0,
            chat_type: fieldValue === "Пользователь" ? "user" : "group",
          });
        }}
      />
      <div className="text-lightFont font-medium">Описание</div>
      <div className="-mx-8">
        <EditedTextarea
          id={notif.notification_id}
          initialValue={item?.content || ""}
          value={content}
          setValue={setContent}
          updFn={() => {
            setNotif({
              ...notif,
              content: content,
            });
          }}
        />
      </div>
      {!disabled && (
        <button
          className={`bg-light self-end px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300`}
          onClick={async () => {
            setIsAddNotific(false);
            await baseRequest({
              method: !!item.notification_id
                ? controlApi.updateNotification({ ...notif, content: content })
                : controlApi.createNotification({ ...notif, content: content }),
              methodType: !!item.notification_id ? EMethod.UPDATE : EMethod.CREATE,
              title: `${!!item.notification_id ? "Обновление" : "Создание"} уведомления`,
              name: "Notification",
              setter: setNotifications,
            });
          }}>
          {!!item.notification_id ? "Обновить" : "Создать"}
        </button>
      )}
      {isPopup && (
        <Popup closeModal={() => setPopup(false)}>
          <div className="text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nуведомление для `}
              <b>{tgAccount}</b>?
            </div>
            <button
              type="button"
              className="prime-button-sm bg-font"
              disabled={isLoading}
              onClick={async () => {
                await baseRequest({
                  method: controlApi.deleteNotification(item?.notification_id),
                  methodType: EMethod.DELETE,
                  title: "Удаление уведомления",
                  name: "Notification",
                  setter: setNotifications,
                });
                setPopup(false);
              }}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
