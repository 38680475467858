import { ICurrencyPair, CurrencyPairsGroup } from "src/types/OnOffTimeline/common";

export const getPairGroup = (a: ICurrencyPair): CurrencyPairsGroup => {
  if (a.currency_from.type === "cash" && a.currency_to.type === "crypto") {
    return CurrencyPairsGroup.CASH_CRYPTO;
  } else if (a.currency_from.type === "crypto" && a.currency_to.type === "cash") {
    return CurrencyPairsGroup.CRYPTO_CASH;
  } else {
    return CurrencyPairsGroup.AUTO;
  }
};
