import { ReactNode } from "react";
import { RadioButtonsOption } from "src/components/FormElements/RadioButtons/RadioButtonsOption";
import { v4 } from "uuid";

export type RadioOption<T> = {
  label: string;
  value: T;
  description?: string;
  disabled?: boolean;
  disabledReason?: string;
  disabledReasonCustomComponent?: ReactNode;
};

interface RadioButtonsProps<T> {
  options: RadioOption<T>[];
  value: T;
  label?: string;
  labelClassname?: string;
  descriptionClassname?: string;
  containerClassname?: string;
  buttonsContainerClassname?: string;
  buttonClassname?: string;
  icon?: string;
  readonly?: boolean;
  onDisabledOptionMouseEnter?: () => void;
  onDisabledOptionMouseLeave?: () => void;
  onChange: (value: T) => void;
}

export const RadioButtons = <T,>({
  options,
  value,
  label,
  onChange,
  labelClassname,
  descriptionClassname,
  containerClassname,
  buttonsContainerClassname,
  buttonClassname,
  readonly,
  onDisabledOptionMouseEnter,
  onDisabledOptionMouseLeave,
}: RadioButtonsProps<T>) => {
  const getIsSelected = (optionValue: T) => value === optionValue;

  return (
    <div className={`flex flex-col ${containerClassname || ""}`}>
      {label && <p className={`text-[18px] font-medium mb-4 ${labelClassname || ""}`}>{label}</p>}
      <div className={`flex flex-col gap-8 ${buttonsContainerClassname || ""}`}>
        {options.map((option) => {
          const isSelected = getIsSelected(option.value);
          return (
            <RadioButtonsOption
              key={v4()}
              option={option}
              isSelected={isSelected}
              onChange={onChange}
              readonly={readonly}
              buttonClassname={buttonClassname}
              descriptionClassname={descriptionClassname}
              onDisabledOptionMouseEnter={onDisabledOptionMouseEnter}
              onDisabledOptionMouseLeave={onDisabledOptionMouseLeave}
            />
          );
        })}
      </div>
    </div>
  );
};
