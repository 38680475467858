import { BASE_URL, DB_URL } from "./constants";
import api from "./http";

export default class switchApi {
  static async getGlobals() {
    return api.get(`${BASE_URL}/pair_builder/main/admin/globals`);
  }
  static async getGlobalDirections() {
    return api.get(`${BASE_URL}/pair_builder/currency_new/globals`);
  }
  static async updateSwitch(data) {
    return api.post(`${BASE_URL}/switch/city`, data);
  }
  static async updateSwitchSite(site_id, data) {
    return api.post(`${BASE_URL}/switch/site/${site_id}`, data);
  }
  static async getSwitch() {
    return api.get(`${BASE_URL}/switch/enabled`);
  }
  static async getSwitchByFilters(data, limit, offset) {
    return api.post(`${DB_URL}/switches/enabled/site?limit=${limit}&offset=${offset}`, data);
  }
  static async turnSwitchSite(data) {
    return api.post(`${DB_URL}/switches/turn`, data);
  }
  static async getBlocks() {
    return api.get(`${BASE_URL}/site/blocks`);
  }
  static async getAlienSites() {
    return api.get(`${BASE_URL}/site/all_sites`);
  }
  static async updateBlocks(data) {
    // заблокировать
    return api.post(`${BASE_URL}/site/blacklist`, data);
  }
  static async deleteBlocks(data) {
    // разблокировать
    return api.post(`${BASE_URL}/site/delete_blocks`, data);
  }
}
