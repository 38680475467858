import { ApplyingChangesMode } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/TetrisSubmitPopup";
import { getEditedSiteData } from "src/helpers/onOffTimeline/getEditedSiteData";
import {
  ChangedSite,
  ChangedPair,
  ChangedSiteType,
  getNewCityFromChangesData,
} from "src/helpers/onOffTimeline/getNewCityFromChangesData";
import { getPairGroup } from "src/helpers/onOffTimeline/getPairGroup";
import {
  getUnsubmittedSite,
  getUnsubmittedPair,
  getUnsubmittedCity,
} from "src/helpers/onOffTimeline/getUnsubmittedEntities";
import { ICity } from "src/types/OnOffTimeline/common";

interface GetDataAfterSubmitProps {
  oldData: ICity[];
  newData: ICity[];
  applyingMode: ApplyingChangesMode;
  username: string;
}

/**
 * oldData - инфа которая щас на таймлайне
 * newData - инфа из тетриса
 * applyingMode - заменяем или объединяем
 * username - имя пользователя, чтобы отобразить автора в несохраненных изменениях на таймлайне
 *
 * функция сравнивает oldData и newData и на основе этого сравнения формирует новый массив данных,
 * в котором изменено все, что должно быть изменено, и добавлено все, что должно быть добавлено
 * новые данные возвращаются сразу с unsubmitted изменениями - готовые данные для отображения результатов на таймлайне
 */
export const getDataAfterSubmit = ({
  oldData,
  newData,
  applyingMode,
  username,
}: GetDataAfterSubmitProps) => {
  const res = [...oldData];

  const entitiesToExpand = new Set<string>();

  newData.forEach((newDataCity) => {
    const existingCityIndex = res.findIndex((city) => city.city_code === newDataCity.city_code);

    const sitesChanges: ChangedSite[] = [];
    const pairsChanges: ChangedPair[] = [];

    if (~existingCityIndex) {
      const existingCity = { ...res[existingCityIndex] };

      entitiesToExpand.add(existingCity.city_code);

      newDataCity.currency_pairs.forEach((pair) => {
        const existingPairIndex = existingCity.currency_pairs.findIndex(
          (item) =>
            item.currency_from.currency_name === pair.currency_from.currency_name &&
            item.currency_to.currency_name === pair.currency_to.currency_name &&
            item.currency_from.type === pair.currency_from.type &&
            item.currency_to.type === pair.currency_to.type
        );

        if (~existingPairIndex) {
          const existingPair = { ...existingCity.currency_pairs[existingPairIndex] };

          const group = getPairGroup(existingPair);

          entitiesToExpand.add(`${existingCity.city_code}_${group}`);
          entitiesToExpand.add(
            `${existingCity.city_code}_${group}_${existingPair.currency_from.currency_name}_${existingPair.currency_to.currency_name}`
          );

          pair.sites.forEach((site) => {
            const existingSiteIndex = existingPair.sites.findIndex(
              (item) => item.site_name === site.site_name
            );

            // если сайт уже есть в данных - пробуем получить его новую версию
            if (~existingSiteIndex) {
              const existingSite = { ...existingPair.sites[existingSiteIndex] };

              const newSite = getEditedSiteData({
                applyingMode,
                existingSite,
                site,
                username,
                group,
                pair: `${existingPair.currency_from.currency_name}_${existingPair.currency_to.currency_name}`,
              });

              sitesChanges.push(newSite);
            }
            // если сайта нет в старых данных, добавляем его в массив изменений сайтов с флагом "created"
            else {
              sitesChanges.push({
                ...getUnsubmittedSite(site, username),
                group,
                pair: `${existingPair.currency_from.currency_name}_${existingPair.currency_to.currency_name}`,
                type: ChangedSiteType.CREATED,
              });
            }
          });
        }
        // если пары нет в старых данных, добавляем ее в массив изменений пар
        else {
          const group = getPairGroup(pair);

          entitiesToExpand.add(`${existingCity.city_code}_${group}`);
          entitiesToExpand.add(
            `${existingCity.city_code}_${group}_${pair.currency_from.currency_name}_${pair.currency_to.currency_name}`
          );

          pairsChanges.push({
            ...getUnsubmittedPair(pair, username),
            group: group,
          });
        }
      });

      // на основе старых данных о городе, а также всех изменений, которые получились после итераций,
      // получаем новые данные по городу
      const newCity = getNewCityFromChangesData({
        existingCity,
        pairsChanges,
        sitesChanges,
      });

      res.splice(existingCityIndex, 1, newCity);
    }
    // если города нет в старых данных, добавляем его unsubmitted версию в новые данные
    else {
      entitiesToExpand.add(newDataCity.city_code);
      newDataCity.currency_pairs.forEach((pair) => {
        const group = getPairGroup(pair);

        entitiesToExpand.add(`${newDataCity.city_code}_${group}`);
        entitiesToExpand.add(
          `${newDataCity.city_code}_${group}_${pair.currency_from.currency_name}_${pair.currency_to.currency_name}`
        );
      });
      res.push(getUnsubmittedCity(newDataCity, username));
    }
  });

  return {
    oldDataUpdated: res,
    entitiesToExpand: Array.from(entitiesToExpand),
  };
};
