import { GroupBase, OptionsOrGroups } from "react-select";
import { TGlobalDirection } from "src/store/directionsReducer";

export const convertToOptions = (arr: string[]): OptionsOrGroups<unknown, GroupBase<string>> => {
  const transformedMessages = [] as Array<{ label: string; value: string }>;
  if (arr?.length > 0) {
    arr?.forEach((el: string) => {
      transformedMessages.push({ label: el, value: el });
    });
  }
  return transformedMessages;
};

export const vectorToOptions = (arr: TGlobalDirection[]) => {
  const transformedMessages = [] as Array<{ label: string; value: TGlobalDirection }>;
  if (arr?.length > 0) {
    arr?.forEach((el) => {
      transformedMessages.push({
        label: `${el.currency_from}-${el.currency_to}`,
        value: el,
      });
    });
  }
  return transformedMessages;
};

export const getArrLabels = (obj, attr): Array<string | null> => {
  if (!!obj && `${attr}` in obj) {
    return obj[attr]?.map((el) => (el !== null ? Object.values(el)[0] : null));
  } else {
    return [];
  }
};
export const getArrValues = (obj, attr) => {
  if (!!obj && `${attr}` in obj) {
    return obj[attr]?.map((el) => (el !== null ? Object.values(el)[1] : null));
  } else {
    return [];
  }
};

export const getItemNestedData = (item, attr: string): Array<string> => {
  if (!!item?.data[0] && attr in item.data[0]) {
    const arr: Array<string> = item?.data?.map((el) => el[attr] || null);
    if (!!arr?.length) {
      return [...new Set(arr)];
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const charFix = (str: string, isEnToRu: boolean): string => {
  const replacer = {
    q: "й",
    w: "ц",
    e: "у",
    r: "к",
    t: "е",
    y: "н",
    u: "г",
    i: "ш",
    o: "щ",
    p: "з",
    "[": "х",
    "]": "ъ",
    a: "ф",
    s: "ы",
    d: "в",
    f: "а",
    g: "п",
    h: "р",
    j: "о",
    k: "л",
    l: "д",
    ";": "ж",
    "'": "э",
    z: "я",
    x: "ч",
    c: "с",
    v: "м",
    b: "и",
    n: "т",
    m: "ь",
    ",": "б",
    ".": "ю",
    "/": ".",
  };
  if (isEnToRu && !!str) {
    for (let i = 0; i < str.length; i++) {
      let replace;
      if (replacer[str[i].toLowerCase()] != undefined) {
        if (str[i] == str[i].toLowerCase()) {
          replace = replacer[str[i].toLowerCase()];
        } else if (str[i] == str[i].toUpperCase()) {
          replace = replacer[str[i].toLowerCase()].toUpperCase();
        }
        str = str.replace(str[i], replace);
      }
    }
  }
  if (!isEnToRu && !!str) {
    const replacerToEN = Object.entries(replacer);
    for (let i = 0; i < str.length; i++) {
      let replace;
      const replacerEl = replacerToEN?.find((el) => el[1] === str[i].toLowerCase());
      if (replacerEl != undefined) {
        if (str[i] == str[i].toLowerCase()) {
          replace = replacerEl[0];
        } else if (str[i] == str[i].toUpperCase()) {
          replace = replacerEl[0].toUpperCase();
        }
        str = str.replace(str[i], replace);
      }
    }
  }

  return str || "";
};

export const translit = (str) => {
  const ru = [
    "А",
    "а",
    "Б",
    "б",
    "В",
    "в",
    "Г",
    "г",
    "Д",
    "д",
    "Е",
    "е",
    "Ё",
    "ё",
    "Ж",
    "ж",
    "З",
    "з",
    "И",
    "и",
    "Й",
    "й",
    "К",
    "к",
    "Л",
    "л",
    "М",
    "м",
    "Н",
    "н",
    "О",
    "о",
    "П",
    "п",
    "Р",
    "р",
    "С",
    "с",
    "Т",
    "т",
    "У",
    "у",
    "Ф",
    "ф",
    "Х",
    "х",
    "Ц",
    "ц",
    "Ч",
    "ч",
    "Ш",
    "ш",
    "Щ",
    "щ",
    "Ъ",
    "ъ",
    "Ы",
    "ы",
    "Ь",
    "ь",
    "Э",
    "э",
    "Ю",
    "ю",
    "Я",
    "я",
  ];
  const en = [
    "A",
    "a",
    "B",
    "b",
    "V",
    "v",
    "G",
    "g",
    "D",
    "d",
    "E",
    "e",
    "E",
    "e",
    "ZH",
    "zh",
    "Z",
    "z",
    "I",
    "i",
    "I",
    "i",
    "K",
    "k",
    "L",
    "l",
    "M",
    "m",
    "N",
    "n",
    "O",
    "o",
    "P",
    "p",
    "R",
    "r",
    "S",
    "s",
    "T",
    "t",
    "U",
    "u",
    "F",
    "f",
    "H",
    "h",
    "C",
    "c",
    "CH",
    "ch",
    "SH",
    "sh",
    "SCH",
    "sch",
    "'",
    "'",
    "Y",
    "y",
    "'",
    "'",
    "E",
    "e",
    "U",
    "u",
    "YA",
    "ya",
  ];
  if (!!str) {
    const isRu = str.match(/[А-Я]/g);
    for (let i = 0, l = str.length; i < l; i++) {
      if (isRu) {
        const index = ru?.findIndex((el) => el === str[i]);
        str = str.replace(str[i], en[index]);
      } else {
        const index = en?.findIndex((el) => el === str[i]);
        str = str.replace(str[i], ru[index]);
      }
    }
  }
  return str || "";
};

export const selectFilter = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any" as "start" | "any" | undefined,
  stringify: (option) =>
    `${typeof option.label === "string" && option.label} ${option.value} ${
      typeof option.label === "string" && charFix(option.label, true)
    } ${typeof option.label === "string" && charFix(option.label, false)} ${
      typeof option.label === "string" && translit(option.label)
    } ${!!option?.data?.title && option.title} ${
      !!option?.data?.title && charFix(option.data.title, true)
    } ${!!option?.data?.title && charFix(option.data.title, false)} ${
      !!option?.data?.title && translit(option.data.title)
    }`,
  trim: true,
};
