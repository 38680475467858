import { Tool } from "./Tool";

export const Feat = ({ item }) => {
  return (
    <div className="w-full flex flex-col gap-[10px] border-t border-[#DDD] pt-[30px] pb-[20px] px-[10px]">
      <div className="flex justify-between gap-4 flex-col md:flex-row items-baseline">
        <div className="flex gap-[20px] font-semibold md:max-w-[80%]">
          <div>{item.id}</div>
          <div>{item.title.toUpperCase()}</div>
        </div>
        <div className="text-xs text-lightGray whitespace-pre">{item.date}</div>
      </div>
      {item?.tools?.map((el, index) => (
        <Tool key={index} item={el} />
      ))}
    </div>
  );
};
