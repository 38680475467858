import { Dispatch, SetStateAction, useState } from "react";
import ReactSelect, { createFilter } from "react-select";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import addApi from "src/api/add";
import { useAppDispatch } from "src/store/store";
import { TStrSelect } from "src/store/directionsReducer";
import { TUser, setUsers } from "src/store/commonReducer";
import { UserIcon } from "src/components/UI/items/UserIcon";
import { Popup } from "src/components/Popup/Popup";
import QRCode from "react-qr-code";

type TProps = {
  item: TUser;
  users: TUser[];
  setSelectedUser: Dispatch<SetStateAction<TUser>>;
  rolesOptions: Array<TStrSelect>;
  setPopup: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  access: boolean;
};

export const User = ({
  item,
  users,
  access,
  setSelectedUser,
  setPopup,
  rolesOptions,
  setIsLoading,
  setMessage,
  setStatus,
}: TProps) => {
  const dispatch = useAppDispatch();
  const [isEditRole, setEditRole] = useState(false);
  const [isEditPopup, setEditPopup] = useState(false);
  const [newOTP, setNewOTP] = useState<string | null>(null);
  const [password, setPassword] = useState("");
  const [role, setRole] = useState<TStrSelect>({ label: item?.role_name, value: item?.role_name });

  const updateUser = async (action: "роль" | "пароль" | "OTP") => {
    const payload = {
      id: item.id,
    };

    if (action === "роль") {
      payload[`role_name`] = role?.label;
    } else if (action === "пароль") {
      payload[`password`] = password;
    } else if (action === "OTP") {
      payload[`reset_otp`] = true;
    }

    setIsLoading(true);
    const { response, error } = await fetchRequest(addApi.updateUser(payload), {
      request: action,
    });
    if (response) {
      setMessage(`Данные пользователя успешно обновлены`);
      setStatus(true);
      if (action === "OTP") {
        setNewOTP(response.otp_key);
      } else if (action === "роль") {
        const updatedUsers = users.map((el) =>
          el.id === item.id
            ? {
                ...el,
                ...response,
              }
            : el
        );
        dispatch(setUsers(updatedUsers));
      }
    }
    if (error) {
      setMessage(`Не удалось обновить ${action}`);
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      setPopup(false);
    }, 4600);
    setEditRole(false);
    setEditPopup(false);
  };

  return (
    <div
      className={`grid grid-cols-[25px_100px_8px_1fr_16px_16px] sm:grid-cols-[40px_150px_20px_1fr_20px_50px] gap-[20px] items-center text-xs rounded-lg duration-700 shadow-smooth p-[10px]`}>
      <UserIcon username={item?.username} />
      <div className="text-left sm:text-base">{item?.username}</div>
      <div>{item?.id}</div>
      {access && isEditRole ? (
        <div className="flex gap-4 items-center">
          <ReactSelect
            classNamePrefix="SquareSelect"
            options={rolesOptions}
            value={role}
            className="w-full"
            onChange={(e: TStrSelect) => setRole(e)}
            filterOption={createFilter(selectFilter)}
          />
          <button
            type="button"
            className={`w-[16px] min-w-[16px] h-[16px] bg-[#62F5B3] flex justify-center items-center rounded-sm text-base hover:brightness-110 ${
              role?.label === item.role_name ? "opacity-20 text-[#000]" : "text-white"
            }`}
            disabled={role?.label === item.role_name}
            onClick={() => updateUser("роль")}>
            <span className={`relative top-[-0.5px] font-bold text-xs`}>✓</span>
          </button>
          <button
            className="w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#989CA4] text-base hover:brightness-90"
            onClick={() => setEditRole(false)}>
            <span className={`relative top-[-1px] rotate-45 left-[1px]`}>+</span>
          </button>
        </div>
      ) : (
        <div
          className={`text-xs text-lightFont ${access ? "cursor-pointer" : ""}`}
          onClick={() => access && setEditRole(true)}>
          {item?.role_name}
        </div>
      )}
      {access && (
        <button
          className={`duration-300 text-gray text-lg hover:text-lightFont rotate-[110deg]`}
          onClick={() => setEditPopup(true)}>
          {`\u270E`}
        </button>
      )}
      <div>
        {access && (
          <button
            className={`duration-300 text-lightGray hover:text-lightFont text-base`}
            onClick={() => {
              setSelectedUser(item);
              setPopup(true);
            }}>
            {`\u2716`}
          </button>
        )}
      </div>
      {isEditPopup && (
        <Popup closeModal={() => setEditPopup(false)}>
          <div className="flex flex-col gap-8">
            <div className="text text-left text-font">Введите новый пароль</div>
            <input
              placeholder="от 12 символов"
              onChange={({ target }) => setPassword(target.value)}
              className="text-sm w-[260px] border border-lightGray rounded-[10px] outline-none h-[40px] py-4 px-8 my-[10px]"
            />
            <button
              disabled={password?.length < 12}
              onClick={() => updateUser("пароль")}
              className="prime-button">
              Обновить пароль
            </button>
            <small className="small-text text-center">или</small>
            <button onClick={() => updateUser("OTP")} className="prime-button">
              Обновить OTP
            </button>
          </div>
        </Popup>
      )}
      {newOTP && (
        <Popup closeModal={() => setNewOTP(null)}>
          <div className="flex flex-col gap-8">
            <div
              style={{ height: "auto", margin: "0 auto 30px auto", maxWidth: 300, width: "100%" }}>
              <QRCode
                size={200}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`otpauth://totp/${item.username}?secret=${newOTP}`}
                viewBox={`0 0 200 200`}
              />
            </div>
            <div className="small-text">Не потеряйте QR-код, он генерируется только один раз</div>
          </div>
        </Popup>
      )}
    </div>
  );
};
