import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { timeToSeconds } from "src/helpers/date";
import { ESource, TOffValues, TSwitchSegment } from "src/store/onOffReducer";
import { MiniSpinner } from "src/components/Spinner/MiniSpinner";
import { sources } from "src/pages/Panel/OnOff/OnOffTimeline/OnOffTimeline";
import { NewDataType } from "src/pages/Panel/OnOff/OnOffTimeline/components/forms/ItemForm";
import { SourceBlock } from "src/pages/Panel/OnOff/OnOffTimeline/components/Sources/SourceBlock";
import { sortSegmentsByStart } from "src/helpers/onOffTimeline/combine";
import { ITimeSegment } from "src/types/OnOffTimeline/common";
import { useSelector } from "react-redux";
import { selectPairsNotFresh } from "src/store/onOffTimelineReducer/selectors";
import { cn } from "src/utils";
import { getSegmentsToCreate } from "src/helpers/onOffTimeline/copyPaste";
import { Tooltip, TooltipProvider, TooltipContent, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";

const isNoMatch = ({ site, bestchange }: Record<ESource, ITimeSegment[]>) =>
  getSegmentsToCreate(site, bestchange).length > 0;

export const SourcesForm = ({
  noSubmit,
  setSubmitDisabled,
  state,
  setState,
  disabledBySelected,
  isLoading,
  submit,
  off,
  setOff,
}: {
  noSubmit?: boolean;
  off?: Record<ESource, boolean>;
  setOff?: (val: Record<ESource, boolean>) => void;
  setSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  state: Record<ESource, ITimeSegment[]>;
  setState: (val: Record<ESource, ITimeSegment[]>) => void;
  submit?: (data: NewDataType) => void;
  disabledBySelected?: boolean;
  isLoading: boolean;
  setNoMatch?: Dispatch<SetStateAction<boolean>>;
}) => {
  const pairsNotFresh = useSelector(selectPairsNotFresh);

  const [noMatch, setNoMatch] = useState(false);

  const [offValues, setOffValues] = useState<TOffValues>(
    off || {
      bestchange: false,
      site: false,
    }
  );

  const handleOffAll = (sourceKey: ESource) => {
    setOffValues((prev) => ({
      site: sourceKey === ESource.SITE ? !prev.site : prev.site,
      bestchange:
        sourceKey === ESource.SITE ? (!prev.site ? true : prev.bestchange) : !prev.bestchange,
    }));
  };

  useEffect(() => {
    setNoMatch?.(isNoMatch(state));
  }, [state]);

  useEffect(() => {
    setOff?.(offValues);
  }, [offValues]);

  const validateSegments = (arr: TSwitchSegment[]) => {
    const sortedSegments = [...arr].sort(sortSegmentsByStart);

    const validateValues = [];

    sortedSegments.forEach((segment, index) => {
      const isSegmentCorrect = timeToSeconds(segment.end) > timeToSeconds(segment.start);

      if (index > 0) {
        const prevSegment = sortedSegments[index - 1];
        validateValues.push(
          isSegmentCorrect && timeToSeconds(segment.start) > timeToSeconds(prevSegment.end)
        );
      } else {
        validateValues.push(isSegmentCorrect);
      }
    });

    return validateValues.some((value) => !value);
  };

  const addSegment = (sourceKey: ESource) => {
    setState({
      ...state,
      [sourceKey]: [...state[sourceKey], { start: "00:00", end: "23:59" }],
    });
  };

  const removeSegment = (index: number, sourceKey: ESource) => {
    const newSegments = [...state[sourceKey]];
    newSegments.splice(index, 1);

    setState({
      ...state,
      [sourceKey]: newSegments,
    });
  };

  const onSegmentChange = (
    index: number,
    field: "start" | "end",
    value: string,
    sourceKey: ESource
  ) => {
    const newSegments = [...state[sourceKey]];
    const updatedSegment = { ...newSegments[index] };
    updatedSegment[field] = value;
    newSegments[index] = updatedSegment;

    setState({
      ...state,
      [sourceKey]: newSegments,
    });
  };

  const makeDouble = () =>
    setState({
      ...state,
      bestchange: [...state.site],
    });

  const onSubmit = () => {
    submit?.({
      segments_site: offValues.site ? [] : state.site,
      segments_best: offValues.bestchange ? [] : state.bestchange,
    });
  };

  const siteError = validateSegments(state.site);
  const bestError = validateSegments(state.bestchange);

  const getIsInvalid = (sourceKey: ESource) => {
    switch (sourceKey) {
      case ESource.SITE:
        return siteError;
      case ESource.BEST:
        return bestError;
    }
  };

  const submitDisabled = siteError || bestError;

  useEffect(() => {
    setSubmitDisabled?.(submitDisabled);
  }, [submitDisabled]);

  return (
    <div className="relative flex flex-col duration-300 w-fit gap-[8px] shrink-0">
      {Object.entries(sources).map(([sourceKey, sourceData]) => (
        <SourceBlock
          key={sourceKey}
          segments={state[sourceKey]}
          sourceKey={sourceKey as ESource}
          source={sourceData}
          offValues={offValues}
          isInvalid={getIsInvalid(sourceKey as ESource)}
          addSegment={addSegment}
          removeSegment={removeSegment}
          onSegmentChange={onSegmentChange}
          makeDouble={makeDouble}
          handleOffAll={handleOffAll}
        />
      ))}
      {noMatch && (
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <p className="text-red w-[315px] mt-[4px] text-[12px] text-center border border-red rounded-md p-[4px]">
                Отрезки сайта не соответствуют отрезкам беста
              </p>
            </TooltipTrigger>
            <TooltipContent>
              <p className="text-[12px] max-w-[250px] text-center">
                Если продолжить, отрезки сайта будут добавлены автоматически
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      {!noSubmit && submit && (
        <div
          className={cn("flex items-center ", pairsNotFresh ? "justify-between" : "justify-end")}>
          {pairsNotFresh && <p className="text-sm text-red pl-[10px]">Не все пары созданы</p>}
          <button
            onClick={onSubmit}
            disabled={submitDisabled || disabledBySelected}
            className={`self-end shrink-0 w-[130px] h-[28px] flex items-center justify-center text-xs py-4 bg-[#BBFFDA] border font-semibold rounded-sm disabled:bg-[#EEE] duration-300`}
            style={{
              boxShadow: isLoading ? "0 0 10px #40AEF088" : "",
              backgroundColor: isLoading ? "#40AEF055" : "",
              borderColor: submitDisabled || disabledBySelected ? "#BABAC355" : "#3BC57AAA",
              color: submitDisabled || disabledBySelected ? "#BABAC3" : "#2BB56A",
            }}>
            {isLoading ? <MiniSpinner /> : disabledBySelected ? "Выберите пары" : "Подтвердить"}
          </button>
        </div>
      )}
    </div>
  );
};
