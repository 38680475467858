export const AlertCard = ({
  item,
}: {
  item: {
    city_name: string;
    link: string;
    vector: string;
    top_percent_30: string;
    top_percent_today: string;
  };
}) => {
  return (
    <div
      className={`text-[0.8rem] table shadow-sm py-[10px] px-[20px] flex flex-wrap flex-col justify-center w-[225px] max-w-[225px] rounded-md`}>
      <div className="relative">
        <div className="font-extrabold">{item.city_name}</div>
        <a className="text-[13px]" target="_blank" rel="noopener noreferrer" href={item.link}>
          {item.vector}
        </a>
      </div>
      <div className="text-xs">
        <div className="font-light flex justify-between flex-nowrap items-center">
          <div>Топ за 30 минут</div>
          <div>{parseFloat(item?.top_percent_30).toFixed(2)}</div>
        </div>
        <div className="font-light flex justify-between flex-nowrap items-center">
          <div>Топ за сегодня</div>
          <div>{parseFloat(item?.top_percent_today).toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};
