export const pascal = (str: string): string => {
  return !!str && !!str?.length ? `${str[0]?.toUpperCase()}${str.substring(1)}` : "";
};

export const checkCondition = (condition: string, a: number, b: number): boolean => {
  switch (condition) {
    case "<": {
      return a < b;
    }
    case "<=": {
      return a <= b;
    }
    case ">": {
      return a > b;
    }
    case ">=": {
      return a >= b;
    }
    case "==": {
      return a === b;
    }
    case "!=": {
      return a !== b;
    }
    default:
      return false;
  }
};
