import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useMemo, memo, useRef, useState } from "react";
import ReactSelect, { createFilter } from "react-select";
import clusterApi from "src/api/cluster";
import {
  setTimelineCitiesByCentrifugo,
  setTimelineByCentrifugo,
} from "src/api/centrifugo/timelineCentrifugo";
import { timeToSeconds } from "src/helpers/date";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import { TSelect, selectAllCities, selectAllCurrencies } from "src/store/directionsReducer";
import {
  TSkippedTimeline,
  TTemplate,
  TTimeline,
  TTimelineDeletePayload,
  TTimelineSite,
  TTimelineVector,
} from "src/store/clusterReducer";
import { setNotific } from "src/store/mainReducer";
import {
  getVectorStr,
  getSiteStr,
  submitAll,
  decreaseActionsCount,
  submit,
} from "./TimelineFunctions/TimelineFunctions";
import {
  TTimelineLocalDeletePayload,
  checkEmpty,
  deleteNewItem,
  stashEmptyRows,
} from "./TimelineFunctions/DeleteTimelineFunctions";
import { TimelineCity } from "./TimelineItems/TimelineCity";
import { CreateButton } from "src/components/UI/buttons/CreateButton";
import { CityLine } from "./CityLine";
import { Popup } from "src/components/Popup/Popup";
import { DeleteConfirm } from "./Confirmations/DeleteConfirm";
import { SaveConfirm } from "./Confirmations/SaveConfirm";
import { SaveAsTemplateConfirm } from "./Confirmations/SaveAsTemplateConfirm";
import cleaner from "src/assets/images/cleaner.svg";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";

type TProps = {
  items: TTimeline[];
  anotherItems: TTimeline[];
  templates: TTemplate[];
  setTemplates: Dispatch<SetStateAction<TTemplate[]>>;
  setTemplateData: Dispatch<SetStateAction<TTimeline[]>>;
  setAnotherTemplateData: Dispatch<SetStateAction<TTimeline[]>>;
  activeTemplate: number | null;
  anotherTemplate: number | null;
  setActiveTemplate: Dispatch<SetStateAction<number | null>>;
  cities: number[];
  anotherCities: number[];
  axisX: number[];
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isDelete: boolean;
  isCopy: boolean;
  setIsCopy: Dispatch<SetStateAction<boolean>>;
  isAdded: string;
  isOpenCities: boolean;
  setIsAdded: Dispatch<SetStateAction<string>>;
  zoom: number;
  step: number;
  expand: boolean;
  visibleCities: Array<number>;
  setVisibleCities: Dispatch<SetStateAction<Array<number>>>;
  visibleVectors: Array<string>;
  setVisibleVectors: Dispatch<SetStateAction<Array<string>>>;
  isBest: boolean;
  nowTime: number;
  username: string;
  actionsCount: number;
  setActionsCount: Dispatch<SetStateAction<number>>;
  isSplitRight: boolean;
  setIsSplitRight: () => void;
  isRight: boolean;
};

export const Timeline = memo(
  ({
    items,
    anotherItems,
    templates,
    setTemplates,
    setTemplateData,
    setAnotherTemplateData,
    cities,
    anotherCities,
    axisX,
    isLoading,
    setIsLoading,
    isDelete,
    isCopy,
    setIsCopy,
    isAdded,
    isOpenCities,
    setIsAdded,
    zoom,
    step,
    expand,
    isBest,
    visibleCities,
    setVisibleCities,
    visibleVectors,
    setVisibleVectors,
    nowTime,
    username,
    actionsCount,
    setActionsCount,
    activeTemplate,
    anotherTemplate,
    setActiveTemplate,
    isSplitRight,
    setIsSplitRight,
    isRight,
  }: TProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const globalCities = useSelector(selectAllCities);
    const globalCurrencies = useSelector(selectAllCurrencies);
    const [isSaveConfirm, setIsSaveConfirm] = useState(false);
    const [isNameConfirm, setNameConfirm] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const nameRef = useRef(null);
    const [isAddCity, setIsAddCity] = useState(false);
    const [copiedEl, setCopiedEl] = useState<TTimelineDeletePayload | null>(null);
    const [deletedEl, setDeletedEl] = useState<TTimelineDeletePayload | null>(null);
    const [pasteEl, setPasteEl] = useState<TSelect | null>(null);
    const [isRightPaste, setIsRightPaste] = useState(false);
    const isPasteAnoterSide =
      isSplitRight && ((isRight && !isRightPaste) || (!isRight && isRightPaste));
    const [isRename, setRename] = useState(false);
    const isTemplate = !!activeTemplate;
    const currentTemplate = templates?.find((el) => el?.id === activeTemplate) || null;

    const citiesOptions = useMemo(
      () =>
        globalCities
          ?.filter((el) => !cities?.includes(el.city_id))
          ?.map((el) => ({ label: el.city_name, value: el.city_id })),
      [globalCities, items]
    );

    const anotherCitiesOptions = useMemo(
      () =>
        globalCities
          ?.filter((el) => !anotherCities?.includes(el.city_id))
          ?.map((el) => ({ label: el.city_name, value: el.city_id })),
      [globalCities, anotherItems]
    );

    const currenciesOptions = useMemo(
      () =>
        globalCurrencies?.map((el) => ({
          label: el.currency_name,
          value: { id: el.currency_id, isCrypto: el.is_crypto },
        })),
      [globalCurrencies]
    );

    const updateTimeline = async () => {
      setIsLoading(true);
      setActionsCount(0);
      const payload = items
        ?.filter((el) => JSON.stringify(el).includes(username))
        ?.map((city) => {
          return {
            ...city,
            vectors: city?.vectors
              ?.filter((vector) => JSON.stringify(vector).includes(username))
              ?.map((vector) => {
                return {
                  ...vector,
                  sites: vector?.sites?.filter((site) => JSON.stringify(site).includes(username)),
                };
              }),
          };
        });
      const { response } = await fetchRequest(
        isTemplate
          ? clusterApi.updateTemplate(activeTemplate, username, payload)
          : clusterApi.updateTimeline(payload),
        { request: "Обновление" }
      );
      if (response) {
        let preparedItems = items?.map((cityEl: TTimeline) => {
          const currCity = response?.created?.find(
            (res: TTimeline) => res.city_id === cityEl.city_id
          );
          if (!!currCity) {
            return {
              ...cityEl,
              vectors: cityEl?.vectors?.map((vectorEl) => {
                const currVector = currCity.vectors?.find(
                  (res: TTimelineVector) => getVectorStr(vectorEl) === getVectorStr(res)
                );
                if (!!currVector) {
                  return {
                    ...currVector,
                    sites: vectorEl?.sites?.map((siteEl) => {
                      const currSite = currVector?.sites?.find(
                        (el: TTimelineSite) => getSiteStr(el) === getSiteStr(siteEl)
                      );
                      if (!!currSite) {
                        return currSite;
                      } else {
                        return siteEl;
                      }
                    }),
                  };
                } else {
                  return vectorEl;
                }
              }),
            };
          } else {
            return cityEl;
          }
        });
        if (!response?.skipped.length) {
          dispatch(
            setNotific({
              type: "success",
              message: "Изменения успешно сохранены",
              request: isTemplate ? "Сохранение изменений шаблона" : "Обновление",
            })
          );
          submitAll({
            data: preparedItems,
            isTemplate: isTemplate,
            setTemplateData: setTemplateData,
          });
        } else {
          await response?.skipped?.forEach((el: TSkippedTimeline) => {
            preparedItems = preparedItems?.map((cityEl: TTimeline) => {
              if (cityEl.city_id === el.city_id) {
                return {
                  ...cityEl,
                  vectors: cityEl?.vectors?.map((vectorEl) => {
                    if (
                      getVectorStr(vectorEl) ===
                      `${el?.currency_from_id}_${el?.currency_to_id}_${el?.big_percent}`
                    ) {
                      return {
                        ...vectorEl,
                        sites:
                          vectorEl?.sites?.filter((siteEl) => {
                            return (
                              getSiteStr(siteEl) !==
                              `${el?.site_id}_${timeToSeconds(el?.time_from)}_${timeToSeconds(
                                el?.time_to
                              )}`
                            );
                          }) || [],
                      };
                    } else {
                      return vectorEl;
                    }
                  }),
                };
              } else {
                return cityEl;
              }
            });
          });
          dispatch(
            setNotific({
              type: "success",
              message: "Не все изменения сохранились - были дубли по времени и позиции",
              request: "Обновление",
            })
          );
          submitAll({
            data: preparedItems,
            isTemplate: isTemplate,
            setTemplateData: setTemplateData,
          });
        }
      }
      setIsAdded("");
      setIsLoading(false);
    };

    const copyIt = async (withSites: boolean) => {
      let currentTemplate = null as number | null;
      let currentCities = [];
      let currentItems = [];
      let setter;
      if (isPasteAnoterSide) {
        currentTemplate = anotherTemplate;
        currentCities = anotherCities;
        currentItems = anotherItems;
        setter = setAnotherTemplateData;
      } else {
        currentTemplate = activeTemplate;
        currentCities = cities;
        currentItems = items;
        setter = setTemplateData;
      }
      if (!currentTemplate) {
        await setTimelineCitiesByCentrifugo(
          [...currentCities, pasteEl?.value]?.sort((a, b) => (a > b ? 1 : -1))
        );
      }
      const copiedItem = items
        ?.filter((el) => el.city_id === copiedEl?.city_id)
        ?.map((el) => ({
          city_id: pasteEl?.value,
          vectors: el.vectors?.map((vector) => ({
            ...vector,
            percent_position: 0,
            cluster_percent: 0,
            cluster_course: 0,
            sites: withSites
              ? vector?.sites?.map((site) => ({
                  creator: username,
                  site_id: site?.site_id,
                  start_time: site?.start_time,
                  end_time: site?.end_time,
                  is_infinity: site?.is_infinity,
                  is_locked: site?.is_locked,
                }))
              : [],
          })),
        }));
      if (!!currentTemplate) {
        setter(
          [...currentItems, ...copiedItem]?.sort((a: TTimeline, b: TTimeline) =>
            a.city_id > b.city_id ? 1 : -1
          )
        );
      } else {
        setTimeout(() => {
          setTimelineByCentrifugo(...copiedItem, pasteEl?.value);
        }, 1000);
      }
      if (isOpenCities && !!visibleCities?.length) {
        setVisibleCities([...visibleCities, pasteEl?.value]);
      }
      setCopiedEl(null);
      setPasteEl(null);
      setIsCopy(false);
    };

    const deleteFn = async (deletePayload: TTimelineLocalDeletePayload) => {
      setIsLoading(true);
      const templatePayload = { ...deletePayload.payload, template_id: activeTemplate };

      const { response } = await fetchRequest(
        isTemplate
          ? clusterApi.deleteTimelineItem(templatePayload)
          : clusterApi.deleteTimelinePercent(deletePayload.payload),
        { request: "Удаление" }
      );
      if (response) {
        deleteNewItem({ ...deletePayload, response: response });
      }
      setIsLoading(false);
    };

    const deleteIt = async (payload: TTimelineDeletePayload, saveRows: boolean) => {
      const curCity = items?.find((el) => el.city_id === payload.city_id);

      const deletePayload = {
        item: curCity,
        payload: payload,
        response: null,
        items: items,
        isTemplate: isTemplate,
        setTemplateData: setTemplateData,
        saveRows: saveRows,
        submitFn: submit,
      };

      const checkedItems = saveRows ? items : checkEmpty(deletePayload);
      const existCity = checkedItems?.find((el) => el.city_id === payload.city_id);
      const existFrom = existCity?.vectors?.filter(
        (el) => el?.currency_from_id === payload?.currency_from_id
      );
      const existTo = existFrom?.filter((el) => el?.currency_to_id === payload?.currency_to_id);
      const existPercent = existTo?.find((el) => el?.percent === payload?.percent);
      const existSite = existPercent?.sites?.find((el) => getSiteStr(el) === getSiteStr(payload));

      if (!!existCity?.vectors?.length && (!!activeTemplate ? activeTemplate >= 0 : true)) {
        if (!("currency_from_id" in payload)) {
          await deleteFn({ ...deletePayload, point: "city" });
        } else {
          if (!!existFrom?.length) {
            if (!("currency_to_id" in payload)) {
              await deleteFn({ ...deletePayload, point: "from" });
            } else {
              if (!!existTo?.length) {
                if (!("percent" in payload)) {
                  await deleteFn({ ...deletePayload, point: "to" });
                } else {
                  if (!!existPercent?.sites?.length) {
                    if (!("site_id" in payload)) {
                      await deleteFn({ ...deletePayload, point: "percent" });
                    } else {
                      if (!!existSite && !("creator" in existSite)) {
                        await deleteFn({ ...deletePayload, point: "site" });
                      } else {
                        await deleteNewItem({ ...deletePayload, point: "site" });
                      }
                    }
                  } else {
                    await deleteNewItem({ ...deletePayload, point: "percent" });
                  }
                }
              } else {
                await deleteNewItem({ ...deletePayload, point: "to" });
              }
            }
          } else {
            await deleteNewItem({ ...deletePayload, point: "from" });
          }
        }
      } else {
        if ("currency_from_id" in payload) {
          if ("currency_to_id" in payload) {
            if ("percent" in payload) {
              if ("site_id" in payload) {
                await deleteNewItem({ ...deletePayload, point: "site" });
              } else {
                await deleteNewItem({ ...deletePayload, point: "percent" });
              }
            } else {
              await deleteNewItem({ ...deletePayload, point: "to" });
            }
          } else {
            await deleteNewItem({ ...deletePayload, point: "from" });
          }
        } else {
          await deleteNewItem({ ...deletePayload, point: "city" });
        }
      }
      setDeletedEl(null);
    };

    const fetchTemplate = async () => {
      setIsLoading(true);
      const { response, error } = await fetchRequest(clusterApi.getTemplate(activeTemplate), {
        request: "Данные шаблона",
      });
      if (response) {
        setTemplateData(response);
      }
      if (error) {
        setTemplateData([]);
      }
      setIsLoading(false);
    };

    const renameTemplate = async (name: string) => {
      const payload = {
        title: name,
        updater: username,
        id: currentTemplate.id,
      };
      const { response } = await fetchRequest(clusterApi.renameTemplate(payload), {
        request: "Переименование шаблона",
      });
      if (response) {
        setTemplates(
          templates?.map((el) =>
            el.id === currentTemplate.id
              ? {
                  ...el,
                  title: name,
                }
              : el
          )
        );
        dispatch(
          setNotific({
            type: "success",
            message: `Шаблон успешно переименован`,
            request: "Переименование шаблона",
          })
        );
      }
    };

    useEffect(() => {
      if (isRename) {
        nameRef.current.focus();
      }
    }, [isRename]);

    useEffect(() => {
      if (!!activeTemplate) {
        setActionsCount(0);
        if (activeTemplate !== -1) {
          fetchTemplate();
        }
      }
    }, [activeTemplate]);

    return (
      <div className={isRight ? "bg-[#282cA40A]" : ""}>
        <div
          className={`fixed top-[114px] flex gap-4 text-lightFont font-black p-2 pl-8 z-40 ${
            isTemplate ? "bg-select" : "bg-transparent"
          }`}>
          {!isRename && (
            <div
              className={`${!!currentTemplate ? "cursor-pointer" : ""}`}
              onClick={() => {
                if (!!currentTemplate) {
                  setRename(true);
                }
              }}>
              {isTemplate ? (
                activeTemplate >= 0 ? (
                  <div>
                    {currentTemplate?.title}{" "}
                    {<span className="pl-8 text-xs font-light">{`\u270E`}</span>}
                  </div>
                ) : (
                  "new template"
                )
              ) : (
                "ТЕКУЩИЙ ТАЙМЛАЙН"
              )}
            </div>
          )}
          {isRename && (
            <input
              ref={nameRef}
              className={`border border-lightGray w-[240px] rounded-sm px-8`}
              placeholder="Назовите шаблон"
              defaultValue={currentTemplate?.title}
              onBlur={() => setRename(false)}
              onKeyDown={async (e) => {
                if (e.keyCode === 13 && !!e.currentTarget.value?.length) {
                  await renameTemplate(e.currentTarget.value);
                  setRename(false);
                }
              }}
            />
          )}
          {actionsCount > 0 && (
            <button
              type="button"
              className={`bg-light w-fit flex items-center gap-8 text-xs px-[16px] h-[26px] border border-lightGray text-lightFont font-semibold rounded-sm hover:opacity-70 whitespace-pre`}
              onClick={() =>
                decreaseActionsCount({
                  items: items,
                  username: username,
                  actionsCount: actionsCount,
                  setActionsCount: setActionsCount,
                  isTemplate: isTemplate,
                  setTemplateData: setTemplateData,
                })
              }>
              <span>{`\u2936`}</span>
              {isSplitRight ? actionsCount : `последнее добавление (${actionsCount})`}
            </button>
          )}
          <div className="flex gap-4 pl-4">
            {!isTemplate && (
              <Tooltip content="Очистить пустые ряды">
                <button
                  type="button"
                  className={`bg-light w-fit flex justify-center items-center pt-4 self-start text-xs w-[26px] min-w-[26px] h-[26px] border border-lightGray text-lightFont font-semibold rounded-sm whitespace-pre`}
                  onClick={() => stashEmptyRows(items)}>
                  <img src={cleaner} alt="cleaner" width={14} height={14} className="opacity-80" />
                </button>
              </Tooltip>
            )}
            {(!activeTemplate || activeTemplate !== -1) && (
              <button
                type="button"
                className={`bg-[#BBFFDA] w-fit self-start text-xs px-[16px] h-[26px] border border-[#3BC57AAA] text-[#2BB56A] font-semibold rounded-sm duration-300 whitespace-pre ${
                  isLoading ? "opacity-30" : ""
                }`}
                disabled={isLoading}
                onClick={() => setIsSaveConfirm(true)}>
                Сохранить
              </button>
            )}
            <button
              type="button"
              className={`bg-light w-fit self-start text-xs px-[16px] h-[26px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300 hover:opacity-70 whitespace-pre ${
                isLoading ? "opacity-30" : ""
              }`}
              disabled={isLoading}
              onClick={() => {
                setNameConfirm(true);
              }}>
              Сохранить как новый шаблон
            </button>
          </div>
          {!isSplitRight ? (
            isTemplate && (
              <button
                type="button"
                className={`bg-light w-fit self-start text-xs px-[16px] h-[26px] border border-lightGray text-lightFont font-semibold rounded-sm whitespace-pre`}
                onClick={() => {
                  setActiveTemplate(null);
                  searchParams.delete("template");
                  setSearchParams(searchParams);
                }}>
                Выйти в текущий таймлайн
              </button>
            )
          ) : (
            <button
              type="button"
              className="flex justify-center items-center pb-2 w-[28px] h-[28px] rounded-sm hover:bg-stale duration-300"
              onClick={setIsSplitRight}>
              {`\u00D7`}
            </button>
          )}
        </div>
        <div
          className={`grid rounded-sm border border-glass-gray text-xs p-4 pt-[68px] ${
            isSplitRight ? "grid-cols-[160px_1fr]" : "grid-cols-[230px_1fr]"
          }`}>
          <div
            className={`w-full flex flex-col duration-300 -m-4 p-4 ${
              isDelete || isCopy ? "bg-stale" : ""
            }`}>
            <div className={`items-end ${isSplitRight ? "w-[164px]" : "w-[234px]"}`}>
              <div className="relative flex flex-col gap-4 h-[20px]">
                <CreateButton title="Добавить город" isAdd={isAddCity} setIsAdd={setIsAddCity} />
                {isAddCity && (
                  <ReactSelect
                    classNamePrefix="SquareSelect"
                    options={citiesOptions}
                    className="absolute w-full z-10 p-4 -m-4 bg-bg"
                    filterOption={createFilter(selectFilter)}
                    onChange={async (e: TSelect) => {
                      if (!!activeTemplate) {
                        setTemplateData(
                          [
                            ...items,
                            {
                              city_id: e.value,
                              vectors: [],
                            },
                          ]?.sort((a, b) => (a.city_id > b.city_id ? 1 : -1))
                        );
                      } else {
                        await setTimelineCitiesByCentrifugo(
                          [...cities, e.value]?.sort((a, b) => (a > b ? 1 : -1))
                        );
                        await setTimelineByCentrifugo(
                          {
                            city_id: e.value,
                            vectors: [],
                          },
                          e.value
                        );
                      }
                      setIsAddCity(false);
                    }}
                  />
                )}
              </div>
            </div>
            <div className={`${isSplitRight ? "w-[164px]" : "w-[234px]"}`}>
              {cities?.map((el) => {
                const findCity = items?.find((city) => city.city_id === el);
                const cityData = !!findCity
                  ? findCity
                  : {
                      city_id: el,
                      vectors: [],
                    };
                return (
                  <CityLine
                    key={el}
                    item={cityData}
                    items={items}
                    globalCities={globalCities}
                    currenciesOptions={currenciesOptions}
                    isCopy={isCopy}
                    setCopiedEl={setCopiedEl}
                    isDelete={isDelete}
                    setDeletedEl={setDeletedEl}
                    visibleCities={visibleCities}
                    setVisibleCities={setVisibleCities}
                    visibleVectors={visibleVectors}
                    setVisibleVectors={setVisibleVectors}
                    activeTemplate={activeTemplate}
                    setTemplateData={setTemplateData}
                    username={username}
                    isSplitRight={isSplitRight}
                  />
                );
              })}
            </div>
          </div>
          {!!items?.length && !!cities?.length && (
            <div className={`relative pt-[20px] max-w-full flex flex-col overflow-x-scroll`}>
              {cities?.map((el) => {
                const findCity = items?.find((city) => city.city_id === el);
                const cityData = !!findCity
                  ? findCity
                  : {
                      city_id: el,
                      vectors: [],
                    };
                return (
                  <TimelineCity
                    key={`${el}_timeline`}
                    item={cityData}
                    axisX={axisX}
                    items={items}
                    isAdded={isAdded}
                    setIsAdded={setIsAdded}
                    zoom={zoom}
                    expand={expand}
                    deleteIt={deleteIt}
                    nowTime={nowTime}
                    step={step}
                    visibleCities={visibleCities}
                    visibleVectors={visibleVectors}
                    isBest={isBest}
                    username={username}
                    actionsCount={actionsCount}
                    setActionsCount={setActionsCount}
                    activeTemplate={activeTemplate}
                    setTemplateData={setTemplateData}
                  />
                );
              })}
            </div>
          )}
          {!!copiedEl && (
            <Popup closeModal={() => setCopiedEl(null)}>
              <div className="text-xs flex flex-col gap-8 max-w-[300px]">
                <div>
                  Скопируйте город{" "}
                  <b>{globalCities?.find((el) => el.city_id === copiedEl.city_id)?.city_name}</b>{" "}
                  для
                </div>
                <ReactSelect
                  classNamePrefix="SquareSelect"
                  options={isPasteAnoterSide ? anotherCitiesOptions : citiesOptions}
                  filterOption={createFilter(selectFilter)}
                  placeholder="Выберите город"
                  onChange={(e: TSelect) => {
                    setPasteEl(e);
                  }}
                />
                {isSplitRight && (
                  <div className="w-full flex flex-col gap-8 -mb-8">
                    Вставить в
                    <div className="flex gap-8">
                      <button
                        type="button"
                        className={`w-full border border-lightGray  px-8 py-4 hover:opacity-70 duration-300 ${
                          !isRightPaste ? "bg-font text-white" : "bg-stale text-lightFont"
                        }`}
                        onClick={() => setIsRightPaste(false)}>
                        левый блок
                      </button>
                      <button
                        type="button"
                        className={`w-full border border-lightGray  px-8 py-4 hover:opacity-70 duration-300 ${
                          isRightPaste ? "bg-font text-white" : "bg-stale text-lightFont"
                        }`}
                        onClick={() => setIsRightPaste(true)}>
                        правый блок
                      </button>
                    </div>
                  </div>
                )}
                <button
                  className="prime-button-sm rounded-sm mt-[20px] -mb-4"
                  onClick={() => copyIt(true)}>
                  Скопировать с сайтами
                </button>
                <button className="prime-button-sm rounded-sm -mb-4" onClick={() => copyIt(false)}>
                  Скопировать только векторы
                </button>
              </div>
            </Popup>
          )}
          {!!deletedEl && (
            <DeleteConfirm
              deletedEl={deletedEl}
              setDeletedEl={setDeletedEl}
              isLoading={isLoading}
              deleteIt={deleteIt}
            />
          )}
          {isSaveConfirm && (
            <SaveConfirm
              updateTimeline={updateTimeline}
              setIsSaveConfirm={setIsSaveConfirm}
              isTemplate={isTemplate}
            />
          )}
          {isNameConfirm && (
            <SaveAsTemplateConfirm
              items={items}
              templates={templates}
              setTemplates={setTemplates}
              templateName={templateName}
              setTemplateName={setTemplateName}
              setNameConfirm={setNameConfirm}
              setActiveTemplate={setActiveTemplate}
              setTemplateData={setTemplateData}
              setIsLoading={setIsLoading}
              setActionsCount={setActionsCount}
              username={username}
              isRight={isRight}
            />
          )}
        </div>
      </div>
    );
  }
);
