import { DeleteScheduleItem } from "src/api/schedule";
import { CurrencyPairsGroup, OnOffItemToDeleteType } from "src/types/OnOffTimeline/common";
import { OnOffDeleteItemProps } from "src/types/OnOffTimeline/store";
import { TCurrencyTypes } from "src/pages/Text/types";

export const getScheduleDeleteBody = ({
  type,
  name,
}: OnOffDeleteItemProps): DeleteScheduleItem[] => {
  const [cityCode, group, from, to, siteName] = name.split("_");

  let fromType: TCurrencyTypes;
  let toType: TCurrencyTypes;

  if (group) {
    switch (group) {
      case CurrencyPairsGroup.CASH_CRYPTO:
        fromType = "cash";
        toType = "crypto";
        break;
      case CurrencyPairsGroup.CRYPTO_CASH:
        fromType = "crypto";
        toType = "cash";
    }
  }

  const res: DeleteScheduleItem = {
    city_code: cityCode,
    currency_from_name: from,
    currency_to_name: to,
    currency_from_type: type === OnOffItemToDeleteType.GROUP ? fromType : undefined,
    currency_to_type: type === OnOffItemToDeleteType.GROUP ? toType : undefined,
    site_name: siteName,
  };

  return [res];
};
