import { groupCurrencyPairsByFrom } from "src/helpers/onOffTimeline/groupCurrencyPairsByFrom";
import {
  ICity,
  ICityWithGroups,
  CurrencyPairsGroup,
  ICurrencyPair,
  ICityPairsGroup,
} from "src/types/OnOffTimeline/common";

/**
 применяет к изначальным данным все 
 необходимые группировки
 */
export function getCitiesWithGroups(cities: ICity[]): ICityWithGroups[] {
  const citiesWithGroups = cities.map((city) => {
    const groups: Record<CurrencyPairsGroup, ICurrencyPair[]> = {
      [CurrencyPairsGroup.CASH_CRYPTO]: [],
      [CurrencyPairsGroup.CRYPTO_CASH]: [],
      [CurrencyPairsGroup.AUTO]: [],
    };

    city.currency_pairs.forEach((pair) => {
      const from = pair.currency_from;
      const to = pair.currency_to;

      // создаем пустые массивы, чтобы избежать
      // непредвиденных ошибок
      pair = {
        ...pair,
        sites: pair.sites.map((site) => ({
          ...site,
          unsubmitted_segments_best: site.unsubmitted_segments_best || [],
          unsubmitted_segments_site: site.unsubmitted_segments_site || [],
        })),
      };

      if (from.type === "cash" && to.type === "crypto") {
        groups[CurrencyPairsGroup.CASH_CRYPTO].push(pair);
      } else if (from.type === "crypto" && to.type === "cash") {
        groups[CurrencyPairsGroup.CRYPTO_CASH].push(pair);
      } else {
        groups[CurrencyPairsGroup.AUTO].push(pair);
      }
    });

    return {
      city_code: city.city_code,
      city_name: city.city_name,
      groups,
    };
  });

  const res: ICityWithGroups[] = [];

  citiesWithGroups.forEach((city) => {
    const newGroups: ICityPairsGroup[] = [];

    Object.entries(city.groups).forEach(([groupName, pairs]) => {
      const name = groupName as CurrencyPairsGroup;
      newGroups.push({
        groupName: name,
        groupedPairs: groupCurrencyPairsByFrom(pairs, name),
      });
    });

    res.push({
      city_code: city.city_code,
      city_name: city.city_name,
      groups: newGroups.filter((group) => group.groupedPairs.length > 0),
    });
  });

  return res.sort((a, b) => a.city_name.localeCompare(b.city_name));
}
