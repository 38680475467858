import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { OnOffFiltersType } from "src/pages/Panel/OnOff/OnOffTimeline/components/header/TimelineFilters";
import { OnOffSite } from "src/types/OnOffTimeline/store";
import { useDispatch } from "react-redux";
import { setOnOffConfirmation, turnOffSite } from "src/store/onOffTimelineReducer/slice";
import { fetchRequest } from "src/helpers/fetchRequest";
import scheduleApi from "src/api/schedule";
import { setNotific } from "src/store/mainReducer";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { colors } from "src/helpers/colors";
import { DeleteConfirmText } from "src/pages/Panel/OnOff/OnOffTimeline/components/popups/OnOffConfirmPopup";
import useHover from "src/hooks/useHover";
import { cn } from "src/utils";

export const SiteMenuItemNew = ({
  item,
  isSelected,
  toggleSearchParamsSite,
}: {
  item: OnOffSite;
  isOnOffTimeline?: boolean;
  isSelected?: boolean;
  toggleSearchParamsSite: (siteName: string) => void;
}) => {
  const dispatch = useDispatch();

  const id = item.site_id % 30;

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [isHovered, { onMouseEnter, onMouseLeave }] = useHover();

  const handleTurnOff = () =>
    dispatch(
      setOnOffConfirmation({
        title: `Вы уверены?`,
        subtitle: `Данное действие удалит все отрезки для сайта ${item.site_name} из данного интерфейса`,
        content: (
          <DeleteConfirmText text="Сайт сохранит свои последние данные, которые будут доступны здесь" />
        ),
        cb: turnOff,
      })
    );

  const turnOff = async () => {
    setLoading(true);

    const { response, error } = await fetchRequest(
      scheduleApi.deleteSchedule([{ site_name: item.site_name }])
    );

    if (error) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText || "Ошибка при удалении сайта" })
      );

      setLoading(false);

      return;
    }

    if (response) {
      dispatch(turnOffSite(item.site_name));

      if (item.has_segments && isSelected) {
        const sitesFromParams = searchParams.get(OnOffFiltersType.SITES)?.split(",") || [];
        const existingSiteIndex = sitesFromParams.findIndex((site) => site === item.site_name);

        if (~existingSiteIndex) {
          sitesFromParams.splice(existingSiteIndex, 1);
        }

        if (sitesFromParams.filter((item) => item.length > 0).length > 0) {
          searchParams.set(OnOffFiltersType.SITES, sitesFromParams.join(","));
        } else {
          searchParams.delete(OnOffFiltersType.SITES);
        }

        setSearchParams(searchParams);
      }
    }

    setLoading(false);
  };

  const toggleSelected = () => {
    toggleSearchParamsSite(item.site_name);
  };

  return (
    <Tooltip
      content={item.has_segments ? "У сайта есть данные" : "У сайта нет данных"}
      direction="top"
      delay={1}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
          backgroundColor: isHovered
            ? `${colors[id]}50`
            : isSelected
            ? `${colors[id]}80`
            : "#BABAC340",
          borderColor: isSelected ? colors[id] : "#BABAC3",
        }}
        className={`disabled:opacity-40 cursor-pointer grid grid-cols-[1fr_50px] items-center gap-8 rounded-sm shadow-smooth-out py-4 px-8 text-xs text-left border-l-4 text-[#282c34CC] whitespace-pre duration-300`}
        onClick={toggleSelected}>
        <div className="w-[70px] overflow-hidden text-ellipsis whitespace-nowrap">
          {item?.site_name}
        </div>
        <label
          onMouseEnter={onMouseLeave}
          onMouseLeave={onMouseEnter}
          className={cn(
            "switcher__label-small cursor-pointer hover:opacity-[0.9] absolute right-4 hover:bg-grayDark",
            item.has_segments ? "bg-green" : "bg-lightGray"
          )}
          onClick={(e) => {
            e.stopPropagation();
            item.has_segments && handleTurnOff();
          }}>
          <input
            disabled={loading}
            checked={item.has_segments}
            className="switcher__check-input"
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
          />
          <span className="switcher__checkbox-small" />
        </label>
      </div>
    </Tooltip>
  );
};
