import { BASE_URL } from "../../api/constants";

export const notify = {
  id: 0,
  compatible: () => {
    if (typeof Notification === "undefined") {
      console.log("Notifications are not available for your browser.");
      return false;
    }
    return true;
  },
  authorize: () => {
    if (notify.compatible()) {
      Notification.requestPermission();
    }
  },
  show: (title, message, link) => {
    if (typeof Notification === "undefined") {
      console.log("Notifications are not available for your browser.");
      return;
    }
    if (notify.compatible()) {
      const notification = new Notification(title, {
        body: message,
        lang: "",
        dir: "auto",
      });
      notification.onclick = (event) => {
        event.preventDefault();
        window.open(`${BASE_URL?.substr(0, BASE_URL?.length - 4)}${link}`, "_blank");
      };
    }
  },
};
