import { useSelector } from "react-redux";
import { LS } from "src/api/constants";
import { selectNotifications } from "src/store/mainReducer";
import { Notific } from "./Notific";

export const NotificQueue = () => {
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");
  const notifications = useSelector(selectNotifications);

  if (!user_access) {
    return null;
  }

  return (
    <div className={"fixed left-[20px] bottom-[10px] flex flex-col items-start z-[101]"}>
      <div className="flex flex-col-reverse">
        {notifications.map((el, index) => (
          <Notific key={index} type={el.type} message={el.message} request={el.request} />
        ))}
      </div>
    </div>
  );
};
