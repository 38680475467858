import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  TGlobalSite,
  selectAllCities,
  selectAllSites,
  TGlobalCurrency,
  TGlobalCity,
  TGlobalCountry,
  selectGlobalCurrencies,
  setGlobalCurrencies,
} from "src/store/directionsReducer";
import { CurrencyColumn } from "src/pages/Tetris/MiniTetris/components/CurrencyColumn";
import { selectFilters } from "src/store/tetrisReduser";
import { allFilter } from "src/pages/Tetris/components/allFilter";
import { MiniNotice } from "src/components/UI/notice/Notice";
import { ICity } from "src/types/OnOffTimeline/common";
import { baseUnavailableDirections, TPairs, TSelectedPair } from "src/store/onOffReducer";
import { setAdminSites } from "src/store/commonReducer";
import addApi from "src/api/add";
import { fetchRequest } from "src/helpers/fetchRequest";
import { useAppDispatch } from "src/store/store";
import { Button } from "src/shadcn/ui/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";
import { v4 } from "uuid";
import { WaitingLoader } from "src/components/UI/loader/WaitingLoader";
import { VectorsPreview } from "src/pages/Add/components/Vectors/VectorsPreview";
import Select, { MultiValue } from "react-select";
import { Popover, PopoverContent, PopoverTrigger } from "src/shadcn/ui/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/shadcn/ui/ui/table";
import { autoCity } from "src/api/constants";
import switchApi from "src/api/onOff";

export interface CreateAllCombinationsProps {
  cities: TGlobalCity[];
  sites: TGlobalSite[];
  currencies_from: TGlobalCurrency[];
  currencies_to: TGlobalCurrency[];
  countriesToCitiesAndCurrencies: CountryToCitiesAndCurrencies[];
}

const createAllCombinations = ({
  cities,
  sites,
  currencies_from,
  currencies_to,
  countriesToCitiesAndCurrencies,
}: CreateAllCombinationsProps): {
  allCombinations: Partial<TSelectedPair>[];
  combinations: Partial<TSelectedPair>[];
  noValidVectors: boolean;
} => {
  if (
    cities.length > 0 &&
    sites.length === 0 &&
    currencies_to.length === 0 &&
    currencies_from.length === 0
  ) {
    return {
      allCombinations: cities.map((city) => ({
        city: { ...city, currency_pairs: [] },
        id: v4(),
      })),
      combinations: cities.map((city) => ({
        city: { ...city, currency_pairs: [] },
        id: v4(),
      })),
      noValidVectors: false,
    };
  }

  const maxLength = Math.max(
    cities.length,
    sites.length,
    currencies_from.length,
    currencies_to.length
  );

  // ключ для хранения инфы о том, использовалась ли комбинация
  const createKey = (...args: string[]) => `${args[0]}_${args[1]}_${args[2]}_${args[3]}`;

  const combinations: TSelectedPair[] = [];
  const allCombinations: TSelectedPair[] = [];
  let noValidVectors = true;
  const included = new Set();

  // составляем все возможные комбинации с присутствием (и отсутсвием, если она не была выбрана) каждой из сущностей
  for (let a = 0; a < (cities?.length || maxLength); a++) {
    for (let b = 0; b < (sites?.length || maxLength); b++) {
      for (let c = 0; c < (currencies_from?.length || maxLength); c++) {
        for (let d = 0; d < (currencies_to?.length || maxLength); d++) {
          const city = cities[a];
          const site = sites[b];
          const currency_from = currencies_from[c];
          const currency_to = currencies_to[d];

          const key = createKey(
            city?.city_code || "",
            site?.site_name || "",
            currency_from?.currency_name || "",
            currency_to?.currency_name || ""
          );

          const combination = {
            id: v4(),
            city: cities[a] as unknown as ICity,
            site: sites[b]?.site_name,
            from: currencies_from[c],
            to: currencies_to[d],
          };

          allCombinations.push(combination);

          const isUnavailable = baseUnavailableDirections?.find(
            (dir) => dir.from_type === currency_from?.type && dir.to_type === currency_to?.type
          );

          let invalidCountry = false;
          const country = countriesToCitiesAndCurrencies.find((item) =>
            item.cities.includes(city?.city_code)
          )?.name_ru;
          if (country) {
            const availableCurrencies =
              countriesToCitiesAndCurrencies.find((item) => item.name_ru === country)?.currencies ||
              [];

            if (
              (currency_from &&
                !availableCurrencies.includes(currency_from.currency_name.replace("20", ""))) ||
              (currency_to &&
                !availableCurrencies.includes(currency_to.currency_name.replace("20", "")))
            ) {
              invalidCountry = true;
            }
          }

          // в нашем случае пользователю не нужны комбинации, где отсутствует сущность,
          // которую он включал в выбор, а также нам не нужны дубликаты и недопустимые сочетания,
          // поэтому добавляем фильтрацию
          if (
            (!city && cities.length > 0) ||
            (!site && sites.length > 0) ||
            (!currency_from && currencies_from.length > 0) ||
            (!currency_to && currencies_to.length > 0) ||
            included.has(key) ||
            !!isUnavailable ||
            invalidCountry
          ) {
            continue;
          }

          noValidVectors = false;
          combinations.push(combination);
          included.add(key);
        }
      }
    }
  }

  return {
    allCombinations,
    combinations,
    noValidVectors,
  };
};

type CreateVectorsPayload = {
  city_names: string[];
  site_names: string[];
  currency_from_codes: string[];
  currency_to_codes: string[];
};

const convertToPayload = (vectors: Partial<TSelectedPair>[]): CreateVectorsPayload => {
  const city_names = new Set<string>();
  const site_names = new Set<string>();
  const currency_from_codes = new Set<string>();
  const currency_to_codes = new Set<string>();

  for (const vector of vectors) {
    vector.city && city_names.add(vector.city.city_name);
    vector.site && site_names.add(vector.site);
    vector.from && currency_from_codes.add(vector.from.currency_name);
    vector.to && currency_to_codes.add(vector.to.currency_name);
  }

  return {
    city_names: Array.from(city_names),
    site_names: Array.from(site_names),
    currency_from_codes: Array.from(currency_from_codes).map((item) =>
      item === "USDTERC20" || item === "USDTTRC20" ? item.replace("20", "") : item
    ),
    currency_to_codes: Array.from(currency_to_codes).map((item) =>
      item === "USDTERC20" || item === "USDTTRC20" ? item.replace("20", "") : item
    ),
  };
};

const page = {
  page: "",
  title: "",
  read_access: "",
  update_access: "",
  color: "#72E5AE",
  text: "#BABAC3",
};

interface CountryToCitiesAndCurrencies extends TGlobalCountry {
  cities: string[];
  currencies: string[];
}

export const Vectors = () => {
  const dispatch = useAppDispatch();
  const filters = useSelector(selectFilters);
  const globalSites = useSelector(selectAllSites);
  const globalCities = useSelector(selectAllCities);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<boolean | null>(null);
  const [selectedPairs, setSelectedPairs] = useState<TSelectedPair[]>([]);
  const [noValidVectors, setNoValidVectors] = useState(false);
  const [vectorsPreviewOpen, setVectorsPreviewOpen] = useState(false);
  const [dbGenerator, setDbGenerator] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [countriesToCitiesAndCurrencies, setCountriesToCitiesAndCurrencies] = useState<
    CountryToCitiesAndCurrencies[]
  >([]);
  const [sites, setSites] = useState<TGlobalSite[]>([]);
  const [cities, setCities] = useState<TGlobalCity[]>([]);
  const [invalidCountriesToCurrencies, setInvalidCountriesToCurrencies] = useState<
    Record<string, Set<string>>
  >({});
  const [pairs, setPairs] = useState<TPairs>({
    from: [],
    to: [],
  });

  const countriesToCitiesOptions = countriesToCitiesAndCurrencies.map((country) => ({
    value: country.name_ru,
    label: country.name_ru,
  }));

  const currencies = useSelector(selectGlobalCurrencies);
  const allCities = useMemo(
    () =>
      globalCities
        ?.filter((el) => el.city_name !== "all")
        ?.filter((el) => allFilter(el.city_name, "cities", filters)),
    [globalCities, filters.cities]
  );

  const fetchGlobalCurrencies = async () => {
    const { response } = await fetchRequest(switchApi.getGlobalDirections(), {
      request: `Глобальные данные`,
    });
    if (response) {
      dispatch(setGlobalCurrencies(response.currencies));
    }
  };

  const fetchAdminSites = async () => {
    const { response } = await fetchRequest(addApi.getSites(), {
      request: "Кредо",
    });
    if (response) {
      dispatch(setAdminSites(response));
    }
  };

  const fetchCountriesToCitiesToCurrencies = async () => {
    const { response } = await fetchRequest(addApi.getCountriesToCitiesAndCurrencies());
    if (response) {
      setCountriesToCitiesAndCurrencies(response);
    }
  };

  useEffect(() => {
    fetchGlobalCurrencies();
    fetchAdminSites();
    fetchCountriesToCitiesToCurrencies();
  }, []);

  useEffect(() => {
    setSelectedCountries((prev) =>
      prev.filter((country) => {
        const item = countriesToCitiesAndCurrencies.find((el) => el.name_ru === country);
        if (!item) {
          return true;
        }
        return cities.some((city) => item.cities.includes(city.city_code));
      })
    );
  }, [cities]);

  const cityAdd = (city: Partial<TGlobalCity>) => {
    const newData = [...cities];
    const existingCityIndex = newData.findIndex((item) => item.city_code === city.city_code);
    if (~existingCityIndex) {
      newData.splice(existingCityIndex, 1);
    } else {
      newData.push(city as TGlobalCity);
    }
    setCities(newData);
  };

  // точечно добавляет и удаляет города, относящиеся к выбранным странам
  const onCountriesSelect = (e: MultiValue<{ value: string; label: string }>) => {
    const addedCountries = e.filter(
      (item) => !selectedCountries.some((country) => country === item.value)
    );
    const removedCountries = selectedCountries.filter(
      (country) => !e.some((item) => item.value === country)
    );

    const citiesSet = new Set([...cities].map((item) => item.city_code));

    removedCountries.forEach((country) => {
      const item = countriesToCitiesAndCurrencies.find((el) => el.name_ru === country);
      if (item) {
        item.cities.forEach((city) => citiesSet.delete(city));
      }
    });
    addedCountries.forEach((country) => {
      const item = countriesToCitiesAndCurrencies.find((el) => el.name_ru === country.value);
      if (item) {
        item.cities.forEach((city) => citiesSet.add(city));
      }
    });

    const newCities = Array.from(citiesSet)
      .map((item) => globalCities.find((city) => city.city_code === item))
      .filter((item) => !!item);

    setSelectedCountries(e.map((item) => item.value));
    setCities(newCities);
  };

  const getInvalidCountryToCurrencies = (combinations: Partial<TSelectedPair>[]) => {
    const invalid: Record<string, Set<string>> = {};

    for (const vector of combinations) {
      if (vector.city) {
        const country = countriesToCitiesAndCurrencies.find((item) =>
          item.cities.includes(vector.city.city_code)
        )?.name_ru;
        if (country) {
          if (!invalid[country]) {
            invalid[country] = new Set();
          }
          const availableCurrencies =
            countriesToCitiesAndCurrencies.find((item) => item.name_ru === country)?.currencies ||
            [];

          if (
            vector.from &&
            !availableCurrencies.includes(vector.from.currency_name.replace("20", ""))
          ) {
            invalid[country].add(vector.from.currency_name);
          }
          if (
            vector.to &&
            !availableCurrencies.includes(vector.to.currency_name.replace("20", ""))
          ) {
            invalid[country].add(vector.to.currency_name);
          }
        }
      }
    }

    return invalid;
  };

  const createCombinations = useCallback(() => {
    const { allCombinations, combinations, noValidVectors } = createAllCombinations({
      cities,
      sites,
      currencies_from: pairs.from,
      currencies_to: pairs.to,
      countriesToCitiesAndCurrencies,
    });

    setInvalidCountriesToCurrencies(getInvalidCountryToCurrencies(allCombinations));
    setSelectedPairs(combinations as TSelectedPair[]);
    setNoValidVectors(noValidVectors);
  }, [pairs, cities, sites]);

  useEffect(() => {
    createCombinations();
  }, [pairs, cities, sites]);

  const addSite = (newSite: TGlobalSite) => {
    const newSites = [...sites];
    const index = newSites.findIndex((site) => site.site_id === newSite.site_id);
    if (~index) {
      newSites.splice(index, 1);
    } else {
      newSites.push(newSite);
    }

    setSites(newSites);
  };

  const openCreateVectors = () => {
    createCombinations();
    setVectorsPreviewOpen(true);
  };

  const submit = async () => {
    setIsLoading(true);
    const { response, error } = await fetchRequest(
      addApi.createVectors({
        ...convertToPayload(selectedPairs),
        add_in_db_generator: dbGenerator,
      }),
      {
        request: "Создание векторов",
      }
    );
    if (response) {
      setMessage(`Вектор успешно создан`);
      setStatus(true);
    }
    if (error) {
      setMessage("Не удалось создать вектор");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      if (response && !error) {
        setVectorsPreviewOpen(false);
      }
    }, 4600);
  };

  const noItemsSelected =
    cities.length === 0 && sites.length === 0 && pairs.from.length === 0 && pairs.to.length === 0;
  const openCreateVectorsDisabled = noItemsSelected || noValidVectors;
  const submitCreateVectorsDisabled = selectedPairs.length === 0;
  const emptyPairsMessage = openCreateVectorsDisabled
    ? "Вы еще не создали векторы"
    : "Нет валидных комбинаций";

  const hasInvalidCountriesToCurrencies = Object.values(invalidCountriesToCurrencies).some(
    (val) => val.size > 0
  );

  return (
    <div className="flex flex-col gap-[10px] pb-[30px]">
      <WaitingLoader
        message={message}
        status={status}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <div className="flex items-center gap-[10px] justify-center flex-wrap">
        {/* invalid countries to currencies */}
        {hasInvalidCountriesToCurrencies && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button size="icon" variant="outline" className="border-red">
                      !
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="w-[150px]">Страна</TableHead>
                          <TableHead className="text-right">Отсутствующие валюты</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {Object.entries(invalidCountriesToCurrencies).map(([country, currencies]) =>
                          currencies.size > 0 ? (
                            <TableRow key={country}>
                              <TableCell className="font-medium w-[150px]">
                                {country === "Объединенные Арабские Эмираты" ? "ОАР" : country}
                              </TableCell>
                              <TableCell>
                                <div className="text-right w-[180px] overflow-x-auto no-scrollbar whitespace-nowrap">
                                  {Array.from(currencies).join(", ")}
                                </div>
                              </TableCell>
                            </TableRow>
                          ) : null
                        )}
                      </TableBody>
                    </Table>
                  </PopoverContent>
                </Popover>
              </TooltipTrigger>
              <TooltipContent>Есть несоответствия стран и валют</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button
                size="sm"
                className="px-[10px]"
                onClick={openCreateVectors}
                disabled={openCreateVectorsDisabled}>
                Создать векторы
              </Button>
            </TooltipTrigger>
            <TooltipContent hidden={!noValidVectors}>Нет валидных комбинаций</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {!noItemsSelected && (
          <Button
            onClick={() => {
              setSites([]);
              setCities([]);
              setPairs({
                from: [],
                to: [],
              });
            }}
            size="sm"
            variant="outline"
            className="px-[10px]">
            Сбросить выбор
          </Button>
        )}
      </div>
      <div className="relative min-w-fit w-full lg:h-[75vh] lg:max-h-none h-[1000px] overflow-y-hidden rounded-sm bg-[#F5F5FD] mr-0">
        <div className="flex flex-col lg:flex-row px-[10px] pb-[10px] h-full overflow-hidden">
          <div
            id="sites"
            className="min-w-[170px] lg:mr-[8px] h-full flex flex-col overflow-y-hidden">
            <div className="sticky top-0 flex flex-col z-10 w-full">
              {!!sites?.length && (
                <MiniNotice
                  count={sites?.length}
                  isVisible
                  right={6}
                  bg="#BBFFDA"
                  color="#2BB56A"
                />
              )}
            </div>
            <div className="flex flex-col h-full overflow-y-auto pt-8">
              {globalSites?.map((el, index) => (
                <button
                  key={index}
                  id={el.site_name}
                  type="button"
                  style={{
                    backgroundColor: sites?.some((item) => item.site_name === el.site_name)
                      ? `${page.color}77`
                      : "#FAFAFF",
                  }}
                  className={`relative select_button mx-0 h-[30px] w-full text-sm shrink-0  ${
                    sites?.some((item) => item.site_name === el.site_name) ? "font-semibold" : ""
                  }`}
                  onClick={() => addSite(el)}>
                  {el.site_name}
                </button>
              ))}
            </div>
          </div>
          <div
            id="cities"
            className="w-full lg:mr-[8px] lg:w-[180px] h-full flex flex-col overflow-hidden">
            <div className="sticky top-0 flex flex-col z-10">
              {!!cities?.length && (
                <MiniNotice
                  count={cities?.length}
                  isVisible
                  right={6}
                  bg="#BBFFDA"
                  color="#2BB56A"
                />
              )}
              <Select
                placeholder="Страны"
                className="text-[12px]"
                value={countriesToCitiesOptions.filter((item) =>
                  selectedCountries.some((country) => country === item.value)
                )}
                onChange={onCountriesSelect}
                options={countriesToCitiesOptions}
                isMulti
              />
            </div>
            <div className="flex flex-col h-full overflow-y-auto pt-8 w-full">
              {[autoCity, ...allCities]?.map((el, index) => (
                <button
                  key={index}
                  id={el.city_code}
                  type="button"
                  style={{
                    backgroundColor: cities?.some((item) => item.city_code === el.city_code)
                      ? `${page.color}77`
                      : "#FAFAFF",
                  }}
                  className={`relative select_button h-[30px] mx-0 w-full text-sm shrink-0  ${
                    cities?.some((item) => item.city_code === el.city_code) ? "font-semibold" : ""
                  }`}
                  onClick={() => cityAdd(el)}>
                  {el.city_name}
                </button>
              ))}
            </div>
          </div>
          <div
            id="vectors"
            className="grid grid-cols-2 gap-[12px] w-full mx-auto lg:max-w-[340px] ml-[6px] min-h-[300px] flex-col overflow-hidden">
            <div className="overflow-y-auto min-h-[300px]">
              <CurrencyColumn
                directionType="from"
                globalCurrencies={currencies}
                pairs={pairs}
                setPairs={setPairs}
                color={`${page.color}77`}
                withSelect={false}
                noPlusButton
              />
            </div>
            <div className="overflow-y-auto">
              <CurrencyColumn
                directionType="to"
                globalCurrencies={currencies}
                pairs={pairs}
                setPairs={setPairs}
                color={`${page.color}77`}
                withSelect={false}
                noPlusButton
              />
            </div>
          </div>
        </div>
        {vectorsPreviewOpen && (
          <VectorsPreview
            isLoading={isLoading}
            disabled={submitCreateVectorsDisabled || isLoading}
            closeModal={() => setVectorsPreviewOpen(false)}
            dbGenerator={dbGenerator}
            emptyPairsMessage={emptyPairsMessage}
            selectedPairs={selectedPairs}
            setDbGenerator={setDbGenerator}
            setSelectedPairs={setSelectedPairs}
            submit={submit}
          />
        )}
      </div>
    </div>
  );
};
