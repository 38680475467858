import { timeToSeconds } from "src/helpers/date";
import {
  joinSegments,
  joinWithUnsubmitted,
  sortSegmentsByStart,
} from "src/helpers/onOffTimeline/combine";
import { NewDataType } from "src/pages/Panel/OnOff/OnOffTimeline/components/forms/ItemForm";
import { ITimeSegment } from "src/types/OnOffTimeline/common";
import { SelectedItem } from "src/types/OnOffTimeline/store";

/**
 * Суммирует результаты копирования,
 * подводит черту
 */
export function getChangesAfterCopy(
  pasteItem: Partial<SelectedItem>,
  applyingEl: Partial<SelectedItem>,
  username: string
) {
  const unsubmitted_segments_best = getUnsubmittedSegments(
    pasteItem.segments_best,
    applyingEl.segments_best,
    username
  );

  const unsubmitted_segments_site = getUnsubmittedSegments(
    pasteItem.segments_site,
    applyingEl.segments_site,
    username
  );

  return { unsubmitted_segments_best, unsubmitted_segments_site };
}

/**
 * Принимает на вход текущие интервалы объекта вставки
 * и текущие интервалы объекта копирования и возвращает
 * необходимые изменения для объекта вставки в виде массива
 */
export function getUnsubmittedSegments(
  pasteItemData: ITimeSegment[],
  copyItemData: ITimeSegment[],
  creator: string
): ITimeSegment[] {
  const itemsToCreate = getSegmentsToCreate(pasteItemData, copyItemData);
  const itemsToDelete = getSegmentsToDelete(pasteItemData, copyItemData);

  return [
    ...itemsToCreate.map((item) => ({ ...item, creator })),
    ...itemsToDelete.map((item) => ({ ...item, creator, deleted_flag: true })),
  ].sort(sortSegmentsByStart); // сортировка для более удобного тестирования и чтоб все имело стандартизированный вид
}

/**
 * Собирает сегменты, которым нужно будет
 * дать флаг deleted_flag, основываясь
 * на отличиях старых данных от новых
 */
export function getSegmentsToDelete(oldData: ITimeSegment[], newData: ITimeSegment[]) {
  const result: ITimeSegment[] = [];

  oldData
    .map((item) => getSegmentPartsToDelete(item, newData))
    .forEach((item) => {
      item.forEach((segment) => result.push(segment));
    });

  return result;
}

/**
 * Находит все пересечения сегмента
 * с пробелами в новых данных и возвращает эти пересечения.
 * Таким образом, мы получаем все части сегмента, которые
 * больше не существуют, то есть должны быть помечены
 * флагом deleted_flag
 */
export function getSegmentPartsToDelete(segment: ITimeSegment, newData: ITimeSegment[]) {
  // если в новых данных ничего нет,
  // значит, весь сегмент должен быть удален
  if (segment && newData.length === 0) {
    return [segment];
  }

  const result: ITimeSegment[] = [];

  // находим все промежутки в новых данных
  let gaps = findGapsInSequence(newData);

  const segmentsStart = Math.min(...newData.map((item) => timeToSeconds(item.start)));
  const segmentStart = timeToSeconds(segment.start);
  const segmentsEnd = Math.max(...newData.map((item) => timeToSeconds(item.end)));
  const segmentEnd = timeToSeconds(segment.end);

  // если старый сегмент начинается раньше, чем новые сегменты,
  // искусственно добавляем пробел в начало
  if (segmentStart < segmentsStart) {
    gaps.unshift({
      start: segment.start,
      end: new Date(segmentsStart * 1000).toISOString().substr(11, 5),
    });
  }

  // если старый сегмент кончается позже, чем новые сегменты,
  // искусственно добавляем пробел в конец
  if (segmentEnd > segmentsEnd) {
    gaps.push({
      start: new Date(segmentsEnd * 1000).toISOString().substr(11, 5),
      end: segment.end,
    });
  }

  gaps = joinSegments(gaps);

  // находим все пересечения старого сегмента
  // с пробелами в новых данных
  // если пересечение найдено - значит, что теперь на месте данных,
  // которые раньше существовали, будет пробел,
  // следовательно, данные считаются удаленными,
  // поэтому собираем их в итоговом массиве и возвращаем
  gaps.forEach((gap) => {
    const intersection = findIntersection(gap, segment);

    if (intersection) {
      result.push({
        ...intersection,
        id: segment.id,
      });
    }
  });

  return result;
}

/**
 * Основываясь на старых и новых данных,
 * определяет, какие сегменты в новых данных должны быть созданы.
 * Функция находит все промежутки старых данных, которые не были заполнены,
 * и затем находит пересечения этих промежутков с новыми данными.
 * Если пересечение было найдено - это означает, что на месте, где
 * до этого не было никаких данных, теперь что-то есть - поэтому это
 * считается созданием нового сегмента. Все такие пересечения собираются
 * в массиве и возвращаются
 */
export function getSegmentsToCreate(
  pasteSegments: ITimeSegment[],
  copySegments: ITimeSegment[]
): ITimeSegment[] {
  const editingSegments = [...pasteSegments];

  // 1 находим все отрезки, которые отсутствовали в старых данных
  const gaps = findGapsInSequence(editingSegments);

  // 2 находим все пересечения отрезков из пункта 1 с добавляемыми отрезками
  const res: ITimeSegment[] = [];
  gaps.forEach((gap) => {
    copySegments.forEach((segment) => {
      const intersection = findIntersection(gap, segment);

      if (intersection) {
        res.push(intersection);
      }
    });
  });

  return res;
}

/**
 * Находит все отрезки таймлайна, которые не были заполнены
 * сегментами из параметра data, и возвращает их в виде массива
 */
export function findGapsInSequence(data: ITimeSegment[]): ITimeSegment[] {
  const gaps: ITimeSegment[] = [];
  const segments = [...data];

  const segmentsStart = Math.min(...segments.map((item) => timeToSeconds(item.start)));
  const segmentsEnd = Math.max(...segments.map((item) => timeToSeconds(item.end)));

  const minStart = 0;
  const maxEnd = timeToSeconds("23:59");

  // если интервалы data начинаются не с начала таймлайна,
  // искусственно добавляем нижнюю границу
  if (minStart < segmentsStart) {
    segments.unshift({ start: "00:00", end: "00:00" });
  }

  // если интервалы data заканчиваются не с в конце таймлайна,
  // искусственно добавляем верхнюю границу
  if (maxEnd > segmentsEnd) {
    segments.push({ start: "23:59", end: "23:59" });
  }

  segments.sort(sortSegmentsByStart);

  for (let i = 0; i < segments.length - 1; i++) {
    const currentSegmentEndSeconds = timeToSeconds(segments[i].end);
    const nextSegmentStartSeconds = timeToSeconds(segments[i + 1].start);

    if (currentSegmentEndSeconds < nextSegmentStartSeconds) {
      gaps.push({
        start: segments[i].end,
        end: segments[i + 1].start,
      });
    }
  }

  return gaps;
}

/**
 * Находит отрезок пересечения
 * двух переданных в нее сегментов.
 * Если у сегментов нет пересечения,
 * возвращает null
 */
export function findIntersection(
  segment1: ITimeSegment,
  segment2: ITimeSegment
): ITimeSegment | null {
  const start1 = timeToSeconds(segment1.start);
  const end1 = timeToSeconds(segment1.end);
  const start2 = timeToSeconds(segment2.start);
  const end2 = timeToSeconds(segment2.end);

  const start = Math.max(start1, start2);
  const end = Math.min(end1, end2);

  if (start < end) {
    return {
      start: new Date(start * 1000).toISOString().substr(11, 5),
      end: new Date(end * 1000).toISOString().substr(11, 5),
    };
  }

  return null;
}

/**
 * На основе данных копируемого сегмента
 * определяет, каким бы он был, если бы были приняты все
 * его неподтвержденные изменения, и возвращает этот
 * "обещанный" сегмент
 */
export function getThePromised(copiedItem: Partial<SelectedItem>): Partial<SelectedItem> {
  return {
    ...copiedItem,
    segments_site: joinWithUnsubmitted(
      copiedItem.segments_site,
      copiedItem.unsubmitted_segments_site
    ),
    segments_best: joinWithUnsubmitted(
      copiedItem.segments_best,
      copiedItem.unsubmitted_segments_best
    ),
  };
}

export function addMissingGreenSegments(data: NewDataType) {
  /**
   * добавляем зеленые отрезки, если были созданы синие,
   * для которых не существует соответствующих зеленых
   */
  const newData = { ...data };

  const newGreenSegments = getSegmentsToCreate(newData.segments_site, newData.segments_best);
  newData.segments_site = joinSegments([...newData.segments_site, ...newGreenSegments]);

  return newData;
}
