import React, { useEffect, useState } from "react";
import { fetchRequest } from "src/helpers/fetchRequest";
import graphsApi from "src/api/graphs";

export const Analytics = () => {
  const [graphLink, setGraphLink] = useState<string>("");

  const fetchDashboard = async () => {
    const { response } = await fetchRequest(graphsApi.dashboard(), { request: "Дашборд" });
    if (response) {
      setGraphLink(response.DASHBOARD);
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <div className="relative w-[calc(100%-20px)] sm:w-[calc(100%-50px)] h-[100vh] top-[-30px] pr-[100px] mb-[100px]">
      <iframe
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          paddingBottom: "100px",
        }}
        src={graphLink}></iframe>
      <div className="w-full h-[400px]" />
    </div>
  );
};
