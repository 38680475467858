import { createSlice } from "@reduxjs/toolkit";
import { StateT } from "./store";
import {
  TAttempt,
  TAttemptResult,
  TBlock,
  TModule,
  TMyAnswers,
  TQuestion,
  TTest,
} from "src/pages/Academy/types";

const academySlice = createSlice({
  name: "academy",
  initialState: {
    tests: [] as TTest[],
    test: null as null | TTest,
    modules: [] as TModule[],
    blocks: [] as TBlock[],
    questions: [] as TQuestion[],
    attempts: null as TAttempt[] | null,
    attempt: null as TAttempt | null,
    myAnswers: null as TMyAnswers | null,
    result: null as TAttemptResult | null,
  },
  reducers: {
    setTests: (state, action) => {
      state.tests = action.payload;
    },
    setTest: (state, action) => {
      state.test = action.payload;
    },
    setModules: (state, action) => {
      const payload = [...action.payload];
      state.modules = payload?.sort((a: TModule, b: TModule) =>
        a.module_order > b.module_order ? 1 : -1
      );
    },
    setBlocks: (state, action) => {
      const payload = [...action.payload];
      state.blocks = payload?.sort((a: TBlock, b: TBlock) =>
        a.block_order > b.block_order ? 1 : -1
      );
    },
    setQuestions: (state, action) => {
      const payload = [...action.payload];
      state.questions = payload?.sort((a: TQuestion, b: TQuestion) =>
        a.question_order > b.question_order ? 1 : -1
      );
    },
    setAttempts: (state, action) => {
      state.attempts = action.payload;
    },
    setAttempt: (state, action) => {
      state.attempt = action.payload;
    },
    setMyAnswers: (state, action) => {
      state.myAnswers = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    reset: (state) => {
      state.test = null;
      state.modules = [];
      state.blocks = [];
      state.questions = [];
      state.attempt = null;
      state.result = null;
    },
  },
});

export const {
  setTests,
  setTest,
  setModules,
  setBlocks,
  setQuestions,
  setAttempts,
  setAttempt,
  setMyAnswers,
  setResult,
  reset,
} = academySlice.actions;

export const selectTests = (state: StateT): TTest[] => state.academy.tests;
export const selectTest = (state: StateT): TTest | null => state.academy.test;
export const selectModules = (state: StateT): TModule[] => state.academy.modules;
export const selectBlocks = (state: StateT): TBlock[] => state.academy.blocks;
export const selectQuestions = (state: StateT): TQuestion[] => state.academy.questions;
export const selectAttempts = (state: StateT): TAttempt[] | null => state.academy.attempts;
export const selectAttempt = (state: StateT): TAttempt | null => state.academy.attempt;
export const selectMyAnswers = (state: StateT): TMyAnswers | null => state.academy.myAnswers;
export const selectResult = (state: StateT): TAttemptResult | null => state.academy.result;

export default academySlice.reducer;
