import { centrifuge } from "./centrifugo";
import { TStatsReport } from "src/pages/Stats/models";
import { setStatsReports } from "src/store/statsReducer";
import { store } from "src/store/store";

export const createStatsReportsSubscription = (resubscribe: boolean, unsubscribe: boolean) => {
  const subscription = centrifuge.subscribe(
    `analytics:reports`,
    async function (res: { data: TStatsReport }) {
      !!res?.data && store.dispatch(setStatsReports(res.data));
    }
  );
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`analytics:reports`, async function (res) {
      !!res?.data && store.dispatch(setStatsReports(res.data));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      if (res.publications[0].data) {
        store.dispatch(setStatsReports(res.publications[0].data));
      }
    } catch (e) {
      console.log(e);
    }
  });
};
