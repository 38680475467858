import { useState, useRef, useEffect } from "react";

export const useDebounceTimer = (ms = 500, ...params: any) => {
  const [blocked, setBlocked] = useState(true);

  const timerId = useRef<NodeJS.Timeout>();

  // two useEffects for debounce
  useEffect(() => {
    setBlocked(true);

    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    timerId.current = setTimeout(() => {
      setBlocked(false);
    }, ms);
  }, params);

  return { blocked };
};
