import { BASE_URL } from "./constants";
import api from "./http";

export default class devApi {
  static async getProxy() {
    return api.get(`${BASE_URL}/v1/proxy_api/v1/proxy/`);
  }
  static async addProxy(data) {
    return api.post(`${BASE_URL}/v1/proxy_api/v1/proxy/`, data);
  }
  static async buyProxy(data) {
    return api.post(`${BASE_URL}/v1/proxy_api/v1/proxy/buy/`, data);
  }
  static async updateProxy(data) {
    return api.patch(`${BASE_URL}/v1/proxy_api/v1/proxy/`, data);
  }
  static async prolongProxy(data) {
    return api.patch(`${BASE_URL}/v1/proxy_api/v1/proxy/prolong/`, data);
  }
  static async deleteProxy(id) {
    return api.delete(`${BASE_URL}/v1/proxy_api/v1/proxy/${id}/`);
  }
  static async getProxyCountries(version) {
    return api.get(
      `${BASE_URL}/v1/proxy_api/v1/proxy/get_available_countries/?proxy_version=${version}`
    ); // PROXY6
  }
  static async getProxyAvailable({ count, period, country, proxy_version }) {
    return api.get(
      `${BASE_URL}/v1/proxy_api/v1/proxy/proxy_available/?count=${count}&period=${period}&country=${country}&proxy_version=${proxy_version}`
    ); // PROXY6
  }
  // сервисы
  static async getServices() {
    return api.get(`${BASE_URL}/v1/proxy_api/v1/service/`);
  }
  static async addServices(data) {
    return api.post(`${BASE_URL}/v1/proxy_api/v1/service/`, data);
  }
  static async updateServices(data) {
    return api.patch(`${BASE_URL}/v1/proxy_api/v1/service/`, data);
  }
  static async deleteServices(id) {
    return api.delete(`${BASE_URL}/v1/proxy_api/v1/service/${id}/`);
  }
  // источники
  static async getSources() {
    return api.get(`${BASE_URL}/v1/proxy_api/v1/source/`);
  }
  static async addSources(data) {
    return api.post(`${BASE_URL}/v1/proxy_api/v1/source/`, data);
  }
  static async updateSources(data) {
    return api.patch(`${BASE_URL}/v1/proxy_api/v1/source/`, data);
  }
  static async deleteSources(id) {
    return api.delete(`${BASE_URL}/v1/proxy_api/v1/source/${id}/`);
  }
  static async getBalance() {
    return api.get(`${BASE_URL}/v1/proxy_api/v1/source/balance/`);
  }
}
