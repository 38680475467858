import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { convertToShortDate, defaultDateValue } from "src/helpers/date";

export const Deadline = ({ item, now, updateRequest }) => {
  const { register } = useFormContext();
  const [isUpdDeadline, setUpdDeadline] = useState(false);

  return (
    <div className="relative w-full h-[20px]">
      <div
        className={`absolute top-8 ${
          !!item?.deadline && +item.deadline / 1000 < now
            ? "bg-[#D7443E33] text-red"
            : "bg-stale text-font"
        } ${
          !isUpdDeadline ? "" : "-z-10 opacity-0"
        } pl-2 pr-4 rounded-full text-[9px] h-[14px] cursor-pointer`}
        onClick={() => {
          setUpdDeadline(true);
        }}>
        {`\u23F1`} {convertToShortDate(Math.round(+item?.deadline / 1000))}
      </div>
      <div
        className={`flex gap-2 items-center absolute top-4 ${
          isUpdDeadline ? "" : "-z-10 opacity-0"
        }`}>
        <input
          className={`h-[20px] border border-glass-gray rounded-sm font-light`}
          type={"datetime-local"}
          defaultValue={!!item?.deadline ? defaultDateValue(+item?.deadline) : ""}
          onKeyDown={async (e) => {
            if (e.keyCode === 13) {
              setUpdDeadline(false);
              await updateRequest();
            }
          }}
          {...register(`${item.id}.deadline`)}
        />
        <button
          className="w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#989CA4] text-base hover:brightness-90"
          onClick={() => setUpdDeadline(false)}>
          <span className={`relative top-[-1px] rotate-45 left-[1px]`}>+</span>
        </button>
        <button
          className={`w-[16px] min-w-[16px] h-[16px] bg-[#62F5B3] flex justify-center items-center rounded-sm text-white text-base hover:brightness-110`}
          onClick={async () => {
            setUpdDeadline(false);
            await updateRequest();
          }}>
          <span className={`relative top-[-0.5px] font-bold text-xs`}>✓</span>
        </button>
      </div>
    </div>
  );
};
