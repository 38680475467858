import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LS } from "src/api/constants";
import { setPinned } from "src/store/commonReducer";
import { navigation } from "./navigation";
import { Pinned } from "./Pinned";
import { IconNavItem } from "./IconNavItem";

export const IconsNav = ({ page, userAccess, pinned, isBurger }) => {
  const dispatch = useDispatch();
  const [isOpenTabs, setIsOpenTabs] = useState("");

  useEffect(() => {
    if (!!localStorage.getItem(LS.PIN)) {
      dispatch(setPinned(localStorage.getItem(LS.PIN)?.split(", ")));
    }
  }, []);

  useEffect(() => {
    if (!isBurger) {
      setIsOpenTabs("");
    }
  }, [isBurger]);

  return (
    <>
      {!!pinned?.length && (
        <div className={`flex flex-col`}>
          <Pinned pinned={pinned} page={page} userAccess={userAccess} hidePin={false} />
        </div>
      )}
      <div className={`flex flex-col gap-8 pt-8`}>
        {Object.entries(navigation.blocks)?.map((el) => {
          if (userAccess?.some((access) => el[1]?.access?.includes(access))) {
            return (
              <IconNavItem
                key={el[0]}
                item={el[1]}
                page={page}
                isOpenTabs={isOpenTabs}
                setIsOpenTabs={setIsOpenTabs}
                isTitle
                pinned={pinned}
                hidePin={false}
                userAccess={userAccess}
              />
            );
          }
        })}
      </div>
      <div className={`flex flex-col gap-8 pt-8`}>
        {Object.entries(navigation.tools)?.map((el) => {
          if (userAccess?.some((access) => el[1]?.access?.includes(access))) {
            return (
              <IconNavItem
                key={el[0]}
                item={el[1]}
                page={page}
                isOpenTabs={isOpenTabs}
                setIsOpenTabs={setIsOpenTabs}
                isTitle
                pinned={pinned}
                hidePin={false}
                userAccess={userAccess}
              />
            );
          }
        })}
      </div>
      <div className={`flex flex-col gap-8 pt-8`}>
        {Object.entries(navigation.helpers)?.map((el) => {
          if (userAccess?.some((access) => el[1]?.access?.includes(access))) {
            return (
              <IconNavItem
                key={el[0]}
                item={el[1]}
                page={page}
                isOpenTabs={isOpenTabs}
                setIsOpenTabs={setIsOpenTabs}
                isTitle={false}
                pinned={pinned}
                hidePin={false}
                userAccess={userAccess}
              />
            );
          }
        })}
      </div>
    </>
  );
};
