import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FILTERED_urls } from "../api/constants";
import { StateT } from "./store";

const initialState = {
  isLogged: false,
  isCentrifugoConnection: false,
  notifications: [] as Array<NotificationT>,
  notificationsFilter: [] as Array<string>,
  lastViewedVersion: "",
  viewers: FILTERED_urls as { [p: string]: string[] },
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setLogin: (state) => {
      state.isLogged = true;
    },
    setLogout: (state) => {
      state.isLogged = false;
    },
    setCentrifugoConnection: (state, { payload }: PayloadAction<boolean>) => {
      state.isCentrifugoConnection = payload;
    },
    setViewers: (state, { payload }) => {
      state.viewers = payload;
    },
    setNotific: (state, { payload }) => {
      const request = state.notifications?.map((el) => el.request);
      if (!request.includes(payload.request)) {
        state.notifications = [...state.notifications, payload];
      }
    },
    setNotificFilter: (state, { payload }) => {
      state.notificationsFilter = payload;
    },
    deleteNotific: (state, { payload }) => {
      state.notifications = state.notifications?.filter((el) => el.request !== payload.request);
    },
    setLastViewed: (state, { payload }) => {
      state.lastViewedVersion = payload;
    },
  },
});

export const {
  setLogin,
  setLogout,
  setCentrifugoConnection,
  setViewers,
  setNotific,
  setNotificFilter,
  deleteNotific,
  setLastViewed,
} = mainSlice.actions;

export const selectIsLogged = (state: StateT) => state.main.isLogged;
export const selectCentrifugoConnection = (state: StateT) => state.main.isCentrifugoConnection;
export const selectViewers = (state: StateT) => state.main.viewers;
export const selectNotifications = (state: StateT): Array<NotificationT> =>
  state.main.notifications;
export const selectNotificFilter = (state: StateT): Array<string> => state.main.notificationsFilter;
export const selectLastViewed = (state: StateT) => state.main.lastViewedVersion;

export default mainSlice.reducer;

export type NotificationT = {
  type: "error" | "success";
  message: string;
  request: string;
};
