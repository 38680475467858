import { createSelector } from "@reduxjs/toolkit";
import {
  checkIsOnOffEntityEdited,
  CheckIsEditedLevel,
  getFilterFn,
  checkIsOnOffEntityNew,
} from "src/helpers/onOffTimeline/checkIsEdited";
import { checkIsFullyExpanded } from "src/helpers/onOffTimeline/checkIsFullyExpanded";
import { SelectIsEditedProps } from "src/types/OnOffTimeline/common";
import { ExpandedStateLevel } from "src/types/OnOffTimeline/store";
import { StateT } from "src/store/store";
import { filterOnOffData } from "src/helpers/onOffTimeline/filterOnOffData";

export const selectOnOffUnfilterredData = (state: StateT) => state.onOffTimeline.timelineData;
export const selectOnOffTimelineData = createSelector(
  [(state: StateT) => state.onOffTimeline],
  (state) => filterOnOffData(state.timelineData, state.currencyFilters)
);
export const selectOnOffIsLoading = (state: StateT) => state.onOffNew.isLoading;
export const selectOnOffSitesLoading = (state: StateT) => state.onOffNew.sitesLoading;
export const selectSelectedItem = (state: StateT) => state.onOffNew.selectedItem;
export const selectOnOffTimelineStep = (state: StateT) => state.onOffNew.step;
export const selectOnOffTimelineZoom = (state: StateT) => state.onOffNew.zoom;
export const selectOnOffCurrencyFilters = (state: StateT) => state.onOffNew.currencyFilters;
export const selectOnOffIsAddMode = (state: StateT) => state.onOffNew.isAddMode;
export const selectOnOffIsSitesMenuOpen = (state: StateT) => state.onOffNew.isSitesMenuOpen;
export const selectOnOffExpandedState = (state: StateT) => state.onOffNew.expandedState;
export const selectOnOffIsCopy = (state: StateT) => state.onOffNew.isCopy;
export const selectOnOffCopiedItem = (state: StateT) => state.onOffNew.copiedItem;
export const selectOnOffDeleteMode = (state: StateT) => state.onOffNew.deleteMode;
export const selectCityTimelineWidth = (state: StateT) => state.onOffNew.cityTimeLineWidth;
export const selectTimelineContainerScrollLeft = (state: StateT) =>
  state.onOffNew.timelineContainerScrollLeft;
export const selectOnOffAxisFixed = (state: StateT) => state.onOffNew.axisFixed;
export const selectItemFormAllData = (state: StateT, selector: string) => {
  const [city, from, to] = selector.split("_");
  return state.onOffTimeline.timelineData
    .find((item) => item.city_code === city)
    ?.currency_pairs.find(
      (pair) => pair.currency_from.currency_name === from && pair.currency_to.currency_name === to
    )?.sites;
};
export const selectOnOffAllExpanded = (state: StateT) =>
  Object.values(state.onOffNew.expandedState).every((city) =>
    checkIsFullyExpanded(city, ExpandedStateLevel.CITY)
  );
export const selectIsCityExpanded = (state: StateT, cityCode: string) =>
  !!state.onOffNew.expandedState[cityCode]?.isExpanded;

export const selectIsGroupExpanded = (state: StateT, selector: string) => {
  const [cityCode, group] = selector.split("_");
  return !!state.onOffNew.expandedState[cityCode]?.children[group]?.isExpanded;
};
export const selectFromExpanded = (state: StateT, selector: string) => {
  const [cityCode, group, currencyFrom] = selector.split("_");
  return !!state.onOffNew.expandedState[cityCode]?.children[group]?.children[currencyFrom]
    ?.isExpanded;
};
export const selectIsPairExpanded = (state: StateT, selector: string) => {
  const [cityCode, group, from, to] = selector.split("_");
  return !!state.onOffNew.expandedState[cityCode]?.children[group]?.children[from]?.children[to]
    ?.isExpanded;
};
export const selectIsCityFullyExpanded = createSelector(
  [(state: StateT) => state.onOffNew.expandedState, (state: StateT, cityCode: string) => cityCode],
  (expandedState, cityCode) => {
    return checkIsFullyExpanded(expandedState[cityCode], ExpandedStateLevel.CITY);
  }
);
export const selectIsGroupFullyExpanded = createSelector(
  [(state: StateT) => state.onOffNew.expandedState, (state: StateT, selector: string) => selector],
  (expandedState, selector) => {
    const [cityCode, group] = selector.split("_");
    return checkIsFullyExpanded(expandedState[cityCode].children[group], ExpandedStateLevel.GROUP);
  }
);
export const selectIsFromFullyExpanded = createSelector(
  [(state: StateT) => state.onOffNew.expandedState, (state: StateT, selector: string) => selector],
  (expandedState, selector) => {
    const [cityCode, group, currencyFrom] = selector.split("_");
    return checkIsFullyExpanded(
      expandedState[cityCode].children[group].children[currencyFrom],
      ExpandedStateLevel.GROUPED_PAIR
    );
  }
);
export const selectOnOffIsEdited = createSelector(
  [(state: StateT) => state.onOffNew.timelineData, (_, props: SelectIsEditedProps) => props],
  (data, props) => {
    const city = data.find((item) => item.city_code === props.cityCode);

    if (!city) {
      return false;
    }

    const filterFn = getFilterFn(props);

    return checkIsOnOffEntityEdited({
      level: CheckIsEditedLevel.CITY,
      data: city,
      filterFn,
    });
  }
);
export const selectOnOffIsNew = createSelector(
  [(state: StateT) => state.onOffNew.timelineData, (_, props: SelectIsEditedProps) => props],
  (data, props) => {
    const city = data.find((item) => item.city_code === props.cityCode);

    if (!city) {
      return false;
    }

    const filterFn = getFilterFn(props);

    return checkIsOnOffEntityNew({
      level: CheckIsEditedLevel.CITY,
      data: city,
      filterFn,
    });
  }
);
export const selectOnOffConfirmation = (state: StateT) => state.onOffNew.confirmation;
export const selectOnOffSites = (state: StateT) => state.onOffNew.allSites;
export const selectOnOffLastAppliedFilters = (state: StateT) => state.onOffNew.lastAppliedFilters;
export const selectPairsNotFresh = (state: StateT) => state.onOffNew.pairsNotFresh;
export const selectOpenAllSites = (state: StateT) => state.onOffNew.openAllSites;
