import { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch } from "src/store/store";
import { Button } from "src/shadcn/ui/ui/button";
import { TBlock } from "../../types";
import { useSearchParams } from "react-router-dom";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectBlocks, setBlocks } from "src/store/academyReducer";
import { setNotific } from "src/store/mainReducer";
import { useSelector } from "react-redux";

export const EditBlock = ({
  item,
  setIsEdit,
}: {
  item?: TBlock;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const module_id = searchParams.get("module_id");
  const blocks = useSelector(selectBlocks);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(item?.block_name || "");
  const disabled = !name?.length || isLoading;

  const submit = async () => {
    setIsLoading(true);
    const payload: TBlock = {
      module_id: +module_id,
      block_name: name,
      block_order: item?.block_order || blocks?.length,
    };
    if (!item) {
      const { response } = await fetchRequest(academyApi.createBlock(payload), {
        request: "Создание блока",
      });
      if (response) {
        dispatch(setBlocks([...blocks, response]));
        searchParams.set("block_id", response.block_id);
        setSearchParams(searchParams);
      }
    } else {
      const { response } = await fetchRequest(
        academyApi.updateBlock(item.block_id, {
          ...payload,
          block_id: item.block_id,
        }),
        {
          request: "Обновление блока",
        }
      );
      if (response) {
        dispatch(
          setBlocks(
            blocks.map((el) =>
              el.block_id === item.block_id
                ? {
                    ...el,
                    ...response,
                  }
                : el
            )
          )
        );
        dispatch(
          setNotific({
            type: "success",
            message: "Данные успешно обновлены",
            request: "Обновление блока",
          })
        );
      }
    }
    setIsLoading(false);
    setIsEdit(false);
  };

  return (
    <div className="flex flex-col gap-8 items-stretch w-full text-sm">
      <Button
        variant="ghost"
        className="absolute top-3 right-3 h-7 w-7 text-slate-500"
        onClick={() => setIsEdit(false)}>
        {`\u2716`}
      </Button>
      <div className="text-slate-400">Название блока</div>
      <input
        className="border border-input rounded-sm py-8 px-3"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Button className="px-5 mt-8 self-end" disabled={disabled} onClick={submit}>
        Сохранить
      </Button>
    </div>
  );
};
