import { Dispatch, SetStateAction } from "react";

export const Pagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
}: {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
}) => {
  const pagesArr = Array.from(Array(totalPages).keys());
  const start = currentPage > 5 ? currentPage - 5 : 0;
  const end = currentPage < 5 ? 5 + 5 : currentPage + 5;

  return (
    <div className="flex gap-8 justify-between pt-5">
      <button
        type="button"
        className="h-7 rounded-sm border border-input px-3 hover:opacity-60 duration-300"
        onClick={() => {
          if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
          }
        }}>
        <div style={{ position: "relative", transform: "rotate(-90deg)" }}>{`\u25B2`}</div>
      </button>
      {start > 0 && (
        <button
          type="button"
          className="h-7 rounded-sm border border-input px-3 hover:opacity-60 duration-300"
          onClick={() => setCurrentPage(0)}>
          {1}
        </button>
      )}
      <div className="flex items-center gap-8">
        {pagesArr.slice(start, end).map((item) => {
          return (
            <button
              type="button"
              className="h-7 rounded-sm border border-input px-3 hover:opacity-60 duration-300"
              style={{
                backgroundColor: currentPage == item ? "#0ea5e9" : "",
                color: currentPage == item ? "white" : "",
              }}
              key={item}
              value={item}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                setCurrentPage(Number(e.currentTarget.value));
              }}>
              {item + 1}
            </button>
          );
        })}
      </div>
      {totalPages > end && (
        <button
          type="button"
          className="h-7 rounded-sm border border-input px-3 hover:opacity-60 duration-300"
          onClick={() => setCurrentPage(totalPages - 1)}>
          {totalPages}
        </button>
      )}
      <button
        type="button"
        className="h-7 rounded-sm border border-input px-3 hover:opacity-60 duration-300"
        onClick={() => {
          currentPage != totalPages - 1 && setCurrentPage(currentPage + 1);
        }}>
        <div className="relative rotate-90">{`\u25B2`}</div>
      </button>
    </div>
  );
};
