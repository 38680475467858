import { Play } from "lucide-react";

interface VideoPreviewProps {
  url: string;
  openVideo: () => void;
}
export const VideoPreview = ({ url, openVideo }: VideoPreviewProps) => {
  return (
    <div className="flex flex-col border border-grayDark/20 rounded-md w-[70px] aspect-square items-center justify-center overflow-hidden">
      <video className="w-full h-full object-cover" src={url} />
      <button
        type="button"
        className="cursor-pointer absolute text-white left-[50%] border-[2px] border-white rounded-full w-[40px] aspect-square flex items-center justify-center top-[50%] -translate-x-[50%] -translate-y-[50%] hover:bg-[#40CEF0] hover:border-[#40CEF0] duration-300"
        onClick={openVideo}>
        <Play className="absolute top-[50%] left-[56%] -translate-x-[50%] -translate-y-[50%]" />
      </button>
    </div>
  );
};
