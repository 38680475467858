import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/store";
import { ROLE, Url } from "src/api/constants";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";
import {
  selectAttempts,
  selectTests,
  setAttempts,
  setResult,
  setTest,
  setTests,
} from "src/store/academyReducer";
import { ESectionType, ETestStatus, TTest } from "../types";
import { Button } from "src/shadcn/ui/ui/button";
import { Avatar } from "src/components/Viewers/Avatar";
import bin from "src/assets/images/bin.svg";
import { useState } from "react";
import { Popup } from "src/components/Popup/Popup";
import { Loader2 } from "lucide-react";

export const TestCard = ({
  test,
  isCreator,
  isLoadingAttempts,
}: {
  test: TTest;
  isCreator: boolean;
  isLoadingAttempts: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tests = useSelector(selectTests);
  const attempts = useSelector(selectAttempts);
  const [isDeleteConfirmPopup, setIsDeleteConfirmPopup] = useState(false);
  const testAttempts = attempts?.filter((el) => el.course_id === test.course_id);
  const startedAttempt = testAttempts?.find((el) => el.attempt_status === ETestStatus.STARTED);
  const passedAttemptsPractice = testAttempts?.filter(
    (el) => el.attempt_status === ETestStatus.FINISHED
  );
  const bestResultPractice =
    passedAttemptsPractice
      ?.filter((el) => el.module?.module_type === ESectionType.PRACTICE)
      ?.sort((a, b) => (a.attempt_percentage < b.attempt_percentage ? 1 : -1))?.[0] || null;
  const passedAttemptsExam = testAttempts?.filter(
    (el) => el.attempt_status === ETestStatus.FINISHED
  );
  const bestResultExam =
    passedAttemptsExam
      ?.filter((el) => el.module?.module_type === ESectionType.EXAMINATION)
      ?.sort((a, b) => (a.attempt_percentage < b.attempt_percentage ? 1 : -1))?.[0] || null;

  const deleteTest = async () => {
    if (!!test.course_id) {
      const { response } = await fetchRequest(academyApi.deleteTest(test.course_id), {
        request: "Удаление теста",
      });
      if (response) {
        dispatch(setTests(tests?.filter((el) => el.course_id !== test.course_id)));
      }
    } else {
      dispatch(setTests(tests?.filter((el) => el.course_name !== test.course_name)));
    }
    setIsDeleteConfirmPopup(false);
  };

  const finishAttempt = async () => {
    if (!!startedAttempt) {
      const { response } = await fetchRequest(academyApi.finishAttempt(startedAttempt.attempt_id), {
        request: "Завершить",
      });
      if (response) {
        dispatch(setResult(response));
      } else {
        dispatch(
          setAttempts(
            attempts?.map((el) =>
              el.attempt_id === startedAttempt?.attempt_id
                ? {
                    ...el,
                    attempt_status: ETestStatus.FINISHED,
                  }
                : el
            )
          )
        );
      }
    }
  };

  return (
    <div className="relative w-[280px] shadow-md p-5 rounded-md flex flex-col justify-between gap-8 duration-300 hover:scale-105 hover:shadow-lg">
      {isCreator && (
        <Button
          variant="ghost"
          className="absolute top-3 right-3 h-7 w-7"
          onClick={() => setIsDeleteConfirmPopup(true)}>
          <img src={bin} alt="удалить" width={15} height={15} />
        </Button>
      )}
      <div className="flex flex-col gap-8">
        <div className="w-10 h-10 mx-auto mb-5 rounded-full shadow-lg">
          <Avatar username={test.course_name} />
        </div>
        <p className="text-xl font-semibold text-center">{test.course_name}</p>
        <small className="text-slate-500 pb-5">{test.course_description}</small>
        <div className="flex flex-col pb-5">
          {test.perms
            ?.filter((el) => el.user_role !== ROLE.ADMIN && el.user_role !== ROLE.MINI_ADMIN)
            ?.map((el) => (
              <small
                key={el.user_role}
                className={`${el.admin_rights ? "text-blue" : "text-slate-500"}`}>
                {el.user_role}
                {el.admin_rights && ` ©`}
              </small>
            ))}
        </div>
      </div>
      <div className="flex flex-col gap-8">
        {!!bestResultPractice && (
          <div
            className={`flex flex-col rounded-md border px-8 py-2 ${
              bestResultPractice.attempt_successful
                ? "border-sky-300 bg-sky-50 text-sky-500"
                : "border-slate-300 bg-slate-50 text-slate-400"
            }`}>
            <div className="flex items-center justify-between text-slate-500">
              <small className="font-extralight">Практика</small>
              <small>{bestResultPractice.module?.module_name}</small>
            </div>
            <div className="flex items-center justify-between">
              <small className="font-extralight">Ваш лучший результат</small>
              <small>{bestResultPractice.attempt_percentage}%</small>
            </div>
          </div>
        )}
        {!!bestResultExam && (
          <div
            className={`flex flex-col rounded-md border px-8 py-2 ${
              bestResultExam.attempt_successful
                ? "border-emerald-300 bg-emerald-50 text-emerald-500"
                : "border-slate-300 bg-slate-50 text-slate-400"
            }`}>
            <div className="flex items-center justify-between text-slate-500">
              <small className="font-extralight">Аттестация</small>
              <small>{bestResultExam.module?.module_name}</small>
            </div>
            <div className="flex items-center justify-between">
              <small className="font-extralight">Ваш лучший результат</small>
              <small>{bestResultExam.attempt_percentage}%</small>
            </div>
          </div>
        )}
        {!isLoadingAttempts ? (
          <>
            <Button
              variant={
                !!passedAttemptsPractice?.length || !!passedAttemptsExam?.length
                  ? "secondary"
                  : "default"
              }
              className={`${
                !!startedAttempt ? "bg-emerald-500 hover:bg-emerald-400 text-white" : ""
              }`}
              onClick={() => {
                dispatch(setTest(test));
                navigate(
                  Url.ACADEMY_SOLVE +
                    `?id=${test.course_id}` +
                    (!!startedAttempt ? `&module_id=${startedAttempt.module?.module_id}` : "")
                );
              }}>
              {!!startedAttempt
                ? "Продолжить"
                : !!passedAttemptsPractice?.length || !!passedAttemptsExam?.length
                ? "Пройти еще раз"
                : "Пройти"}
            </Button>
            {!!startedAttempt && (
              <Button variant="secondary" className="hover:opacity-70" onClick={finishAttempt}>
                Завершить попытку
              </Button>
            )}
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center py-8">
            <Loader2 className="animate-spin w-[24px] h-[24px] text-[#40CEF0]" />
          </div>
        )}
        {isCreator && (
          <Button
            variant="outline"
            className="min-h-[40px]"
            onClick={() => {
              dispatch(setTest(test));
              navigate(Url.ACADEMY_EDIT + `?id=${test.course_id}`);
            }}>
            Редактировать
          </Button>
        )}
      </div>
      {isDeleteConfirmPopup && (
        <Popup closeModal={() => setIsDeleteConfirmPopup(false)}>
          <div className="flex flex-col gap-7 p-3">
            <div>
              Вы уверены что хотите <b>удалить</b> тест?
            </div>
            <Button className="bg-rose-500" onClick={deleteTest}>
              Удалить тест
            </Button>
          </div>
        </Popup>
      )}
    </div>
  );
};
