import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { LS, Url } from "../../../api/constants";
import authApi from "../../../api/auth";
import { selectIsLogged, setLogin } from "../../../store/mainReducer";
import { logout } from "../../../helpers/authHelper";
import Input from "../../../components/Input";
import { FormProvider, useForm } from "react-hook-form";
import { fetchRequest } from "../../../helpers/fetchRequest";

const AuthForm = () => {
  const isLogged = useSelector(selectIsLogged);
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const [error, setError] = useState("");

  const logoutFn = async () => {
    await logout();
  };

  useEffect(() => {
    if (isLogged) {
      logoutFn();
    }
  }, []);

  const onSubmit = async (e) => {
    setError("");
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId;
    const body = {
      ...e,
      fingerprint,
    };

    const { response } = await fetchRequest(authApi.login(body));
    if (response) {
      dispatch(setLogin());
      localStorage.setItem(LS.USERNAME, body.username);
      localStorage.setItem(LS.ACCESS, response.user_access_node.join(", "));
      localStorage.setItem(LS.ROLE, response?.user_role_name);
      return navigate(Url.DEFAULT, { replace: true });
    } else {
      setError("Что-то пошло не так");
    }
  };

  useEffect(() => {
    if (isLogged && user_access) {
      navigate(Url.DEFAULT, { replace: true });
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        className="max-w-[410px] p-6 mx-auto bg-white rounded-xl shadow-lg items-center space-x-4 my-6"
        onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="username"
          name="username"
          label="Username"
          type="text"
          validation={{ required: true }}
        />
        {errors.username && errors.username.type === "required" && (
          <span className="text-red">Не заполнено обязательное поле</span>
        )}
        <Input
          placeholder="min 8 characters"
          name="password"
          label="Password"
          type="password"
          validation={{ required: true, minLength: 6 }}
        />
        {errors.password && errors.password.type === "required" && (
          <span className="text-red">Не заполнено обязательное поле</span>
        )}
        {errors.password && errors.password.type === "minLength" && (
          <span className="text-red">Минимальное количество символов 7</span>
        )}
        <Input
          placeholder="verify code"
          name="verify_code"
          label="Verify code"
          type="text"
          validation={{ required: true }}
        />
        {errors.verify_code && errors.verify_code.type === "required" && (
          <span className="text-red">Не заполнено обязательное поле</span>
        )}
        <button className={`prime-button mt-[20px]`}>Войти</button>
        {error && <p className="text-red my-12">{error}</p>}
      </form>
    </FormProvider>
  );
};
export default AuthForm;
