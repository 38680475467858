import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchRequest } from "src/helpers/fetchRequest";
import textApi from "src/api/text";
import { LS } from "src/api/constants";
import { TGlobalCity, TSelect, TStrSelect } from "src/store/directionsReducer";
import { setNotific } from "src/store/mainReducer";
import {
  TCitySelect,
  TDirectionSelect,
  TPreparedVector,
  TTextCities,
  TTextDirectionGet,
  TTextLang,
  TTextNotDirections,
  TTextSubType,
  TTextSubTypeList,
  TTextType,
  TTextVectors,
  TVectorType,
} from "../../types";
import { OptionLabel } from "src/components/FormElements/CustomOption";
import { CurrencyTypes } from "./CurrencyTypes";
import { Languages } from "./Languages";
import { TextFilters } from "./TextFilters";
import { Problems } from "./Problems";
import { ExistTexts } from "./ExistTexts/ExistTexts";
import lang_icon from "src/assets/images/language.svg";

type TProps = {
  directionsData: TTextDirectionGet | null;
  setDirectionsData: Dispatch<SetStateAction<TTextDirectionGet | null>>;
  existSubtypeTexts: TTextNotDirections | null;
  setExistSubtypeTexts: Dispatch<SetStateAction<TTextNotDirections | null>>;
  globalCities: TGlobalCity[];
  site: TSelect | null;
  city: TCitySelect | null;
  setCity: Dispatch<SetStateAction<TCitySelect> | null>;
  setCurrentCity: Dispatch<SetStateAction<TCitySelect> | null>;
  setVector: Dispatch<SetStateAction<TDirectionSelect | null>>;
  selectedVectors: Array<TDirectionSelect>;
  setSelectedVectors: Dispatch<SetStateAction<Array<TDirectionSelect>>>;
  currencyType: TVectorType;
  setCurrencyType: Dispatch<SetStateAction<TVectorType>>;
  types: Array<TTextType>;
  subTypes: TTextSubTypeList[];
  setType: Dispatch<SetStateAction<TTextType>>;
  setSubType: Dispatch<SetStateAction<TTextSubType | null>>;
  lang: TStrSelect | null;
  setLang: Dispatch<SetStateAction<TStrSelect | null>>;
  isCreate: boolean;
  setIsCreate: Dispatch<SetStateAction<boolean>>;
  isTemplate: boolean;
  isNotNeededVectors: boolean;
  clearTemplate: () => void;
};

export const SideBlock = ({
  directionsData,
  setDirectionsData,
  existSubtypeTexts,
  setExistSubtypeTexts,
  globalCities,
  site,
  city,
  setCity,
  setCurrentCity,
  setVector,
  selectedVectors,
  setSelectedVectors,
  currencyType,
  setCurrencyType,
  types,
  subTypes,
  setType,
  setSubType,
  lang,
  setLang,
  isCreate,
  setIsCreate,
  isTemplate,
  isNotNeededVectors,
  clearTemplate,
}: TProps) => {
  const userAccess = localStorage.getItem(LS.ACCESS);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [isLang, setIsLang] = useState(false);
  const [isGlobals, setIsGlobals] = useState(false);
  const [languages, setLanguages] = useState<Array<string>>([]);
  const isCashDirection =
    currencyType?.currency_from_type === "cash" || currencyType?.currency_to_type === "cash";

  const preparedVectors = useMemo(
    () =>
      directionsData?.cities?.map((cityEl: TTextCities) =>
        cityEl.vectors?.map((vectorEl) => ({
          ...vectorEl,
          city_id: cityEl.city_id,
          city_code: cityEl.city_code,
          city_name: cityEl.city_name,
        }))
      ) || ([] as Array<Array<TPreparedVector>>),
    [directionsData]
  );

  const citiesArr = useMemo(
    () => directionsData?.cities?.map((el: TTextCities) => el.vectors),
    [directionsData]
  );

  const vectorsArr = useMemo(() => {
    if (!!citiesArr?.length) {
      return citiesArr?.reduce((a, b) => a?.concat(b))?.map((el: TTextVectors) => el.languages);
    } else {
      return [];
    }
  }, [citiesArr]);

  const langArr = useMemo(() => {
    if (!!vectorsArr?.length) {
      return vectorsArr
        ?.reduce((a, b) => a?.concat(b))
        ?.map((el: TTextLang) => el.lang) as Array<string>;
    } else {
      return [];
    }
  }, [vectorsArr]);

  const existLanguages = useMemo(() => [...new Set(langArr)], [langArr]);

  const citiesOptions = useMemo(
    () =>
      [...new Set(directionsData?.cities?.map((el) => el.city_id))]
        ?.map((el: number) => directionsData?.cities?.find((cityEl) => cityEl.city_id === el))
        ?.map((el: TTextCities) => {
          const isNotOnSite =
            !el.vectors?.length ||
            !el.vectors?.find((vectorEl: TTextVectors) => !!vectorEl?.is_on_site);
          const isNoTexts =
            !el.vectors?.length ||
            !!el.vectors?.find(
              (vectorEl: TTextVectors) =>
                !vectorEl?.languages ||
                !!vectorEl?.languages?.map((langEl: TTextLang) => !langEl.types?.length)
            );
          return {
            label: OptionLabel({
              label: el.city_name,
              isFirstError: isNotOnSite,
              textFirstError: "Нет включенных векторов на сайте",
              firstColor: "inherit",
              isSecondError: isNoTexts,
              textSecondError: "Есть векторы с незаполненными текстами",
              secondColor: "#D7443E",
            }),
            title: el?.city_name,
            value: el.city_id,
            isNotOnSite: isNotOnSite,
            isNoTexts: isNoTexts,
          };
        })
        ?.sort((a, b) => (a.label > b.label ? 1 : -1)) as Array<TCitySelect>,
    [directionsData]
  );

  const globalCitiesOptions = useMemo(
    () =>
      globalCities?.map((el) => {
        const resCity = citiesOptions?.find((cityEl: TCitySelect) => cityEl.title === el.city_name);
        return !!resCity
          ? resCity
          : {
              label: OptionLabel({
                label: el.city_name,
                isFirstError: true,
                textFirstError: "Нет включенных векторов на сайте",
                firstColor: "inherit",
                isSecondError: true,
                textSecondError: "Есть векторы с незаполненными текстами",
                secondColor: "#D7443E",
              }),
              title: el?.city_name,
              value: el.city_id,
              isNotOnSite: true,
              isNoTexts: true,
            };
      }),
    [globalCities, citiesOptions]
  );

  const fetchLang = async () => {
    const { response, error } = await fetchRequest(textApi.getLang(), { request: "Языки" });
    if (response) {
      setLanguages(response?.map((el) => el.name));
    }
    if (error) {
      setLanguages([]);
    }
  };

  const fetchDirections = async (site_id) => {
    const { response, error, status } = await fetchRequest(textApi.getDirections(site_id));
    if (response) {
      setDirectionsData(response);
    }
    if (error && status !== 404) {
      dispatch(setNotific({ type: "error", message: error?.statusText }));
      setDirectionsData(null);
    }
    if (status === 404) {
      setDirectionsData(null);
    }
  };

  const fetchNotDirectionsData = async (site_id) => {
    const { response, error, status } = await fetchRequest(textApi.getNotDirectionsTexts(site_id));
    if (response) {
      setExistSubtypeTexts(response);
    }
    if (error && status !== 404) {
      dispatch(
        setNotific({
          type: "error",
          message: error?.statusText,
          request: "Наличие текстов по подтипам",
        })
      );
      setExistSubtypeTexts(null);
    }
    if (status === 404) {
      setExistSubtypeTexts(null);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchLang();
    })();
  }, []);

  useEffect(() => {
    if (!!site?.label) {
      fetchDirections(site?.value);
      fetchNotDirectionsData(site?.value);
    }
  }, [site?.label]);

  useEffect(() => {
    if (!!citiesOptions?.length) {
      if (!!searchParams?.get("lang")) {
        const language = searchParams?.get("lang") as string;
        setLang({ label: language, value: language });
      }
      if (!!searchParams?.get("city")) {
        if (!isCashDirection) {
          const autoCity = {
            label: OptionLabel({
              label: "Автообмены",
              isFirstError: false,
              textFirstError: "Нет включенных векторов на сайте",
              firstColor: "inherit",
              isSecondError: false,
              textSecondError: "Есть векторы с незаполненными текстами",
              secondColor: "#D7443E",
            }),
            title: "Автообмены",
            value: 0,
            isNotOnSite: false,
            isNoTexts: false,
          };
          setCity(autoCity);
        } else {
          setCity(citiesOptions?.find((el) => el.value === +searchParams.get("city")));
        }
      }
      if (!!searchParams?.get("cur_city")) {
        setCurrentCity(citiesOptions?.find((el) => el.value === +searchParams.get("cur_city")));
      }
    }
  }, [citiesOptions]);

  return (
    <div className={`flex flex-col gap-[22px] md:max-w-[300px]`}>
      <div className="flex justify-between gap-4 -mt-2 -mb-8">
        <div className="flex gap-4">
          <button
            type="button"
            className={`bg-light px-[16px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
              isGlobals ? "opacity-30" : ""
            }`}
            onClick={() => setIsGlobals(false)}>
            Векторы сайта
          </button>
          <button
            type="button"
            className={`bg-light px-[16px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
              !isGlobals ? "opacity-30" : ""
            }`}
            onClick={() => setIsGlobals(true)}>
            Все векторы
          </button>
        </div>
        {userAccess?.includes("on-off_post") && (
          <button
            type="button"
            className={`flex justify-center items-center bg-select border border-lightGray rounded-sm px-2 py-px duration-300 hover:opacity-70`}
            onClick={() => setIsLang(!isLang)}>
            <img src={lang_icon} alt="язык" width={24} height={24} className="opacity-90" />
          </button>
        )}
      </div>
      {userAccess?.includes("on-off_post") && (
        <Languages isLang={isLang} languages={languages} setLanguages={setLanguages} />
      )}
      {!!directionsData?.cities?.length || !!existSubtypeTexts ? (
        <>
          <Problems
            directionsData={directionsData}
            preparedVectors={preparedVectors}
            existLanguages={existLanguages}
            types={types}
            subTypes={subTypes}
            setType={setType}
            setSubType={setSubType}
            setIsCreate={setIsCreate}
            setCity={setCity}
            setSelectedVectors={setSelectedVectors}
            setLang={setLang}
            setCurrencyType={setCurrencyType}
          />
          <ExistTexts
            directionsData={directionsData}
            existSubtypeTexts={existSubtypeTexts}
            types={types}
            subTypes={subTypes}
            setType={setType}
            setSubType={setSubType}
            setLang={setLang}
            setIsCreate={setIsCreate}
            setCurrentCity={setCurrentCity}
            setVector={setVector}
          />
        </>
      ) : (
        <div className="rounded-sm bg-select px-8 py-4 -my-8">
          Не найдены включенные на сайте города или добавленные тексты
        </div>
      )}
      <CurrencyTypes
        currencyType={currencyType}
        setCurrencyType={setCurrencyType}
        setVector={setVector}
        setSelectedVectors={setSelectedVectors}
      />
      <TextFilters
        title="Создать для"
        citiesOptions={isGlobals ? globalCitiesOptions : citiesOptions}
        directionsData={directionsData}
        currencyType={currencyType}
        isGlobals={isGlobals}
        city={city}
        setCity={setCity}
        selectedVectors={selectedVectors}
        setSelectedVectors={setSelectedVectors}
        isVisible={isCreate && !!site?.value && !isTemplate}
        lang={lang}
        setLang={setLang}
        languages={languages}
        existLanguages={existLanguages}
        isNotNeededVectors={isNotNeededVectors}
        setIsVisible={() => {
          setIsCreate(true);
          clearTemplate();
        }}
      />
    </div>
  );
};
