import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { colors } from "src/helpers/colors";
import { convertToShortDate } from "src/helpers/date";
import { Popup } from "src/components/Popup/Popup";
import split_right from "src/assets/images/splitRight.svg";

export const TemplateCard = ({
  item,
  setActiveTemplate,
  openTemplates,
  addDate,
  updateQueue,
  deleteTemplate,
  setTemplateData,
  isLoading,
  isSplitRight,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDeleteConfirm, setDeleteConfirm] = useState(false);

  return (
    <div className="relative flex flex-col gap-4 text-[10px] bg-[#FDFDFFAA] rounded-md p-8 -m-8 h-fit">
      {!!addDate?.length && (
        <button
          type="button"
          className={`absolute flex justify-center items-center w-[100px] h-[60px] rounded-md bg-[#DADAE3AA] z-10 text-[24px] font-medium text-white hover:opacity-50 disabled:bg-transparent`}
          disabled={isLoading}
          onClick={() => {
            updateQueue({
              id: item?.id,
              dates_to_create: [addDate],
              dates_to_delete: [],
            });
          }}>
          +
        </button>
      )}
      {isSplitRight && (
        <button
          type="button"
          className={`absolute flex justify-center items-center w-[100px] h-[60px] rounded-md bg-[#B2FFDE33] z-10 text-[24px] pt-4 font-medium text-white hover:opacity-50 disabled:bg-transparent`}
          disabled={isLoading}
          onClick={() => {
            if (item.id === -1) {
              setTemplateData([]);
            }
            setActiveTemplate(item.id);
            searchParams.set("right-template", item.id);
            setSearchParams(searchParams);
            openTemplates(false);
          }}>
          <img
            src={split_right}
            alt="открыть справа"
            width={16}
            height={16}
            className="opacity-50"
          />
        </button>
      )}
      <div
        style={{
          backgroundColor: item?.id >= 0 ? `${colors[item?.id % 30]}33` : "#BABAC333",
          color: item?.id >= 0 ? "inherit" : "#FFF",
          fontSize: item?.title?.length > 20 ? 10 : item?.id >= 0 ? 12 : 24,
          fontWeight: item?.id >= 0 ? 300 : 500,
        }}
        className={`relative w-[100px] h-[60px] rounded-md flex justify-center items-center text-xs hover:opacity-70 p-4`}
        onMouseMove={() => !addDate?.length && setIsDeleteVisible(true)}
        onMouseLeave={() => setIsDeleteVisible(false)}>
        <button
          type="button"
          className={`leading-4 disabled:bg-transparent`}
          disabled={isLoading}
          onClick={() => {
            if (item.id === -1) {
              setTemplateData([]);
            }
            setActiveTemplate(item.id);
            searchParams.set("template", item.id);
            setSearchParams(searchParams);
            openTemplates(false);
          }}>
          {!!item?.title ? item?.title : "+"}
        </button>
        {isDeleteVisible && item?.id >= 0 && (
          <button
            type="button"
            disabled={isLoading}
            className={`absolute -top-4 -right-4 flex justify-center items-center h-[14px] w-[14px] rounded-full shadow-base text-[8px] bg-bg text-lightFont disabled:bg-transparent`}
            onClick={() => setDeleteConfirm(true)}>
            {`\u2716`}
          </button>
        )}
      </div>
      {("creator" in item || "updater" in item) && (
        <div className="font-semibold text-right leading-3 px-4 text-lightFont">
          {"creator" in item && (
            <>
              <div className="text-left font-light">Создан</div>
              <div>{item?.creator}</div>
              <div>{convertToShortDate(+new Date(item?.created_at) / 1000)}</div>
            </>
          )}
          {"updater" in item && (
            <>
              <div className="text-left font-light pt-4">Обновлен</div>
              <div>{item?.updater}</div>
              <div>{convertToShortDate(+new Date(item?.last_update) / 1000)}</div>
            </>
          )}
        </div>
      )}
      {isDeleteConfirm && (
        <Popup closeModal={() => setDeleteConfirm(false)}>
          <div className="flex flex-col items-center gap-[20px] text-xs text-center whitespace-pre">
            <div>{`Вы уверены, что хотите удалить\n${item?.title}?`}</div>
            <button
              type="button"
              className={`w-fit mx-auto prime-button-sm bg-font rounded-sm disabled:bg-transparent`}
              disabled={isLoading}
              onClick={async () => {
                await deleteTemplate(item?.id);
                setDeleteConfirm(false);
              }}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
