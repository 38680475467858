import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRedactors } from "../pages/Bid/models";
import { StateT } from "./store";
import { TCurrencyTypes } from "../pages/Text/types";

const directionsSlice = createSlice({
  name: "directions",
  initialState: {
    allCurrencies: [] as Array<TGlobalCurrency>,
    globalCurrencies: [] as Array<TGlobalCurrency>,
    allDirections: [] as Array<TGlobalDirection>,
    globalDirections: [] as Array<TGlobalDirection>,
    allSites: [] as Array<TGlobalSite>,
    allCities: [] as Array<TGlobalCity>,
    allCountries: [] as Array<TGlobalCountry>,
    allRoles: [] as Array<string>,
    site: null as TSite | null,
    sites: [] as TSite[],
    city: null as TCity | null,
    curFrom: null as null | string,
    curTo: null as null | string,
    minMax: [] as Array<TMinMaxCities>,
    minMaxSheet: [] as Array<TMinMaxSheet>,
    fetchCurrentData: {} as TFetchCurrentData,
    redactors: [] as Array<TDRedactor>,
    mixerSites: [] as Array<TMixerSite>,
    mixerRanges: [] as Array<TMixerRanges>,
    currentSiteEnabled: false,
    expansion: [] as Array<TMixerCluster>,
    siteExpansion: [] as Array<TMixerCluster>,
  },
  reducers: {
    setAllDirections: (state, action) => {
      state.allDirections = action.payload;
    },
    setAllCurrencies: (state, action) => {
      state.allCurrencies = action.payload;
    },
    setGlobalDirections: (state, action) => {
      state.globalDirections = action.payload;
    },
    setGlobalCurrencies: (state, action) => {
      state.globalCurrencies = action.payload;
    },
    setAllSites: (state, action) => {
      state.allSites = action.payload;
    },
    setAllCities: (state, action) => {
      state.allCities = action.payload;
    },
    setCountries: (state, action) => {
      state.allCountries = action.payload;
    },
    setAllRoles: (state, action) => {
      state.allRoles = action.payload;
    },
    setSelectedSite: (state, action) => {
      state.site = action.payload;
    },
    toggleSiteSelected: (state, { payload }: PayloadAction<TSite>) => {
      const existingSiteIndex = state.sites.findIndex((item) => item.site_id === payload.site_id);

      if (~existingSiteIndex) {
        state.sites.splice(existingSiteIndex, 1);
      } else {
        state.sites.push(payload);
      }
    },
    setSelectedSites: (state, { payload }: PayloadAction<TSite[]>) => {
      state.sites = payload;
    },
    toggleSiteIsOff: (state, { payload }: PayloadAction<string>) => {
      state.allSites = state.allSites.map((site) =>
        site.site_name === payload ? { ...site, isOff: !site.isOff } : site
      );
    },
    setSelectedCity: (state, action) => {
      state.city = action.payload;
    },
    setSelectedFrom: (state, action) => {
      state.curFrom = action.payload;
    },
    setSelectedTo: (state, action) => {
      state.curTo = action.payload;
    },
    setFetchCurrentData: (state, action) => {
      state.fetchCurrentData = action.payload;
    },
    setMinMax: (state, action) => {
      state.minMax = action.payload;
    },
    setMinMaxSheet: (state, action) => {
      state.minMaxSheet = action.payload;
    },
    setRedactor: (state, { payload }) => {
      state.redactors = [
        ...state.redactors,
        { vector: payload.vector, site: payload.site, user: payload.user },
      ];
    },
    deleteRedactor: (state, { payload }) => {
      state.redactors = state.redactors?.filter(
        (el) => el.vector !== payload.vector && el.site !== payload.site
      );
    },
    setMixerSites: (state, action) => {
      state.mixerSites = action.payload;
    },
    updateMixerSites: (state, action) => {
      state.mixerSites = state.mixerSites?.map((el) =>
        el.exchanger_id === action.payload.exchanger_id
          ? { ...el, clusters: action.payload.clusters, enabled: action.payload.enabled }
          : el
      );
    },
    setMixerRanges: (state, action) => {
      state.mixerRanges = action.payload;
    },
    setCurrentSiteEnabled: (state, action) => {
      state.currentSiteEnabled = action.payload;
    },
    setClusterExpansion: (state, action) => {
      state.expansion = action.payload;
    },
    setSiteExpansion: (state, action) => {
      state.siteExpansion = action.payload;
    },
  },
});

export const {
  setAllCurrencies,
  setAllDirections,
  setGlobalCurrencies,
  setGlobalDirections,
  setAllSites,
  setAllCities,
  setCountries,
  setAllRoles,
  setSelectedSite,
  setSelectedSites,
  toggleSiteSelected,
  toggleSiteIsOff,
  setSelectedCity,
  setSelectedFrom,
  setSelectedTo,
  setFetchCurrentData,
  setMinMax,
  setMinMaxSheet,
  setRedactor,
  deleteRedactor,
  setMixerSites,
  updateMixerSites,
  setMixerRanges,
  setCurrentSiteEnabled,
  setClusterExpansion,
  setSiteExpansion,
} = directionsSlice.actions;

export const selectAllCurrencies = (state: StateT) => state.directions.allCurrencies;
export const selectAllDirections = (state: StateT) => state.directions.allDirections;
export const selectGlobalCurrencies = (state: StateT) => state.directions.globalCurrencies;
export const selectGlobalDirections = (state: StateT) => state.directions.globalDirections;
export const selectAllSites = (state: StateT): Array<TGlobalSite> => state.directions.allSites;
export const selectAllCities = (state: StateT) => state.directions.allCities;
export const selectAllCountries = (state: StateT) => state.directions.allCountries;
export const selectAllRoles = (state: StateT): string[] => state.directions.allRoles;
export const selectSite = (state: StateT): TSite | null => state.directions.site;
export const selectDirectionSites = (state: StateT): TSite[] => state.directions.sites;
export const selectCity = (state: StateT): TCity | null => state.directions.city;
export const selectCurFrom = (state: StateT): string | null => state.directions.curFrom;
export const selectCurTo = (state: StateT): string | null => state.directions.curTo;
export const selectFetchCurrentData = (state: StateT) => state.directions.fetchCurrentData;
export const selectMinMax = (state: StateT) => state.directions.minMax;
export const selectMinMaxSheet = (state: StateT): Array<TMinMaxSheet> =>
  state.directions.minMaxSheet;
export const selectDirectionRedactors = (state: StateT): Array<IRedactors> =>
  state.directions.redactors;
export const selectMixerSites = (state: StateT): Array<TMixerSite> => state.directions.mixerSites;
export const selectMixerRanges = (state: StateT): Array<TMixerRanges> =>
  state.directions.mixerRanges;
export const selectCurrentSiteEnabled = (state: StateT): boolean =>
  state.directions.currentSiteEnabled;
export const selectClusterExpansion = (state: StateT): Array<TMixerCluster> =>
  state.directions.expansion;
export const selectSiteExpansion = (state: StateT): Array<TMixerCluster> =>
  state.directions.siteExpansion;

export default directionsSlice.reducer;

export type TSelect = {
  label: string;
  value: number;
};
export type TStrSelect = {
  label: string;
  value: string;
};
export type TCurSelect = {
  label: string;
  value: {
    id: number;
    isCrypto: boolean;
  };
};

export type TGlobalDirection = {
  currency_from: string;
  currency_to: string;
  crypto_first: boolean;
};
export type TBaseCurrency = {
  currency_id: number;
  currency_name: string;
};
export type TGlobalCurrency = {
  id: number;
  currency_id: number;
  currency_name: string;
  is_crypto: boolean;
  type: TCurrencyTypes;
};
export type TGlobalSite = {
  id: number;
  site_id: number;
  best_id: number;
  site_name: string;
  is_changable: boolean;
  is_on_best: boolean;
  isOff?: boolean;
};
export type TSite = {
  site_id: number;
  site_name: string;
  isOff?: boolean;
};
export type TBestSite = {
  best_id: number;
  site_name: string;
  is_our: boolean;
  is_on_best_now: boolean;
};
export type TCity = {
  city_id: number;
  city_name: string;
};
export type TGlobalCity = {
  id: number;
  city_id: number;
  city_name: string;
  city_code: string;
  country: string;
  is_main: boolean;
  is_region: boolean;
};
export type TGlobalCountry = {
  name_ru?: string;
  name_en?: string;
  can_delete?: boolean;
  country: string;
};
export type TGlobalAutoCity = {
  vector: TGlobalDirection;
  site_id: number;
  site_name: string;
  city_id: number;
  city_name: string;
  city_code: string;
  course_get: number;
  course_give: number;
  is_region: boolean;
  is_site_on_bestchange: boolean;
  max_sum: number;
  min_sum: number;
  step: number;
  is_enabled: boolean;
  percent: number;
};
export type TFetchCurrentData = {
  site: TSite;
  vector: {
    currency_from: string;
    currency_to: string;
    crypto_first: boolean;
  };
};
export type TCurrent = {
  auto_adjustment: {
    is_enabled: boolean;
    percentage_get: number;
    percentage_give: number;
  };
  city_code: string;
  city_id: number;
  city_name: string;
  course_get: number;
  course_give: number;
  is_region: boolean;
  is_site_on_bestchange: boolean;
  max_sum: number;
  min_sum: number;
  step: number;
};
export type TAutocorr = {
  city_id: number;
  is_autocorr: boolean;
  percent: number;
};
export type TFetchAutoData = {
  site_id: number;
  vector: {
    currency_from: string;
    currency_to: string;
    crypto_first: boolean;
  };
};
export type TDRedactor = {
  vector: string;
  site: string;
  user: string;
};
export type TMinMaxCities = {
  city_name: string;
  city_code: string;
  sector: string | null;
  min_sum: number;
  max_sum: number;
  is_region: boolean;
};
export type TMinMaxException = {
  city_id: number;
  exception_id: number;
  min_summ_office: string;
  max_summ_office: string;
  min_summ_region: string;
  max_summ_region: string;
  reserve_office: string;
  reserve_region: string;
};
export type TMinMaxCurrency = {
  sheet_id: number;
  currency_id: number;
  currency_name: string;
  min_summ_office: string;
  max_summ_office: string;
  min_summ_region: string;
  max_summ_region: string;
  reserve_office: string;
  reserve_region: string;
  fix_course_count: string;
  is_exceptions: boolean;
  exception_block?: Array<TMinMaxException>;
};
export type TMinMaxSheet = {
  region_id: number;
  region_name: string;
  site_id: number;
  site_name: string;
  is_on_best: boolean;
  is_on_exnode: boolean;
  work_time_start: string; // HH:MM
  work_time_end: string; // HH:MM
  site_text_url: string;
  currencies: Array<TMinMaxCurrency>;
};
export type TMinMaxRegion = {
  region_id: number;
  region_name: string;
  currencies: Array<TBaseCurrency>;
};
export type TCredo = {
  name: string;
  username: string;
  password: string;
  port: number;
  host: string;
  prefix: string;
  driver: string;
  create_database: boolean;
};

export type TMixerSite = {
  exchanger_id: number;
  exchanger_name: string;
  enabled: boolean;
  availability: 0 | 50 | 100;
  clusters: TMixerCluster[];
};

export type TMixerCluster = {
  id: number;
  percent: number;
};
export type TMixerRanges = {
  id: number;
  from: number;
  to: number;
};
export type TEntropy = {
  common_cities: number;
  common_directions: number;
  best_common_directions: number;
  best_common_courses: number;
  best_top_three: number;
  overall: number;
};
export type TLastAvailable = {
  last_update: string; // 2023-06-30T19:57
  exchanger_name: string;
  is_our: boolean;
};
export type TAlienOptions = "все" | "наши" | "не наши";
export type TLinks = "site" | "city" | "from_" | "to";
export type TGraphFilter = {
  sites: Array<TSelect>;
  city: Array<TSelect>;
  from: Array<TSelect>;
  to: Array<TSelect>;
  time_from: string;
  time_to: string;
  links: Array<TLinks>;
  level: Array<TLinks>;
  direction: "LR" | "UD";
  alien: TAlienOptions;
  is_on_best_now: boolean;
  type: "site" | "city" | "";
};

export type TGraphLevels = "site" | "city" | "from_" | "to";

export type TGraphPeriod = {
  time_from: string; // "2023-08-30T03:36"
  time_to: string; // "2023-08-30T12:36"
};

export type TNode = {
  best_link: string | null;
  id: string;
  label: string;
};

export type TEdge = {
  from_: string;
  to: string;
  label?: string;
};

export type TGraphData = {
  nodes: TNode[];
  edges: TEdge[];
};

export type TCurrencyTypesFilter = {
  from: TCurrencyTypes[];
  to: TCurrencyTypes[];
};
