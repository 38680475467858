import { useEffect, useState } from "react";

export default function Copied({
  value,
  copiedValue,
  title,
  color,
}: {
  value: string;
  copiedValue?: string;
  title: string;
  color?: string;
}) {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [isCopied]);

  return (
    <button
      type="button"
      className="relative text-left"
      style={{
        color: !!color ? color : "white",
      }}
      onClick={async () => {
        setCopied(true);
        await navigator.clipboard.writeText(!!copiedValue ? copiedValue : value);
      }}>
      <div
        className="absolute -top-7 -right-5 rounded-lg h-fit bg-font text-xs p-8"
        style={{
          opacity: isCopied ? "1" : "0",
        }}>
        {title}
      </div>
      {`${value}`}
    </button>
  );
}
