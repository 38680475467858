import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import summaryApi from "src/api/summary";
import { TSummarySite } from "src/store/commonReducer";
import { selectSite } from "src/store/directionsReducer";
import { Site } from "./components/Site";

export const Summary = () => {
  const selectedSite = useSelector(selectSite);
  const [data, setData] = useState<Array<TSummarySite>>([]);

  const fetchSites = async () => {
    const { response } = await fetchRequest(summaryApi.getSites(), {
      request: "Сайты",
    });
    if (response) {
      setData(response);
    }
  };

  useEffect(() => {
    fetchSites();
  }, []);

  return (
    <div className="w-full sm:w-[600px] duration-300 flex flex-col gap-[10px] font-light pl-[20px] pr-[50px] md:pr-[80px] pb-[100px] mx-auto">
      {data?.map((el) => (
        <Site key={el.id} item={el} selectedSite={selectedSite} />
      ))}
    </div>
  );
};
