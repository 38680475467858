import { SVG } from "src/assets/images/SVG";
import { cn } from "src/helpers/cn";
import { TGlobalCurrency } from "src/store/directionsReducer";

interface CurrencyLabelProps {
  currency: TGlobalCurrency;
  undo?: () => void;
  undoable?: boolean;
  undoClassname?: string;
}

const CurrencyLabel = ({ currency, undo, undoable, undoClassname }: CurrencyLabelProps) => {
  return (
    <div className="flex items-center gap-[4px]">
      <span>{currency.currency_name}</span>
      <SVG id={currency.currency_name} />
      {undoable && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            undo?.();
          }}
          className={cn(
            "hover:bg-gray/10 text-sm rounded-sm transition px-[6px] aspect-square flex items-center justify-center cursor-pointer",
            undoClassname
          )}>
          <span className="mt-[8px]">{`\u2936`}</span>
        </button>
      )}
    </div>
  );
};

export default CurrencyLabel;
