import { cutter } from "src/helpers/currencyHelper";
import { TFlight } from "src/store/statsReducer";

export const FlightCard = ({ item }: { item: TFlight }) => {
  return (
    <div
      className={`relative left-2 text-[0.8rem] flex flex-wrap flex-col justify-center w-[225px] max-w-[225px] rounded-md`}>
      <div className="table shadow-sm w-[225px] ml-[5px] mt-[0]">
        <div className="font-light justify-between flex flex-nowrap items-center my-[10px] mx-[15px]">
          <div className="text-[8px] text-left w-full">
            <div className="text-xs font-bold">{item.site_name}</div>
            <div className="grid grid-cols-2 font-bold">
              <div>{`${cutter(item.currency_from)}-${cutter(item.currency_to)}`}</div>
              <div>{item.city_name}</div>
            </div>
            <div>курс: {item.course}</div>
            <div>лимит: {item.limit}</div>
          </div>
          <div>{item.diff}%</div>
        </div>
      </div>
    </div>
  );
};
