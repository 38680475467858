import { NavLink } from "react-router-dom";
import { Url } from "src/api/constants";
import { convertToDate } from "src/helpers/date";
import { AlertCard } from "../Alerts/AlertCard";
import { ReviewCard } from "../Reviews/ReviewCard";
import { FlightCard } from "../Flights/FlightCard";
import filter from "src/assets/images/filter.svg";

export const AlertsGroup = ({ items, tab, flightFilter, openFlightsFilters, flightsFilters }) => {
  return (
    <div
      className={`w-[230px] relative left-[10px] top-[0] pr-[10px] ${
        tab === "flights" ? "min-h-[74px] max-h-[40vh]" : "min-h-[70px]"
      } h-full`}>
      {tab === "flights" && !!items?.data.length && (
        <div className="relative -top-4 left-[16px] flex gap-4 whitespace-pre text-white font-bold">
          <button
            className="min-w-[16px] w-[16px] h-[16px] bg-[#DADAE3] hover:bg-[#CACAD3] flex justify-center items-center rounded-sm duration-300"
            onClick={() => openFlightsFilters(true)}>
            <img src={filter} alt="фильтр" width={13} height={13} />
          </button>
          <button className="h-[16px] text-[11px] px-8 leading-0 bg-font rounded-sm hover:opacity-80">
            <NavLink to={Url.FLIGHTS}>В вылеты {`\u279C`}</NavLink>
          </button>
          <div className="text-[10px] px-[6px] leading-4 w-full text-right bg-font rounded-sm">
            {convertToDate(items?.last_upd)}
          </div>
        </div>
      )}
      {(tab === "flights" ? items?.data?.length > 0 : items?.length > 0) && (
        <div className={`frame3 flex flex-col gap-8 heightAnimation w-[250px] my-0 h-full`}>
          {tab === "alert" && items?.map((el, index) => <AlertCard key={index} item={el} />)}
          {tab === "review" && items?.map((el, index) => <ReviewCard key={index} item={el} />)}
          {tab === "flights" &&
            items?.data
              ?.filter((el) => flightFilter(el, flightsFilters))
              ?.sort((a, b) => b.diff - a.diff)
              ?.map((el, index) => <FlightCard key={index} item={el} />)}
        </div>
      )}
    </div>
  );
};
