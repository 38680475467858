import { Dispatch, SetStateAction } from "react";
import { add } from "src/helpers/addHelper";
import { RateItem } from "src/store/statsReducer";
import { VectorLabelMaker } from "src/components/UI/labelMaker/LabelMaker";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { TT, TTetrisTab } from "../components/tetrisConstants";
import { findCurrentRate } from "src/helpers/tetrisHelper";

type TProps = {
  item: string;
  rates: RateItem[];
  vectors: string[];
  setVectors: Dispatch<SetStateAction<Array<string>>>;
  page: TTetrisTab;
  percent: number;
};

export const CurrencyItem = ({ item, rates, vectors, setVectors, page, percent }: TProps) => {
  const vectorData = item?.split("-to-");
  const rate = findCurrentRate({
    rates,
    curFrom: vectorData[0],
    curTo: vectorData[1],
  });
  const result = rate * ((100 + percent) / 100);

  return (
    <button
      type="button"
      style={{
        backgroundColor: vectors?.includes(item) ? `${page.color}33` : "#FAFAFF",
        fontWeight: vectors?.includes(item) ? "600" : "400",
      }}
      className={`relative select_button w-[145px] lg:w-[155px] px-[10px] pt-1 mx-0 ${
        page.page === TT.AUTOCORR ? "pb-[10px] h-[55px]" : ""
      }`}
      onClick={() => add(vectors, setVectors, item)}>
      <VectorLabelMaker vector={item} />
      {page.page === TT.AUTOCORR && !Number.isNaN(rate) && !!rate && (
        <div className="absolute bottom-0 text-[9px]">
          <Tooltip content={`Результирующий курс\nот курса биржи\n${rate}`}>
            <div className="mt-[14px] bg-lightGray rounded-sm px-4 py-[1px] leading-[9px]">
              {parseFloat(result.toFixed(6))}
            </div>
          </Tooltip>
        </div>
      )}
    </button>
  );
};
