export const Notice = ({ count }) => {
  return (
    <div className="absolute top-[-5px] left-[-5px] rounded-[9px] bg-font text-white text-[12px] font-bold min-w-[18px] h-[18px] px-[5px] z-[1]">
      {count}
    </div>
  );
};

export const MiniNotice = ({
  count,
  isVisible,
  right,
  bg,
  color,
}: {
  count: number;
  isVisible: boolean;
  right?: number;
  bg?: string;
  color?: string;
}) => {
  return (
    <div
      style={{
        right: right ? `${right}px` : "20px",
        backgroundColor: bg ? bg : "#282c34",
        color: color ? color : "#fff",
      }}
      className={`${
        isVisible ? "flex" : "hidden md:flex"
      } absolute top-[3px] items-center rounded-[9px] text-[10px] leading-0 font-bold w-fit min-w-[14px] h-[14px] px-[5px] z-[30]`}>
      {count}
    </div>
  );
};
