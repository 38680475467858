import { Popup } from "src/components/Popup/Popup";

export const DeleteConfirmation = ({
  data,
  isFullConfirmation,
  openFullConfirmation,
  deleteArr,
  deleteItems,
  allFilter,
}) => {
  return (
    <Popup closeModal={() => openFullConfirmation(null)}>
      <div className="text-xs flex flex-col items-center">
        <div className="w-full text-sm">Уверены что хотите удалить выбранные элементы?</div>
        <div className="my-[10px] w-full max-h-[70vh] overflow-y-scroll flex flex-col gap-4">
          {isFullConfirmation
            ? data
                ?.filter((el) => !el.is_locked)
                ?.filter((el) => allFilter(el))
                ?.map((el2, index) => (
                  <div key={index}>{`${el2.site_name} - ${el2.city_name} - ${el2.vector}`}</div>
                ))
            : deleteArr?.map((el) => {
                const obj = JSON.parse(el);
                if (
                  data?.find(
                    (dataItem) =>
                      `${dataItem?.vector}_${dataItem?.site_name}_${dataItem?.city_name}` ===
                      `${obj?.vector}_${obj?.site_name}_${obj?.city_name}`
                  )?.is_locked !== true
                ) {
                  return (
                    <div key={`${obj.site_name}_${obj.city_name}_${obj.vector}`}>
                      {`${obj.site_name} - ${obj.city_name} - ${obj.vector}`}
                    </div>
                  );
                }
              })}
        </div>
        <button
          className="prime-button-sm bg-font text-white"
          onClick={() => {
            isFullConfirmation ? deleteItems(true) : deleteItems(false);
          }}>
          Удалить
        </button>
      </div>
    </Popup>
  );
};
