import { Popup } from "src/components/Popup/Popup";

export const Info = ({ setFn }) => {
  return (
    <Popup closeModal={() => setFn(false)}>
      <div className="flex flex-col gap-8 w-[270px] sm:w-[330px] md:w-[350px] font-light text-sm">
        <div>Составьте расписание кластеров для векторов, на которые хотите влиять</div>
        <div className="w-fit rounded-sm bg-[#40AEF033] px-4 -mx-4">
          <b>Выставленные значения появятся в таймлайне</b>
        </div>
        <div>
          <b>Обратите внимание:</b>
        </div>
        <ul className="list-disc pl-[10px] mt-4">
          <li>
            Если сайт стоит на кластере, тогда <b>расписание</b> игнорирует кластер сайта для
            выбранного вектора
          </li>
          <li>
            Если вектор уже стоит на кластере, то кластер вектора <b>удаляется</b>
          </li>
          <li>
            Вы можете запланировать движение вектора по позициям в течение дня, выставив для него
            несколько <b>расписаний</b>. Важно - временные интервалы не должны пересекаться
          </li>
          <li>
            При выставлении расписаний в <b>ночной период</b> нужно создать 2 расписания - один до
            23:59, второй с 00:00
          </li>
        </ul>
      </div>
    </Popup>
  );
};
