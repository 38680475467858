import { setNotific } from "src/store/mainReducer";
import { useAppDispatch } from "src/store/store";

interface ImagePreviewProps {
  photosIndex: number;
  url: string;
  setCurrentImage: ({ src, index }: { src: string; index: number }) => void;
  setPopup: (val: boolean) => void;
}
export const ImagePreview = ({
  photosIndex,
  url,
  setCurrentImage,
  setPopup,
}: ImagePreviewProps) => {
  const dispatch = useAppDispatch();

  const openImage = () => {
    if (!~photosIndex) {
      dispatch(
        setNotific({
          type: "error",
          message: "Изображение не найдено",
        })
      );
      return;
    }
    setCurrentImage({ src: url, index: photosIndex });
    setPopup(true);
  };

  return (
    <div className="flex flex-col border border-grayDark/20 rounded-md w-[70px] aspect-square items-center justify-center overflow-hidden">
      <img
        className="cursor-pointer object-cover w-full h-full"
        src={url}
        alt=""
        onClick={openImage}
      />
    </div>
  );
};
