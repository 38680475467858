import { FC } from "react";
import { Url } from "src/api/constants";
import { NestedObjects } from "../Control/components/routes";
import { SolveTest } from "./SolveTest/SolveTest";
import { EditTest } from "./CreateTest/EditTest";
import { Result } from "./Result/Result";

export const academyRoutes = {
  test: {
    title: "Тест",
    path: Url.ACADEMY_SOLVE,
    element: SolveTest,
    access: ["courses_get"],
  },
  edit: {
    title: "Редактирование тестов",
    path: Url.ACADEMY_EDIT,
    element: EditTest,
    access: ["courses_post"],
  },
  result: {
    title: "Результаты аттестации",
    path: Url.ACADEMY_RESULT,
    element: Result,
    access: ["courses_get"],
  },
} as NestedObjects<TAcademyRoute>;

export type TAcademyRoute = {
  title: string;
  path: string;
  element: FC;
  access: Array<string>;
};
