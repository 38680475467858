import { useEffect } from "react";
import { useSelector } from "react-redux";
import switchApi from "src/api/onOff";
import { fetchRequest } from "src/helpers/fetchRequest";
import { setNotific } from "src/store/mainReducer";
import { setGlobalCurrencies, setGlobalDirections } from "src/store/directionsReducer";
import { SitesList } from "src/pages/Panel/OnOff/OnOffTimeline/components/common/SitesList";
import { setOpenAllSites, setSelectedItem, setZoom } from "src/store/onOffTimelineReducer/slice";
import { CreateBlock } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/CreateBlock";
import { TimeLineData } from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/TimelineData";
import { OnOffConfirmPopup } from "src/pages/Panel/OnOff/OnOffTimeline/components/popups/OnOffConfirmPopup";
import { Popup } from "src/components/Popup/Popup";
import { ItemForm } from "src/pages/Panel/OnOff/OnOffTimeline/components/forms/ItemForm";
import {
  selectSelectedItem,
  selectOnOffIsSitesMenuOpen,
  selectOnOffTimelineData,
  selectOnOffUnfilterredData,
} from "src/store/onOffTimelineReducer/selectors";
import { useGlobalFilters } from "src/hooks/useGlobalFilters";
import { getOnOffSites } from "src/store/onOffTimelineReducer/thunks/getOnOffSites";
import { useAppDispatch } from "src/store/store";
import { ErrorBoundary } from "@sentry/react";
import { ErrorFallback } from "src/components/ErrorHandling/ErrorFallback";
import { TimelineHeader } from "src/pages/Panel/OnOff/OnOffTimeline/components/header/TimelineHeader";
import useDebounce from "src/hooks/useDebounce";

export const sources = {
  site: {
    title: "Сайт",
    color: "#72E5AE",
  },
  bestchange: {
    title: "Best",
    color: "#40AEF0",
  },
};

export const OnOffTimeline = () => {
  const dispatch = useAppDispatch();

  const selectedItem = useSelector(selectSelectedItem);
  const isSitesMenuOpen = useSelector(selectOnOffIsSitesMenuOpen);

  const filteredData = useSelector(selectOnOffTimelineData);
  const data = useSelector(selectOnOffUnfilterredData);

  useGlobalFilters();

  const fetchGlobalDirections = async () => {
    const { response, error } = await fetchRequest(switchApi.getGlobalDirections());
    if (response) {
      dispatch(setGlobalDirections(response.vectors));
      dispatch(setGlobalCurrencies(response.currencies));
    }
    if (error) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText, request: `Глобальные данные` })
      );
    }
  };

  const closeItemForm = () => {
    dispatch(setSelectedItem(null));
    dispatch(setOpenAllSites(false));
  };

  useEffect(() => {
    fetchGlobalDirections();
    dispatch(getOnOffSites());
  }, []);

  const handleResize = useDebounce(() => {
    const newZoom = (window.innerWidth - 620) / 24;
    if (newZoom >= 30) {
      dispatch(setZoom(newZoom));
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        className={`relative grid duration-300 mt-[16px] ${
          isSitesMenuOpen ? "grid-cols-[170px_1fr]" : "grid-cols-[20px_1fr]"
        }`}>
        <SitesList />
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback errorMessage={error.message} resetError={resetError} />
          )}>
          <div className="h-full w-full rounded-sm pb-10">
            <TimelineHeader data={data} />
            <CreateBlock />
            <TimeLineData data={data} filteredData={filteredData} />
          </div>
        </ErrorBoundary>
      </div>
      <OnOffConfirmPopup />
      {selectedItem && (
        <Popup closeModal={closeItemForm}>
          <ItemForm closeModal={closeItemForm} selectedItem={selectedItem} />
        </Popup>
      )}
    </>
  );
};
