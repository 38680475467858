import { centrifuge } from "./centrifugo";
import { store } from "../../store/store";
import { RateItem, setRates } from "../../store/statsReducer";

export const convertRateItem = (item: RateItem): RateItem => ({
  ...item,
  pair_name: `${item.currency_code_from} - ${item.currency_code_to}`,
  course_price: Number(item.course_price),
});

export const createRatesSubscription = (resubscribe, unsubscribe) => {
  const subscription = centrifuge.subscribe(`direction:EXCHANGE_COURSES`, async function (res) {
    store.dispatch(setRates(convertRateItem(res.data)));
  });
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`direction:EXCHANGE_COURSES`, async function (res) {
      store.dispatch(setRates(convertRateItem(res.data)));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
};
