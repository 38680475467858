import { ButtonHTMLAttributes } from "react";
import useLongPress from "src/hooks/useLongPress";

interface HoldButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  interval?: number;
}

export const HoldButton = ({ onClick, interval, ...rest }: HoldButtonProps) => {
  const longPressCallbacks = useLongPress({
    cb: onClick,
    interval,
  });

  return (
    <button {...rest} onClick={onClick} {...longPressCallbacks}>
      {rest.children}
    </button>
  );
};
