export const colors = [
  "#3BB54A",
  "#009DDC",
  "#485158",
  "#CE86AA",
  "#FF6700",
  "#6761A8",
  "#A6F7A8",
  "#1638D8",
  "#E8D7FF",
  "#AAC0CD",
  "#C3F73A",
  "#A0C7FE",
  "#B6174B",
  "#009B72",
  "#9999FF",
  "#FAFF00",
  "#201E1F",
  "#FDA769",
  "#DFDDD5",
  "#E1B79F",
  "#2E77AE",
  "#31572C",
  "#134074",
  "#5D0178",
  "#84B082",
  "#F3FFBD",
  "#FF1654",
  "#857E61",
  "#D78521",
  "#BF9DCD",
];

export const pastel = [
  "#97CADB",
  "#C6FBDE",
  "#DF9DCD",
  "#E1B79F",
  "#A7A1E8",
  "#F5AEA1",
  "#F7C571",
  "#DFBDED",
  "#84B082",
  "#50BDFF",
  "#6EB7EE",
  "#BCF4DE",
  "#F3FFBD",
  "#FAFFA0",
  "#FF86B4",
  "#BD71D8",
  "#F7B551",
  "#60FBD2",
  "#86A8FF",
  "#FF9999",
  "#BBBBFF",
  "#FFFF88",
  "#6BE57A",
  "#84D082",
  "#FFDF86",
  "#70ADFF",
  "#4EE7FF",
  "#F5CEC7",
  "#A3D2E5",
  "#7EC3A4",
  "#F59E91",
  "#E07464",
  "#C6C09C",
  "#D0FFA7",
];
