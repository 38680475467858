import { createSlice } from "@reduxjs/toolkit";
import { StateT } from "./store";

const clusterSlice = createSlice({
  name: "cluster",
  initialState: {
    clusters: [] as Array<TCluster | TClusterSchedule>,
    timeline: null as null | Array<TTimeline>,
    timelineCities: [] as Array<number>,
    timelineLastUpd: null as null | string,
  },
  reducers: {
    setClusters: (state, action) => {
      state.clusters = action.payload;
    },
    setTimeline: (state, action) => {
      state.timeline = action.payload;
    },
    setTimelineCity: (state, action) => {
      const currentCity = state.timeline?.find((el) => el?.city_id === action.payload.city_id);
      if (!!currentCity) {
        state.timeline = state.timeline?.map((el) =>
          el?.city_id === action.payload.city_id ? action.payload.items : el
        );
      } else if (!!action.payload.items) {
        if (!!state.timeline) {
          state.timeline = [...state.timeline, action.payload.items]?.sort((a, b) =>
            a.city_id > b.city_id ? 1 : -1
          );
        } else {
          state.timeline = [action.payload?.items];
        }
      }
    },
    setTimelineCities: (state, action) => {
      state.timelineCities = action.payload;
    },
    setTimelineLastUpd: (state, action) => {
      state.timelineLastUpd = action.payload;
    },
  },
});

export const { setClusters, setTimeline, setTimelineCity, setTimelineCities, setTimelineLastUpd } =
  clusterSlice.actions;

export const selectClusters = (state: StateT): Array<TCluster | TClusterSchedule> =>
  state.cluster.clusters;
export const selectTimeline = (state: StateT): null | Array<TTimeline> => state.cluster.timeline;
export const selectTimelineCities = (state: StateT): Array<number> => state.cluster.timelineCities;
export const selectTimelineLastUpd = (state: StateT): null | string =>
  state.cluster.timelineLastUpd;

export default clusterSlice.reducer;

export type TSiteCluster = {
  site_id: number;
  site_name: string;
  percent: string;
};
export type TCluster = {
  city_name: string;
  percent: string;
  site_name: string;
  vector: string; // ETH-to-CASHUSD
  is_locked: boolean;
};
export type TClusterSchedule = {
  site_name: string;
  city_name: string;
  vector: string; // ETH-to-CASHUSD
  percent: string;
  big_percent: string;
  on_timeline: boolean;
  time_from: string; // 14:00
  time_to: string; // 14:00
  is_locked: boolean;
  is_infinity: boolean;
};

export type TTimelineSite = {
  creator?: string;
  id?: number;
  site_id: number;
  start_time: string; // 19:00
  end_time: string; // 20:20
  is_infinity: boolean;
  is_locked: boolean;
  action?: number;
};

export type TTimelineVector = {
  currency_from_id: number;
  currency_to_id: number;
  percent: number;
  cluster_percent: number;
  cluster_course: number;
  percent_position?: number;
  percent_position_min?: number;
  percent_position_max?: number;
  sites: Array<TTimelineSite>;
};

export type TTimeline = {
  city_id: number;
  vectors: Array<TTimelineVector>;
};

export type TUncontrolledError = {
  city_id: number;
  currency_from_id: number;
  currency_to_id: number;
  site_id: number;
  time_from: string;
  time_to: string;
};
export type TAutocorrError = {
  city_id: number;
  currency_from_id: number;
  currency_to_id: number;
  site_id: number;
};
export type TBlacklistError = {
  city_id: number;
  currency_from_id: number;
  currency_to_id: number;
};

export type TTimelineErrors = {
  site_empty: Array<TUncontrolledError>;
  autocorr: Array<TAutocorrError>;
  blacklists: Array<TBlacklistError>;
};

export type TSkippedTimeline = {
  big_percent: number;
  city_id: number;
  currency_from_id: number;
  currency_to_id: number;
  site_id: number;
  time_from: string;
  time_to: string;
  vector: string; // "USDTTRC-to-CASHUSD-msk"
};
export type TTimelineDeletePayload = {
  city_id: number;
  currency_from_id?: number;
  currency_to_id?: number;
  percent?: number;
  site_id?: number;
  start_time?: string;
  end_time?: string;
  saveRows?: boolean;
};
export type TTimelineUpdateLock = {
  city_name: string;
  site_name: string;
  vector: string;
  percent: number;
  time_from: string;
  time_to: string;
  is_locked: boolean;
};
// Шаблоны таймлайна
export type TTemplate = {
  title: string;
  id: number;
  creator?: string;
  created_at?: string; // 2023-06-30T19:57
  updater?: string;
  last_update?: string; // 2023-06-30T19:57
  dates: Array<string>; // 2023-07-11
};
