import { Copy } from "lucide-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";
import bidApi, { mockAgg } from "src/api/bid";
import { useFetch } from "src/hooks/useFetch";
import { Button } from "src/shadcn/ui/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "src/shadcn/ui/ui/dialog";
import { ErrorMessage } from "src/shadcn/ui/ui/error-message";
import { setNotific } from "src/store/mainReducer";
import { ModalProps, Option } from "src/types/common";

export const CopyAggModal = ({ isOpen, setIsOpen }: ModalProps) => {
  const dispatch = useDispatch();
  const [sp] = useSearchParams();
  const selectedAgg = sp.get("agg");
  const aggOptions = mockAgg
    .filter((item) => item !== selectedAgg)
    .map((item) => ({ value: item, label: item }));
  const [targetAgg, setTargetAgg] = useState<null | Option>(aggOptions[0]);

  const {
    fn: copyAggregator,
    isLoading,
    isError,
    error,
  } = useFetch({
    fetchFn: () =>
      bidApi.copyAggregator({
        source_aggregator_name: selectedAgg,
        target_aggregator_name: targetAgg.value,
      }),
    onSuccess: () => {
      dispatch(
        setNotific({
          type: "success",
          message: "Данные скопированы",
          request: "Копирование данных агрегатора",
        })
      );
      setIsOpen(false);
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger className="border p-[10px] rounded-md">
        <Copy className="w-[15px] h-[15px]" />
      </DialogTrigger>
      <DialogContent>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <p>
              Копирование данных из агрегатора <b>{selectedAgg}</b>.
            </p>
            <p>Выберите, в какой агрегатор данные будут скопированы</p>
          </div>
          <ReactSelect
            className="text font-bold text-center"
            classNamePrefix="Select"
            value={targetAgg}
            onChange={setTargetAgg}
            options={aggOptions}
          />
          <Button size="sm" loading={isLoading} onClick={copyAggregator}>
            Скопировать
          </Button>
          {isError && <ErrorMessage>{error}</ErrorMessage>}
        </div>
      </DialogContent>
    </Dialog>
  );
};
