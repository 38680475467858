import { LS } from "src/api/constants";
import { ESource, TSwitchSegment } from "src/store/onOffReducer";
import { sources } from "../../OnOffTimeline";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { Avatar } from "src/components/Viewers/Avatar";
import { timeToSeconds } from "src/helpers/date";
import { useSelector } from "react-redux";
import {
  selectOnOffTimelineZoom,
  selectOnOffTimelineStep,
} from "src/store/onOffTimelineReducer/selectors";

export const Segment = ({ attr, segment }: { attr: ESource; segment: TSwitchSegment }) => {
  const zoom = useSelector(selectOnOffTimelineZoom);
  const step = useSelector(selectOnOffTimelineStep);

  const username = localStorage.getItem(LS.USERNAME);

  const getInterval = (start: string, end: string) =>
    ((timeToSeconds(end) - timeToSeconds(start)) / (60 * step)) * zoom;

  const getStart = (start: string) => (timeToSeconds(start) / (60 * step)) * zoom;

  const intervalPX = getInterval(segment.start, segment.end);
  const startPX = getStart(segment.start);

  return (
    <div
      className={`absolute border w-fit h-[12px] rounded-sm min-w-[10px] ${
        attr === ESource.SITE ? "bottom-px" : "top-px"
      }`}
      style={{
        backgroundColor: segment.deleted_flag
          ? "#fff"
          : !!segment.creator
          ? sources[attr].color
          : `${sources[attr].color}CC`,
        borderColor: sources[attr].color,
        outline: segment.deleted_flag ? "#D7443ECC 1.5px dashed" : "",
        backgroundImage: segment.deleted_flag
          ? ""
          : `linear-gradient(to bottom, rgba(255, 255, 255, 0.4), ${sources[attr].color}`,
        boxShadow: segment.deleted_flag ? "" : `inset 0 1px 2px ${sources[attr].color}`,
        left: `${startPX}px`,
        width: `${intervalPX}px`,
        zIndex: segment.deleted_flag ? 5 : !!segment.id && !segment.deleted_flag ? "inherit" : 6,
        filter: !segment.id ? "hue-rotate(-20deg) brightness(1.2)" : "",
      }}>
      {!!segment.creator && (
        <div className={`absolute -top-8 -right-4 text-base font-bold`}>
          <Tooltip
            content={segment.creator === username ? "Вы" : segment.creator || ""}
            direction="left">
            <div
              className={`relative h-[18px] w-[18px] rounded-full border z-10 border-stale ${
                username === segment.creator ? "bg-font" : "bg-bg"
              }`}>
              <Avatar username={segment.creator} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
