import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { LS, Url } from "src/api/constants";
import { features } from "src/pages/NewFeatures/features";
import authApi from "src/api/auth";
import {
  selectIsLogged,
  selectLastViewed,
  setLastViewed,
  setLogout,
  setNotific,
} from "src/store/mainReducer";
import { selectPinned } from "src/store/commonReducer";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import { Burger } from "./components/Burger";
import { PageTitle } from "./components/PageTitle";
import burger from "src/assets/images/burger.svg";
import door from "src/assets/images/door.svg";
import { Pinned } from "./components/Pinned";
import { NavHints } from "./components/NavHints";

const Header = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const page = useLocation().pathname;
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");
  const username = localStorage.getItem(LS.USERNAME);
  const role = localStorage.getItem(LS.ROLE);
  const savedLastViewedVersion = localStorage.getItem(LS.LAST_VIEW);
  const pinned = useSelector(selectPinned);
  const lastViewedVersion = useSelector(selectLastViewed);
  const haveNewFeatures = lastViewedVersion !== features[0].id;
  const isAuth = useSelector(selectIsLogged);
  const [isBurger, setIsBurger] = useState(false);

  const logout = async () => {
    try {
      await authApi.logout();
    } catch (error) {
      dispatch(setNotific({ type: "error", message: "Не удалось выйти", request: "Logout" }));
    } finally {
      localStorage.removeItem(LS.USERNAME);
      localStorage.removeItem(LS.ACCESS);
      localStorage.removeItem(LS.ROLE);
      dispatch(setLogout());
      nav(Url.LOGIN, { replace: true });
    }
  };

  useEffect(() => {
    dispatch(setLastViewed(savedLastViewedVersion));
  }, []);

  if (isAuth && user_access) {
    return (
      <header className="header fixed top-[0] whitespace-pre w-full flex bg-font font-bold text-glass-md mb-10 z-50">
        <nav className="flex flex-nowrap items-center w-full">
          <div className="flex gap-[20px]">
            <button
              type="button"
              className="py-8 px-12 shrink-0"
              onMouseMove={() => setIsBurger(true)}
              onClick={() => setIsBurger(!isBurger)}>
              <img src={burger} alt="menu" width={28} height={28} />
            </button>
            <div className="flex flex-nowrap items-center gap-[16px] w-full m-12 h-[24px]">
              <PageTitle page={page} isBurger />
              <Burger
                user_access={user_access}
                pinned={pinned}
                page={page}
                isBurger={isBurger}
                setIsBurger={setIsBurger}
              />
            </div>
            {!!pinned?.length && (
              <div className={`hidden lg:flex items-center gap-8 text-white`}>
                <Pinned pinned={pinned} page={page} userAccess={user_access} hidePin />
              </div>
            )}
          </div>
        </nav>
        <div className="flex flex-nowrap items-center absolute right-[0] pr-[20px] h-[30px] m-8 justify-evenly w-fit bg-font">
          {isAuth && (
            <NavLink
              to={Url.NEWS}
              className={`relative top-2 rounded-full px-8 py-4 mr-8 text-xs leading-[12px] h-fit duration-300 ${
                haveNewFeatures ? "bg-[#FCC815] text-white" : ""
              } ${page === Url.NEWS ? "text-white" : ""}`}>
              updates
            </NavLink>
          )}
          {isAuth && (
            <button type="button" onClick={logout}>
              <Tooltip direction="bottom" content="Выйти">
                <img
                  className="w-[35px] h-[30px] relative top-4"
                  src={door}
                  alt="Выйти"
                  width={20}
                  height={20}
                />
              </Tooltip>
            </button>
          )}
          <div className="text-white text-left">
            <div>{username}</div>
            {!!role && <div className="text-[9px] leading-[6px] font-medium">{role}</div>}
          </div>
        </div>
        <div className={`hidden lg:block absolute top-[52px]`}>
          <NavHints userAccess={user_access} page={page} />
        </div>
      </header>
    );
  }
  return null;
};

export default Header;
