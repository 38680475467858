import zoomIn from "src/assets/images/zoom-in.svg";
import zoomOut from "src/assets/images/zoom-out.svg";
import { HoldButton } from "src/components/UI/buttons/HoldButton";

export const ZoomButtons = ({ value, setValue, step, min }) => {
  return (
    <div className="flex gap-2 rounded-sm bg-stale p-2 w-fit">
      <HoldButton
        type="button"
        className="flex justify-center items-center rounded-sm h-[22px] w-[22px] min-w-[22px] bg-bg duration-300 hover:shadow-inset"
        onClick={() => setValue(value + step)}>
        <img src={zoomIn} alt="увеличить" width={16} height={16} className="opacity-50" />
      </HoldButton>
      <HoldButton
        type="button"
        disabled={value <= min}
        className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] min-w-[22px] bg-bg duration-300 hover:shadow-inset ${
          value === min ? "opacity-70 brightness-[1.4]" : ""
        }`}
        onClick={() => {
          if (value > min) {
            setValue(value - step);
          }
        }}>
        <img
          src={zoomOut}
          alt="уменьшить"
          width={15.5}
          height={15.5}
          className={`${value === min ? "opacity-20" : "opacity-50"}`}
        />
      </HoldButton>
    </div>
  );
};
