import { Dispatch, SetStateAction, useState, memo } from "react";
import { convertToShortTime, timeToSeconds } from "src/helpers/date";
import { setTimelineByCentrifugo } from "src/api/centrifugo/timelineCentrifugo";
import { TTimeline, TTimelineVector } from "src/store/clusterReducer";

type TProps = {
  cityId: number;
  item: TTimelineVector;
  axisX: Array<number>;
  isAdded: string;
  zoom: number;
  step: number;
  id: number;
  items: Array<TTimeline>;
  username: string;
  actionsCount: number;
  setActionsCount: Dispatch<SetStateAction<number>>;
  activeTemplate: number | null;
  setTemplateData: Dispatch<SetStateAction<Array<TTimeline>>>;
};

export const VectorAxisX = memo(
  ({
    cityId,
    item,
    axisX,
    isAdded,
    zoom,
    step,
    id,
    items,
    username,
    actionsCount,
    setActionsCount,
    activeTemplate,
    setTemplateData,
  }: TProps) => {
    const now = new Date();
    const nowTime = Math.round(timeToSeconds(`${now.getHours()}:${now.getMinutes()}`) / 60);
    const past = nowTime % 3;
    const nowEl = Math.ceil(nowTime / step);
    const [startEl, setStartEl] = useState(-1);

    const addItem = async (start: number, end: number) => {
      const findItem = items?.find((el) => el.city_id === cityId);
      if (!!findItem) {
        const preparedItems = {
          ...findItem,
          vectors: findItem.vectors?.map((vector) =>
            `${vector.currency_from_id}_${vector.currency_to_id}_${vector.percent}` ===
            `${item.currency_from_id}_${item.currency_to_id}_${item.percent}`
              ? {
                  ...vector,
                  sites: [
                    ...vector.sites,
                    {
                      creator: username,
                      action: actionsCount + 1,
                      site_id: +isAdded,
                      start_time: convertToShortTime(start * 60 * step) || "00:00",
                      end_time:
                        end * 60 * step < 86400 ? convertToShortTime(end * 60 * step) : "23:59",
                      is_infinity: true,
                      is_locked: false,
                    },
                  ],
                }
              : vector
          ),
        };
        if (!!activeTemplate) {
          setTemplateData(items?.map((city) => (city.city_id === cityId ? preparedItems : city)));
        } else {
          await setTimelineByCentrifugo(preparedItems, cityId);
        }
        setActionsCount((prevState) => ++prevState);
      }
    };

    return (
      <div
        className={`flex justify-start w-fit h-full ${
          isAdded !== "" ? "z-10 relative" : "-z-10 absolute"
        } ${id % 2 > 0 ? "bg-[#EAEDE8AA]" : ""}`}>
        {axisX &&
          axisX?.map((el, index) => {
            const key = `${el}_${cityId}_${item.currency_from_id}_${item.currency_to_id}_${item.percent}_timeline`;
            return (
              <div
                key={key}
                className={`h-[29px] duration-300 hover:bg-glass-gray border-l border-glass-gray`}
                style={{
                  background:
                    index < nowEl - 1
                      ? "#BABAC355"
                      : index === nowEl - 1
                      ? `linear-gradient(to right, #BABAC355 ${(100 / 3) * past}%, #FAFAFF00 ${
                          (100 / 3) * (3 - past)
                        }%)`
                      : "#FAFAFF00",
                  width: `${zoom}px`,
                  maxWidth: `${zoom}px`,
                }}>
                {isAdded !== "" && (
                  <div
                    className={`flex justify-center items-center h-[23px] object-contain m-4 hover:bg-glass-md cursor-pointer text-xs text-lightFont ${
                      startEl === el ? "bg-[#FFFFFF55]" : "bg-[#D0FFB733]"
                    }`}
                    style={{
                      width: `${zoom - 8}px`,
                    }}
                    onClick={async () => {
                      if (startEl === -1) {
                        setStartEl(el);
                      } else {
                        await addItem(startEl, el + 1);
                        setStartEl(-1);
                      }
                    }}>
                    {startEl === el && `\u279C`}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
);
