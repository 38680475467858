import { TCurrencyTypes } from "src/pages/Text/types";
import { Url } from "src/api/constants";
import { SVG } from "src/assets/images/SVG";
import { Soon } from "../UI/soon/Soon";

export const CurrencyRow = ({
  currencyName,
  currencyType,
  page,
  onDeleteClick,
  access,
  isSoonAccess,
}: {
  currencyName: string;
  currencyType?: TCurrencyTypes;
  page: string;
  onDeleteClick?: () => void;
  access?: boolean;
  isSoonAccess?: boolean;
}) => {
  return (
    <div
      className={`grid gap-[20px] items-center rounded-lg duration-700 shadow-smooth p-[10px] text-left text-xs ${
        !!currencyType
          ? "grid-cols-[40px_1fr_1fr_16px] sm:grid-cols-[40px_1fr_1fr_20px]"
          : "grid-cols-[40px_1fr_16px] sm:grid-cols-[40px_1fr_20px]"
      }`}>
      <div className="flex justify-center items-center">
        <SVG id={currencyName} />
      </div>
      <div>{currencyName}</div>
      {!!currencyType && (
        <div className="text-center text-lightFont font-semibold">{currencyType}</div>
      )}
      {page === Url.ADD && (
        <Soon isAccess={isSoonAccess as boolean} isRight>
          <div>
            {access && (
              <button
                className={`duration-300 text-lightGray hover:text-lightFont`}
                onClick={onDeleteClick}>{`\u2716`}</button>
            )}
          </div>
        </Soon>
      )}
    </div>
  );
};
