import { createAsyncThunk } from "@reduxjs/toolkit";
import scheduleApi from "src/api/schedule";
import { fetchRequest } from "src/helpers/fetchRequest";

import { getCitiesWithGroups } from "src/helpers/onOffTimeline/getCititesWithGroups";
import { getTetrisPostData } from "src/helpers/onOffTimeline/getTetrisPostData";
import { proceedPostServerResponse } from "src/helpers/onOffTimeline/proceedPostServerResponse";
import { ICity } from "src/types/OnOffTimeline/common";
import { setNotific } from "src/store/mainReducer";
import {
  initExpandedState,
  setOnOffIsLoading,
  setOnOffTimelineData,
} from "src/store/onOffTimelineReducer/slice";
import { getOnOffSites } from "src/store/onOffTimelineReducer/thunks/getOnOffSites";

interface PostOnOffDataProps {
  data: ICity[];
  entitiesToExpand?: string[];
}

export const postOnOffData = createAsyncThunk(
  "onOff/postOnOffData",
  async ({ data, entitiesToExpand }: PostOnOffDataProps, { dispatch }) => {
    try {
      const body = getTetrisPostData(data);

      if (body.to_change.length === 0 && body.to_delete.length === 0) {
        return;
      }

      dispatch(setOnOffIsLoading(true));

      const { response, error } = await fetchRequest(scheduleApi.postSchedule(body));

      if (error || !response) {
        dispatch(
          setNotific({
            type: "error",
            message: error?.statusMessage || "Ошибка при сохранении данных",
          })
        );
        dispatch(setOnOffIsLoading(false));
        return;
      }

      if (response) {
        if (response.is_changed.skipped.length > 0 || response.is_deleted.skipped.length > 0) {
          dispatch(setNotific({ type: "error", message: "Не все данные были сохранены" }));
        }

        const res = proceedPostServerResponse(data, response);

        const groupedData = getCitiesWithGroups(res);
        dispatch(setOnOffTimelineData(res));
        dispatch(getOnOffSites());
        if (entitiesToExpand) {
          dispatch(initExpandedState({ data: groupedData, entitiesToExpand }));
        }
      }
    } catch (e) {
      dispatch(setNotific({ type: "error", message: "Ошибка при сохранении данных" }));
    }

    dispatch(setOnOffIsLoading(false));
  }
);
