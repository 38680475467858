import api from "./http";
import { getEndOfTheDate, getStartOfTheDate } from "src/helpers/date";
import { BASE_URL } from "./constants";

export default class requestApi {
  static async getRequests({ from, to }) {
    return api.get(
      `${BASE_URL}/it-requests/requests/by_time?time_from=${getStartOfTheDate(
        +from * 1000
      )}&time_to=${getEndOfTheDate(+to * 1000)}`
    );
  }
  static async getRequest(id) {
    return api.get(`${BASE_URL}/it-requests/requests/${id}/`);
  }
  static async createRequest(data) {
    return api.post(`${BASE_URL}/it-requests/requests/`, data);
  }
  static async updateRequest(data) {
    return api.patch(`${BASE_URL}/it-requests/requests/`, data);
  }
  static async getTags() {
    return api.get(`${BASE_URL}/it-requests/tags/`);
  }
  static async addComment(data) {
    return api.post(`${BASE_URL}/it-requests/comments/`, data);
  }
  static async markAsViwed(id) {
    return api.post(`${BASE_URL}/it-requests/requests/${id}/view`);
  }
  static async deleteAsssets(data) {
    return api.delete(`${BASE_URL}/it-requests/requests/delete_assets`, {
      data: { assets_ids: data },
    });
  }
  static async uploadAssets(id, formData) {
    return api.post(`${BASE_URL}/it-requests/requests/${id}/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  static async getUnreadReqCount() {
    return api.get(`${BASE_URL}/it-requests/requests/new_requests`);
  }
  static async viewAll() {
    return api.post(`${BASE_URL}/it-requests/requests/view_all`);
  }
  static async getStatistics({ from, to }) {
    return api.get(
      `${BASE_URL}/it-requests/requests/stats?time_from=${getStartOfTheDate(
        +from * 1000
      )}&time_to=${getEndOfTheDate(+to * 1000)}`
    );
  }
}
