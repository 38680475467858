import { BASE_URL } from "./constants";
import api from "./http";

export default class clusterApi {
  static async createCluster(data) {
    return api.post(`${BASE_URL}/cluster/percent`, data);
  }
  static async getClusters() {
    return api.get(`${BASE_URL}/cluster/all`);
  }
  static async deleteClusters(data) {
    return api.post(`${BASE_URL}/cluster/delete`, data);
  }
  static async updateCluster(data) {
    return api.post(`${BASE_URL}/cluster/lock`, data);
  }
  static async updateSitesCluster(data) {
    return api.post(`${BASE_URL}/cluster-manager/sites/`, data);
  }
  static async getSitesClusters() {
    return api.get(`${BASE_URL}/cluster-manager/sites/`);
  }
  // Расписание
  static async getClusterSchedule() {
    return api.get(`${BASE_URL}/cluster-manager/schedule/`);
  }
  static async createClusterSchedule(data) {
    return api.post(`${BASE_URL}/cluster-manager/schedule/old`, data);
  }
  static async deleteClusterSchedule(data) {
    return api.post(`${BASE_URL}/cluster-manager/schedule/delete`, data);
  }
  static async updateClusterSchedule(data) {
    return api.post(`${BASE_URL}/cluster-manager/schedule/lock`, data);
  }
  static async updateClusterScheduleInfinity(data) {
    return api.post(`${BASE_URL}/cluster-manager/schedule/infinity`, data);
  }
  // Таймлайн
  static async getTimeline() {
    return api.get(`${BASE_URL}/cluster-manager/timeline/`);
  }
  static async updateTimeline(data) {
    return api.post(`${BASE_URL}/cluster-manager/timeline/`, data);
  }
  static async updateTimelinePercent(data) {
    return api.patch(`${BASE_URL}/cluster-manager/timeline/percent`, data);
  }
  static async updateTimelineInfinity(data) {
    return api.patch(`${BASE_URL}/cluster-manager/timeline/infinity`, data);
  }
  static async deleteTimelinePercent(data) {
    return api.delete(
      `${BASE_URL}/cluster-manager/timeline` +
        `${"city_id" in data ? `?city_id=${data.city_id}` : ""}` +
        `${"currency_from_id" in data ? `&currency_from_id=${data.currency_from_id}` : ""}` +
        `${"currency_to_id" in data ? `&currency_to_id=${data.currency_to_id}` : ""}` +
        `${"percent" in data ? `&percent=${data.percent}` : ""}` +
        `${"site_id" in data ? `&site_id=${data.site_id}` : ""}` +
        `${"start_time" in data ? `&start_time=${data.start_time}` : ""}` +
        `${"end_time" in data ? `&end_time=${data.end_time}` : ""}`
    );
  }
  static async getTimelineErrors() {
    return api.get(`${BASE_URL}/cluster-manager/timeline/conflicts`);
  }
  // Таймлайн шаблоны
  static async getTemplates() {
    return api.get(`${BASE_URL}/cluster-manager/templates/queue`);
  }
  static async getTemplate(id) {
    return api.get(`${BASE_URL}/cluster-manager/templates/?id=${id}`);
  }
  static async deleteTemplate(id) {
    return api.delete(`${BASE_URL}/cluster-manager/templates/?id=${id}`);
  }
  static async createTemplate(data) {
    return api.post(`${BASE_URL}/cluster-manager/templates/`, data);
  }
  static async updateTemplate(id, updater, data) {
    return api.patch(`${BASE_URL}/cluster-manager/templates/?id=${id}&updater=${updater}`, data);
  }
  static async renameTemplate(data) {
    return api.patch(`${BASE_URL}/cluster-manager/templates/rename`, data);
  }
  static async updateQueue(data) {
    return api.post(`${BASE_URL}/cluster-manager/templates/queue`, data);
  }
  static async deleteTimelineItem(data) {
    return api.delete(
      `${BASE_URL}/cluster-manager/templates` +
        `${"city_id" in data ? `?city_id=${data.city_id}` : ""}` +
        `${"template_id" in data ? `&template_id=${data.template_id}` : ""}` +
        `${"currency_from_id" in data ? `&currency_from_id=${data.currency_from_id}` : ""}` +
        `${"currency_to_id" in data ? `&currency_to_id=${data.currency_to_id}` : ""}` +
        `${"percent" in data ? `&percent=${data.percent}` : ""}` +
        `${"site_id" in data ? `&site_id=${data.site_id}` : ""}` +
        `${"start_time" in data ? `&start_time=${data.start_time}` : ""}` +
        `${"end_time" in data ? `&end_time=${data.end_time}` : ""}`
    );
  }
}
