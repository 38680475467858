import eye from "src/assets/images/eye.svg";
import closeEye from "src/assets/images/closeEye.svg";
import zoomIn from "src/assets/images/zoom-in.svg";
import infinity from "src/assets/images/infinity.svg";
import pumpkin from "src/assets/images/pumpkin.svg";

export const Info = () => {
  return (
    <div className="text-[11px] text-left min-w-[290px] max-w-[350px] md:max-w-[500px] flex flex-col gap-[10px] max-h-[80vh] overflow-y-scroll visibleScroll px-[10px]">
      <div>
        Это Таймлайн. На графике можно видеть время, когда сайт находится на определенном проценте,
        по которому считается позиция, где 100% - количество сайтов вектора. Если время уже прошло -
        оно отмечается серым.
      </div>
      <div>
        Для того, чтобы добавить сайт - нужно перетащить сайт из общего пула слева. Для удобства
        можете отобразить только сайты с беста.
      </div>
      <div>
        Нельзя создать и кластер <b>timeline</b>, и кластер на <b>расписание</b>, если есть
        пересечение по времени для сайта. Это взаимоисключающие кластера. С обычными{" "}
        <b>кластерами</b> и <b>кластерами сайтов</b> - если есть пересечения, обычный кластер
        игонрируется, в приоритете всегда кластер таймлайн/расписание.
      </div>
      <div>
        Можно сделать так, чтобы разные сайты стояли в одно время на одном проценте и направлении,
        но нельзя сделать так, чтобы один сайт стоял на одном направлении, но на разных процентах в
        пересекающиеся промежутки времени.
      </div>
      <div>
        <b>Важно!</b> Процент кластера рассчитывается от нашего лимита, а не от ТОПа беста.
      </div>
      <div>
        Узнайте о ожидаемом проценте кластера и примерном курсе, нажав на сайт на таймлайне.
      </div>
      <div className="grid grid-cols-[30px_1fr] items-baseline gap-x-[10px] gap-y-4">
        <div>
          <div className="flex justify-center items-center w-[20px] min-w-[20px] h-[20px] rounded-sm bg-stale font-normal text-base text-lightFont pb-[3px]">
            +
          </div>
        </div>
        <div>
          Добавляйте города, направления и проценты. Если создать направление на таймлайне, но не
          добавить ни один сайт, тогда направление <b>не сохранится</b>. Если делаете какие-то
          правки, нажимайте на кнопку <b>сохранить</b> внизу.
        </div>
        <div className="rounded-sm bg-stale p-2 w-fit h-fit">
          <div className="flex justify-center items-center rounded-sm h-[16px] w-[16px] min-w-[16px] bg-bg text-lightFont text-[9px]">
            <div className="rotate-90">{`\u25BC`}</div>
            <div className="-rotate-90">{`\u25BC`}</div>
          </div>
        </div>
        <div>
          Кнопки для изменения времени. Увеличивать или уменьшать таймлайн при нажатии на границы
          сайта. Также изменить время можно при нажатии на сам сайт на таймлайне в открывшемся окне.
        </div>
        <div className="rounded-sm bg-stale p-2 w-fit h-fit relative top-4">
          <div className="flex justify-center items-center rounded-sm h-[16px] w-[16px] min-w-[16px] bg-bg">
            <img src={zoomIn} alt="увеличить" width={14} height={14} className="opacity-50" />
          </div>
        </div>
        <div>Регулируйте масштаб</div>
        <div className="rounded-sm bg-stale p-2 w-fit h-fit relative top-4">
          <div className="flex justify-center items-center rounded-sm h-[16px] w-[16px] min-w-[16px] bg-bg">
            <img src={eye} alt="показать" width={14} height={14} />
          </div>
        </div>
        <div>Показать только выделенные векторы</div>
        <div className="rounded-sm bg-stale p-2 w-fit h-fit relative top-4 mb-[6px]">
          <div className="flex justify-center items-center rounded-sm h-[16px] w-[16px] min-w-[16px] bg-bg">
            <img src={closeEye} alt="скрыть" width={22} height={22} />
          </div>
        </div>
        <div>Скрыть выделенные векторы</div>
        <div className="flex gap-2 rounded-sm bg-stale p-2 w-fit text-[8px] text-lightFont">
          <div className={`flex justify-center items-center rounded-sm h-[16px] px-4 bg-bg`}>
            BEST
          </div>
        </div>
        <div>Показать только сайты с беста</div>
        <div
          className={`bg-[#F7931A] flex items-center justify-center rounded-full h-[16px] w-[16px] mx-2`}>
          <img src={pumpkin} alt={"тыква"} width={13} height={13} />
        </div>
        <div>Таймлайн не сохранен. После 00:00 он удалится.</div>
        <div
          className={`bg-[#40AEF0] flex items-center justify-center rounded-full h-[16px] w-[16px] mx-2`}>
          <img src={infinity} alt={"сохранено"} width={13} height={13} />
        </div>
        <div>
          Таймлайн сохранен и будет применяться в последующие дни. Если с вечера планируете таймланы
          на следующий день - воспользуйтесь этим.
        </div>
        <div className="mx-2">0 %</div>
        <div>
          Процент можно изменить. При изменении процента он изменится для всех сайтов таймлайна.
        </div>
      </div>
    </div>
  );
};
