import { deleteRedactor, setRedactor } from "../../store/bidReducer";
import { store } from "../../store/store";
import { centrifuge } from "./centrifugo";

export const setRedactorByCentrifugo = async (vector, user) => {
  const updItemVal = {
    key: "SET_REDACTOR",
    vector: vector,
    user: user,
  };
  try {
    await centrifuge.publish(`bid:${vector}_redactor`, JSON.stringify(updItemVal));
  } catch {
    store.dispatch(setRedactor({ vector, user: user }));
  }
};

export const deleteRedactorByCentrifugo = async (vector) => {
  const updItemVal = {
    key: "DELETE_REDACTOR",
    vector,
  };
  try {
    await centrifuge.publish(`bid:${vector}_redactor`, JSON.stringify(updItemVal));
  } catch {
    store.dispatch(deleteRedactor({ vector }));
  }
};

export const createBidSubscription = (resubscribe, unsubscribe, vector) => {
  let subscription = centrifuge.subscribe(`bid:${vector}_redactor`);
  if (resubscribe) {
    subscription.unsubscribe();
    subscription = centrifuge.subscribe(`bid:${vector}_redactor`);
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
  subscription.on("publish", function ({ data }) {
    const msg = JSON.parse(data);
    const { key, vector, user } = msg;
    switch (key) {
      case "SET_REDACTOR":
        store.dispatch(setRedactor({ vector, user: user }));
        break;
      case "DELETE_REDACTOR":
        store.dispatch(deleteRedactor({ vector }));
        break;
      default: {
        console.log(msg);
      }
    }
  });
  subscription.on("subscribe", async function () {
    try {
      const res = await subscription.history({
        limit: 1,
      });
      const msg = JSON.parse(res.publications[0].data);
      if (res.publications[0].data) {
        // здесь он может получать массив направлений и чуваков
        if (msg.key === "SET_REDACTOR") {
          setTimeout(() => {
            store.dispatch(setRedactor({ vector: msg.vector, user: msg.user }));
          }, 1000);
        }
        if (msg.key === "DELETE_REDACTOR") {
          setTimeout(() => {
            store.dispatch(deleteRedactor({ vector: msg.vector }));
          }, 1000);
        }
      }
    } catch (e) {
      console.log(e);
    }
  });
};
