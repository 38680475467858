import { TAlias } from "../types";

export const Alias = ({
  alias,
  text,
  setText,
  caret,
}: {
  alias: Array<TAlias>;
  text: string;
  setText;
  caret: number | null;
}) => {
  return (
    <div className="flex flex-wrap gap-4">
      {alias?.map((el) => (
        <AliasBadge key={el.id} item={el} text={text} setText={setText} caret={caret} />
      ))}
    </div>
  );
};

export const AliasBadge = ({
  item,
  text,
  setText,
  caret,
}: {
  item: TAlias;
  text: string;
  setText;
  caret: number | null;
}) => {
  const pasteFn = () => {
    if (caret !== null) {
      setText(text?.substring(0, caret) + item?.value + text?.substring(caret));
    } else {
      setText(text + item?.value);
    }
  };

  return (
    <button
      className="bg-blue font-semibold text-white px-8 rounded-sm hover:opacity-80 duration-300"
      onClick={pasteFn}>
      {item?.title}
    </button>
  );
};
