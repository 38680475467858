import { useState } from "react";
import {
  TAdminSiteCreatePayload,
  TAdminSiteUpdatePayload,
  TExcCity,
  TExcVector,
} from "src/store/commonReducer";
import { TSelect } from "src/store/directionsReducer";
import arrow from "src/assets/images/arrow.svg";
import bin from "src/assets/images/bin.svg";

type TProps = {
  item: TExcCity;
  siteId: number;
  citiesOptions: Array<TSelect>;
  currenciesOptions: Array<TSelect>;
  baseRequest: (
    request: "add" | "update" | "delete",
    title: "Создание исключения" | "Удаление исключения",
    data: TAdminSiteUpdatePayload | TAdminSiteCreatePayload
  ) => void;
};

export const Exception = ({
  item,
  siteId,
  citiesOptions,
  currenciesOptions,
  baseRequest,
}: TProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const cityName = citiesOptions?.find((el) => el.value === item.city_id)?.label;

  return (
    <div
      className={`rounded-sm border border-stale p-8 bg-bg duration-300 ${
        isOpen ? "max-h-[300px] overflow-y-scroll" : "max-h-[34px] overflow-hidden"
      }`}>
      <div className="flex justify-between items-center mb-8">
        <div>{cityName}</div>
        <div className="flex gap-[16px]">
          <button
            type="button"
            className={`w-[16px] h-[16px] bg-[#EAEAF3] hover:bg-[#D7443E77] flex justify-self-end justify-center items-center rounded-sm duration-300`}
            onClick={() =>
              baseRequest("delete", "Удаление исключения", {
                site_id: siteId,
                city_id: item.city_id,
              })
            }>
            <img src={bin} alt="удалить" width={15} height={15} />
          </button>
          <button type="button" onClick={() => setIsOpen(!isOpen)}>
            <img
              src={arrow}
              alt="Открыть"
              width={10}
              height={10}
              className={`duration-300 w-[10px] h-[10px] ${isOpen ? "rotate-180" : ""}`}
            />
          </button>
        </div>
      </div>
      <div className="grid sm:grid-cols-2">
        {item?.data?.map((el) => (
          <ExceptionVector
            key={`${item?.city_id}_${el?.currency_from_id}_${el.currency_to_id}`}
            item={el}
            currenciesOptions={currenciesOptions}
          />
        ))}
      </div>
    </div>
  );
};

export const ExceptionVector = ({
  item,
  currenciesOptions,
}: {
  item: TExcVector;
  currenciesOptions: Array<TSelect>;
}) => {
  const from = currenciesOptions?.find((el) => el.value === item.currency_from_id);
  const to = currenciesOptions?.find((el) => el.value === item.currency_to_id);

  return (
    <div className="flex gap-4">
      <div>{from.label}</div>
      <div>{to.label}</div>
    </div>
  );
};
