import { CSSProperties, ReactNode, useState } from "react";
import arrow from "src/assets/images/arrow.svg";

interface AccordionProps {
  children: ReactNode;
  label?: string;
  labelClassname?: string;
  labelContainerClassname?: string;
  labelContainerStyles?: CSSProperties;
  customLabelComponent?: ReactNode;
  containerClassname?: string;
  containerStyles?: CSSProperties;
  isOpen?: boolean;
  isExpandable?: boolean;
  toggleIsOpen?: () => void;
  notExpandableOnClick?: () => void;
}

export const Accordion = ({
  label,
  labelClassname,
  labelContainerClassname,
  labelContainerStyles,
  customLabelComponent,
  containerClassname,
  containerStyles,
  children,
  isOpen,
  isExpandable = true,
  toggleIsOpen,
  notExpandableOnClick,
}: AccordionProps) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggle = () => setAccordionOpen((prev) => !prev);

  const isOpenVar = isOpen || accordionOpen;
  const toggleHandler = toggleIsOpen || toggle;

  return (
    <div
      className={`w-full flex flex-col items-start ${containerClassname || ""}`}
      style={containerStyles}>
      <div
        onClick={isExpandable ? toggleHandler : undefined}
        style={labelContainerStyles}
        className={`sticky left-0 cursor-pointer flex items-center gap-8 pl-2 ${
          labelContainerClassname || ""
        }`}>
        {isExpandable && (
          <span
            className={`relative duration-300 ${
              isOpenVar ? "rotate-180" : ""
            } w-[10px] h-[10px] min-w-[10px]`}>
            <img src={arrow} alt="раскрыть" width={10} height={10} />
          </span>
        )}
        <span className={labelClassname} onClick={!isExpandable ? notExpandableOnClick : undefined}>
          {customLabelComponent || label || "Группа"}
        </span>
      </div>
      {/* inside content */}
      {isOpenVar && children}
    </div>
  );
};
