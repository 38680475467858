import { NavLink } from "react-router-dom";

export const Tool = ({ item }) => {
  return (
    <div className="flex flex-col gap-8">
      <div>{item.title.toUpperCase()}</div>
      <NavLink to={item.route.path} className="text-xs text-lightGray">
        {item.route.title}
      </NavLink>
      <div className="flex flex-col gap-4 text-xs">
        {item?.description?.map((el, index) => (
          <div key={index}>
            {el?.split("**")?.map((substr, id) => (
              <span key={`${index}_${id}`} className="even:text-[#40AEF0] even:font-semibold">
                {substr}
              </span>
            ))}
          </div>
        ))}
        <div className="flex flex-col gap-4 text-xs mt-8">
          {item?.icons?.map((el, index) => (
            <div key={index} className="flex gap-8">
              <div className="flex gap-8">
                <div
                  style={{ backgroundColor: el.bg, color: el.color }}
                  className="relative top-2 h-[16px] w-[16px] min-w-[16px] flex justify-center items-center rounded-sm font-bold text-[16px] leading-[0]">
                  {el.content}
                </div>
                <div>
                  {el?.text?.split("**")?.map((substr, id) => (
                    <span key={`${index}_${id}`} className="even:text-[#40AEF0] even:font-semibold">
                      {substr}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
