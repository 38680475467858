import { RateItem } from "src/store/statsReducer";

export enum ERateSource {
  BINANCE = "Binance",
  COINBASE = "Coinbase",
  CENTRAL_BANK = "Central Bank",
}

export const sourceColor = (source: string) => {
  switch (source) {
    case ERateSource.BINANCE:
      return "#FFBC0A";
    case ERateSource.COINBASE:
      return "#52D58E";
    case ERateSource.CENTRAL_BANK:
      return "#A58";
    default:
      return "#89A";
  }
};

/**
 Находит курс для выбранной пары.
 Источников курса может быть несколько, курсы из разных источников могут отличаться.
 У источников есть приоритетность, в первую очередь нужно попытаться найти курс из самого приоритетного источника.
 Приоритетность источников: BINANCE -> все остальные.
 */
export const findCurrentRate = ({
  rates,
  curFrom,
  curTo,
}: {
  rates: RateItem[];
  curFrom: string;
  curTo: string;
}): number | null => {
  const from = curFrom?.includes("RUB") ? "CASHRUB" : curFrom;
  const to = curTo?.includes("RUB") ? "CASHRUB" : curTo;

  const isDoubleRub = curFrom?.includes("RUB") && to?.includes("RUB");

  if (isDoubleRub) return 1;

  // находим все данные по выбранной паре
  let pairRates = rates?.filter((el) =>
    from?.includes("CASH")
      ? el?.pair_name?.replaceAll("20", "") === `${to} - ${from?.replace("CASH", "")}`
      : el?.pair_name?.replaceAll("20", "") === `${from} - ${to?.replace("CASH", "")}`
  );
  if (!pairRates?.length) {
    pairRates = rates?.filter(
      (el) =>
        el?.pair_name?.replaceAll("20", "") ===
        `${from?.replace("CASH", "")} - ${to?.replace("CASH", "")}`
    );
  }

  // данные для текущей пары не были найдены
  if (!pairRates?.length) return null;

  const binance_rate = pairRates?.find(
    (el) => el.exchanger_name === ERateSource.BINANCE
  )?.course_price;
  return !!binance_rate ? +binance_rate : +pairRates[0]?.course_price;
};
