import { useEffect, useState } from "react";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";

export const OnOffRow = ({ item, isAccess, add, isFull, data }) => {
  const [checked, setChecked] = useState(false);

  const checkIt = () => {
    setChecked(!checked);
    add(item);
  };

  useEffect(() => {
    setChecked(isFull);
  }, [isFull]);

  useEffect(() => {
    setChecked(false);
  }, [data]);

  return (
    <div
      className={`rounded-sm pl-4 pr-8 py-[1px] h-[22px] w-fit pt-[3px] flex outline outline-1 text-[#383C44] font-semibold ${
        item?.best_is_enabled
          ? "bg-[#40AEF055] outline-[#40AEF0]"
          : item.vector_is_enabled
          ? "bg-[#3BB54A11] outline-[#3BB54A55]"
          : "bg-[#D7443E11] outline-[#D7443E33]"
      }`}>
      {isAccess && (
        <div className="text-[10px] -mt-[1px]">
          <Checkbox checked={checked} onChange={checkIt} />
        </div>
      )}
      <div className={`flex gap-4`}>
        <div>{item.vector[0]}</div>
        <div>{item.vector[2]}</div>
        <div>{item.city_code}</div>
      </div>
    </div>
  );
};
