import { useDispatch } from "react-redux";
import { OnOffDataEntityType } from "src/types/OnOffTimeline/store";
import {
  selectIsCityExpanded,
  selectIsCityFullyExpanded,
  selectIsGroupExpanded,
  selectIsGroupFullyExpanded,
  selectIsPairExpanded,
  selectFromExpanded,
  selectIsFromFullyExpanded,
} from "src/store/onOffTimelineReducer/selectors";
import { toggleExpandedEntity } from "src/store/onOffTimelineReducer/slice";
import { useAppSelector } from "src/store/store";

interface UseOnOffToggleExpandedProps {
  type: OnOffDataEntityType;
  selector: string;
}

export const useOnOffToggleExpanded = ({ type, selector }: UseOnOffToggleExpandedProps) => {
  const dispatch = useDispatch();

  let isExpanded = false;
  let isFullyExpanded = false;
  const toggleIsExpanded = () => dispatch(toggleExpandedEntity(selector));

  switch (type) {
    case OnOffDataEntityType.CITY:
      isExpanded = useAppSelector((state) => selectIsCityExpanded(state, selector));
      isFullyExpanded = useAppSelector((state) => selectIsCityFullyExpanded(state, selector));
      break;
    case OnOffDataEntityType.GROUP:
      isExpanded = useAppSelector((state) => selectIsGroupExpanded(state, selector));
      isFullyExpanded = useAppSelector((state) => selectIsGroupFullyExpanded(state, selector));
      break;
    case OnOffDataEntityType.PAIR:
      isExpanded = useAppSelector((state) => selectIsPairExpanded(state, selector));
      break;
    case OnOffDataEntityType.FROM_PAIR:
      isExpanded = useAppSelector((state) => selectFromExpanded(state, selector));
      isFullyExpanded = useAppSelector((state) => selectIsFromFullyExpanded(state, selector));
      break;
  }

  return { isExpanded, isFullyExpanded, toggleIsExpanded };
};
