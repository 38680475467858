import { Tooltip } from "../Tooltip/Tooltip";
import checkmark from "src/assets/images/checkmark.svg";
import lock from "src/assets/images/lock.svg";

export const LockButton = ({
  stat,
  confirm,
  active,
  content,
  isSmall,
}: {
  stat: boolean;
  confirm: () => void;
  active: boolean;
  content: string;
  isSmall?: boolean;
}) => {
  return (
    <Tooltip content={content} direction={isSmall ? "right" : "top"}>
      <button
        type="button"
        onClick={confirm}
        disabled={!active}
        className={`rounded-[50%] flex justify-center items-center ${
          isSmall ? "w-[20px] h-[20px]" : "w-[30px] h-[30px]"
        } ${active ? "cursor-pointer" : "cursor-default"} ${stat ? "bg-green" : "bg-lightGray"}`}>
        <img
          className={
            isSmall ? "w-[14px] h-[14px]" : !stat ? "w-[16px] h-[16px]" : "w-[20px] h-[20px]"
          }
          src={!stat && !isSmall ? checkmark : lock}
          alt="Блок"
        />
      </button>
    </Tooltip>
  );
};
