import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { fetchRequest } from "src/helpers/fetchRequest";
import minMaxApi from "src/api/minMax";
import { TBaseCurrency, TMinMaxCurrency, TMinMaxSheet } from "src/store/directionsReducer";
import { Field } from "src/components/FormElements/Field";
import { Popup } from "src/components/Popup/Popup";
import { SadCat } from "src/components/UI/loader/SadCat";
import { ExceptionsBlock } from "./ExceptionsBlock";

type TProps = {
  site: TMinMaxSheet;
  currency: TBaseCurrency;
  item: TMinMaxCurrency | null;
  fetchSheet: () => void;
  closeFn: Dispatch<SetStateAction<boolean>>;
  access: boolean;
};

export const CurrencyForm = ({ site, currency, item, fetchSheet, closeFn, access }: TProps) => {
  const methods = useForm({ mode: "onChange" });
  const [isAddException, setAddException] = useState(false);
  const [isError, setError] = useState(false);
  const dirtyFields = Object.keys(methods.formState.dirtyFields);
  const errors = Object.keys(methods.formState.errors);
  const disabled =
    !!errors.length ||
    !dirtyFields.length ||
    (!!item && !dirtyFields?.map((el) => methods.watch(el) === item[el]).includes(false));

  const updateSite = async (e) => {
    if (+e.min_summ_office <= +e.max_summ_office && +e.min_summ_region <= +e.max_summ_region) {
      const siteCurrencies = site.currencies?.map((el) => el.currency_id);
      const preparedCurrencies = siteCurrencies?.includes(currency.currency_id)
        ? site.currencies?.map((el) =>
            el.currency_id === currency.currency_id ? { ...el, ...e } : el
          )
        : [
            ...site.currencies,
            {
              ...currency,
              ...e,
              fix_course_count: e.fix_course_count || "",
              is_exceptions: false,
            },
          ];
      const payload = [
        {
          ...site,
          currencies: preparedCurrencies,
        },
      ];
      const { response } = await fetchRequest(minMaxApi.updateMinMaxSheet(payload), {
        request: "Обновление данных",
      });
      if (response) {
        closeFn(false);
        await fetchSheet();
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setAddException(false);
  }, [item]);

  return (
    <div className="max-h-[70vh] overflow-y-scroll -mr-[20px] pr-[20px]">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(updateSite)} className="flex flex-col gap-8 text-xs">
          <div className="flex gap-[20px] justify-between font-semibold text-sm">
            <div>{site.site_name}</div>
            <div>{currency.currency_name}</div>
          </div>
          <div className="border-t border-lightGray flex flex-col gap-4 py-8 text-lightFont">
            <div className="flex flex-col gap-4 lg:gap-0 lg:grid lg:grid-cols-2 lg:divide-x divide-lightGray">
              <div className="lg:pr-8 flex flex-col gap-4">
                <div className="font-semibold">ОФИС</div>
                <Field
                  title="MIN"
                  name="min_summ_office"
                  defaultValue={item?.min_summ_office || 0}
                  required
                  disabled={!access}
                  isNumber
                  isPositive
                />
                <Field
                  title="MAX"
                  name="max_summ_office"
                  defaultValue={item?.max_summ_office || 0}
                  required
                  disabled={!access}
                  isNumber
                  isPositive
                />
                <Field
                  title="RESERVE"
                  name="reserve_office"
                  defaultValue={item?.reserve_office || 0}
                  required
                  disabled={!access}
                  isNumber
                  isPositive
                />
              </div>
              <div className="lg:pl-8 flex flex-col gap-4">
                <div className="font-semibold">РЕГИОН</div>
                <Field
                  title="MIN"
                  name="min_summ_region"
                  defaultValue={item?.min_summ_region || 0}
                  required
                  disabled={!access}
                  isNumber
                  isPositive
                />
                <Field
                  title="MAX"
                  name="max_summ_region"
                  defaultValue={item?.max_summ_region || 0}
                  required
                  disabled={!access}
                  isNumber
                  isPositive
                />
                <Field
                  title="RESERVE"
                  name="reserve_region"
                  defaultValue={item?.reserve_region || 0}
                  required
                  disabled={!access}
                  isNumber
                  isPositive
                />
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <Field
                title="FIX"
                name="fix_course_count"
                defaultValue={item?.fix_course_count}
                disabled={!access}
                placeholder="Например: 3 подтверждение сетью или 5 мин"
              />
            </div>
          </div>
          {access && (
            <button
              className={`${
                disabled ? "opacity-30" : ""
              } self-end rounded-sm font-semibold text-lightFont border border-lightGray bg-stale px-[16px] pb-2`}
              disabled={disabled}>
              Сохранить
            </button>
          )}
        </form>
      </FormProvider>
      {access && !!item && (
        <div
          className="flex items-center gap-[10px] cursor-pointer text-lightFont mt-[16px] mb-8 duration-300 hover:opacity-70"
          onClick={() => setAddException(!isAddException)}>
          <div className="flex justify-center items-center bg-stale rounded-sm h-[20px] w-[20px] font-bold pb-4">
            +
          </div>
          <div className="text-xs">Добавить исключение</div>
        </div>
      )}
      {access && isAddException && (
        <ExceptionsBlock
          item={null}
          fetchSheet={fetchSheet}
          sheetId={item?.sheet_id || null}
          access={access}
        />
      )}
      {item?.is_exceptions &&
        item.exception_block?.map((el) => (
          <ExceptionsBlock
            key={el.city_id}
            item={el}
            fetchSheet={fetchSheet}
            sheetId={item?.sheet_id}
            access={access}
          />
        ))}
      {isError && (
        <Popup closeModal={() => setError(false)}>
          <SadCat
            message={`Котики грустят, когда вы выставляете\nMIN больше MAX`}
            setFn={setError}
          />
        </Popup>
      )}
    </div>
  );
};
