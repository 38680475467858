import { useState } from "react";
import { ExceptionVector } from "./ExceptionVector";
import arrow from "src/assets/images/arrow.svg";

export const Exception = ({ item, cities, currencies }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cityName = cities?.find((el) => el.city_id === item.city_id)?.city_name;

  return (
    <div
      className={`rounded-sm border border-stale p-8 bg-bg duration-300 ${
        isOpen ? "max-h-[300px] overflow-y-scroll visibleScroll" : "max-h-[34px] overflow-hidden"
      }`}>
      <div className="flex justify-between items-center mb-8">
        <div>{cityName}</div>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <img
            src={arrow}
            alt="Открыть"
            width={10}
            height={10}
            className={`duration-300 w-[10px] h-[10px] ${isOpen ? "rotate-180" : ""}`}
          />
        </button>
      </div>
      <div className="grid sm:grid-cols-2">
        {item?.data?.map((el) => (
          <ExceptionVector
            key={`${item?.city_id}_${el?.currency_from_id}_${el.currency_to_id}`}
            item={el}
            currencies={currencies}
          />
        ))}
      </div>
    </div>
  );
};
