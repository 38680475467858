import { convertToShortDate } from "src/helpers/date";
import { TBestSite, TGraphFilter, TLastAvailable } from "src/store/directionsReducer";
import { ColorTip } from "src/components/UI/items/ColorTip";

export const LastAvailable = ({
  data,
  filter,
  sites,
}: {
  data: Array<TLastAvailable>;
  filter: TGraphFilter;
  sites: Array<TBestSite>;
}) => {
  const day = 24 * 60 * 60 * 1000;
  const today = +new Date() - day;
  const week = today - day * 7;

  const allFilter = (el) =>
    (filter.alien === "все" ||
      (filter.alien === "наши" && el?.is_our) ||
      (filter.alien === "не наши" && !el?.is_our)) &&
    (!filter.sites.length ||
      !!filter.sites?.find(
        (site) => site.label.toLowerCase() === el.exchanger_name.toLowerCase()
      )) &&
    (!filter.is_on_best_now ||
      !!sites?.find((site) => site.site_name === el.exchanger_name)?.is_on_best_now) &&
    (!filter.time_from.length || +new Date(el?.last_update) >= +new Date(filter.time_from)) &&
    (!filter.time_to.length || +new Date(el?.last_update) <= +new Date(filter.time_to));

  return (
    <div className="relative top-[380px] md:top-0 w-[280px] lg:w-[70%] xl:w-[80%] p-[20px] md:pt-0 pb-[60px] flex flex-col gap-[10px] backdrop-blur-sm rounded-lg">
      <div className="flex items-center flex-wrap lg:flex-nowrap gap-8 text-[10px] bg-bg rounded-lg lg:rounded-t-none py-8 px-[20px] -ml-[20px]">
        <ColorTip title="Наши сайты" color="#33BC9BAA" isCircle />
        <ColorTip title="Не наши сайты" color="#BABAC388" isCircle />
        <ColorTip title="Был сегодня" color="#33BC9B55" />
        <ColorTip title="Был на неделе" color="#F7C57155" />
        <ColorTip title="Более недели нет" color="#BABAC311" />
      </div>
      <div className="flex flex-wrap gap-x-8 gap-y-4">
        {data
          ?.filter((el) => allFilter(el))
          ?.map((el, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor:
                    +new Date(el?.last_update) >= today
                      ? `#33BC9B55`
                      : +new Date(el?.last_update) >= week
                      ? "#F7C57155"
                      : "#BABAC311",
                }}
                className={`w-[280px] flex justify-between items-center rounded-md border border-[#BABAC322] py-4 px-8`}>
                <div className="flex items-center gap-8">
                  <div
                    className={`h-[12px] w-[12px] overflow-hidden flex rounded-full border border-lightGray ${
                      el?.is_our ? "bg-[#33BC9BAA]" : "bg-[#BABAC388]"
                    }`}
                  />
                  <div>{el.exchanger_name}</div>
                </div>
                <div>{convertToShortDate(+new Date(el?.last_update) / 1000)}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
