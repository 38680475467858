import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "./mainReducer";
import bidReducer from "./bidReducer";
import directionsReducer from "./directionsReducer";
import statsReducer from "./statsReducer";
import clusterSlice from "./clusterReducer";
import commonSlice from "./commonReducer";
import tetrisSlice from "./tetrisReduser";
import onOffTimelineSlice from "./onOffTimelineReducer/slice";
import onOffSlice from "./onOffReducer";
import academySlice from "./academyReducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    main: mainReducer,
    bid: bidReducer,
    directions: directionsReducer,
    stats: statsReducer,
    cluster: clusterSlice,
    onOff: onOffSlice,
    onOffTimeline: onOffTimelineSlice,
    common: commonSlice,
    tetris: tetrisSlice,
    onOffNew: onOffTimelineSlice,
    academy: academySlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const dispatch = store.dispatch;

export type StateT = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<StateT> = useSelector;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
