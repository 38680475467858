import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Outlet, useNavigate } from "react-router-dom";
import { fetchRequest } from "src/helpers/fetchRequest";
import { LS, Url } from "src/api/constants";
import controlApi from "src/api/control";
import { setNotific } from "src/store/mainReducer";
import {
  selectRouteMap,
  setControlChats,
  setControlUsers,
  setRouteMap,
} from "src/store/commonReducer";
import { BreadCrumbs } from "src/components/UI/nav/BreadCrumbs";
import { ControlNav } from "./components/ControlNav";
import { ControlFilters } from "./components/ControlFilters";
import { controlRoutes } from "./components/routes";

export const Control = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const page = location.substring(9).split("/");
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");
  const routeMap = useSelector(selectRouteMap);
  const isAdminController = user_access?.includes("control_post");
  const role = localStorage.getItem(LS.ROLE);
  const isMerch = role === "merch";

  const fetchUsers = async () => {
    const { response, error, status } = await fetchRequest(controlApi.getControlUsers());
    if (response) {
      dispatch(setControlUsers(response));
    }
    if (error && status !== 404) {
      dispatch(setNotific({ type: "error", message: error?.statusText, request: "Пользователи" }));
    }
  };

  const fetchChats = async () => {
    const { response, error, status } = await fetchRequest(controlApi.getRegulationsChats());
    if (response) {
      dispatch(setControlChats(response));
    }
    if (error && status !== 404) {
      dispatch(setNotific({ type: "error", message: error?.statusText, request: "Чаты" }));
    }
  };

  useEffect(() => {
    if (!isMerch) {
      fetchUsers();
      fetchChats();
    }
  }, []);

  useEffect(() => {
    if (page?.filter((el) => el !== "")?.length !== routeMap?.length) {
      if (!!page?.length && !!page[0]?.length) {
        let definedRouteMap = [];
        const el1 = Object.entries(controlRoutes)?.find((el) => el[0] === page[0]);
        definedRouteMap = [{ title: el1[1]?.route[0], route: el1[1]?.route[1] }];
        if (!!page[1]?.length) {
          const el2 = Object.entries(controlRoutes[page[0]]?.children)?.find(
            (el) => el[0] === page[1]
          );
          definedRouteMap = [
            ...definedRouteMap,
            { title: el2[1]?.route[0], route: `${el1[1]?.route[1]}${el2[1]?.route[1]}` },
          ];
        }
        dispatch(setRouteMap(definedRouteMap));
      }
    } else if (!page[0]?.length) {
      dispatch(setRouteMap([{ title: "Кошельки", route: Url.CONTROL_WALLETS }]));
      navigate(Url.CONTROL_WALLETS);
    }
  }, [location]);
  return (
    <div className={`h-full w-full flex gap-[20px] mt-[-10px]`}>
      <ControlNav routes={controlRoutes} />
      <div className="h-full w-[30px] md:w-[200px] md:min-w-[200px] " />
      <div
        className={`flex flex-col-reverse ${
          page[1] === "reports" ? "lg:flex-row" : ""
        } w-full justify-between mx-auto pl-[14px] sm:pl-0 sm:ml-0 mr-[70px]`}>
        <div className={`max-w-[300px] sm:max-w-full ${page[0] === "stats" ? "" : "pb-[100px]"}`}>
          <BreadCrumbs routeMap={routeMap} />
          <Outlet context={[isAdminController]} />
        </div>
        {page[1] === "reports" && <ControlFilters />}
      </div>
    </div>
  );
};
