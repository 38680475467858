import { createAsyncThunk } from "@reduxjs/toolkit";
import scheduleApi from "src/api/schedule";
import { fetchRequest } from "src/helpers/fetchRequest";
import { setNotific } from "src/store/mainReducer";
import { setOnOffSites, setOnOffSitesLoading } from "src/store/onOffTimelineReducer/slice";

export const getOnOffSites = createAsyncThunk("onOff/getSites", async (_, { dispatch }) => {
  dispatch(setOnOffSitesLoading(true));

  try {
    const { response, error, status } = await fetchRequest(scheduleApi.getSites());

    if (status === 204) {
      dispatch(setOnOffSites([]));
      dispatch(setOnOffSitesLoading(false));
      return;
    }

    if (error || !response) {
      dispatch(setNotific({ type: "error", message: "Ошибка при получении списка сайтов" }));
      dispatch(setOnOffSites([]));
      dispatch(setOnOffSitesLoading(false));
      return;
    }

    if (!error && response && Array.isArray(response) && response.length > 0) {
      dispatch(setOnOffSites(response));
    }
  } catch (e) {
    dispatch(setNotific({ type: "error", message: "Ошибка при получении списка сайтов" }));
  }

  dispatch(setOnOffSitesLoading(false));
});
