import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBoostSubscription,
  createBoostByRatioSubscription,
} from "src/api/centrifugo/statsCentrifugo";
import { convertToDate, convertToTime } from "src/helpers/date";
import { LS } from "src/api/constants";
import { selectIsOldStats, selectOffset, setIsOldStats } from "src/store/statsReducer";
import { IStat, TRatio } from "../models";
import { Popup } from "src/components/Popup/Popup";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";
import { Ratios } from "./Ratios/Ratios";
import { TopRatios } from "./TopRatios";
import spiral from "src/assets/images/spiral.svg";
import filter from "src/assets/images/filter.svg";
import "src/components/UI/switcher/switcher.scss";

type TProps = {
  last_upd: number;
  isConnect: boolean | "";
  setIsConnect: Dispatch<SetStateAction<boolean | "">>;
  stats: IStat[];
  customCities: Array<number>;
  setCustomCities: Dispatch<SetStateAction<Array<number>>>;
  ratios: TRatio[];
  setRatios: Dispatch<SetStateAction<TRatio[]>>;
};

export const StatsMenu = ({
  last_upd,
  isConnect,
  setIsConnect,
  stats,
  customCities,
  setCustomCities,
  ratios,
  setRatios,
}: TProps) => {
  const dispatch = useDispatch();
  const isOldStats = useSelector(selectIsOldStats);
  const [isOpen, setOpen] = useState(true);
  const [isOpenFilters, openFilters] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [isRatios, setIsRatios] = useState(false);
  const [ratio, setRatio] = useState<TRatio | null>(null);
  const [timer, setTimer] = useState("");
  const isTimerNaN = Number.isNaN(+timer[timer?.length - 1]);
  const offset = useSelector(selectOffset);
  const citiesIds = useMemo(() => stats?.map((el) => el.city_id), [stats]);

  useEffect(() => {
    if (last_upd) {
      const deltaTime = Math.round(+new Date() / 1000) - +last_upd;
      if (deltaTime < 86400) {
        const timerId = setInterval(() => {
          setTimer(convertToTime(Math.round(+new Date() / 1000) - +last_upd));
        }, 1000);
        return () => clearInterval(timerId);
      }
      if (deltaTime >= 86400 && deltaTime < 172800) {
        setTimer(`Более 1 дня`);
      }
      if (last_upd && deltaTime >= 172800) {
        setTimer(`${Math.ceil(deltaTime / 86400)} дня`);
      }
    }
  }, [last_upd]);

  useEffect(() => {
    if (offset > 0) {
      setIsConnect(true);
      const timerOffset = setTimeout(() => {
        setIsConnect(false);
      }, 15500);
      return () => clearTimeout(timerOffset);
    }
  }, [offset.toString()]);

  useEffect(() => {
    if (isOldStats) {
      createBoostSubscription(false, false);
      return () => createBoostSubscription(false, true);
    } else {
      createBoostByRatioSubscription(false, false);
      return () => createBoostByRatioSubscription(false, true);
    }
  }, [isOldStats]);

  return (
    <div className="flex justify-start fixed top-[60px] left-[10px] z-[40]">
      <button
        onClick={() => setOpen(!isOpen)}
        type="button"
        className="w-[20px] bg-lightGray text-white font-black rounded-lg mr-[5px] shadow">
        {isOpen ? `\u276E` : `\u276F`}
      </button>
      <div
        className={`flex justify-start items-center relative top-[0] widthAnimation ${
          isOpen ? "w-fit lg:max-w-[1005px]" : "max-w-[0] w-[0]"
        }`}>
        <div
          className={`table grid grid-cols-[110px_40px_40px_1fr] sm:grid-cols-[150px_40px_40px_1fr] gap-[5px] sm:gap-[10px] items-center bg-white mr-[5px] sm:mr-[10px] relative widthAnimation py-[8px] overflow-hidden ${
            !isOpen ? "max-w-[0]" : "max-w-[400px] sm:max-w-[400px] px-[8px] sm:pl-5"
          }`}>
          <div>
            <div
              className={`text-left sm:whitespace-pre widthAnimation overflow-hidden ${
                last_upd ? "text-[13px] font-medium" : "small-text mt-[7px]"
              } ${!isOpen ? "max-w-[0] w-[0]" : "max-w-[150px] w-[80px] sm:w-[150px]"}`}>
              {last_upd ? convertToDate(last_upd) : "Нет ответа от сервера"}
            </div>
            <div
              className={`small-text text-left hidden sm:block sm:whitespace-pre widthAnimation overflow-hidden ${
                !isOpen ? "max-w-[0] w-[0]" : "max-w-[150px] sm:w-[150px]"
              }`}>
              С последнего обновления:
            </div>
            <div className="flex justify-between items-center">
              <div
                className={`text-left whitespace-pre widthAnimation overflow-hidden ${
                  last_upd && !isTimerNaN
                    ? "text-[14px] sm:text-[16px] font-medium"
                    : "small-text mt-[2px]"
                } ${!isOpen ? "max-w-[0] w-[0]" : "max-w-[150px] w-[64px] sm:w-[150px]"}`}>
                {last_upd ? timer : ""}
              </div>
              <div className="flex justify-end gap-2 sm:gap-4">
                <div
                  className={`flex items-center justify-center h-5 w-5 min-w-[20px] ${
                    isConnect === "" ? "bg-lightGray" : isConnect ? "bg-green" : "bg-red"
                  } rounded-lg`}>
                  <img src={spiral} alt="центрифуга" width={15} height={15} />
                </div>
                <div
                  className="flex items-center justify-center h-5 w-5 min-w-[20px] bg-lightGray hover:opacity-70 text-white text-xs font-black rounded-lg cursor-pointer"
                  onClick={() => setIsInfo(true)}>
                  i
                </div>
              </div>
            </div>
          </div>
          <div
            className={`text-left widthAnimation flex flex-col gap-4 justify-center items-center h-full -mt-2 ${
              !isOpen ? "max-w-[0] w-[0]" : "max-w-[80px]"
            }`}>
            <button
              type="button"
              className="flex justify-center items-center w-[28px] h-[28px] pt-2 rounded-lg bg-stale hover:bg-lightStale"
              onClick={() => openFilters(true)}>
              <img src={filter} alt="фильтр" width={16} height={16} />
            </button>
            <button
              type="button"
              className="w-[28px] h-[28px] rounded-lg bg-stale hover:bg-lightStale text-[9px] font-bold text-lightFont"
              onClick={() => setIsRatios(true)}>
              X / Y
            </button>
          </div>
          <div className="flex flex-col h-full justify-between">
            <div className="text-[8px]">Вернуть старую статистику</div>
            <div className="switcher">
              <label
                style={{ backgroundColor: isOldStats ? "green" : "lightgrey" }}
                className={`switcher__label cursor-pointer hover:opacity-[0.9] widthAnimation ${
                  !isOpen ? "max-w-[0] w-[0]" : "max-w-[80px]"
                }`}>
                <input
                  className={`switcher__check-input`}
                  type="checkbox"
                  checked={isOldStats}
                  onChange={() => {
                    dispatch(setIsOldStats(!isOldStats));
                    localStorage.setItem(LS.OLD_STATS, (!isOldStats)?.toString());
                  }}
                />
                <span className="switcher__checkbox"></span>
              </label>
            </div>
          </div>
          {!isOldStats && (
            <div className="flex flex-col h-full gap-8">
              <div className="text-[8px]">Применить ко всем</div>
              <div className="relative min-w-[100px]">
                <TopRatios ratio={ratio} setRatio={setRatio} ratios={ratios} cities={citiesIds} />
              </div>
            </div>
          )}
        </div>
      </div>
      {isOpenFilters && (
        <Popup closeModal={() => openFilters(false)}>
          <div className="flex flex-col gap-4 text-sm max-h-[60vh] w-[260px] overflow-auto">
            <div className="text-xs font-light mb-8">
              Выберите города, статистику по которым хотите видеть
            </div>
            {stats?.map((el) => (
              <div key={el.city_id} className="flex gap-8">
                <Checkbox
                  checked={!customCities.length || customCities.includes(el.city_id)}
                  onChange={() => {
                    if (customCities.includes(el.city_id)) {
                      setCustomCities(customCities?.filter((city) => city !== el.city_id));
                      localStorage.setItem(
                        LS.STATS_CITIES,
                        customCities?.filter((city) => city !== el.city_id)?.toString()
                      );
                    } else {
                      setCustomCities([...customCities, el.city_id]);
                      localStorage.setItem(
                        LS.STATS_CITIES,
                        [...customCities, el.city_id]?.toString()
                      );
                    }
                  }}
                />
                {el.city_name}
              </div>
            ))}
          </div>
        </Popup>
      )}
      {isRatios && (
        <Popup closeModal={() => setIsRatios(false)}>
          <Ratios ratios={ratios} setRatios={setRatios} />
        </Popup>
      )}
      {isInfo && (
        <Popup closeModal={() => setIsInfo(false)}>
          <div className="text-[11px] text-left min-w-[290px] max-w-[350px] flex flex-col gap-[10px]">
            <div className="grid grid-cols-[40px_1fr] gap-[10px]">
              <svg
                width="18px"
                height="18px"
                version="1.1"
                viewBox="0 0 700 500"
                fill="#282c34"
                xmlns="http://www.w3.org/2000/svg">
                <path d="m420.15 489.77c0 38.656-31.336 69.996-69.992 69.996s-69.992-31.34-69.992-69.996c0-38.652 31.336-69.992 69.992-69.992s69.992 31.34 69.992 69.992" />
                <path d="m420.15 280.04c0 38.652-31.336 69.992-69.992 69.992s-69.992-31.34-69.992-69.992c0-38.656 31.336-69.992 69.992-69.992s69.992 31.336 69.992 69.992" />
                <path d="m420.15 70.301c0 38.656-31.336 69.992-69.992 69.992s-69.992-31.336-69.992-69.992 31.336-69.992 69.992-69.992 69.992 31.336 69.992 69.992" />
              </svg>
              <div>Ускорение мониторинга статистики для вектора выключено</div>
            </div>
            <div className="grid grid-cols-[40px_1fr] gap-[10px]">
              <svg
                width="20px"
                height="20px"
                version="1.1"
                viewBox="0 0 700 500"
                fill="#FF8A00"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m140.62 243.88 64.961 26.938h-0.003906c-7.3242 18.492-13.164 37.535-17.469 56.953l-2.4648 11.199
                    93.129 93.184 11.199-2.4062v-0.003906c19.418-4.3047 38.461-10.145 56.953-17.469l26.938 64.961-0.003906-0.003906c0.53516
                    1.3086 1.6953 2.2578 3.082 2.5312 1.3867 0.26953 2.8164-0.17578 3.8047-1.1875l40.711-40.711h0.003906c13.434-13.434
                    20.555-31.926 19.598-50.902l-1.4531-26.152c75.375-55.664 151.64-153.27 172.42-323.62
                    1.1953-8.6562-1.7227-17.375-7.8867-23.574-6.1602-6.1953-14.863-9.1562-23.527-8.0078-170.3 20.941-267.96 97.27-323.62
                    172.42l-26.098-1.3438c-18.934-1.0312-37.422 5.9883-50.902 19.32l-40.711 40.711c-1.1484 0.97656-1.6875 2.4922-1.4102
                    3.9766 0.27734 1.4805 1.3281 2.6992 2.7539 3.1914zm261.18-99.23c9.4609-9.4297 22.277-14.715 35.633-14.699 13.359 0.015625
                    26.164 5.332 35.602 14.781s14.742 22.262 14.742 35.617c0 13.355-5.3047 26.168-14.742 35.617-9.4375 9.4492-22.242 14.77-35.602
                    14.781-13.355 0.015625-26.172-5.2695-35.633-14.699-9.4922-9.457-14.824-22.301-14.824-35.699 0-13.398 5.332-26.242
                    14.824-35.699zm-273.34 294.95c-10.781-3.7852-22.355-4.7109-33.602-2.6914-2.0312"
                />
              </svg>
              <div>
                Ускорение статистики для вектора включено. Не активируйте его на всех векторах.
                Сервис стабильно работает при 10-15 включенных направлениях
              </div>
            </div>
            <div className="grid grid-cols-[40px_1fr] gap-[10px]">
              <div
                className={`flex items-center justify-center h-5 w-5 min-w-[20px] bg-lightGray rounded-lg`}>
                <img src={spiral} alt="центрифуга" width={15} height={15} />
              </div>
              <div>
                Соединение не установлено. Если длится более 5 секунд - <b>сообщите об ошибке</b>
              </div>
            </div>
            <div className="grid grid-cols-[40px_1fr] gap-[10px]">
              <div
                className={`flex items-center justify-center h-5 w-5 min-w-[20px] bg-green rounded-lg`}>
                <img src={spiral} alt="центрифуга" width={15} height={15} />
              </div>
              <div>Стабильное соединение. Данные приходят с интервалом не более 15 секунд</div>
            </div>
            <div className="grid grid-cols-[40px_1fr] gap-[10px]">
              <div
                className={`flex items-center justify-center h-5 w-5 min-w-[20px] bg-red rounded-lg`}>
                <img src={spiral} alt="центрифуга" width={15} height={15} />
              </div>
              <div>Нестабильное соединение. Попробуйте перезагрузить страницу.</div>
            </div>
            <div>
              Время и таймер показывают время выгрузки данных с Беста до их обработки и разницу
              этого времени с текущим
            </div>
            <div>Обработка на текущий момент может занимать от 20 секунд до 3 минут</div>
            <div>
              Если <b>с последнего обновления</b> прошло более 5 мин - <b>сообщите об ошибке</b>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};
