import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { LS, Url } from "src/api/constants";
import addApi from "src/api/add";
import switchApi from "src/api/onOff";
import {
  selectAllCurrencies,
  setAllCurrencies,
  TGlobalCurrency,
} from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { CurrencyRow } from "src/components/Rows/CurrencyRow";

type TProps = {
  access: boolean;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const Currencies = ({ setMessage, setStatus, setIsLoading, access }: TProps) => {
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");
  const isSoonAccess = user_access?.includes("admin_post");
  const dispatch = useDispatch();
  const [isPopup, setPopup] = useState(false);
  const [isGood, setIsGood] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState<TGlobalCurrency>();
  const currencies = useSelector(selectAllCurrencies);

  const fetchGlobals = async () => {
    const { response } = await fetchRequest(switchApi.getGlobals(), {
      request: "Глобальные данные",
    });
    if (response) {
      dispatch(setAllCurrencies(response.currencies));
    }
  };

  const deleteCurrency = async () => {
    setIsLoading(true);
    const { response, error } = await fetchRequest(
      addApi.deleteCurrency(selectedCurrency?.currency_id),
      { request: "Удаление валют" }
    );
    if (response) {
      setMessage(`${selectedCurrency?.currency_name} успешно удалена`);
      setStatus(true);
      await fetchGlobals();
    }
    if (error) {
      setMessage("Не удалось удалить валюту");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      setPopup(false);
    }, 4600);
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsGood(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full sm:w-[600px] duration-300 flex flex-col gap-[10px] font-light pl-[20px] pb-[100px]">
      {currencies?.map((el) => (
        <CurrencyRow
          key={el?.currency_id}
          currencyName={el?.currency_name}
          currencyType={el?.type}
          page={Url.ADD}
          isSoonAccess={isSoonAccess}
          access={access}
          onDeleteClick={() => {
            setSelectedCurrency(el);
            setPopup(true);
          }}
        />
      ))}
      {isPopup && (
        <Popup closeModal={() => setPopup(false)} noPadding>
          <div className="table-header px-[30px] flex justify-center">УДАЛИТЬ ВАЛЮТУ</div>
          <div className="px-[30px] py-[20px] text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nвалюту `}
              <b>{selectedCurrency?.currency_name}</b> безвозвратно?
            </div>
            <button className="prime-button-sm" onClick={deleteCurrency}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
      {!isGood && !currencies?.length && <Emptiness message={"Ошибка сервиса"} />}
    </div>
  );
};
