import { useMemo, useState } from "react";
import { defaultDateValue, translateToDay, translateToMonth } from "src/helpers/date";
import { fetchRequest } from "src/helpers/fetchRequest";
import clusterApi from "src/api/cluster";
import { TemplateCard } from "./TemplateCard";
import { QueueCard } from "./QueueCard";
import split_right from "src/assets/images/splitRight.svg";

export const Templates = ({
  templates,
  setTemplates,
  setLeftTemplate,
  setRightTemplate,
  openTemplates,
  setLeftTemplateData,
  setRightTemplateData,
  username,
}) => {
  const [addDate, setAddDate] = useState(""); // 2023-07-11
  const [isLoading, setIsLoading] = useState(false);
  const [isSplitRight, setIsSplitRight] = useState(false);
  const today = new Date();
  const lastDateOfMonth = useMemo(
    () => new Date(today?.getFullYear(), today?.getMonth() + 1, 0).getDate(),
    []
  );
  const week = useMemo(
    () =>
      Array.from(Array(7))?.map((el, index) => ({
        label:
          today?.getDate() + index <= lastDateOfMonth
            ? `${translateToDay((today.getDay() + index) % 7)} ${
                today?.getDate() + index
              } ${translateToMonth(today.getMonth())}`
            : `${translateToDay((today.getDay() + index) % 7)} ${
                (today?.getDate() + index) % lastDateOfMonth
              } ${translateToMonth(today.getMonth() + 1)}`,
        value: defaultDateValue(
          new Date(today?.getFullYear(), today?.getMonth(), today.getDate() + index)
        ),
      })),
    []
  );

  const emptyQueue = {
    title: "",
    id: -1,
  };

  const updateQueue = async (data) => {
    setIsLoading(true);
    const payload = {
      updater: username,
      data: [data],
    };
    const { response } = await fetchRequest(clusterApi.updateQueue(payload), {
      request: "Шаблоны",
    });
    if (response) {
      const preparedItems = templates?.map((el) =>
        el.id === data?.id
          ? {
              ...el,
              dates: !!response?.data[0]?.dates_to_create?.length
                ? [...el.dates, ...response.data[0].dates_to_create]
                : !!response?.data[0]?.dates_to_delete?.length
                ? el.dates?.filter((el) => !response?.data[0]?.dates_to_delete?.includes(el))
                : el.dates,
            }
          : el
      );
      setTemplates(preparedItems);
      setAddDate("");
    }
    setIsLoading(false);
  };

  const deleteTemplate = async (id) => {
    setIsLoading(true);
    const { response } = await fetchRequest(clusterApi.deleteTemplate(id), {
      request: "Удаление шаблона",
    });
    if (response) {
      setTemplates(templates?.filter((el) => el.id !== id));
    }
    setIsLoading(false);
  };

  return (
    <div className="flex-wrap flex-col divide-y divide-stale font-light text-sm text-center w-[270px] p-[20px] -mr-[10px] sm:w-[510px] lg:w-[750px] xl:w-[990px] 2xl:w-[1235px] max-h-[80vh] overflow-auto">
      <div className="flex flex-col gap-[16px] pt-[10px] pb-[20px]">
        <div className="relative flex items-center justify-center font-semibold text-lightFont">
          Шаблоны
          <div className="absolute right-[10px] flex gap-2 rounded-sm bg-stale p-2 w-fit text-[10px] text-lightFont">
            <button
              type="button"
              className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px] pt-4 duration-300 hover:shadow-inset ${
                isSplitRight ? "bg-[#B2FFDEAA]" : "bg-bg"
              }`}
              onClick={() => {
                setAddDate("");
                setIsSplitRight(!isSplitRight);
              }}>
              <img src={split_right} alt="открыть справа" width={16} height={16} />
            </button>
          </div>
        </div>
        <div
          className={`relative flex flex-wrap gap-[20px] border rounded-md p-8 -m-[9px] -mr-0 ${
            !!addDate.length ? "border-[#72E5AE55] bg-glass-select" : "border-bg"
          }`}>
          {!!addDate?.length && (
            <button
              type="button"
              className={`absolute -top-8 -right-4 flex justify-center items-center rounded-full shadow-base text-xs px-8 pb-px bg-bg text-lightFont hover:opacity-80 z-20`}
              onClick={() => setAddDate("")}>
              {`skip >>`}
            </button>
          )}
          {templates?.map((el, index) => (
            <TemplateCard
              key={`${index}_template`}
              item={el}
              addDate={addDate}
              updateQueue={updateQueue}
              setTemplateData={isSplitRight ? setRightTemplateData : setLeftTemplateData}
              setActiveTemplate={isSplitRight ? setRightTemplate : setLeftTemplate}
              openTemplates={openTemplates}
              deleteTemplate={deleteTemplate}
              isLoading={isLoading}
              isSplitRight={isSplitRight}
            />
          ))}
          {!addDate.length && (
            <TemplateCard
              openTemplates={openTemplates}
              setTemplateData={isSplitRight ? setRightTemplateData : setLeftTemplateData}
              setActiveTemplate={isSplitRight ? setRightTemplate : setLeftTemplate}
              addDate={addDate}
              updateQueue={updateQueue}
              deleteTemplate={deleteTemplate}
              isLoading={isLoading}
              isSplitRight={isSplitRight}
              item={{
                title: "",
                id: -1,
              }}
            />
          )}
        </div>
      </div>
      {!!templates?.length && (
        <div className="relative flex flex-col gap-[16px] pt-[20px] pb-[10px]">
          {isSplitRight && (
            <div className="absolute top-4 -left-[15px] w-[calc(100%+20px)] h-[calc(100%+10px)] bg-stale z-20 rounded-sm" />
          )}
          <div className="font-semibold text-lightFont">Очередь</div>
          <div className="flex flex-wrap gap-[20px]">
            {week?.map((el, index) => (
              <QueueCard
                key={`${index}_template`}
                index={index}
                date={el}
                item={
                  templates?.find((temp) =>
                    temp.dates?.includes(el?.value.toString().split("T")[0])
                  ) || emptyQueue
                }
                isLoading={isLoading}
                setActiveTemplate={isSplitRight ? setRightTemplate : setLeftTemplate}
                openTemplates={openTemplates}
                updateQueue={updateQueue}
                addDate={addDate}
                setAddDate={setAddDate}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
