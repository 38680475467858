import { Dispatch, SetStateAction } from "react";
import { setTimelineByCentrifugo } from "src/api/centrifugo/timelineCentrifugo";
import { timeToSeconds } from "src/helpers/date";
import {
  TTimelineVector,
  TTimelineSite,
  TTimelineDeletePayload,
  TTimeline,
} from "src/store/clusterReducer";

export const getVectorStr = (vectorEl: TTimelineVector) => {
  return `${vectorEl?.currency_from_id}_${vectorEl?.currency_to_id}_${vectorEl?.percent}`;
};

export const getSiteStr = (siteEl: TTimelineSite | TTimelineDeletePayload) => {
  return `${siteEl?.site_id}_${timeToSeconds(siteEl?.start_time)}_${timeToSeconds(
    siteEl?.end_time
  )}`;
};

export const checkSite = (
  response: Array<TTimeline> | null,
  vector: TTimelineVector,
  site: TTimelineSite
): boolean => {
  return !!response
    ? !response[0]?.vectors
        ?.find((deletedVector) => getVectorStr(deletedVector) === getVectorStr(vector))
        ?.sites?.find((deletedSite) => getSiteStr(deletedSite) === getSiteStr(site))
    : site?.is_locked;
};

export const submit = async ({ item, items, isTemplate, setTemplateData }: TTimelineSubmit) => {
  if (isTemplate) {
    setTemplateData(items?.map((city) => (city.city_id === item?.city_id ? item : city)));
  } else {
    await setTimelineByCentrifugo(item, item?.city_id);
  }
};

export const submitAll = async ({
  data,
  isTemplate,
  setTemplateData,
}: {
  data: TTimeline[];
  isTemplate: boolean;
  setTemplateData: Dispatch<SetStateAction<TTimeline[]>>;
}) => {
  if (isTemplate) {
    setTemplateData(data);
  } else {
    data?.forEach((el) => setTimelineByCentrifugo(el, el.city_id));
  }
};

export const clearAction = async ({
  items,
  username,
  actionsCount,
  isTemplate,
  setTemplateData,
  submitFn,
}: {
  items: TTimeline[];
  username: string;
  actionsCount: number;
  isTemplate: boolean;
  setTemplateData: Dispatch<SetStateAction<TTimeline[]>>;
  submitFn: (payload: TTimelineSubmit) => void;
}) => {
  const currentItem = items?.find(
    (cityEl) =>
      !!cityEl?.vectors?.find(
        (vectorEl) =>
          !!vectorEl?.sites?.find(
            (siteEl) => siteEl?.creator === username && siteEl?.action === actionsCount
          )
      )
  );
  if (!!currentItem) {
    const preparedItem = {
      ...currentItem,
      vectors: currentItem.vectors?.map((vector) =>
        !!vector?.sites?.find(
          (siteEl) => siteEl?.creator === username && siteEl?.action === actionsCount
        )
          ? {
              ...vector,
              sites: vector?.sites?.filter(
                (site) => !(site?.creator === username && site?.action === actionsCount)
              ),
            }
          : vector
      ),
    };
    await submitFn({
      item: preparedItem,
      items: items,
      isTemplate: isTemplate,
      setTemplateData: setTemplateData,
    });
  }
};

export const decreaseActionsCount = async ({
  items,
  username,
  actionsCount,
  setActionsCount,
  isTemplate,
  setTemplateData,
}: {
  items: TTimeline[];
  username: string;
  actionsCount: number;
  setActionsCount: Dispatch<SetStateAction<number>>;
  isTemplate: boolean;
  setTemplateData: Dispatch<SetStateAction<TTimeline[]>>;
}) => {
  clearAction({
    items,
    username,
    actionsCount,
    isTemplate,
    setTemplateData,
    submitFn: submit,
  });
  setActionsCount(actionsCount - 1);
};

export type TTimelineSubmit = {
  item: TTimeline;
  items: TTimeline[];
  isTemplate: boolean;
  setTemplateData: Dispatch<SetStateAction<TTimeline[]>>;
};
