import { getPairGroup } from "src/helpers/onOffTimeline/getPairGroup";
import { ICity, ICurrencyPair, SelectIsEditedProps } from "src/types/OnOffTimeline/common";
import { OnOffDataEntityType } from "src/types/OnOffTimeline/store";

export enum CheckIsEditedLevel {
  CITY = "city",
  PAIR = "pair",
}

export interface CheckIsEditedBase {
  level?: CheckIsEditedLevel;
  filterFn: (pair: ICurrencyPair | void) => boolean;
}

export interface CheckIsEditedCity extends CheckIsEditedBase {
  level?: CheckIsEditedLevel.CITY;
  data: ICity;
}

export interface CheckIsEditedPair extends CheckIsEditedBase {
  level?: CheckIsEditedLevel.PAIR;
  data: ICurrencyPair;
}

export const checkIsOnOffEntityEdited = ({
  level,
  data,
  filterFn,
}: CheckIsEditedCity | CheckIsEditedPair) => {
  if (!level) {
    level = CheckIsEditedLevel.CITY;
  }

  if (level === CheckIsEditedLevel.CITY) {
    const pairs = data.currency_pairs.filter(filterFn);

    return pairs.some((pair) =>
      checkIsOnOffEntityEdited({ level: CheckIsEditedLevel.PAIR, data: pair, filterFn })
    );
  }

  if (level === CheckIsEditedLevel.PAIR) {
    return data.sites.some(
      (site) =>
        site.unsubmitted_segments_best?.length > 0 || site.unsubmitted_segments_site?.length > 0
    );
  }

  return false;
};

export const checkIsOnOffEntityNew = ({
  level,
  data,
  filterFn,
}: CheckIsEditedCity | CheckIsEditedPair) => {
  if (!level) {
    level = CheckIsEditedLevel.CITY;
  }

  if (level === CheckIsEditedLevel.CITY) {
    const pairs = data.currency_pairs.filter(filterFn);

    return pairs.every((pair) =>
      checkIsOnOffEntityNew({ level: CheckIsEditedLevel.PAIR, data: pair, filterFn })
    );
  }

  if (level === CheckIsEditedLevel.PAIR) {
    return data.sites.every(
      (site) =>
        site.segments_best.length === 0 &&
        site.segments_site.length === 0 &&
        (site.unsubmitted_segments_best?.length > 0 || site.unsubmitted_segments_site?.length > 0)
    );
  }

  return false;
};

export const getFilterFn = (props: SelectIsEditedProps) => {
  const { type } = props;
  if (type === OnOffDataEntityType.CITY) {
    return () => true;
  } else if (type === OnOffDataEntityType.GROUP) {
    return (pair: ICurrencyPair) => {
      const { groupName } = props;
      const group = getPairGroup(pair);
      return groupName === group;
    };
  } else if (type === OnOffDataEntityType.FROM_PAIR) {
    return (pair: ICurrencyPair) => pair.currency_from.currency_name === props.from.currency_name;
  } else if (type === OnOffDataEntityType.PAIR) {
    return (pair: ICurrencyPair) =>
      pair.currency_from.currency_name === props.from.currency_name &&
      pair.currency_to.currency_name === props.to.currency_name;
  }
};
