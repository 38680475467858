import { Dispatch, SetStateAction } from "react";
import { Popup } from "src/components/Popup/Popup";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";
import { currencyFilter } from "src/pages/Tetris/MiniTetris/components/currencyFilter";
import { TAvailableDirectionsTypes } from "src/store/onOffReducer";

export const Settings = ({
  close,
  anavailableDirections,
  setAnavailableDirections,
}: {
  close: () => void;
  anavailableDirections: TAvailableDirectionsTypes[];
  setAnavailableDirections: Dispatch<SetStateAction<TAvailableDirectionsTypes[]>>;
}) => {
  const haveAvailable = !!anavailableDirections?.filter((el) => el.available)?.length;

  const setChecked = (item: TAvailableDirectionsTypes) => {
    const arr = anavailableDirections?.map((el) =>
      el.from_type === item.from_type && el.to_type === item.to_type
        ? {
            ...el,
            available: !el.available,
          }
        : el
    );
    setAnavailableDirections(arr);
  };

  return (
    <Popup closeModal={close}>
      <div className="min-w-[280px] max-w-[300px] text-xs text-lightFont flex flex-col gap-[16px]">
        <b className="text-sm text-center pb-4">Настройки</b>
        <span>Группы направлений, недоступные для создания:</span>
        <div className="flex flex-col gap-4">
          {anavailableDirections
            ?.filter((el) => !el.available)
            ?.map((el, index) => {
              return <DirectionRow key={index} el={el} setChecked={setChecked} />;
            })}
        </div>
        {haveAvailable && (
          <>
            <span>Вы включили:</span>
            <div className="flex flex-col gap-4">
              {anavailableDirections
                ?.filter((el) => el.available)
                ?.map((el, index) => {
                  return <DirectionRow key={index} el={el} setChecked={setChecked} />;
                })}
            </div>
          </>
        )}
        <span className="whitespace-pre-line">
          {`Значения сохранятся до обновления страницы. После обновления - вернутся к дефолтным.\nПеред тем, как разрешать изменение дефолтных настроек, убедитесь, что договоренности существуют`}
        </span>
      </div>
    </Popup>
  );
};

const DirectionRow = ({
  el,
  setChecked,
}: {
  el: TAvailableDirectionsTypes;
  setChecked: (item: TAvailableDirectionsTypes) => void;
}) => {
  const from = currencyFilter?.find((filterEl) => filterEl.type === el.from_type);
  const to = currencyFilter?.find((filterEl) => filterEl.type === el.to_type);

  return (
    <div className="flex items-center gap-4">
      <div className={`flex-1 flex items-center justify-center py-4 shadow-base`}>{from?.icon}</div>
      <div className={`flex-1 flex items-center justify-center py-4 shadow-base`}>{to?.icon}</div>
      <div className="flex items-center ml-8">
        <Checkbox checked={el.available} onChange={() => setChecked(el)} />
      </div>
    </div>
  );
};
