import { useDispatch, useSelector } from "react-redux";
import { LS } from "src/api/constants";
import { selectLSTops, setLSTops } from "src/store/statsReducer";
import trophy from "src/assets/images/trophy.svg";

type TProps = {
  top: 1 | 2;
  cityId: number;
};

export const TopButtons = ({ top, cityId }: TProps) => {
  const dispatch = useDispatch();
  const LSTops = useSelector(selectLSTops);

  const setTop = (top: 1 | 2) => {
    const savedTop = LSTops?.find((topEl) => topEl?.city_id === cityId);
    let preparedRatios = [];
    if (!!savedTop) {
      preparedRatios = LSTops?.map((topEl) =>
        topEl.city_id === cityId
          ? {
              ...topEl,
              top: top,
            }
          : topEl
      );
    } else if (!!LSTops) {
      preparedRatios = [
        ...LSTops,
        {
          city_id: cityId,
          top: top,
        },
      ];
    } else {
      preparedRatios = [
        {
          city_id: cityId,
          top: top,
        },
      ];
    }
    dispatch(setLSTops(preparedRatios));
    localStorage.setItem(
      LS.STATS_RATIOS,
      preparedRatios?.map((ratioEl) => `${ratioEl.city_id}_${ratioEl.ratio_id}`)?.join(",")
    );
  };

  return (
    <div className="grid grid-cols-2 w-[100px] lg:w-full gap-2 text-xs text-lightFont relative top-2">
      <button
        type="button"
        className={`flex items-center justify-center gap-4 w-full h-[20px] px-4 rounded-l-md bg-white hover:opacity-80 ${
          top === 1 ? "font-extrabold" : "opacity-50"
        }`}
        onClick={() => setTop(1)}>
        <img src={trophy} alt="топ" width={10} height={10} className="relative top-px" />1
      </button>
      <button
        type="button"
        className={`flex items-center justify-center gap-4 w-full h-[20px] px-4 rounded-r-md bg-white hover:opacity-80 ${
          top === 2 ? "font-extrabold" : "opacity-50"
        }`}
        onClick={() => setTop(2)}>
        <img src={trophy} alt="топ" width={10} height={10} className="relative top-px" />2
      </button>
    </div>
  );
};
