import { useState } from "react";
import { useFormContext } from "react-hook-form";
import eye from "../assets/images/eye.svg";

const Input = ({ label, type, placeholder, name, error, validation }) => {
  const { register } = useFormContext();

  const [inputType, changeType] = useState(type);
  return (
    <div className="relative flex flex-col gap-8 mt-30">
      <label htmlFor={name} className="text-blue text-left font-bold text-st">
        {label}
      </label>
      <input
        className="relative color-black rounded-button py-12 px-24 border border-strokeGray !outline-none hover:border-grayDark focus:border-grayDark"
        type={inputType}
        name={name}
        placeholder={placeholder}
        {...register(name, validation)}
      />
      {type === "password" && (
        <button
          type="button"
          onClick={() => {
            changeType(inputType === "password" ? "text" : "password");
          }}>
          <img className="absolute right-[8%] top-[50%]" src={eye} alt="eye" />
        </button>
      )}

      {error && <small className="color-red">{error}</small>}
    </div>
  );
};

export default Input;
