import Select, { createFilter } from "react-select";
import { useSelector } from "react-redux";
import { selectAllCities, selectGlobalCurrencies } from "src/store/directionsReducer";
import { useSearchParams } from "react-router-dom";
import { selectOnOffIsLoading } from "src/store/onOffTimelineReducer/selectors";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { getOnOffData } from "src/store/onOffTimelineReducer/thunks/getOnOffData";
import { useAppDispatch } from "src/store/store";
import { selectFilter } from "src/helpers/formHelpers";

interface SelectOption {
  label: string;
  value: string | number;
}

export const getOnOffGetQuery = (params: URLSearchParams) => {
  const site_names = params.get(OnOffFiltersType.SITES);
  const city_codes = params.get(OnOffFiltersType.CITIES);
  const currency_from_codes = params.get(OnOffFiltersType.FROM);
  const currency_to_codes = params.get(OnOffFiltersType.TO);

  return {
    site_names,
    city_codes,
    currency_from_codes,
    currency_to_codes,
  };
};

export enum OnOffFiltersType {
  CITIES = "cities",
  FROM = "from",
  TO = "to",
  SITES = "sites",
}

const allFilters = [OnOffFiltersType.CITIES, OnOffFiltersType.FROM, OnOffFiltersType.TO];

export const TimelineFilters = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const isLoading = useSelector(selectOnOffIsLoading);

  const sitesFromParams = searchParams.get(OnOffFiltersType.SITES)?.split(",") || [];

  const globalCurrencies = useSelector(selectGlobalCurrencies);

  const currenciesFromOptions = globalCurrencies.map((item) => ({
    label: item.currency_name,
    value: item.currency_id,
  }));

  const currenciesToOptions = globalCurrencies.map((item) => ({
    label: item.currency_name,
    value: item.currency_id,
  }));

  const citiesOptions = useSelector(selectAllCities).map((city) => ({
    label: city.city_name,
    value: city.city_code,
  }));

  const manageSearchParams = (param: OnOffFiltersType, values: string[]) => {
    if (values && values.length > 0) {
      searchParams.set(
        param,
        values.map((val) => (val === "USDTERC" || val === "USDTTRC" ? val + "20" : val)).join(",")
      );
    } else {
      searchParams.delete(param);
    }

    setSearchParams(searchParams);
  };

  const getSelectValue = (field: OnOffFiltersType): SelectOption[] => {
    const valuesFromParams = searchParams.get(field)?.split(",") || [];

    switch (field) {
      case "cities":
        return citiesOptions.filter((option) => valuesFromParams.includes(option.value));
      case "from":
        return currenciesFromOptions.filter((option) =>
          valuesFromParams
            .map((item) => (item.includes("20") ? item.replace("20", "") : item))
            .includes(option.label)
        );
      case "to":
        return currenciesToOptions.filter((option) =>
          valuesFromParams
            .map((item) => (item.includes("20") ? item.replace("20", "") : item))
            .includes(option.label)
        );
    }
  };

  const isAnyFiltersSelected = allFilters
    .map((item) => {
      const filter = searchParams.get(item);

      return filter && filter.split(",").length > 0;
    })
    .includes(true);

  const resetFilters = () => {
    allFilters.forEach((filter) => {
      searchParams.delete(filter);
    });

    setSearchParams(searchParams);
  };

  const submit = () => {
    const query = getOnOffGetQuery(searchParams);
    dispatch(getOnOffData(query));
  };

  const getDataDisabled = isLoading || !sitesFromParams.length;

  return (
    <div className="w-full flex flex-col items-start gap-2 mt-[5px]">
      <h3>Фильтры для получения данных</h3>
      <div className="flex gap-8 w-full items-center">
        <Select
          filterOption={createFilter(selectFilter)}
          className="min-w-[150px] max-w-[30%] text-xs text-left"
          value={getSelectValue(OnOffFiltersType.CITIES)}
          options={citiesOptions}
          onChange={(e) =>
            manageSearchParams(
              OnOffFiltersType.CITIES,
              e.map((item) => item.value as string)
            )
          }
          placeholder="Город"
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          isMulti
        />
        <Select
          filterOption={createFilter(selectFilter)}
          className="min-w-[120px] max-w-[30%] text-xs text-left"
          value={getSelectValue(OnOffFiltersType.FROM)}
          options={currenciesFromOptions}
          onChange={(e) =>
            manageSearchParams(
              OnOffFiltersType.FROM,
              e.map((item) => item.label)
            )
          }
          placeholder="from"
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          isMulti
        />
        <Select
          filterOption={createFilter(selectFilter)}
          className="min-w-[120px] max-w-[30%] text-xs text-left"
          value={getSelectValue(OnOffFiltersType.TO)}
          options={currenciesToOptions}
          onChange={(e) =>
            manageSearchParams(
              OnOffFiltersType.TO,
              e.map((item) => item.label)
            )
          }
          placeholder="to"
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          isMulti
        />
        {isAnyFiltersSelected && (
          <button
            type="button"
            className={`text-red/70 bg-[#D7443E11] border-[#D7443E33] w-fit text-xs px-[16px] h-[26px] border font-semibold rounded-sm duration-300 whitespace-pre ${
              isLoading ? "opacity-30" : ""
            }`}
            disabled={isLoading}
            onClick={resetFilters}>
            Сбросить фильтры
          </button>
        )}
        <Tooltip
          content="Необходимо выбрать хотя бы 1 сайт"
          show={!sitesFromParams.length}
          delay={1}>
          <button
            type="button"
            className={`disabled:text-white disabled:border-transparent cursor-pointer bg-[#BBFFDA] w-fit text-xs px-[16px] h-[26px] border border-[#3BC57AAA] text-[#2BB56A] font-semibold rounded-sm duration-300 whitespace-pre ${
              isLoading ? "opacity-30" : ""
            }`}
            disabled={getDataDisabled}
            onClick={submit}>
            Получить данные
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
