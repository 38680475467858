import { memo } from "react";
import { useSelector } from "react-redux";
import { cn } from "src/helpers/cn";
import { useOnOffToggleExpanded } from "src/hooks/useOnOffToggleExpanded";
import { Accordion } from "src/pages/Panel/OnOff/OnOffTimeline/components/common/Accordion";
import CurrencyLabel from "src/pages/Panel/OnOff/OnOffTimeline/components/common/CurrencyLabel";
import { SiteWithSegments } from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/SiteWithSegments";
import {
  confirmDelete,
  confirmUndo,
} from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/TimelineData";
import { CurrencyWithSites, ICity, OnOffItemToDeleteType } from "src/types/OnOffTimeline/common";
import { OnOffDataEntityType } from "src/types/OnOffTimeline/store";
import { TGlobalCurrency } from "src/store/directionsReducer";
import {
  selectOnOffDeleteMode,
  selectOnOffIsEdited,
  selectOnOffIsNew,
} from "src/store/onOffTimelineReducer/selectors";
import {
  onOffTimeLineUndo,
  setOnOffConfirmation,
  setOpenAllSites,
  setSelectedItem,
} from "src/store/onOffTimelineReducer/slice";
import { deleteOnOffEntity } from "src/store/onOffTimelineReducer/thunks/deleteOnOffEntity";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { DeleteConfirmText } from "src/pages/Panel/OnOff/OnOffTimeline/components/popups/OnOffConfirmPopup";
import { Boxes } from "lucide-react";

interface CurrencyToWithSitesProps {
  data: CurrencyWithSites;
  city: Partial<ICity>;
  groupName: string;
  currencyFrom: TGlobalCurrency;
}

export const CurrencyToWithSites = memo(
  ({ data, city, groupName, currencyFrom }: CurrencyToWithSitesProps) => {
    const dispatch = useAppDispatch();

    const deleteMode = useSelector(selectOnOffDeleteMode);

    const label = `${city.city_name} ${currencyFrom.currency_name}-${data.currency.currency_name}?`;
    const name = `${city.city_code}_${groupName}_${currencyFrom.currency_name}_${data.currency.currency_name}`;

    const onOpenAllSites = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(setOpenAllSites(true));
      dispatch(
        setSelectedItem({
          cityCode: city.city_code,
          currencyFrom,
          currencyTo: data.currency,
          siteName: data.sites[0].site_name,
        })
      );
    };

    const { isExpanded, toggleIsExpanded } = useOnOffToggleExpanded({
      type: OnOffDataEntityType.PAIR,
      selector: name,
    });

    const isNew = useAppSelector((state) =>
      selectOnOffIsNew(state, {
        type: OnOffDataEntityType.PAIR,
        cityCode: city.city_code,
        from: currencyFrom,
        to: data.currency,
      })
    );
    const isEdited = useAppSelector((state) =>
      selectOnOffIsEdited(state, {
        type: OnOffDataEntityType.PAIR,
        cityCode: city.city_code,
        from: currencyFrom,
        to: data.currency,
      })
    );

    const onDelete = () => {
      dispatch(
        setOnOffConfirmation({
          title: confirmDelete,
          subtitle: label,
          content: (
            <DeleteConfirmText text="Удаленные сайты сохранят свои последние данные, которые будут доступны здесь" />
          ),
          cb: () =>
            dispatch(
              deleteOnOffEntity({
                type: OnOffItemToDeleteType.PAIR,
                name: `${city.city_code}_${groupName}_${currencyFrom.currency_name}_${data.currency.currency_name}`,
                isNew,
              })
            ),
        })
      );
    };

    const undo = () => {
      dispatch(
        setOnOffConfirmation({
          title: confirmUndo,
          subtitle: label,
          cb: () => {
            dispatch(onOffTimeLineUndo(name));
          },
        })
      );
    };
    return (
      <Accordion
        isOpen={isExpanded}
        isExpandable={!deleteMode}
        toggleIsOpen={toggleIsExpanded}
        customLabelComponent={
          <div className="flex items-center">
            <CurrencyLabel
              undoClassname="-mt-[6px]"
              undoable={isEdited && !deleteMode}
              undo={undo}
              currency={data.currency}
            />
            {!isEdited && !isNew && (
              <button
                onClick={onOpenAllSites}
                className={cn(
                  "ml-[10px] flex items-center justify-center h-[20px] w-[20px] aspect-square rounded-md text-gray hover:text-green hover:border-green group duration-300"
                )}>
                <Boxes className="w-[15px] h-[15px]" />
              </button>
            )}
          </div>
        }
        labelClassname={cn(
          "py-[10px]",
          !deleteMode && "hover:underline",
          deleteMode && "border border-red bg-[#D7443E11] hover:bg-[#D7443E33] duration-300"
        )}
        labelContainerClassname={cn(
          "rounded-sm sticky left-[160px] text-xs bg-bg py-[6px] w-[150px] z-[8] shadow-[15px_0px_30px_-15px_rgba(0,0,0,0.3)]",
          isEdited && !isNew && "onOffEdited",
          isNew && "onOffNew"
        )}
        notExpandableOnClick={onDelete}>
        <div className="flex flex-col">
          {data.sites.map((site, i) => (
            <SiteWithSegments
              key={site.site_name}
              index={i}
              data={site}
              city={city}
              groupName={groupName}
              currencyFrom={currencyFrom}
              currencyTo={data.currency}
            />
          ))}
        </div>
      </Accordion>
    );
  }
);
