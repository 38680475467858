import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TFlightFilters } from "./statsReducer";
import { StateT } from "./store";
import { Asset } from "src/pages/Requests/components/Request";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    pinned: [] as Array<string>,
    users: [] as Array<TUser>,
    controlUsers: [] as Array<TControlUser>,
    controlChats: [] as Array<TChat>,
    processUsers: [] as Array<TControlUser>,
    requests: [] as Array<TRequest>,
    requestsActions: null as null | Array<TRequest>,
    lastAction: 0,
    readedActions: [] as Array<number>,
    tags: [] as Array<TTag>,
    reports: [] as TReport[],
    sites: [] as Array<TAdminSite>,
    controlStats: [] as Array<TControlStats>,
    controlEvents: [] as Array<TControlEvent>,
    controlStatsPeriod: {} as TPeriodStats,
    bestStatuses: [] as Array<TBestStatuses>,
    sitesAutoTests: [] as Array<TSitesAutoTests>,
    citiesAutoTests: [] as Array<TCitiesAutoTests>,
    vectorsAutoTests: [] as Array<TVectorsAutoTests>,
    currencies: [] as Array<TBestCurrency>,
    cities: [] as Array<TBestCity>,
    controlBadges: {
      site: {},
      aggregator: {},
    } as TControlBadges,
    controlFilters: {
      aggregator: null,
      city: null,
      site: null,
      from: null,
      to: null,
    } as TControlFilters,
    routeMap: [] as Array<TRouteMap>,
    unreadReqCount: 0,
  },
  reducers: {
    setPinned: (state, action) => {
      state.pinned = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setControlUsers: (state, action) => {
      state.controlUsers = action.payload;
    },
    setControlChats: (state, action) => {
      state.controlChats = action.payload;
    },
    setProcessUsers: (state, action) => {
      state.processUsers = action.payload;
    },
    setAdminSites: (state, action) => {
      state.sites = action.payload;
    },
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    updateRequest: (state, { payload }: PayloadAction<Partial<TRequest>>) => {
      if (!payload.id) {
        throw new Error("В payload не передан id");
      }
      const item = state.requests.find((request) => request.id === payload.id);
      if (!item) {
        throw new Error("Не найден запрос по переданному id");
      }
      state.requests = state.requests.map((request) =>
        request.id === item.id ? { ...item, ...payload, assets: payload.assets || [] } : request
      );
    },
    createRequest: (state, { payload }: PayloadAction<TRequest>) => {
      state.requests = [payload, ...state.requests];
    },
    viewAllReq: (state) => {
      state.requests = state.requests.map((item) => ({ ...item, is_viewed: true }));
      state.unreadReqCount = 0;
    },
    setRequestsActions: (state, action) => {
      state.requestsActions = action.payload;
    },
    setUnredReqCount: (state, { payload }: PayloadAction<number>) => {
      state.unreadReqCount = payload;
    },
    setLastAction: (state, action) => {
      state.lastAction = action.payload;
    },
    setReadedActions: (state, action) => {
      state.readedActions = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setReports: (state, action) => {
      state.reports = action.payload;
    },
    setBestStatuses: (state, action) => {
      state.bestStatuses = action.payload;
    },
    setBestCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setBestCities: (state, action) => {
      state.cities = action.payload;
    },
    setControlBadges: (state, action) => {
      const tab = action.payload.tab;
      state.controlBadges = {
        ...state.controlBadges,
        [tab]: action.payload.data,
      };
    },
    setSitesAutoTests: (state, action) => {
      state.sitesAutoTests = action.payload;
    },
    setCitiesAutoTests: (state, action) => {
      state.citiesAutoTests = action.payload;
    },
    setVectorsAutoTests: (state, action) => {
      state.vectorsAutoTests = action.payload;
    },
    setControlStats: (state, action) => {
      state.controlStats = action.payload;
    },
    setControlStatsPeriod: (state, action) => {
      state.controlStatsPeriod = action.payload;
    },
    setControlEvents: (state, action) => {
      state.controlEvents = action.payload;
    },
    updateControlEvents: (state, action) => {
      state.controlEvents = state.controlEvents?.map((el) =>
        el.id === action.payload.id ? { ...el, section: action.payload.status_name } : el
      );
    },
    setControlFilters: (state, action) => {
      const attr = action.payload.attr;
      state.controlFilters = {
        ...state.controlFilters,
        [attr]: action.payload.value,
      };
    },
    clearControlFilters: (state) => {
      state.controlFilters = {
        aggregator: null,
        city: null,
        site: null,
        from: null,
        to: null,
      };
    },
    setRouteMap: (state, action) => {
      state.routeMap = action.payload;
    },
  },
});

export const {
  setPinned,
  setUsers,
  setControlUsers,
  setControlChats,
  setAdminSites,
  setRequests,
  updateRequest,
  createRequest,
  setRequestsActions,
  setUnredReqCount,
  viewAllReq,
  setLastAction,
  setReadedActions,
  setTags,
  setReports,
  setBestStatuses,
  setBestCurrencies,
  setBestCities,
  setSitesAutoTests,
  setCitiesAutoTests,
  setVectorsAutoTests,
  setControlStats,
  setControlStatsPeriod,
  setControlBadges,
  setControlEvents,
  updateControlEvents,
  setControlFilters,
  clearControlFilters,
  setRouteMap,
  setProcessUsers,
} = commonSlice.actions;

export const selectUnreadReqCount = (state: StateT) => state.common.unreadReqCount;
export const selectPinned = (state: StateT): Array<string> => state.common.pinned;
export const selectUsers = (state: StateT) => state.common.users;
export const selectProcessUsers = (state: StateT) => state.common.processUsers;
export const selectControlUsers = (state: StateT): Array<TControlUser> => state.common.controlUsers;
export const selectControlChats = (state): Array<TChat> => state.common.controlChats;
export const selectAdminSites = (state: StateT) => state.common.sites;
export const selectRequests = (state: StateT): Array<TRequest> => state.common.requests;
export const selectRequestsActions = (state: StateT): null | Array<TRequest> =>
  state.common.requestsActions;
export const selectLastAction = (state: StateT): number => state.common.lastAction;
export const selectReadedActions = (state: StateT): Array<number> => state.common.readedActions;
export const selectTags = (state: StateT): Array<TTag> => state.common.tags;
export const selectReports = (
  state: StateT
): Array<
  TAggregatorReport | TBestReports | TAvailableReport | TCarsReport | TSiteValidatorReport
> => state.common.reports;
export const selectBestStatuses = (state: StateT) => state.common.bestStatuses;
export const selectBestCurrencies = (state: StateT) => state.common.currencies;
export const selectBestCities = (state: StateT) => state.common.cities;
export const selectSitesAutoTests = (state: StateT) => state.common.sitesAutoTests;
export const selectCitiesAutoTests = (state: StateT) => state.common.citiesAutoTests;
export const selectVectorsAutoTests = (state: StateT) => state.common.vectorsAutoTests;
export const selectControlStats = (state: StateT) => state.common.controlStats;
export const selectControlStatsPeriod = (state: StateT) => state.common.controlStatsPeriod;
export const selectControlBadges = (state: StateT) => state.common.controlBadges;
export const selectControlEvents = (state: StateT): Array<TControlEvent> =>
  state.common.controlEvents;
export const selectControlFilters = (state: StateT) => state.common.controlFilters;
export const selectRouteMap = (state: StateT) => state.common.routeMap;

export default commonSlice.reducer;

export type TReport =
  | TAggregatorReport
  | TBestReports
  | TAvailableReport
  | TCarsReport
  | TSiteValidatorReport;

export type TControlFilters = TFlightFilters & {
  aggregator: null | Array<{ label: string; value: string }>;
};

export type TUser = {
  id: number;
  username: string;
  role_name: string;
};
export type TControlUser = {
  id: number;
  telegram_user_id: number;
  username: string;
};
export type TSummarySite = {
  id: number;
  site_id: number;
  exnode_id: number;
  site_name: string;
  tumbler_site: boolean; // синхронизатор минмакс, шагов
  tumbler_scam_checker: boolean; // вылеты
  update_turn_status: boolean; // вкл/выкл
  db_access: boolean; // mySql - возможность менять
  is_on_best: boolean;
  site_url: string;
  site_url_xml: string;
  real_xml: string;
  is_pe: boolean;
  exceptions: Array<TExcCity>;
};
export type TAdminSite = {
  update_new: boolean;
  has_procedure: boolean;
  id: number;
  site_id: number;
  exnode_id: number;
  site_name: string;
  site_uuid: string;
  tumbler_site: boolean; // синхронизатор минмакс, шагов
  tumbler_scam_checker: boolean; // вылеты
  update_turn_status: boolean; // вкл/выкл
  has_show_file: boolean; // возможность вкл/выкл на бесте
  db_access: boolean; // mySql - возможность менять
  new_db_structure: boolean;
  is_on_best: boolean;
  site_url: string;
  site_url_xml: string;
  real_xml: string;
  is_pe: boolean;
  pe_login?: string;
  pe_password?: string;
  pe_link?: string;
  pe_version?: string;
  xml_json: boolean;
  core_api_url?: string;
  core_api_token?: string;
  exceptions: Array<TExcCity>;
  db_data: Array<TDataBase>;
};
export type TExcVector = {
  currency_from_id: number;
  currency_to_id: number;
};
export type TExcCity = {
  city_id: number;
  data: Array<TExcVector>;
};
export type TDataBase = {
  driver: string;
  port: number;
  host: string;
  username: string;
  database: string;
  prefix: string;
  table_name: string;
};
export type TAdminSiteCreatePayload = {
  site_id: number;
  city_id: number;
};
export type TAdminSiteUpdatePayload = {
  site_id: number;
  city_id: number;
  data: TExcVector[];
};
export type TSitesAutoTests = {
  site_name: string;
  site_status: boolean;
};
export type TCitiesAutoTests = {
  city_id: number;
  city_name: string;
  city_lang: string;
  city_code: string;
  city_code_name: string;
  city_is_main: boolean;
};
export type TVectorsAutoTests = {
  site_url: string;
  data: [
    {
      from_code: string;
      from_name: string;
      to_code: string;
      to_name: string;
      is_crypto_first: boolean;
      lang: string;
      city_is_main: boolean;
      text_v: string;
    }
  ];
};
export type TSiteValidatorReport = {
  site_url: string;
  vectors: [
    {
      from_code: string;
      to_code: string;
      cities: [
        {
          city_code: string;
          langs: [
            {
              lang: string;
              info: TReportInfo;
            }
          ];
        }
      ];
    }
  ];
};
export type TReportInfo = {
  current_page_url: string;
  fatal: boolean;
  last_time_checked: number;
  max_info?: TSitesReportInfo;
  min_info?: TSitesReportInfo;
  in_info?: TSitesReportInfo;
  out_info?: TSitesReportInfo;
  texts?: string[];
};
export type TSitesReportInfo = {
  fact_value: number | string;
  expected_value: number | string;
  valid: false;
  is_read: boolean;
};
export type TBestReports = {
  date_time: number;
  url: string;
  from_name: string;
  to_name: string;
  city_code: string;
  exchanger_url: string;
  monitoring_url: string;
  monitoring_name: TMonitoring;
};

export type TMonitoring = "exnode" | "bestchange";

export type TBestStatuses = {
  exchanger_name: string;
  exchanger_url: string;
  exchanger_status: boolean;
  exchanger_id: number;
  exchanger_xml_url: string;
};

export type TBestCurrency = {
  currency_name: string;
  currency_id: number;
};

export type TBestCity = {
  city_code: string;
  city_id: number;
};

export type TControlStats = {
  section_name: string;
  section_id: string;
  section_order_num: number;
  amount_month: number;
  amount_day: number;
  percent_month: number;
  amount_month_normal: number;
  amount_day_normal: number;
  data: Array<TIssue>;
};
export type TPeriodStats = {
  period: TPeriod[];
  days: TDay[];
};
export type TPeriod = {
  section_name: string;
  section_id: string;
  section_order_num: number;
  amount_period: number;
  data: TPeriodData[];
};

export type TPeriodData = {
  issue_name: string;
  issue_id: string;
  amount_period: number;
};
export type TDay = {
  date_info: string;
  data: Array<{
    section_id: string;
    amount_period: number;
    data: TDayIssue[];
  }>;
};
export type TDayIssue = {
  issue_id: string;
  amount_period: number;
};

export type TControlEvent = {
  id: number;
  event_id: string;
  status: string;
  is_fire: boolean;
  responsible_user: TBoardUser;
  deadline: number;
  priority: TPriority;
  description: string;
  issue: TBaseIssue;
  section: TBaseSection;
  history: Array<THistory>;
  created_at: number;
  amount: number;
  notification_list: Array<string>;
};
export type TBoardUser = {
  name: string;
};
export type TBaseIssue = {
  issue_id: string;
  issue_name: string;
};
export type TBaseSection = {
  section_id: string;
  section_name: string;
};
export type THistory = {
  id: number;
  status: string;
  timestamp: number;
  responsible_user: TBoardUser;
  deadline: number;
};
export type TPriority = "low" | "medium" | "high";

export type TAvailableReport = {
  city_code: string;
  date_time: number;
  from_name: string;
  monitoring_name: TMonitoring;
  to_name: string;
  url: string;
};
export type TCourseReport = {
  in_info: {
    monitoring_value: number;
    exchanger_value: number;
    valid: boolean;
  };
  out_info: {
    monitoring_value: number;
    exchanger_value: number;
    valid: boolean;
  };
};
export type TMinMaxReport = {
  min_info: {
    monitoring_value: number;
    exchanger_value: number;
    valid: boolean;
  };
  max_info: {
    monitoring_value: number;
    exchanger_value: number;
    valid: boolean;
  };
};
export type TReserveReport = {
  reserve_info: {
    monitoring_value: number;
    exchanger_value: number;
    valid: boolean;
  };
};
export type TAggregatorCity = {
  city_code: string;
  date_time: number & (TCourseReport | TReserveReport | TMinMaxReport);
};

export type TAggregatorReport = {
  monitoring_name: TMonitoring;
  data: [
    {
      url: string;
      vectors: [
        {
          currency_from: string;
          currency_to: string;
          cities: TAggregatorCity[];
        }
      ];
    }
  ];
};

export type TCarsCity = {
  city_code: string;
  date_time: number;
  monitoring_url: string;
  main: boolean;
  delivery_info: {
    monitoring_value: boolean;
    exchanger_value: boolean;
  };
};

export type TCarsReport = {
  monitoring_name: TMonitoring;
  data: [
    {
      url: string;
      vectors: [
        {
          currency_from: string;
          currency_to: string;
          cities: TCarsCity[];
        }
      ];
    }
  ];
};

export type TIssue = {
  issue_name: string;
  issue_id: string;
  amount_month: number;
  amount_day: number;
  amount_month_normal: number;
  amount_day_normal: number;
  percent_month: number;
  on_autocontrol: boolean;
};
export type TControlBadge = {
  availability: number;
  redirect: number;
  courses: number;
  minmax: number;
  reserve: number;
  delivery: number;
};
export type TControlSitesBadge = {
  text: number;
  courses: number;
  minmax: number;
};
export type TControlBadges = {
  site: TControlSitesBadge;
  aggregator: TControlBadge;
};

export type TRequestStatus = "в очереди" | "в работе" | "готово" | "отклонено" | "тестируется";
export type TRequestType = "пожар" | "запрос" | "задача";
export type TPriorityType = "high" | "medium" | "low";
export type TRequest = {
  id: number;
  title: string;
  description: string;
  status: TRequestStatus;
  updated_timestamp: number;
  created_timestamp: number;
  creator: string;
  creator_role?: string;
  last_action_id?: number;
  last_action_updated?: number;
  priority?: TPriorityType;
  updator: string;
  type: TRequestType;
  assets: Asset[];
  is_viewed: boolean;
};
export type TRouteMap = {
  title: string;
  route: string;
};
export type TTag = {
  id: number;
  tag: string;
};
export type TProcessingChat = {
  chat_name: string;
  chat_id: number;
  interval_x: number; // секунды до фиксации нарушения и уведомления процессинга
  interval_y: number; // секунды до уведомления контроля
  issue_id: string;
  responsible_users: Array<TControlUser>;
};
export type TAutoIssue = {
  issue_id: string;
  issue_name: string;
  section_id: string;
  amount_day_normal: number;
  amount_month_normal: number;
  on_autocontrol: boolean;
};
// Регламенты
export type TRegulation = {
  section_name: string;
  section_id: string;
  id: number;
  responsible_users: Array<TControlUser>;
  spent_time?: number;
  check_period: TCheckPeriod;
  check_period_text?: string;
  issue_id: string; // UUID
  issue_name: string;
  violation_algorithm?: string;
  notifications_system: Array<TNotification>;
  text_notifications_system?: string;
  is_auto_notification: boolean;
  fire_description?: string;
  checkpoints_by_day?: number;
  checkpoints_by_month?: number;
};
export type TNotification = {
  id?: number;
  issue_id: string; // UUID
  stage_number: number;
  chats?: Array<TChat>;
  users?: Array<TControlUser>;
  senior_users?: Array<TControlUser>;
  time_to_next_stage?: number;
  in_the_moment: boolean;
};
export type TChat = {
  id: number;
  telegram_chat_id: number;
  chat_name: string;
};
export type TCheckPeriod = {
  day: Array<number>;
  week: Array<number>;
  month: Array<number>;
};

// Кошельки
export type TNetwork = "btc" | "eth" | "trx";
export type TCoin = {
  coin_id?: string;
  coin_name: string;
  coin_network: TNetwork;
  coin_address?: string;
  coin_department?: string;
};
export type TWallet = {
  wallet_id?: string;
  wallet_name: string;
  wallet_address: string;
  wallet_department?: string;
  wallet_network: TNetwork;
};
export type TCondition = "<" | "<=" | "==" | ">=" | ">" | "!=";
export type TTrack = {
  track_name: string;
  track_id?: string;
  coin_id: string;
  amount: number;
  actual_amount?: number;
  condition: TCondition;
  wallet_id: string;
  updated_at?: number;
};
export type TTrackNotification = {
  track_id: string;
  notification_id?: string;
  chat_type: "user" | "group";
  chat_id: number;
  content: string;
};

export type TTypeEditorType = {
  title: TPriorityType | TRequestType;
  icon: string;
  color: string;
  size: number;
};
