import { Dispatch, SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";
import { fetchRequest } from "src/helpers/fetchRequest";
import addApi from "src/api/add";
import { TDataBase } from "src/store/commonReducer";
import { Popup } from "src/components/Popup/Popup";
import { Field } from "src/components/FormElements/Field";

type TProps = {
  item: TDataBase;
  site_id: number;
  access: boolean;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  fetchAdminSites: () => void;
};

export const DBForm = ({
  item,
  site_id,
  setMessage,
  setStatus,
  fetchAdminSites,
  setIsLoading,
  access,
}: TProps) => {
  const { watch } = useFormContext();
  const [isEditPassword, setEditPassword] = useState(false);

  const updatePassword = async () => {
    setIsLoading(true);
    const data = {
      site_id: site_id,
      driver: item?.driver,
      password: watch(`password-${item?.driver}`),
    };
    const { response, error } = await fetchRequest(addApi.updateSitePassword(data), {
      request: "Обновление пароля",
    });
    if (response) {
      setMessage(`пароль успешно обновлен`);
      setStatus(true);
      await fetchAdminSites();
    }
    if (error) {
      setMessage("Не удалось обновить пароль");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      setEditPassword(false);
    }, 4600);
  };

  return (
    <div className="grid sm:grid-cols-2 gap-[10px]">
      <div className="flex flex-col gap-4">
        <Field
          title="host"
          name={`host-${item?.driver}`}
          defaultValue={item?.host}
          required
          disabled={!access}
        />
        <Field
          title="database"
          name={`database-${item?.driver}`}
          defaultValue={item?.database}
          required
          disabled={!access}
        />
        <Field
          title="username"
          name={`username-${item?.driver}`}
          defaultValue={item?.username}
          required
          disabled={!access}
        />
        <Field
          title="table_name"
          name={`table_name-${item?.driver}`}
          defaultValue={item?.table_name}
          required
          disabled={!access}
        />
      </div>
      <div className="flex flex-col gap-4">
        <Field
          title="port"
          name={`port-${item?.driver}`}
          defaultValue={item?.port}
          required
          disabled={!access}
        />
        <div className="grid grid-cols-[66px_1fr] items-baseline gap-[10px]">
          <div className="text-right">driver</div>
          <div className="bg-[#989CAAAA] text-white text-center rounded-sm pb-[2px] font-bold">
            {item?.driver}
          </div>
        </div>
        {access && (
          <div
            className="grid grid-cols-[66px_1fr] items-baseline gap-[10px] cursor-pointer"
            onClick={() => setEditPassword(true)}>
            <div className="text-right">password</div>
            <div className="bg-[#989CAAAA] text-white text-center rounded-sm pt-[3px] max-h-[20px] font-bold">
              ********
            </div>
          </div>
        )}
        <Field
          title="prefix"
          name={`prefix-${item?.driver}`}
          defaultValue={item?.prefix}
          required
          disabled={!access}
        />
      </div>
      {isEditPassword && (
        <Popup closeModal={() => setEditPassword(false)} noPadding>
          <div className="w-full table-header flex justify-center">ИЗМЕНЕНИЕ ПАРОЛЯ</div>
          <div className="p-[20px] text-xs flex flex-col gap-4">
            <Field title="password" name={`password-${item?.driver}`} defaultValue={""} required />
            <button
              type="button"
              className="prime-button-sm bg-font mt-[16px]"
              onClick={updatePassword}>
              Обновить пароль
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
