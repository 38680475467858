import { useEffect, useState } from "react";

export const PaginationLoader = ({ isComplete, currentPage, totalPages }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (!isComplete) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 2000);
    }
    return () => clearTimeout(timer);
  }, [isComplete]);

  return (
    <div
      className={`flex gap-8 duration-300 ${
        isVisible ? "opacity-100" : !isComplete ? "opacity-70" : "opacity-0"
      }`}>
      {isComplete ? "Загружено" : `Загружается страница ${currentPage} из ${totalPages}...`}
    </div>
  );
};
