import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TStrSelect } from "src/store/directionsReducer";
import {
  TCitySelect,
  TDirectionSelect,
  TTextCities,
  TTextDirectionGet,
  TTextNotDirections,
  TTextSubType,
  TTextSubTypeList,
  TTextType,
} from "../../../types";
import { ExistCity } from "./ExistCity";
import arrow from "src/assets/images/arrow.svg";

type TProps = {
  directionsData: TTextDirectionGet | null;
  existSubtypeTexts: TTextNotDirections | null;
  types: Array<TTextType>;
  subTypes: TTextSubTypeList[];
  setCurrentCity: Dispatch<SetStateAction<TCitySelect> | null>;
  setVector: Dispatch<SetStateAction<TDirectionSelect | null>>;
  setType: Dispatch<SetStateAction<TTextType>>;
  setSubType: Dispatch<SetStateAction<TTextSubType | null>>;
  setLang: Dispatch<SetStateAction<TStrSelect | null>>;
  setIsCreate: Dispatch<SetStateAction<boolean>>;
};

export const ExistTexts = ({
  directionsData,
  existSubtypeTexts,
  types,
  subTypes,
  setCurrentCity,
  setVector,
  setType,
  setSubType,
  setLang,
  setIsCreate,
}: TProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [opened, setOpened] = useState(false);

  const existTexts = useMemo(() => {
    if (!!directionsData?.cities?.length) {
      return directionsData?.cities
        ?.map((cityEl) => ({
          ...cityEl,
          vectors: cityEl?.vectors
            ?.filter((el) => !!el.languages?.length)
            ?.sort((a, b) =>
              `${a?.currency_from_type}_${a?.currency_to_type}` >
              `${b?.currency_from_type}_${b?.currency_to_type}`
                ? 1
                : -1
            ),
        }))
        ?.filter((el) => !!el.vectors?.length) as Array<TTextCities>;
    } else {
      return [] as Array<TTextCities>;
    }
  }, [directionsData]);

  return (
    <div className="-my-8 border border-stale rounded-sm">
      <button
        type="button"
        className={`flex w-full justify-end gap-8 items-center relative h-[20px] px-8`}
        onClick={() => setOpened(!opened)}>
        <div className="text-[18px] font-black leading-3 text-[#52D58E]">{`\u25CF`}</div>
        <div className="text-lightFont font-light">Добавленные тексты</div>
        <div className={`duration-300 ${!opened ? "rotate-180" : ""}`}>
          <img src={arrow} alt="раскрыть" width={12} height={12} />
        </div>
      </button>
      <div
        className={`flex flex-col leading-4 text-left overflow-y-auto duration-300 px-8 text-[#32C56E] font-medium ${
          opened ? "max-h-[70vh] py-8" : "max-h-[0] py-0"
        }`}>
        {!!existSubtypeTexts && (
          <div className="flex flex-col gap-8 pb-8">
            {existSubtypeTexts.languages?.map((el) => (
              <div
                key={`exist_${el.lang}`}
                className="flex flex-col gap-4 w-full rounded-sm bg-[#BABAC333] p-4 font-semibold whitespace-pre">
                <div className="text-lightFont">{el.lang}</div>
                {el.types?.map((typeEl, index) => {
                  const currentType = types?.find((el) => el.id === typeEl?.type_id);
                  const currentSubType = subTypes
                    ?.find((el) => el.name === currentType?.name_en)
                    ?.data?.find((dataEl) => dataEl?.text_subtype_name_en === typeEl?.text_subtype);

                  return (
                    <button
                      type="button"
                      key={`exist_${el.lang}_${index}`}
                      className="px-4 pb-2 rounded-sm bg-[#32C56E] text-[#FFF] w-fit hover:bg-pistachio duration-300"
                      onClick={() => {
                        setType(currentType);
                        setSubType(currentSubType || null);
                        setLang({ label: el.lang, value: el.lang });
                        setIsCreate(true);
                        searchParams.set("lang", el.lang);
                        searchParams.set("type", currentType.id?.toString());
                        if (!!currentSubType) {
                          searchParams.set("subtype", currentSubType?.id?.toString());
                        }
                        setSearchParams(searchParams);
                      }}>
                      {currentType?.name_ru || typeEl?.type_id} /{" "}
                      {currentSubType?.text_subtype_name_ru || typeEl?.text_subtype}
                    </button>
                  );
                })}
              </div>
            ))}
          </div>
        )}
        {existTexts?.map((el) => (
          <ExistCity
            key={el?.city_code}
            item={el}
            types={types}
            subTypes={subTypes}
            setVector={setVector}
            setCurrentCity={setCurrentCity}
            setLang={setLang}
            setType={setType}
            setSubType={setSubType}
            setIsCreate={setIsCreate}
          />
        ))}
      </div>
    </div>
  );
};
