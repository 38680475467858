import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FieldValues, FormProvider, useForm, useFormContext } from "react-hook-form";
import { TSiteCluster } from "src/store/clusterReducer";
import { fetchRequest } from "src/helpers/fetchRequest";
import { LS } from "src/api/constants";
import clusterApi from "src/api/cluster";
import { setNotific } from "src/store/mainReducer";
import { Emptiness } from "src/components/UI/loader/Emptiness";

export const SiteClusters = () => {
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const isAccess = userAccess?.includes("cluster_post");
  const dispatch = useDispatch();
  const methods = useForm();
  const [isGood, setIsGood] = useState<boolean>(true);
  const [clusters, setClusters] = useState<Array<TSiteCluster>>([]);
  const dirtyFields = Object.keys(methods.formState.dirtyFields);

  const fetchSitesClusters = async () => {
    const { response, error } = await fetchRequest(clusterApi.getSitesClusters(), {
      request: "Кластеры сайтов",
    });
    if (response) {
      setClusters(response);
      setIsGood(true);
    }
    if (error) {
      setClusters([]);
      setIsGood(false);
    }
  };

  const onSubmit = async (e: FieldValues) => {
    const payload = Object.entries(e)
      ?.filter((field) => dirtyFields?.includes(field[0]))
      .map((el) => ({
        site_id: clusters?.find((cluster) => cluster?.site_name === el[0])?.site_id,
        site_name: el[0],
        percent: el[1],
      }));
    const { response } = await fetchRequest(clusterApi.updateSitesCluster(payload), {
      request: "Выставление кластеров сайтов",
    });
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: "Проценты успешно обновлены",
          request: "Выставление кластеров сайтов",
        })
      );
      methods.reset();
      await fetchSitesClusters();
    }
  };

  useEffect(() => {
    fetchSitesClusters();
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="pb-[100px] pt-[10px]">
        {!!clusters?.length && (
          <div className="flex flex-wrap justify-start mb-[20px] mx-auto w-[300px] sm:w-fit sm:pl-[30px] sm:pr-[50px] md:pr-[80px] text-sm">
            {clusters?.map((el) => (
              <div
                key={el.site_id}
                className="p-[10px] shadow-base rounded-md w-[250px] mx-[5px] mt-[0] mb-[10px]">
                <Field
                  title={el.site_name}
                  name={el.site_name}
                  defaultValue={el?.percent}
                  disabled={!isAccess}
                  clusters={clusters}
                />
              </div>
            ))}
          </div>
        )}
        {!!clusters?.length && isAccess && (
          <button className="prime-button-sm bg-font" disabled={!dirtyFields?.length}>
            Выставить
          </button>
        )}
      </form>
      {!isGood && !clusters?.length && (
        <div className="w-full flex justify-center mx-auto">
          <Emptiness message={"Ошибка сервиса"} />
        </div>
      )}
    </FormProvider>
  );
};

const Field = ({
  title,
  name,
  defaultValue,
  disabled,
  clusters,
}: {
  title: string;
  name: string;
  defaultValue: string | number;
  disabled: boolean;
  clusters: Array<TSiteCluster>;
}) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, clusters]);

  return (
    <label className={`grid grid-cols-2 gap-[10px] text-left`}>
      <div>{title}</div>
      <input
        className={`border border-[#EEE] outline-lightGray rounded-sm px-[8px] ${
          disabled ? "bg-light" : ""
        }`}
        {...register(`${name}`, { required: true, disabled: disabled })}
      />
    </label>
  );
};
