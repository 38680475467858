import { ReactNode } from "react";
import { TCurrencyTypes } from "src/pages/Text/types";

export const currencyFilter: { type: TCurrencyTypes; icon: ReactNode }[] = [
  {
    type: "crypto",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        viewBox="0 0 32 32"
        id="_x3C_Layer_x3E_"
        version="1.1"
        xmlSpace="preserve">
        <g id="Bitcoin_x2C__BTC">
          <g id="XMLID_91_">
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M15.67,25.13l0.58-2.36c2.81,0.53,4.92,0.32,5.81-2.22    c0.72-2.04-0.03-3.22-1.51-3.99c1.08-0.25,1.89-0.96,2.1-2.42h0c0.3-2-1.22-3.07-3.3-3.79l0.56-2.25 M12.71,24.39l0.57-2.34    c0.45,0.12,0.89,0.23,1.31,0.34 M14.73,9.2c-0.36-0.08-3.32-0.82-3.32-0.82l-0.44,1.76c0,0,1.22,0.28,1.2,0.3    c0.67,0.17,0.79,0.61,0.77,0.96l-1.85,7.41c-0.08,0.2-0.29,0.51-0.75,0.39c0.02,0.02-1.2-0.3-1.2-0.3l-0.82,1.89    c0,0,2.93,0.74,3.32,0.84 M17.71,9.87c-0.43-0.11-0.88-0.21-1.32-0.31l0.54-2.2 M14.71,16.45c1.12,0.28,4.69,0.83,4.16,2.96l0,0    c-0.51,2.04-3.95,0.94-5.07,0.66l0.5-2.01 M15.95,11.51c0.93,0.23,3.92,0.66,3.44,2.6c-0.46,1.86-3.33,0.91-4.26,0.68l0.42-1.68"
              id="Bitcoin_x2C__BTC_x2C__cryptocurrency_2_"
            />

            <circle
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              className="st1"
              cx="16"
              cy="16"
              id="XMLID_173_"
              r="14.5"
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    type: "cash",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        width="20px"
        height="20px"
        viewBox="0 0 30 30">
        <path d="M5.5 5c-.655 0-.66 1.01 0 1h22c.286 0 .5.214.5.5v13c0 .66 1 .66 1 0v-13c0-.822-.678-1.5-1.5-1.5h-22zm-2 2c-.654 0-.654 1 0 1h22c.286 0 .5.214.5.5v13c0 .665 1.01.66 1 0v-13c0-.822-.678-1.5-1.5-1.5h-22zm-2 2C.678 9 0 9.678 0 10.5v12c0 .822.678 1.5 1.5 1.5h22c.822 0 1.5-.678 1.5-1.5v-12c0-.822-.678-1.5-1.5-1.5h-22zm0 1h22c.286 0 .5.214.5.5v12c0 .286-.214.5-.5.5h-22c-.286 0-.5-.214-.5-.5v-12c0-.286.214-.5.5-.5zm1 1c-.276 0-.5.224-.5.5v2c0 .672 1 .656 1 0V12h1.5c.672 0 .656-1 0-1h-2zm10 0C9.468 11 7 13.468 7 16.5S9.468 22 12.5 22s5.5-2.468 5.5-5.5-2.468-5.5-5.5-5.5zm8 0c-.656 0-.672 1 0 1H22v1.5c0 .656 1 .672 1 0v-2c0-.276-.224-.5-.5-.5h-2zm-8 1c2.49 0 4.5 2.01 4.5 4.5S14.99 21 12.5 21 8 18.99 8 16.5s2.01-4.5 4.5-4.5zm0 1c-.277 0-.5.223-.5.5v.594c-.578.21-1 .76-1 1.406 0 .82.68 1.5 1.5 1.5.28 0 .5.212.5.5 0 .288-.22.5-.5.5h-1c-.338-.005-.5.248-.5.5s.162.505.5.5h.5v.5c0 .277.223.5.5.5s.5-.223.5-.5v-.594c.578-.21 1-.76 1-1.406 0-.82-.68-1.5-1.5-1.5-.28 0-.5-.212-.5-.5 0-.288.22-.5.5-.5h1c.338.005.5-.248.5-.5s-.162-.505-.5-.5H13v-.5c0-.277-.223-.5-.5-.5zm-10 6.002c-.25-.002-.5.162-.5.498v2c0 .276.224.5.5.5h2c.656 0 .672-1 0-1H3v-1.5c0-.328-.25-.496-.5-.498zm20 0c-.25.002-.5.17-.5.498V21h-1.5c-.672 0-.656 1 0 1h2c.276 0 .5-.224.5-.5v-2c0-.336-.25-.5-.5-.498z" />
      </svg>
    ),
  },
  {
    type: "card",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M3 9H21M7 15H9M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];
