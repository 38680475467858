import { Dispatch, SetStateAction, useMemo } from "react";
//import { timeStamp } from "src/helpers/date";
import Graph from "react-graph-vis";
import { pastel } from "src/helpers/colors";
import { TGraphData, TGraphFilter } from "src/store/directionsReducer";
//import { GraphTimeline } from "./GraphTimeline";

type TProps = {
  data: TGraphData;
  selectedNodes: Array<string>;
  setSelectedNodes: Dispatch<SetStateAction<Array<string>>>;
  hierarchical: boolean;
  filter: TGraphFilter;
  edgeLength: number;
  isFilterOpen: boolean;
};

export const VectorsGraph = ({
  data,
  selectedNodes,
  setSelectedNodes,
  hierarchical,
  filter,
  edgeLength,
}: //  isFilterOpen,
TProps) => {
  // const period = {
  //   time_from: "2023-08-30T03:36",
  //   time_to: "2023-08-30T12:36",
  // };
  //const [point, setPoint] = useState(timeStamp(period?.time_from));
  const visibleData = useMemo(
    () => ({
      nodes: data?.nodes
        ?.filter((el) => {
          const nodeData = selectedNodes?.map((selected) => {
            const arr = selected?.split("_");
            return arr?.filter((_, i) => i !== arr.length - 1)?.join("_");
          });
          return !(
            !!selectedNodes?.length &&
            nodeData?.some((selected) => {
              const arr = el?.id?.split("_");
              return arr
                ?.filter((_, i) => i !== arr.length - 1)
                ?.join("_")
                ?.includes(`${selected}_`);
            })
          );
        })
        ?.map((el) => {
          const coloredId = el?.id?.split("_")[1];
          return el.id?.includes("site")
            ? {
                ...el,
                color: filter?.type === "site" ? "#282c34" : pastel[+coloredId % 37 || 0],
                label: `${selectedNodes?.includes(el.id) ? `\u25BC ` : ""}${el.label}${
                  el.id?.includes("delivery") ? ` 🚗` : ""
                }`,
                font: {
                  size: filter?.type === "site" ? 20 : 18,
                  color: filter?.type === "site" ? "#FFF" : "#282C34",
                },
                margin: filter?.type === "site" ? 16 : 12,
                // periods: [
                //   {
                //     time_from: "2023-08-30T04:36",
                //     time_to: "2023-08-30T12:36",
                //   },
                // ],
              }
            : el.id?.includes("city")
            ? {
                ...el,
                color: filter?.type === "city" ? "#282c34" : pastel[+coloredId % 37 || 0],
                label: `${selectedNodes?.includes(el.id) ? `\u25BC ` : ""}${el.label}${
                  el.id?.includes("delivery") ? ` 🚗` : ""
                }`,
                font: {
                  size: filter?.type === "city" ? 20 : 18,
                  color: filter?.type === "city" ? "#FFF" : "#282C34",
                },
                margin: filter?.type === "city" ? 16 : 12,
                // periods: [
                //   {
                //     time_from: "2023-08-30T03:36",
                //     time_to: "2023-08-30T09:36",
                //   },
                //   {
                //     time_from: "2023-08-30T11:36",
                //     time_to: "2023-08-30T12:36",
                //   },
                // ],
              }
            : el.id?.includes("from")
            ? {
                ...el,
                color: "#DADAE3",
                label: selectedNodes?.includes(el.id) ? `\u25BC ${el.label}` : el.label,
                font: {
                  color: "#282C34",
                },
                // periods: el.label?.includes("CASHRUB")
                //   ? [
                //       {
                //         time_from: "2023-08-30T03:36",
                //         time_to: "2023-08-30T09:36",
                //       },
                //     ]
                //   : [
                //       {
                //         time_from: "2023-08-30T05:36",
                //         time_to: "2023-08-30T12:36",
                //       },
                //     ],
              }
            : {
                ...el,
                color: "#EFEDE5",
                label: `\u2196 ${el.label}`,
                font: {
                  color: "#282C34",
                },
                // periods: el.label?.includes("BTC")
                //   ? [
                //       {
                //         time_from: "2023-08-30T03:36",
                //         time_to: "2023-08-30T12:36",
                //       },
                //     ]
                //   : [
                //       {
                //         time_from: "2023-08-30T05:36",
                //         time_to: "2023-08-30T12:36",
                //       },
                //     ],
              };
        }),
      // ?.map((el) =>
      //   !!el.periods?.find(
      //     (periodEl) =>
      //       timeStamp(periodEl.time_from) <= point && timeStamp(period.time_to) >= point
      //   )
      //     ? {
      //         ...el,
      //         opacity: 1,
      //       }
      //     : {
      //         ...el,
      //         opacity: 0,
      //       }
      // ),
      edges: data?.edges
        ?.filter((el) => {
          const nodeData = selectedNodes?.map((selected) => {
            const arr = selected?.split("_");
            return arr?.filter((str, i) => i !== arr.length - 1)?.join("_");
          });
          return !(
            !!selectedNodes?.length &&
            nodeData?.some((selected) => {
              const arr = el?.to?.split("_");
              return arr
                ?.filter((str, i) => i !== arr.length - 1)
                ?.join("_")
                ?.includes(`${selected}_`);
            })
          );
        })
        ?.map((el) => {
          const from = el.from_?.split("_");
          const fromType = from?.splice(-1, 1);
          const toType = el.to?.split("_")?.splice(-1, 1);
          return {
            ...el,
            label: !!el?.label ? `${el?.label}%` : "",
            font: {
              color: !!el?.label && +el?.label > 70 ? "#D7443E" : "#AAA",
            },
            from: el.from_,
            width: fromType[0] === toType[0] ? 4 : 3,
            dashes: fromType[0] === toType[0],
            color:
              fromType[0] === toType[0]
                ? (fromType[0] === "city" && filter?.type === "site") ||
                  (fromType[0] === "site" && filter?.type === "city")
                  ? `${pastel[+from[1] % 37 || 0]}`
                  : fromType[0] === "from"
                  ? "#DADAE3"
                  : "#EFEDE5"
                : "#AAA",
            length: fromType[0] === toType[0] ? 700 : edgeLength,
          };
        }),
    }),
    [data, selectedNodes] //point
  );

  const options = useMemo(
    () => ({
      layout: {
        hierarchical: {
          enabled: hierarchical,
          direction: filter?.direction,
          edgeMinimization: false,
          parentCentralization: false,
          nodeSpacing: edgeLength,
          shakeTowards: "roots",
          sortMethod: "directed",
        },
        improvedLayout: false,
        randomSeed: 10,
      },
      edges: {
        arrows: {
          to: {
            enabled: false,
          },
        },
      },
      physics: {
        solver: "repulsion",
        repulsion: {
          centralGravity: 0.1,
          springConstant: 0.05,
          nodeDistance: edgeLength,
          damping: 0.09,
        },
      },
      nodes: {
        borderWidth: 0,
        shape: "box",
        size: 30,
        physics: !hierarchical,
        margin: {
          top: 6,
          right: 8,
          bottom: 4,
          left: 8,
        },
      },
    }),
    [hierarchical, edgeLength, filter?.direction]
  );

  return (
    <>
      <Graph
        graph={visibleData}
        options={options}
        events={{
          click: (e) => {
            if (!!e.nodes?.length && !e.nodes[0]?.includes("to")) {
              if (selectedNodes?.includes(e.nodes[0])) {
                setSelectedNodes(selectedNodes?.filter((el) => el !== e.nodes[0]));
              } else {
                setSelectedNodes([...selectedNodes, e.nodes[0]]);
              }
            }
            if (!!e.nodes?.length && e.nodes[0]?.includes("to")) {
              const link = visibleData?.nodes?.find((el) => el.id === e.nodes[0])?.best_link;
              if (!!link) {
                window.open(link, "_blank");
              }
            }
          },
        }}
      />
      {/* <GraphTimeline
        isFilterOpen={isFilterOpen}
        period={period}
        point={point}
        setPoint={setPoint}
      /> */}
    </>
  );
};
