export const addCities = (arr, setFn, { target }) => {
  let data = [];
  if (arr?.length > 0) {
    if (!arr?.includes(target.id)) {
      data = [...arr, target.id];
    } else {
      data = [...arr];
      data?.splice(
        arr.findIndex((el) => el === target.id),
        1
      );
    }
  } else {
    data = [target.id];
  }
  setFn(data);
};

export const add = (arr, setFn, e) => {
  let data = [];
  if (arr?.length > 0) {
    if (!arr?.includes(e)) {
      data = [...arr, e];
    } else {
      data = [...arr];
      data?.splice(
        arr.findIndex((el) => el === e),
        1
      );
    }
  } else {
    data = [e];
  }
  setFn(data);
};

export const addAll = (arr, setFn, type) => {
  if (type === "on") {
    setFn(arr);
  } else {
    setFn([]);
  }
};
