import { Dispatch, SetStateAction, useState } from "react";
import { defineAnswerInputType } from "../../constants";
import { EQuestion, TAnswer, TAttemptAnswer } from "../../types";
import { EditAnswer } from "./EditAnswer";
import { Button } from "src/shadcn/ui/ui/button";

export const Answer = ({
  answer,
  name,
  type,
  isEditPage,
  isEditQuestion,
  saveAnswer,
  isExpired,
  yourAnswer,
  setTrigger,
}: {
  answer: TAnswer;
  name: string;
  type: EQuestion;
  isEditPage: boolean;
  isEditQuestion: boolean;
  isExpired?: boolean;
  yourAnswer?: TAttemptAnswer | undefined;
  saveAnswer?: (payload: {
    id: number | string;
    isDelete?: boolean;
    name?: string;
    answer_is_correct?: boolean;
  }) => void;
  setTrigger?: Dispatch<SetStateAction<number>>;
}) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className="relative">
      {!isEdit || !saveAnswer ? (
        <label
          className={`flex gap-3 items-center p-3 pl-5 border shadow-md rounded-sm ${
            answer.answer_is_correct ? "bg-emerald-50 border-emerald-300" : ""
          }`}>
          {type !== EQuestion.INPUT && (
            <input
              className="w-5"
              type={defineAnswerInputType(type)}
              name={name}
              onChange={() => {
                if (!!setTrigger) {
                  setTrigger((prev) => {
                    return ++prev;
                  });
                }
              }}
              value={answer.answer_id}
              disabled={isEditPage || isExpired}
              defaultChecked={
                !!yourAnswer &&
                Array.isArray(yourAnswer.answer) &&
                typeof answer.answer_id === "number"
                  ? yourAnswer.answer?.includes(answer.answer_id)
                  : false
              }
            />
          )}
          <span className="w-full">{answer.answer_title}</span>
          {isEditQuestion && (
            <Button
              variant="ghost"
              className="h-7 w-7 text-slate-500 text-base rotate-90"
              onClick={() => setIsEdit(!isEdit)}>
              {`\u270E`}
            </Button>
          )}
        </label>
      ) : (
        <EditAnswer
          answer={answer}
          question_type={type}
          setIsEdit={setIsEdit}
          saveAnswer={saveAnswer}
        />
      )}
    </div>
  );
};
