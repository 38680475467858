import ReactSelect, { createFilter } from "react-select";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getArrLabels,
  getArrValues,
  getItemNestedData,
  selectFilter,
} from "src/helpers/formHelpers";
import { selectAllCities, TStrSelect } from "src/store/directionsReducer";
import {
  clearControlFilters,
  selectControlFilters,
  selectReports,
  selectRouteMap,
  setControlFilters,
  TControlFilters,
  TReport,
} from "src/store/commonReducer";

export const ControlFilters = () => {
  const dispatch = useDispatch();
  const cities = useSelector(selectAllCities);
  const filters = useSelector(selectControlFilters);
  const routeMap = useSelector(selectRouteMap);
  const reports = useSelector(selectReports);
  const page = routeMap[0]?.route?.replace("/", "");
  const monitoringOptions = ["bestchange", "exnode"].map((el) => ({ label: el, value: el }));

  const sitesOptions: Array<TStrSelect> = useMemo(() => {
    if (!!reports?.length) {
      return "data" in reports[0]
        ? reports
            ?.map((el) =>
              getItemNestedData(el, "url")?.map((el2: string) => ({
                label: el2?.split("//")[1],
                value: el2?.split("//")[1],
              }))
            )
            ?.reduce((a, b) => a.concat(b))
        : [
            ...new Set(
              reports?.map((el) => {
                return "site_url" in el ? el.site_url : "url" in el ? el.url?.split("//")[1] : null;
              })
            ),
          ]?.map((el2: string) => ({ label: el2, value: el2 }));
    } else {
      return [];
    }
  }, [reports]);

  const fromOptions: Array<TStrSelect> = useMemo(() => {
    if (!!reports?.length) {
      if ("from_name" in reports[0]) {
        return [...new Set(reports?.map((el) => "from_name" in el && el.from_name))]?.map(
          (el2) => ({ label: el2, value: el2 })
        );
      }
      if ("vectors" in reports[0]) {
        return [
          ...new Set(
            reports
              ?.map((el) => "vectors" in el && el.vectors?.map((el2) => el2.from_code))
              ?.reduce((a, b) => a.concat(b))
          ),
        ]?.map((el2) => ({ label: el2, value: el2 }));
      }
      if ("data" in reports[0]) {
        const arr = reports
          ?.map(
            (el) =>
              "data" in el &&
              el.data
                ?.map((el2) => el2?.vectors?.map((el3) => el3?.currency_from))
                ?.reduce((a, b) => a.concat(b))
          )
          ?.reduce((a, b) => a.concat(b));
        return [...new Set(arr)]?.map((el2: string) => ({ label: el2, value: el2 }));
      }
    } else {
      return [];
    }
  }, [reports]);

  const toOptions: Array<TStrSelect> = useMemo(() => {
    if (!!reports?.length) {
      if ("to_name" in reports[0]) {
        return [...new Set(reports?.map((el) => "to_name" in el && el.to_name))]?.map(
          (el2: string) => ({ label: el2, value: el2 })
        );
      }
      if ("vectors" in reports[0]) {
        return [
          ...new Set(
            reports
              ?.map((el) => "vectors" in el && el.vectors?.map((el2) => el2.to_code))
              ?.reduce((a, b) => a.concat(b))
          ),
        ]?.map((el2) => ({ label: el2, value: el2 }));
      }
      if ("data" in reports[0]) {
        const arr = reports
          ?.map(
            (el) =>
              "data" in el &&
              el.data
                ?.map((el2) => el2?.vectors?.map((el3) => el3?.currency_to))
                ?.reduce((a, b) => a.concat(b))
          )
          ?.reduce((a, b) => a.concat(b));
        return [...new Set(arr)]?.map((el2: string) => ({ label: el2, value: el2 }));
      }
    } else {
      return [];
    }
  }, [reports]);

  const citiesOptions: Array<TStrSelect> = useMemo(() => {
    if (!!reports?.length) {
      if ("city_code" in reports[0]) {
        return [...new Set(reports?.map((el) => "city_code" in el && el.city_code))]?.map(
          (el2) => ({ label: cities?.find((el3) => el3.city_code === el2)?.city_name, value: el2 })
        );
      }
      if ("vectors" in reports[0]) {
        return [
          ...new Set(
            reports
              ?.map(
                (el) =>
                  "vectors" in el &&
                  el.vectors
                    ?.map((el2) => el2.cities?.map((el3) => el3.city_code))
                    ?.reduce((a, b) => a.concat(b))
              )
              ?.reduce((a, b) => a.concat(b))
          ),
        ]?.map((el4) => ({
          label: cities?.find((el5) => el5.city_code === el4)?.city_name,
          value: el4,
        }));
      }
      if ("data" in reports[0]) {
        const arr = reports
          ?.map(
            (el) =>
              "data" in el &&
              el.data?.map((el2) =>
                el2?.vectors?.map((el3) => el3?.cities?.map((el4) => el4.city_code))
              )
          )
          ?.reduce((a, b) => a.concat(b))
          ?.reduce((a, b) => a.concat(b))
          ?.reduce((a, b) => a.concat(b));
        return [...new Set(arr)]?.map((el5: string) => ({
          label: cities?.find((el6) => el6.city_code === el5)?.city_name,
          value: el5,
        }));
      }
    }
  }, [reports, cities]);

  return (
    <div className="flex flex-col mx-auto sm:mx-0 gap-8 items-center text-xs w-[280px] mb-[30px]">
      {page === "aggregator" && (
        <ReactSelect
          className="w-full"
          value={filters?.aggregator}
          placeholder="мониторинг"
          options={monitoringOptions}
          filterOption={createFilter(selectFilter)}
          onChange={(e) => dispatch(setControlFilters({ attr: "aggregator", value: e }))}
          isMulti
        />
      )}
      <ReactSelect
        className="w-full"
        value={filters?.site}
        placeholder="сайт"
        options={sitesOptions}
        filterOption={createFilter(selectFilter)}
        onChange={(e) => dispatch(setControlFilters({ attr: "site", value: e }))}
        isMulti
      />
      <ReactSelect
        className="w-full"
        value={filters?.from}
        placeholder="from"
        options={fromOptions}
        filterOption={createFilter(selectFilter)}
        onChange={(e) => dispatch(setControlFilters({ attr: "from", value: e }))}
        isMulti
      />
      <ReactSelect
        className="w-full"
        value={filters?.to}
        placeholder="to"
        options={toOptions}
        filterOption={createFilter(selectFilter)}
        onChange={(e) => dispatch(setControlFilters({ attr: "to", value: e }))}
        isMulti
      />
      <ReactSelect
        className="w-full"
        value={filters?.city}
        placeholder="город"
        options={citiesOptions}
        filterOption={createFilter(selectFilter)}
        onChange={(e) => dispatch(setControlFilters({ attr: "city", value: e }))}
        isMulti
      />
      <button
        className="text-lightGray border-b border-lightGray px-[28px] duration-300 hover:text-lightFont hover:border-lightFont"
        onClick={() => dispatch(clearControlFilters())}>
        Сбросить фильтры
      </button>
    </div>
  );
};

export const controlFilter = (item: TReport, filters: TControlFilters) => {
  return (
    // aggregator
    (filters?.aggregator !== null && !!filters?.aggregator?.length && "monitoring_name" in item
      ? getArrLabels(filters, "aggregator")?.includes(item?.monitoring_name)
      : item) &&
    // site
    (filters?.site !== null && !!filters?.site?.length && ("site_url" in item || "url" in item)
      ? getArrLabels(filters, "site")?.includes(
          "site_url" in item ? item?.site_url : item?.url?.split("//")[1]
        )
      : item) &&
    // from
    (filters?.from !== null && !!filters?.from?.length && "from_name" in item
      ? getArrLabels(filters, "from")?.includes(item?.from_name)
      : item) &&
    // to
    (filters?.to !== null && !!filters?.to?.length && "to_name" in item
      ? getArrLabels(filters, "to")?.includes(item?.to_name)
      : item) &&
    // city
    (filters?.city !== null && !!filters?.city?.length && "city_code" in item
      ? getArrValues(filters, "city")?.includes(item?.city_code)
      : item)
  );
};

export const siteFilter = (item, filters: TControlFilters) => {
  return filters?.site !== null && !!filters?.site?.length && "url" in item
    ? getArrValues(filters, "site")?.includes(item?.url?.split("//")[1])
    : item;
};

export const vectorFilter = (item, filters: TControlFilters) => {
  return (
    // from
    (filters?.from !== null && !!filters?.from?.length
      ? ("currency_from" in item
          ? getArrLabels(filters, "from")?.includes(item?.currency_from)
          : item) &&
        ("from_code" in item ? getArrLabels(filters, "from")?.includes(item?.from_code) : item)
      : item) &&
    // to
    (filters?.to !== null && !!filters?.to?.length
      ? ("currency_to" in item ? getArrLabels(filters, "to")?.includes(item?.currency_to) : item) &&
        ("from_code" in item ? getArrLabels(filters, "to")?.includes(item?.to_code) : item)
      : item)
  );
};

export const cityFilter = (item, filters: TControlFilters) => {
  return filters?.city !== null && !!filters?.city?.length && "city_code" in item
    ? getArrValues(filters, "city")?.includes(item?.city_code)
    : item;
};
