import { memo } from "react";
import { cn } from "src/helpers/cn";
import { useOnOffToggleExpanded } from "src/hooks/useOnOffToggleExpanded";
import { AccordionLabel } from "src/pages/Panel/OnOff/OnOffTimeline/components/common/AccordionLabel";
import CurrencyLabel from "src/pages/Panel/OnOff/OnOffTimeline/components/common/CurrencyLabel";
import { CurrencyToWithSites } from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/CurrencyToWithSites";
import {
  confirmDelete,
  confirmUndo,
} from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/TimelineData";
import {
  CurrencyPairsGroupedByFrom,
  ICity,
  OnOffItemToDeleteType,
} from "src/types/OnOffTimeline/common";
import { OnOffDataEntityType, ExpandActionType } from "src/types/OnOffTimeline/store";
import {
  selectOnOffDeleteMode,
  selectOnOffIsEdited,
  selectOnOffIsNew,
} from "src/store/onOffTimelineReducer/selectors";
import {
  onOffTimeLineUndo,
  setOnOffConfirmation,
  toggleExpandAllFrom,
} from "src/store/onOffTimelineReducer/slice";
import { deleteOnOffEntity } from "src/store/onOffTimelineReducer/thunks/deleteOnOffEntity";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { DeleteConfirmText } from "src/pages/Panel/OnOff/OnOffTimeline/components/popups/OnOffConfirmPopup";
import { useSelector } from "react-redux";

interface CurrencyPairGroupedProps {
  group: CurrencyPairsGroupedByFrom;
  city: Partial<ICity>;
  index: number;
}

export const CurrencyPairGrouped = memo(({ group, city }: CurrencyPairGroupedProps) => {
  const dispatch = useAppDispatch();
  const selector = `${city.city_code}_${group.groupName}_${group.currency_from.currency_name}`;
  const deleteMode = useSelector(selectOnOffDeleteMode);
  const isNew = useAppSelector((state) =>
    selectOnOffIsNew(state, {
      type: OnOffDataEntityType.FROM_PAIR,
      cityCode: city.city_code,
      from: group.currency_from,
    })
  );
  const isEdited = useAppSelector((state) =>
    selectOnOffIsEdited(state, {
      type: OnOffDataEntityType.FROM_PAIR,
      cityCode: city.city_code,
      from: group.currency_from,
    })
  );

  const label = `${city.city_name} ${group.currency_from.currency_name}?`;
  const name = `${city.city_code}_${group.groupName}_${group.currency_from.currency_name}`;

  const onDelete = () => {
    dispatch(
      setOnOffConfirmation({
        title: confirmDelete,
        subtitle: label,
        content: (
          <DeleteConfirmText text="Удаленные сайты сохранят свои последние данные, которые будут доступны здесь" />
        ),
        cb: () =>
          dispatch(
            deleteOnOffEntity({
              type: OnOffItemToDeleteType.CURRENCY_GROUP,
              name,
              isNew,
            })
          ),
      })
    );
  };

  const undo = () => {
    dispatch(
      setOnOffConfirmation({
        title: confirmUndo,
        subtitle: label,
        cb: () => {
          dispatch(onOffTimeLineUndo(name));
        },
      })
    );
  };
  const { isExpanded, isFullyExpanded, toggleIsExpanded } = useOnOffToggleExpanded({
    type: OnOffDataEntityType.FROM_PAIR,
    selector,
  });

  return (
    <div className="grid grid-cols-[160px_1fr] last:border-none border-gray/10 mt-[8px] overflow-clip first:mt-0 shadow-[15px_0px_30px_-15px_rgba(0,0,0,0.3)]">
      <div className="sticky left-0 text-xs text-left z-[7] bg-bg pt-4 pl-[20px] w-full bg-gradient-to-r from-[#CCCCFF23] via-[#CCCCFF23] to-[#CCCCFF06]">
        <AccordionLabel
          undoable={isEdited && !deleteMode}
          undo={undo}
          undoClassname="text-[11px] -mt-[3px]"
          plusClassname="ml-[0]"
          customLabelComponent={<CurrencyLabel currency={group.currency_from} />}
          labelClassname={cn(
            "py-[10px]",
            deleteMode && "border border-red bg-[#D7443E11] hover:bg-[#D7443E33] duration-300"
          )}
          containerClassname={cn(isEdited && !isNew && "onOffEdited", isNew && "onOffNew")}
          isFullyExpanded={isFullyExpanded}
          isExpandable={!deleteMode}
          onClick={() => {
            dispatch(
              toggleExpandAllFrom({
                type: isFullyExpanded ? ExpandActionType.COLLAPSE : ExpandActionType.EXPAND,
                selector,
              })
            );
          }}
          notExpandableOnClick={onDelete}
          isExpanded={isExpanded}
          onClickClassic={toggleIsExpanded}
          classicExpand
        />
      </div>
      {isExpanded ? (
        <div className="flex flex-col w-full">
          {group.currencies_to.map((item, i) => (
            <CurrencyToWithSites
              key={i}
              data={item}
              city={city}
              groupName={group.groupName}
              currencyFrom={group.currency_from}
            />
          ))}
        </div>
      ) : (
        <div className="sticky left-[200px] w-fit flex justify-start items-center font-bold text-grayDark ml-[12px]">
          <button
            className="hover:bg-gray/10 rounded-sm transition ml-[6px] px-[6px] aspect-square flex items-center"
            onClick={toggleIsExpanded}>
            <span className="flex items-center align-middle">...</span>{" "}
          </button>
          <span className="text-xs flex h-[20px] text-gray/60 ml-[4px] mt-[6px]">
            ({group.currencies_to.length})
          </span>
        </div>
      )}
    </div>
  );
});
