import { Dispatch, SetStateAction, useEffect, useState } from "react";

type TProps = {
  id: string | number;
  initialValue: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  updFn: () => void;
  isCut?: boolean;
  isParagraphs?: boolean;
};

export const EditedTextarea = ({
  id,
  initialValue,
  value,
  setValue,
  updFn,
  isCut,
  isParagraphs,
}: TProps) => {
  const [isEdit, setEdit] = useState(false);
  const textarea = document.getElementById(`${id}textarea`);
  const disabled = !value.trim().length;

  useEffect(() => {
    if (isEdit) {
      textarea?.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    setEdit(false);
  }, [initialValue]);

  return (
    <div className="text-left px-8">
      {isEdit ? (
        <>
          <textarea
            className={`relative top-0 w-full h-[100px] text-xs px-8 border border-stale rounded-sm`}
            value={value}
            id={`${id}textarea`}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={async (e) => {
              if (e.keyCode === 13 && !!e.currentTarget?.value.trim().length && !e.ctrlKey) {
                await updFn();
                setEdit(false);
              }
              if (e.keyCode === 13 && e.ctrlKey) {
                setValue(value + `\n`);
              }
            }}
          />
          <div className="w-full flex gap-4 justify-end items-center">
            <button
              className="w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#989CA4] text-base hover:brightness-90"
              onClick={() => setEdit(false)}>
              <span className={`relative top-[-1px] rotate-45 left-[1px]`}>+</span>
            </button>
            <button
              className={`w-[16px] min-w-[16px] h-[16px] bg-[#62F5B3] flex justify-center items-center rounded-sm text-white text-base hover:brightness-110 ${
                disabled ? "opacity-20" : ""
              }`}
              disabled={disabled}
              onClick={async () => {
                await updFn();
                setEdit(false);
              }}>
              <span className={`relative top-[-0.5px] font-bold text-xs`}>✓</span>
            </button>
          </div>
        </>
      ) : (
        <div className={`cursor-pointer ${isCut ? "cut-text" : ""}`} onClick={() => setEdit(true)}>
          {isParagraphs
            ? value?.split(`\n`)?.map((el, index) => (
                <div
                  key={index}
                  className="grid grid-cols-[16px_1fr_16px] gap-x-8 py-2 items-start">
                  <div className="flex justify-center items-center rounded-sm bg-glass-gray h-[16px] w-[16px] min-w-[16px]">
                    {index + 1}
                  </div>
                  <div>{el}</div>
                </div>
              ))
            : value || " - "}
        </div>
      )}
    </div>
  );
};
