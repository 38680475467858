import { Dispatch, SetStateAction } from "react";
import { Count } from "src/components/UI/notice/Count";
import { CloseButton } from "src/components/UI/buttons/CloseButton";

type TProps = {
  item;
  isEdit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  isAccess: boolean;
  updFn: () => void;
  attr: string;
  percent: number;
  rgb: string;
  isMobileHidden?: boolean;
  isVisibleNull?: boolean;
};

export const EditedField = ({
  item,
  isEdit,
  setEdit,
  value,
  setValue,
  isAccess,
  updFn,
  attr,
  percent,
  rgb,
  isMobileHidden,
  isVisibleNull,
}: TProps) => {
  return (
    <>
      {isEdit ? (
        <div
          className={`${
            isMobileHidden ? "hidden sm:flex" : "flex"
          } items-center gap-2 relative left-[-5px] top-[-2px]`}>
          <input
            type="number"
            className="w-[40px] text-center border border-[#282c3433] rounded-sm py-0 max-h-[18px]"
            defaultValue={value}
            step={1}
            onChange={(e) => setValue(+e.target.value)}
            onWheel={(e) => {
              e.currentTarget?.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                updFn();
              }
            }}
          />
          <button
            className="w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#282c3466] text-[9px] font-extrabold"
            onClick={() => updFn()}>
            {`\u2713`}
          </button>
          <CloseButton setFn={setEdit} />
        </div>
      ) : (
        <div
          className={`${isMobileHidden ? "hidden sm:flex" : "flex"}  w-full justify-center ${
            isAccess && "cursor-pointer"
          }`}
          onClick={() => {
            if (isAccess) {
              setEdit(true);
              setValue(item[attr]);
            }
          }}>
          {isVisibleNull && (!item[attr] || item[attr] === 0) ? (
            "0"
          ) : (
            <Count rgb={rgb} percent={percent} count={item[attr]} />
          )}
        </div>
      )}
    </>
  );
};
