import { Url } from "src/api/constants";

export const features = [
  {
    id: "2.16",
    title: "Отчеты статистики. Выставляйте план и статистику по клиентам",
    date: "2 мая 2024",
    access: "stats_get",
    tools: [
      {
        title: "Отчеты статистики",
        route: {
          title: "/ Статистика / Отчеты",
          path: `${Url.STATS_REPORTS}`,
        },
        description: [
          "Отчеты по городам день / ночь подтягиваются в реальном времени.",
          "Вы можете добавить/убрать векторы, по которым будет считаться отчет, изменять количество клиентов за 30 мин и за день / ночь, план-процент, рассчет по которому считается статистика для города.",
          "Каждые полчаса обнуляется количество клиентов за 30 минут, и со сменой периода (день / ночь) - обнуляется количество клиентов за период.",
          "Обратите внимание, что отчет отобразится только для последнего периода (день / ночь) - по Вашим запросам мы не храним истории отчетов. Отчеты отправляются в ТГ чат, где Вы можете увидеть все зафиксированные за временной промежуток данные.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "2.15",
    title: "Подтверждайте свою квалификацию с новой обучающей платформой",
    date: "25 апреля 2024",
    access: "courses_get",
    tools: [
      {
        title: "Криптарий",
        route: {
          title: "/ Криптарий",
          path: `${Url.ACADEMY}`,
        },
        description: [
          "Мы расширяем экосистему внутренних сервисов.",
          "Создана платформа для тестирования специалистов. Ваши старшие коллеги теперь могут создавать тесты для подтверждения вашей квалификации - и по их успешному прохождению система сама может повышать уровень доступов пользователей.",
          "Академия сейчас работает в тестовом режиме для специалистов отдела курсовиков. Если у вас будут пожелания по интерфейсу или если Вы заметите некорректность в работе - поделитесь с командой разработчиков. Спасибо за Вашу обратную связь.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "2.14",
    title: "Карты в автокоре. Восстановление доступов",
    date: "5 апреля 2024",
    access: "direction_get",
    tools: [
      {
        title: "Тетрис Автокор",
        route: {
          title: "/ Тетрис / Автокор",
          path: `${Url.TETRIS}?page=autocorr`,
        },
        description: [
          "Добавили в Тетрис / Автокор карточные направления и крипторубль.",
          "Курсы по картам (рубль) и крипторублю берут курс рубля.",
          "Исправили ошибку Infinity/NaN в рассчете процента по результирующему курсу. Если курс не подтянулся - функция просто не доступна и подсказка не отображается. ",
        ],
        icons: [],
      },
      {
        title: "Админ",
        route: {
          title: "/ ???",
          path: ``,
        },
        description: [
          "Добавили новый уровень прав для топ-курсовиков.",
          "Теперь в случае утери пароля / OTP вы можете обратиться за помощью к своему старшему коллеге. Это значительно ускоряет восстановление учетных записей. У топ-курсовиков также появились дополнительные привелегии - и мы готовимся к тому чтобы дать им больше возможностей в сфере подтверждения квалификации их младших коллег",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "2.13",
    title: "Обновление запросов к айти",
    date: "29 февраля 2024",
    access: "it_requests_get",
    tools: [
      {
        title: "Запросы к айти",
        route: {
          title: "/ Запросы к айти",
          path: Url.REQUESTS,
        },
        description: [
          "Добавлена возможность прикреплять к запросу новые типы файлов: видео, пдф, текстовые файлы.",
          "Также добавлена возможность посмотреть статистику по количеству разных запросов за определенный промежуток времени. Кнопка для показа блока со статистикой находится в левом верхнем углу страницы.",
        ],
      },
    ],
  },
  {
    id: "2.11",
    title: "Создайте расписание включения - выключения сайтов с таймлайном",
    date: "12 февраля 2024",
    access: "on-off_get",
    tools: [
      {
        title: "Вкл/выкл таймлайн",
        route: {
          title: "/ Вкл/выкл таймлайн",
          path: Url.ONOFF_TIMELINE,
        },
        description: [
          "С помощью интерфейса можно просматривать текущую информацию по включенности сайтов на таймлайне, предусмотрены фильтры и возможность изменять зум.",
          "Данные организованы по группам, при необходимости их можно скрывать или показывать (раскрывать/закрывать).",
          "Чтобы запросить данные, сначала необходимо выбрать фильтры. Единственным обязательным фильтром являются сайты, по которым нужно показать информацию, они выбираются из списка в левой части страницы.",
          "Средствами таймлайна данные можно удалять и редактировать. Редактирование реализовано как точечное, так и посредством копирования данных для одного сайта в другие.",
          "Все изменения, сделанные пользователем, отображаются на таймлайне специальными отрезками с иконкой автора. Изменения пользователя можно отменять - как каждое по отдельности, так и все сразу",
          "С помощью тетриса можно добавить новые данные на таймлайн.",
          "Есть 2 режима создания новых данных: объединение и замена. Выбор режима производится на финальном этапе создания. В режиме объединения при наличии старых данных, которые пересекаются с новыми, данные будут объединены. В режиме замены все старые данные просто заменяются новыми.",
          "При создании новых данных также есть опция сначала посмотреть на результат. При выборе этой опции данные не отправятся на сервер, а отобразятся в интерфейсе. Затем можно будет их подредактировать при необходимости и уже с помощью кнопки 'Сохранить' из верхнего меню отправить их на сервер.",
          "Для того, чтобы предварительный просмотр был доступен, все данные по сайтам, которые планируется отредактировать, должны уже быть загружена в приложении.",
        ],
      },
    ],
  },
  {
    id: "2.10",
    title: "Высчитывайте процент для нужного курса в Автокоре",
    date: "6 февраля 2024",
    access: "direction_get",
    tools: [
      {
        title: "Тетрис Автокор",
        route: {
          title: "/ Тетрис / Автокор",
          path: `${Url.TETRIS}?page=autocorr`,
        },
        description: [
          'Появилась возможность в Тетрис / Автокоре получать подсказку по проценту. Функция доступна при выборе одной валютной пары. Нажмите на "Курс" и введите нужный курс - процент подсчитается сам.',
        ],
        icons: [],
      },
    ],
  },
  {
    id: "2.9",
    title: "Новые типы в админке текстов",
    date: "31 января 2024",
    access: "text_admin",
    tools: [
      {
        title: "Тексты",
        route: {
          title: "/ Сайты / Тексты",
          path: Url.TEXT,
        },
        description: [
          "Полезно хранить тексты в одном месте.",
          "Работа с текстами по направлениям хорошо себя показала. Теперь мы привязали к админке текстов так же типы текстов, которые не зависят от направлений. Заголовки писем, уведомления об успешной регистрации или исполненной заявке, инструкция по восстановлению пароля.",
          "Новые типы не являются обязательными, поэтому **ошибки** по ним не будут отображаться в боковом меню.",
          "Созданные тексты по новым типам также будут появляться в **Добавленных текстах**.",
          "Сайты, интегрируемые на **Ядро**, по умолчанию будут подтягивать тексты по новым типам.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "2.8",
    title: "Предновогоднее расхламление",
    date: "25 декабря 2023",
    access: "stats_get",
    tools: [
      {
        title: "",
        route: {
          title: "",
          path: "",
        },
        description: ["Без сожалений расстаемся со старым:"],
        icons: [
          {
            bg: "transparent",
            color: "#52D58E",
            content: `\u25CF`,
            text: 'Удалены вкладки "Направления", "Миксер".',
          },
          {
            bg: "transparent",
            color: "#52D58E",
            content: `\u25CF`,
            text: 'Временно убрали неработающий функционал: "Бустер статистики" и "Лимиты".',
          },
          {
            bg: "transparent",
            color: "#52D58E",
            content: `\u25CF`,
            text: "Убрали Гара из ставки, раньше он был доступен на валютных парах с рублем, но тестирование плохо себя показало.",
          },
        ],
      },
      {
        title: "",
        route: {
          title: "",
          path: "",
        },
        description: ["Команда разработки поздравляет Вас с наступающим Новым годом!❤️"],
      },
    ],
  },
  {
    id: "2.7",
    title: "Приоритетные источники курсов. Корректное время не только в Москве",
    date: "17 ноября 2023",
    access: "stats_get",
    tools: [
      {
        title: "Курсы",
        route: {
          title: "/ Курсы",
          path: Url.RATES,
        },
        description: [
          "Курсы, сохраненяемые в избранное, теперь привязываются к источнику и отображаются в боковом меню в соответствии с источником (BINANCE / COINBASE / ...). ",
          "Не удивляйтесь, если заметите, что курсы, которые вы сохраняли ранее, пропадут. Избранные векторы хотят уточнения биржи. Нужно зайти на страницу курсов, сбросить старые избранные и выбрать новые. Векторы запомнят выбранный источник.",
        ],
      },
      {
        title: "Тетрис Автокор",
        route: {
          title: "/ Тетрис / Автокор",
          path: `${Url.TETRIS}?page=autocorr`,
        },
        description: [
          "Мы заметили, что подсказки о результирующем курсе автокора в редких случаях смотрят не на ту биржу, по которой идет выставление.",
          "Мы исправили подсказки в тетрисе - теперь они тянут курс из приоритетного источника, как и должно быть. Для пар состоящих из обоих USD-валют (например USDTTRC - CASHUSD) приоритетным является OUR_COURSE, для остальных - BINANCE. Если какой-то источник исчезнет, курс подсказки будет смотреть на менее приоритетный, при и его отсутствии - на первый найденный по направлению.",
        ],
      },
      {
        title: "Статистика",
        route: {
          title: "/ Статистика",
          path: Url.STATS,
        },
        description: [
          "Мы заметили, что в нескольких сервисах время может некорректно отображаться для других часовых поясов (не по Москве).",
          "Из-за этого Статистика для этих пользователей может выбрасывать алерты об устаревшести, когда Статистика актуальная. Также работа с Таймлайном может стать невыносимой для них.",
          "Мы обновили учет смещения часового пояса.",
          "Команда разработки провела тестирование, но если Вы заметите некорректность в работе - сообщите об этом. Спасибо за Вашу обратную связь.",
        ],
        icons: [
          {
            bg: "#CCC",
            color: "#FFF",
            content: `!`,
            text: "Обратите внимание, что во вкладках Курсы (последнее обновление курса бирж) и Контроль - время отображается по Москве.",
          },
        ],
      },
    ],
  },
  {
    id: "2.6",
    title: "Таймлайн расставляет пересекающиеся векторы экономичнее",
    date: "9 ноября 2023",
    access: "cluster_get",
    tools: [
      {
        title: "Таймлайн",
        route: {
          title: "/ Таймлайн",
          path: Url.CLUSTER_TIMELINE,
        },
        description: [
          "Мы улучшили алгоритм распределения пересекающихся векторов внутри процента таймлайна. Теперь они стараются занимать еще меньше места.",
          'Как **бонус** - векторы перестали "убегать" при редактировании, если они не получают новых пересечений.',
        ],
        icons: [],
      },
    ],
  },
  {
    id: "2.5",
    title: "Таймлайн сам чистит пустые ряды по утрам",
    date: "8 ноября 2023",
    access: "cluster_get",
    tools: [
      {
        title: "Таймлайн",
        route: {
          title: "/ Таймлайн",
          path: Url.CLUSTER_TIMELINE,
        },
        description: [
          "Выбирать нужные векторы бывает утомительно. Ранее мы добавляли функционал по сохранению пустых рядов таймлайна, для того, чтобы добавление векторов не забирало много времени. В соответствии с этим, ряды не удалялись до явной команды удалить их.",
          "**Бывает так, что в полночь**, когда применяется **новый шаблон** - если есть города/векторы, которые были в прошлом таймлайне, а в новом их нет - образовывались пустые ряды.",
          "Они бывают надоедливы.",
          "Что такое **пустые** ряды?",
          "Это строки в которых НИЧЕГО нет.",
          "Не сохранненные изменения не считаются пустотой. Чтобы очистить свои несохраненные изменения в Текущем Таймлайне - пользуйтесь **красным крестиком**. В шаблоне - обновите страницу или выйдите из него.",
          'Мы сделали так, чтобы при первом посещении таймлайна в новый день, таймлайн сам чистил пустые ряды. Если вы открыли таймлайн и увидели в нижнем левом углу плашку **"Пустые ряды успешно проверены и очищены"** - значит это только что произошло.',
          "Так же мы добавили **кнопку с человечком** в меню, который чистит пустые ряды, когда вам удобно. Обратите внимание, что пустые ряды сбросятся **у всех пользователей**.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "2.4",
    title: "Создавайте любые комбинации для рассчета статистики. Смотрите отчеты",
    date: "6 ноября 2023",
    access: "stats_get",
    tools: [
      {
        title: "Статистика",
        route: {
          title: "/ Статистика",
          path: Url.STATS,
        },
        description: [
          "Чтобы создать/удалить рассчет - нажмите на кнопку **X / Y** в верхнем меню на странице Статистики.",
          "Выберите нужный рассчет для **каждого города**, чтобы кастомизировать Вашу страницу статистики. Города запомнят, по какому рассчету Вы хотите видеть статистику для них.",
        ],
        icons: [
          {
            bg: "#CCC",
            color: "#FFF",
            content: `!`,
            text: "Новая статистика не будет отображаться, если нет **хотя бы одного** рассчета.",
          },
          {
            bg: "#CCC",
            color: "#FFF",
            content: `!`,
            text: "Пожалуйста, не создавайте слишком много рассчетов! Статистика будет считаться медленнее.",
          },
          {
            bg: "#CCC",
            color: "#FFF",
            content: `!`,
            text: "Обратите внимание, что **Алерты** в боковом меню по прежнему приходят в соответствии со старой статистикой. Мы соберем от Вас обратную связь по статистике и примем решение, что с ними делать, позже.",
          },
          {
            bg: "#CCC",
            color: "#FFF",
            content: `!`,
            text: "Мы пока оставили возможность просматривать старую статистику. После позитивного прохождения тестового периода **старая статистика исчезнет**.",
          },
        ],
      },
      {
        title: "Отчеты",
        route: {
          title: "/ Статистика / Отчеты",
          path: Url.STATS_REPORTS,
        },
        description: [
          "Отчеты по статистике переезжают к нам из телеграмма.",
          "Чтобы попасть в отчеты нужно либо открыть их через боковое навигационное меню, либо найти в голубых табах на вкладках раздела статистики.",
          "Отчеты отображаются за последние **2 дня**.",
          "Мы добавили возможность сравнивать отчеты по дням, фильтровать и показывать только нужные.",
          "Отчеты доступны по всем созданным рассчетам. Отчеты начнут формироваться после **создания рассчета**. При создании расчета отчетов за прошедшее время не будет.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "2.3",
    title: "Баги шаблонов таймлайна пофикшены. Копируйте города между шаблонами.",
    date: "26 сентября 2023",
    access: "cluster_get",
    tools: [
      {
        title: "Таймлайн",
        route: {
          title: "/ Таймлайн",
          path: Url.CLUSTER_TIMELINE,
        },
        description: [
          "Пофикшен **баг** с изменениями-удалениями в шаблонах. Проблема 1 попытки при создании шаблона исчерпана. Теперь редактирование Шаблона ведет себя так же как же как и Текущий Таймлайн за исключением отслеживания изменений других пользователей в реальном времени. Несохраненные изменения по прежнему нужно сохранять перед обновлением страницы, промежуточные изменения для Шаблонов не сохраняются.",
          "Мы добавили **переименование** шаблонов. Для того чтобы переименовать наблон - откройте его и нажмите на название. Подтвердите новое название на enter.",
        ],
        icons: [
          {
            bg: "transparent",
            color: "#282c34",
            content: `\u25E8`,
            text: '**Двухоконный режим**. Мы реализовали копирование городов "из-таймлана-в-шаблон" и "из-шаблона-в-шаблон". В шаблонах нажмите на икноку "Открыть справа" и выберите, какой шаблон вы хотите открыть в правом окне. Мы добавили эту функцию для копирования, но весь остальной функционал так же хорошо рабоотает в двухоконном режиме.',
          },
          {
            bg: "transparent",
            color: "#E7444E",
            content: `\u2665`,
            text: "Это может быть незаметно, но Таймлайн претерпел много изменений. Команда разработки провела тестирование, но если Вы заметите некорректность в работе - сообщите об этом. Спасибо за Вашу обратную связь.",
          },
        ],
      },
    ],
  },
  {
    id: "2.2",
    title: "Непрочитанные сообщения в запросах к IT",
    date: "1 сентября 2023",
    access: "it_requests_get",
    tools: [
      {
        title: "Поддержка",
        route: {
          title: "/ Поддержка",
          path: Url.REQUESTS,
        },
        description: [
          "Мы добавили функцию непрочитанных сообщений. Уведомления появляются в боковом меню и в шапке, если вы **запинните Поддержку**.",
          "Когда сообщение отображается непрочитанным?",
          "Когда происходит любое изменение (смена статуса, приоритета, новые изображения или комментарии) в запросе, в котором где-то обнаружено Ваше участие. Либо вы **создали** его, либо **комментировали**, либо вас кто-то **тегнул**. Это сделано для того, чтобы не отвлекать вас на запросы, с которыми вы не связаны.",
        ],
        icons: [
          {
            bg: "#BBFFDAAA",
            color: "#3BC57A",
            content: `@`,
            text: "Если вы хотите, чтобы конкретному пользователю точно отобразилось сообщение как непрочитанное - **тегните** его. Для этого напишите ник человека в любом месте (заголовке, описании, комментарии). Достаточно одного тега на запрос чтобы пользователю отображались уведомления о каждом новом изменении.",
          },
          {
            bg: "transparent",
            color: "#E7444E",
            content: `\u25CF`,
            text: '**Непрочитанные** сообщения подсвечиваются зеленым и выделяются красной точкой. Так же в фильтрах появилась кнопка "Показать только непрочитанные". Чтобы отметить сообщение прочитанным - откройте его. Или нажмите кнопку "Прочитать все".',
          },
          {
            bg: "transparent",
            color: "#DAE0ED",
            content: `\u25A0`,
            text: "Обычно запросы создаются для того, чтобы на них отвечали разработчики. Если увидите сообщение подвеченное серо-синим цветом - его создал кто-то из **команды разработки** и это запрос к Вам. Ответьте на него.",
          },
        ],
      },
    ],
  },
  {
    id: "2.1",
    title: "Создавая тексты, добавляйте языки и отслеживайте пропущенные тексты",
    date: "1 сентября 2023",
    access: "text_admin",
    tools: [
      {
        title: "Тексты",
        route: {
          title: "/ Сайты / Тексты",
          path: Url.TEXT,
        },
        description: ["В админке текстов появились новые функции:"],
        icons: [
          {
            bg: "#D0FFB7",
            color: "#111",
            content: `А`,
            text: "Создание текстов на любые языки. Создавайте языки и привязывайте к ним тексты.",
          },
          {
            bg: "#282c34",
            color: "#FFF",
            content: `С`,
            text: 'Админка текстов готовится к работе с карточными направлениями и направлениями "crypto-crypto". Скоро появится возможность создавать не-cash направления.',
          },
          {
            bg: "#BABAC322",
            color: "#BABAC3",
            content: `\u25BC`,
            text: 'Селекты дают больше информации. Раньше при выборе "Векторы сайта" - в предложенных векторах появлялись только векторы **включенные на сайте** на данный момент времени. Сейчас отображаются также векторы, на которые **созданы тексты**. Серые опции селекта **языка** скажут о том, что еще нет ни одного текста по данному языку. Серые опции **города** - о том что ни один вектор по городу не включен на сайте в настоящий момент. Серые опции **вектора** - о том что вектор выключен на сайте.',
          },
          {
            bg: "transparent",
            color: "transparent",
            content: ``,
            text: "",
          },
          {
            bg: "transparent",
            color: "#52D58E",
            content: `\u25CF`,
            text: 'Мы убрали селекты для просмотра существующих текстов. Чтобы просмотреть тексты откройте в боковом меню **"Добавленные тексты"**. С помощью этого раздела можно сразу увидеть все созданные тексты сайта, по типам ("ДО", "ПОСЛЕ", "ТЕКСТЫ ПИСЕМ") и языкам.',
          },
          {
            bg: "transparent",
            color: "#BABAC3",
            content: `\u25A0`,
            text: "Мы добавили подсказки в боковое меню. **Серые подсказки** скажут о том что есть тексты на неактивных векторах. И это хорошо.",
          },
          {
            bg: "transparent",
            color: "#FCC815",
            content: `\u25B2`,
            text: "**Желтые подсказки** - проверяют сколько всего языков есть среди добавленных на сайт текстов и если язык пропущен - подскажут вектор. Добавив хотя бы один текст на новом языке - они напомнят, что работа еще не окончена. Если пропущен русский язык - это плохо.",
          },
          {
            bg: "transparent",
            color: "#E7444E",
            content: `\u25CF`,
            text: '**Красные подсказки** проверяют наличие обязательных типов текстов по векторам по обнаруженным языкам. Обязательными типами они считают тексты "ДО ЗАЯВКИ", "ПОСЛЕ ЗАЯВКИ" и "ТЕКСТЫ ПИСЕМ".',
          },
          {
            bg: "transparent",
            color: "#000",
            content: `\u2716`,
            text: "**Черные подсказки** - найдут город в котором есть хотя бы один включенный вектор и появятся, когда по этому вектору вообще не найдено никаких текстов. Это очень плохо.",
          },
        ],
      },
    ],
  },
  {
    id: "1.11",
    title: "Управляйте отображением связей в графах. Сравнивайте с энтропией",
    date: "18 июля 2023",
    access: "cluster_get",
    tools: [
      {
        title: "GRAPH Векторы",
        route: {
          title: "/ Сайты / GRAPH Векторы",
          path: Url.DIRECTION_GRAPH,
        },
        description: [
          "В графах добавилась возможность выбирать, какие связи требуется показать - сайт/город/валюты.",
          "Были внесены правки в фильтрацию.",
          "Энтропию теперь можно сравнить с графом без переключения вкладок - просмотр графа фиксируется в фоновом режиме.",
          "Добавилась возможность посмотреть, когда сайт был в последний раз на бесте по фильтрам.",
        ],
        icons: [],
      },
      {
        title: "Статистика",
        route: {
          title: "/ Статистика",
          path: Url.STATS,
        },
        description: [
          "В статистке появилось отображение векторов, включенных только на сайте (не на бесте).",
          "Если рядом с городом есть перечень векторов, Вы можете перейти во вкладку **Вкл/Выкл** по предложенной кнопке - необходимые фильтры подставятся - запросить данные и увидеть конкретные, потенциально проблемные, векторы на конкретных сайтах",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.10",
    title: "Шаблоны Таймлайна",
    date: "12 июля 2023",
    access: "cluster_get",
    tools: [
      {
        title: "Таймлайн",
        route: {
          title: "/ Таймлайн",
          path: Url.CLUSTER_TIMELINE,
        },
        description: [
          "Планируйте таймлайны на неделю вперед.",
          "Больше не нужно редактировать таймлайны каждый день. Создайте **шаблоны** - и расположите их по датам. В 00:00 при наличии запланированного шаблона - он автоматически применится.",
          "Есть возможность создать шаблон копией с выставленных значений (с сайтами или без), копией с существующего шаблона или с чистого листа.",
          "**Обратите внимание:**",
          "Создавайте **уникальные** названия. Мы заблокировали создание шаблонов с одинаковыми названиями специально, чтобы избавить вас от сомнений.",
          "Не сохраняйте пустые шаблоны (без сайтов) - векторы можно скопировать в любой момент с другого существующего шаблона. Сохранятся только те векторы, на которых есть хотя бы один сайт. Это защитит вас от пустых таймлайнов.",
          "Один день - один шаблон. Только так.",
          "Если менять шаблон, который **сегодня работает** - ничего сегодня не изменится - шаблоны применяются в полночь 🌑",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.9",
    title: "Диаграммы энтропии",
    date: "10 июля 2023",
    access: "direction_get",
    tools: [
      {
        title: "Энтропия",
        route: {
          title: "/ Сайты / GRAPH Векторы / Энтропия",
          path: `${Url.DIRECTION_GRAPH}?page=entropy`,
        },
        description: [
          "Мы сделали инструмент для визуализации опасностей, связанных со **схожестью** сайтов.",
          "Насколько схожи сайты по векторам, на которых они активны в момент времени, можно увидеть на диаграммах. Выберите больше параметров чтобы увидеть более точное сравнение. Если диаграмма красная - ситуация потенциально опасна.",
        ],
        icons: [],
      },
      {
        title: "",
        route: {
          title: "",
          path: "",
        },
        description: ["Так же пофиксили и добавили кое-что полезное:"],
        icons: [
          {
            bg: "#3BC57A",
            color: "#BBFFDA",
            content: `\u2749`,
            text: 'В **Графах** появилась фильтрация для выбора сайтов - можно заранее знать какие сайты отобразятся на графе выбрав **"сейчас на бесте"**. Или выбрать конкретный **момент времени** - для него можно выбрать сайты из всех, которые когда либо были включены. Это станет удобнее - мы планируем вспомогательную вкладку со справочными датами последнего зафиксированного присутствия сайта на бесте. Появилась возможность сравнивать наши сайты с не нашими.',
          },
          {
            bg: "#CCC",
            color: "#FFF",
            content: "%",
            text: "**Тетрис-расписания** теперь поддерживают выставление 0 процента",
          },
          {
            bg: "#CCC",
            color: "#FFF",
            content: `\u2196`,
            text: "Выйти из любого всплывающего окна теперь можно на клавишу **escape**. Или - для обладателей тачбаров - **§**",
          },
        ],
      },
    ],
  },
  {
    id: "1.8",
    title: "Обновленная навигация",
    date: "6 июля 2023",
    access: "",
    tools: [
      {
        title: "",
        route: {
          title: "",
          path: "",
        },
        description: [
          "Мы обновили навигацию и убрали повторяющиеся вкладки.",
          "Сделали фильтры еще лучше - при переходе по вкладкам, при возможности применить значения предыдущего выбора - значения применятся.",
        ],
        icons: [
          {
            bg: "#FFF",
            color: "#CCC",
            content: `\u2199`,
            text: "**Закрепите** для себя вкладки, которыми часто пользуетесь и они появятся в верхнем меню",
          },
        ],
      },
      {
        title: "Расписания кластеров",
        route: {
          title: "/ Тетрис / Расписание кластеров",
          path: `${Url.TETRIS}?page=schedule`,
        },
        description: ["Расписания в **тетрисе** теперь идут в таймлайн."],
        icons: [],
      },
    ],
  },
  {
    id: "1.7.1",
    title: "Графы направлений на бесте",
    date: "29 июня 2023",
    access: "direction_get",
    tools: [
      {
        title: "GRAPH Векторы",
        route: {
          title: "/ Панель / GRAPH Векторы",
          path: Url.DIRECTION_GRAPH,
        },
        description: [
          "Просматривайте активные направления сайтов на бесте с **графом**. Выберите сайт или несколько сайтов.",
          `**Графы** полезные - попробуйте и расскажите как сделать их еще лучше ${`\u2665`}.`,
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.7",
    title: "Просматривайте только инстересующую статистику",
    date: "29 июня 2023",
    access: "stats_get",
    tools: [
      {
        title: "Статистика",
        route: {
          title: "/ Статистика",
          path: Url.STATS,
        },
        description: [
          "Выбирайте только те города в статистике, которые хотите видеть. Ищите значок фильтра в меню. Данные сохранятся для последующих сессий.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.6",
    title: "Отслеживайте статусы запросов к IT. Выставляйте Таймлайны с Расписанием",
    date: "7 июня 2023",
    access: "it_requests_get",
    tools: [
      {
        title: "Запросы к IT",
        route: {
          title: "/ Запросы",
          path: Url.REQUESTS,
        },
        description: [
          "Все запросы теперь расположены по статусам. Нажмите на шапку колонки, чтобы увидеть только запрсы с нужным статусом.",
          "Мы сделали так, чтобы закрытые запросы отображались только за выбранный период (по дате последнего обновления). Это ускорило загрузку страницы.",
          "Появился поиск по строке. Отфильтруйте запросы по своему имени, чтобы найти все запросы, которые вы создавали или комментировали, или найдите быстро нужный запрос по названию или тегу.",
          "Прикрепленные картинки теперь можно листать.",
        ],
        icons: [
          {
            bg: "#9999FF",
            color: "#FFF",
            content: "?",
            text: 'Появился новый тип запроса - "Задача". Для задач доступно назначение дедлайнов.',
          },
        ],
      },
      {
        title: "Расписания кластеров",
        route: {
          title: "/ Кластеры / Расписание",
          path: Url.CLUSTER_SCHEDULE,
        },
        description: [
          "Мы привязали Расписания к Таймлайнам - теперь при выставлении Расписаний указывается именно процент таймлайна (0%, 20%...), процент кластера рассчитывается и присваивается в течении минуты. Выставленные значения отображаются на таймлайне.",
          "**Обратите внимание**, что функция доступна имеенно во вкладке Кластеры. Расписания в тетрисе по прежнему принимают обычный процент кластера. Решение представлено как временное, позже мы сделаем таймлайны более удобными для выставления.",
          `Спасибо, за Вашу обратную связь ${`\u2665`}.`,
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.5",
    title: "Планируйте кластеры с Таймлайном",
    date: "1 июня 2023",
    access: "cluster_get",
    tools: [
      {
        title: "Таймлайн",
        route: {
          title: "/ Кластеры / Таймлайн",
          path: Url.CLUSTER_TIMELINE,
        },
        description: [
          "Теперь представить время, в которое сайт находится на определенном проценте, по которому считается позиция, можно на графике.",
          "Фильтруйте выставленные значения по **топу** и **бесту** для формирования картины о прогнозах по ключевым задачам.",
        ],
        icons: [],
      },
      {
        title: "Админка Текстов",
        route: {
          title: "/ Тексты",
          path: Url.TEXT,
        },
        description: [
          'Добавлен новый тип текстов - "Детали заявки", для возможности использования дополнительного блока текста после заявки',
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.4",
    title: "Добавлять тексты на сайт стало проще.\nСводка по сайтам",
    date: "17 мая 2023",
    access: "direction_get",
    tools: [
      {
        title: "Админка Текстов",
        route: {
          title: "/ Тексты",
          path: Url.TEXT,
        },
        description: [
          "Создан сервис для добавления текстов (до, после заявки, тексты писем) сайтов в единое хранилище. Сайты будут обращаться к нему для актуализации текстов.",
          "Добавление осуществляется для конкретных городов и векторов с возможностью выбора множества валютных пар.",
          "Тексты поддерживают добавление переменных - номер заявки, суммы, валюты.",
          "**Обратите внимание** - чтобы тексты начали сообщаться с сайтом, важно изменить источник текстов в самом сайте. После этого обновление текстов на сайте через сервис будет происходить синхронно.",
        ],
        icons: [],
      },
      {
        title: "Сводка по сайтам",
        route: {
          title: "/ Панель / Сводка",
          path: Url.SUMMARY,
        },
        description: [
          "Мы добавили страницу с кратким резюме сайтов.",
          "Айдишки, ссылки, статусы и возможности обновления - необходимая справочная информация в быстром доступе.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.3.1",
    title: "Данные из таблицы minmax синхронизируются с minmax",
    date: "12 мая 2023",
    access: "direction_get",
    tools: [
      {
        title: "Таблица minmax",
        route: {
          title: "/ Панель / MinMax Таблица",
          path: Url.MINMAX_SHEET,
        },
        description: [
          "Добавлен функционал для перетягивания данных по минимальным/максимальным суммам из таблицы в БД сайта. Данные на сайте обновляются.",
          "Вы можете проверить результат синхронизации в старой вкладке MinMax.",
          "**Обратите внимание** -  синхронизируются только поля min и max. Резервы не фиксируются.",
        ],
        icons: [
          {
            bg: "#FFF",
            color: "#282C34",
            content: "❐",
            text: "Мы добавили copy-paste ячеек валют или времени. Данные ячейки копируются полностью, со всеми исключениями.",
          },
        ],
      },
    ],
  },
  {
    id: "1.3",
    title: "Таблица статусов сайтов в Аналитике",
    date: "12 мая 2023",
    access: "analytics_get",
    tools: [
      {
        title: "Аналитика",
        route: {
          title: "/ Аналитика",
          path: Url.ANALYTICS,
        },
        description: [
          "Мы добавили в дашборд Аналитики таблицу статусов векторов по сайтам. В таблице отображаются включенные направления **на сайте** (именно их обновляет парсер).",
          "В верхней панели дашборда есть фильтры. С помощью полей **turned_site_name**, **turned_from**, **turned_to**, **turned_city_code** можно влиять на таблицу в графане.",
          "Ишите Таблицу внизу дашборда.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.2",
    title: "Все инструмены с интерфейсом кластеров переехали в единый сервис",
    date: "11 мая 2023",
    access: "direction_get",
    tools: [
      {
        title: "Тетрис",
        route: {
          title: "/ Тетрис",
          path: Url.TETRIS,
        },
        description: [
          "Автокор, кластеры, расписания кластеров, BLACKLIST - теперь ими можно пользоваться в рамках одного сервиса.",
          "Мы улучшили фильтры. При обновлении страницы или возвращении - результаты поиска сохраняются.",
          "Вы можете делиться результатами поиска с коллегами передав ссылку, чтобы быстрее сообщить о проблеме.",
          "После позитивного прохождения тестового периода **старые вкладки удалятся**.",
        ],
        icons: [],
      },
    ],
  },
  {
    id: "1.1",
    title: "Таблица minmax переехала из google таблиц",
    date: "5 мая 2023",
    access: "minmax_get",
    tools: [
      {
        title: "Таблица minmax",
        route: {
          title: "/ Панель / MinMax Таблица",
          path: Url.MINMAX_SHEET,
        },
        description: [
          "Данные по minmax суммам и резервам теперь хранятся в админке.",
          "Обменники и валюты не нужно добавлять вручную - это происходит автоматически.",
          "Сайты отмечены определленым цветом в зависимости от того, представлены ли на Bestchange или Exnode. Если статус сайта изменится на агрегаторе - Вы это увидете.",
          "Добавлена возможность прикреплять и редактировать ссылки на **документы с текстами** обменников.",
        ],
        icons: [
          {
            bg: "#3BC57A",
            color: "#BBFFDA",
            content: "*",
            text: "Есть возможность добавлять **исключения** - minmax суммы и резервы для отдельных городов.",
          },
        ],
      },
    ],
  },
];
