import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Droppable, Draggable, DragDropContext, DropResult } from "react-beautiful-dnd";
import { useAppDispatch } from "src/store/store";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectBlocks, setBlocks } from "src/store/academyReducer";
import { EditBlock } from "../components/Block/EditBlock";
import { Block } from "../components/Block/Block";
import { BlockCard } from "../components/Block/BlockCard";

export const EditModuleBlocks = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const block_id = searchParams.get("block_id");
  const blocks = useSelector(selectBlocks);
  const [isCreate, setIsCreate] = useState(false);
  const currentBlock = blocks?.find((el) => el.block_id === +block_id);

  const reorderBlocks = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedBlock = blocks[result.source.index];
    const { response } = await fetchRequest(
      academyApi.updateBlock(reorderedBlock.block_id, {
        ...reorderedBlock,
        block_order: result.destination.index + 1 <= 0 ? 1 : result.destination.index + 1,
      }),
      {
        request: "Обновление блока",
      }
    );
    if (response) {
      dispatch(
        setBlocks(
          blocks?.map((el, index) =>
            index === result.source.index
              ? {
                  ...el,
                  block_order: response.block_order,
                }
              : el.block_order > response.block_order
              ? {
                  ...el,
                  block_order: el.block_order + 1,
                }
              : el
          )
        )
      );
    }
  };

  useEffect(() => {
    if (!block_id && !!blocks?.length) {
      searchParams.set("block_id", blocks[0].block_id?.toString());
      setSearchParams(searchParams);
    }
  }, [searchParams, blocks]);

  return (
    <div className="p-5">
      <h2>Блоки</h2>
      <div className="py-5">
        <DragDropContext onDragEnd={reorderBlocks}>
          <Droppable droppableId={"blocks"} type={"section"}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                className="flex flex-col gap-8"
                {...provided.droppableProps}>
                {!!blocks?.length &&
                  blocks?.map((el, index) => {
                    const key = !!el.block_id ? el.block_id : `${index}_new_block`;

                    return (
                      <Draggable key={key} draggableId={key.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <BlockCard item={el} />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {!isCreate ? (
                  <Draggable draggableId={"new_block"} index={-1}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <button
                          className="relative w-[280px] sm:w-[360px] bg-slate-50 shadow-md rounded-md flex justify-center items-center duration-300 hover:scale-105 hover:shadow-lg text-[32px] font-bold text-blue"
                          onClick={() => setIsCreate(true)}
                          {...provided.dragHandleProps}>
                          +
                        </button>
                      </div>
                    )}
                  </Draggable>
                ) : (
                  <div className="relative w-[280px] sm:w-[360px] bg-slate-50 shadow-md rounded-md flex justify-center items-center p-5">
                    <EditBlock setIsEdit={setIsCreate} />
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {currentBlock && <Block item={currentBlock} isEditPage />}
    </div>
  );
};
