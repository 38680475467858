interface Info {
  city_code: string;
  site_name: string;
  currency_from_name: string;
  currency_to_name: string;
  platform_name: ESource;
}

interface GetSiteUpdatedSegmentsProps extends Info {
  segments: ITimeSegment[];
  response: PostScheduleResponse;
}

interface MergeSegmentsProps extends Info {
  segments: ITimeSegment[];
  updatedSegments: OnOffSignal[];
}

import { PostScheduleResponse } from "src/api/schedule";
import { sortSegmentsByStart } from "src/helpers/onOffTimeline/combine";
import { findIntersection } from "src/helpers/onOffTimeline/copyPaste";
import {
  ICity,
  ITimeSegment,
  OnOffSignal,
  OnOffSignalToDelete,
} from "src/types/OnOffTimeline/common";
import { ESource } from "src/store/onOffReducer";

/**
 * на основе имеющихся данных и ответа сервера
 * формирует новые данные и возвращает их
 */
export const proceedPostServerResponse = (
  timelineData: ICity[],
  response: PostScheduleResponse
): ICity[] =>
  [...timelineData].map((city) => ({
    ...city,
    currency_pairs: city.currency_pairs.map((pair) => ({
      ...pair,
      sites: pair.sites.map((site) => ({
        ...site,
        segments_site: getSiteUpdatedSegments({
          segments: site.segments_site,
          city_code: city.city_code,
          site_name: site.site_name,
          currency_from_name: pair.currency_from.currency_name,
          currency_to_name: pair.currency_to.currency_name,
          platform_name: ESource.SITE,
          response,
        }),
        segments_best: getSiteUpdatedSegments({
          segments: site.segments_best,
          city_code: city.city_code,
          site_name: site.site_name,
          currency_from_name: pair.currency_from.currency_name,
          currency_to_name: pair.currency_to.currency_name,
          platform_name: ESource.BEST,
          response,
        }),
        unsubmitted_segments_best: [],
        unsubmitted_segments_site: [],
      })),
    })),
  }));

/**
 * на основе ответа сервера обрабатывает переданные отрезки
 * сначала фильтрует, удаляя все, что должно быть удалено
 * затем соединяет то что осталось с тем что пришло с сервера
 * и возвращает этот итоговый результат
 */
export const getSiteUpdatedSegments = ({
  segments,
  response,
  platform_name,
  currency_from_name,
  currency_to_name,
  city_code,
  site_name,
}: GetSiteUpdatedSegmentsProps): ITimeSegment[] => {
  const filteredSegments = filterSegments(segments, response.is_deleted.updated);
  const mergedSegments = mergeSegments({
    segments: filteredSegments,
    updatedSegments: response.is_changed.updated,
    city_code,
    site_name,
    currency_from_name,
    currency_to_name,
    platform_name,
  });
  return mergedSegments;
};

/**
 * на основании ответа от сервера фильтрует переданные отрезки,
 * удаляя те, что должны быть удалены
 */
export const filterSegments = (
  segments: ITimeSegment[],
  deletedSegments: OnOffSignalToDelete[]
): ITimeSegment[] => {
  return segments.filter(
    (segment) => !deletedSegments.some((deletedItem) => deletedItem.id === segment.id)
  );
};

/**
 * соединяет ответ сервера с тем, что имеется сейчас
 * сначала находит все данные, которые никак не затронуты сервером
 * эти данные считаются неизмененными и остаются
 * затем добавляет к этим данным ответ сервера
 * и возвращает итоговый результат
 */
export const mergeSegments = ({
  segments,
  updatedSegments,
  platform_name,
  city_code,
  currency_from_name,
  currency_to_name,
  site_name,
}: MergeSegmentsProps): ITimeSegment[] => {
  const filteredUpdatedSegments = updatedSegments.filter(
    (item) =>
      item.platform_name === platform_name &&
      item.city_code === city_code &&
      item.currency_from_name === currency_from_name &&
      item.currency_to_name === currency_to_name &&
      item.site_name === site_name
  );

  const noIntersectingSegments = segments.filter(
    (item) =>
      !filteredUpdatedSegments.some(
        (updatedItem) =>
          findIntersection(item, updatedItem) ||
          item.start === updatedItem.end ||
          item.end === updatedItem.start
      )
  );

  return [
    ...filteredUpdatedSegments.map((item) => ({
      id: item.id,
      start: item.start,
      end: item.end,
    })),
    ...noIntersectingSegments,
  ].sort(sortSegmentsByStart);
};
