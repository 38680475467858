import { findIntersection } from "src/helpers/onOffTimeline/copyPaste";
import { ITimeSegment, OnOffSignal, OnOffSignalToDelete } from "src/types/OnOffTimeline/common";
import { ESource } from "src/store/onOffReducer";
import { joinWithUnsubmitted } from "src/helpers/onOffTimeline/combine";

export interface GetUpdatedSegmentsForServerProps {
  segments: ITimeSegment[];
  unsubSegments: ITimeSegment[];
  city_code: string;
  currency_from_name: string;
  currency_to_name: string;
  platform_name: ESource;
  site_name: string;
}

export const getUpdatedSegmentsForServer = ({
  segments,
  unsubSegments,
  city_code,
  site_name,
  currency_from_name,
  currency_to_name,
  platform_name,
}: GetUpdatedSegmentsForServerProps) => {
  const to_change: OnOffSignal[] = [];
  const to_delete: OnOffSignalToDelete[] = [];

  // следим за айдишниками тех отрезков, которые мы решили расширить
  // чтобы далее не учитывать их при удалении
  const includedIds: Record<number, boolean> = {};

  // получаем то как отрезки будут выглядеть после применения всех изменений
  const finalSegments = joinWithUnsubmitted(segments, unsubSegments);

  // сначала соберем все данные для to_change:
  // то что добавилось или было изменено
  finalSegments.forEach((segment) => {
    for (let i = 0; i < segments.length; i++) {
      // если отрезки полностью совпадают, значит это старый отрезок, который не изменился
      // пропускаем его
      if (segment.start === segments[i].start && segment.end === segments[i].end) {
        return;
      }

      // если нашли пересечение с каким-то из отрезков старых данных,
      // значит новый отрезок - результат расширения старого
      // поэтому нам нужно присвоить айдишник старого новому
      // если айди уже использован, пропускаем данный отрезок
      if (findIntersection(segment, segments[i]) && !includedIds[segments[i].id]) {
        to_change.push({
          city_code,
          site_name,
          currency_from_name,
          currency_to_name,
          platform_name,
          start: segment.start,
          end: segment.end,
          id: segments[i].id,
        });
        // после присвоения id добавляем его в мап использованных
        includedIds[segments[i].id] = true;
        return;
      }
    }

    // если прошли весь цикл for, значит отрезок либо новый, либо старый и остался без изменений
    if (!segment.id) {
      // если у отрезка нет id - он новый, добавляем в массив
      to_change.push({
        city_code,
        site_name,
        currency_from_name,
        currency_to_name,
        platform_name,
        start: segment.start,
        end: segment.end,
      });
    }
  });

  // затем соберем все данные to_delete
  // в эту категорию войдут все отрезки которые были полностью удалены
  // и все отрезки которые были полностью поглощены новыми итоговыми данными
  // поэтому здесь мы итерируемся уже не по финальным отрезкам, а по изначальным
  segments.forEach((segment) => {
    let isDeleted = true;

    for (let i = 0; i < finalSegments.length; i++) {
      // если было найдено полное совпадение, значит, это старый отрезок, который остался без изменений
      // сразу же прекращаем дальнейшие манипуляции над этим отрезком
      if (segment.start === finalSegments[i].start && segment.end === finalSegments[i].end) {
        isDeleted = false;
        return;
      }

      if (findIntersection(segment, finalSegments[i])) {
        // если было найдено пересечение,
        // то, во-первых, мы отмечаем, что отрезок не был полностью удален
        isDeleted = false;

        // во-вторых, проверяем, учитывался ли он в наших предыдущих преобразованиях
        // если не учитывался - должен быть удален
        if (!includedIds[segment.id]) {
          to_delete.push({
            id: segment.id,
            city_code,
            site_name,
            currency_from_name,
            currency_to_name,
            platform_name,
          });
        }
      }
    }

    // если после проходки по финальным отрезкам
    // не было найдено ни одного отрезка который бы пересекался с текущим
    // значит текущий должен быть удален
    if (!includedIds[segment.id] && isDeleted) {
      to_delete.push({
        id: segment.id,
        city_code,
        site_name,
        currency_from_name,
        currency_to_name,
        platform_name,
      });
    }
  });

  return {
    to_change,
    to_delete,
  };
};
