import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { timeToSeconds } from "src/helpers/date";
import { fetchRequest } from "src/helpers/fetchRequest";
import clusterApi from "src/api/cluster";
import { TTemplate, TTimeline, TTimelineErrors } from "src/store/clusterReducer";
import { Popup } from "src/components/Popup/Popup";
import { BoardMenu } from "./BoardMenu";
import { ErrorsBlock } from "./Errors/ErrorsBlock";
import { Templates } from "./Templates/Templates";
import { Timeline } from "./Timeline";

type TProps = {
  centrifugoCities: Array<number>;
  timeline: Array<TTimeline>;
  isAdded: string;
  setIsAdded: Dispatch<SetStateAction<string>>;
  isBest: boolean;
  setIsBest: Dispatch<SetStateAction<boolean>>;
  fetchTimeline: (isClearMyChanges: boolean) => void;
  username: string;
  isSitesMenuOpen: boolean;
};

export const TimelineBoard = ({
  centrifugoCities,
  timeline,
  isAdded,
  setIsAdded,
  isBest,
  setIsBest,
  fetchTimeline,
  username,
  isSitesMenuOpen,
}: TProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [templates, setTemplates] = useState<Array<TTemplate>>([]);
  const [isOpenTemplates, openTemplates] = useState(false);

  const [zoom, setZoom] = useState(30);
  const [step, setStep] = useState(30);
  const [expand, setExpand] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCities, setIsOpenCities] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCities, setVisibleCities] = useState<Array<number>>([]);
  const [visibleVectors, setVisibleVectors] = useState<Array<string>>([]);
  const [actionsCount, setActionsCount] = useState(0);
  const [errors, setErrors] = useState<TTimelineErrors>();
  const now = new Date();
  const nowTime = Math.round(timeToSeconds(`${now.getHours()}:${now.getMinutes()}`) / 60);
  const nowEl = Math.ceil(nowTime / step);
  const elem = document.getElementById(`${nowEl}_axis`);
  const axisX = useMemo(() => Array.from(Array(1440 / step).keys()), [step]); // 1440 минут в сутках

  const [leftTemplate, setLeftTemplate] = useState<number | null>(null);
  const [rightTemplate, setRightTemplate] = useState<number | null>(null);
  const [leftTemplateData, setLeftTemplateData] = useState<Array<TTimeline>>([]);
  const [rightTemplateData, setRightTemplateData] = useState<Array<TTimeline>>([]);

  const items = useMemo(
    () => (!!leftTemplate ? leftTemplateData : timeline),
    [timeline, leftTemplateData, leftTemplate]
  );
  const itemsRight = useMemo(
    () => (!!rightTemplate ? rightTemplateData : []),
    [rightTemplateData, rightTemplate]
  );
  const leftTemplateCities = useMemo(
    () => leftTemplateData?.map((el) => el.city_id),
    [leftTemplateData]
  );
  const rightTtemplateCities = useMemo(
    () => rightTemplateData?.map((el) => el.city_id),
    [rightTemplateData]
  );
  const cities = useMemo(
    () => (!!leftTemplate ? leftTemplateCities : centrifugoCities),
    [centrifugoCities, leftTemplateCities, leftTemplate]
  );
  const citiesRight = useMemo(
    () => (!!rightTemplate ? rightTtemplateCities : []),
    [rightTtemplateCities, rightTemplate]
  );

  const fetchErrors = async () => {
    const { response } = await fetchRequest(clusterApi.getTimelineErrors());
    if (response) {
      setErrors(response);
    }
  };

  const fetchTemplates = async () => {
    const { response } = await fetchRequest(clusterApi.getTemplates(), { request: "Шаблоны" });
    if (response) {
      setTemplates(response);
    }
  };

  useEffect(() => {
    fetchErrors();
    fetchTemplates();
    if (!!searchParams?.get("template")) {
      setLeftTemplate(+searchParams?.get("template"));
    }
    if (!!searchParams?.get("right-template")) {
      setRightTemplate(+searchParams?.get("right-template"));
    }
  }, []);

  useEffect(() => {
    if (nowEl > 0 && !!elem) {
      elem?.scrollIntoView({ inline: "start", behavior: "smooth" });
    }
  }, [elem]);

  useEffect(() => {
    if (!isOpenCities) {
      setVisibleCities([]);
    } else {
      setVisibleCities(items?.map((el) => el.city_id));
    }
  }, [isOpenCities]);

  return (
    <div className="flex flex-col pb-[100px]">
      <BoardMenu
        zoom={zoom}
        setZoom={setZoom}
        isCopy={isCopy}
        setIsCopy={setIsCopy}
        fetchTimeline={fetchTimeline}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        isBest={isBest}
        setIsBest={setIsBest}
        step={step}
        setStep={setStep}
        isOpenCities={isOpenCities}
        setIsOpenCities={setIsOpenCities}
        expand={expand}
        setExpand={setExpand}
        isTemplate={!!rightTemplate || !!leftTemplate}
        openTemplates={openTemplates}
        isSitesMenuOpen={isSitesMenuOpen}
        setActionsCount={setActionsCount}
      />
      <div className={`w-full pr-[50px] md:pr-[80px] ${!!rightTemplate ? "grid grid-cols-2" : ""}`}>
        <Timeline
          items={items}
          anotherItems={itemsRight}
          templates={templates}
          setTemplates={setTemplates}
          setTemplateData={setLeftTemplateData}
          setAnotherTemplateData={setRightTemplateData}
          cities={cities}
          anotherCities={citiesRight}
          axisX={axisX}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isDelete={isDelete}
          isCopy={isCopy}
          setIsCopy={setIsCopy}
          isOpenCities={isOpenCities}
          isAdded={isAdded}
          setIsAdded={setIsAdded}
          zoom={zoom}
          step={step}
          expand={expand}
          isBest={isBest}
          visibleCities={visibleCities}
          setVisibleCities={setVisibleCities}
          visibleVectors={visibleVectors}
          setVisibleVectors={setVisibleVectors}
          nowTime={nowTime}
          username={username}
          actionsCount={actionsCount}
          setActionsCount={setActionsCount}
          activeTemplate={leftTemplate}
          anotherTemplate={rightTemplate}
          setActiveTemplate={setLeftTemplate}
          isSplitRight={!!rightTemplate}
          isRight={false}
          setIsSplitRight={() => {
            if (!!leftTemplate) {
              searchParams.delete("template");
              setSearchParams(searchParams);
              setLeftTemplate(null);
            } else if (!!rightTemplate) {
              searchParams.set("template", rightTemplate?.toString());
              searchParams.delete("right-template");
              setSearchParams(searchParams);
              setLeftTemplate(rightTemplate);
              setRightTemplate(null);
            }
          }}
        />
        {!!rightTemplate && (
          <Timeline
            items={itemsRight}
            anotherItems={items}
            templates={templates}
            setTemplates={setTemplates}
            setTemplateData={setRightTemplateData}
            setAnotherTemplateData={setLeftTemplateData}
            cities={citiesRight}
            anotherCities={cities}
            axisX={axisX}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isDelete={isDelete}
            isCopy={isCopy}
            setIsCopy={setIsCopy}
            isOpenCities={isOpenCities}
            isAdded={isAdded}
            setIsAdded={setIsAdded}
            zoom={zoom}
            step={step}
            expand={expand}
            isBest={isBest}
            visibleCities={visibleCities}
            setVisibleCities={setVisibleCities}
            visibleVectors={visibleVectors}
            setVisibleVectors={setVisibleVectors}
            nowTime={nowTime}
            username={username}
            actionsCount={actionsCount}
            setActionsCount={setActionsCount}
            activeTemplate={rightTemplate}
            anotherTemplate={leftTemplate}
            setActiveTemplate={setRightTemplate}
            isSplitRight={!!rightTemplate}
            setIsSplitRight={() => {
              searchParams.delete("right-template");
              setSearchParams(searchParams);
              setRightTemplate(null);
            }}
            isRight
          />
        )}
      </div>
      {!!errors && !leftTemplate && (
        <ErrorsBlock
          errors={errors}
          setIsAdded={setIsAdded}
          setVisibleCities={setVisibleCities}
          visibleVectors={visibleVectors}
          setVisibleVectors={setVisibleVectors}
          isSitesMenuOpen={isSitesMenuOpen}
        />
      )}
      {isOpenTemplates && (
        <Popup closeModal={() => openTemplates(false)} noPadding>
          <Templates
            templates={templates}
            setTemplates={setTemplates}
            setLeftTemplateData={setLeftTemplateData}
            setRightTemplateData={setRightTemplateData}
            username={username}
            setLeftTemplate={setLeftTemplate}
            setRightTemplate={setRightTemplate}
            openTemplates={openTemplates}
          />
        </Popup>
      )}
    </div>
  );
};
