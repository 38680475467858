import { Dispatch, SetStateAction } from "react";
import { TGlobalSite } from "src/store/directionsReducer";
import { colors } from "src/helpers/colors";

type TProps = {
  item: TGlobalSite;
  isAdded: string;
  setIsAdded: Dispatch<SetStateAction<string>>;
};

export const SiteMenuItem = ({ item, isAdded, setIsAdded }: TProps) => {
  const id = item.site_id % 30;

  return (
    <button
      type="button"
      style={{ backgroundColor: `${colors[id]}77`, borderColor: colors[id] }}
      className={`grid grid-cols-[1fr_50px] items-center gap-8 rounded-sm shadow-smooth-out py-4 px-8 text-xs text-left border-l-4 text-[#282c34CC] whitespace-pre duration-300 ${
        isAdded === "" || item.site_id === +isAdded ? "" : "grayscale opacity-30"
      }`}
      onClick={() => {
        if (item.site_id === +isAdded) {
          setIsAdded("");
        } else {
          setIsAdded(item.site_id?.toString());
        }
      }}>
      <div>{item?.site_name}</div>
    </button>
  );
};
