import { useSelector } from "react-redux";
import { selectFlights, selectFlightsFilters } from "src/store/statsReducer";
import { FlightCard } from "src/components/SideBar/Flights/FlightCard";
import { flightFilter, FlightsFilters } from "./components/FlightsFilters";

export const Flights = () => {
  const flights = useSelector(selectFlights);
  const flightsFilters = useSelector(selectFlightsFilters);

  return (
    <div className="flex flex-col md:flex-row items-center md:items-start pr-[50px]">
      <div className="px-[20px] pb-[20px]">
        <FlightsFilters items={flights} />
      </div>
      <div className="flex flex-col gap-[20px] items-center md:items-start">
        <div className="px-[20px] flex gap-4 text-white text-xs">
          <div className="bg-font px-8 rounded-md">вылетов: {flights?.data?.length}</div>
          <div className="bg-font px-8 rounded-md">
            с фильтрами: {flights?.data?.filter((el) => flightFilter(el, flightsFilters))?.length}
          </div>
        </div>
        <div className="flex flex-col gap-8 px-[10px] md:px-0 md:flex-row md:flex-wrap mr-[20px]">
          {flights?.data
            ?.filter((el) => flightFilter(el, flightsFilters))
            ?.sort((a, b) => b.diff - a.diff)
            .map((el, index) => (
              <FlightCard key={index} item={el} />
            ))}
        </div>
      </div>
    </div>
  );
};
