export const TabButton = ({ title, active, setFn, disabled = false }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`px-8 rounded-sm whitespace-pre duration-300 hover:opacity-70 cursor-pointer ${
        active ? "bg-font text-white disabled:bg-font" : "text-lightFont"
      } ${
        disabled && !active
          ? "opacity-0 max-h-0 overflow-hidden my-0 py-0"
          : "opacity-100 max-h-[30px] overflow-visible my-px py-4"
      }`}
      onClick={setFn}>
      {title}
    </button>
  );
};
