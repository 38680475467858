import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import addApi from "src/api/add";
import { LS } from "src/api/constants";
import { setCountries, setAllCities } from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { WaitingLoader } from "src/components/UI/loader/WaitingLoader";
import { CreateUser } from "./components/Users/CreateUser/CreateUser";
import { Users } from "./components/Users/Users";
import { Vectors } from "src/pages/Add/components/Vectors/Vectors";
import { Countries } from "src/pages/Add/components/Countries/Countries";
import { AddButton } from "src/components/UI/buttons/AddButton";
import { CreateCountry } from "src/pages/Add/components/Countries/CreateCountry";
import switchApi from "src/api/onOff";
import { CreateSite } from "src/pages/Add/components/Sites/CreateSite/CreateSite";
import { CreateCurrency } from "src/pages/Add/components/Currencies/CreateCurrency/CreateCurrency";
import { CreateCity } from "src/pages/Add/components/Cities/CreateCity/CreateCity";
import { Sites } from "src/pages/Add/components/Sites/Sites";
import { Currencies } from "src/pages/Add/components/Currencies/Currencies";
import { Cities } from "src/pages/Add/components/Cities/Cities";

export const Add = () => {
  const lastTab = localStorage.getItem(LS.LAST_ADD_TAB) as TDeleteTab;

  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<TDeleteTab>(lastTab || "- пользователь");
  const [addTab, setAddTab] = useState<TAddTab>("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<boolean | null>(null);
  const adminAccess = userAccess?.includes("admin_post") as boolean;
  const addAccess = userAccess?.includes("add_post") as boolean;
  const usersAccess = userAccess?.includes("user_post") as boolean;

  const fetchGlobals = async () => {
    const { response } = await fetchRequest(switchApi.getGlobals(), {
      request: "Глобальные данные",
    });
    if (response) {
      dispatch(setAllCities(response.cities));
    }
  };

  const fetchCountries = async () => {
    const { response, error } = await fetchRequest(addApi.getCountries(), { request: "Страны" });
    if (response) {
      dispatch(setCountries(response));
    }
    if (error) {
      dispatch(setCountries([]));
    }
  };

  const changeTab = (tab: TDeleteTab) => {
    setActiveTab(tab);
    setAddTab("");
    localStorage.setItem(LS.LAST_ADD_TAB, tab);
  };

  useEffect(() => {
    addAccess && fetchCountries();
  }, []);

  return (
    <div className="flex flex-col items-center w-[calc(100% - 80px)] lg:w-fit mr-[50px] lg:mx-auto">
      <WaitingLoader
        message={message}
        status={status}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <div className="flex flex-col w-full flex-wrap sm:flex-row gap-[10px] sm:gap-[20px] mb-[20px] sm:justify-center text-sm font-light pl-[20px]">
        {addAccess && (
          <button
            className={`rounded-lg duration-700 shadow-smooth hover:shadow-inset p-[20px] w-full sm:w-[130px] min-w-[130px] ${
              activeTab === "- векторы" &&
              "bg-font text-white shadow-smooth-black hover:shadow-inset-black"
            }`}
            onClick={() => changeTab("- векторы")}>
            Векторы
          </button>
        )}
        {addAccess && (
          <button
            className={`rounded-lg duration-700 shadow-smooth hover:shadow-inset p-[20px] w-full sm:w-[130px] min-w-[130px] ${
              activeTab === "- страны" &&
              "bg-font text-white shadow-smooth-black hover:shadow-inset-black"
            }`}
            onClick={() => changeTab("- страны")}>
            Страны
          </button>
        )}
        {addAccess && (
          <button
            className={`rounded-lg duration-700 shadow-smooth hover:shadow-inset p-[20px] w-full sm:w-[130px] min-w-[130px] ${
              activeTab === "- обменник" &&
              "bg-font text-white shadow-smooth-black hover:shadow-inset-black"
            }`}
            onClick={() => changeTab("- обменник")}>
            Обменники
          </button>
        )}
        {addAccess && (
          <button
            className={`rounded-lg duration-700 shadow-smooth hover:shadow-inset p-[20px] w-full sm:w-[130px] min-w-[130px] ${
              activeTab === "- валюта" &&
              "bg-font text-white shadow-smooth-black hover:shadow-inset-black"
            }`}
            onClick={() => changeTab("- валюта")}>
            Валюты
          </button>
        )}
        {adminAccess && (
          <button
            className={`rounded-lg duration-700 shadow-smooth hover:shadow-inset p-[20px] w-full sm:w-[130px] min-w-[130px] ${
              activeTab === "- город" &&
              "bg-font text-white shadow-smooth-black hover:shadow-inset-black"
            }`}
            onClick={() => changeTab("- город")}>
            Города
          </button>
        )}
        {usersAccess && (
          <button
            className={`rounded-lg duration-700 shadow-smooth hover:shadow-inset p-[20px] w-full sm:w-[130px] min-w-[130px] ${
              activeTab === "- пользователь" &&
              "bg-font text-white shadow-smooth-black hover:shadow-inset-black"
            } ${!usersAccess ? "opacity-30" : ""}`}
            onClick={() => changeTab("- пользователь")}
            disabled={!usersAccess}>
            Пользователи
          </button>
        )}
      </div>
      {activeTab !== "- векторы" && (
        <div className="w-full sm:w-[600px] duration-300 pl-[20px] mb-[10px]">
          {(addAccess || usersAccess) && (
            <AddButton
              title={
                activeTab === "- обменник"
                  ? "Добавить обменник"
                  : activeTab === "- валюта"
                  ? "Добавить валюту"
                  : activeTab === "- город"
                  ? "Добавить город"
                  : activeTab === "- страны"
                  ? "Добавить страну"
                  : "Добавить пользователя"
              }
              onClick={() => {
                if (addTab?.length > 0) {
                  setAddTab("");
                } else {
                  setAddTab(activeTab?.replace("- ", "") as TAddTab);
                }
              }}
            />
          )}
        </div>
      )}

      {activeTab === "- векторы" && addAccess && <Vectors />}
      {activeTab === "- страны" && addAccess && (
        <Countries
          setIsLoading={setIsLoading}
          setMessage={setMessage}
          setStatus={setStatus}
          access={addAccess}
        />
      )}

      {addTab === "обменник" && addAccess && (
        <CreateSite
          setActiveTab={setAddTab}
          setIsLoading={setIsLoading}
          setSiteMessage={setMessage}
          setSiteStatus={setStatus}
        />
      )}
      {addTab?.length > 0 && addTab !== "обменник" && (
        <Popup closeModal={() => setAddTab("")} noPadding>
          {addTab === "валюта" && addAccess && <CreateCurrency setActiveTab={setAddTab} />}
          {addTab === "город" && addAccess && (
            <CreateCity
              setActiveTab={setAddTab}
              setIsLoading={setIsLoading}
              setMessage={setMessage}
              setStatus={setStatus}
              fetchGlobals={fetchGlobals}
            />
          )}
          {addTab === "пользователь" && usersAccess && <CreateUser setActiveTab={setAddTab} />}
          {addTab === "страны" && addAccess && (
            <CreateCountry
              setActiveTab={setAddTab}
              fetchCountries={fetchCountries}
              setMessage={setMessage}
              setStatus={setStatus}
              setIsLoading={setIsLoading}
            />
          )}
        </Popup>
      )}
      {activeTab === "- обменник" && addAccess && (
        <Sites
          setIsLoading={setIsLoading}
          setMessage={setMessage}
          setStatus={setStatus}
          access={addAccess}
        />
      )}
      {activeTab === "- валюта" && addAccess && (
        <Currencies
          setIsLoading={setIsLoading}
          setMessage={setMessage}
          setStatus={setStatus}
          access={addAccess}
        />
      )}
      {activeTab === "- город" && addAccess && (
        <Cities
          setIsLoading={setIsLoading}
          setMessage={setMessage}
          setStatus={setStatus}
          access={addAccess}
          fetchGlobals={fetchGlobals}
        />
      )}
      {activeTab === "- пользователь" && usersAccess && (
        <Users
          setIsLoading={setIsLoading}
          setMessage={setMessage}
          setStatus={setStatus}
          access={usersAccess}
        />
      )}
    </div>
  );
};

export type TAddTab = "" | "обменник" | "валюта" | "пользователь" | "город" | "векторы" | "страны";
export type TDeleteTab =
  | "- обменник"
  | "- валюта"
  | "- пользователь"
  | "- город"
  | "- векторы"
  | "- страны";
