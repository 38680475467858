import sadCat from "src/assets/images/sadCat.jpeg";

export const SadCat = ({ message, setFn }) => {
  return (
    <div className="flex flex-col gap-[20px] text-xs font-light w-[260px] whitespace-pre text-center">
      <img src={sadCat} alt="Грустный кот" />
      <div>{message}</div>
      <button
        type="button"
        className="prime-button-sm bg-font self-center w-fit"
        onClick={() => setFn(false)}>
        Понятно
      </button>
    </div>
  );
};
