import { SVG } from "src/assets/images/SVG";
import { TGlobalCurrency } from "src/store/directionsReducer";
import { LS } from "src/api/constants";
import { Soon } from "src/components/UI/soon/Soon";

interface CurrencyCardProps {
  item: TGlobalCurrency;
  onClick: () => void;
  isSelected?: boolean;
  color: string;
  deleteCurrency?: () => void;
}

const CurrencyCard = ({ item, isSelected, onClick, color, deleteCurrency }: CurrencyCardProps) => {
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const addAccess = userAccess?.includes("add_post") as boolean;
  const isSoonAccess = userAccess?.includes("admin_post");

  return (
    <button
      type="button"
      className={`relative select_button grid grid-cols-[1fr_2fr] justify-items-center items-center gap-8 hover:opacity-80 text-xs
        ${isSelected ? "font-semibold" : ""}
      `}
      style={{
        backgroundColor: isSelected ? color : "#FAFAFF",
        marginRight: 0,
      }}
      onClick={onClick}>
      <div>
        <SVG id={item.currency_name} />
      </div>
      <span className="w-full text-left">{item.currency_name}</span>
      {!!deleteCurrency && (
        <div className="absolute right-[10px]">
          <Soon isAccess={isSoonAccess as boolean} isRight>
            <div>
              {addAccess && (
                <button
                  className={`duration-300 text-lightGray hover:text-lightFont`}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteCurrency();
                  }}>{`\u2716`}</button>
              )}
            </div>
          </Soon>
        </div>
      )}
    </button>
  );
};

export default CurrencyCard;
