import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactSelect, { createFilter } from "react-select";
import {
  selectRates,
  setRates,
  setCountries,
  selectCountries,
  selectStaticCountries,
  setStaticCountries,
} from "src/store/bidReducer";
import {
  selectCurFrom,
  selectCurTo,
  setSelectedFrom,
  setSelectedTo,
} from "src/store/directionsReducer";
import { setNotific } from "src/store/mainReducer";
import { useForm, FormProvider, FieldValues } from "react-hook-form";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import bidApi, { curreniesMock, mockAgg } from "src/api/bid";
import { LS } from "src/api/constants";
import "src/components/UI/switcher/switcher.scss";
import { Spinner } from "src/components/Spinner/Spinner";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { CountryRow } from "./components/CountryRow";
import { Option } from "src/types/common";
import { CopyAggModal } from "src/pages/Bid/components/CopyAgg";
import { useModal } from "src/hooks/useModal";
import { ErrorMessage } from "src/shadcn/ui/ui/error-message";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";
import { InfoIcon } from "lucide-react";
import { UpdateButton } from "src/components/UI/buttons/UpdateButton";
import { Redactor } from "src/components/Redactors/Redactor";
import { MAX_BET_DELTA } from "src/constants/bid";

export const Bid = () => {
  const dispatch = useDispatch();
  const methods = useForm({ mode: "onChange" });
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const access = userAccess?.includes("bid_post") as boolean;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGood, setIsGood] = useState(true);
  const [redactorDisabled, setRedactorDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const curFrom = useSelector(selectCurFrom);
  const curTo = useSelector(selectCurTo);
  const rates = useSelector(selectRates);
  const countries = useSelector(selectCountries);
  const staticCountries = useSelector(selectStaticCountries);
  const [initialBossRate, setInitialBossRate] = useState<Record<string, string>>({});
  const [betsError, setBetsError] = useState("");

  const { isOpen, setIsOpen } = useModal();

  const onAggChange = (option: Option) => {
    searchParams.set("agg", option.value);
    setSearchParams(searchParams);
  };
  const selectedAgg = searchParams.get("agg");

  const formValues = methods.getValues();

  const curreniesOptions = curreniesMock.map((item) => ({
    value: item,
    label: item.currency_code,
  }));
  const fromCurrency = curreniesOptions.find((item) => item.label === curFrom) || null;
  const toOptions = curreniesOptions.filter((item) => {
    if (fromCurrency?.value.currency_type === "cash") return item.value.currency_type === "crypto";
    return true;
  });
  const toCurrency = toOptions.find((item) => item.label === curTo) || null;

  type CurrencyOption = typeof toOptions[0];
  const onCurrencyChange = (option: CurrencyOption, type: "from" | "to") => {
    const setter = type === "from" ? setSelectedFrom : setSelectedTo;
    dispatch(setter(option.value.currency_code));
  };

  const fetchBidData = async () => {
    setLoading(true);

    const { response: responseRates, error } = await fetchRequest(
      bidApi.getRates(curFrom, curTo, selectedAgg),
      {
        request: "Ставки",
      }
    );

    if (responseRates) {
      dispatch(setRates(responseRates));
      const initialBossRate: Record<string, string> = {};
      responseRates.forEach((rate) =>
        rate.bets.forEach((bet) => (initialBossRate[bet.country_name] = bet.bet_boss_rate))
      );
      setInitialBossRate(initialBossRate);
      setIsGood(true);
    }
    if (error) {
      dispatch(setRates([]));
      setIsGood(false);
    }

    setLoading(false);
  };

  const validateBetValues = () => {
    if (!rates || rates.length === 0) return false;
    const bets = rates[0].bets;

    let isValid = true;

    for (const bet of bets) {
      for (const city of bet.cities_data) {
        const currentBetValue = Number(formValues[city.city_name].bet_value_front);
        const initialBetValue = Number(city.bet_value_front);
        const initialBossRate = Number(bet.bet_boss_rate);

        // if bet_is_delta we only check initial bet value
        // if not delta we check bet value and boss rate
        if (
          Math.abs(initialBetValue - currentBetValue) > MAX_BET_DELTA &&
          (formValues[city.city_name].bet_is_delta
            ? true
            : Math.abs(initialBossRate - currentBetValue) > MAX_BET_DELTA)
        ) {
          isValid = false;
          methods.setError(`${city.city_name}.bet_value_front`, { message: "error" });
        }
      }
    }

    return isValid;
  };

  const isPercent = rates?.[0]?.bets?.some((bet) =>
    bet?.cities_data?.some((city) => city?.bet_as_percentage)
  );

  const updateBossRate = async () => {
    const { error } = await fetchRequest(
      bidApi.updateBossRate({
        currency_code_from: curFrom,
        currency_code_to: curTo,
        bets: Object.keys(initialBossRate).map((country) => ({
          country_name: country,
          bet_boss_rate: formValues[country],
          aggregator_name: selectedAgg,
        })),
      })
    );

    if (error) {
      dispatch(
        setNotific({
          type: "error",
          message: "Ошибка при обновлении босс-ставки",
          request: "Обновление босс-ставки",
        })
      );
    }

    fetchBidData();
  };

  const isBossRateChanged = () =>
    Object.keys(initialBossRate).some((key) => initialBossRate[key] !== formValues[key]);

  const intervalRef = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    if (!curFrom || !curTo || !selectedAgg) return;
    fetchBidData();

    intervalRef.current = setInterval(() => fetchBidData(), 10000);

    return () => clearInterval(intervalRef.current);
  }, [curFrom, curTo, selectedAgg, intervalRef]);

  useEffect(() => {
    if (!rates?.length) return;

    const vectorCountries = rates?.find(
      (item) =>
        item?.currency_code_from === fromCurrency.value.currency_code &&
        item?.currency_code_to === toCurrency.value.currency_code
    )?.bets;

    if (!vectorCountries) return;

    dispatch(setCountries(vectorCountries));
    dispatch(setStaticCountries(vectorCountries));
  }, [fromCurrency, toCurrency, rates]);

  // конвертация данных для бэка и отправка
  const onSubmit = async (e: FieldValues) => {
    setBetsError("");

    if (!validateBetValues()) {
      setBetsError(
        "Ставка не должна отличаться на значение большее чем 0.5 от предыдущего значения или boss stavka"
      );
      return;
    }

    const countriesData = countries
      ?.map((el) => ({
        ...el,
        bet_boss_rate: e[el.country_name],
        cities_data: el.cities_data
          ?.map((city) =>
            city.bet_value_front !== e[city.city_name]?.bet_value_front ||
            city.bet_is_delta !== e[city.city_name]?.bet_is_delta
              ? {
                  ...city,
                  bet_value_front: e[city.city_name]?.bet_value_front,
                  bet_is_delta: e[city.city_name]?.bet_is_delta,
                }
              : null
          )
          .filter(Boolean),
      }))
      .filter((item) => item.cities_data.length > 0);

    if (countriesData.length === 0) return;

    const data = {
      currency_code_from: curFrom,
      currency_code_to: curTo,
      bets: countriesData,
    };

    const { response } = await fetchRequest(bidApi.updateBidData(data), {
      request: "Обновление ставок",
    });

    if (!response) return;

    if (response.failed_list?.length > 0) {
      const failedCities = [];
      response.failed_list.forEach((item) =>
        item.bets.forEach((bet) =>
          bet.cities_data.forEach((city) => failedCities.push(city.city_name))
        )
      );
      setBetsError("Ставки не обновились для городов: " + failedCities.join(", "));
    }

    await fetchBidData();
    dispatch(
      setNotific({
        type: "success",
        message: "Ставки успешно обновлены",
        request: "Обновление ставок",
      })
    );
  };

  const currentVector = curFrom + "-" + curTo;

  return (
    <div>
      <div className="flex flex-col justify-center relative w-[calc(100% - 100px)] mr-[30px]">
        <div className="p-6 lg:w-[480px] table rounded-xl shadow-lg items-center space-x-4 w-full">
          <FormProvider {...methods}>
            <div>
              <div className="flex items-center gap-[8px]">
                <div className="flex-1">
                  <ReactSelect
                    placeholder="Агрегатор"
                    className="text font-bold"
                    classNamePrefix={"Select"}
                    value={selectedAgg ? { label: selectedAgg, value: selectedAgg } : null}
                    onChange={onAggChange}
                    options={mockAgg.map((item) => ({ value: item, label: item }))}
                  />
                </div>
                <CopyAggModal isOpen={isOpen} setIsOpen={setIsOpen} />
              </div>
              <div className="flex mt-[10px] gap-[8px]">
                <div className="flex-1">
                  <ReactSelect
                    placeholder={"Направление..."}
                    className="text font-bold"
                    classNamePrefix={"Select"}
                    value={fromCurrency}
                    onChange={(option) => onCurrencyChange(option, "from")}
                    options={curreniesOptions}
                    filterOption={createFilter(selectFilter)}
                  />
                </div>
                <div className="flex-1">
                  <ReactSelect
                    placeholder={"Направление..."}
                    className="text font-bold"
                    classNamePrefix={"Select"}
                    value={toCurrency}
                    onChange={(option) => onCurrencyChange(option, "to")}
                    options={toOptions}
                    filterOption={createFilter(selectFilter)}
                  />
                </div>
                {isPercent && (
                  <TooltipProvider delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger>
                        <InfoIcon className="text-orange w-[36px]" />
                      </TooltipTrigger>
                      <TooltipContent>
                        Ставка для данной пары рассчитывается как процент
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </div>
            </div>
            {isLoading && !rates?.length && (
              <div className="absolute w-full top-[150px] flex justify-center">
                <Spinner />
              </div>
            )}
            {!!countries?.length && (
              <form style={{ marginLeft: "0px" }} onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={"flex"}>
                  <div className="shadow-md rounded-lg mt-[20px] w-full">
                    <div className={`hidden table-header sm:grid grid-cols-2 pl-[20px] pr-[70px]`}>
                      <div className="text-left flex items-center gap-[12px]">
                        <span>Город</span>
                      </div>
                      <div>Ставка/Дельта</div>
                    </div>
                    {countries?.map((item) => (
                      <CountryRow
                        key={item.country_name}
                        item={item}
                        staticCountries={staticCountries}
                        redactorDisabled={redactorDisabled}
                        access={access}
                        isLoading={isLoading}
                        updateBossRate={updateBossRate}
                        initialBossRate={initialBossRate}
                      />
                    ))}
                  </div>
                </div>
                {userAccess?.includes("bid_post") && !redactorDisabled && (
                  <button className="prime-button mt-[20px]" disabled={isBossRateChanged()}>
                    Отправить
                  </button>
                )}
                {betsError && <ErrorMessage>{betsError}</ErrorMessage>}
              </form>
            )}
          </FormProvider>
        </div>
        {userAccess?.includes("bid_post") && curFrom && curTo && (
          <Redactor
            currentVector={currentVector}
            setRedactorDisabled={setRedactorDisabled}
            page="bid"
          />
        )}
        {curFrom && curTo && (
          <div className={`fixed top-[132px] left-[20px]`}>
            <UpdateButton updFn={fetchBidData} time={10} dependence={countries} />
          </div>
        )}
        {!isGood && !countries?.length && <Emptiness message={"Ошибка сервиса"} />}
        <div className="h-[100px]" />
      </div>
    </div>
  );
};
