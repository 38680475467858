import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { AddButton } from "src/components/UI/buttons/AddButton";
import { ImageUploader } from "src/components/FormElements/ImageUploader";
import { TypeEditor } from "./TypeEditor";
import { TagsEditor } from "./TagsEditor";
import { VectorsEditor } from "./VectorsEditor";
import { ContentType, getAssetFromFile } from "src/pages/Requests/components/Request";
import AssetPreview from "src/pages/Requests/components/AssetPreview";
import { Gallery } from "src/pages/Requests/components/Gallery";

export const CreateRequest = ({
  assets,
  setAssets,
  currentType,
  setCurrentType,
  currentPriority,
  setCurrentPriority,
  isAdd,
  setIsAdd,
  isDelay,
  types,
  priorities,
  tags,
  selectedTags,
  setSelectedTags,
  setTags,
  savedSites,
  setSavedSites,
  savedVectors,
  setSavedVectors,
  lastFailedReq,
}) => {
  const { register, watch } = useFormContext();
  const photos = assets.filter(
    (item) => item.content_type === ContentType.IMAGE || item.content_type === ContentType.VIDEO
  );
  const disabled = !watch(`title`)?.length || photos?.length > 10 || isDelay;
  const [isPopup, setPopup] = useState(false);
  const [currentImage, setCurrentImage] = useState({ src: "", index: 0 });

  const handleUpload = async (e) => {
    e.preventDefault();
    const { files: newFiles } = e.target;

    if (newFiles?.length) {
      const assets = await Promise.all(Object.values(newFiles).map(getAssetFromFile));
      setAssets((prev) => [
        ...prev.filter((item) => !assets.some((asset) => asset.name === item.name)),
        ...assets,
      ]);
    }
  };

  const handlePaste = async (e) => {
    if (e?.clipboardData?.items?.length) {
      const file = e?.clipboardData?.items[0]?.getAsFile();
      if (!!file) {
        const newFile = await getAssetFromFile(file);
        setAssets((prev) => [...prev, newFile]);
      }
    }
  };

  const deleteAsset = async (id: number | string) =>
    setAssets((prev) => prev.filter((asset) => asset.id !== id));

  return (
    <div>
      <AddButton title="Добавить запрос" onClick={() => setIsAdd(!isAdd)} />
      <div
        className={`relative duration-500 overflow-hidden flex flex-col gap-8 text-xs px-[20px] ${
          isAdd ? "max-h-[1500px] opacity-100 mt-8 py-[12px]" : "max-h-0 opacity-0 mt-0 py-4"
        }`}>
        <div
          className={`absolute top-0 left-0 w-full h-full bg-stale backdrop-blur-[1px] rounded-lg duration-500 ${
            isDelay ? "z-30 opacity-70" : "-z-10 opacity-0"
          }`}
        />
        <div className="flex gap-4">
          <TypeEditor
            currentType={currentType}
            setCurrentType={setCurrentType}
            isAccess={true}
            types={types}
          />
          <TypeEditor
            currentType={currentPriority}
            setCurrentType={setCurrentPriority}
            isAccess={true}
            types={priorities}
          />
          <input
            className="w-full border border-[#282c3433] rounded-sm py-4 px-8 max-h-[24px]"
            placeholder="Заголовок"
            {...register(`title`, { required: true })}
          />
        </div>
        <TagsEditor
          tags={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          setTags={setTags}
          isAddVisible={true}
          access={true}
        />
        <VectorsEditor
          savedSites={savedSites}
          setSavedSites={setSavedSites}
          savedVectors={savedVectors}
          setSavedVectors={setSavedVectors}
          isAddVisible={true}
          access={true}
          isFull
        />
        <textarea
          className="w-full border border-[#282c3433] rounded-sm py-4 px-8 min-h-[100px] mb-8"
          placeholder="Описание"
          {...register(`description`)}
        />
        <div className="flex flex-wrap items-start gap-4 mb-[10px]">
          <ImageUploader
            handleUpload={handleUpload}
            handlePaste={handlePaste}
            filesLength={assets.length}
          />
          {assets.map((asset, index) => (
            <AssetPreview
              key={index}
              canEdit
              notModified
              deleteAsset={deleteAsset}
              photosIndex={photos.findIndex(
                (item) => item.id === asset.id || item.name === asset.name
              )}
              asset={asset}
              setCurrentImage={setCurrentImage}
              setPopup={setPopup}
              isNew={false}
            />
          ))}
        </div>
        <div className="flex justify-end">
          <button className="prime-button-sm bg-font rounded-sm" disabled={disabled}>
            {lastFailedReq ? "Повторить" : "Создать"}
          </button>
        </div>
      </div>
      <Gallery
        photos={photos}
        isPopup={isPopup}
        setPopup={setPopup}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
      />
    </div>
  );
};
