import { AssetClient, ContentType } from "src/pages/Requests/components/Request";
import { ReactNode } from "react";
import { VideoPreview } from "src/pages/Requests/components/AssetPreview/video";
import { FilePreview } from "src/pages/Requests/components/AssetPreview/file";
import { ImagePreview } from "src/pages/Requests/components/AssetPreview/image";
import Copied from "src/components/UI/copied/Copied";

interface AssetPreviewProps {
  canEdit?: boolean;
  notModified?: boolean;
  deleteAsset: (id: number | string) => void;
  photosIndex: number;
  asset: AssetClient;
  setCurrentImage: ({ src, index }: { src: string; index: number }) => void;
  setPopup: (val: boolean) => void;
  isNew: boolean;
  isCopyPath?: boolean;
}

const AssetPreview = ({
  canEdit,
  notModified,
  deleteAsset,
  photosIndex,
  asset,
  setCurrentImage,
  setPopup,
  isNew,
  isCopyPath,
}: AssetPreviewProps) => {
  let preview: ReactNode;
  const openVideo = () => {
    setCurrentImage({
      index: photosIndex,
      src: asset.path,
    });
    setPopup(true);
  };

  switch (asset.content_type) {
    case ContentType.IMAGE:
      preview = (
        <ImagePreview
          photosIndex={photosIndex}
          url={asset.path}
          setCurrentImage={setCurrentImage}
          setPopup={setPopup}
        />
      );
      break;

    case ContentType.VIDEO:
      preview = <VideoPreview openVideo={openVideo} url={asset.path} />;
      break;

    case ContentType.APPLICATION:
      preview = <FilePreview asset={asset} />;
  }

  const handleDeleteAsset = () => deleteAsset(asset.id);

  return (
    <div className="relative cursor-default">
      {canEdit && (
        <button
          className={`hover:bg-gray hover:text-white z-[2] absolute -top-4 -left-4 h-[14px] w-[14px] rounded-full shadow-base text-[8px] text-[#CACAD3]  ${
            !notModified ? "bg-[#D6F1FF]" : "bg-bg"
          }`}
          type="button"
          onClick={handleDeleteAsset}>
          {`\u2716`}
        </button>
      )}
      {isNew && (
        <div
          className={
            "absolute flex items-center h-[14px] text-[9px] -top-4 -right-4 rounded-lg px-8 bg-[#40CEF0] text-white font-bold"
          }>
          new
        </div>
      )}
      {isCopyPath && !isNew && (
        <div
          className={
            "absolute flex items-center text-[9px] -top-4 -right-0 rounded-lg px-8 bg-emerald-500 text-white font-bold"
          }>
          <Copied value="Copy link" copiedValue={asset.url || asset.path} title="Скопировано" />
        </div>
      )}
      {preview}
    </div>
  );
};

export default AssetPreview;
