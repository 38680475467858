import { useEffect, useState } from "react";
import { fetchRequest } from "src/helpers/fetchRequest";
import graphsApi from "src/api/graphs";

export const ControlDashboard = () => {
  const [graphLink, setGraphLink] = useState<string>("");

  const fetchDashboard = async () => {
    const { response } = await fetchRequest(graphsApi.controlDashboard(), {
      request: "Дашборд",
    });
    if (response) {
      setGraphLink(response);
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <div className="relative w-[calc(100%-20px)] sm:w-[calc(100%-50px)] h-[100vh] top-[-30px] pr-[10px] mb-[100px]">
      {!!graphLink?.length && (
        <iframe style={{ width: "100%", height: "100%" }} src={graphLink}></iframe>
      )}
    </div>
  );
};
