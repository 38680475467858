import { ContentType } from "../Requests/components/Request";

export type TNav = {
  title: string;
  path: string;
  module_id: string | null;
  module_type?: ESectionType;
};

export type TPagination = {
  limit: number;
  offset: number;
};

export type TPerms = {
  user_role: string;
  admin_rights: boolean;
};

export type TTest = {
  course_id?: number;
  course_name: string;
  course_description: string;
  perms: TPerms[];
  course_auto_grant: boolean;
  course_grant_role: string | null;
  course_required_percentage: number | null;
  course_required_role: string | null;
};

export type TUpdateTest = {
  course_name: string;
  course_description: string;
};

export type TModule = {
  module_id?: number;
  course_id: number;
  module_name: string;
  module_type: ESectionType;
  module_order: number;
  module_deadline_in_minutes: number | null;
  module_blocks: TBlock[];
  module_allowed_users?: string[];
  questions_count?: number;
};

export type TBlock = {
  module_id: number;
  block_id?: number;
  block_name: string;
  block_order: number;
};

export type TQuestion = {
  question_id?: number;
  block_id: number;
  question_title: string;
  question_type: EQuestion;
  question_content: string;
  question_explanation: string;
  question_answers: TAnswer[];
  question_order: number;
  question_attachments: TAttachment[];
};

export type TAttachment = {
  attachment_id?: number;
  attachment_url: string;
  attachment_type: ContentType;
};

export type TAttempt = {
  course_id: number;
  module: {
    module_id: number;
    module_name: string;
    module_type: ESectionType;
  };
  attempt_id: number;
  attempt_status: ETestStatus;
  attempt_started_on: string; // 2022-01-01T00:00:00;
  attempt_finished_on: string; // 2022-01-01T00:00:00;
  attempt_total_questions: number;
  attempt_percentage: number | null;
  attempt_successful: boolean;
  attempt_answers: TAttemptAnswer[];
};

export type TAttemptAnswer = {
  question_id: number;
  answer: number[] | string;
  answer_is_correct: boolean;
};

export type TAttemptResult = TAttempt & {
  attempt_right_answers: number;
  questions: TQuestion[];
};

export type TMyAnswers = {
  attempt_id: number;
  answers: TAnswer[];
};

export type TAnswer = {
  question_id?: number;
  answer_id: number | string;
  answer_title: string;
  answer_is_correct?: boolean;
};

export type TAnswerPayload = {
  question_id: number;
  selected_option: number[] | string;
};

export enum EQuestion {
  NONE = "no_answer",
  RADIO = "single_answer",
  MULTI = "multi_answer",
  INPUT = "input_answer",
}

export enum ETestStatus {
  STARTED = "started",
  FINISHED = "finished",
}

export enum ESectionType {
  THEORY = "theory",
  PRACTICE = "practice",
  EXAMINATION = "exam",
}
