import { ExpandedEntity, ExpandedStateLevel } from "src/types/OnOffTimeline/store";

export const checkIsFullyExpanded = (cur: ExpandedEntity, level: ExpandedStateLevel) => {
  if (level === ExpandedStateLevel.CITY) {
    return (
      !!cur?.isExpanded &&
      Object.values(cur?.children).every((group) =>
        checkIsFullyExpanded(group, ExpandedStateLevel.GROUP)
      )
    );
  }

  if (level === ExpandedStateLevel.GROUP) {
    return (
      !!cur?.isExpanded &&
      Object.values(cur?.children).every((from) =>
        checkIsFullyExpanded(from, ExpandedStateLevel.GROUPED_PAIR)
      )
    );
  }

  if (level === ExpandedStateLevel.GROUPED_PAIR) {
    return !!cur?.isExpanded && Object.values(cur?.children).every((pair) => pair?.isExpanded);
  }
};
