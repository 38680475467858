import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import search from "src/assets/images/search.svg";
import { charFix, translit } from "src/helpers/formHelpers";
import { cn } from "src/utils";
import { TTurnOffReportVectors } from "../models";
import SelectedSimplePairCard from "./SelectedSimplePairCard";
import statsApi from "src/api/stats";
import { fetchRequest } from "src/helpers/fetchRequest";
import { dispatch } from "src/store/store";
import { setNotific } from "src/store/mainReducer";
import { Button } from "src/shadcn/ui/ui/button";
import { selectStatsReports } from "src/store/statsReducer";
import { Popup } from "src/components/Popup/Popup";

const columns = ["Город", "From", "To"];

export const SelectedSimplePairs = ({
  emptyMessage,
  selectedPairs,
  setSelectedPairs,
  className,
  isAccess,
}: {
  emptyMessage?: string;
  className?: string;
  selectedPairs: TTurnOffReportVectors[];
  setSelectedPairs: Dispatch<SetStateAction<TTurnOffReportVectors[]>>;
  isAccess: boolean;
}) => {
  const statsReports = useSelector(selectStatsReports);
  const [filterStr, setFilterStr] = useState("");
  const [arrToRemove, setArrToRemove] = useState<TTurnOffReportVectors[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteConfirm, setDeleteConfirm] = useState<"all" | "selected" | null>(null);

  const checkToDelete = (item: TTurnOffReportVectors) => {
    const checked = !!arrToRemove?.find((el) => el.id === item.id);
    if (checked) {
      setArrToRemove(arrToRemove.filter((el) => el.id !== item.id));
    } else {
      if (!!arrToRemove?.length) {
        setArrToRemove([...arrToRemove, item]);
      } else {
        setArrToRemove([item]);
      }
    }
  };

  const deleteVectors = async () => {
    setIsLoading(true);
    const { response } = await fetchRequest(statsApi.turnOffVectors(arrToRemove), {
      request: "Удаление векторов",
    });
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: "Успешно удалены",
          request: "Удаление векторов",
        })
      );
      setSelectedPairs(
        selectedPairs?.filter(
          (el) =>
            !response.some(
              (resEl: TTurnOffReportVectors) => resEl.id === el.id && resEl.in_report === false
            )
        )
      );
      setArrToRemove([]);
    }
    setDeleteConfirm(null);
  };

  const deleteAllVectors = async () => {
    setIsLoading(true);
    const { response } = await fetchRequest(statsApi.turnOffVectors(selectedPairs), {
      request: "Удаление всех векторов",
    });
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: "Успешно удалены",
          request: "Удаление всех векторов",
        })
      );
      setSelectedPairs(
        selectedPairs?.filter(
          (el) =>
            !response.some(
              (resEl: TTurnOffReportVectors) => resEl.id === el.id && resEl.in_report === false
            )
        )
      );
      setArrToRemove([]);
    }
    setDeleteConfirm(null);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [statsReports]);

  return (
    <div
      className={cn(
        "w-full frame square-scroll rounded-sm bg-[#F5F5FD] flex flex-col gap-8 py-8 overflow-y-auto h-full max-h-[70vh] text-sm",
        className
      )}
      style={{
        height: "auto",
        margin: "10px 0",
      }}>
      <small className="text-lightFont leading-4 whitespace-pre opacity-80 pl-[6px]">
        {`Перед тем, как нажать "Подтверить",\nпроверьте выбранные пары`}
      </small>
      <div className="flex w-full relative">
        <input
          className="text-xs w-full border border-[#EEE] rounded-sm outline-none mx-4 px-8 h-[25px]"
          placeholder="Поиск"
          onChange={(e) => setFilterStr(e.target.value)}
        />
        <img
          src={search}
          alt="Найти"
          width={16}
          height={16}
          className="absolute top-4 right-[16px] opacity-30"
        />
      </div>
      <div className="flex items-center justify-between px-8">
        <Button
          type="button"
          variant="outline"
          className="px-3 text-xs py-2 h-fit"
          onClick={() => setDeleteConfirm("selected")}
          disabled={isLoading || !arrToRemove?.length}>
          удалить выбранные
        </Button>
        <Button
          type="button"
          variant="destructive"
          className="px-3 text-xs py-2 h-fit"
          onClick={() => setDeleteConfirm("all")}
          disabled={isLoading}>
          удалить все
        </Button>
      </div>
      <h3 className="font-bold">Выбранные пары</h3>
      <div className="flex flex-col">
        <div className="grid grid-cols-3 gap-8 w-full px-8">
          {columns.map((column) => (
            <span key={column} className="flex text-xs font-medium pl-[10px]">
              {column}
            </span>
          ))}
        </div>
        {selectedPairs.length === 0 && (
          <div className="flex w-full items-center justify-center py-[6px] text-[14px]">
            {emptyMessage || "Вы еще не создали пары"}
          </div>
        )}
        {selectedPairs
          ?.filter((pair: TTurnOffReportVectors) => {
            if (!!filterStr?.length) {
              const pairStr = JSON.stringify(pair)?.toLowerCase();
              const str = filterStr?.toLowerCase();
              return (
                pairStr?.includes(str) ||
                pairStr?.includes(charFix(str, true)) ||
                pairStr?.includes(charFix(str, false)) ||
                pairStr?.includes(translit(str))
              );
            } else {
              return true;
            }
          })
          ?.map((pair: TTurnOffReportVectors) => {
            const checked = !!arrToRemove?.find((el) => el.id === pair.id);
            return (
              <SelectedSimplePairCard
                key={pair.id}
                selectedPair={pair}
                checked={checked}
                checkToDelete={checkToDelete}
                isAccess={isAccess}
              />
            );
          })}
      </div>
      {!!isDeleteConfirm && (
        <Popup closeModal={() => setDeleteConfirm(null)}>
          <div className="flex flex-col gap-7 items-center max-w-[300px]">
            <div className="text-center">
              Вы уверены что хотите удалить из отчетов{" "}
              {isDeleteConfirm === "all" ? <b>все векторы</b> : "следующие векторы"}?
            </div>
            {isDeleteConfirm === "selected" && (
              <div className="flex flex-col gap-4">
                {arrToRemove?.map((el) => (
                  <div key={el.id}>
                    {el.city_name} {el.currency_from_code || ""} {el.currency_to_code || ""}
                  </div>
                ))}
              </div>
            )}
            <Button
              variant="destructive"
              className="px-5"
              disabled={isLoading}
              onClick={() => {
                if (isDeleteConfirm === "all") {
                  deleteAllVectors();
                } else if (isDeleteConfirm === "selected") {
                  deleteVectors();
                }
              }}>
              {isDeleteConfirm === "all" ? "Удалить все" : "Удалить выбранные"}
            </Button>
          </div>
        </Popup>
      )}
    </div>
  );
};
