import { useMemo } from "react";
import { minidenticon } from "minidenticons";

export const Avatar = ({ username }) => {
  const svgURI = useMemo(
    () => "data:image/svg+xml;utf8," + encodeURIComponent(minidenticon(username, 95, 45)),
    [username]
  );
  return <img src={svgURI} alt={username} />;
};
