import React from "react";
import { Route, Routes } from "react-router-dom";
import { LS, Url } from "../../api/constants";
import Auth from "../../components/Auth";
import AuthRoute from "./AuthRoute";
import { Stats } from "../Stats/Stats";
import { StatsReports } from "../Stats/StatsReports/StatsReports";
import { SiteClusters } from "../Clusters/SiteClusters/SiteClusters";
import { Timeline } from "../Clusters/Timeline/TL";
import { Add } from "../Add/Add";
import { Rates } from "../Rates/Rates";
import { Analytics } from "../Analytics/Analytics";
import { DirectionsGraph } from "../Panel/DirectionsGraph/DirectionsGraph";
import { MinMax } from "../Panel/MinMax/MinMax";
import { Step } from "../Panel/Step/Step";
import { OnOff } from "../Panel/OnOff/OnOff";
import { Summary } from "../Panel/Summary/Summary";
import { Tetris } from "../Tetris/Tetris";
import { Bid } from "../Bid/Bid";
import { ProfPage } from "src/pages/Bid/ProfPage";
import { Developers } from "../Developers/Developers";
import { Requests } from "../Requests/Requests";
import { Flights } from "../Flights/Flights";
import { Control } from "../Control/Control";
import { ControlDashboard } from "../Control/Stats/DashBoard/ControlDashboard";
import { controlRoutes, TRoute } from "../Control/components/routes";
import { Text } from "../Text/Text";
import { MinMaxSheet } from "../Panel/MinMaxSheet/MinMaxSheet";
import { NewFeatures } from "../NewFeatures/NewFeatures";
import { OnOffTimeline } from "src/pages/Panel/OnOff/OnOffTimeline/OnOffTimeline";
import { Academy } from "../Academy/Academy";
import { TAcademyRoute, academyRoutes } from "../Academy/routes";

const Routing = ({ mAccess, sAccess }: { mAccess: boolean; sAccess: boolean }) => {
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");

  return (
    <Routes>
      {!user_access && <Route path={Url.LOGIN} element={<Auth />} />}
      {user_access && (
        <>
          {(user_access?.includes("stats_get") || user_access?.includes("stats_post")) && (
            <Route
              path={Url.DEFAULT}
              element={
                <AuthRoute>
                  <Stats />
                </AuthRoute>
              }
            />
          )}
          <Route
            path={Url.RATES}
            element={
              <AuthRoute>
                <Rates />
              </AuthRoute>
            }
          />
          <Route
            path={Url.FLIGHTS}
            element={
              <AuthRoute>
                <Flights />
              </AuthRoute>
            }
          />
          {(user_access?.includes("bid_get") || user_access?.includes("bid_post")) && (
            <Route
              path={Url.BID}
              element={
                <AuthRoute>
                  <Bid />
                </AuthRoute>
              }
            />
          )}
          {/* {(user_access?.includes("bid_get") || user_access?.includes("bid_post")) && (
            <Route
              path={Url.LIMITS}
              element={
                <AuthRoute>
                  <Limits />
                </AuthRoute>
              }
            />
          )} */}
          {(user_access?.includes("bid_get") || user_access?.includes("bid_post")) && (
            <Route
              path={Url.PROF}
              element={
                <AuthRoute>
                  <ProfPage />
                </AuthRoute>
              }
            />
          )}
          {(user_access?.includes("stats_get") || user_access?.includes("stats_post")) && (
            <>
              <Route
                path={Url.STATS}
                element={
                  <AuthRoute>
                    <Stats />
                  </AuthRoute>
                }
              />
              <Route
                path={Url.STATS_REPORTS}
                element={
                  <AuthRoute>
                    <StatsReports />
                  </AuthRoute>
                }
              />
              <Route
                path={Url.ANALYTICS}
                element={
                  <AuthRoute>
                    <Analytics />
                  </AuthRoute>
                }
              />
            </>
          )}
          {(user_access?.includes("cluster_get") || user_access?.includes("cluster_post")) && (
            <>
              <Route
                path={Url.SITE_CLUSTERS}
                element={
                  <AuthRoute>
                    <SiteClusters />
                  </AuthRoute>
                }
              />
              <Route
                path={Url.CLUSTER_TIMELINE}
                element={
                  <AuthRoute>
                    <Timeline />
                  </AuthRoute>
                }
              />
            </>
          )}
          {(user_access?.includes("add_get") ||
            user_access?.includes("add_post") ||
            user_access?.includes("user_post")) && (
            <Route
              path={Url.ADD}
              element={
                <AuthRoute>
                  <Add />
                </AuthRoute>
              }
            />
          )}
          {user_access?.includes("on-off_post") && (
            <Route
              path={Url.TEXT}
              element={
                <AuthRoute>
                  <Text />
                </AuthRoute>
              }
            />
          )}
          {(user_access?.includes("control_get") || user_access?.includes("merch_get")) && (
            <Route
              path={Url.CONTROL}
              element={
                <AuthRoute>
                  <Control />
                </AuthRoute>
              }>
              {" "}
              {}
              {Object.values(controlRoutes)?.map((el1: TRoute, index1: number) => {
                const NewComponentA = el1.route[2] as React.FC;
                if (!!user_access?.some((access) => el1?.access?.includes(access))) {
                  return (
                    <Route
                      key={index1}
                      path={el1.route[1] as string}
                      element={
                        <AuthRoute>
                          <NewComponentA />
                        </AuthRoute>
                      }>
                      {}
                      {el1?.children !== null &&
                        Object.values(el1?.children)?.map((el2: TRoute, index2: number) => {
                          const NewComponentB = el2.route[2] as React.FC;
                          return (
                            <Route
                              key={`${index1}_${index2}`}
                              path={el2.route[1] as string}
                              element={
                                <AuthRoute>{!!NewComponentB && <NewComponentB />}</AuthRoute>
                              }>
                              {}
                              {el2?.children !== null &&
                                Object.values(el2?.children)?.map((el3: TRoute, index3: number) => {
                                  const NewComponentC = el3.route[2] as React.FC;
                                  return (
                                    <Route
                                      key={`${index1}_${index2}_${index3}`}
                                      path={el3.route[1] as string}
                                      element={
                                        <AuthRoute>
                                          <NewComponentC />
                                        </AuthRoute>
                                      }
                                    />
                                  );
                                })}
                            </Route>
                          );
                        })}
                    </Route>
                  );
                }
              })}
            </Route>
          )}
          {(user_access?.includes("control_get") || user_access?.includes("merch_get")) && (
            <Route
              path={`${Url.CONTROL}/${Url.CONTROL_DASHBOARD}`}
              element={
                <AuthRoute>
                  <ControlDashboard />
                </AuthRoute>
              }
            />
          )}
          {(user_access?.includes("direction_get") || user_access?.includes("direction_post")) && (
            <Route
              path={Url.DIRECTION_GRAPH}
              element={
                <AuthRoute>
                  <DirectionsGraph />
                </AuthRoute>
              }
            />
          )}
          {mAccess && (
            <Route
              path={Url.MINMAX}
              element={
                <AuthRoute>
                  <MinMax />
                </AuthRoute>
              }
            />
          )}
          {mAccess && (
            <Route
              path={Url.MINMAX_SHEET}
              element={
                <AuthRoute>
                  <MinMaxSheet />
                </AuthRoute>
              }
            />
          )}
          {sAccess && (
            <Route
              path={Url.STEP}
              element={
                <AuthRoute>
                  <Step />
                </AuthRoute>
              }
            />
          )}
          {(user_access?.includes("on-off_get") || user_access?.includes("on-off_post")) && (
            <>
              <Route
                path={Url.ONOFF}
                element={
                  <AuthRoute>
                    <OnOff />
                  </AuthRoute>
                }
              />
              <Route
                path={Url.ONOFF_TIMELINE}
                element={
                  <AuthRoute>
                    <OnOffTimeline />
                  </AuthRoute>
                }
              />
            </>
          )}
          {user_access?.includes("direction_get") && (
            <Route
              path={Url.TETRIS}
              element={
                <AuthRoute>
                  <Tetris />
                </AuthRoute>
              }
            />
          )}
          {user_access?.includes("direction_get") && (
            <Route
              path={Url.SUMMARY}
              element={
                <AuthRoute>
                  <Summary />
                </AuthRoute>
              }
            />
          )}
          {user_access?.includes("admin_post") && (
            <Route
              path={Url.DEVELOPERS}
              element={
                <AuthRoute>
                  <Developers />
                </AuthRoute>
              }
            />
          )}
          {user_access?.includes("it_requests_get") && (
            <Route
              path={Url.REQUESTS}
              element={
                <AuthRoute>
                  <Requests />
                </AuthRoute>
              }
            />
          )}
          {user_access?.includes("courses_get") && (
            <Route
              path={Url.ACADEMY}
              element={
                <AuthRoute>
                  <Academy />
                </AuthRoute>
              }>
              {Object.values(academyRoutes)?.map((el: TAcademyRoute) => {
                const NewComponentA = el.element as React.FC;
                if (!!user_access?.some((access) => el?.access?.includes(access))) {
                  return (
                    <Route
                      key={el.path}
                      path={el.path}
                      element={
                        <AuthRoute>
                          <NewComponentA />
                        </AuthRoute>
                      }></Route>
                  );
                }
              })}
            </Route>
          )}
          <Route
            path={Url.NEWS}
            element={
              <AuthRoute>
                <NewFeatures />
              </AuthRoute>
            }
          />
        </>
      )}
      {!user_access && (
        <Route
          path={"/*"}
          element={
            <AuthRoute>
              <Auth />
            </AuthRoute>
          }
        />
      )}
    </Routes>
  );
};

export default Routing;
