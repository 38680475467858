import { BASE_URL } from "src/api/constants";
import api from "src/api/http";
import { Exchanger, ToggleExchangerResponse } from "src/types/exchangers";

export default class exchangersApi {
  static async getExchangers() {
    return api.get<Exchanger[] | undefined>(`${BASE_URL}/v1/course_api/v1/exchanger/`);
  }

  static async toggleExchanger({ exchanger_id, exchanger_is_broadcast }: ToggleExchangerResponse) {
    return api.patch<ToggleExchangerResponse | undefined>(
      `/v1/course_api/v1/exchanger/${exchanger_id}/`,
      {
        exchanger_is_broadcast,
      }
    );
  }
}
