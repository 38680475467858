import { BASE_URL, DB_URL } from "./constants";
import api from "./http";

export default class tetrisApi {
  // Автокор
  static async getTetrisAutocorr() {
    return api.get(`${DB_URL}/autocorr/`);
  }
  static async createTetrisAutocorr(data) {
    return api.patch(`${DB_URL}/autocorr/`, data);
  }
  static async deleteTetrisAutocorr(data) {
    return api.post(`${DB_URL}/autocorr/`, data);
  }
  static async updateTetrisAutocorr(data) {
    return api.patch(`${DB_URL}/autocorr/lock/`, data);
  }
  // Блеклисты
  static async getTetrisBlacklist() {
    return api.get(`${DB_URL}/blacklist/`);
  }
  static async createTetrisBlacklist(data) {
    return api.post(`${DB_URL}/blacklist/many/`, data);
  }
  static async deleteTetrisBlacklist(data) {
    return api.delete(`${DB_URL}/blacklist/`, { data });
  }
  static async getAlienSites() {
    return api.get(`${BASE_URL}/pair_builder/all-best-sites/`);
  }
  // Кластеры
  static async getTetrisCluster() {
    return api.get(`${BASE_URL}/cluster/all`);
  }
  static async createTetrisCluster(data) {
    return api.post(`${BASE_URL}/cluster/percent`, data);
  }
  static async deleteTetrisCluster(data) {
    return api.post(`${BASE_URL}/cluster/delete`, data);
  }
  static async updateTetrisCluster(data) {
    return api.post(`${BASE_URL}/cluster/lock`, data);
  }
  // Расписание
  static async getTetrisSchedule() {
    return api.get(`${BASE_URL}/cluster-manager/schedule/`);
  }
  static async createTetrisSchedule(data) {
    return api.post(`${BASE_URL}/cluster-manager/schedule/old`, data);
  }
  static async deleteTetrisSchedule(data) {
    return api.post(`${BASE_URL}/cluster-manager/schedule/delete`, data);
  }
  static async updateTetrisSchedule(data) {
    return api.post(`${BASE_URL}/cluster-manager/schedule/lock`, data);
  }
  static async updateTetrisScheduleInfinity(data) {
    return api.post(`${BASE_URL}/cluster-manager/schedule/infinity`, data);
  }
}
