type TProps = {
  title: string;
  color: string;
  isCircle?: boolean;
};

export const ColorTip = ({ color, title, isCircle }: TProps) => {
  return (
    <div className="flex gap-8 items-center whitespace-pre">
      <div
        style={{ backgroundColor: color }}
        className={`h-[16px] w-[16px] min-w-[16px] border border-lightGray ${
          isCircle ? "rounded-full" : "rounded-sm"
        }`}></div>
      <div>{title}</div>
    </div>
  );
};
