import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TCurrencyTypes } from "src/pages/Text/types";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";

type TProps = {
  currencyName: string;
  setCurrencyName: Dispatch<SetStateAction<string>>;
  setCurrencyId: Dispatch<SetStateAction<number>>;
  setExnodeId: Dispatch<SetStateAction<string>>;
  currencyType: TCurrencyTypes;
  setCurrencyType: Dispatch<SetStateAction<TCurrencyTypes>>;
  setTranscript: Dispatch<SetStateAction<string>>;
  setTranslation: Dispatch<SetStateAction<string>>;
  setFullName: Dispatch<SetStateAction<string>>;
  setFullNameEn: Dispatch<SetStateAction<string>>;
  setCreateVectors: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  setStep: Dispatch<SetStateAction<number>>;
};

export const AddCurrency = ({
  currencyName,
  setCurrencyName,
  setCurrencyId,
  currencyType,
  setCurrencyType,
  setTranscript,
  setTranslation,
  setFullName,
  setCreateVectors,
  setFullNameEn,
  setExnodeId,
  disabled,
  setStep,
}: TProps) => {
  const [error, setError] = useState("");
  const cashTerm =
    currencyType === "cash" && currencyName?.length > 2 && currencyName?.substring(0, 4) !== "CASH";
  const CashNotific = "Называйте все наличные с CASH";

  useEffect(() => {
    if (cashTerm) {
      setError(CashNotific);
    }
  }, [currencyType, currencyName]);

  useEffect(() => {
    if (error?.length) {
      setTimeout(() => setError(""), 5000);
    }
  }, [error]);

  return (
    <div className="p-[20px] relative flex flex-col">
      <div className="flex w-full gap-4 justify-center mb-[16px] text-sm font-semibold">
        <button
          type="button"
          className={`py-2 px-8 rounded-md ${
            currencyType === "crypto" ? "bg-font text-white" : "bg-bg"
          }`}
          onClick={() => setCurrencyType("crypto")}>
          CRYPTO
        </button>
        <button
          type="button"
          className={`py-2 px-8 rounded-md ${
            currencyType === "cash" ? "bg-font text-white" : "bg-bg"
          }`}
          onClick={() => setCurrencyType("cash")}>
          CASH
        </button>
        <button
          type="button"
          className={`py-2 px-8 rounded-md ${
            currencyType === "card" ? "bg-font text-white" : "bg-bg"
          }`}
          onClick={() => setCurrencyType("card")}>
          CARD
        </button>
      </div>
      <div className="text-[14px] font-bold  leading-3 text-left text-font">Название:</div>
      <input
        onChange={({ target }) => setCurrencyName(target.value.toUpperCase())}
        type="text"
        required
        placeholder={
          currencyType === "crypto" ? "BTC" : currencyType === "cash" ? "CASHUSD" : "SBERRUB"
        }
        className="text-sm lg:w-[250px] border border-lightGray rounded outline-none h-[30px] py-2 px-8 my-[10px]"
      />
      {error?.length > 0 && (
        <div className="small-text w-full whitespace-pre leading-3 absolute top-[62px] right-[20px]">
          <div className="text-red text-[10px] font-bold text-right">{error}</div>
        </div>
      )}
      <div className="text-[14px] font-bold  leading-3 text-left text-font">ID на BESTCHANGE:</div>
      <input
        onChange={({ target }) => setCurrencyId(+target.value)}
        type="number"
        required
        placeholder={"123"}
        className="text-sm lg:w-[250px] border border-lightGray rounded outline-none h-[30px] py-2 px-8 my-[10px]"
      />
      <div className="text-[14px] font-bold  leading-3 text-left text-font">ID на EXNODE:</div>
      <input
        onChange={({ target }) => setExnodeId(target.value)}
        type="number"
        placeholder={"123"}
        className="text-sm lg:w-[250px] border border-lightGray rounded outline-none h-[30px] py-2 px-8 my-[10px]"
      />
      <div className="text-[14px] font-bold  leading-3 text-left text-font">Транскрипция:</div>
      <input
        onChange={({ target }) => setTranscript(target.value)}
        type="text"
        required
        placeholder={
          currencyType === "crypto"
            ? "bitcoin"
            : currencyType === "cash"
            ? "cash-dollar"
            : "sberbank"
        }
        className="text-sm lg:w-[250px] border border-lightGray rounded outline-none h-[30px] py-2 px-8 my-[10px]"
      />
      <div className="text-[14px] font-bold  leading-3 text-left text-font">Перевод:</div>
      <input
        onChange={({ target }) => setTranslation(target.value)}
        type="text"
        required
        placeholder={
          currencyType === "crypto"
            ? "Биткоин"
            : currencyType === "cash"
            ? "Доллар"
            : "Сбербанк RUB"
        }
        className="text-sm lg:w-[250px] border border-lightGray rounded outline-none h-[30px] py-2 px-8 my-[10px]"
      />
      <div className="text-[14px] font-bold  leading-3 text-left text-font">
        Полное наименование:
      </div>
      <input
        onChange={({ target }) => setFullName(target.value)}
        type="text"
        required
        placeholder={
          currencyType === "crypto"
            ? "Bitcoin BTC"
            : currencyType === "cash"
            ? "Dollar CASHUSD"
            : "Сбербанк RUB"
        }
        className="text-sm lg:w-[250px] border border-lightGray rounded outline-none h-[30px] py-2 px-8 my-[10px]"
      />
      <div className="text-[14px] font-bold leading-4 text-left text-font">
        Полное наименование на английском:
      </div>
      <input
        onChange={({ target }) => setFullNameEn(target.value)}
        type="text"
        required
        placeholder={
          currencyType === "crypto"
            ? "Bitcoin BTC"
            : currencyType === "cash"
            ? "Dollar CASHUSD"
            : "Dollar CASHUSD"
        }
        className="text-sm lg:w-[250px] border border-lightGray rounded outline-none h-[30px] py-2 px-8 my-[10px]"
      />
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <label className="flex items-center justify-start my-[8px] gap-[10px] cursor-pointer">
              <input
                type="checkbox"
                className="border border-lightGray rounded outline-none h-[16px] w-[16px]"
                onChange={(e) => setCreateVectors(e.target.checked)}
              />
              <div className="text-[14px] leading-[1]">Автоматически создать вектора</div>
            </label>
          </TooltipTrigger>
          <TooltipContent className="text-center max-w-[200px]">
            Если опция выбрана, будут автоматически созданы все возможные векторы
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {currencyType !== "card" && (
        <div className="w-[250px] flex justify-center">
          <button
            onClick={() => setStep(1)}
            className={`w-[40px] h-[40px] ml-[10px] rounded-[50%] flex justify-center items-center mt-[20px] text-white font-extrabold text-[12px] hover:opacity-[0.8] bgActiveAnimation ${
              !disabled ? "bg-font cursor-pointer" : "bg-white cursor-default"
            }`}>
            {`\u2713`}
          </button>
        </div>
      )}
    </div>
  );
};
