import { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/store";
import { fetchRequest } from "src/helpers/fetchRequest";
import academyApi from "src/api/academy";
import { LS, Url } from "src/api/constants";
import {
  selectModules,
  selectResult,
  selectTests,
  setAttempts,
  setBlocks,
  setModules,
  setTest,
  setTests,
} from "src/store/academyReducer";
import { TestNav } from "./components/TestNav";
import { TNav, TPagination } from "./types";
import { TestCard } from "./components/TestCard";
import { Result } from "./Result/Result";
import { Pagination } from "src/components/UI/pagination/Pagination";

export const Academy = () => {
  const dispatch = useAppDispatch();
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const isCreator = userAccess?.includes("courses_post");
  const [searchParams] = useSearchParams();
  const course_id = searchParams.get("id");
  const module_id = searchParams.get("module_id");
  const tab = useLocation().pathname;
  const isStartPage = tab?.replaceAll("/", "") === Url.ACADEMY.replaceAll("/", "");
  const isEdit = !isStartPage && tab?.includes("edit");
  const tests = useSelector(selectTests);
  const modules = useSelector(selectModules);
  const result = useSelector(selectResult);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingAttempts, setIsLoadingAttempts] = useState(false);
  const limit = 10;
  const totalPages = Math.ceil(totalCount / limit);

  const tabs: TNav[] = modules?.map((el) => ({
    title: el.module_name,
    module_id: String(el.module_id),
    module_type: el.module_type,
    path:
      Url.ACADEMY +
      Url.ACADEMY_SOLVE +
      `?id=${course_id}` +
      `&module_id=${el.module_id}` +
      `&type=${el.module_type}`,
  }));

  const fetchTests = async () => {
    const pagination: TPagination = {
      limit: limit,
      offset: limit * currentPage,
    };
    const { response } = await fetchRequest(academyApi.getTests(pagination), { request: "Тесты" });
    if (response) {
      dispatch(setTests(response.courses));
      setTotalCount(response.total_count);
    }
  };

  const fetchModules = async (course_id: number) => {
    const { response } = await fetchRequest(academyApi.getModules(course_id), {
      request: "Модули",
    });
    if (response) {
      dispatch(setModules(response));
    }
  };

  const fetchAttempts = async () => {
    setIsLoadingAttempts(true);
    const { response } = await fetchRequest(academyApi.getAttempts(), {
      request: "Попытки",
    });
    if (response) {
      dispatch(setAttempts(response));
    }
    setIsLoadingAttempts(false);
  };

  useEffect(() => {
    if (!result) {
      fetchAttempts();
    }
  }, [result]);

  useEffect(() => {
    fetchTests();
  }, [currentPage]);

  useEffect(() => {
    if (!!course_id) {
      fetchModules(+course_id);
    }
  }, [course_id]);

  useEffect(() => {
    if (!!modules?.length && !!module_id && module_id !== Url.ACADEMY_MODULE_PARAM) {
      const current_module = modules.find((el) => el.module_id === +module_id);
      if (!!current_module) {
        dispatch(setBlocks(current_module.module_blocks));
      }
    }
  }, [module_id, modules]);

  return (
    <div
      className={`relative text-left md:pl-[16px] pr-[50px] md:pr-[80px] flex flex-col gap-8 pb-[100px]`}>
      {isEdit && (
        <div className="fixed z-20 right-[50px] md:right-[80px] top-[100px] px-5 py-3 rounded-sm shadow-sm bg-purple-200 text-sm text-purple-500">
          Редактирование
        </div>
      )}
      {!isStartPage && !isEdit && !result && <TestNav isEdit={isEdit} tabs={tabs} />}
      {isStartPage && !result && (
        <div
          className={`w-full flex flex-col items-center gap-10 whitespace-pre-line ${
            isEdit ? "markdown-edit" : "markdown"
          }`}>
          <h2>Выберите тест</h2>
          <div className="w-full flex justify-center flex-wrap gap-3">
            {tests?.map((test) => {
              return (
                <TestCard
                  key={test.course_id}
                  test={test}
                  isCreator={isCreator}
                  isLoadingAttempts={isLoadingAttempts}
                />
              );
            })}
            <NavLink
              to={Url.ACADEMY_EDIT}
              className="relative w-[140px] min-h-[200px] bg-slate-50 shadow-md rounded-md flex justify-center items-center duration-300 hover:scale-105 hover:shadow-lg text-[40px] font-bold"
              onClick={() => dispatch(setTest(null))}>
              +
            </NavLink>
          </div>
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      )}
      {!!result ? (
        <Result />
      ) : (
        <div className={`whitespace-pre-line ${isEdit ? "markdown-edit" : "markdown"}`}>
          <Outlet context={[isCreator]} />
        </div>
      )}
    </div>
  );
};
