import { Dispatch, SetStateAction, useRef, useState } from "react";
import { defaultDateTime } from "src/helpers/date";

type TProps = {
  title: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

export const DateField = ({ title, value, setValue }: TProps) => {
  const [isUpd, setUpd] = useState(false);
  const dateRef = useRef(null);

  return (
    <div className="grid grid-cols-[66px_1fr] items-center gap-[10px]">
      <div className="text-right">{title}</div>
      <div className="relative w-[160px] h-[18px]">
        <div
          className={`absolute top-[1px] bg-stale text-font whitespace-pre ${
            !isUpd ? "" : "-z-10 opacity-0"
          } pl-2 pr-4 rounded-full text-[9px] h-[14px] my-2 cursor-pointer`}
          onClick={() => {
            setUpd(true);
            dateRef.current.focus();
          }}>
          {`\u23F1`} {value}
        </div>
        <input
          className={`absolute -top-[1px] px-8 ${
            isUpd ? "" : "-z-10 opacity-0"
          } h-[20px] font-light`}
          type={"datetime-local"}
          ref={dateRef}
          defaultValue={defaultDateTime()}
          onBlur={async (e) => {
            setUpd(false);
            setValue(e.target.value + ":00+00:00");
          }}
        />
      </div>
    </div>
  );
};
