import { useEffect, useState } from "react";
import { Diagram } from "src/pages/Panel/DirectionsGraph/Entropy/Entropy";
import { Question } from "../components/Question/Question";
import { useSelector } from "react-redux";
import { selectResult, reset } from "src/store/academyReducer";
import { Button } from "src/shadcn/ui/ui/button";
import { useAppDispatch } from "src/store/store";
import { useNavigate } from "react-router-dom";
import { LS, Url } from "src/api/constants";
import authApi from "src/api/auth";
import { ESectionType } from "../types";
import { fetchRequest } from "src/helpers/fetchRequest";

export const Result = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const result = useSelector(selectResult);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (!!result) {
      setTrigger(false);
      setTimeout(() => {
        setTrigger(true);
        if (result.module?.module_type === ESectionType.EXAMINATION && result?.attempt_successful) {
          (async () => {
            const { response, status } = await fetchRequest(authApi.refreshToken());
            if (response) {
              if (!!response && status >= 200 && status < 300) {
                localStorage.setItem(LS.ACCESS, response?.user_access_node?.join(", "));
                localStorage.setItem(LS.ROLE, response?.user_role_name);
              }
            }
          })();
        }
      }, 500);
    }
  }, [result]);

  return (
    <div className="w-full max-w-[900px] mx-auto py-5 flex flex-col gap-10 markdown">
      <div className={`mx-auto`}>
        <Diagram
          item={[0, result?.attempt_percentage]}
          index={5}
          trigger={trigger}
          size={40}
          name={result?.attempt_successful ? "Тестирование успешно пройдено!" : "Попробуйте еще"}
          isGood={result?.attempt_successful}
        />
      </div>
      {result?.questions?.map((question) => {
        const yourAnswer = result?.attempt_answers?.find(
          (el) => el.question_id === question.question_id
        );
        return (
          <Question
            key={question.question_id}
            question={question}
            isEditPage={false}
            yourAnswer={yourAnswer || null}
          />
        );
      })}
      <Button
        onClick={() => {
          dispatch(reset());
          navigate(Url.ACADEMY);
        }}>
        На главную
      </Button>
    </div>
  );
};
