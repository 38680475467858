import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { LS, Url } from "src/api/constants";
import { selectUnreadReqCount, setPinned } from "src/store/commonReducer";
import { DropList } from "../../UI/dropModal/DropList";
import arrow from "src/assets/images/arrow.svg";
import pin from "src/assets/images/pin.svg";

type TProps = {
  item;
  page: string;
  isOpenTabs: string;
  setIsOpenTabs: Dispatch<SetStateAction<string>>;
  isTitle: boolean;
  pinned: Array<string>;
  hidePin: boolean;
  userAccess: Array<string>;
};

export const IconNavItem = ({
  item,
  page,
  isOpenTabs,
  setIsOpenTabs,
  isTitle,
  pinned,
  hidePin,
  userAccess,
}: TProps) => {
  const dispatch = useDispatch();
  const [isPinVisible, setPinVisible] = useState(false);
  const unreadReqCount = useSelector(selectUnreadReqCount);
  const isPinned = useMemo(() => pinned?.includes(item.path) || false, [pinned]);
  const color = useMemo(() => (!hidePin && "color" in item ? item?.color : "inherit"), []);

  const open = () => {
    if (isOpenTabs === item.title) {
      setIsOpenTabs("");
    } else {
      setIsOpenTabs(item.title);
    }
  };

  return (
    <div
      className="relative"
      onMouseMove={() => setPinVisible(true)}
      onMouseLeave={() => setPinVisible(false)}>
      <div
        style={{ color: color, backgroundColor: color !== "inherit" ? `${color}33` : "inherit" }}
        className={`cursor-pointer flex w-full items-center justify-between rounded-sm hover:bg-[#FFFFFF33] ${
          hidePin && !!item?.icon ? "px-8" : hidePin && !item?.icon ? "pr-8" : "pl-8 pr-4"
        } ${
          page?.split("/")?.filter((el) => el !== "")[0] === item.path?.replace("/", "")
            ? ""
            : "opacity-60 hover:opacity-100"
        }`}
        onClick={open}>
        {!!item?.children?.length ? (
          <div
            className={`flex items-center gap-8 h-[30px] w-full min-w-[30px] ${
              !!item?.icon ? "text-sm" : "text-xs"
            }`}>
            {!!item?.icon ? (
              <span className="flex justify-center items-center w-[30px]">
                <img
                  src={item?.icon}
                  alt={item.title}
                  width={18}
                  height={18}
                  className={`${
                    color !== "inherit" && !hidePin
                      ? "brightness-[0.2]"
                      : hidePin
                      ? ""
                      : "brightness-50"
                  }`}
                />
              </span>
            ) : (
              <div />
            )}
            {isTitle && <div className={`w-full ${!!item?.icon ? "" : "pt-2"}`}>{item?.title}</div>}
          </div>
        ) : (
          <NavLink
            to={item.path}
            className={`flex items-center gap-8 h-[30px] w-full min-w-[30px] ${
              !!item?.icon ? "text-sm" : "text-xs"
            }`}>
            {!!item?.icon ? (
              <span className="flex justify-center items-center w-[30px]">
                <img
                  src={item?.icon}
                  alt={item.title}
                  width={18}
                  height={18}
                  className={`${hidePin ? "" : "brightness-50"}`}
                />
              </span>
            ) : (
              <div />
            )}
            {isTitle && <div className={`w-full ${!!item?.icon ? "" : "pt-2"}`}>{item?.title}</div>}
          </NavLink>
        )}
        {!!item?.children?.length ? (
          <div className="relative pr-[5px]">
            <img
              src={arrow}
              alt="открыть"
              width={10}
              height={10}
              className={`duration-300 ${isOpenTabs === item.path ? "rotate-180" : "rotate-0"}`}
            />
          </div>
        ) : (
          <div />
        )}
        {item.path === Url.REQUESTS && !!unreadReqCount && unreadReqCount > 0 && (
          <div className="relative top-px flex items-center justify-center rounded-full text-white text-[11px] leading-[11px] bg-[#F33] w-fit min-w-[16px] h-[16px] pl-2 pr-[3px] pb-px mx-4">
            {unreadReqCount}
          </div>
        )}
        {!item?.children?.length && !hidePin && (
          <button
            type="button"
            className={`flex justify-center items-center rounded-sm p-4 h-[20px] min-w-[20px] hover:bg-bg ${
              isPinned ? "bg-bg" : ""
            }`}
            onClick={() => {
              if (isPinned) {
                localStorage.setItem(LS.PIN, pinned?.filter((el) => el !== item.path)?.join(", "));
                dispatch(setPinned(pinned?.filter((el) => el !== item.path)));
              } else {
                localStorage.setItem(LS.PIN, [...pinned, item.path]?.join(", "));
                dispatch(setPinned([...pinned, item.path]));
              }
            }}>
            <img
              src={pin}
              alt="pin"
              width={12}
              height={12}
              className={`brightness-50 duration-300 ${
                isPinVisible || isPinned ? "" : "opacity-0"
              }`}
            />
          </button>
        )}
      </div>
      {!!item?.children?.length && !hidePin && (
        <DropList
          tabs={item.children}
          page={page}
          isOpen={isOpenTabs === item.title}
          pinned={pinned}
          color={color}
          userAccess={userAccess}
        />
      )}
    </div>
  );
};
