import { BASE_URL } from "./constants";
import api from "./http";

export default class textApi {
  // Шаблоны
  static async getTemplates(data) {
    return api.get(
      `${BASE_URL}/admin-text/template/?site_id=${data?.site_id}&type_id=${data?.type_id}&currency_from_type=${data?.currency_from_type}&currency_to_type=${data?.currency_to_type}`
    );
  }
  static async addTemplates(data) {
    return api.post(`${BASE_URL}/admin-text/template/`, data);
  }
  static async updateTemplates(data) {
    return api.patch(`${BASE_URL}/admin-text/template/`, data);
  }
  static async deleteTemplates(template_id) {
    return api.delete(`${BASE_URL}/admin-text/template/?template_id=${template_id}`);
  }
  // Направления
  static async getDirections(site_id) {
    return api.get(`${BASE_URL}/admin-text/direction/all/${site_id}`);
  }
  static async getNotDirectionsTexts(site_id) {
    return api.get(`${BASE_URL}/admin-text/text/created-text-no-directions/${site_id}`);
  }
  // Переменные
  static async getAlias() {
    return api.get(`${BASE_URL}/admin-text/alias/`);
  }
  // Тексты
  static async getText(data) {
    return api.get(
      `${BASE_URL}/admin-text/text/` +
        `${"site_id" in data ? `?site_id=${data?.site_id}` : ""}` +
        `${"type_id" in data ? `&type_id=${data?.type_id}` : ""}` +
        `${
          "text_subtype_id" in data && !!data?.text_subtype_id
            ? `&text_subtype_id=${data?.text_subtype_id}`
            : ""
        }` +
        `${"city_id" in data ? `&city_id=${data?.city_id}` : ""}` +
        `${"currency_from_id" in data ? `&currency_from=${data?.currency_from_id}` : ""}` +
        `${"currency_to_id" in data ? `&currency_to=${data?.currency_to_id}` : ""}` +
        `${"lang" in data ? `&lang=${data?.lang}` : ""}`
    );
  }
  static async addText(data) {
    return api.post(`${BASE_URL}/admin-text/text/`, data);
  }
  static async updateText(data) {
    return api.patch(`${BASE_URL}/admin-text/text/`, data);
  }
  // Языки
  static async getLang() {
    return api.get(`${BASE_URL}/admin-text/lang/`);
  }
  static async addLang(data) {
    return api.post(`${BASE_URL}/admin-text/lang/`, data);
  }
  // Types - "before" | "after"
  static async getTypes() {
    return api.get(`${BASE_URL}/admin-text/type/`);
  }
  static async getSubTypes() {
    return api.get(`${BASE_URL}/admin-text/subtype/`);
  }
  static async addType(data) {
    return api.post(`${BASE_URL}/admin-text/type/`, data);
  }
}
