import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUniqueItems } from "src/helpers/getUniqueItems";
import {
  selectAllCities,
  selectAllSites,
  selectCity,
  selectCurFrom,
  selectCurTo,
  selectGlobalCurrencies,
  selectSite,
  setSelectedCity,
  setSelectedFrom,
  setSelectedSite,
  setSelectedTo,
} from "src/store/directionsReducer";

export enum GlobalFiltersType {
  CITIES = "cities",
  SITES = "sites",
  FROM_CURRENCIES = "from",
  TO_CURRENCIES = "to",
}

export const useGlobalFilters = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCity = useSelector(selectCity);
  const selectedSite = useSelector(selectSite);
  const selectedCurFrom = useSelector(selectCurFrom);
  const selectedCurTo = useSelector(selectCurTo);

  const globalSites = useSelector(selectAllSites);
  const globalCities = useSelector(selectAllCities);
  const globalCurrencies = useSelector(selectGlobalCurrencies);

  const cities = searchParams.get(GlobalFiltersType.CITIES)?.split(",") || [];
  const sites = searchParams.get(GlobalFiltersType.SITES)?.split(",") || [];
  const from = searchParams.get(GlobalFiltersType.FROM_CURRENCIES)?.split(",") || [];
  const to = searchParams.get(GlobalFiltersType.TO_CURRENCIES)?.split(",") || [];

  // при маунте страницы применить выбранные
  // сайт, город, валюты к searchParams
  useEffect(() => {
    if (selectedCity) {
      searchParams.set(
        GlobalFiltersType.CITIES,
        getUniqueItems([...cities, selectedCity.city_id]).join(",")
      );
    }

    if (selectedSite) {
      searchParams.set(
        GlobalFiltersType.SITES,
        getUniqueItems([...sites, selectedSite.site_name]).join(",")
      );
    }

    if (selectedCurFrom && selectedCurFrom !== "Все направления") {
      searchParams.set(
        GlobalFiltersType.FROM_CURRENCIES,
        getUniqueItems([...from, selectedCurFrom]).join(",")
      );
    }

    if (selectedCurTo && selectedCurTo !== "Все направления") {
      searchParams.set(
        GlobalFiltersType.TO_CURRENCIES,
        getUniqueItems([...to, selectedCurTo]).join(",")
      );
    }

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const city = globalCities.find((item) => item.city_code === cities[cities.length - 1]);

    if (!city) {
      dispatch(setSelectedCity(null));
      return;
    }

    if (!selectedCity || String(selectedCity.city_id) !== city.city_code) {
      dispatch(setSelectedCity({ city_id: city.city_code, city_name: city.city_name }));
    }
  }, [cities]);

  useEffect(() => {
    const site = globalSites.find((item) => item.site_name === sites[sites.length - 1]);
    if (site) {
      dispatch(setSelectedSite(site));
    } else {
      dispatch(setSelectedSite(null));
    }
  }, [sites]);

  useEffect(() => {
    const from_currency = globalCurrencies.find(
      (item) => item.currency_name === from[from.length - 1]
    );

    if (from_currency) {
      dispatch(setSelectedFrom(from_currency.currency_name));
    } else {
      dispatch(setSelectedFrom(null));
    }
  }, [from]);

  useEffect(() => {
    const to_currency = globalCurrencies.find((item) => item.currency_name === to[to.length - 1]);
    if (to_currency) {
      dispatch(setSelectedTo(to_currency.currency_name));
    } else {
      dispatch(setSelectedTo(null));
    }
  }, [to]);
};
