import { X } from "lucide-react";
import { FC, useEffect, useLayoutEffect, useRef, ReactNode } from "react";
import { createPortal } from "react-dom";

export const useCreatePortalInBody = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  if (wrapperRef.current === null && typeof document !== "undefined") {
    const div = document.createElement("div");
    div.setAttribute("data-body-portal", "");
    wrapperRef.current = div;
  }
  useLayoutEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper || typeof document === "undefined") {
      return;
    }
    document.body.appendChild(wrapper);
    return () => {
      document.body.removeChild(wrapper);
    };
  }, []);
  return (children) => wrapperRef.current && createPortal(children, wrapperRef.current);
};

export const Popup: FC<{ children: ReactNode; closeModal: () => void; noPadding?: boolean }> = ({
  children,
  closeModal,
  noPadding,
}) => {
  const createBodyPortal = useCreatePortalInBody();

  const catchCombination = (e) => {
    const isCase = e.keyCode === 27 || e.keyCode === 192;
    if (isCase) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", catchCombination);
    return () => document.removeEventListener("keyup", catchCombination);
  }, []);

  return createBodyPortal(
    <div
      id="wrapper"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0,0.5)",
        width: "100vw",
        height: "100vh",
        zIndex: 100,
      }}>
      <div
        className={`bg-bg absolute max-w-[85vw] overflow-y-auto max-h-[90vh] rounded-[8px] top-[40%] left-[50%] translate-x-[-50%] translate-y-[-40%] ${
          noPadding ? "" : "p-[22px]"
        }`}>
        <button
          className="absolute bg-white rounded-md p-[2px] top-[4px] right-[4px] text-[30px] z-[1]"
          onClick={() => {
            closeModal();
          }}>
          <X className="w-[16px] h-[16px]" />
        </button>
        {children}
      </div>
    </div>
  );
};
