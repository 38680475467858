import * as Sentry from "@sentry/react";

const environment = import.meta.env.REACT_APP_ENVIRONMENT;
const dsn = import.meta.env.REACT_APP_SENTRY_DSN;

export const sentryConfig = {
  dsn,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  environment,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};
