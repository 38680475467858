import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import { convertToOptions } from "src/helpers/formHelpers";
import addApi from "src/api/add";
import { selectAllRoles, TStrSelect } from "src/store/directionsReducer";
import { selectUsers, setUsers, TUser } from "src/store/commonReducer";
import { Popup } from "src/components/Popup/Popup";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { User } from "./User";
import { LS } from "src/api/constants";

type TProps = {
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  access: boolean;
};

export const Users = ({ setMessage, setStatus, setIsLoading, access }: TProps) => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const userRoles = useSelector(selectAllRoles);
  const userRole = localStorage.getItem(LS.ROLE);
  const isAdmin = userRole === "admin" || userRole === "mini_admin";
  const rolesOptions = useMemo(
    () =>
      convertToOptions(
        userRoles?.filter((el) =>
          isAdmin ? true : el !== "admin" && el !== "mini_admin" && el !== "courser_super_admin"
        )
      ) as Array<TStrSelect>,
    [userRoles, isAdmin]
  );
  const [isPopup, setPopup] = useState(false);
  const [isGood, setIsGood] = useState(true);
  const [selectedUser, setSelectedUser] = useState<TUser>();

  const fetchUsers = async () => {
    const { response, error } = await fetchRequest(addApi.getUsers(), { request: "Пользователи" });
    if (response) {
      dispatch(setUsers(response));
      setIsGood(true);
    }
    if (error) {
      setIsGood(false);
    }
  };

  const deleteUser = async () => {
    setIsLoading(true);
    const { response, error } = await fetchRequest(addApi.deleteUser(selectedUser?.id), {
      request: "Удаление пользователя",
    });
    if (response) {
      setMessage(`${selectedUser?.username} успешно удален`);
      setStatus(true);
      await fetchUsers();
    }
    if (error) {
      setMessage("Не удалось удалить\nпользователя");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      setPopup(false);
    }, 4600);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="w-full sm:w-[600px] duration-300 flex flex-col gap-[10px] font-light pl-[20px] pb-[100px]">
      {users?.map((el) => (
        <User
          key={el?.id}
          item={el}
          users={users}
          access={
            access &&
            (!isAdmin
              ? el.role_name !== "admin" &&
                el.role_name !== "mini_admin" &&
                el.role_name !== "courser_super_admin"
              : true)
          }
          setPopup={setPopup}
          setSelectedUser={setSelectedUser}
          rolesOptions={rolesOptions}
          setIsLoading={setIsLoading}
          setMessage={setMessage}
          setStatus={setStatus}
        />
      ))}
      {isPopup && (
        <Popup closeModal={() => setPopup(false)} noPadding>
          <div className="table-header px-[30px] flex justify-center">УДАЛИТЬ ПОЛЬЗОВАТЕЛЯ</div>
          <div className="px-[30px] py-[20px] text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nпользователя `}
              <b>{selectedUser?.username}</b> безвозвратно?
            </div>
            <button className="prime-button-sm" onClick={deleteUser}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
      {!isGood && !users?.length && <Emptiness message={"Ошибка сервиса"} />}
    </div>
  );
};
