export const OFFSET = -(new Date()?.getTimezoneOffset() * 60);

export const prepareSeconds = (date: number): number => {
  return date?.toString()?.length === 10 ? date : +date?.toString().substring(0, 10);
};

export const resetTimezone = (date: Date, isMoscow: boolean): string => {
  const offset = date.getTimezoneOffset();
  const hours = date?.getHours();
  const resHours = hours + offset / 60 + (isMoscow ? 3 : 0);
  date.setHours(resHours);

  return date?.toLocaleString("ru");
};

export const putZero = (number: number): string => {
  const str = number?.toString();
  return str.length === 2 ? str : "0" + str;
};

export const convertUnixDate = (date: number): string => {
  if (date) {
    const seconds = prepareSeconds(date);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Intl.DateTimeFormat("ru", options).format((+seconds - OFFSET) * 1000);
  } else return "";
};

export const convertToDate = (date: number): string => {
  if (date) {
    const seconds = prepareSeconds(date);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Intl.DateTimeFormat("ru", options).format(+seconds * 1000);
  } else return "";
};

export const convertToShortDate = (date: number): string => {
  if (date) {
    const seconds = prepareSeconds(date);
    const options: Intl.DateTimeFormatOptions = {
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Intl.DateTimeFormat("ru", options).format(+seconds * 1000)?.replace(",", "");
  } else return "";
};

export const convertToShortTime = (date: number): string => {
  if (date) {
    const seconds = prepareSeconds(date);
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
    };
    return new Intl.DateTimeFormat("ru", options).format((+seconds - OFFSET) * 1000);
  } else return "";
};

export const convertToTime = (date: number): string => {
  if (date) {
    const seconds = prepareSeconds(date);
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Intl.DateTimeFormat("ru", options).format((+seconds - OFFSET) * 1000);
  } else return "";
};

export const convertToSeconds = (date: number): string => {
  if (date) {
    const seconds = prepareSeconds(date);
    const options: Intl.DateTimeFormatOptions = {
      minute: "numeric",
      second: "numeric",
    };
    return new Intl.DateTimeFormat("ru", options).format((+seconds - OFFSET) * 1000);
  } else return "";
};

export const convertToMonth = (date: number): string => {
  if (date) {
    const seconds = prepareSeconds(date);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
    };
    return new Intl.DateTimeFormat("default", options)
      .format((+seconds - OFFSET) * 1000)
      ?.toUpperCase();
  } else return "";
};

export const translateToMonth = (index: number): string => {
  if (Number.isInteger(index) && index >= 0 && index <= 11) {
    const arr = [
      "янв",
      "фев",
      "мар",
      "апр",
      "май",
      "июн",
      "июл",
      "авг",
      "сен",
      "окт",
      "ноя",
      "дек",
    ];
    return arr[index];
  } else {
    return "";
  }
};
export const translateToDay = (index: number): string => {
  if (Number.isInteger(index) && index >= 0 && index <= 6) {
    const arr = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
    return arr[index];
  } else {
    return "";
  }
};
export const convertToMonthOffset = (date: number, location?: string): string => {
  if (date) {
    const seconds = prepareSeconds(date);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
    };
    return new Intl.DateTimeFormat(location || "default", options)
      .format(+seconds * 1000)
      ?.toUpperCase();
  } else return "";
};

export const timeToSeconds = (time: string): number => {
  // 09:00 / 09:00:00
  // const reg = new RegExp(/^([01]\d|2[0-3]):([0-5]\d)$/);
  // const timeData = reg.test(time) ? time?.split(":") : ["00", "00"];
  const timeData = time?.split(":") || ["00", "00"];
  const hours = +timeData[0] * 3600;
  const minutes = +timeData[1] * 60;
  const res = hours + minutes;
  return res >= 86400 || isNaN(res) ? 0 : res;
};

export const timeStamp = (date: string): number => {
  return Math.round(+new Date(date || 0) / 1000);
};

export const defaultDateTime = (): string => {
  const localISOString = new Date(Date.now() + OFFSET * 1000).toISOString().slice(0, -1);
  // convert to YYYY-MM-DDTHH:MM
  return localISOString.substring(0, ((localISOString.indexOf("T") | 0) + 6) | 0);
};

export const defaultDateValue = (value: Date | number): string => {
  const localISOString = new Date(+value + OFFSET * 1000).toISOString().slice(0, -1);
  // convert to YYYY-MM-DDTHH:MM
  return localISOString.substring(0, ((localISOString.indexOf("T") | 0) + 6) | 0);
};

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

export const getEndOfTheDate = (date: Date | number): number => {
  const convertedDate = new Date(date);
  convertedDate.setHours(23, 59, 59, 999); // Устанавливаем последние миллисекунды дня
  return new Date(+convertedDate / 1000).valueOf();
};

export const getStartOfTheDate = (date: Date | number): number => {
  const convertedDate = new Date(date);
  convertedDate.setHours(0, 0, 0, 0);
  return new Date(+convertedDate / 1000).valueOf();
};

export const presetRanges = [
  {
    label: "За сегодня",
    range: () => ({
      startDate: new Date(getStartOfTheDate(new Date()) * 1000),
      endDate: new Date(getEndOfTheDate(new Date()) * 1000),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 24 часа",
    range: () => ({
      startDate: new Date(getStartOfTheDate(new Date().getTime() - 24 * 60 * 60 * 1000) * 1000),
      endDate: new Date(getEndOfTheDate(new Date()) * 1000),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 2 дня",
    range: () => ({
      startDate: new Date(getStartOfTheDate(new Date().getTime() - 2 * 24 * 60 * 60 * 1000) * 1000),
      endDate: new Date(getEndOfTheDate(new Date()) * 1000),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 5 дней",
    range: () => ({
      startDate: new Date(getStartOfTheDate(new Date().getTime() - 5 * 24 * 60 * 60 * 1000) * 1000),
      endDate: new Date(getEndOfTheDate(new Date()) * 1000),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 7 дней",
    range: () => ({
      startDate: new Date(getStartOfTheDate(new Date().getTime() - 7 * 24 * 60 * 60 * 1000) * 1000),
      endDate: new Date(getEndOfTheDate(new Date()) * 1000),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 30 дней",
    range: () => ({
      startDate: new Date(
        getStartOfTheDate(new Date().getTime() - 30 * 24 * 60 * 60 * 1000) * 1000
      ),
      endDate: new Date(getEndOfTheDate(new Date()) * 1000),
    }),
    isSelected() {
      return false;
    },
  },
];

export const getPreviousMonth = () => {
  const currentDate = new Date();

  let currentMonth = currentDate.getMonth();

  // если текущий месяц - январь, получаем декабрь и уменьшаем год
  if (currentMonth === 0) {
    currentMonth = 11;
    currentDate.setFullYear(currentDate.getFullYear() - 1);
  } else {
    currentMonth -= 1;
  }

  currentDate.setMonth(currentMonth);

  return currentDate;
};
