import { useMemo } from "react";
import { colors } from "src/helpers/colors";

export const ErrorRow = ({ item, type, tab, globalCities, globalCurrencies, globalSites }) => {
  const city = useMemo(
    () => globalCities?.find((el) => el.city_id === item.city_id),
    [globalCities]
  );
  const from = useMemo(
    () => globalCurrencies?.find((el) => el.currency_id === item.currency_from_id),
    [globalCurrencies]
  );
  const to = useMemo(
    () => globalCurrencies?.find((el) => el.currency_id === item.currency_to_id),
    [globalCurrencies]
  );

  return (
    <div
      style={{ color: tab?.color }}
      className={`grid grid-cols-[20px_1fr] gap-8 px-8 text-xs rounded-sm hover:bg-[#BABAC333] cursor-pointer`}
      onClick={() => tab?.onClick(item)}>
      <div className="relative top-[3px] text-[18px] font-black leading-3">{tab?.icon}</div>
      <div className="text-left">
        {"site_id" in item && (
          <>
            Сайт{" "}
            <span
              className={`border-l-4 text-font pl-[6px] pr-8 py-2 mx-8 rounded-sm`}
              style={{
                backgroundColor: `${colors[item.site_id % 30]}77`,
                borderColor: colors[item.site_id % 30],
              }}>
              {globalSites?.find((el) => el.site_id === item.site_id)?.site_name}
            </span>
          </>
        )}
        {tab?.text}
        {` в городе `}
        <b>{city?.city_name}</b>
        {` по `}
        <b>
          {from?.currency_name}-{to?.currency_name}
        </b>
        {"time_from" in item && !!item?.time_from && (
          <>
            {` c `}
            <b>{item?.time_from}</b>
          </>
        )}
        {"time_to" in item && !!item?.time_to && (
          <>
            {` по `}
            <b>{item?.time_to}</b>
          </>
        )}
        {type === "site_empty" && !item?.time_from && !item?.time_to && " сейчас"}
      </div>
    </div>
  );
};
