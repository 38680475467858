import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router";
import { LS, Url } from "src/api/constants";
import { selectIsLogged } from "src/store/mainReducer";

const AuthRoute = ({ children }) => {
  const isAuth = useSelector(selectIsLogged);
  const user_access = localStorage.getItem(LS.ACCESS)?.split(", ");
  const location = useLocation();
  if (!isAuth && !user_access) {
    return <Navigate to={Url.LOGIN} state={{ from: location }} replace={true} />;
  }
  return children;
};
export default AuthRoute;
