import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import controlApi from "src/api/control";
import { setControlUsers, setProcessUsers } from "src/store/commonReducer";
import { UserIcon } from "src/components/UI/items/UserIcon";
import { Popup } from "src/components/Popup/Popup";

export const User = ({ item, users, isProcessing }) => {
  const dispatch = useDispatch();
  const [isUpd, setUpd] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const inputRef = useRef(null);

  const updateUser = async (username) => {
    const { response } = await fetchRequest(
      controlApi.updateControlUser({
        ...item,
        username: username,
      }),
      { request: "Обновление пользователя ТГ" }
    );
    if (response) {
      dispatch(
        setControlUsers(
          users?.map((el) =>
            el.telegram_user_id === item.telegram_user_id ? { ...el, username: username } : el
          )
        )
      );
    }
  };

  const deleteUser = async () => {
    const { response } = await fetchRequest(controlApi.deleteControlUser(item.telegram_user_id), {
      request: "Исключение пользователя ТГ",
    });
    if (response) {
      dispatch(
        setControlUsers(users?.filter((el) => el.telegram_user_id !== item.telegram_user_id))
      );
    }
  };

  const updateProcessUser = async (username) => {
    const { response } = await fetchRequest(
      controlApi.updateProcessUser({
        ...item,
        username: username,
      }),
      { request: "Обновление пользователя ТГ" }
    );
    if (response) {
      dispatch(
        setProcessUsers(
          users?.map((el) =>
            el.telegram_user_id === item.telegram_user_id ? { ...el, username: username } : el
          )
        )
      );
    }
  };

  const deleteProcessUser = async () => {
    const { response } = await fetchRequest(controlApi.deleteProcessUser(item.telegram_user_id), {
      request: "Исключение пользователя ТГ",
    });
    if (response) {
      dispatch(
        setProcessUsers(users?.filter((el) => el.telegram_user_id !== item.telegram_user_id))
      );
    }
  };

  return (
    <div
      className={`grid grid-cols-[25px_1fr_16px] sm:grid-cols-[40px_1fr_30px] gap-[20px] items-center rounded-lg duration-700 shadow-smooth p-[10px] text-left`}>
      <UserIcon username={item?.username?.replace("@", "")} />
      <div className="relative top-2">
        <div className="relative w-full h-[20px] cursor-pointer text-xs">
          <div
            className={`absolute ${!isUpd ? "z-[1]" : "-z-10 opacity-0"} cursor-pointer`}
            onClick={() => {
              setUpd(true);

              inputRef.current.focus();
            }}>
            {item?.username}
          </div>
          <input
            className={`absolute w-full ${isUpd ? "z-[1]" : "-z-10 opacity-0"} px-4 font-light`}
            ref={inputRef}
            defaultValue={item?.username}
            onBlur={() => setUpd(false)}
            onKeyDown={async (e) => {
              if (e.keyCode === 13 && !!e.currentTarget.value?.length) {
                isProcessing
                  ? await updateProcessUser(e.currentTarget.value)
                  : await updateUser(e.currentTarget.value);
                setUpd(false);
              }
            }}
          />
        </div>
        <div className="text-[9px] text-[#40AEF0]">ID: {item.telegram_user_id}</div>
      </div>
      <button
        className={`duration-300 text-lightGray hover:text-lightFont`}
        onClick={() => setPopup(true)}>{`\u2716`}</button>
      {isPopup && (
        <Popup closeModal={() => setPopup(false)}>
          <div className="text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nпользователя `}
              <b>{item?.username}</b>?
            </div>
            <button
              className="prime-button-sm"
              onClick={isProcessing ? deleteProcessUser : deleteUser}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
