import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactSelect, { createFilter } from "react-select";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import textApi from "src/api/text";
import { setNotific } from "src/store/mainReducer";
import {
  selectAllSites,
  selectAllCities,
  setGlobalCurrencies,
  setGlobalDirections,
  selectSite,
  setSelectedSite,
  TSelect,
  TStrSelect,
} from "src/store/directionsReducer";
import {
  TDirectionSelect,
  TTemplate,
  TAlias,
  TVectorType,
  TTemplatesGetPayload,
  TTextUpdateResponse,
  TTextDirectionGet,
  TTextType,
  TCitySelect,
  TTextGetPayload,
  TText,
  TTextCities,
  TTextVectors,
  TextType,
  TTextSubTypeList,
  TTextSubType,
  TextSubType,
  TTextNotDirections,
} from "./types";
import { Popup } from "src/components/Popup/Popup";
import { Templates } from "./components/Templates";
import { TextBlock } from "./components/TextBlock";
import { Alias } from "./components/Alias";
import { SideBlock } from "./components/SideBlock/SideBlock";
import switchApi from "src/api/onOff";
import { TextTypes } from "./components/TextTypes";

export const Text = () => {
  const dispatch = useDispatch();
  const username = localStorage.getItem("username");
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSite = useSelector(selectSite);
  const globalCities = useSelector(selectAllCities);
  const [directionsData, setDirectionsData] = useState<TTextDirectionGet | null>(null);
  const [existSubtypeTexts, setExistSubtypeTexts] = useState<TTextNotDirections | null>(null);
  const [isPopup, setPopup] = useState(false);
  const [isChangeTypeConfirmation, setChangeTypeConfirmation] = useState(false);
  const [caret, setCaret] = useState<number | null>(null);
  const [isOpenTemplates, setIsOpenTemplates] = useState(false);
  const [isGoodTemp, setIsGoodTemp] = useState<boolean | null>(null);
  const [types, setTypes] = useState<Array<TTextType>>([]);
  const [subTypes, setSubTypes] = useState<Array<TTextSubTypeList>>([]);
  const [type, setType] = useState<TTextType>({
    id: 1,
    name_ru: "до",
    name_en: TextType.BEFORE,
  });
  const [subType, setSubType] = useState<TTextSubType | null>(null);
  const [lastResTypeStr, setLastTypeStr] = useState("");
  const [isCreate, setIsCreate] = useState(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Array<TTemplate>>([]);
  const [template, setTemplate] = useState<TTemplate | null>(null);
  const [alias, setAlias] = useState<Array<TAlias>>([]);
  const [site, setSite] = useState<TSelect | null>(null);
  const [currentCity, setCurrentCity] = useState<TCitySelect | null>(null); //!isCreate
  const [vector, setVector] = useState<TDirectionSelect | null>(null); //!isCreate
  const [city, setCity] = useState<TCitySelect | null>(null); //isCreate
  const [selectedVectors, setSelectedVectors] = useState<Array<TDirectionSelect>>([]); //isCreate
  const [lang, setLang] = useState<TStrSelect | null>(null);
  const [text, setText] = useState("");
  const [resText, setResText] = useState<TText | null>(null);
  const globalSites = useSelector(selectAllSites);

  const isNotNeededVectors =
    !!subType &&
    !(
      subType?.text_subtype_name_en === TextSubType.EXCHANGE_REQUEST &&
      type.name_en === TextType.MAIL
    );

  const disabledByVectors =
    (isTemplate && !selectedVectors?.length && !city) ||
    (!isTemplate && (!isCreate ? !vector || !currentCity : !selectedVectors?.length || !city));
  const disabledText = (!isNotNeededVectors && disabledByVectors) || !site;
  const activeText =
    !disabledText && (isCreate || (!!resText && resText?.text !== text)) && !!text.length;
  const disabledTemplate = !site || !text?.length || text === template?.text;

  const [currencyType, setCurrencyType] = useState<TVectorType>({
    currency_from_type: "cash",
    currency_to_type: "crypto",
  });

  const sitesOptions = useMemo(
    () => globalSites?.map((el) => ({ label: el.site_name, value: el.site_id })),
    [globalSites]
  );

  const title = useMemo(() => {
    if (template !== null && isTemplate) {
      if (template?.id === 0) {
        return "🗒 Создание нового шаблона";
      } else {
        return "🗒 Редактирование шаблона";
      }
    } else if (!isCreate) {
      if (!!site?.label && (isNotNeededVectors || (!!currentCity?.title && !!vector?.title))) {
        return `✅ Просмотр текста ${site?.label} ${isNotNeededVectors ? "" : currentCity?.title} ${
          isNotNeededVectors ? "" : vector?.title
        } ${"по типу " + lastResTypeStr}`;
      } else {
        return "❓ Выберите вектор";
      }
    } else {
      return "⚡️ Создание текста";
    }
  }, [
    template,
    isTemplate,
    isCreate,
    isNotNeededVectors,
    site?.label,
    currentCity?.title,
    vector?.title,
    lastResTypeStr,
  ]);

  const clearTemplate = () => {
    setIsTemplate(false);
    setTemplate(null);
  };

  const fetchGlobalDirections = async () => {
    const { response } = await fetchRequest(switchApi.getGlobalDirections(), {
      request: "Глобальные данные",
    });
    if (response) {
      dispatch(setGlobalCurrencies(response.currencies));
      dispatch(setGlobalDirections(response.vectors));
    }
  };

  const fetchTemplate = async () => {
    setIsGoodTemp(null);
    const { response, error, status } = await fetchRequest(
      textApi.getTemplates({
        site_id: site?.value,
        type_id: type.id,
        currency_from_type: currencyType.currency_from_type,
        currency_to_type: currencyType.currency_to_type,
      } as TTemplatesGetPayload),
      { request: "Шаблоны" }
    );
    if (response) {
      setTemplates(response);
      setIsGoodTemp(true);
    }
    if (error && status !== 404) {
      setTemplates([]);
      setIsGoodTemp(false);
    }
    if (status === 404) {
      setIsGoodTemp(true);
      setTemplates([]);
    }
  };

  const fetchAlias = async () => {
    const { response, error } = await fetchRequest(textApi.getAlias(), {
      request: "Переменные",
    });
    if (response) {
      setAlias(response);
    }
    if (error) {
      setAlias([]);
    }
  };

  const fetchTypes = async () => {
    const { response, error } = await fetchRequest(textApi.getTypes(), {
      request: "Типы текстов",
    });
    if (response) {
      setTypes(response);
    }
    if (error) {
      setTypes([]);
    }
  };

  const fetchSubTypes = async () => {
    const { response, error } = await fetchRequest(textApi.getSubTypes());
    if (response) {
      setSubTypes(response);
    }
    if (error) {
      dispatch(
        setNotific({ type: "error", message: error?.statusText, request: "Подтипы текстов" })
      );
      setSubTypes([]);
    }
  };

  const fetchText = async (payload) => {
    setIsCreate(false);
    const { response, error, status } = await fetchRequest(textApi.getText(payload), {
      request: `Текст для ${site?.label}`,
    });
    if (response) {
      setText(response?.text);
      setResText(response);
    }
    if (error && status !== 404) {
      setText("");
      setResText(null);
    }
    if (status === 404) {
      setText("");
      setResText(null);
    }

    const currentType = types?.find((el) => el.id === payload?.type_id);
    const currentSubType = subTypes
      ?.find((el) => el.name === currentType?.name_en)
      ?.data?.find((dataEl) => dataEl?.id === payload?.text_subtype_id);
    setLastTypeStr(
      `${type?.name_ru} ${!!currentSubType ? "/ " + currentSubType?.text_subtype_name_ru : ""}`
    );
    clearTemplate();
  };

  const updateVectors = (response: TTextUpdateResponse[]) => {
    const newType = {
      type_id: type.id,
      text_subtype: subType?.text_subtype_name_en || null,
    };
    const newCities =
      response?.filter(
        (el) => !directionsData?.cities?.find((cityEl) => cityEl.city_id === el.city_id)
      ) || null;
    const newCitiesUniqItems = !!newCities
      ? [...new Set(newCities?.map((cityDataEl) => cityDataEl.city_id))]
      : [];

    const oldCities = directionsData?.cities?.map((el) => {
      const findedCityDirections =
        response?.filter((vectorEl) => vectorEl.city_id === el.city_id) || [];

      if (!!findedCityDirections?.length) {
        const newVectors =
          findedCityDirections?.filter(
            (findedEl) =>
              !el.vectors?.find(
                (vectorDataEl) =>
                  `${vectorDataEl.currency_from_id}_${vectorDataEl.currency_to_id}` ===
                  `${findedEl.currency_from}_${findedEl.currency_to}`
              )
          ) || null;
        const newVectorsUniqItems = !!newVectors
          ? [
              ...new Set(
                newVectors?.map(
                  (vectorDataEl) => `${vectorDataEl.currency_from}-to-${vectorDataEl.currency_to}`
                )
              ),
            ]
          : [];

        return {
          ...el,
          vectors: [
            ...el.vectors.map((vectorEl) => {
              const findedVectorArr =
                findedCityDirections?.filter(
                  (findedEl) =>
                    `${vectorEl.currency_from_id}_${vectorEl.currency_to_id}` ===
                    `${findedEl.currency_from}_${findedEl.currency_to}`
                ) || [];

              if (!!findedVectorArr?.length) {
                const isNewLang = !vectorEl?.languages?.find(
                  (langEl) => langEl.lang === lang.label
                );

                if (isNewLang) {
                  return {
                    ...vectorEl,
                    languages: [
                      {
                        lang: lang.label,
                        types: [newType],
                      },
                    ],
                  };
                } else {
                  return {
                    ...vectorEl,
                    languages: vectorEl?.languages?.map((langEl) =>
                      langEl.lang === lang.label
                        ? {
                            ...langEl,
                            types: [...new Set([...langEl.types, newType])],
                          }
                        : langEl
                    ),
                  };
                }
              } else {
                return vectorEl;
              }
            }),
            ...newVectorsUniqItems.map((uniq: string) => {
              const vectorData = selectedVectors?.find(
                (selectedVector) =>
                  `${selectedVector.value.currency_from_id}-to-${selectedVector.value.currency_to_id}` ===
                  uniq
              )?.value;

              return {
                currency_from_id: vectorData?.currency_from_id,
                currency_from_name: vectorData?.currency_from_name,
                currency_from_type: vectorData?.currency_from_type,
                currency_to_id: vectorData?.currency_to_id,
                currency_to_name: vectorData?.currency_to_name,
                currency_to_type: vectorData?.currency_to_type,
                is_on_site: false,
                languages: [
                  {
                    lang: lang.label,
                    types: [newType],
                  },
                ],
              };
            }),
          ],
        };
      } else {
        return el;
      }
    });

    if (!!newCitiesUniqItems?.length) {
      const newCitiesArr = newCitiesUniqItems?.map((el: number) => {
        const newCity = globalCities?.find((globalCity) => globalCity.city_id === el);
        const newCityVectors = newCities?.filter((newCityEl) => newCityEl.city_id === el);
        return {
          city_id: el,
          city_code: newCity?.city_code,
          city_name: newCity?.city_name,
          vectors: newCityVectors?.map((vectorEl: TTextUpdateResponse) => {
            const vectorData = selectedVectors?.find(
              (selectedVector) =>
                `${selectedVector.value.currency_from_id}-to-${selectedVector.value.currency_to_id}` ===
                `${vectorEl.currency_from}-to-${vectorEl.currency_to}`
            )?.value;
            return {
              currency_from_id: vectorEl.currency_from,
              currency_from_name: vectorData?.currency_from_name,
              currency_from_type: vectorData?.currency_from_type,
              currency_to_id: vectorEl.currency_to,
              currency_to_name: vectorData?.currency_to_name,
              currency_to_type: vectorData?.currency_to_type,
              is_on_site: false,
              languages: [
                {
                  lang: lang.label,
                  types: [newType],
                },
              ],
            };
          }),
        };
      });
      setDirectionsData({
        ...directionsData,
        cities: [...oldCities, ...newCitiesArr],
      });
    } else {
      setDirectionsData({
        ...directionsData,
        cities: oldCities,
      });
    }
  };

  const updateNotVectors = (response: TTextNotDirections) => {
    setExistSubtypeTexts(response);
  };

  const submitText = async () => {
    const isExistSubType = !!existSubtypeTexts?.languages
      ?.find((el) => el.lang === lang?.label)
      ?.types?.find(
        (typeEl) =>
          typeEl.type_id === type.id && typeEl?.text_subtype === subType?.text_subtype_name_en
      );

    const updatePayload = {
      created_by_user: username,
      site_id: site?.value,
      type_id: type?.id,
      text_subtype_id: subType?.id || null,
      lang: lang?.label || "RU",
      text: text,
      directions: [],
    };
    const createPayload = { ...updatePayload };
    if (isCreate && !isNotNeededVectors) {
      const newVectors = [] as TDirectionSelect[];
      const oldVectors = [] as TDirectionSelect[];
      selectedVectors.forEach((el: TDirectionSelect) => {
        const cityItem = directionsData.cities?.find(
          (cityEl: TTextCities) => cityEl.city_id === el.value.city_id
        );
        if (!!cityItem?.vectors.length) {
          const vectorItem = cityItem.vectors.find(
            (vectorEl: TTextVectors) =>
              `${vectorEl.currency_from_id}_${vectorEl.currency_to_id}` ===
              `${el.value.currency_from_id}_${el.value.currency_to_id}`
          );
          if (!!vectorItem?.languages?.length) {
            const langItem = vectorItem.languages.find((langEL) => langEL.lang === el.value.lang);
            if (
              !!langItem?.types?.length &&
              langItem.types?.map((typeEl) => typeEl.type_id)?.includes(type.id)
            ) {
              oldVectors.push(el);
            } else {
              newVectors.push(el);
            }
          } else {
            newVectors.push(el);
          }
        } else {
          newVectors.push(el);
        }
      });
      if (!!newVectors.length) {
        createPayload.directions = newVectors?.map((el) => ({
          currency_from: el.value.currency_from_id,
          currency_to: el.value.currency_to_id,
          city_id: el.value.city_id,
        }));
      }
      if (!!oldVectors.length) {
        updatePayload.directions = oldVectors?.map((el) => ({
          currency_from: el.value.currency_from_id,
          currency_to: el.value.currency_to_id,
          city_id: el.value.city_id,
        }));
      }
    } else if (!isNotNeededVectors) {
      updatePayload.directions = [
        {
          currency_from: vector?.value.currency_from_id,
          currency_to: vector?.value.currency_to_id,
          city_id: currentCity?.value,
        },
      ];
    }
    setPopup(false);

    const {
      response: updateResponse,
      error: updateError,
    }: {
      response: TTextUpdateResponse[] | null;
      error: { statusText: string } | null;
    } =
      (isNotNeededVectors ? isExistSubType : !!updatePayload.directions.length) &&
      (await fetchRequest(textApi.updateText(updatePayload)));

    const {
      response: createResponse,
      error: createError,
    }: {
      response: TTextUpdateResponse[] | null;
      error: { statusText: string } | null;
    } =
      (isNotNeededVectors ? !isExistSubType : !!createPayload.directions.length) &&
      (await fetchRequest(textApi.addText(createPayload)));

    if (updateResponse || createResponse) {
      dispatch(
        setNotific({
          type: "success",
          message: "Текст успешно сохранен",
          request: `${isCreate ? "Создание" : "Сохранение"} текста`,
        })
      );
      const response = !!createResponse ? createResponse : updateResponse;
      setResText({
        id: response[0]?.id,
        text: response[0]?.text,
        created_at: response[0]?.created_at,
        created_by_user: response[0]?.created_by_user,
      });
      if (isCreate && !isNotNeededVectors) {
        setChangeTypeConfirmation(true);
      }
      if (!!createResponse) {
        if (!isNotNeededVectors) {
          updateVectors(createResponse);
        } else {
          const updatedNotVectors: TTextNotDirections = {
            site_id: site.value,
            languages: !!existSubtypeTexts?.languages?.length
              ? existSubtypeTexts?.languages?.map((el) =>
                  el.lang === createPayload.lang
                    ? {
                        ...el,
                        types: el.types?.length
                          ? [
                              ...el.types,
                              {
                                type_id: createPayload.type_id,
                                text_subtype: subType.text_subtype_name_en,
                              },
                            ]
                          : [
                              {
                                type_id: createPayload.type_id,
                                text_subtype: subType.text_subtype_name_en,
                              },
                            ],
                      }
                    : el
                )
              : [
                  {
                    lang: createPayload.lang,
                    types: [
                      {
                        type_id: type.id,
                        text_subtype: subType.text_subtype_name_en,
                      },
                    ],
                  },
                ],
          };
          updateNotVectors(updatedNotVectors);
        }
      }
    }
    if (createError) {
      dispatch(
        setNotific({
          type: "error",
          message: createError?.statusText,
          request: `Не удалось создать текст`,
        })
      );
    }
    if (updateError) {
      dispatch(
        setNotific({
          type: "error",
          message: updateError?.statusText,
          request: `Не удалось обновить текст`,
        })
      );
    }
  };

  const submitTemplate = async () => {
    if (!!template?.text?.length) {
      const payload = {
        id: template?.id,
        text: text,
        created_by_user: username,
      };
      const { response, error, status } = await fetchRequest(textApi.updateTemplates(payload));
      if (response) {
        dispatch(
          setNotific({
            type: "success",
            message: "Шаблон успешно сохранен",
            request: "Обновление шаблона",
          })
        );
        await fetchTemplate();
      }
      if (error && status !== 404) {
        dispatch(
          setNotific({ type: "error", message: error?.statusText, request: "Обновление шаблона" })
        );
      }
    }
    if (!template?.text?.length) {
      const payload = {
        text: text,
        created_by_user: username,
        site_id: site?.value,
        type_id: type.id,
        currency_from_type: currencyType.currency_from_type,
        currency_to_type: currencyType.currency_to_type,
      };
      const { response, error, status } = await fetchRequest(textApi.addTemplates(payload));
      if (response) {
        dispatch(
          setNotific({
            type: "success",
            message: "Шаблон успешно создан",
            request: "Создание шаблона",
          })
        );
        await fetchTemplate();
      }
      if (error && status !== 404) {
        dispatch(
          setNotific({ type: "error", message: error?.statusText, request: "Создание шаблона" })
        );
      }
    }
  };

  useEffect(() => {
    fetchGlobalDirections();
    fetchAlias();
    fetchTypes();
    fetchSubTypes();
  }, []);

  useEffect(() => {
    if (!!searchParams?.get("type") && !!types?.length) {
      setType(types?.find((el) => el.id === +searchParams?.get("type")));
    }
  }, [types]);

  useEffect(() => {
    if (!!searchParams?.get("subtype") && !!subTypes?.length && !!type) {
      setSubType(
        subTypes
          ?.find((el) => el.name === type?.name_en)
          ?.data?.find((dataEl) => dataEl?.id === +searchParams?.get("subtype"))
      );
    }
  }, [subTypes, type]);

  useEffect(() => {
    if (!!searchParams?.get("site") && !!sitesOptions?.length) {
      setSite(sitesOptions?.find((el) => el.value === +searchParams.get("site")));
    }
    if (!!selectedSite && !searchParams?.get("site") && !!sitesOptions?.length) {
      setSite({ label: selectedSite.site_name, value: selectedSite.site_id });
    }
  }, [sitesOptions]);

  useEffect(() => {
    if (!!site?.label && isOpenTemplates) {
      fetchTemplate();
    }
  }, [site?.label, type?.id, currencyType, isOpenTemplates]);

  useEffect(() => {
    if (!!currentCity?.label && !!site?.value && !!vector?.value && !isNotNeededVectors) {
      const payload = {
        site_id: site?.value,
        type_id: type?.id,
        text_subtype_id: subType?.id || null,
        city_id: currentCity?.value,
        currency_from_id: vector?.value.currency_from_id,
        currency_to_id: vector?.value.currency_to_id,
        lang: lang?.label || "RU",
      } as TTextGetPayload;
      fetchText(payload);
    }
  }, [site?.value, type?.id, currentCity?.label, vector?.value]);

  useEffect(() => {
    if (isNotNeededVectors) {
      setIsTemplate(false);
    }
    if (!!site?.value && isNotNeededVectors && !!subType) {
      const payload = {
        site_id: site?.value,
        type_id: type?.id,
        text_subtype_id: subType?.id,
        lang: lang?.label || "RU",
      } as TTextGetPayload;
      fetchText(payload);
    }
  }, [site?.value, type?.id, subType]);

  return (
    <div
      className={`grid md:grid-cols-[2fr_1fr] gap-[50px] md:gap-[16px] pl-[20px] pr-[50px] sm:pr-[80px] text-xs items-start font-light pb-[100px]`}>
      <div className={`flex flex-col gap-8`}>
        <TextTypes
          type={type}
          types={types}
          setTypes={setTypes}
          subTypes={subTypes}
          subType={subType}
          setFn={(currentType, currentSubType) => {
            setType(currentType);
            setSubType(currentSubType);
            searchParams.set("type", currentType?.id?.toString());
            if (!!currentSubType) {
              searchParams.set("subtype", currentSubType?.id?.toString());
            } else {
              searchParams.delete("subtype");
            }
            setSearchParams(searchParams);
          }}
        />
        <div className="flex items-center gap-4">
          {!isNotNeededVectors && (
            <div className="py-2 px-4 rounded-sm border border-[#BABAC322] bg-[#F1F1FA]">
              <button
                type="button"
                className={`bg-font rounded-sm w-fit h-[22px] text-[10px] px-4 text-xs text-white font-semibold hover:opacity-80 duration-300`}
                onClick={() => setIsOpenTemplates(!isOpenTemplates)}>
                ШАБЛОНЫ
              </button>
            </div>
          )}
          <ReactSelect
            classNamePrefix="SquareSelect"
            options={sitesOptions}
            className="w-full"
            filterOption={createFilter(selectFilter)}
            value={site}
            placeholder="Сайт"
            onChange={(e: TSelect) => {
              setSite(e);
              searchParams.set("site", e?.value?.toString());
              dispatch(setSelectedSite({ site_id: e?.value, site_name: e?.label }));
              setSearchParams(searchParams);
            }}
          />
        </div>
        <div
          className={`grid h-full rounded-sm duration-300 p-4 -m-4 ${
            isOpenTemplates && !isNotNeededVectors
              ? "md:grid-cols-[160px_1fr] gap-8"
              : "md:grid-cols-[0px_1fr] gap-0"
          } ${isTemplate ? "bg-[#40AEF033]" : "bg-bg"}`}>
          {!isNotNeededVectors ? (
            <Templates
              data={templates}
              template={template}
              setTemplate={setTemplate}
              isTemplate={isTemplate}
              setIsTemplate={setIsTemplate}
              setText={setText}
              isGood={!!site?.value}
              isGoodTemp={isGoodTemp}
              isOpen={isOpenTemplates}
              fetchTemplate={fetchTemplate}
              resText={resText}
              currencyType={currencyType}
            />
          ) : (
            <div />
          )}
          <div className={`w-full h-full flex flex-col gap-4`}>
            <Alias alias={alias} text={text} setText={setText} caret={caret} />
            {!!title?.length && (
              <div className="min-h-[24px] pb-8 text-left text-[14px] font-semibold text-lightFont">
                {title?.toUpperCase()}
              </div>
            )}
            <TextBlock
              text={text}
              setText={setText}
              disabled={disabledText}
              disabledTemplate={disabledTemplate}
              activeText={activeText}
              isTemplate={isTemplate}
              submitTemplate={submitTemplate}
              template={template}
              setCaret={setCaret}
              site={site}
              setPopup={setPopup}
              resText={resText}
              isNotNeededVectors={isNotNeededVectors}
            />
          </div>
        </div>
      </div>
      <SideBlock
        directionsData={directionsData}
        setDirectionsData={setDirectionsData}
        existSubtypeTexts={existSubtypeTexts}
        setExistSubtypeTexts={setExistSubtypeTexts}
        globalCities={globalCities}
        site={site}
        city={city}
        setCity={setCity}
        setCurrentCity={setCurrentCity}
        setVector={setVector}
        selectedVectors={selectedVectors}
        setSelectedVectors={setSelectedVectors}
        currencyType={currencyType}
        setCurrencyType={setCurrencyType}
        types={types}
        subTypes={subTypes}
        lang={lang}
        setLang={setLang}
        isCreate={isCreate}
        setIsCreate={setIsCreate}
        isTemplate={isTemplate}
        setType={setType}
        setSubType={setSubType}
        isNotNeededVectors={isNotNeededVectors}
        clearTemplate={() => {
          clearTemplate();
          setText(resText?.text || "");
        }}
      />
      {isPopup && (
        <Popup closeModal={() => setPopup(false)}>
          <div className="flex flex-col gap-[30px] max-w-[280px] font-light text-sm items-center">
            <div>
              Подтвердите обновление текста для сайта{" "}
              <span className="font-semibold">{site?.label}</span>
              {" по типу "}
              <span className="font-semibold">{`${type?.name_ru} ${
                !!subType ? "/ " + subType?.text_subtype_name_ru : ""
              }`}</span>
              :
            </div>
            {!isNotNeededVectors &&
              (!isCreate ? (
                <div className="w-full flex flex-col gap-8">
                  <div>
                    Город: <b>{currentCity?.title}</b>
                  </div>
                  <div>
                    Вектор: <b>{vector?.title}</b>
                  </div>
                </div>
              ) : (
                <div className="w-full flex flex-col gap-8 max-h-[310px] overflow-y-scroll">
                  <div>Векторы:</div>
                  <div>
                    {selectedVectors?.map((el, index) => (
                      <div key={index}>
                        <b>{el?.value?.title}</b>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            <button
              className="w-fit px-[20px] py-4 font-semibold text-lightFont rounded-sm duration-300 bg-[#EAEAF3] hover:opacity-80"
              onClick={submitText}>
              Обновить текст
            </button>
          </div>
        </Popup>
      )}
      {isChangeTypeConfirmation && (
        <Popup
          closeModal={() => {
            setSelectedVectors([]);
            setChangeTypeConfirmation(false);
          }}>
          <div className="flex flex-col gap-[12px] max-w-[280px] font-light text-sm items-center">
            <div>Текст по выбранным векторам создался.</div>
            <div>Хотите ли вы сохранить выбранные векторы и применить к новому типу текстов?</div>
            <small className="text-light-font">Иначе выбранные векторы сбросятся</small>
            <TextTypes
              type={type}
              types={types?.filter((el) => el.id !== type.id)}
              setTypes={setTypes}
              subTypes={subTypes}
              subType={subType}
              setFn={(currentType, currentSubType) => {
                setType(currentType);
                setSubType(currentSubType);
                searchParams.set("type", currentType?.id?.toString());
                if (!!currentSubType) {
                  searchParams.set("subtype", currentSubType?.id?.toString());
                } else {
                  searchParams.delete("subtype");
                }
                setSearchParams(searchParams);
                setChangeTypeConfirmation(false);
              }}
            />
          </div>
        </Popup>
      )}
    </div>
  );
};
