import { Dispatch, SetStateAction, useState, ReactNode } from "react";
import { TLinks } from "src/store/directionsReducer";

export type TDropType = {
  title: string;
  value: TLinks;
  color: string;
};

type TProps = {
  currentTypes: Array<TLinks>;
  setCurrentTypes: Dispatch<SetStateAction<Array<TLinks>>>;
  isAccess: boolean;
  types: Array<TDropType>;
  isClick?: boolean;
  children: ReactNode;
  isOnlyValue?: boolean;
};

export const DropEditor = ({
  currentTypes,
  setCurrentTypes,
  isAccess,
  types,
  isClick,
  children,
  isOnlyValue,
}: TProps) => {
  const [isEditType, setEditType] = useState(false);

  return (
    <div
      className={`relative ${isAccess && "cursor-pointer"}`}
      onClick={() => {
        if (isClick) {
          setEditType(true);
        }
      }}
      onMouseMove={() => {
        if (isAccess && !isClick) {
          setEditType(true);
        }
      }}>
      {children}
      {isEditType && (
        <div
          className="absolute top-0 p-4 pr-8 -m-4 bg-bg z-10 rounded-sm bg-bg shadow-base flex flex-col gap-4"
          onMouseLeave={() => setEditType(false)}>
          {isAccess &&
            types?.map((item, index) => (
              <div
                key={index}
                className="flex gap-8 items-center bg-bg z-20 cursor-pointer"
                onClick={() => {
                  if (currentTypes?.includes(item.value)) {
                    isOnlyValue
                      ? setCurrentTypes([])
                      : setCurrentTypes(currentTypes?.filter((el) => el !== item.value));
                  } else {
                    isOnlyValue
                      ? setCurrentTypes([item.value])
                      : setCurrentTypes([...currentTypes, item.value]);
                  }
                }}>
                <div
                  style={{ background: item.color }}
                  className={`h-[20px] w-[20px] rounded-sm justify-center items-center flex text-white font-bold ${
                    currentTypes?.includes(item.value) ? "" : "grayscale opacity-30"
                  }`}>
                  {item.title?.substring(0, 1)?.toUpperCase()}
                </div>
                <div>{item.title}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
