import { Url } from "src/api/constants";
import { IconsNav } from "./IconsNav";

export const Burger = ({ user_access, page, pinned, isBurger, setIsBurger }) => {
  return (
    <div
      className={`burger fixed font-normal duration-700 ${
        isBurger ? "w-[200px] max-w-[200px] pl-[20px] pr-[10px]" : "w-[1px] max-w-[1px] px-0"
      } h-full left-0 top-[48px] py-[20px] text-left z-[100] ${
        page !== Url.ANALYTICS ? "text-font bg-[#EAEBEF99]" : "text-white bg-[#2A2B2F11]"
      }`}
      onMouseLeave={() => setIsBurger(false)}>
      <div
        className={`flex flex-col gap-8 duration-500 overflow-hidden divide-y divide-stale ${
          isBurger ? "opacity-100" : "opacity-0"
        }`}>
        <IconsNav page={page} pinned={pinned} userAccess={user_access} isBurger={isBurger} />
      </div>
    </div>
  );
};
