export const ItemRow = ({
  title,
  title2,
  access,
  onDeleteClick,
}: {
  title: string;
  title2?: string;
  onDeleteClick?: () => void;
  access?: boolean;
}) => {
  return (
    <div
      className={`grid ${
        !!title2
          ? "grid-cols-[210px_1fr_16px] sm:grid-cols-[210px_1fr_20px]"
          : "grid-cols-[1fr_16px] sm:grid-cols-[1fr_20px]"
      } pl-[20px]  gap-[20px] items-center rounded-lg duration-700 shadow-smooth p-[10px] text-left text-xs`}>
      <div>{title}</div>
      {title2 && <div>{title2}</div>}
      {onDeleteClick && (
        <div>
          {access && (
            <button
              className={`duration-300 text-lightGray hover:text-lightFont`}
              onClick={onDeleteClick}>{`\u2716`}</button>
          )}
        </div>
      )}
    </div>
  );
};
