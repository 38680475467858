import { FormProvider, useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import addApi from "src/api/add";
import { selectAllCountries, TStrSelect } from "src/store/directionsReducer";
import ReactSelect, { createFilter } from "react-select";
import { selectFilter } from "src/helpers/formHelpers";
import { TAddTab } from "src/pages/Add/Add";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "src/shadcn/ui/ui/tooltip";

type TProps = {
  setActiveTab: React.Dispatch<React.SetStateAction<TAddTab>>;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  fetchGlobals: () => void;
};

export const CreateCity = ({
  setActiveTab,
  setMessage,
  setStatus,
  setIsLoading,
  fetchGlobals,
}: TProps) => {
  const methods = useForm({ mode: "onChange" });
  const countries = useSelector(selectAllCountries);
  const countriesOptions = countries?.map((el) => ({ label: el?.name_ru, value: el?.name_ru }));
  const [isRegion, setIsRegion] = useState(true);
  const [country, setCountry] = useState<TStrSelect>();
  const [loading, setLoading] = useState(false);

  const createCity = async () => {
    setIsLoading(true);
    const data = {
      city_id: +methods.watch("city_id"),
      city_name: methods.watch("city_name"),
      city_name_en: methods.watch("city_name_en"),
      city_code: methods.watch("city_code"),
      is_region: isRegion,
      city_country: country?.label,
      create_vectors: methods.watch("create_vectors"),
    };
    setLoading(true);
    const { response, error } = await fetchRequest(addApi.addCity(data), {
      request: "Добавление городов",
    });
    setLoading(false);
    if (response) {
      setMessage(`Город ${methods.watch("city_name")} успешно добавлен`);
      setStatus(true);
      await fetchGlobals();
    }
    if (error) {
      setMessage("Не удалось добавить город");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      setActiveTab(null);
    }, 4600);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(createCity)}>
        <div className={`max-h-[85vh] text-center pb-[20px] overflow-y-auto`}>
          <div className="table-header justify-center">НОВЫЙ ГОРОД</div>
          <div className="p-[20px] text-xs text-left flex flex-col md:flex-row gap-[10px] w-[300px]">
            <div className="w-full">
              <div>Город:</div>
              <input
                placeholder="Москва"
                required
                className="w-full border border-lightGray rounded outline-none h-[30px] py-4 px-8 my-[4px]"
                {...methods.register("city_name")}
              />
              <div>Город на английском:</div>
              <input
                placeholder="Moscow"
                required
                className="w-full border border-lightGray rounded outline-none h-[30px] py-4 px-8 my-[4px]"
                {...methods.register("city_name_en")}
              />
              <div>ID города на BESTCHANGE:</div>
              <input
                type="number"
                placeholder="123"
                required
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="w-full border border-lightGray rounded outline-none h-[30px] py-4 px-8 my-[4px]"
                {...methods.register("city_id")}
              />
              <div>Сокращение:</div>
              <input
                placeholder="msk"
                required
                className="w-full border border-lightGray rounded outline-none h-[30px] py-4 px-8 my-[4px]"
                {...methods.register("city_code")}
              />
              <div>Страна:</div>
              <ReactSelect
                className="text-xs mb-[4px]"
                classNamePrefix="FormSelect"
                options={countriesOptions}
                filterOption={createFilter(selectFilter)}
                classNames={{
                  menuList: () => "h-[170px]",
                }}
                placeholder={"Страна..."}
                onChange={(e: TStrSelect) => setCountry(e)}
              />
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <label className="flex items-center justify-start my-[12px] gap-[10px] cursor-pointer">
                      <input
                        type="checkbox"
                        className="border border-lightGray rounded outline-none h-[16px] w-[16px]"
                        {...methods.register("create_vectors")}
                      />
                      <div className="text-[14px] leading-[1]">Автоматически создать вектора</div>
                    </label>
                  </TooltipTrigger>
                  <TooltipContent className="text-center max-w-[200px]">
                    Если опция выбрана, будут автоматически созданы все возможные векторы
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <div className="flex gap-8 my-[10px] items-center">
                <button
                  className={`prime-button-sm border text-xs ${
                    !isRegion ? "bg-font text-white" : "bg-white text-font border-lightGray"
                  } hover:text-white`}
                  type="button"
                  onClick={() => setIsRegion(false)}>
                  Основной
                </button>
                <button
                  className={`prime-button-sm border text-xs ${
                    isRegion ? "bg-font text-white" : "bg-white text-font border-lightGray"
                  } hover:text-white`}
                  type="button"
                  onClick={() => setIsRegion(true)}>
                  Регион
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-baseline">
            <button type="submit" disabled={loading} className={`prime-button bg-font`}>
              {loading ? "Загрузка..." : "Добавить город"}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
