import { Dispatch, SetStateAction } from "react";
import { TPerms } from "../types";

export const Perm = ({
  role,
  perms,
  setPerms,
}: {
  role: string;
  perms: TPerms[];
  setPerms: Dispatch<SetStateAction<TPerms[]>>;
}) => {
  const currentPerm = perms?.find((el) => el.user_role === role);
  const isAdminRights = !!currentPerm ? currentPerm.admin_rights : null;

  const setPerm = (value: boolean | null) => {
    if (!!currentPerm) {
      if (value === null) {
        setPerms(perms.filter((el) => el.user_role !== role));
      } else {
        setPerms(
          perms.map((el) =>
            el.user_role === role
              ? {
                  user_role: role,
                  admin_rights: value,
                }
              : el
          )
        );
      }
    } else {
      setPerms([
        ...perms,
        {
          user_role: role,
          admin_rights: value,
        },
      ]);
    }
  };

  return (
    <div
      className={`flex flex-col md:grid grid-cols-2 md:items-center gap-y-8 gap-x-5 rounded-md shadow-md px-3 py-8 border-2 duration-300 ${
        !!currentPerm ? "border-emerald-400 bg-emerald-50" : "border-white"
      }`}>
      <div className="text-sm font-semibold text-slate-600">{role}</div>
      <div className="flex flex-col gap-8 text-xs">
        <label className="flex gap-3">
          <input
            type="radio"
            name={role}
            value="false"
            checked={isAdminRights === false}
            onChange={() => setPerm(false)}
          />
          Участие
        </label>
        <label className="flex gap-3">
          <input
            type="radio"
            name={role}
            id="true"
            checked={isAdminRights === true}
            onChange={() => setPerm(true)}
          />
          Редактирование
        </label>
        <label className="flex gap-3 text-slate-400">
          <input
            type="radio"
            name={role}
            id="null"
            checked={isAdminRights === null}
            onChange={() => setPerm(null)}
          />
          Не доступен
        </label>
      </div>
    </div>
  );
};
