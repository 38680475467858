import { TT } from "../components/tetrisConstants";

export const Confirmation = ({ sites, cities, vectors, percent, submit, setPopup, path }) => {
  return (
    <>
      {!!cities?.length && !!sites?.length && !!vectors?.length ? (
        <div className="px-[30px] py-[20px] font-light text-sm min-w-[300px] max-w-[350px]">
          <div>Направления</div>
          <div className="flex flex-wrap mb-[15px] pt-[10px] pb-[15px] border-b border-[#AAA] max-h-[110px] overflow-y-scroll">
            {vectors?.map((el, index) => (
              <div key={index} className="font-extrabold mr-5">
                {el}
              </div>
            ))}
          </div>
          <div>Сайты</div>
          <div className="flex flex-wrap mb-[15px] pt-[10px] pb-[15px] border-b border-[#AAA] max-h-[110px] overflow-y-scroll">
            {sites?.map((el, index) => (
              <div key={index} className="font-extrabold mr-5">
                {el}
              </div>
            ))}
          </div>
          <div>Города</div>
          <div className="flex flex-wrap mb-[15px] pt-[10px]  max-h-[110px] overflow-y-scroll">
            {cities?.map((el, index) => (
              <div key={index} className="font-extrabold mr-5">
                {el}
              </div>
            ))}
          </div>
          {path !== TT.BLACKLIST && (
            <div>
              Процент: <span className="font-extrabold">{percent}</span>
            </div>
          )}
          <div className="w-full flex justify-center mt-[15px]">
            <button className="prime-button-md bg-font" type="submit" onClick={submit}>
              {path === TT.BLACKLIST ? "Заблокировать" : "Создать"}
            </button>
          </div>
        </div>
      ) : (
        <div className="p-[20px] font-light text-center max-w-[200px] mx-auto">
          <div className="text-sm">Выберите хотя бы один вектор - сайт - город</div>
          <button
            type="button"
            className="prime-button-md bg-font mt-[20px]"
            onClick={() => setPopup(false)}>
            Понятно
          </button>
        </div>
      )}
    </>
  );
};
