import copy from "src/assets/images/copy.svg";
import close from "src/assets/images/close.svg";
import { useState } from "react";
import { LS } from "src/api/constants";
import { Popup } from "src/components/Popup/Popup";
import { ZoomButtons } from "src/components/UI/buttons/ZoomButtons";
import { useSelector } from "react-redux";
import {
  resetOnOffUserChanges,
  setCopiedItem,
  setDeleteMode,
  setIsCopy,
  toggleOnOffIsAddMode,
} from "src/store/onOffTimelineReducer/slice";
import { setZoom, setStep } from "src/store/onOffTimelineReducer/slice";
import bin from "src/assets/images/bin.svg";

import {
  selectOnOffTimelineData,
  selectOnOffIsCopy,
  selectOnOffIsSitesMenuOpen,
  selectOnOffIsAddMode,
  selectOnOffDeleteMode,
  selectOnOffTimelineZoom,
  selectOnOffTimelineStep,
  selectOnOffIsLoading,
} from "src/store/onOffTimelineReducer/selectors";
import { postOnOffData } from "src/store/onOffTimelineReducer/thunks/postOnOffData";
import { useAppDispatch } from "src/store/store";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { ICity } from "src/types/OnOffTimeline/common";

export const TimelineMenu = ({ data }: { data: ICity[] }) => {
  const dispatch = useAppDispatch();

  const isAccess = !!localStorage.getItem(LS.ACCESS)?.includes("on-off_post");
  const username = localStorage.getItem(LS.USERNAME);

  const hasUnsubSegments = data.some((city) =>
    city.currency_pairs.some((pair) =>
      pair.sites.some(
        (site) =>
          site.unsubmitted_segments_best?.length > 0 || site.unsubmitted_segments_site?.length > 0
      )
    )
  );

  const timelineData = useSelector(selectOnOffTimelineData);
  const isLoading = useSelector(selectOnOffIsLoading);
  const isCopy = useSelector(selectOnOffIsCopy);
  const isSitesMenuOpen = useSelector(selectOnOffIsSitesMenuOpen);
  const isAddMode = useSelector(selectOnOffIsAddMode);
  const deleteMode = useSelector(selectOnOffDeleteMode);

  const [isCentrifugoConfirm, setCentrifugoConfirm] = useState(false);
  const [isClearConfirm, setClearConfirm] = useState(false);
  const steps = [10, 30, 60];

  const zoom = useSelector(selectOnOffTimelineZoom);
  const step = useSelector(selectOnOffTimelineStep);

  const onCopyClick = () => {
    if (isCopy) {
      dispatch(setCopiedItem(null));
    }

    dispatch(setIsCopy(!isCopy));
    dispatch(setDeleteMode(false));
  };

  const saveUsersChanges = async () => {
    dispatch(postOnOffData({ data: timelineData }));
  };

  return (
    <div
      className={`flex flex-col gap-8 duration-300 bg-bg z-20 pr-[20px] pb-8 ${
        !isSitesMenuOpen ? "w-[calc(100%-20px)]" : "w-[calc(100%-200px)]"
      }`}>
      <div className={`flex flex-col xl:flex-row gap-y-4 gap-x-8`}>
        <div className="flex gap-4">
          {hasUnsubSegments && (
            <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
              <button
                type="button"
                className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px] bg-bg duration-300 hover:bg-[#E7B4AE]`}
                onClick={() => {
                  setClearConfirm(true);
                }}>
                <img src={close} alt="обновить" width={18} height={18} />
              </button>
            </div>
          )}
          <ZoomButtons
            value={zoom}
            setValue={(value: number) => dispatch(setZoom(value))}
            step={10}
            min={30}
          />
          {isAccess && (
            <>
              <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
                <Tooltip content="Добавление данных" delay={1}>
                  <button
                    type="button"
                    className={`flex justify-center items-center text-sm rounded-sm h-[22px] w-[22px] max-w-[22px] pb-2 duration-300 hover:shadow-inset ${
                      isAddMode ? "bg-[#B2FFDE]" : " bg-bg"
                    }`}
                    onClick={() => {
                      dispatch(toggleOnOffIsAddMode());
                    }}>
                    +
                  </button>
                </Tooltip>
              </div>
              <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
                <Tooltip content="Режим копирования" delay={1}>
                  <button
                    type="button"
                    className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px] duration-300 hover:shadow-inset ${
                      isCopy ? "bg-[#B2FFDE]" : "bg-bg"
                    }`}
                    onClick={onCopyClick}>
                    <img
                      src={copy}
                      alt="скопировать"
                      width={14}
                      height={14}
                      className="opacity-70"
                    />
                  </button>
                </Tooltip>
              </div>
              <div className="rounded-sm bg-stale p-2 w-fit text-xs text-lightFont">
                <Tooltip content="Режим удаления" delay={1}>
                  <button
                    type="button"
                    className={`flex justify-center items-center rounded-sm h-[22px] w-[22px] max-w-[22px]  duration-300 hover:shadow-inset ${
                      deleteMode ? "bg-[#E7B4AE]" : "bg-bg"
                    }`}
                    onClick={() => {
                      dispatch(setDeleteMode(!deleteMode));
                      dispatch(setIsCopy(false));
                    }}>
                    <img src={bin} alt="удалить" width={14} height={14} />
                  </button>
                </Tooltip>
              </div>
            </>
          )}
          <div className="flex gap-2 rounded-sm bg-stale p-2 w-fit text-[10px] text-lightFont">
            {steps?.map((el) => (
              <button
                key={el}
                type="button"
                className={`flex justify-center items-center rounded-sm h-[22px] w-fit min-w-[22px] px-4 bg-bg duration-300 hover:shadow-inset ${
                  step === el ? "font-bold" : "opacity-70 brightness-[1.4]"
                }`}
                onClick={() => dispatch(setStep(el))}>
                {el}m
              </button>
            ))}
          </div>
          <button
            type="button"
            className={`bg-[#BBFFDA] w-fit self-start text-xs px-[16px] h-[26px] border border-[#3BC57AAA] text-[#2BB56A] font-semibold rounded-sm duration-300 whitespace-pre ${
              isLoading ? "opacity-30" : ""
            }`}
            disabled={isLoading}
            onClick={saveUsersChanges}>
            Сохранить
          </button>
        </div>
      </div>
      {isCentrifugoConfirm && (
        <Popup closeModal={() => setCentrifugoConfirm(false)}>
          <div className={"max-w-[300px] flex flex-col gap-[20px] text-sm"}>
            <div>
              Вы уверены, что хотите <b>сбросить</b> все несохраненные изменения всех пользователей?
            </div>
            <div className="text-xs">
              Перед сбросом предупреждайте коллег, чтобы они успели сохранить свои изменения.
            </div>
            <button
              type="button"
              className="w-fit mx-auto prime-button-sm bg-font rounded-sm"
              onClick={async () => {
                setCentrifugoConfirm(false);
              }}>
              Сбросить все изменения
            </button>
          </div>
        </Popup>
      )}
      {isClearConfirm && (
        <Popup closeModal={() => setClearConfirm(false)}>
          <div className={"max-w-[300px] flex flex-col gap-[20px] text-sm"}>
            <div>
              Вы уверены, что хотите <b>сбросить</b> все свои несохраненные изменения?
            </div>
            <div className="text-xs">Изменения других пользователей останутся.</div>
            <button
              type="button"
              className="w-fit mx-auto prime-button-sm bg-font rounded-sm"
              onClick={async () => {
                setClearConfirm(false);
                dispatch(resetOnOffUserChanges(username));
              }}>
              Сбросить свои изменения
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
