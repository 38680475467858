import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { convertToShortTime, timeToSeconds } from "src/helpers/date";
import { selectAllCities, selectAllCurrencies, TGlobalSite } from "src/store/directionsReducer";
import {
  TTimelineDeletePayload,
  TTimelineSite,
  TTimelineUpdateLock,
  TTimelineVector,
} from "src/store/clusterReducer";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { LockButton } from "src/components/UI/buttons/LockButton";
import bin from "src/assets/images/bin.svg";
import infinity from "src/assets/images/infinity.svg";
import pumpkin from "src/assets/images/pumpkin.svg";

type TProps = {
  cityId: number;
  vector: TTimelineVector;
  site: TGlobalSite;
  item: TTimelineSite;
  deleteIt: (payload: TTimelineDeletePayload, saveRows: boolean) => void;
  setPopup: Dispatch<SetStateAction<boolean>>;
  updateInfinity: () => void;
  updateLock: (payload: TTimelineUpdateLock) => void;
  updateTime: (startValue: number, endValue: number) => void;
  updatePercent: (percent: number) => void;
  activeTemplate: number | null;
};

export const TimeLineItemForm = ({
  cityId,
  vector,
  site,
  item,
  deleteIt,
  setPopup,
  updateInfinity,
  updateLock,
  updateTime,
  updatePercent,
  activeTemplate,
}: TProps) => {
  const methods = useForm({ mode: "onChange" });
  const globalCities = useSelector(selectAllCities);
  const globalCurrencies = useSelector(selectAllCurrencies);
  const currentCity = useMemo(
    () => globalCities?.find((city) => city.city_id === cityId),
    [globalCities, cityId]
  );
  const currentFrom = useMemo(
    () => globalCurrencies?.find((el) => el.currency_id === vector.currency_from_id),
    [globalCurrencies, vector.currency_from_id]
  );
  const currentTo = useMemo(
    () => globalCurrencies?.find((el) => el.currency_id === vector.currency_to_id),
    [globalCurrencies, vector.currency_to_id]
  );
  const interval = timeToSeconds(item.end_time) - timeToSeconds(item.start_time);
  const [start, setStart] = useState(item?.start_time);
  const [end, setEnd] = useState(item?.end_time);
  const [percent, setPercent] = useState(vector.percent);
  const disabled = timeToSeconds(end) <= timeToSeconds(start) || percent < 0;

  const updateFn = async () => {
    if (start !== item?.start_time || end !== item?.end_time) {
      const difStart =
        start !== item?.start_time ? timeToSeconds(start) - timeToSeconds(item?.start_time) : 0;
      const difEnd =
        end !== item?.end_time ? timeToSeconds(end) - timeToSeconds(item?.end_time) : 0;
      await updateTime(difStart, difEnd);
    }
    if (percent !== vector?.percent) {
      await updatePercent(percent);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(updateFn)}
        className="w-[280px] flex flex-col gap-8 text-sm">
        <div className="flex justify-between">
          <div className="font-semibold">{currentCity?.city_name}</div>
          {!item?.is_locked && (
            <button
              type="button"
              className={`relative top-2 w-[16px] h-[16px] hidden sm:block bg-[#EAEAF3] hover:bg-[#D7443E77] flex justify-self-end justify-center items-center rounded-sm duration-300`}
              onClick={async () => {
                await deleteIt(
                  {
                    city_id: cityId,
                    currency_from_id: vector.currency_from_id,
                    currency_to_id: vector.currency_to_id,
                    percent: vector.percent,
                    site_id: item.site_id,
                    start_time: item.start_time,
                    end_time: item.end_time,
                  },
                  false
                );
                setPopup(false);
              }}>
              <img src={bin} alt="удалить" width={15} height={15} />
            </button>
          )}
        </div>
        <div className="flex justify-between">
          <div>
            {currentFrom?.currency_name} - {currentTo?.currency_name}
          </div>
          <div className="flex gap-4">
            {!!item?.id ? (
              <input
                type="number"
                className="w-[50px] border border-lightGray text-right px-4"
                value={percent || 0}
                step={1}
                required
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                onChange={(e) => setPercent(+e.target.value)}
              />
            ) : (
              <span>{vector?.percent}</span>
            )}
            %
          </div>
        </div>
        <div className="flex gap-8 w-full h-full items-center font-semibold">
          <div className={`font-bold ml-2 -mr-4 relative top-px`}>
            <Tooltip
              content={
                !item?.is_infinity
                  ? `Сохраните для использования\nболее чем 1 день, иначе после\n0:00 расписание удалится`
                  : `Расписание сохранено`
              }
              direction="right">
              <button
                type="button"
                className={`${
                  item?.is_infinity ? "bg-[#40AEF0]" : "bg-[#F7931A]"
                } flex items-center justify-center rounded-full h-[20px] w-[20px] relative`}
                onClick={updateInfinity}>
                <img
                  src={item?.is_infinity ? infinity : pumpkin}
                  alt={item?.is_infinity ? "сохранено" : "тыква"}
                  width={15}
                  height={15}
                />
              </button>
            </Tooltip>
          </div>
          <LockButton
            stat={item?.is_locked}
            content={!item?.is_locked ? `Заблокируйте для удаления` : `Заблокировано для удаления`}
            active={+vector.cluster_percent !== 0 && !("creator" in item)}
            isSmall
            confirm={() =>
              updateLock({
                city_name: currentCity?.city_name,
                site_name: site?.site_name,
                vector: `${currentFrom?.currency_name}-to-${currentTo?.currency_name}`,
                percent: vector.cluster_percent,
                time_from: item?.start_time,
                time_to: item?.end_time,
                is_locked: !item?.is_locked,
              })
            }
          />
          <div>{site?.site_name || item?.site_id}</div>
        </div>
        <div className="flex flex-col gap-8 text-xs">
          <div className="flex gap-4 text-xs my-8">
            <input
              type="time"
              className="w-full border border-lightGray rounded outline-none h-[30px] py-4 px-8"
              defaultValue={item?.start_time || "00:00"}
              required
              onChange={(e) => setStart(e.target.value)}
            />
            <input
              type="time"
              className="w-full border border-lightGray rounded outline-none h-[30px] py-4 px-8"
              defaultValue={item?.end_time}
              required
              onChange={(e) => setEnd(e.target.value)}
            />
          </div>
          <div>Интервал: {convertToShortTime(interval)}</div>
          {!activeTemplate && <div>Процент кластера: {vector?.cluster_percent}</div>}
          {!activeTemplate && <div>Ожидаемый курс: {vector?.cluster_course}</div>}
          <button
            className={`bg-light w-fit self-end text-xs px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
              disabled ? "opacity-0" : ""
            }`}
            disabled={disabled}>
            Подтвердить
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
