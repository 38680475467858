interface CreateBlockToolsProps {
  submitDisabled: boolean;
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  cancel: () => void;
  submitPairs: () => void;
}

export const CreateBlockTools = ({
  submitDisabled,
  setIsSettings,
  cancel,
  submitPairs,
}: CreateBlockToolsProps) => {
  return (
    <div className={`mr-8 flex items-center justify-end gap-4 text-xs font-semibold pb-[10px]`}>
      <button
        type="button"
        className="h-[28px] w-[28px] flex items-center justify-center border rounded-sm text-[#8A8A93] border-stale bg-bg hover:opacity-80"
        onClick={() => setIsSettings(true)}>
        <span className="relative bottom-2 leading-3 text-2xl">{`\u2699`}</span>
      </button>
      <button
        type="button"
        className="px-[12px] py-4 border rounded-sm text-lightFont border-lightFont bg-lightStale hover:opacity-80"
        onClick={cancel}>
        Сбросить выбор
      </button>
      <button
        type="button"
        disabled={submitDisabled}
        onClick={submitPairs}
        className="px-[12px] py-4 bg-[#BBFFDA] border border-[#3BC57AAA] text-[#2BB56A] rounded-sm hover:opacity-80 disabled:grayscale disabled:bg-[#EEE]">
        Создать пары
      </button>
    </div>
  );
};
