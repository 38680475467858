import { AxiosResponse } from "axios";
import { useState } from "react";
import { FetchRequestOptions, Message, fetchRequest } from "src/helpers/fetchRequest";

type FetchFn<ReturnValue> = () => Promise<AxiosResponse<ReturnValue & Message>>;

interface Props<ReturnValue> {
  fetchFn: FetchFn<ReturnValue>;
  options?: FetchRequestOptions;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useFetch = <ReturnValue>({
  fetchFn,
  options,
  onSuccess,
  onError,
}: Props<ReturnValue>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const fn = async () => {
    setIsLoading(true);
    setIsError(false);
    const res = await fetchRequest<ReturnValue>(fetchFn(), options);

    setIsLoading(false);

    if (res.error) {
      setIsError(true);
      setError(res.error.statusText || "Ошибка запроса");
      onError?.();
    } else {
      onSuccess?.();
    }

    return res;
  };

  return {
    fn,
    isLoading,
    isError,
    error,
  };
};
