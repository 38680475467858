import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const Editor: FC<{
  setInput: Dispatch<SetStateAction<boolean>>;
  name: string;
  setValue: (name, citySum) => void;
  clearFields: boolean;
  isGeneral?: boolean;
  updateFields?: () => void;
  citySum: number;
}> = ({ setInput, name, setValue, clearFields, isGeneral, updateFields, citySum }) => {
  const { unregister } = useFormContext();

  const editOff = () => {
    if (isGeneral && updateFields) {
      updateFields();
      setValue(name, citySum);
      setInput(false);
    } else {
      setValue(name, citySum);
      setInput(false);
    }
    unregister(name);
  };
  useEffect(() => {
    if (clearFields) {
      editOff();
    }
  }, [clearFields]);

  return (
    <button
      type="button"
      className={`ml-[5px] sm:ml-[10px] h-5 w-5 ${
        isGeneral ? "border border-lightGray rounded-[50%] text-[8px]" : "text-[10px]"
      }`}
      onClick={editOff}>{`\u2573`}</button>
  );
};
