import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TPriorityType, TRequestType, TTypeEditorType } from "src/store/commonReducer";

type TProps = {
  currentType: TPriorityType | TRequestType;
  setCurrentType: Dispatch<SetStateAction<TPriorityType | TRequestType>>;
  isAccess: boolean;
  types: Array<TTypeEditorType>;
  isClick?: boolean;
  trigger?: boolean;
  disabled?: boolean;
};

export const TypeEditor = ({
  currentType,
  setCurrentType,
  isAccess,
  types,
  isClick,
  trigger,
  disabled,
}: TProps) => {
  const [isEditType, setEditType] = useState(false);
  const type: TTypeEditorType = types?.find((el: TTypeEditorType) => el.title === currentType);

  useEffect(() => {
    if (trigger) {
      setEditType(false);
    }
  }, [trigger]);

  return (
    <div
      className={`relative ${isAccess && "cursor-pointer"}`}
      onClick={() => {
        if (isClick) {
          setEditType(true);
        }
      }}
      onMouseMove={() => {
        if (isAccess && !isClick && !disabled) {
          setEditType(true);
        }
      }}>
      {type && (
        <div
          style={{ background: type.color }}
          className={`h-[20px] w-[20px] rounded-sm justify-center items-center flex`}>
          <img src={type.icon} alt={type.title} width={type.size} height={type.size} />
        </div>
      )}
      {type && isEditType && (
        <div
          className="absolute top-0 p-4 pr-8 -m-4 bg-bg z-10 rounded-md shadow-base flex flex-col gap-4"
          onMouseLeave={() => setEditType(false)}>
          <div className="flex gap-8 items-center">
            <div
              style={{ background: type.color }}
              className={`h-[20px] w-[20px] rounded-sm justify-center items-center flex`}>
              <img src={type.icon} alt={type.title} width={type.size} height={type.size} />
            </div>
            <div>{type.title}</div>
          </div>
          {isAccess &&
            types
              ?.filter((el: TTypeEditorType) => el.title !== currentType)
              ?.map((el: TTypeEditorType) => (
                <div
                  key={el.title}
                  className="flex gap-8 items-center bg-bg z-20 cursor-pointer"
                  onClick={() => {
                    setCurrentType(el.title);
                    setEditType(false);
                  }}>
                  <div
                    style={{ background: el.color }}
                    className={`h-[20px] w-[20px] rounded-sm justify-center items-center flex`}>
                    <img src={el.icon} alt={el.title} width={el.size} height={el.size} />
                  </div>
                  <div>{el.title}</div>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};
