import { Popup } from "src/components/Popup/Popup";
import { SelectedPairs } from "src/pages/Panel/OnOff/OnOffTimeline/components/CreateBlock/SelectedPairs";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";
import { Button } from "src/shadcn/ui/ui/button";
import { Loader2 } from "lucide-react";
import { TSelectedPair } from "src/store/onOffReducer";

interface VectorsPreviewProps {
  closeModal: () => void;
  emptyPairsMessage: string;
  selectedPairs: TSelectedPair[];
  submit: () => void;
  disabled: boolean;
  isLoading: boolean;
  dbGenerator: boolean;
  setSelectedPairs: React.Dispatch<React.SetStateAction<TSelectedPair[]>>;
  setDbGenerator: (val: boolean) => void;
}

export const VectorsPreview = ({
  closeModal,
  emptyPairsMessage,
  selectedPairs,
  submit,
  disabled,
  isLoading,
  dbGenerator,
  setSelectedPairs,
  setDbGenerator,
}: VectorsPreviewProps) => {
  return (
    <Popup closeModal={closeModal}>
      <div className="flex flex-col">
        <h3 className="font-medium text-lg text-center">Созданные векторы</h3>
        <div className="w-full overflow-x-auto">
          <SelectedPairs
            emptyMessage={emptyPairsMessage}
            className="w-[550px] max-h-[600px]"
            selectedPairs={selectedPairs}
            removeItem={(id: string) =>
              setSelectedPairs((prev) => prev.filter((item) => item.id !== id))
            }
            removeAll={() => setSelectedPairs([])}
          />
          <label className="flex items-center gap-[4px] mb-[20px] cursor-pointer group">
            <Checkbox checked={dbGenerator} onChange={(e) => setDbGenerator(e.target.checked)} />
            <span className="text-[14px] group-hover:opacity-80">
              Добавить все выбранные векторы в DB generator
            </span>
          </label>
        </div>
        <Button className="mx-auto px-[12px] mt-[5px]" disabled={disabled} onClick={submit}>
          {isLoading ? <Loader2 className="animate-spin" /> : "Подтвердить"}
        </Button>
      </div>
    </Popup>
  );
};
