import { Url } from "src/api/constants";
import academy from "src/assets/images/academy.svg";
import admin from "src/assets/images/whiteAdmin.svg";
import cash from "src/assets/images/cash.svg";
import clock from "src/assets/images/clock.svg";
import control from "src/assets/images/squareCheck.svg";
import integrations from "src/assets/images/integrations.svg";
import statistic from "src/assets/images/statistic.svg";
import support from "src/assets/images/whiteSupport.svg";
import tetris from "src/assets/images/tetris.svg";

export const navigation = {
  blocks: {
    courses: {
      title: "Курсы",
      access: ["bid_get"],
      icon: cash,
      color: "#02457A",
      children: [
        {
          title: "Ставка",
          path: Url.BID,
          access: ["bid_get"],
        },
        // {
        //   title: "Лимиты",
        //   path: Url.LIMITS,
        //   access: ["on-off_get"],
        // },
        {
          title: "Профы",
          path: Url.PROF,
          access: ["bid_get"],
        },
        {
          title: "Кластеры сайтов",
          path: Url.SITE_CLUSTERS,
          access: ["cluster_get"],
        },
      ],
    },
    integrations: {
      title: "Сайты",
      access: ["on-off_get"],
      icon: integrations,
      color: "#248232",
      children: [
        {
          title: "MinMax",
          path: Url.MINMAX,
          access: ["minmax_get"],
        },
        {
          title: "MinMax Таблица",
          path: Url.MINMAX_SHEET,
          access: ["minmax_get"],
        },
        {
          title: "Вкл/Выкл",
          path: Url.ONOFF,
          access: ["on-off_get"],
        },
        {
          title: "Switcher",
          path: Url.ONOFF_TIMELINE,
          access: ["on-off_get"],
        },
        {
          title: "Шаги",
          path: Url.STEP,
          access: ["step_get"],
        },
        {
          title: "Сводка",
          path: Url.SUMMARY,
          access: ["direction_get"],
        },
        {
          title: "GRAPH Векторы",
          path: Url.DIRECTION_GRAPH,
          access: ["direction_get"],
        },
        {
          title: "Тексты",
          path: Url.TEXT,
          access: ["on-off_post"],
        },
      ],
    },
    control: {
      title: "Контроль",
      path: `${Url.CONTROL}/${Url.CONTROL_WALLETS}`,
      access: ["control_post"], // "control_get", "merch_get"
      icon: control,
      color: "#453E21",
      children: [],
    },
    stats: {
      title: "Статистика",
      access: ["stats_get"],
      icon: statistic,
      color: "#5555BB",
      children: [
        {
          title: "Статистика",
          path: Url.STATS,
          access: ["stats_get"],
        },
        {
          title: "Отчеты",
          path: Url.STATS_REPORTS,
          access: ["stats_get"],
        },
        {
          title: "Аналитика",
          path: Url.ANALYTICS,
          access: ["stats_get"],
        },
      ],
    },
  },
  tools: {
    timeline: {
      title: "Таймлайн",
      path: Url.CLUSTER_TIMELINE,
      access: ["cluster_get"],
      icon: clock,
      children: [],
    },
    tetris: {
      title: "Тетрис",
      path: Url.TETRIS,
      access: ["direction_get"],
      icon: tetris,
      children: [],
    },
  },
  helpers: {
    admin: {
      title: "Админ",
      path: Url.ADD,
      access: ["add_get", "user_post"],
      icon: admin,
      children: [
        {
          title: "Добавление",
          path: Url.ADD,
          access: ["add_get", "user_post"],
        },
        {
          title: "Прокси",
          path: Url.DEVELOPERS,
          access: ["admin_get"],
        },
      ],
    },
    support: {
      title: "Поддержка",
      path: Url.REQUESTS,
      access: ["it_requests_get"],
      icon: support,
      children: [],
    },
    academy: {
      title: "Криптарий",
      path: Url.ACADEMY,
      access: ["courses_get"],
      icon: academy,
      children: [],
    },
  },
  additional: [
    {
      path: Url.FLIGHTS,
      title: "Вылеты",
    },
    {
      path: Url.RATES,
      title: "Курсы",
    },
    {
      path: Url.REPORTS,
      title: "Контроль",
    },
    {
      path: Url.DEFAULT,
      title: "Статистика",
    },
    {
      path: Url.NEWS,
      title: "Обновления",
    },
  ],
};

export type TChildrenRoute = {
  title: string;
  path: string;
  access: string[];
};
