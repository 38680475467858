import { SVG } from "src/assets/images/SVG";
import { cn } from "src/utils";
import { TTurnOffReportVectors } from "../models";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";

interface SelectedPairCardProps {
  selectedPair: TTurnOffReportVectors;
  checked: boolean;
  checkToDelete: (item: TTurnOffReportVectors) => void;
  isAccess: boolean;
}

const SelectedSimplePairCard = ({
  selectedPair,
  checked,
  checkToDelete,
  isAccess,
}: SelectedPairCardProps) => {
  return (
    <div className="select_button bg-bg flex justify-between px-8 items-center gap-4 ml-4 overflow-hidden text-xs">
      {isAccess && (
        <div className="relative top-[2.5px] -mr-4">
          <Checkbox checked={checked} onChange={() => checkToDelete(selectedPair)} />
        </div>
      )}
      <div className={cn("grid grid-cols-3 gap-8 w-full")}>
        <div className="text-left whitespace-nowrap">
          {!!selectedPair?.city_name ? selectedPair?.city_name : "Авто"}
        </div>
        <div className="grid grid-cols-[30px_1fr] justify-items-center items-center gap-4 hover:opacity-80 text-xs">
          <div>
            <SVG id={selectedPair?.currency_from_code} />
          </div>
          <span className="w-full text-left">{selectedPair?.currency_from_code}</span>
        </div>
        <div className="grid grid-cols-[30px_1fr] justify-items-center items-center gap-4 hover:opacity-80 text-xs">
          <div>
            <SVG id={selectedPair?.currency_to_code} />
          </div>
          <span className="w-full text-left">{selectedPair?.currency_to_code}</span>
        </div>
      </div>
    </div>
  );
};

export default SelectedSimplePairCard;
