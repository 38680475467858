import { TChildrenRoute, navigation } from "src/components/Header/components/navigation";

export const getTitle = (pageUrl: string, obj) => {
  let title: string;

  const search = (pageUrl: string, obj) => {
    if (typeof obj !== "object") {
      return;
    }

    if (obj.path === pageUrl) {
      title = obj.title;
      return;
    }

    Object.values(obj).forEach((item) => search(pageUrl, item));

    if (obj.children && obj.children.length > 0) {
      obj.children.forEach((child: TChildrenRoute) => search(pageUrl, child));
    }
  };

  search(pageUrl, obj);

  return title;
};

export const PageTitle = ({ page, isBurger }) => {
  const title = getTitle(page, navigation);
  const color = isBurger ? "text-white" : ("text-lightGray" as string);

  return <div className={`${color} text-left pt-2 min-w-[160px]`}>{title}</div>;
};
