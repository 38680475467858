import { ITimeSegment } from "src/types/OnOffTimeline/common";
import { timeToSeconds } from "../date";
import { findIntersection } from "src/helpers/onOffTimeline/copyPaste";

export const sortSegmentsByStart = (a: ITimeSegment, b: ITimeSegment) => {
  return timeToSeconds(a.start) - timeToSeconds(b.start);
};

export const findTheLatestEnd = (segments: ITimeSegment[]) => {
  let max = segments[0].end;

  segments.forEach((item) => {
    const endTime = timeToSeconds(item.end);
    const maxToSeconds = timeToSeconds(max);

    if (endTime > maxToSeconds) {
      max = item.end;
    }
  });

  return max;
};

export const joinSegments = (segmentsList: ITimeSegment[]) => {
  const segments = [...segmentsList].sort(sortSegmentsByStart);

  const result: ITimeSegment[] = [];

  let i = 0;

  while (i < segments.length) {
    let temp: ITimeSegment[] = [];
    let tempMaxEnd = 0;

    let h = i;

    temp.push(segments[h]);
    tempMaxEnd = timeToSeconds(segments[h].end);

    while (h < segments.length - 1 && timeToSeconds(segments[h + 1].start) <= tempMaxEnd) {
      temp.push(segments[h + 1]);
      tempMaxEnd = Math.max(tempMaxEnd, timeToSeconds(segments[h + 1].end));
      h++;
    }

    if (temp.length > 1) {
      i = h + 1;
    } else {
      i += 1;
    }

    const period: ITimeSegment = {
      start: temp[0].start,
      end: findTheLatestEnd(temp),
      id: temp.find((item) => item.id)?.id || undefined,
    };

    result.push(period);

    temp = [];
    tempMaxEnd = 0;
  }

  return result;
};

export const deleteBlueAccordingly = (blue: ITimeSegment[], deletedSegment: ITimeSegment) => {
  const containingSegments: ITimeSegment[] = [];

  const result: ITimeSegment[] = [];

  blue.forEach((segment) => {
    if (
      !(timeToSeconds(segment.start) > timeToSeconds(deletedSegment.end)) &&
      !(timeToSeconds(deletedSegment.start) > timeToSeconds(segment.end))
    ) {
      containingSegments.push(segment);
    } else {
      result.push(segment);
    }
  });

  const cutSegments: ITimeSegment[] = [];

  containingSegments.forEach((segment) => {
    const cutSegment = cutFromSegment(segment, deletedSegment);
    if (cutSegment) {
      cutSegments.push(...cutSegment);
    }
  });

  const finalRes = [...result, ...cutSegments];

  return joinSegments(finalRes);
};

export const addGreenAccordingly = (green: ITimeSegment[], newSegments: ITimeSegment[]) => {
  const greenSegments = [...green];

  greenSegments.push(...newSegments);
  return joinSegments(greenSegments);
};

export const cutFromSegment = (
  segment: ITimeSegment,
  deletedSegment: ITimeSegment
): ITimeSegment[] | undefined => {
  const result = { ...segment };

  const segmentStart = timeToSeconds(segment.start);
  const segmentEnd = timeToSeconds(segment.end);
  const deletedStart = timeToSeconds(deletedSegment.start);
  const deletedEnd = timeToSeconds(deletedSegment.end);

  if (segmentStart < deletedStart && segmentEnd > deletedEnd) {
    const res1: ITimeSegment = {
      start: segment.start,
      end: deletedSegment.start,
    };
    const res2: ITimeSegment = {
      start: deletedSegment.end,
      end: segment.end,
    };

    return [res1, res2];
  }

  if (
    (segmentStart === deletedStart && segmentEnd === deletedEnd) ||
    (segmentStart === deletedStart && deletedEnd > segmentEnd) ||
    (segmentEnd === deletedEnd && deletedStart < segmentStart) ||
    (deletedStart < segmentStart && deletedEnd > segmentEnd)
  ) {
    return;
  }

  if (segmentStart < deletedStart) {
    result.end = deletedSegment.start;

    if (segmentEnd === deletedEnd) {
      result.start = segment.start;

      return [result];
    }
  } else {
    result.start = deletedSegment.end;
  }

  if (segmentEnd < deletedEnd) {
    result.end = deletedSegment.start;
  } else {
    result.start = deletedSegment.end;
  }

  return [result];
};

/**
 * Принимает массив сегментов
 * и массив несохраненных сегментов,
 * и объединяет их, возвращая итоговое состояние
 */
export function joinWithUnsubmitted(
  segments: ITimeSegment[],
  unsubmitted: ITimeSegment[]
): ITimeSegment[] {
  let res: (ITimeSegment | ITimeSegment[])[] = [...segments];

  unsubmitted?.forEach((unsubItem) => {
    // если интервал нужно удалить - просто ищем его в
    // массиве result и выпиливаем оттуда
    if (unsubItem.deleted_flag) {
      const indexOfItemToDelete = res.findIndex(
        (resultItem: ITimeSegment) =>
          resultItem.start === unsubItem.start && resultItem.end === unsubItem.end
      );

      // если нашли интервал, полностью совпадающий с удаленным,
      // то просто удаляем его
      if (~indexOfItemToDelete) {
        res.splice(indexOfItemToDelete, 1);
      } else {
        // если нет интервалов, которые совпадают полностью,
        // то ищем интервалы, которые частично попадают в зону удаления,
        // и получаем из них новые интервалы
        // полученные интервалы вставляем в массив вместо старого
        res.forEach((item: ITimeSegment, i) => {
          if (findIntersection(item, unsubItem)) {
            const replacementItems = getReplacementItems(item, unsubItem);
            res[i] = replacementItems;
          }
        });

        const newRes = [];

        res.forEach((item) => {
          if (Array.isArray(item)) {
            item.forEach((segment) => {
              newRes.push(segment);
            });
          } else {
            newRes.push(item);
          }
        });

        res = [...newRes];
      }
    }
    // если интервал удалять не нужно -
    // просто добавляем его в итоговый массив
    else {
      res.push(unsubItem);
    }
  });

  // возвращаем итоговый массив с объединенными интервалами
  return joinSegments(res as ITimeSegment[]);
}

/**
 * Принимает 2 сегмента: редактируемый сегмент и сегмент к удалению,
 * и в зависимости от того, как они пересекаются, возвращает массив сегментов,
 * которые получатся, если из первого сегмента удалить область, которая обозначается
 * сегментом к удалению
 */
export function getReplacementItems(segment: ITimeSegment, deleted: ITimeSegment) {
  const segmentStart = timeToSeconds(segment.start);
  const segmentEnd = timeToSeconds(segment.end);
  const deletedStart = timeToSeconds(deleted.start);
  const deletedEnd = timeToSeconds(deleted.end);

  // если сегмент полностью содержится внутри удаленного,
  // сегмент должен быть полность удален и заменен ничем
  if (segmentStart >= deletedStart && segmentEnd <= deletedEnd) {
    return [];
  }
  // если удаленный сегмент полностью содержится внутри редактируемого,
  // то часть редактируемого, которая пересекается с удаленным, должна быть
  // отброшена, а 2 оставшиеся должны быть возвращены как новая версия редактируемого
  else if (segmentStart < deletedStart && segmentEnd > deletedEnd) {
    const first = { start: segment.start, end: deleted.start };
    const second = { start: deleted.end, end: segment.end };
    return [first, second];
  }
  // если редактируемый и удаленный сегменты не пересекаются,
  // возвращаем редактируемый сегмент без изменений
  else if (segmentEnd < deletedStart || segmentStart > deletedEnd) {
    return [segment];
  } else {
    // в этом блоке будут только случаи, когда сегменты пересекаются,
    // и при этом какие-то из их концов совпадают
    // тут мы пытаемся определить, где находится пересечение,
    // и в зависимости от этого корректно вернуть оставшийся отрезок
    const intersection = findIntersection(segment, deleted);
    const intersectionStart = timeToSeconds(intersection.start);

    if (intersectionStart > segmentStart) {
      return [{ start: segment.start, end: intersection.start }];
    } else {
      return [{ start: intersection.end, end: segment.end }];
    }
  }
}
