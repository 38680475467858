import {
  ICity,
  ICityWithGroups,
  ISite,
  ITimeSegment,
  OnOffItemToDeleteType,
} from "src/types/OnOffTimeline/common";
import { TCurrencyTypesFilter, TGlobalCurrency } from "src/store/directionsReducer";
import { ReactNode } from "react";

export interface OnOffConfirmation {
  title: string;
  subtitle?: string;
  content?: ReactNode;
  cb: () => void;
}

export interface OnOffTimelineInitialState {
  step: number;
  zoom: number;
  isAddMode: boolean;
  currencyFilters: TCurrencyTypesFilter;
  isSitesMenuOpen: boolean;
  expandedState: OnOffExpandedState;
  allCitiesExpanded: boolean;
  everythingExpanded: boolean;
  timelineData: ICity[];
  selectedItem: SelectedItem;
  copiedItem: SelectedItem;
  isCopy: boolean;
  deleteMode: boolean;
  cityTimeLineWidth: number;
  timelineContainerScrollLeft: number;
  axisFixed: boolean;
  confirmation: OnOffConfirmation | null;
  isLoading: boolean;
  sitesLoading: boolean;
  errorMessage: string | null;
  allSites: OnOffSite[];
  lastAppliedFilters: OnOffLastAppliedFilters;
  pairsNotFresh: boolean;
  openAllSites: boolean;
}

export interface OnOffLastAppliedFilters {
  sites: string[];
  cities: string[];
  from: string[];
  to: string[];
}

export interface OnOffSite {
  site_id: number;
  site_name: string;
  has_segments: boolean;
  on_best: boolean;
}

export interface SelectedItem extends ISite {
  city: Partial<ICity>;
  currencyFrom: TGlobalCurrency;
  currencyTo: TGlobalCurrency;
  otherSites?: ISite[];
}

export interface InitExpandedStateProps {
  data: ICityWithGroups[];
  entitiesToExpand?: string[];
}

export interface SetSelectedItemProps {
  cityCode: string;
  currencyFrom: TGlobalCurrency;
  currencyTo: TGlobalCurrency;
  siteName: string;
}

export interface UpdateSelectedSegmentsPayload extends SelectedItem {
  unsubmitted_segments_site: ITimeSegment[];
  unsubmitted_segments_best: ITimeSegment[];
}

export interface OnOffDeleteItemProps {
  type: OnOffItemToDeleteType;
  name: string;
  isNew?: boolean;
}

export interface ExpandedEntity {
  isExpanded: boolean;
  children?: Record<string, ExpandedEntity>;
}

export enum ExpandedStateLevel {
  ROOT,
  CITY,
  GROUP,
  GROUPED_PAIR,
}

export enum OnOffDataEntityType {
  CITY = "city",
  GROUP = "group",
  FROM_PAIR = "from_pair",
  PAIR = "pair",
  SITE = "site",
}

export enum ExpandActionType {
  EXPAND = "expand",
  COLLAPSE = "collapse",
}

export interface ToggleExpandedPayload {
  selector: string;
  type: ExpandActionType;
}

export type OnOffExpandedState = Record<string, ExpandedEntity>;

export interface TSelectedPair {
  id: string;
  from: TGlobalCurrency;
  to: TGlobalCurrency;
  city: ICity | null;
  site: string | null;
}
