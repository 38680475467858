import { useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { EQuestion, TAttemptAnswer, TAnswerPayload, TQuestion, TAttempt } from "../../types";
import { EditQuestion } from "./EditQuestion";
import { Button } from "src/shadcn/ui/ui/button";
import { Gallery } from "src/pages/Requests/components/Gallery";
import AssetPreview from "src/pages/Requests/components/AssetPreview";
import { AssetClient, ContentType } from "src/pages/Requests/components/Request";
import { Loader2 } from "lucide-react";
import { BASE_URL, ACADEMY_STATIC_TOKEN, LS } from "src/api/constants";
import api from "src/api/http";
import { setNotific } from "src/store/mainReducer";
import { useAppDispatch } from "src/store/store";
import { Answer } from "../Answer/Answer";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";
import { useSelector } from "react-redux";
import { selectAttempt, selectMyAnswers, setMyAnswers } from "src/store/academyReducer";

const downloadAssetsOptions = {
  headers: {
    "X-STATIC-TOKEN": ACADEMY_STATIC_TOKEN,
  },
  responseType: "blob" as const,
};

export const Question = ({
  question,
  isEditPage,
  yourAnswer,
  isExpired,
}: {
  question: TQuestion;
  isEditPage: boolean;
  yourAnswer?: TAttemptAnswer | null | undefined;
  isExpired?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const attempt = useSelector(selectAttempt);
  const myAnswers = useSelector(selectMyAnswers);
  const [isEdit, setIsEdit] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [currentImage, setCurrentImage] = useState({ src: "", index: 0 });
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [answerClickTrigger, setTrigger] = useState(0);

  const answerFromAttempt = attempt?.attempt_answers?.find(
    (el) => el.question_id === question.question_id
  );
  const answerFromMyAnswers = myAnswers?.answers?.find(
    (el) => el.question_id === question.question_id
  );
  const [isAnswered, setIsAnswered] = useState(
    !!answerFromAttempt || !!answerFromMyAnswers || false
  );
  const [answer, setAnswer] = useState(
    !!answerFromAttempt && typeof answerFromAttempt?.answer === "string"
      ? answerFromAttempt?.answer
      : !!answerFromMyAnswers && question.question_type === EQuestion.INPUT
      ? answerFromMyAnswers?.answer_title
      : ""
  );

  const [attachments, setAttachments] = useState<AssetClient[]>(
    question?.question_attachments?.map((el) => ({
      id: el.attachment_id,
      path: el.attachment_url,
      url: (BASE_URL + el.attachment_url).replace("api/", ""),
      content_type: el.attachment_type,
    })) || []
  );

  const answers = useMemo(() => {
    const inputs = document?.getElementsByName(`${question.question_id}`);
    const inputsList = (!!inputs ? [...inputs] : []) as HTMLInputElement[];

    return inputsList?.filter((el) => el.checked)?.map((el) => +el.value) || [];
  }, [answerClickTrigger]);

  const disabledAnswer =
    (question.question_type === EQuestion.INPUT ? !answer?.length : !answers?.length) || isLoading;

  const deleteAsset = async (id: number | string) => {
    if (!!question?.question_id && typeof id === "number") {
      const { response } = await fetchRequest(
        academyApi.deleteAttachment(question?.question_id, [id]),
        {
          request: "Удаление вложений",
        }
      );
      if (response) {
        setAttachments((prev) => prev.filter((asset) => asset.id !== id));
      }
    } else {
      setAttachments((prev) => prev.filter((asset) => asset.id !== id));
    }
  };

  const downloadImage = async (assetUrl: string): Promise<string> => {
    try {
      const { data } = await api.get(
        (BASE_URL + assetUrl).replace("api/", ""),
        downloadAssetsOptions
      );
      const img = URL.createObjectURL(data);
      return img;
    } catch {
      dispatch(
        setNotific({
          type: "error",
          message: "Ошибка при загрузке картинки",
        })
      );
      return "";
    }
  };

  const downloadAssets = async (assets: AssetClient[]) => {
    if (!assets || assets.length === 0) return [];
    if (assets && assets.length > 0) {
      const finalAssets: AssetClient[] = [];

      for (const asset of assets) {
        let src: string;
        const type = asset.content_type?.includes("image")
          ? ContentType.IMAGE
          : asset.content_type?.includes("video")
          ? ContentType.VIDEO
          : ContentType.APPLICATION;
        if (asset.content_type === ContentType.IMAGE || asset.content_type === ContentType.VIDEO) {
          src = asset.path?.includes("blob") ? asset.path : await downloadImage(asset.path);
        }
        finalAssets.push({
          ...asset,
          path: src,
          content_type: type,
        });
      }
      return finalAssets;
    }
  };

  const answerSubmit = async () => {
    setIsLoading(true);
    const payload: TAnswerPayload = {
      question_id: question.question_id,
      selected_option: question.question_type === EQuestion.INPUT ? answer : answers,
    };
    const { response } = await fetchRequest<TAttempt>(
      academyApi.answerAttempt(attempt.attempt_id, payload),
      {
        request: "Ответ",
      }
    );
    if (response) {
      const myAnswersFromRes = {
        attempt_id: attempt?.attempt_id,
        answers: response?.attempt_answers,
      };
      dispatch(setMyAnswers(myAnswersFromRes));
      localStorage.setItem(LS.ACADEMY_MY_ANSWERS, JSON.stringify(myAnswersFromRes));
      setIsAnswered(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (question?.question_attachments && question?.question_attachments.length > 0) {
        setAssetsLoading(true);
        const newAssets = await downloadAssets(
          question?.question_attachments?.map((el) => ({
            id: el.attachment_id,
            path: el.attachment_url,
            url: (BASE_URL + el.attachment_url).replace("api/", ""),
            content_type: el.attachment_type,
          }))
        );
        setAttachments(newAssets);
      }
      setAssetsLoading(false);
    })();
  }, [question?.question_attachments]);

  return (
    <div
      className={`w-full flex flex-col gap-5 p-5 shadow-md border rounded-md ${
        yourAnswer?.answer_is_correct === false || yourAnswer === null
          ? "border-rose-400 bg-rose-50"
          : yourAnswer?.answer_is_correct === true
          ? "border-emerald-400 bg-emerald-50"
          : isAnswered && !yourAnswer
          ? "border-stone-300 bg-stone-50"
          : isEdit
          ? "bg-purple-50"
          : "border-input"
      }`}>
      {!isEdit ? (
        <>
          <div className="flex gap-5 items-center">
            <h3>{question.question_title}</h3>
            {isEditPage && (
              <Button
                variant="ghost"
                className="h-10 w-10 text-slate-500 text-lg rotate-90"
                onClick={() => setIsEdit(!isEdit)}>
                {`\u270E`}
              </Button>
            )}
          </div>
          {(isEditPage || !!question.question_content?.length) && (
            <Markdown
              className={`w-full h-full pb-8 px-5 ${isEditPage ? "min-h-[100px]" : ""}`}
              remarkPlugins={[remarkGfm]}>
              {question.question_content}
            </Markdown>
          )}
          {!isEditPage && question.question_type === EQuestion.INPUT ? (
            <div className="flex flex-col gap-8">
              <div className="text-slate-400">Ваш ответ</div>
              {!!yourAnswer ? (
                yourAnswer?.answer
              ) : (
                <input
                  className="border border-input rounded-sm py-8 px-3"
                  value={answer}
                  disabled={isExpired}
                  onChange={(e) => setAnswer(e.target.value)}
                />
              )}
              {!!yourAnswer && (
                <>
                  <div className="text-slate-400 pt-5">Верный ответ</div>
                  <div className="flex flex-col gap-8 lg:grid grid-cols-2">
                    {question.question_answers?.map((el) => (
                      <Answer
                        key={el.answer_id}
                        answer={el}
                        name={`${question?.question_id}` || "new_question"}
                        type={question?.question_type || EQuestion.NONE}
                        isEditPage={isEditPage}
                        isEditQuestion={isEdit}
                        isExpired={isExpired}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-8 lg:grid grid-cols-2">
              {question.question_answers?.map((el, index) => {
                const key = !!el.answer_id ? el.answer_id : `${index}_new_answer`;

                return (
                  <Answer
                    key={key}
                    answer={el}
                    yourAnswer={yourAnswer}
                    name={`${question?.question_id}` || "new_question"}
                    type={question?.question_type || EQuestion.NONE}
                    isEditPage={isEditPage}
                    isEditQuestion={isEdit}
                    isExpired={isExpired}
                    setTrigger={setTrigger}
                  />
                );
              })}
            </div>
          )}
          {yourAnswer === null && <div className="text-rose-500">Вы пропустили вопрос</div>}
        </>
      ) : (
        <>
          <h3>Редактирование</h3>
          <EditQuestion
            question={question}
            setIsEdit={setIsEdit}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </>
      )}
      {isEdit && (
        <>
          <div className="flex items-start flex-wrap gap-4">
            {!!attachments?.length &&
              attachments?.map((attachment, index) => (
                <AssetPreview
                  key={attachment.id}
                  canEdit={isEdit}
                  notModified={true}
                  deleteAsset={deleteAsset}
                  photosIndex={index}
                  asset={attachment}
                  setCurrentImage={setCurrentImage}
                  setPopup={setPopup}
                  isNew={typeof attachment.id === "string"}
                  isCopyPath
                />
              ))}
            {assetsLoading && (
              <div className="w-[68px] h-[68px] flex items-center justify-center">
                <Loader2 className="animate-spin w-[26px] h-[26px] text-[#40CEF0]" />
              </div>
            )}
          </div>
          <Gallery
            photos={attachments}
            isPopup={isPopup}
            setPopup={setPopup}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
          />
        </>
      )}
      {!yourAnswer &&
        !isEditPage &&
        !isAnswered &&
        !isExpired &&
        question.question_type !== EQuestion.NONE && (
          <Button disabled={disabledAnswer} onClick={answerSubmit}>
            Ответить
          </Button>
        )}
      {!!yourAnswer && (
        <div className="border border-input-300 bg-white text-slate-500 text-sm p-5 rounded-sm">
          {question?.question_explanation}
        </div>
      )}
    </div>
  );
};
