import { ReactNode } from "react";

export const Soon = ({
  children,
  isPadding,
  isLeft,
  isRight,
  maxWidth,
  isAccess,
  rounded,
}: {
  children: ReactNode;
  isPadding?: boolean;
  isLeft?: boolean;
  isRight?: boolean;
  maxWidth?: string;
  isAccess: boolean;
  rounded?: string;
}) => {
  return (
    <div className={`relative z-[20] ${rounded ? rounded : "rounded-lg"}`}>
      {!isAccess && (
        <div
          className={`duration-500 opacity-0 hover:opacity-100 hover:bg-glass absolute h-full flex items-center backdrop-blur rounded-lg text-xs font-bold text-lightFont text-center cursor-default w-full px-[10px] ${
            maxWidth && `max-w-[${maxWidth}]`
          } ${isLeft ? "justify-start" : isRight ? "justify-end" : "justify-center"} ${
            isPadding && "w-[calc(100%+20px)] left-[-10px] py-[8px]"
          }`}>
          Скоро
        </div>
      )}
      {children}
    </div>
  );
};
