export interface ProfItem {
  city_name: string;
  prof_bets: ProfBet[];
}

export interface ProfBet {
  currency_code_from: string;
  currency_code_to: string;
  prof_form_percent: number;
  prof_form_rate: number;
  prof_form_origin_rate: number;
  prof_form_comment: string;
  prof_form_as_percentage: boolean;
  prof_form_deal_type: ProfDealType;
}

export enum ProfDealType {
  SELL = "sell",
  BUY = "buy",
}
