import { memo } from "react";
import { useSelector } from "react-redux";
import { cn } from "src/helpers/cn";
import { useOnOffToggleExpanded } from "src/hooks/useOnOffToggleExpanded";
import { Accordion } from "src/pages/Panel/OnOff/OnOffTimeline/components/common/Accordion";
import { AccordionLabel } from "src/pages/Panel/OnOff/OnOffTimeline/components/common/AccordionLabel";
import { CurrencyPairGrouped } from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/CurrencyPairGrouped";
import {
  confirmDelete,
  confirmUndo,
} from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/TimelineData";
import { ICity, ICityPairsGroup, OnOffItemToDeleteType } from "src/types/OnOffTimeline/common";
import { OnOffDataEntityType, ExpandActionType } from "src/types/OnOffTimeline/store";
import {
  selectOnOffDeleteMode,
  selectOnOffIsEdited,
  selectOnOffIsNew,
} from "src/store/onOffTimelineReducer/selectors";
import {
  onOffTimeLineUndo,
  setOnOffConfirmation,
  toggleExpandAllGroupCurrencies,
} from "src/store/onOffTimelineReducer/slice";
import { deleteOnOffEntity } from "src/store/onOffTimelineReducer/thunks/deleteOnOffEntity";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { DeleteConfirmText } from "src/pages/Panel/OnOff/OnOffTimeline/components/popups/OnOffConfirmPopup";

interface CurrencyGroupProps {
  group: ICityPairsGroup;
  city: Partial<ICity>;
  width: number;
}

export const CurrencyGroup = memo(({ group, city }: CurrencyGroupProps) => {
  const dispatch = useAppDispatch();

  const deleteMode = useSelector(selectOnOffDeleteMode);

  const selector = `${city.city_code}_${group.groupName}`;

  const { isExpanded, isFullyExpanded, toggleIsExpanded } = useOnOffToggleExpanded({
    type: OnOffDataEntityType.GROUP,
    selector,
  });

  const isEdited = useAppSelector((state) =>
    selectOnOffIsEdited(state, {
      type: OnOffDataEntityType.GROUP,
      cityCode: city.city_code,
      groupName: group.groupName,
    })
  );
  const isNew = useAppSelector((state) =>
    selectOnOffIsNew(state, {
      type: OnOffDataEntityType.GROUP,
      cityCode: city.city_code,
      groupName: group.groupName,
    })
  );

  const subtitle = `${city.city_name} ${group.groupName}?`;

  const onDelete = () => {
    dispatch(
      setOnOffConfirmation({
        title: confirmDelete,
        subtitle,
        content: (
          <DeleteConfirmText text="Удаленные сайты сохранят свои последние данные, которые будут доступны здесь" />
        ),
        cb: () =>
          dispatch(
            deleteOnOffEntity({
              type: OnOffItemToDeleteType.GROUP,
              name: `${city.city_code}_${group.groupName}`,
              isNew,
            })
          ),
      })
    );
  };

  const isAuto = group.groupName === "AUTO";

  const undo = () => {
    dispatch(
      setOnOffConfirmation({
        title: confirmUndo,
        subtitle,
        cb: () => {
          dispatch(onOffTimeLineUndo(`${city.city_code}_${group.groupName}`));
        },
      })
    );
  };
  return (
    <Accordion
      isOpen={isAuto ? true : isExpanded}
      isExpandable={!deleteMode}
      toggleIsOpen={toggleIsExpanded}
      customLabelComponent={
        !isAuto ? (
          <AccordionLabel
            undoable={isEdited && !deleteMode}
            undo={undo}
            undoClassname={cn(isEdited && !isNew && "onOffEdited", "text-[12px] -mt-[4px]")}
            label={group.groupName}
            labelClassname={cn(
              "text-[16px] font-medium",
              !deleteMode && "hover:underline",
              deleteMode && "border border-red bg-[#D7443E11] hover:bg-[#D7443E33] duration-300"
            )}
            containerClassname={cn(isEdited && !isNew && "onOffEdited", isNew && "onOffNew")}
            isFullyExpanded={isFullyExpanded}
            isExpandable={!deleteMode}
            onClick={() =>
              dispatch(
                toggleExpandAllGroupCurrencies({
                  type: isFullyExpanded ? ExpandActionType.COLLAPSE : ExpandActionType.EXPAND,
                  selector,
                })
              )
            }
            notExpandableOnClick={onDelete}
          />
        ) : undefined
      }
      containerClassname={cn(!isExpanded && "last:pb-[5px]")}
      labelContainerClassname={
        !isAuto
          ? cn(
              "text-xs bg-bg h-[44px] sticky left-0 pl-[20px] py-[10px]",
              isExpanded && "bg-gradient-to-t from-[#e7e7e760] via-[#e7e7e730] to-[#e7e7e700]"
            )
          : "hidden"
      }>
      <div className="flex flex-col w-full">
        {group.groupedPairs.map((group, i) => (
          <CurrencyPairGrouped
            key={group.currency_from.currency_name}
            index={i}
            group={group}
            city={city}
          />
        ))}
      </div>
    </Accordion>
  );
});
