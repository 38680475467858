import { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { fetchRequest } from "src/helpers/fetchRequest";
import { TMinMaxSheet } from "src/store/directionsReducer";
import minMaxApi from "src/api/minMax";
import { Field } from "src/components/FormElements/Field";

type TProps = {
  site: TMinMaxSheet;
  fetchSheet: () => void;
  closeFn: Dispatch<SetStateAction<boolean>>;
};

export const WorkTimeForm = ({ site, fetchSheet, closeFn }: TProps) => {
  const methods = useForm({ mode: "onChange" });
  const dirtyFields = Object.keys(methods.formState.dirtyFields);
  const errors = Object.keys(methods.formState.errors);
  const disabled =
    !!errors.length ||
    !dirtyFields.length ||
    !dirtyFields?.map((el) => methods.watch(el) === site[el]).includes(false);

  const updateSite = async (e) => {
    const payload = [
      {
        ...site,
        ...e,
      },
    ];
    const { response } = await fetchRequest(minMaxApi.updateMinMaxSheet(payload), {
      request: "Обновление рабочего времени",
    });
    if (response) {
      closeFn(false);
      await fetchSheet();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(updateSite)} className="flex flex-col gap-8">
        <div className="font-semibold">{site.site_name}</div>
        <div className="flex flex-col gap-4 py-8 border-t border-lightGray text-sm text-lightFont">
          <Field
            title="START"
            name="work_time_start"
            defaultValue={site?.work_time_start}
            required
            placeholder={"11:00"}
          />
          <Field
            title="END"
            name="work_time_end"
            defaultValue={site?.work_time_end}
            required
            placeholder={"21:00"}
          />
        </div>
        <button
          className={`${
            disabled ? "opacity-30" : ""
          } self-end rounded-sm font-semibold text-lightFont border border-lightGray bg-stale px-[16px] pb-2`}
          disabled={disabled}>
          Сохранить
        </button>
      </form>
    </FormProvider>
  );
};
