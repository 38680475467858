import { ITimeSegment } from "src/types/OnOffTimeline/common";

export const areSegmentsEqual = (segments1: ITimeSegment[], segments2: ITimeSegment[]): boolean => {
  if (segments1.length !== segments2.length) {
    return false;
  }

  for (let i = 0; i < segments1.length; i++) {
    if (segments1[i].start !== segments2[i].start || segments1[i].end !== segments2[i].end) {
      return false;
    }
  }

  return true;
};
