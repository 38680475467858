import { IS_DEV } from "src/api/constants";

interface ErrorFallbackProps {
  errorMessage: string;
  showErrorMessage?: boolean;
  resetError: () => void;
}

export const ErrorFallback = ({
  errorMessage,
  showErrorMessage,
  resetError,
}: ErrorFallbackProps) => {
  return (
    <div className="flex flex-col items-center bg-white w-fit py-[30px] px-[50px] rounded-xl mx-auto">
      <h1 className="font-bold text-3xl mb-[10px]">Упс! Что-то пошло не так</h1>
      <p className="text-grayDark mb-[16px] text-[18px]">Мы уже работаем над проблемой</p>
      {(IS_DEV || showErrorMessage) && (
        <div className="text-grayDark mb-[16px] text-[18px]">
          <p>Текст ошибки:</p>
          <p className="text-center">{errorMessage}</p>
        </div>
      )}
      <button
        onClick={resetError}
        className="hover:bg-gray/10 border border-gray/20 rounded-md transition ml-[6px] px-[8px] py-[4px] flex items-center justify-center cursor-pointer w-fit">
        Попробовать еще раз
      </button>
    </div>
  );
};
