import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllCities, selectAllCurrencies, selectAllSites } from "src/store/directionsReducer";
import {
  TAutocorrError,
  TBlacklistError,
  TTimelineErrors,
  TUncontrolledError,
} from "src/store/clusterReducer";
import { ErrorTab } from "./ErrorTab";
import { ErrorRow } from "./ErrorRow";
import arrow from "src/assets/images/arrow.svg";

type TProps = {
  errors: TTimelineErrors;
  setIsAdded: Dispatch<SetStateAction<string>>;
  setVisibleCities: Dispatch<SetStateAction<Array<number>>>;
  visibleVectors: Array<string>;
  setVisibleVectors: Dispatch<SetStateAction<Array<string>>>;
  isSitesMenuOpen: boolean;
};

export const ErrorsBlock = ({
  errors,
  isSitesMenuOpen,
  setIsAdded,
  setVisibleCities,
  visibleVectors,
  setVisibleVectors,
}: TProps) => {
  const globalSites = useSelector(selectAllSites);
  const globalCities = useSelector(selectAllCities);
  const globalCurrencies = useSelector(selectAllCurrencies);
  const [activeTab, setActiveTab] = useState("");
  const [opened, setOpened] = useState(false);

  const baseAction = (item) => {
    const isVectorVisible = visibleVectors?.includes(`${item?.city_id}_${item?.currency_from_id}`);
    const elem = document.getElementById(`${item.city_id}_city`);
    setVisibleCities([item?.city_id]);
    if (!isVectorVisible) {
      setVisibleVectors([...visibleVectors, `${item?.city_id}_${item?.currency_from_id}`]);
    }
    !!elem && elem?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  const errorTabs = useMemo(
    () => ({
      site_empty: {
        title: "НЕКОНТРОЛИРУЕМЫЕ САЙТЫ",
        text: "не выставлен ни в одном инструменте контроля курсов",
        icon: `\u25CF`,
        color: "#E7444E",
        onClick: (item: TUncontrolledError) => {
          setIsAdded(item?.site_id?.toString());
          baseAction(item);
        },
      },
      autocorr: {
        title: "АВТОКОР",
        text: "стоит на таймлайне и блокируется автокором",
        icon: `\u25B2`,
        color: "#42B57E",
        onClick: (item: TAutocorrError) => {
          setIsAdded("");
          baseAction(item);
        },
      },
      blacklists: {
        title: "BLACKLIST",
        text: "На векторе присутствуют блеклисты",
        icon: `\u25A0`,
        color: "#282c34",
        onClick: (item: TBlacklistError) => {
          setIsAdded("");
          baseAction(item);
        },
      },
    }),
    []
  );

  return (
    <div
      className={`fixed bottom-[0] duration-300 ${
        !isSitesMenuOpen ? "w-[calc(100%-20px)]" : "w-[calc(100%-200px)]"
      } pr-[50px] md:pr-[80px] z-20`}>
      <div className="border-t-4 rounded-sm border-lightFont">
        <div className="flex flex-col bg-light border border-stale rounded-sm pt-8 pb-[16px]">
          <div className="flex justify-end items-center text-xs px-4">
            <button
              type="button"
              className={`flex items-center w-full justify-end relative h-[20px] mr-8`}
              onClick={() => setOpened(!opened)}>
              <div className={`duration-300 ${!opened ? "rotate-180" : ""}`}>
                <img src={arrow} alt="раскрыть" width={12} height={12} />
              </div>
            </button>
            {Object.entries(errorTabs)?.map((el) => (
              <ErrorTab
                key={el[0]}
                item={el}
                count={errors[el[0]]?.length}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            ))}
          </div>
          <div
            className={`flex flex-col gap-2 overflow-y-auto duration-300 ${
              opened ? "max-h-[20vh]" : "max-h-[0]"
            }`}>
            {Object.entries(errors)
              ?.filter((el) => !activeTab?.length || activeTab === el[0])
              ?.map((el) => (
                <div key={el[0]} className={"flex flex-col gap-2"}>
                  {el[1]?.map((error, index) => (
                    <ErrorRow
                      key={`${el[0]}_${index}`}
                      type={el[0]}
                      item={error}
                      tab={errorTabs[el[0]]}
                      globalCities={globalCities}
                      globalCurrencies={globalCurrencies}
                      globalSites={globalSites}
                    />
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
