import { EQuestion, ESectionType, TAnswer, TQuestion } from "../../types";
import { questionTypesOptions } from "../../constants";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectQuestions, setQuestions } from "src/store/academyReducer";
import { setNotific } from "src/store/mainReducer";
import { useAppDispatch } from "src/store/store";
import { Button } from "src/shadcn/ui/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/shadcn/ui/ui/select";
import { ImageUploader } from "src/components/FormElements/ImageUploader";
import {
  AssetClient,
  ContentType,
  getAssetFromFile,
  getVideoDuration,
} from "src/pages/Requests/components/Request";
import { MdEditor } from "../../CreateTest/MdEditor";
import { EditAnswer } from "../Answer/EditAnswer";
import bin from "src/assets/images/bin.svg";
import { BASE_URL } from "src/api/constants";
import { Answer } from "../Answer/Answer";
import { Popup } from "src/components/Popup/Popup";

export const EditQuestion = ({
  question,
  setIsEdit,
  attachments,
  setAttachments,
}: {
  question?: TQuestion;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  attachments?: AssetClient[];
  setAttachments?: Dispatch<SetStateAction<AssetClient[]>>;
}) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const questions = useSelector(selectQuestions);
  const block_id = searchParams.get("block_id");
  const module_type = searchParams.get("type");
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [name, setName] = useState(question?.question_title || "");
  const [type, setType] = useState<EQuestion | null>(question?.question_type || EQuestion.NONE);
  const [content, setContent] = useState(question?.question_content || "");
  const [answers, setAnswers] = useState<TAnswer[]>(question?.question_answers || []);
  const [explanation, setExplanation] = useState<string | null>(
    module_type === ESectionType.THEORY ? null : question?.question_explanation || ""
  );
  const [isDeleteConfirmPopup, setIsDeleteConfirmPopup] = useState(false);
  const correctAnswerLength = answers?.filter((el) => el.answer_is_correct)?.length || 0;
  const singleAnswerError = type === EQuestion.RADIO && correctAnswerLength > 1;
  const question_attr_name = module_type === ESectionType.THEORY ? "пункт" : "вопрос";

  const assetsModified =
    attachments?.length !== question?.question_attachments?.length ||
    attachments?.some((asset) => typeof asset.id === "string");

  const notModified = useMemo(() => {
    return (
      name === question?.question_title &&
      content === question?.question_content &&
      type === question?.question_type &&
      JSON.stringify(answers) === JSON.stringify(question?.question_answers) &&
      explanation === question?.question_explanation
    );
  }, [name, content, type, answers, explanation]);

  const requiredFieldsError = [
    {
      field_name: "название",
      value: !name?.length,
    },
    {
      field_name: "пояснение ответа",
      value: module_type !== ESectionType.THEORY && !explanation?.length,
    },
    {
      field_name: "тип вопроса",
      value: module_type !== ESectionType.THEORY && type === EQuestion.NONE,
    },
  ]?.filter((el) => el.value === true);

  const disabled =
    isLoading ||
    (module_type !== ESectionType.THEORY && !correctAnswerLength) ||
    notModified ||
    !!requiredFieldsError?.length ||
    singleAnswerError;

  const handleUpload = async (e) => {
    e.preventDefault();

    const { files } = e.target;
    if (files?.length && !!setAttachments) {
      const newAssets = await Promise.all(Object.values(files).map(getAssetFromFile));
      setAttachments((prev) => {
        return [
          ...prev.filter((item) => !newAssets.some((asset) => asset.name === item.name)),
          ...newAssets,
        ];
      });
    }
  };

  const handlePaste = async (e) => {
    if (e?.clipboardData?.items?.length) {
      const file = e?.clipboardData?.items[0]?.getAsFile();
      if (!!file && !!setAttachments) {
        const newFile = await getAssetFromFile(file);
        setAttachments((prev) => [...prev, newFile]);
      }
    }
  };

  const uploadAssets = async () => {
    const videos = attachments?.filter((asset) => asset.content_type === ContentType.VIDEO);
    for (const vid of videos) {
      const duration = await getVideoDuration(vid.file);
      if (duration > 600) {
        dispatch(
          setNotific({
            type: "error",
            message: "Видео не должны быть длиннее 10 минут",
          })
        );
        return;
      }
    }
    const formData = new FormData();
    attachments
      ?.filter((el) => typeof el.id === "string")
      ?.forEach((asset) => {
        formData.append("files", asset.file);
      });
    const { response, error } = await fetchRequest(
      academyApi.addAttachment(question?.question_id, formData)
    );
    if (!response || error) {
      throw new Error("Ошибка при загрузке файлов на сервер, попробуйте снова");
    }
    return response as TQuestion;
  };

  const submit = async () => {
    setIsLoading(true);
    const payload: TQuestion = {
      block_id: +block_id,
      question_title: name,
      question_type: type,
      question_content: content,
      question_explanation: explanation,
      question_answers: answers,
      question_attachments: attachments?.map((el) => ({
        attachment_id: +el.id,
        attachment_type: el.content_type,
        attachment_url: el.path,
      })),
      question_order: question?.question_order || questions?.length,
    };
    if (!question) {
      const { response } = await fetchRequest(academyApi.createQuestion(payload), {
        request: `Создание ${question_attr_name}а`,
      });
      if (response) {
        dispatch(setQuestions([...questions, response]));
      }
    } else {
      const { response } = await fetchRequest(
        academyApi.updateQuestion(question?.question_id, {
          ...payload,
          question_id: question?.question_id,
        }),
        {
          request: `Обновление ${question_attr_name}а`,
        }
      );
      if (response) {
        dispatch(
          setQuestions(
            questions.map((el) =>
              el.question_id === question?.question_id
                ? {
                    ...el,
                    ...response,
                  }
                : el
            )
          )
        );
        dispatch(
          setNotific({
            type: "success",
            message: "Данные успешно обновлены",
            request: `Обновление ${question_attr_name}а`,
          })
        );
      }
    }
    setIsLoading(false);
    setIsEdit(false);
  };

  const deleteQuestion = async () => {
    if (!!question?.question_id) {
      const { response } = await fetchRequest(academyApi.deleteQuestion(question?.question_id), {
        request: `Удаление ${question_attr_name}а`,
      });
      if (response) {
        dispatch(setQuestions(questions?.filter((el) => el.question_id !== question?.question_id)));
      }
    } else {
      dispatch(
        setQuestions(questions?.filter((el) => el.question_title !== question?.question_title))
      );
    }
    setIsDeleteConfirmPopup(false);
  };

  const saveAnswer = ({
    id,
    isDelete,
    name,
    answer_is_correct,
  }: {
    id: number | string;
    isDelete?: boolean;
    name?: string;
    answer_is_correct?: boolean;
  }) => {
    if (isDelete) {
      setAnswers(answers.filter((el) => el.answer_id !== id));
    } else {
      const newAnswer: TAnswer = {
        answer_id: id,
        answer_title: name,
        answer_is_correct: answer_is_correct,
      };
      const isExistAnswer = !!answers?.find((el) => el.answer_id === id);
      if (isExistAnswer) {
        setAnswers(
          answers.map((el) =>
            el.answer_id === id
              ? {
                  ...el,
                  ...newAnswer,
                }
              : el
          )
        );
      } else {
        if (!!question?.question_id) {
          setAnswers([
            ...answers,
            {
              ...newAnswer,
              question_id: question?.question_id,
            },
          ]);
        } else {
          setAnswers([...answers, newAnswer]);
        }
      }
    }
  };

  return (
    <div className="relative flex flex-col gap-8 items-stretch w-full text-sm pt-4">
      <div className="absolute top-0 right-0 flex gap-4">
        <Button
          variant="ghost"
          className="h-7 w-7 text-slate-500 bg-rose-200"
          onClick={() => setIsDeleteConfirmPopup(true)}>
          <img src={bin} alt="удалить" width={15} height={15} />
        </Button>
        <Button
          variant="ghost"
          className="h-7 w-7 text-slate-500 bg-white"
          onClick={() => setIsEdit(false)}>
          {`\u2716`}
        </Button>
      </div>
      <div className="text-slate-400">Название {question_attr_name}а</div>
      <input
        className="border border-input rounded-sm py-8 px-3"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {module_type !== ESectionType.THEORY && (
        <>
          <div className="text-slate-400">Тип {question_attr_name}а</div>
          <Select value={type} onValueChange={(e: EQuestion) => setType(e)}>
            <SelectTrigger>
              <SelectValue placeholder={`Тип ${question_attr_name}а`} />
            </SelectTrigger>
            <SelectContent position="popper">
              {questionTypesOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </>
      )}
      <MdEditor text={content} setText={setContent} />
      {type !== EQuestion.NONE && (
        <>
          {module_type !== ESectionType.THEORY && (
            <div className="flex flex-col gap-8 lg:grid grid-cols-2">
              {answers?.map((el, index) => {
                const key = !!el.answer_id ? el.answer_id : `${index}_new_answer`;

                return (
                  <Answer
                    key={key}
                    answer={el}
                    name={`${question?.question_id}` || "new_question"}
                    type={type}
                    isEditPage
                    isEditQuestion
                    saveAnswer={saveAnswer}
                  />
                );
              })}
            </div>
          )}
          {!isCreate ? (
            <button
              className="relative w-[280px] sm:w-[360px] bg-slate-50 shadow-md rounded-md flex justify-center items-center duration-300 hover:scale-105 hover:shadow-lg py-8 font-bold text-blue"
              onClick={() => setIsCreate(true)}>
              Добавить ответ
            </button>
          ) : (
            <div className="relative w-[280px] sm:w-[360px] bg-slate-50 shadow-md rounded-md flex justify-center items-center p-5">
              <EditAnswer question_type={type} setIsEdit={setIsCreate} saveAnswer={saveAnswer} />
            </div>
          )}
          {!!requiredFieldsError?.length && (
            <div className="text-rose-500 text-xs">
              Не заполнены обязательные поля:{" "}
              {requiredFieldsError?.map((el) => (
                <span key={el.field_name}>{el.field_name}</span>
              ))}
            </div>
          )}
          {singleAnswerError && (
            <small className="text-rose-500">Верный ответ должен быть только один</small>
          )}
          {correctAnswerLength === 0 && (
            <small className="text-rose-500">Укажите корректный ответ</small>
          )}
          <div className="text-slate-400">Объясните ответ</div>
          <textarea
            className="border border-input rounded-sm py-8 px-3"
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
          />
        </>
      )}
      {!!setAttachments ? (
        <div className="flex gap-4 my-[16px] pr-[18px]">
          <ImageUploader
            handleUpload={handleUpload}
            handlePaste={handlePaste}
            filesLength={attachments?.length || 0}
          />
          <Button
            className="w-fit px-5 bg-emerald-500 text-white duration-300 hover:bg-emerald-400 disabled:bg-slate-200 disabled:text-font"
            size="sm"
            variant="outline"
            disabled={!assetsModified}
            onClick={async () => {
              const questionResponse = await uploadAssets();
              setAttachments(
                attachments?.map((el: AssetClient) => {
                  const currentAttachment = questionResponse.question_attachments?.find((resEl) =>
                    resEl.attachment_url
                      ?.replaceAll(" ", "")
                      ?.includes(el.name?.replaceAll(" ", ""))
                  );
                  if (!!currentAttachment) {
                    return {
                      id: currentAttachment.attachment_id,
                      path: el.path,
                      content_type: el.content_type,
                      url: (BASE_URL + currentAttachment.attachment_url).replace("api/", ""),
                    };
                  } else {
                    return el;
                  }
                })
              );
            }}>
            Сохранить вложения
          </Button>
        </div>
      ) : (
        <div className="text-slate-400">
          Чтобы добавить изображения первично сохраните {question_attr_name}
        </div>
      )}
      <Button className="px-5 mt-8 self-end bg-emerald-500" disabled={disabled} onClick={submit}>
        Сохранить
      </Button>
      {isDeleteConfirmPopup && (
        <Popup closeModal={() => setIsDeleteConfirmPopup(false)}>
          <div className="flex flex-col gap-7 p-3">
            <div>
              Вы уверены что хотите <b>удалить</b> {question_attr_name}?
            </div>
            <Button className="bg-rose-500" onClick={deleteQuestion}>
              Удалить {question_attr_name}
            </Button>
          </div>
        </Popup>
      )}
    </div>
  );
};
