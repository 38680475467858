import { Dispatch, SetStateAction, useState } from "react";
import { AxiosResponse } from "axios";
import { NestedObjects } from "../components/routes";
import controlApi from "src/api/control";
import {
  TCoin,
  TNetwork,
  TCondition,
  TTrack,
  TTrackNotification,
  TWallet,
} from "src/store/commonReducer";
import { Popup } from "src/components/Popup/Popup";
import { FieldRow } from "src/components/FormElements/FieldRow";
import { FieldTabs } from "src/components/FormElements/FieldTabs";
import { EMethod } from "./Wallets";

type TProps = {
  item: TCoin;
  baseRequest: (data: {
    method: Promise<AxiosResponse>;
    methodType: EMethod;
    title: string;
    name: string;
    setter:
      | Dispatch<SetStateAction<TTrackNotification[] | TTrack[] | TCoin[] | TWallet[]>>
      | ((res: NestedObjects<TCondition | TNetwork>) => void);
  }) => void;
  networkColor: (TNetwork) => string;
  isLoading: boolean;
  setCoins: Dispatch<SetStateAction<TCoin[]>>;
  networks: TNetwork[];
};

export const Coin = ({
  item,
  baseRequest,
  isLoading,
  networkColor,
  networks,
  setCoins,
}: TProps) => {
  const [isPopup, setPopup] = useState(false);
  const [coin, setCoin] = useState(item);
  const disabled =
    isLoading ||
    !coin.coin_name.length ||
    (coin.coin_name === item.coin_name &&
      coin.coin_network === item.coin_network &&
      coin.coin_address === item.coin_address);

  return (
    <div
      style={{ backgroundColor: `${networkColor(item.coin_network)}55` }}
      className={`relative flex flex-col w-full rounded-lg duration-700 shadow-smooth-out py-[10px] px-[20px] text-left text-xs`}>
      <button
        type="button"
        className={`absolute -top-[6px] -right-4 flex items-center justify-center h-[16px] w-[16px] bg-bg rounded-full shadow-sm text-[10px] text-lightFont`}
        onClick={() => setPopup(true)}>
        {`\u2715`}
      </button>
      <FieldRow
        label="название"
        value={item.coin_name}
        defaultValue={item.coin_name}
        edited
        placeholder="Назовите монету"
        updFn={(fieldValue: string) => {
          setCoin({
            ...coin,
            coin_name: fieldValue,
          });
        }}
      />
      <FieldTabs
        label="сеть"
        placeholder="Выберите сеть"
        arr={networks}
        value={coin?.coin_network}
        edited
        updFn={(fieldValue) => {
          setCoin({
            ...coin,
            coin_network: networks?.find((el) => el === fieldValue) || "btc",
          });
        }}
      />
      {"coin_address" in item && (
        <FieldRow
          label="адрес"
          value={item.coin_address}
          defaultValue={item.coin_address}
          edited
          placeholder="Введите адрес для токена"
          updFn={(fieldValue: string) => {
            setCoin({
              ...coin,
              coin_address: fieldValue,
            });
          }}
        />
      )}
      {!disabled && (
        <button
          className={`bg-light self-end px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300`}
          onClick={async () => {
            await baseRequest({
              method: controlApi.updateCoin(coin),
              methodType: EMethod.UPDATE,
              title: "Обновление монеты",
              name: "Coin",
              setter: setCoins,
            });
          }}>
          Обновить
        </button>
      )}
      {isPopup && (
        <Popup closeModal={() => setPopup(false)}>
          <div className="text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nмонету `}
              <b>{item?.coin_name}</b>?
            </div>
            <button
              type="button"
              className="prime-button-sm bg-font"
              disabled={isLoading}
              onClick={async () => {
                await baseRequest({
                  method: controlApi.deleteCoin(item?.coin_id),
                  methodType: EMethod.DELETE,
                  title: "Удаление монеты",
                  name: "Coin",
                  setter: setCoins,
                });
                setPopup(false);
              }}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
