import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllCountries } from "src/store/directionsReducer";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { ItemRow } from "src/components/Rows/ItemRow";

type TProps = {
  access: boolean;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const Countries = ({ access }: TProps) => {
  const [isGood, setIsGood] = useState(true);
  const countries = useSelector(selectAllCountries);

  useEffect(() => {
    const timer = setTimeout(() => setIsGood(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full sm:w-[600px] duration-300 flex flex-col gap-[10px] font-light pl-[20px] pb-[100px]">
      {countries?.map((el) => (
        <ItemRow key={el?.name_ru} title={el?.name_ru} access={access} />
      ))}
      {!isGood && !countries?.length && <Emptiness message={"Ошибка сервиса"} />}
    </div>
  );
};
