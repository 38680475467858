import { SourcesServicesForm } from "./Form";

export const SourcesServices = ({ sources, setSources, services, setServices }) => {
  return (
    <div className="w-full flex flex-col md:flex-row justify-center gap-[20px] ml-[20px] mr-[50px] md:mx-auto text-left font-light">
      <SourcesServicesForm items={sources} setFn={setSources} attr="Sources" />
      <SourcesServicesForm items={services} setFn={setServices} attr="Services" />
    </div>
  );
};
