import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Url } from "src/api/constants";
import { Popup } from "src/components/Popup/Popup";
import { selectOnOffConfirmation } from "src/store/onOffTimelineReducer/selectors";
import { setOnOffConfirmation } from "src/store/onOffTimelineReducer/slice";

export const OnOffConfirmPopup = () => {
  const dispatch = useDispatch();
  const confirmation = useSelector(selectOnOffConfirmation);

  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    setIsLoading(true);

    try {
      await confirmation.cb();
    } catch (error) {
      console.log(error);
    }

    dispatch(setOnOffConfirmation(null));

    setIsLoading(false);
  };

  if (!confirmation) {
    return null;
  }

  const { title, subtitle, content } = confirmation;

  return (
    <Popup closeModal={() => dispatch(setOnOffConfirmation(null))}>
      <div className="text-xs flex flex-col gap-8 items-center max-w-[300px]">
        <div className="text-center">{title}</div>
        {subtitle && (
          <div className="flex flex-wrap gap-4 text-center">
            <span className="font-bold">{subtitle}</span>
          </div>
        )}
        {content}
        <button className="prime-button-sm rounded-sm mt-8" disabled={isLoading} onClick={submit}>
          Подтвердить
        </button>
      </div>
    </Popup>
  );
};

export const DeleteConfirmText = ({ text }: { text: string }) => (
  <p className="text-center">
    <span>{text}</span>{" "}
    <NavLink to={Url.ONOFF} className="text-blue hover:underline">
      Вкл/Выкл
    </NavLink>
  </p>
);
