import { RadioOption } from "src/components/FormElements/RadioButtons/RadioButtons";
import { cn } from "src/helpers/cn";
import Checked from "src/assets/images/radio-button-checked.svg";
import Unchecked from "src/assets/images/radio-button-unchecked.svg";

interface RadioButtonsOptionProps<T> {
  option: RadioOption<T>;
  onChange: (value: T) => void;
  isSelected: boolean;
  readonly: boolean;
  descriptionClassname?: string;
  buttonClassname?: string;
  onDisabledOptionMouseEnter?: () => void;
  onDisabledOptionMouseLeave?: () => void;
}

export const RadioButtonsOption = <T,>({
  option: { label, value, description, disabled, disabledReason, disabledReasonCustomComponent },
  readonly,
  onChange,
  isSelected,
  descriptionClassname,
  buttonClassname,
  onDisabledOptionMouseEnter,
  onDisabledOptionMouseLeave,
}: RadioButtonsOptionProps<T>) => {
  return (
    <div
      onMouseEnter={disabled ? onDisabledOptionMouseEnter : undefined}
      onMouseLeave={disabled ? onDisabledOptionMouseLeave : undefined}
      onClick={!disabled ? () => onChange(value) : undefined}
      className={cn(
        `group flex flex-col gap-[2px] cursor-pointer p-[10px] border-[0.5px] transition border-gray/60 rounded-md relative ${
          isSelected ? "bg-[#EFF4FF] border-[#004DE6]" : "hover:bg-gray/10"
        } ${buttonClassname || ""} ${readonly ? "pointer-events-none opacity-60" : ""}`,
        disabled && " border-gray/20"
      )}>
      <div className="rounded-sm pl-[1px] text-[16px] font-medium flex items-center gap-[10px]">
        <span className={cn(disabled && "opacity-30")}>{label}</span>
      </div>
      {description && (
        <div
          className={cn(
            "rounded-sm pl-[1px] text-[14px] font-light text-grayDark",
            descriptionClassname,
            disabled && "opacity-30"
          )}>
          {description}
        </div>
      )}
      {disabled && (disabledReason || disabledReasonCustomComponent) && (
        <div
          className={cn(
            "flex items-center gap-[8px] max-h-[0] opacity-0 overflow-hidden duration-300 scale-90",
            "group-hover:max-h-[40px] mt-[6px] group-hover:opacity-100 group-hover:scale-100"
          )}>
          <p className="mt-[2px] bg-orange text-white flex items-center justify-center rounded-sm font-bold text-16px leading-[0] h-[16px] w-[16px] shrink-0">
            !
          </p>
          {disabledReasonCustomComponent || (
            <p className="text-[14px] leading-[14px] text-grayDarka">{disabledReason}</p>
          )}
        </div>
      )}
      <img
        className={cn("absolute right-[9px] top-[9px] w-[18px] h-[18px]", disabled && "opacity-30")}
        src={isSelected ? Checked : Unchecked}
      />
    </div>
  );
};
