import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TCurrencyTypes, TDirectionSelect, TVectorType } from "../../types";
import { TabButton } from "../TabButton";

type TProps = {
  currencyType: TVectorType;
  setCurrencyType: Dispatch<SetStateAction<TVectorType>>;
  setVector: Dispatch<SetStateAction<TDirectionSelect | null>>;
  setSelectedVectors: Dispatch<SetStateAction<Array<TDirectionSelect>>>;
};

export const CurrencyTypes = ({
  currencyType,
  setCurrencyType,
  setVector,
  setSelectedVectors,
}: TProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditType, setEditType] = useState(false);

  const currencyTypes = [
    {
      currency_from_type: "crypto",
      currency_to_type: "crypto",
    },
    {
      currency_from_type: "cash",
      currency_to_type: "crypto",
    },
    {
      currency_from_type: "card",
      currency_to_type: "crypto",
    },
    {
      currency_from_type: "cash",
      currency_to_type: "card",
    },
    {
      currency_from_type: "card",
      currency_to_type: "cash",
    },
    {
      currency_from_type: "crypto",
      currency_to_type: "cash",
    },
    {
      currency_from_type: "crypto",
      currency_to_type: "card",
    },
  ] as Array<TVectorType>;

  const fromTypes = useMemo(
    () => [...new Set(currencyTypes?.map((el) => el.currency_from_type))],
    []
  );
  const toTypes = useMemo(
    () =>
      currencyTypes
        ?.filter((el) => el.currency_from_type === currencyType.currency_from_type)
        ?.map((el) => el.currency_to_type),
    [currencyType.currency_from_type]
  );

  useEffect(() => {
    let from = null as TCurrencyTypes | null;
    let to = null as TCurrencyTypes | null;
    if (!!searchParams?.get("from_type")) {
      from = searchParams?.get("from_type") as TCurrencyTypes;
    }
    if (!!searchParams?.get("to_type")) {
      to = searchParams?.get("to_type") as TCurrencyTypes;
    }
    setCurrencyType((prevState) => ({
      currency_from_type: !!from ? from : prevState.currency_from_type,
      currency_to_type: !!to ? to : prevState.currency_to_type,
    }));
  }, []);

  return (
    <div
      className="grid grid-cols-2 gap-4 -my-8 p-4 border border-stale font-semibold rounded-sm leading-3 duration-300"
      onMouseOver={() => setEditType(true)}
      onMouseLeave={() => setEditType(false)}>
      <div className="flex flex-col duration-300">
        {fromTypes?.map((el: TCurrencyTypes) => (
          <TabButton
            key={`from_${el}`}
            title={el?.toUpperCase()}
            active={currencyType?.currency_from_type === el}
            disabled={!isEditType}
            setFn={() => {
              searchParams.set("from_type", el);
              setSearchParams(searchParams);
              const suitableToTypes = currencyTypes
                ?.filter((curType) => curType.currency_from_type === el)
                ?.map((curType) => curType.currency_to_type);
              const suitableToType = suitableToTypes?.includes(currencyType.currency_to_type)
                ? currencyType.currency_to_type
                : suitableToTypes[0];
              if (suitableToType !== currencyType.currency_to_type) {
                searchParams.set("to_type", suitableToType);
                setSearchParams(searchParams);
              }
              setCurrencyType({
                currency_from_type: el,
                currency_to_type: suitableToType,
              });
              setVector(null);
              setSelectedVectors([]);
            }}
          />
        ))}
      </div>
      <div className="flex flex-col duration-300">
        {toTypes?.map((el: TCurrencyTypes) => (
          <TabButton
            key={`to_${el}`}
            title={el?.toUpperCase()}
            disabled={!isEditType}
            active={currencyType?.currency_to_type === el}
            setFn={() => {
              searchParams.set("to_type", el);
              setSearchParams(searchParams);
              setCurrencyType({ ...currencyType, currency_to_type: el });
            }}
          />
        ))}
      </div>
    </div>
  );
};
