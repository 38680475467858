import { Download, Eye } from "lucide-react";
import { AssetClient } from "src/pages/Requests/components/Request";
import { cn } from "src/utils";
import FileIcon from "src/assets/images/file.svg";

const downloadFile = (asset: AssetClient) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = asset.path;
  downloadLink.download = asset.name;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};

interface FilePreviewProps {
  asset: AssetClient;
}

export const FilePreview = ({ asset }: FilePreviewProps) => {
  const fileNameSplit = asset.name?.split(".") || [];
  const n = fileNameSplit.length;
  const isPdf = asset.name?.split(".")[n - 1] === "pdf";

  const handleViewPdf = () => {
    const newTab = window.open();
    if (newTab) {
      newTab.document.write(
        `<iframe width="100%" height="100%" src="${asset.path}" frameborder="0" allowfullscreen></iframe>`
      );
    } else {
      alert("Unable to open new tab. Please allow pop-ups for this site.");
    }
  };
  const handleDownload = () => downloadFile(asset);

  return (
    <div
      className={cn(
        "flex flex-col border border-grayDark/20 rounded-md w-[70px] aspect-square items-center justify-between py-[5px] group",
        isPdf && "cursor-pointer hover:border-[#40CEF0] duration-300 "
      )}>
      <div className="absolute inset-0 bg-grayDark/50 rounded-md flex items-center justify-center duration-300 group-hover:opacity-100 opacity-0">
        <div className="flex items-center gap-[4px]">
          {isPdf && (
            <button
              type="button"
              onClick={handleViewPdf}
              className="text-white hover:text-[#40CEF0] duration-300">
              <Eye className="w-[14px] h-[14px]" />
            </button>
          )}
          <button
            type="button"
            onClick={handleDownload}
            className="text-white hover:text-[#40CEF0] duration-300">
            <Download className="w-[14px] h-[14px]" />
          </button>
        </div>
      </div>
      <img className="text-blue" width={40} height={40} src={FileIcon} />
      <p className="w-[90%] mx-auto text-center text-[9px] whitespace-nowrap overflow-hidden text-ellipsis">
        {asset.name}
      </p>
    </div>
  );
};
