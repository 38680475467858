import { useEffect, useMemo, useState } from "react";
import { Emptiness, GoodEmptiness } from "src/components/UI/loader/Emptiness";

export const Entropy = ({ entropy, isLoading, isGood }) => {
  const arr = Object.entries(entropy) as Array<Array<string>>;
  const [trigger, setTrigger] = useState(true);
  const [isShow, setShow] = useState(false);

  const strArr = [
    "Работают по одинаковым городам",
    "Работают по одинаковым направлениям",
    "Одновременно были на бесте по одинаковым направлениям",
    "Одновременно были одинаковые курсы по одинаковым направлениям",
    "Одновременно были в ТОП 3 по одинаковым направлениям",
    "Общий процент схожести сайтов",
  ];

  const calculateCoordinate = (count, r, cx, cy) => {
    const sectors = [];
    let startAngle = -90;
    for (let i = 0; i < count; i++) {
      if (i <= count - 1) {
        const angle = 360 / count;
        const rad = Math.PI / 180;
        const x = cx + r * Math.cos(startAngle * rad);
        const y = cy + r * Math.sin(startAngle * rad);
        startAngle += angle;

        sectors.push({ x, y });
      } else {
        const angle = 360 / (count - count);
        const rad = Math.PI / 180;
        const x = cx + r * 2 * Math.cos(startAngle * rad);
        const y = cy + r * 2 * Math.sin(startAngle * rad);
        startAngle += angle;

        sectors.push({ x, y });
      }
    }
    return sectors;
  };

  const coords = useMemo(() => {
    return (
      calculateCoordinate(arr?.length - 1, 150, 100, 120) ||
      (Array.from(Array(arr?.length - 1))?.map((_) => ({ x: 0, y: 0 })) as Array<{
        x: number;
        y: number;
      }>)
    );
  }, [entropy]);

  useEffect(() => {
    setTrigger(false);
    setTimeout(() => setTrigger(true), 500);
  }, [entropy]);

  return (
    <div className={`w-full ${isShow ? "h-[600px]" : "h-[20px]"} flex flex-col`}>
      <button
        type="button"
        className="relative -left-[50px] w-fit flex items-center gap-8 rounded-[4px] pl-[16px] pr-8 h-[24px] max-h-[24px] min-h-[24px] bg-font text-white hover:opacity-80 border border-lightGray duration-300"
        onClick={() => setShow(!isShow)}>
        {isShow ? "Скрыть" : "Показать"} энтропию
        <div className={`duration-300 ${isShow ? "rotate-180" : "rotate-0"}`}>{`\u25BC`}</div>
      </button>
      {isShow && (
        <div className="relative flex justify-center items-center top-[45%] translate-y-[-40%] flex flex-col items-center gap-[20px] h-[500px] w-[400px]">
          {arr?.map(
            (el, index) =>
              index !== arr?.length - 1 && (
                <div
                  key={el[0]}
                  className="absolute w-[150px]"
                  style={{ left: coords[index]?.x, top: coords[index]?.y }}>
                  <Diagram
                    item={el}
                    index={index}
                    trigger={trigger}
                    size={30}
                    name={strArr[index]}
                  />
                </div>
              )
          )}
          <div className="relative -top-[70px] -left-[25px] w-[150px]">
            <Diagram item={arr[5]} index={5} trigger={trigger} size={40} name={strArr[5]} />
          </div>
          {!isLoading && isGood !== null && !entropy && (
            <div className="w-full mx-auto absolute top-[50%] translate-y-[-50%]">
              {isGood ? (
                <GoodEmptiness message="Нет данных по выбранным параметрам" />
              ) : !isGood ? (
                <Emptiness message={"Ошибка сервиса"} />
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const Diagram = ({
  item,
  index,
  name,
  trigger,
  size,
  isGood = null,
}: {
  item: number[] | string[];
  index: number;
  name: string;
  trigger: boolean;
  size: number;
  isGood?: boolean | null;
}) => {
  const circumference = size * 2 * Math.PI;
  const width = `${size * 2 + 10}px`;
  const containerWidth = `${size * 2 + 20}px`;

  const offset = (el) => {
    if (trigger) {
      return +el[1] === 100 ? circumference * (1 - 99 / 100) : circumference * (1 - +el[1] / 100);
    } else return circumference;
  };

  return (
    <div className="flex flex-col gap-[16px] items-center h-fit text-lightFont">
      <div
        className={`flex items-center text-lightFont justify-center w-[${width}] h-[${width}] min-w-[${width}] min-h-[${width}] rounded-[50%] text-[10px] font-bold bg-bg shadow-inset`}>
        {item[1]}% {/* eslint-disable react/no-unknown-property */}
        <div
          x-data="scrollProgress"
          className="absolute inline-flex items-center justify-center overflow-hidden rounded-full">
          <svg className={`w-[${containerWidth}] h-[${containerWidth}]`}>
            <defs>
              <linearGradient id={`${index}_gradient`} x1="0%" y1="0%" x2="0%" y2="100%">
                <stop
                  id={`${index}_start`}
                  offset="0%"
                  stopColor={
                    (isGood === null ? +item[1] > 70 : isGood === false)
                      ? "#F37"
                      : (isGood === null ? +item[1] <= 50 : isGood === true)
                      ? "#3F7"
                      : "#FE0"
                  }
                />
                <stop
                  id={`${index}_end`}
                  offset="100%"
                  stopColor={
                    (isGood === null ? +item[1] > 70 : isGood === false)
                      ? "#F00"
                      : (isGood === null ? +item[1] <= 50 : isGood === true)
                      ? "#00bc9b"
                      : "#F7C571"
                  }
                />
              </linearGradient>
            </defs>
            <circle
              className="text-[#EEE]"
              strokeWidth="10"
              stroke="currentColor"
              fill="transparent"
              r={size}
              cx={size + 10}
              cy={size + 10}
            />
            <circle
              className={`duration-500 -rotate-90`}
              strokeWidth="10"
              strokeDasharray={circumference}
              strokeDashoffset={offset(item) || circumference}
              strokeLinecap="round"
              stroke={`url(#${index}_gradient)`}
              fill="transparent"
              r={size}
              cx={-(size + 10)}
              cy={size + 10}
            />
          </svg>
        </div>
      </div>
      <div>{name}</div>
    </div>
  );
};
