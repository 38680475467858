import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import controlApi from "src/api/control";
import { selectControlChats, setControlChats } from "src/store/commonReducer";
import { setNotific } from "src/store/mainReducer";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { CreateChat } from "./CreateChat";
import { Chat } from "./Chat";

export const Chats = () => {
  const dispatch = useDispatch();
  const chats = useSelector(selectControlChats);
  const [isGood, setIsGood] = useState<boolean>(true);

  const fetchChats = async () => {
    const { response, error, status } = await fetchRequest(controlApi.getRegulationsChats());
    if (response) {
      dispatch(setControlChats(response));
      setIsGood(true);
    }
    if (error && status !== 404) {
      dispatch(setNotific({ type: "error", message: error?.statusText, request: "Чаты" }));
      setIsGood(false);
    }
    if (status === 404) {
      setIsGood(true);
    }
  };

  return (
    <div className="md:min-w-[300px]">
      <CreateChat fetchChats={fetchChats} />
      <div className="flex flex-col gap-4 mt-8">
        {chats?.map((el) => (
          <Chat key={el.id} item={el} chats={chats} />
        ))}
      </div>
      {!isGood && <Emptiness message="Ошибка сервиса" />}
    </div>
  );
};
