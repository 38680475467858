import { SVG } from "src/assets/images/SVG";
import { TSelectedPair } from "src/store/onOffReducer";
import bin from "src/assets/images/bin.svg";
import { cn } from "src/utils";

interface SelectedPairCardProps {
  selectedPair: TSelectedPair;
  onDelete: () => void;
}

const SelectedPairCard = ({ selectedPair, onDelete }: SelectedPairCardProps) => {
  return (
    <div className="select_button bg-bg flex justify-between px-8 items-center gap-4 ml-4">
      <div className={cn("grid grid-cols-[80px_160px_1fr_1fr] gap-8 w-full")}>
        {!!selectedPair?.site ? (
          <div className="border-r pr-1 border-gray/30 whitespace-nowrap overflow-hidden text-ellipsis">
            {selectedPair.site}
          </div>
        ) : (
          <div />
        )}
        <div className="text-left whitespace-nowrap">
          {!!selectedPair?.city
            ? selectedPair?.city?.city_name
            : selectedPair?.from?.type === "card" || selectedPair?.to?.type === "card"
            ? "Авто"
            : ""}
        </div>
        <div className="grid grid-cols-[30px_1fr] justify-items-center items-center gap-4 hover:opacity-80 text-xs">
          <div>
            <SVG id={selectedPair?.from?.currency_name} />
          </div>
          <span className="w-full text-left">{selectedPair?.from?.currency_name}</span>
        </div>
        <div className="grid grid-cols-[30px_1fr] justify-items-center items-center gap-4 hover:opacity-80 text-xs">
          <div>
            <SVG id={selectedPair?.to?.currency_name} />
          </div>
          <span className="w-full text-left">{selectedPair?.to?.currency_name}</span>
        </div>
      </div>
      <button
        type="button"
        className={`flex justify-center items-center rounded-sm h-[28px] w-[28px] max-w-[28px] bg-bg duration-300 hover:shadow-inset`}
        onClick={onDelete}>
        <img src={bin} alt="удалить" width={16} height={16} />
      </button>
    </div>
  );
};

export default SelectedPairCard;
