import React, { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { MiniSpinner } from "src/components/Spinner/MiniSpinner";
import { Editor } from "./Editor";

export const Input: FC<{
  name: string;
  sum: number;
  citySum: number;
  clearFields: boolean;
  pending: boolean;
  isGeneral?: boolean;
  updateFields?: () => void;
  isAccess: boolean;
}> = ({ name, sum, citySum, pending, clearFields, isGeneral, updateFields, isAccess }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [input, setInput] = useState(false);
  const nameSubStr = name?.split(".");

  const isPending = pending && sum !== citySum;

  useEffect(() => {
    if (isAccess && sum !== citySum) {
      setInput(true);
    }
  }, [sum, citySum]);

  return (
    <div className="flex justify-center items-center relative sm:w-[170px]">
      {!input ? (
        <div
          className={`text w-full text-center ${isAccess && "cursor-pointer"} ${
            isPending ? "hidden" : "block"
          }`}
          onClick={() => {
            if (isAccess) {
              setInput(true);
            }
          }}>
          {citySum}
        </div>
      ) : (
        <input
          className={`text w-[95px] sm:w-[140px] border border-lightGray rounded outline-none h-[24px] sm:h-[30px] py-4 pl-8 sm:px-8 ${
            isPending ? "hidden" : "block"
          }`}
          type="number"
          min={0.000001}
          step={0.000001}
          disabled={!isAccess}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          defaultValue={sum}
          {...register(name, { required: true, minLength: 1 })}
        />
      )}
      {(errors && nameSubStr[1] === "min_sum"
        ? errors[`${nameSubStr[0]}.min_sum`]?.type === "required"
        : errors[`${nameSubStr[0]}.max_sum`]?.type === "required") && (
        <div className="text-red absolute top-[23px] w-full text-center">не заполнено</div>
      )}
      {isPending && <MiniSpinner />}
      {!isPending && input && (
        <Editor
          setInput={setInput}
          name={name}
          setValue={setValue}
          clearFields={clearFields}
          isGeneral={isGeneral}
          updateFields={updateFields}
          citySum={citySum}
        />
      )}
    </div>
  );
};
