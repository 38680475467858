import { createSlice } from "@reduxjs/toolkit";
import { StateT } from "./store";
import { TGlobalCurrency } from "./directionsReducer";
import { TCurrencyTypes } from "src/pages/Text/types";
import { ICity } from "src/types/OnOffTimeline/common";

const onOffSlice = createSlice({
  name: "onOff",
  initialState: {
    switchVectors: [] as Array<TSwitchVector>,
    blocks: [] as Array<TBlocks>,
    switchDataTL: null as null | TSwitchTL,
    editedEl: null as null | TEditedItemTL,
    actionsCount: 0,
  },
  reducers: {
    setSwitchVectors: (state, action) => {
      state.switchVectors = action.payload;
    },
    pushSwitchVectors: (state, action) => {
      state.switchVectors = [...state.switchVectors, ...action.payload];
    },
    updateSwitchVectors: (state, action) => {
      state.switchVectors = state.switchVectors?.map((el) => {
        const item = action.payload?.find(
          (vector: TSwitchVector) =>
            vector.site_id === el.site_id &&
            vector.vector?.join("-") === el.vector?.join("-") &&
            vector.city_code === el.city_code
        );
        if (item) {
          return (el = item);
        } else {
          return el;
        }
      });
    },
  },
});

export const { setSwitchVectors, pushSwitchVectors, updateSwitchVectors } = onOffSlice.actions;

export const selectSwitchVectors = (state: StateT): Array<TSwitchVector> =>
  state.onOff.switchVectors;

export default onOffSlice.reducer;

export type TSwitchVector = {
  site_id: number;
  vector: Array<string>; // BTC-to-CASHRUB
  city_code: string;
  vector_is_enabled: boolean;
  best_is_enabled: boolean;
  is_region: boolean;
};

export type TBlocks = {
  site_name: string;
  city_name: string;
  vector: string;
};

export enum ESource {
  SITE = "site",
  BEST = "bestchange",
}

export type TOffValues = {
  site: boolean;
  bestchange: boolean;
};

export type TSource = {
  title: string;
  color: string;
};

export type TSwitchSegment = {
  id?: number;
  start: string;
  end: string;
  creator?: string;
  action?: number;
  deleted_flag?: boolean;
};

export type TSwitchItemTL = {
  site_id: number;
  site_name: string;
  city_name: string | null;
  city_code: string | null;
  currency_from: string;
  currency_to: string;
  segments_best: TSwitchSegment[];
  segments_site: TSwitchSegment[];
  unsubmitted_segments_best?: TSwitchSegment[];
  unsubmitted_segments_site?: TSwitchSegment[];
  is_locked: boolean;
};

export enum Levels {
  CITY = "city",
  FROM = "from",
  TO = "to",
  SEGMENTS_SITE = "segments_site",
  SEGMENTS_BEST = "segments_best",
}

export type TSegmentsGroup = {
  segments_best: TSwitchSegment[];
  segments_site: TSwitchSegment[];
};

export type TEditedItemTL = {
  level?: Levels;
  city_code: string | null;
  currency_from?: string;
  currency_to?: string;
  segments_best?: TSwitchSegment[];
  segments_site?: TSwitchSegment[];
  unsubmitted_segments_best?: TSwitchSegment[];
  unsubmitted_segments_site?: TSwitchSegment[];
  is_locked?: boolean;
};

export type TSwitchToTL = {
  currency_name: string;
  is_locked?: boolean;
  segments_best: TSwitchSegment[];
  segments_site: TSwitchSegment[];
  unsubmitted_segments_best?: TSwitchSegment[];
  unsubmitted_segments_site?: TSwitchSegment[];
};

export type TSwitchVectorTL = {
  currency_from: string;
  currencies_to: TSwitchToTL[];
};

export type TSwitchDataTL = {
  city_id: number | null; // для сортировки
  city_name: string | null;
  city_code: string | null;
  vectors: TSwitchVectorTL[];
};

export type TSwitchTL = {
  site_id: number;
  data: TSwitchDataTL[];
};

export interface TPairs {
  from: TGlobalCurrency[];
  to: TGlobalCurrency[];
}

export type TLevels = {
  identifier: string;
  arr_attr: string;
};

export interface TSelectedPair {
  id: string;
  from: TGlobalCurrency;
  to: TGlobalCurrency;
  city: ICity | null;
  site?: string | null;
}

export type TAvailableDirectionsTypes = {
  from_type: TCurrencyTypes;
  to_type: TCurrencyTypes;
  available: boolean;
};

export const baseUnavailableDirections: TAvailableDirectionsTypes[] = [
  {
    from_type: "cash",
    to_type: "cash",
    available: false,
  },
  {
    from_type: "card",
    to_type: "card",
    available: false,
  },
  {
    from_type: "crypto",
    to_type: "crypto",
    available: false,
  },
  {
    from_type: "card",
    to_type: "cash",
    available: false,
  },
  {
    from_type: "cash",
    to_type: "card",
    available: false,
  },
  // cash-in
  {
    from_type: "cash-in",
    to_type: "cash-in",
    available: false,
  },
  {
    from_type: "cash-in",
    to_type: "card",
    available: false,
  },
  {
    from_type: "cash-in",
    to_type: "cash",
    available: false,
  },
  {
    from_type: "card",
    to_type: "cash-in",
    available: false,
  },
  {
    from_type: "cash",
    to_type: "cash-in",
    available: false,
  },
];
