import { ChangeEvent } from "react";

export const Checkbox = ({
  checked,
  onChange,
  disabled,
}: {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}) => {
  return (
    <label
      className={`checkmark-label shrink-0 ${checked ? "checked" : ""} ${
        disabled ? "cursor-default" : "cursor-pointer"
      }`}>
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={onChange}
        disabled={disabled}
        className={`absolute opacity-0 ${disabled ? "cursor-default" : "cursor-pointer"}`}
      />
    </label>
  );
};
