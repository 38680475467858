import ghost from "src/assets/images/ghost.svg";
import good from "src/assets/images/good.svg";

export const Emptiness = ({ message }) => {
  return (
    <div className="w-full flex flex-col gap-[20px] items-center sm:mt-[20px] text-sm text-lightGray text-center whitespace-pre">
      <img src={ghost} alt="пусто" width={250} height={300} />
      <div>{message}</div>
    </div>
  );
};

export const GoodEmptiness = ({ message }) => {
  return (
    <div className="w-full flex flex-col gap-[50px] items-center sm:mt-[40px] text-sm text-[#BABAC3] text-center whitespace-pre">
      <img src={good} alt="good" width={200} height={300} />
      <div>{message}</div>
    </div>
  );
};
