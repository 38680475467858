import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { cn } from "src/helpers/cn";
import { SiteMenuItemNew } from "src/pages/Panel/OnOff/OnOffTimeline/components/common/SiteMenuItem";
import { OnOffFiltersType } from "src/pages/Panel/OnOff/OnOffTimeline/components/header/TimelineFilters";
import {
  selectOnOffIsSitesMenuOpen,
  selectOnOffSites,
  selectOnOffSitesLoading,
} from "src/store/onOffTimelineReducer/selectors";
import { toggleOnOffIsSitesMenuOpen } from "src/store/onOffTimelineReducer/slice";

export const SitesList = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const isSitesMenuOpen = useSelector(selectOnOffIsSitesMenuOpen);
  const isLoading = useSelector(selectOnOffSitesLoading);
  const sites = useSelector(selectOnOffSites);

  const sitesFromParams = searchParams.get("sites")?.split(",");

  const [inited, setInited] = useState(false);

  const sitesOnBest = sites.filter((item) => item.on_best);
  const sitesNotOnBest = sites.filter((item) => !item.on_best);

  const toggleSearchParamsSite = (siteName: string) => {
    const sites = searchParams.get(OnOffFiltersType.SITES)?.split(",") || [];

    const existingSiteIndex = sites.findIndex((item) => item === siteName);

    if (~existingSiteIndex) {
      sites.splice(existingSiteIndex, 1);
    } else {
      sites.push(siteName);
    }

    if (sites.filter((item) => item.length > 0).length > 0) {
      searchParams.set(OnOffFiltersType.SITES, sites.join(","));
    } else {
      searchParams.delete(OnOffFiltersType.SITES);
    }

    setSearchParams(searchParams);
  };

  const toggleListCollapsed = () => {
    dispatch(toggleOnOffIsSitesMenuOpen());
    if (!inited) {
      setInited(true);
    }
  };

  return (
    <div className={cn("relative z-[11] ml-[15px]")}>
      <button
        type="button"
        className={`fixed top-[73px] left-[20px] bg-stale rounded-sm w-[18px] h-[18px] text-xs text-white z-30`}
        onClick={toggleListCollapsed}>
        <div
          className={`duration-300 ${
            isSitesMenuOpen ? "rotate-90" : "-rotate-90"
          }`}>{`\u25BC`}</div>
      </button>
      <div
        className={cn(
          "fixed",
          isSitesMenuOpen && "bg-bg rounded-md shadow-[0px_0px_30px_-10px_rgba(0,0,0,0.3)]"
        )}>
        {sites.length > 0 ? (
          <div
            className={cn(
              "flex flex-col gap-4 text-left pb-[100px] max-h-[90vh] overflow-hidden",
              isSitesMenuOpen ? "max-w-[160px] w-full p-[20px]" : "max-w-0 px-0",
              inited && "duration-300"
            )}>
            {sitesOnBest.length > 0 && (
              <div className="flex flex-col gap-[4px] mt-[10px]">
                <p className="text-grayDark pb-[2px] border-b border-gray/30 mb-[6px] whitespace-pre">
                  Есть на бесте
                </p>
                <div className="flex flex-col gap-[7px]">
                  {sitesOnBest.map((el) => {
                    const isSelected = !!sitesFromParams?.includes(el.site_name);

                    return (
                      <SiteMenuItemNew
                        key={`site_${el.site_name}`}
                        item={el}
                        isOnOffTimeline
                        isSelected={isSelected}
                        toggleSearchParamsSite={toggleSearchParamsSite}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {sitesNotOnBest.length > 0 && (
              <div className="flex flex-col gap-[4px] mt-[10px]">
                <p className="text-grayDark pb-[2px] border-b border-gray/30 mb-[6px] whitespace-pre">
                  Нет на бесте
                </p>
                <div className="flex flex-col gap-[7px]">
                  {sitesNotOnBest.map((el) => {
                    const isSelected = !!sitesFromParams?.includes(el.site_name);

                    return (
                      <SiteMenuItemNew
                        key={`site_${el.site_name}`}
                        item={el}
                        isOnOffTimeline
                        isSelected={isSelected}
                        toggleSearchParamsSite={toggleSearchParamsSite}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="p-[20px] w-[150px] text-xs">
            {isLoading ? "загрузка..." : "нет доступных сайтов"}
          </div>
        )}
      </div>
    </div>
  );
};
