import { Dispatch, SetStateAction, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import addApi from "src/api/add";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect, { createFilter } from "react-select";
import { convertToOptions, selectFilter } from "src/helpers/formHelpers";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectAllRoles, TStrSelect } from "src/store/directionsReducer";
import { setNotific } from "src/store/mainReducer";
import { TAddTab } from "src/pages/Add/Add";
import { LS } from "src/api/constants";

type TProps = {
  setActiveTab: Dispatch<SetStateAction<TAddTab>>;
};

export const CreateUser = ({ setActiveTab }: TProps) => {
  const dispatch = useDispatch();
  const userRoles = useSelector(selectAllRoles);
  const userRole = localStorage.getItem(LS.ROLE);
  const isAdmin = userRole === "admin" || userRole === "mini_admin";
  const options = useMemo(
    () =>
      convertToOptions(
        userRoles?.filter((el) =>
          isAdmin ? true : el !== "admin" && el !== "mini_admin" && el !== "courser_super_admin"
        )
      ),
    [userRoles, isAdmin]
  );
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState<{ label: string; value: string }>({ label: "", value: "" });
  const [QR, setQR] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const disabled = login?.length < 5 && password.length < 5 && role?.label?.length === 0;

  const setR = (e: TStrSelect) => setRole(e);

  const createUser = async () => {
    const data = {
      username: login.trim(),
      password: password.trim(),
      role_name: role?.label,
    };
    const { response } = await fetchRequest(addApi.addUser(data), {
      request: "Создание пользователя",
    });
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: `Пользователь ${login} успешно создан`,
          request: "Создание пользователя",
        })
      );
      setQR(response?.otp_key);
      setIsCreated(true);
    }
  };

  return (
    <div className="w-[300px]">
      <div className="table-header justify-center">НОВЫЙ ПОЛЬЗОВАТЕЛЬ</div>
      <div className="p-[20px] m-auto">
        {isCreated && (
          <div style={{ height: "auto", margin: "0 auto 30px auto", maxWidth: 300, width: "100%" }}>
            <QRCode
              size={200}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`otpauth://totp/${login}?secret=${QR}`}
              viewBox={`0 0 200 200`}
            />
          </div>
        )}
        <div className={`${isCreated && "flex justify-between"}`}>
          <div className="text text-left text-font">Логин</div>
          {isCreated ? (
            <div className="text text-left font-light text-font">{login}</div>
          ) : (
            <input
              onBlur={({ target }) => setLogin(target.value)}
              className="text-sm w-[260px] border border-lightGray rounded-[10px] outline-none h-[40px] py-4 px-8 my-[10px]"
            />
          )}
        </div>
        <div className={`${isCreated && "flex justify-between"}`}>
          <div className="text text-left text-font">Пароль</div>
          {isCreated ? (
            <div className="text text-left font-light text-font">{password}</div>
          ) : (
            <input
              onBlur={({ target }) => setPassword(target.value)}
              className="text-sm w-[260px] border border-lightGray rounded-[10px] outline-none h-[40px] py-4 px-8 my-[10px]"
            />
          )}
        </div>
        <div className={`${isCreated && "flex justify-between"}`}>
          <div className="text text-left text-font">Роль</div>
          {isCreated ? (
            <div className="text text-left font-light text-font">{role.label}</div>
          ) : (
            <ReactSelect
              className="text my-[10px] h-[40px] w-[260px]"
              classNamePrefix="Select"
              options={options}
              filterOption={createFilter(selectFilter)}
              placeholder={role?.label ? role.label : "Роль..."}
              value={role?.label}
              onChange={setR}
            />
          )}
        </div>
        {isCreated && (
          <div className="small-text">Не потеряйте QR-код, он генерируется только один раз</div>
        )}
        <div className="flex justify-center items-baseline mt-[40px]">
          {isCreated ? (
            <button onClick={() => setActiveTab("")} className="prime-button">
              Подтвердить
            </button>
          ) : (
            <button onClick={createUser} className="prime-button" disabled={disabled}>
              Добавить пользователя
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
