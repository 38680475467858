import { Dispatch, SetStateAction, useMemo, memo, useState } from "react";
import ReactSelect, { createFilter } from "react-select";
import { setTimelineByCentrifugo } from "src/api/centrifugo/timelineCentrifugo";
import { selectFilter } from "src/helpers/formHelpers";
import { TCurSelect, TGlobalCity } from "src/store/directionsReducer";
import { TTimeline, TTimelineDeletePayload } from "src/store/clusterReducer";
import { CreateButton } from "src/components/UI/buttons/CreateButton";
import { VectorLine } from "./VectorLine";
import arrow from "src/assets/images/arrow.svg";

type TProps = {
  item: TTimeline;
  items: Array<TTimeline>;
  globalCities: Array<TGlobalCity>;
  currenciesOptions: Array<TCurSelect>;
  isCopy: boolean;
  setCopiedEl: Dispatch<SetStateAction<TTimelineDeletePayload | null>>;
  isDelete: boolean;
  setDeletedEl: Dispatch<SetStateAction<TTimelineDeletePayload | null>>;
  visibleCities: Array<number>;
  setVisibleCities: Dispatch<SetStateAction<Array<number>>>;
  visibleVectors: Array<string>;
  setVisibleVectors: Dispatch<SetStateAction<Array<string>>>;
  activeTemplate: number | null;
  setTemplateData: Dispatch<SetStateAction<Array<TTimeline>>>;
  username: string;
  isSplitRight: boolean;
};

export const CityLine = memo(
  ({
    item,
    items,
    globalCities,
    currenciesOptions,
    username,
    setCopiedEl,
    setDeletedEl,
    isCopy,
    isDelete,
    activeTemplate,
    setTemplateData,
    visibleCities,
    setVisibleCities,
    visibleVectors,
    setVisibleVectors,
    isSplitRight,
  }: TProps) => {
    const [newFrom, setNewFrom] = useState<TCurSelect>();
    const [newTo, setNewTo] = useState<TCurSelect>();
    const [newPercent, setNewPercent] = useState<string>("0");
    const [isAddVector, setIsAddVector] = useState(false);
    const disabled = !newFrom || !newTo || !newPercent.length;
    const opened = useMemo(() => visibleCities?.includes(item.city_id), [visibleCities]);

    const toOptions = useMemo(() => {
      if (!!newFrom) {
        if (newFrom?.value.isCrypto) {
          return currenciesOptions?.filter((el) => !el.value.isCrypto);
        } else {
          return currenciesOptions?.filter((el) => el.value.isCrypto);
        }
      } else {
        return currenciesOptions;
      }
    }, [currenciesOptions, newFrom?.value.isCrypto]);

    const currentCity = useMemo(
      () => globalCities?.find((city) => city.city_id === item.city_id),
      [globalCities, item.city_id]
    );

    return (
      <div className={`min-h-[67px] relative -left-4`}>
        <div
          className={`w-full grid grid-cols-[10px_1fr] items-center gap-x-8 text-left h-[29px] text-[11px] bg-[#BABAC355] border-b border-glass-gray`}>
          <div
            className={`flex gap-8 items-center mx-2 border rounded-sm cursor-pointer whitespace-pre ${
              isDelete
                ? "w-fit border-red bg-[#D7443E11] hover:bg-[#D7443E33]"
                : isCopy
                ? "w-fit border-green bg-[#B2FFDE55] hover:bg-[#B2FFDE]"
                : "w-full border-[#FFFFFF00]"
            }`}
            onClick={() => {
              if (!isDelete && !isCopy) {
                if (opened) {
                  setVisibleCities(visibleCities?.filter((el) => el !== item?.city_id));
                } else {
                  setVisibleCities([...visibleCities, item?.city_id]);
                }
              }
              if (isCopy) {
                setCopiedEl({ city_id: item?.city_id });
              }
              if (isDelete) {
                setDeletedEl({ city_id: item?.city_id });
              }
            }}>
            <div
              className={`${isDelete || isCopy ? "hidden" : "block"} relative duration-300 ${
                opened ? "rotate-180" : ""
              } w-[10px] h-[10px] min-w-[10px] ml-[3px]`}>
              <img src={arrow} alt="раскрыть" width={10} height={10} />
            </div>
            <div>{currentCity?.city_name}</div>
          </div>
        </div>
        <div className="w-full flex flex-col">
          {opened &&
            item?.vectors?.map((el, index) => {
              const key = `${item.city_id}_${el.currency_from_id}_${el.currency_to_id}_${el.percent}_${index}`;
              return (
                <VectorLine
                  key={key}
                  vectorIndex={index}
                  vectors={item?.vectors}
                  isDelete={isDelete}
                  items={items}
                  opened={opened}
                  isCopy={isCopy}
                  username={username}
                  isNotFirstFrom={
                    el.currency_from_id === item?.vectors[index - 1]?.currency_from_id
                  }
                  setDeletedEl={setDeletedEl}
                  isNotFirstTo={el.currency_to_id === item?.vectors[index - 1]?.currency_to_id}
                  visibleVectors={visibleVectors}
                  setVisibleVectors={setVisibleVectors}
                  cityId={item.city_id}
                  item={el}
                  currenciesOptions={currenciesOptions}
                  activeTemplate={activeTemplate}
                  setTemplateData={setTemplateData}
                  isSplitRight={isSplitRight}
                />
              );
            })}
          <div className="relative h-[25px] max-h-[25px] flex items-center">
            <div className={`duration-300 pl-4 pb-2 ${isAddVector ? "opacity-0" : ""}`}>
              {opened && !isCopy && !isDelete ? (
                <CreateButton
                  title="Добавить вектор"
                  isAdd={isAddVector}
                  setIsAdd={setIsAddVector}
                />
              ) : (
                <div className="h-[25px]" />
              )}
            </div>
            {isAddVector && (
              <div className="absolute -top-[7px] z-[1] -left-8 flex items-center gap-4 rounded-sm bg-bg py-[3px] px-8">
                <ReactSelect
                  classNamePrefix="SquareSelect"
                  className="w-[120px]"
                  options={currenciesOptions}
                  filterOption={createFilter(selectFilter)}
                  placeholder="from"
                  onChange={(e: TCurSelect) => setNewFrom(e)}
                />
                <ReactSelect
                  classNamePrefix="SquareSelect"
                  className="w-[120px]"
                  options={toOptions}
                  filterOption={createFilter(selectFilter)}
                  placeholder="to"
                  onChange={(e: TCurSelect) => setNewTo(e)}
                />
                <input
                  className={`w-[120px] h-[24px] border border-lightGray outline-lightGray rounded-sm px-[8px]`}
                  placeholder="percent"
                  type="number"
                  step="any"
                  defaultValue={newPercent}
                  onWheel={(e) => {
                    e.currentTarget.blur();
                  }}
                  onChange={(e) => setNewPercent(e.target.value)}
                />
                <button
                  className={`w-[16px] min-w-[16px] h-[16px] bg-[#62F5B3] flex justify-center items-center rounded-sm text-white text-base hover:brightness-110 ${
                    disabled ? "opacity-20" : ""
                  }`}
                  disabled={disabled}
                  onClick={async () => {
                    const findItem = items?.find((el) => el.city_id === item.city_id);
                    if (!!newFrom && !!newTo) {
                      const preparedItems = {
                        ...(!!findItem ? findItem : { city_id: item.city_id }),
                        vectors: [
                          ...(!!findItem ? findItem.vectors : []),
                          {
                            currency_from_id: newFrom.value.id,
                            currency_to_id: newTo.value.id,
                            percent: +newPercent,
                            cluster_percent: 0,
                            cluster_course: 0,
                            sites: [],
                          },
                        ]
                          ?.sort((a, b) => (a.percent < b.percent ? 1 : -1))
                          ?.sort((a, b) =>
                            `${a.currency_from_id}_${a.currency_to_id}` >
                            `${b.currency_from_id}_${b.currency_to_id}`
                              ? 1
                              : -1
                          ),
                      };
                      if (!!activeTemplate) {
                        setTemplateData(
                          items?.map((city) =>
                            city.city_id === item.city_id ? preparedItems : city
                          )
                        );
                      } else {
                        await setTimelineByCentrifugo(preparedItems, item.city_id);
                      }
                    }
                    setIsAddVector(false);
                  }}>
                  <span className={`relative top-[-0.5px] font-bold text-xs`}>✓</span>
                </button>
                <button
                  className="w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#989CA4] text-base hover:brightness-90"
                  onClick={() => setIsAddVector(false)}>
                  <span className={`relative top-[-1px] rotate-45 left-[1px]`}>+</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
