import { Dispatch, SetStateAction, useEffect, useMemo, memo, useState } from "react";
import { useSelector } from "react-redux";
import { timeToSeconds } from "src/helpers/date";
import { createOverlaysVectors } from "src/helpers/timelineHelper";
import {
  TTimeline,
  TTimelineDeletePayload,
  TTimelineSite,
  TTimelineVector,
} from "src/store/clusterReducer";
import { selectAllSites } from "src/store/directionsReducer";
import { TimelineItem } from "./TimelineItem";
import { VectorAxisX } from "./VectorAxisX";

type TProps = {
  id: number;
  item: TTimelineVector;
  cityId: number;
  axisX: Array<number>;
  items: Array<TTimeline>;
  isAdded: string;
  setIsAdded: Dispatch<SetStateAction<string>>;
  zoom: number;
  step: number;
  expand: boolean;
  deleteIt: (payload: TTimelineDeletePayload, saveRows: boolean) => void;
  visibleVectors: Array<string>;
  opened: boolean;
  isBest: boolean;
  isNotFirstFrom: boolean;
  username: string;
  actionsCount: number;
  setActionsCount: Dispatch<SetStateAction<number>>;
  activeTemplate: number | null;
  setTemplateData: Dispatch<SetStateAction<Array<TTimeline>>>;
};

export const TimelineVector = memo(
  ({
    id,
    item,
    cityId,
    axisX,
    items,
    isAdded,
    setIsAdded,
    zoom,
    step,
    expand,
    deleteIt,
    visibleVectors,
    opened,
    isBest,
    isNotFirstFrom,
    username,
    actionsCount,
    setActionsCount,
    activeTemplate,
    setTemplateData,
  }: TProps) => {
    const globalSites = useSelector(selectAllSites);
    const isVectorVisible = useMemo(
      () => visibleVectors?.includes(`${cityId}_${item?.currency_from_id}`),
      [visibleVectors]
    );
    const [vector, setVector] = useState<Array<TTimelineVector>>([]);

    const getInterval = (siteEl: TTimelineSite) =>
      ((timeToSeconds(siteEl?.end_time) - timeToSeconds(siteEl?.start_time)) / (60 * step)) * zoom;
    const getStart = (siteEl: TTimelineSite) =>
      (timeToSeconds(siteEl?.start_time) / (60 * step)) * zoom;

    useEffect(() => {
      if (!!item?.sites?.length) {
        const arr = createOverlaysVectors(item);
        setVector(arr);
      } else {
        setVector([item]);
      }
    }, [item]);

    if (opened && (isVectorVisible || !isNotFirstFrom || +item?.percent === 0)) {
      return (
        <div>
          {vector?.map((vectorEl, index) => (
            <div
              key={index}
              className={`relative w-fit flex h-[29px] max-h-[29px] ${
                opened && (isVectorVisible || !isNotFirstFrom || +item?.percent === 0)
                  ? ""
                  : "hidden"
              }`}>
              <VectorAxisX
                cityId={cityId}
                item={vectorEl}
                axisX={axisX}
                isAdded={isAdded}
                zoom={zoom}
                step={step}
                id={id}
                items={items}
                username={username}
                actionsCount={actionsCount}
                setActionsCount={setActionsCount}
                activeTemplate={activeTemplate}
                setTemplateData={setTemplateData}
              />
              {vectorEl?.sites?.map((el) => (
                <TimelineItem
                  key={`${cityId}_${item.currency_from_id}_${item.currency_to_id}_${item.percent}_${el.site_id}_${el.start_time}_${el.end_time}_${el.is_infinity}_item`}
                  item={el}
                  cityId={cityId}
                  vector={item}
                  globalSites={globalSites}
                  deleteIt={deleteIt}
                  isAdded={isAdded}
                  setIsAdded={setIsAdded}
                  items={items}
                  zoom={zoom}
                  step={step}
                  expand={expand}
                  isBest={isBest}
                  getInterval={getInterval}
                  getStart={getStart}
                  username={username}
                  activeTemplate={activeTemplate}
                  setTemplateData={setTemplateData}
                />
              ))}
            </div>
          ))}
        </div>
      );
    }
  }
);
