import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import academyApi from "src/api/academy";
import { fetchRequest } from "src/helpers/fetchRequest";
import {
  selectAttempt,
  selectAttempts,
  selectModules,
  selectMyAnswers,
  setAttempt,
  setAttempts,
  setMyAnswers,
  setResult,
} from "src/store/academyReducer";
import { ESectionType, ETestStatus, TMyAnswers } from "../types";
import { Block } from "../components/Block/Block";
import { Button } from "src/shadcn/ui/ui/button";
import { useAppDispatch } from "src/store/store";
import { Loader2 } from "lucide-react";
import { convertToSeconds, convertToTime } from "src/helpers/date";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { LS } from "src/api/constants";

export const SolveTest = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const course_id = searchParams.get("id");
  const tabType = searchParams.get("type");
  const module_id = searchParams.get("module_id");
  const modules = useSelector(selectModules);
  const attempts = useSelector(selectAttempts);
  const attempt = useSelector(selectAttempt);
  const myAnswers = useSelector(selectMyAnswers);
  const [isStarted, setIsStarted] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timer, setTimer] = useState<string | null>("");
  const isExpired = !!attempt && !!attempt.attempt_finished_on && timer === null;

  const currentModule = useMemo(
    () => modules?.find((el) => el.module_id === +module_id),
    [module_id, modules]
  );

  const startAttempt = async () => {
    setIsLoading(true);
    const { response } = await fetchRequest(academyApi.startAttempt(+module_id), {
      request: "Старт",
    });
    if (response) {
      dispatch(setAttempt(response));
      dispatch(setAttempts([...attempts, response]));
      setIsStarted(true);
    } else {
      setIsStarted(false);
    }
    setIsLoading(false);
  };

  const finishAttempt = async () => {
    if (!!course_id && !!module_id) {
      const { response } = await fetchRequest(academyApi.finishAttempt(attempt.attempt_id), {
        request: "Завершить",
      });
      if (response) {
        dispatch(setResult(response));
      }
    }
  };

  useEffect(() => {
    if (!!modules?.length && Array.isArray(attempts)) {
      if (!module_id) {
        searchParams.set("module_id", modules[0].module_id?.toString());
        searchParams.set("type", modules[0].module_type);
        searchParams.set("block_id", modules[0].module_blocks[0]?.block_id?.toString());
        setSearchParams(searchParams);
      } else {
        const currentAttempt = attempts?.find(
          (el) => +module_id === el?.module?.module_id && el.attempt_status === ETestStatus.STARTED
        );
        if (!!currentAttempt) {
          dispatch(setAttempt(currentAttempt));
          setIsStarted(true);
        }
      }
      if (!!modules?.length && !!module_id) {
        setIsLoading(false);
      }
    }
  }, [module_id, modules, attempts]);

  useEffect(() => {
    if (!!attempt?.attempt_finished_on) {
      const deadline = Math.round(+new Date(attempt.attempt_finished_on) / 1000);
      const deltaTime = deadline - Math.round(+new Date() / 1000);

      if (deltaTime > 0) {
        const timerId = setInterval(() => {
          const time = deadline - Math.round(+new Date() / 1000);
          time > 0
            ? setTimer(time < 3600 ? convertToSeconds(time) : convertToTime(time))
            : setTimer(null);
        }, 1000);
        return () => clearInterval(timerId);
      } else {
        setTimer(null);
      }
    }
  }, [attempt?.attempt_finished_on]);

  useEffect(() => {
    const savedMyAnswers = localStorage.getItem(LS.ACADEMY_MY_ANSWERS);
    if (!!savedMyAnswers && !!attempt) {
      const parsedMyAnswers = JSON.parse(savedMyAnswers) as TMyAnswers;
      if (parsedMyAnswers?.attempt_id === attempt.attempt_id) {
        dispatch(setMyAnswers(parsedMyAnswers));
      } else {
        dispatch(setMyAnswers(null));
        localStorage.removeItem(LS.ACADEMY_MY_ANSWERS);
      }
    }
  }, [attempt]);

  return (
    <div className="max-w-[900px] mx-auto py-5 flex flex-col gap-10">
      {!!attempt && !!attempt.attempt_finished_on && timer !== "" && (
        <div
          className={`fixed z-10 right-[50px] md:right-[80px] top-[100px] flex flex-col items-end gap-4 px-5 py-3 rounded-sm shadow-sm text-sm ${
            isExpired ? "bg-rose-200 text-rose-500" : "bg-stone-200 text-stone-500"
          }`}>
          {!isExpired ? (
            <>
              До окончания теста
              <div className="font-bold">{timer}</div>
            </>
          ) : (
            "Время истекло"
          )}
        </div>
      )}
      {tabType !== ESectionType.THEORY && <div className="relative "></div>}
      {isStarted !== null || tabType === ESectionType.THEORY ? (
        isStarted || tabType === ESectionType.THEORY ? (
          <>
            {!!currentModule &&
              currentModule?.module_blocks?.map((block, block_index) => {
                return (
                  <Block
                    key={block_index}
                    item={block}
                    isEditPage={false}
                    isExpired={isExpired}
                    isFirst={block_index === 0}
                  />
                );
              })}
            {tabType !== ESectionType.THEORY && !!currentModule?.questions_count && (
              <div className="mx-auto text-slate-400">
                <small>Ответов </small>
                <span
                  className={`font-bold text-2xl ${
                    myAnswers?.answers?.length === currentModule?.questions_count
                      ? "text-emerald-500"
                      : "text-yellow-500"
                  }`}>
                  {myAnswers?.answers?.length || 0}
                </span>{" "}
                из {currentModule?.questions_count}
              </div>
            )}
            {tabType !== ESectionType.THEORY && !isExpired && (
              <Button
                onClick={() => {
                  if (tabType !== ESectionType.THEORY) {
                    finishAttempt();
                  }
                }}>
                {tabType === ESectionType.EXAMINATION ? "Завершить тест" : "Завершить модуль"}
              </Button>
            )}
          </>
        ) : (
          <div className="w-full h-full min-h-[80vh] flex items-center justify-center">
            <Emptiness
              message={
                tabType === ESectionType.THEORY
                  ? "Не удалось получить модуль"
                  : "Не удалось начать тест"
              }
            />
          </div>
        )
      ) : isLoading ? (
        <div className="w-full h-full min-h-[80vh] flex items-center justify-center">
          <Loader2 className="animate-spin w-[40px] h-[40px] text-[#40CEF0]" />
        </div>
      ) : (
        <div className="w-full h-full min-h-[80vh] flex items-center justify-center">
          <Button
            size="lg"
            className="bg-emerald-500 px-14"
            disabled={!course_id || !module_id || tabType === ESectionType.THEORY}
            onClick={startAttempt}>
            Начать
          </Button>
        </div>
      )}
    </div>
  );
};
