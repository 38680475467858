import { CityRow } from "./CityRow";
import { useFormContext } from "react-hook-form";
import { ICountryData } from "../models";

type TProps = {
  item: ICountryData;
  staticCountries: Array<ICountryData>;
  isRegion: boolean;
  isOpen: boolean;
  access: boolean;
  redactorDisabled: boolean;
  isLoading: boolean;
};

export const SectorRow = ({
  item,
  isRegion,
  isOpen,
  access,
  redactorDisabled,
  staticCountries,
  isLoading,
}: TProps) => {
  const { setValue } = useFormContext();
  const items = item?.cities_data?.filter((city) => city?.city_is_region === isRegion);

  const setAll = ({ rate, isDelta }: { rate: number; isDelta: boolean }) => {
    items.map((el) => {
      setValue(`${el?.city_name}.bet_value_front`, rate);
      setValue(`${el?.city_name}.bet_is_delta`, isDelta);
    });
  };

  return (
    <div
      className={`duration-700 overflow-y-auto ${
        isOpen ? `max-h-[430px] ${!!items.length && "pb-[12px]"}` : "max-h-0 pb-0"
      }`}>
      {items?.map((el, index) => (
        <CityRow
          item={el}
          boss={+item.bet_boss_rate}
          index={index}
          regionLength={items.length}
          key={`${item.country_name}-${el.city_name}`}
          access={access}
          country={item.country_name}
          staticCountries={staticCountries}
          redactorDisabled={redactorDisabled}
          setAll={setAll}
          isRegion={isRegion}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};
