import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertToOptions, selectFilter } from "src/helpers/formHelpers";
import {
  selectAllCities,
  selectAllSites,
  selectCity,
  selectCurFrom,
  selectCurTo,
  selectSite,
  setSelectedCity,
  setSelectedFrom,
  setSelectedSite,
  setSelectedTo,
  TStrSelect,
} from "src/store/directionsReducer";
import { selectExistTetris, TTetris } from "src/store/tetrisReduser";
import ReactSelect, { createFilter } from "react-select";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { Spinner } from "src/components/Spinner/Spinner";
import { TT } from "../components/tetrisConstants";
import { Info } from "../components/Info";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { ExistRow } from "./ExistRow";
import tetrisApi from "src/api/tetris";
import { fetchRequest } from "src/helpers/fetchRequest";
import { pascal } from "src/helpers/strHelper";
import { setNotific } from "src/store/mainReducer";

export const ExistList = ({
  isAccess,
  isGood,
  fetchItems,
  page,
  searchParams,
  setSearchParams,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(selectExistTetris);
  const [deleteArr, setDeleteArr] = useState<Array<string>>([]); // Array<JSON.stringify>
  const [focus, setFocus] = useState({
    vector: "",
    site: "",
    city: "",
    percent: "",
    from: "",
    to: "",
  });
  const initialFilter = {
    vector: "Все направления",
    site: "Все сайты",
    city: "Все города",
    alien: "Все сайты",
    percent: 0.001,
  };
  const [filter, setFilter] = useState(initialFilter);
  const [isInfo, setIsInfo] = useState(false);
  const [isFullConfirmation, openFullConfirmation] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const sites = useSelector(selectAllSites);
  const cities = useSelector(selectAllCities);
  const curFrom = useSelector(selectCurFrom);
  const curTo = useSelector(selectCurTo);
  const selectedSite = useSelector(selectSite);
  const selectedCity = useSelector(selectCity);
  const uniqVectors = Array.from(new Set(data?.map((el) => el?.vector)));
  const uniqSites = Array.from(new Set(data?.map((el) => el?.site_name)));
  const uniqCities = Array.from(new Set(data?.map((el) => el?.city_name)));
  const vectorOptions = [
    { label: "Все направления", value: "Все направления" },
    ...convertToOptions(uniqVectors),
  ] as TStrSelect[];
  const siteOptions = [
    { label: "Все сайты", value: "Все сайты" },
    ...convertToOptions(uniqSites),
  ] as TStrSelect[];
  const cityOptions = [
    { label: "Все города", value: "Все города" },
    ...convertToOptions(uniqCities),
  ] as TStrSelect[];
  const alienOptions = convertToOptions(["Все сайты", "Наши сайты", "Не наши сайты"]);

  const allFilter = (el) =>
    (filter.vector?.replace("20", "")?.toUpperCase()?.split("_")?.join("-") ===
      el?.vector?.replace("20", "")?.toUpperCase()?.split("_")?.join("-") ||
      filter.vector === "Все направления") &&
    (filter.site === el?.site_name || filter.site === "Все сайты") &&
    (filter.city === el?.city_name || filter.city === "Все города") &&
    (page.page !== TT.BLACKLIST
      ? true
      : filter.alien === "Все сайты" ||
        (filter.alien === "Наши сайты" && !el?.is_alien) ||
        (filter.alien === "Не наши сайты" && el?.is_alien)) &&
    (page.page !== TT.CLUSTER ? true : Math.abs(filter.percent) <= Math.abs(el.percent));

  const deleteItems = async (isFull) => {
    const arr = deleteArr?.map((el) => JSON.parse(el));
    const payload = isFull
      ? data
          ?.filter((el: TTetris) => ("is_locked" in el ? !el?.is_locked : true))
          ?.filter((el) => allFilter(el))
      : arr
          ?.filter((obj: TTetris) => {
            if ("is_locked" in obj) {
              const item = data?.find(
                (dataItem: TTetris) =>
                  `${dataItem?.vector}_${dataItem?.site_name}_${dataItem?.city_name}` ===
                  `${obj?.vector}_${obj?.site_name}_${obj?.city_name}`
              );
              return "is_locked" in item && !item?.is_locked;
            } else {
              return true;
            }
          })
          ?.filter((el) => allFilter(el));

    const { response } = await fetchRequest(
      tetrisApi[`deleteTetris${pascal(page?.page)}`](payload),
      { request: "Удаление" }
    );
    if (response) {
      setDeleteArr([]);
      await fetchItems();
      dispatch(setNotific({ type: "success", message: "Успешно", request: "Удаление" }));
    }
    openFullConfirmation(null);
  };

  useEffect(() => {
    let obj = {};
    for (const key in filter) {
      if (!!searchParams?.get(key)) {
        obj = { ...obj, [key]: searchParams?.get(key) };
      }
      if (!searchParams?.get(key)) {
        switch (key) {
          case "site": {
            if (!!selectedSite) {
              obj = { ...obj, [key]: selectedSite?.site_name };
            }
            break;
          }
          case "vector": {
            if (!!curFrom && !!curTo) {
              obj = { ...obj, [key]: `${curFrom}-to-${curTo}` };
            }
            break;
          }
          case "city": {
            if (!!selectedCity) {
              obj = { ...obj, [key]: selectedCity.city_name };
            }
            break;
          }
        }
      }
    }
    if (!!Object.keys(obj).length) {
      setFilter({ ...filter, ...obj });
    }
  }, []);

  useEffect(() => {
    for (const key in filter) {
      if (filter[key] !== initialFilter[key]) {
        searchParams.set(key, filter[key]);
      }
      if (!!searchParams?.get(key) && filter[key] === initialFilter[key]) {
        searchParams.delete(key);
      }
    }
    setSearchParams(searchParams);
  }, [filter]);

  useEffect(() => {
    setIsLoading(true);
  }, [page]);

  useEffect(() => {
    setIsLoading(false);
  }, [data]);

  return (
    <div
      className={`w-full max-w-[350px] mx-auto lg:ml-0 lg:mr-[20px] lg:w-fit lg:max-w-[600px] flex flex-col items-start mb-[200px]`}>
      <div className="w-full text-xs relative flex flex-col gap-[6px] min-w-[304px] mx-auto lg:mr-0 lg:mr-[10px] pl-4">
        <ReactSelect
          classNamePrefix="SquareSelect"
          placeholder="Вектор..."
          options={vectorOptions}
          value={{ label: filter.vector, value: filter.vector }}
          filterOption={createFilter(selectFilter)}
          onChange={(e: TStrSelect) => {
            setFilter({ ...filter, vector: e.label });
            const vectorData = e.label?.includes("-to-")
              ? e.label.replace("20", "")?.split("-to-")
              : e.label.replace("20", "")?.split("_TO_");
            dispatch(setSelectedFrom(vectorData[0]?.replace("RC", "RC20")));
            dispatch(setSelectedTo(vectorData[1]?.replace("RC", "RC20")));
          }}
        />
        <ReactSelect
          classNamePrefix="SquareSelect"
          placeholder="Сайт..."
          options={siteOptions}
          value={{ label: filter.site, value: filter.site }}
          filterOption={createFilter(selectFilter)}
          onChange={(e: TStrSelect) => {
            setFilter({ ...filter, site: e.label });
            const site = sites?.find((el) => el.site_name === e.label);
            dispatch(setSelectedSite({ site_id: site?.site_id, site_name: e?.label }));
          }}
        />
        <ReactSelect
          classNamePrefix="SquareSelect"
          placeholder="Город..."
          options={cityOptions}
          value={{ label: filter.city, value: filter.city }}
          filterOption={createFilter(selectFilter)}
          onChange={(e: TStrSelect) => {
            setFilter({ ...filter, city: e.label });
            const city = cities?.find((el) => el.city_name === e.label);
            dispatch(setSelectedCity({ city_id: city?.city_id, city_name: e?.label }));
          }}
        />
        {page.page === TT.BLACKLIST && (
          <ReactSelect
            classNamePrefix="SquareSelect"
            options={alienOptions}
            value={{ label: filter.alien, value: filter.alien }}
            filterOption={createFilter(selectFilter)}
            onChange={(e: TStrSelect) => setFilter({ ...filter, alien: e.label })}
          />
        )}
        {page.page === TT.CLUSTER && (
          <div className="flex gap-[14px] items-baseline">
            <div className="text-lightFont whitespace-pre">Скрыть меньше по модулю:</div>
            <input
              className="w-full border border-lightGray rounded-sm h-[24px] px-8"
              value={filter.percent}
              type="number"
              step="any"
              onChange={(e) => setFilter({ ...filter, percent: +e.target.value })}
            />
          </div>
        )}
      </div>
      {isAccess && data?.length > 0 ? (
        <div className="flex gap-4 lg:mr-[10px] my-[16px] pl-4 font-semibold text-lightFont text-xs">
          <button
            type="button"
            className="rounded-sm bg-stale px-[12px] py-2"
            onClick={() => {
              if (!!deleteArr.length) {
                openFullConfirmation(false);
              }
            }}>
            Удалить выбранные
          </button>
          <button
            type="button"
            className="rounded-sm bg-stale px-[12px] py-2"
            onClick={() => openFullConfirmation(true)}>
            Удалить все
          </button>
          {page.page === TT.SCHEDULE && (
            <button
              type="button"
              className="w-[22px] min-w-[22px] bg-[#40AEF0] rounded-sm font-extrabold text-white hover:opacity-80"
              onClick={() => setIsInfo(true)}>
              i
            </button>
          )}
        </div>
      ) : (
        <div className="h-[16px]" />
      )}
      {!isLoading && (
        <div className="flex flex-col gap-2 lg:h-[60vh] lg:overflow-y-auto">
          {data
            ?.filter((el) => allFilter(el))
            ?.map((el, index) => (
              <ExistRow
                key={`${el.site_name}-${el.vector}-${el.city_name}-${index}`}
                item={el}
                data={data}
                isAccess={isAccess}
                deleteArr={deleteArr}
                setDeleteArr={setDeleteArr}
                page={page}
                focus={focus}
                setFocus={setFocus}
                fetchItems={fetchItems}
              />
            ))}
          {!isGood && <Emptiness message="Ошибка сервиса" />}
        </div>
      )}
      {isLoading && (
        <div className="w-full relative rounded-sm bg-[#BABAC333] h-[53vh] flex justify-center items-center">
          <Spinner />
        </div>
      )}
      {isFullConfirmation !== null && (
        <DeleteConfirmation
          data={data}
          isFullConfirmation={isFullConfirmation}
          openFullConfirmation={openFullConfirmation}
          deleteArr={deleteArr}
          deleteItems={deleteItems}
          allFilter={allFilter}
        />
      )}
      {isInfo && <Info setFn={setIsInfo} />}
    </div>
  );
};

export type TFocus = {
  vector: string;
  site: string;
  city: string;
  percent: string;
  from: string;
  to: string;
};
