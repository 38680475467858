import api from "./http";
import { BASE_URL } from "./constants";

export default class graphsApi {
  static async dashboard() {
    return api.get(`${BASE_URL}/stats/graphs/dashboard`);
  }
  static async controlDashboard() {
    return api.get(`${BASE_URL}/statistics_agregator/statistics-dashboard`);
  }
  static async directionsGraph(data) {
    return api.get(
      `${BASE_URL}/sites-available/graph/?type=${data.type}` +
        `${!!data.links?.length ? `&link=${data.links}` : ""}` +
        `${!!data.site_ids?.length ? `&site_ids=${data.site_ids}` : ""}` +
        `${!!data.city_ids?.length ? `&city_ids=${data.city_ids}` : ""}` +
        `${
          !!data.currency_from_ids?.length ? `&currency_from_ids=${data.currency_from_ids}` : ""
        }` +
        `${!!data.currency_to_ids?.length ? `&currency_to_ids=${data.currency_to_ids}` : ""}` +
        `${!!data?.cities?.length ? `&cities=${data.cities}` : ""}` +
        `${!!data?.time_from?.length ? `&time_point=${data.time_from}` : ""}`
    );
  }
  static async getEntropy(data) {
    return api.get(
      `${BASE_URL}/sites-available/similarity/?sites=${data.sites}` +
        `${!!data?.cities?.length ? `&cities=${data.cities}` : ""}` +
        `${!!data?.time_from?.length ? `&time_from=${data.time_from}` : ""}` +
        `${!!data?.time_to?.length ? `&time_to=${data.time_to}` : ""}`
    );
  }
  static async getBestSites() {
    return api.get(`${BASE_URL}/sites-available/similarity/best-sites`);
  }
  static async getLastAvailable(data) {
    return api.get(
      `${BASE_URL}/sites-available/survivor/?` +
        `${!!data?.sites?.length ? `sites=${data.sites}` : ""}` +
        `${!!data.currency_from?.length ? `&currency_from=${data.currency_from}` : ""}` +
        `${!!data.currency_to?.length ? `&currency_to=${data.currency_to}` : ""}` +
        `${!!data?.cities?.length ? `&cities=${data.cities}` : ""}` +
        `${!!data?.time_from?.length ? `&time_from=${data.time_from}` : ""}` +
        `${!!data?.time_to?.length ? `&time_to=${data.time_to}` : ""}`
    );
  }
}
