import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import textApi from "src/api/text";
import { fetchRequest } from "src/helpers/fetchRequest";
import { setNotific } from "src/store/mainReducer";
import { TTextSubType, TTextSubTypeList, TTextType } from "../types";
import { Popup } from "src/components/Popup/Popup";

type TProps = {
  type: TTextType;
  types: TTextType[];
  setTypes: Dispatch<SetStateAction<TTextType[]>>;
  subTypes: TTextSubTypeList[];
  subType: TTextSubType | null;
  setFn: (currentType: TTextType, currentSubType: TTextSubType) => void;
};

export const TextTypes = ({ type, types, setTypes, subTypes, subType, setFn }: TProps) => {
  const dispatch = useDispatch();
  const [isCreate, setIsCreate] = useState(false);
  const [openedType, setOpenedType] = useState<number | null>(null);
  const [name, setName] = useState("");

  const createType = async () => {
    const { response } = await fetchRequest(
      textApi.addType({
        name_ru: name,
        name_en: name,
      }),
      { request: "Не удалось создать тип" }
    );
    if (response) {
      dispatch(
        setNotific({
          type: "success",
          message: `Тип ${name?.toUpperCase()} успешно создан`,
          request: "Создание типа",
        })
      );
      setTypes([...types, response]);
      setIsCreate(false);
      setName("");
    }
  };

  return (
    <div className="relative flex flex-wrap gap-4 items-center -my-2 py-4 px-4 border border-stale rounded-sm leading-3">
      {types?.map((el) => (
        <TypeButton
          key={el?.id}
          item={el}
          openedType={openedType}
          setOpenedType={setOpenedType}
          active={type?.id === el?.id}
          setFn={setFn}
          subType={subType}
          subTypes={subTypes?.find((subEl) => subEl.name === el.name_en)?.data || []}
        />
      ))}
      {isCreate && (
        <Popup closeModal={() => setIsCreate(false)}>
          <h2 className="text-center">Создайте новый тип текстов</h2>
          <div className="flex flex-col gap-[12px] max-w-[280px] pt-[12px] font-light text-sm items-center">
            <b>Обратите внимание!</b> Типы текстов невозможно удалить. Называйте типы емко,
            согласуйте с командой перед созданием.
            <input
              className={`border border-lightGray w-[240px] px-8`}
              placeholder="Назовите тип"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <button
              type="button"
              className={`bg-light w-fit px-[16px] h-[26px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300 hover:opacity-70 ${
                !name?.length ? "opacity-30" : ""
              }`}
              disabled={!name?.length}
              onClick={async () => {
                await createType();
                setIsCreate(false);
              }}>
              Создать
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};

const TypeButton = ({
  item,
  openedType,
  setOpenedType,
  active,
  setFn,
  disabled = false,
  subType,
  subTypes,
}: {
  item: TTextType;
  openedType: number | null;
  setOpenedType: Dispatch<SetStateAction<number | null>>;
  active: boolean;
  setFn?: (currentType: TTextType, currentSubType: TTextSubType) => void;
  disabled?: boolean;
  subType: TTextSubType;
  subTypes: TTextSubType[];
}) => {
  const isOpen = openedType === item.id;

  const label = (): string => {
    if (!!subTypes?.length) {
      if (!!subType && active) {
        return `/ ${subType.text_subtype_name_ru}`;
      } else {
        return `\u25BC`;
      }
    } else {
      return "";
    }
  };

  return (
    <div className="relative">
      <button
        type="button"
        disabled={disabled}
        className={`px-8 py-8 rounded-sm whitespace-pre duration-300 hover:opacity-70 cursor-pointer ${
          active ? "bg-font text-white disabled:bg-font" : "text-lightFont"
        } ${
          disabled && !active
            ? "opacity-0 max-h-0 overflow-hidden my-0 py-0"
            : "opacity-100 max-h-[30px] overflow-visible my-px py-8"
        }`}
        onClick={() => {
          if (!!subTypes?.length) {
            setOpenedType(isOpen ? null : item.id);
          } else {
            setFn(item, null);
            setOpenedType(null);
          }
        }}>
        {item?.name_ru?.toUpperCase()} {label()}
      </button>
      {isOpen && (
        <div
          className="absolute flex flex-col gap-2 z-20 top-[28px] right-0 bg-bg flex gap-4 -mx-px px-4 py-8 border border-stale rounded-sm"
          onMouseLeave={() => {
            setOpenedType(null);
          }}>
          {subTypes?.map((el) => (
            <button
              type="button"
              key={el.id}
              className={`px-8 py-4 rounded-sm whitespace-pre duration-300 hover:opacity-70 cursor-pointer`}
              onClick={() => {
                setFn(item, el);
                setOpenedType(null);
              }}>
              {el.text_subtype_name_ru?.toUpperCase()}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
