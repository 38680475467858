import { RateItem, TRates } from "src/store/statsReducer";

export const getRatesArr = (data: TRates) => {
  const res: RateItem[] = [];
  for (const exchanger of Object.values(data)) {
    for (const item of Object.values(exchanger)) {
      res.push(item);
    }
  }
  return res;
};

export const getRatesState = (data: RateItem[]): TRates => {
  const res: TRates = {};

  for (const item of Object.values(data)) {
    if (!res[item.exchanger_name]) res[item.exchanger_name] = {};
    res[item.exchanger_name][item.pair_name] = item;
  }

  return res;
};
