import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteNotific } from "src/store/mainReducer";
import "./Notific.css";

export const Notific: FC<{
  type: "error" | "success";
  message: string;
  request?: string;
  time?: number;
}> = ({ type, message, request, time }) => {
  const dispatch = useDispatch();
  const timeout = time ? time : 10000;

  const close = () => {
    dispatch(deleteNotific({ type: type, message: message, request: request }));
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      close();
    }, timeout);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className={`relative flex items-center justify-center notific bg-bg ${type} mt-4 z-[99]`}>
      <button
        type="button"
        onClick={close}
        className="text-lightGray absolute top-0 right-[5px] hover:opacity-[0.8]">
        {`\u2716`}
      </button>
      {request && (
        <div className="absolute left-[10px] top-[5px] text-font text-left text-[9px] mb-4">
          {request}:
        </div>
      )}
      <div className={"text-xs font-base"}>{message}</div>
    </div>
  );
};
