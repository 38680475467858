import { Dispatch, SetStateAction } from "react";
import { TGlobalCountry, TGlobalCurrency, TGlobalSite } from "src/store/directionsReducer";
import { AdderButton } from "./AdderButton";

type TGlobalType = TGlobalCurrency | TGlobalSite | TGlobalCountry;

export const Adder = ({
  selectedItems,
  setSelectedItems,
  allItems,
  itemName,
  height,
}: {
  selectedItems: Array<TGlobalType>;
  setSelectedItems: Dispatch<SetStateAction<Array<TGlobalType>>>;
  allItems: Array<TGlobalType>;
  height?: string;
  itemName: "currency_name" | "site_name" | "name_ru";
}) => {
  const addItem = (item: TGlobalType, isExist: boolean) => {
    if (!isExist) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems?.filter((el) => {
          if (itemName === "currency_name" && "currency_name" in el && "currency_name" in item) {
            return el[itemName] !== item[itemName];
          }
          if (itemName === "site_name" && "site_name" in el && "site_name" in item) {
            return el[itemName] !== item[itemName];
          }
          if (itemName === "name_ru" && "name_ru" in el && "name_ru" in item) {
            return el[itemName] !== item[itemName];
          }
        })
      );
    }
  };

  const addAll = () => {
    setSelectedItems(allItems);
  };

  return (
    <div className={`${height ? height : "h-[350px]"} lg:w-full overflow-y-scroll frame2`}>
      <div className="my-[10px]">
        <div className="flex flex-col items-end relative top-[-10px]">
          <button
            type="button"
            className="mb-[5px] mr-[5px] py-[2px] px-[8px] rounded-[8px] text-[12px] font-[600] text-white bg-[#3bb54a] whitespace-pre"
            onClick={addAll}>
            Добавить все
          </button>
          {allItems?.map((el: TGlobalType, index) => {
            if (itemName === "currency_name" && "currency_name" in el) {
              const isExist = !!selectedItems?.find(
                (selectedEl: TGlobalType) =>
                  "currency_name" in selectedEl && selectedEl[itemName] === el[itemName]
              );
              return (
                <AdderButton
                  key={index}
                  title={el[itemName]}
                  isExist={isExist}
                  fn={() => addItem(el, isExist)}
                />
              );
            }
            if (itemName === "site_name" && "site_name" in el) {
              const isExist = !!selectedItems?.find(
                (selectedEl: TGlobalType) =>
                  "site_name" in selectedEl && selectedEl[itemName] === el[itemName]
              );
              return (
                <AdderButton
                  key={index}
                  title={el[itemName]}
                  isExist={isExist}
                  fn={() => addItem(el, isExist)}
                />
              );
            }
            if (itemName === "name_ru" && "name_ru" in el) {
              const isExist = !!selectedItems?.find(
                (selectedEl: TGlobalType) =>
                  "name_ru" in selectedEl && selectedEl[itemName] === el[itemName]
              );
              return (
                <AdderButton
                  key={index}
                  title={el[itemName]}
                  isExist={isExist}
                  fn={() => addItem(el, isExist)}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
