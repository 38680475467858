import { Dispatch, SetStateAction, useState } from "react";
import { TMinMaxCurrency, TMinMaxRegion, TMinMaxSheet } from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";
import { SheetCurrencyItem } from "./SheetCurrencyItem";
import { WorkTimeForm } from "./WorkTimeForm";
import { SiteUrlForm } from "./SiteUrlForm";
import copy from "src/assets/images/copy.svg";
import paste from "src/assets/images/empty.svg";

type TProps = {
  item: TMinMaxSheet;
  region: TMinMaxRegion;
  fetchSheet: () => void;
  access: boolean;
  copiedItem: TMinMaxCurrency | null;
  setCopiedItem: Dispatch<SetStateAction<TMinMaxCurrency | null>>;
  selectedToPaste: Array<TMinMaxCurrency & { site_id: number }>;
  setSelectedToPaste: Dispatch<SetStateAction<Array<TMinMaxCurrency & { site_id: number }>>>;
  sitesToSynchronize: Array<number>;
  setSitesToSynchronize: Dispatch<SetStateAction<Array<number>>>;
  copiedTime: TMinMaxSheet | null;
  setCopiedTime: Dispatch<SetStateAction<TMinMaxSheet | null>>;
  selectedToPasteTime: Array<TMinMaxSheet>;
  setSelectedToPasteTime: Dispatch<SetStateAction<Array<TMinMaxSheet>>>;
  isChangable: boolean;
};

export const SheetSite = ({
  item,
  region,
  fetchSheet,
  access,
  copiedItem,
  setCopiedItem,
  selectedToPaste,
  setSelectedToPaste,
  copiedTime,
  setCopiedTime,
  selectedToPasteTime,
  setSelectedToPasteTime,
  sitesToSynchronize,
  setSitesToSynchronize,
  isChangable,
}: TProps) => {
  const [isSiteUrlForm, setIsSiteUrlForm] = useState(false);
  const [isWorkTimeForm, setIsWorkTimeForm] = useState(false);
  const isChecked = sitesToSynchronize?.includes(item?.site_id);
  const itPaste = !!selectedToPasteTime?.find((el) => el.site_id === item?.site_id);

  const color = () => {
    if (item.is_on_best && item.is_on_exnode) {
      return "#DADDD8";
    }
    if (item.is_on_best && !item.is_on_exnode) {
      return "#E1E7F2";
    }
    if (!item.is_on_best && item.is_on_exnode) {
      return "#E1F2E7";
    } else {
      return "#FAFAFF";
    }
  };

  const copyTimeFn = async () => {
    if (!copiedTime) {
      setCopiedTime(item);
    }
    if (!!copiedTime && copiedTime?.site_id === item?.site_id) {
      setCopiedTime(null);
      setSelectedToPasteTime([]);
    }
    if (!!copiedTime && copiedTime?.site_id !== item?.site_id) {
      if (!!selectedToPasteTime?.find((el) => el.site_id === item?.site_id)) {
        setSelectedToPasteTime(selectedToPasteTime?.filter((el) => el.site_id !== item?.site_id));
      } else {
        setSelectedToPasteTime([...selectedToPasteTime, item]);
      }
    }
  };

  return (
    <div
      style={{ backgroundColor: color() }}
      className={`w-fit flex divide-x text-left border-b border-x divide-lightGray border-lightGray`}>
      <div className="relative flex justify-between w-[210px] min-w-[210px]">
        <div className="absolute left-[10px] top-2 flex items-center gap-4">
          {access && isChangable && (
            <div className="-mb-8 -mr-8">
              <Checkbox
                checked={isChecked}
                onChange={() => {
                  if (!isChecked) {
                    setSitesToSynchronize([...sitesToSynchronize, item.site_id]);
                  } else {
                    setSitesToSynchronize(sitesToSynchronize?.filter((el) => el !== item.site_id));
                  }
                }}
              />
            </div>
          )}
          {access && !isChangable && <div className="w-[18px]" />}
          {!!item?.site_text_url && (
            <a
              className="px-4 rounded-full bg-[#3BC57A] text-[#BBFFDA] mt-2 leading-[13px] font-extrabold text-[10px] duration-300 hover:opacity-70"
              target="_blank"
              href={item.site_text_url}
              rel="noreferrer">
              TEXT
            </a>
          )}
          {access && (
            <div
              className="flex justify-center cursor-pointer mt-2 h-[14px] w-[14px] text-xs text-font rounded-sm bg-white leading-[13px] font-bold"
              onClick={() => setIsSiteUrlForm(true)}>{`\u270E`}</div>
          )}
        </div>
        <div className="flex flex-col justify-center px-[10px] w-full font-semibold text-sm">
          <div>{item.site_name}</div>
        </div>
        <div className="text-[10px] leading-[18px] text-center px-8">
          <div>MIN</div>
          <div>MAX</div>
          <div>РЕЗЕРВ</div>
          <div>FIX</div>
        </div>
      </div>
      <div className="w-fit lg:w-full grid grid-flow-col grid-cols-auto auto-cols-[200px] divide-x text-left divide-lightGray">
        {region?.currencies?.map((el, index) => {
          const currency = item?.currencies?.find((cur) => cur.currency_id === el.currency_id);
          return (
            <SheetCurrencyItem
              key={
                !!currency ? `${item.site_id}_${el.currency_id}` : `${item.site_id}_${index}_clear`
              }
              item={!!currency ? currency : null}
              currency={el}
              site={item}
              fetchSheet={fetchSheet}
              access={access}
              copiedItem={copiedItem}
              setCopiedItem={setCopiedItem}
              selectedToPaste={selectedToPaste}
              setSelectedToPaste={setSelectedToPaste}
            />
          );
        })}
        <div
          className={`relative w-full h-full ${
            itPaste
              ? "bg-[#BBFFDA]"
              : copiedTime?.site_id === item?.site_id
              ? "bg-[#3BC57A]"
              : !!copiedTime && copiedTime?.site_id !== item?.site_id
              ? "bg-glass-gray opacity-30"
              : ""
          }`}>
          {access && (
            <button type="button" className="absolute right-2 top-2 z-[1]" onClick={copyTimeFn}>
              <img
                src={!!copiedTime && copiedTime?.site_id !== item?.site_id ? paste : copy}
                alt="copy"
                width={16}
                height={16}
              />
            </button>
          )}
          <div
            className={`grid grid-cols-2 text-xs divide-x divide-lightGray ${
              access ? "cursor-pointer" : ""
            }`}
            onClick={() => {
              if (access) {
                setIsWorkTimeForm(true);
              }
            }}>
            <div className="px-8">{item?.work_time_start}</div>
            <div className="px-8">{item?.work_time_end}</div>
          </div>
        </div>
      </div>
      {isSiteUrlForm && (
        <Popup closeModal={() => setIsSiteUrlForm(false)}>
          <SiteUrlForm site={item} fetchSheet={fetchSheet} closeFn={setIsSiteUrlForm} />
        </Popup>
      )}
      {isWorkTimeForm && (
        <Popup closeModal={() => setIsWorkTimeForm(false)}>
          <WorkTimeForm site={item} fetchSheet={fetchSheet} closeFn={setIsWorkTimeForm} />
        </Popup>
      )}
    </div>
  );
};
