import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/store";
import statsApi from "src/api/stats";
import { fetchRequest } from "src/helpers/fetchRequest";
import { setNotific } from "src/store/mainReducer";
import { selectRatios, selectStatsReports, setRatios } from "src/store/statsReducer";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { createStatsReportsSubscription } from "src/api/centrifugo/statsReports";
import { StatsReport } from "./StatsReport";
import { convertToDate } from "src/helpers/date";
import { MiniTetris } from "src/pages/Tetris/MiniTetris/MiniTetris";
import { TPairs } from "src/store/onOffReducer";
import { ICity } from "src/types/OnOffTimeline/common";
import { selectGlobalCurrencies, setGlobalCurrencies } from "src/store/directionsReducer";
import { Button } from "src/shadcn/ui/ui/button";
import switchApi from "src/api/onOff";
import { TTurnOffReportVectors, TTurnReportVectors } from "../models";
import { Loader2 } from "lucide-react";
import { SelectedSimplePairs } from "./SelectedSimplePairs";
import { LS } from "src/api/constants";

export const StatsReports = () => {
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const isAccess = userAccess?.includes("stats_post");
  const dispatch = useAppDispatch();
  const ratios = useSelector(selectRatios);
  const statsReports = useSelector(selectStatsReports);
  const globalCurrencies = useSelector(selectGlobalCurrencies);
  const [isEditRatio, setEditRatio] = useState(false);
  const [isEditVectors, setEditVectors] = useState(false);
  const [isRatioLoading, setRatioLoading] = useState(false);
  const [isVectorsLoading, setVectorsLoading] = useState(false);

  const [cities, setCities] = useState<Array<ICity>>([]);
  const [selectedPairs, setSelectedPairs] = useState<TTurnOffReportVectors[]>([]);
  const [pairs, setPairs] = useState<TPairs>({
    from: [],
    to: [],
  });

  const currentRatio = useMemo(() => {
    return ratios?.find((el) => el.ratio_id === statsReports?.ratio_id) || null;
  }, [statsReports, ratios]);

  const dateFrom = useMemo(() => convertToDate(statsReports?.datetime_from) || "", [statsReports]);
  const dateTo = useMemo(() => convertToDate(statsReports?.datetime_to) || "", [statsReports]);

  const fetchGlobalCurrencies = async () => {
    const { response } = await fetchRequest(switchApi.getGlobalDirections(), {
      request: `Глобальные данные`,
    });
    if (response) {
      dispatch(setGlobalCurrencies(response.currencies));
    }
  };

  const fetchTurnedVectors = async () => {
    const { response } = await fetchRequest(statsApi.getTurnedVectors(), {
      request: `Направления отчетов`,
    });
    if (response) {
      setSelectedPairs(response);
    }
  };

  const fetchRatios = async () => {
    const { response } = await fetchRequest(statsApi.getRatios(), {
      request: "Соотношения",
    });
    if (response) {
      dispatch(setRatios(response));
    }
  };

  const updateReportRatio = async (payload: { ratio_id: number }) => {
    setRatioLoading(true);
    const { response } = await fetchRequest(statsApi.setReportRatio(payload), {
      request: "Рассчет отчетов",
    });
    if (response) {
      setEditRatio(false);
      dispatch(
        setNotific({
          type: "success",
          message: "Успешно обновлен.  Данные появятся в новом отчете",
          request: "Рассчет отчетов",
        })
      );
    }
    setRatioLoading(false);
  };

  const updateVectors = async () => {
    setVectorsLoading(true);
    const payload: TTurnReportVectors = {
      city_names: cities?.map((el) => el.city_name),
      currency_from_codes: pairs?.from?.map((el) => el.currency_name),
      currency_to_codes: pairs?.to?.map((el) => el.currency_name),
    };
    const { response } = await fetchRequest(statsApi.turnVectors(payload), {
      request: "Обновление векторов",
    });
    if (response) {
      const createdVectors = response?.filter(
        (el: TTurnOffReportVectors) =>
          !selectedPairs?.some(
            (existEl) =>
              `${existEl.city_name}_${existEl.currency_from_code}_${existEl.currency_to_code}` ===
              `${el.city_name}_${el.currency_from_code}_${el.currency_to_code}`
          ) && el.in_report === true
      );
      if (!!selectedPairs?.length) {
        setSelectedPairs([...selectedPairs, ...createdVectors]);
      } else {
        setSelectedPairs(createdVectors);
      }
      dispatch(
        setNotific({
          type: "success",
          message: "Успешно обновлены. Данные появятся в новом отчете",
          request: "Обновление векторов",
        })
      );
    }
    setVectorsLoading(false);
  };

  useEffect(() => {
    fetchGlobalCurrencies();
    fetchRatios();
    fetchTurnedVectors();
    createStatsReportsSubscription(false, false);
    return () => {
      createStatsReportsSubscription(false, true);
    };
  }, []);

  return (
    <div className="pl-[10px] pr-[50px] sm:pr-[70px] text-slate-800 mx-auto max-w-[1000px] text-sm flex flex-col gap-3 pb-[100px]">
      {isAccess &&
        (isEditVectors ? (
          <div className="relative flex flex-col items-stretch md:flex-row gap-8">
            <Button
              type="button"
              size="icon"
              className="h-7 w-7 flex justify-center items-center z-10 absolute bg-yellow-400 font-bold top-3 right-2 px-8 duration-300 hover:bg-lightStale rounded-md text-white text-base"
              onClick={() => setEditVectors(false)}>
              {`\u00D7`}
            </Button>
            <div className="relative w-full">
              <MiniTetris
                cities={cities}
                setCities={setCities}
                globalCurrencies={globalCurrencies}
                pairs={pairs}
                setPairs={setPairs}
              />
              <Button
                type="button"
                className="absolute px-5 bottom-3 right-2"
                disabled={
                  !cities?.length || !pairs?.from?.length || !pairs?.to?.length || isVectorsLoading
                }
                onClick={updateVectors}>
                Создать пары
              </Button>
            </div>
            <SelectedSimplePairs
              selectedPairs={selectedPairs}
              setSelectedPairs={setSelectedPairs}
              isAccess={isAccess}
            />
          </div>
        ) : (
          <button
            className="flex gap-3 justify-center items-center bg-slate-100 rounded-sm border border-slate-400 p-8 font-semibold duration-300 hover:opacity-70"
            onClick={() => setEditVectors(true)}>
            Редактировать векторы
            <span className="rotate-[110deg] text-yellow-400">{`\u270E`}</span>
          </button>
        ))}
      {!!statsReports?.cities_reports?.length &&
        (!!selectedPairs?.length ? (
          <div className="border border-slate-400 rounded-sm divide-y divide-slate-400 text-xs">
            <div
              className={`rounded-t-sm divide-y divide-slate-400 font-semibold ${
                statsReports.report_type === "day" ? "bg-slate-100" : "bg-[#33415599] text-white"
              }`}>
              <div className="text-left px-8 py-4">
                {dateFrom?.substring(0, dateFrom?.length - 3)} -{" "}
                {dateTo?.substring(dateTo?.length - 8, dateTo?.length - 3)}
              </div>
              <div className="grid grid-cols-6 items-center *:py-4 *:px-8 divide-x divide-slate-400 *:h-full *:content-center">
                <div>
                  <p>{statsReports.report_type === "day" ? "🔆 День" : "🌑 Ночь"}</p>
                  <p>
                    {statsReports.period_datetime_from} - {statsReports.period_datetime_to}
                  </p>
                </div>
                <div className="col-span-3 px-4">
                  {isEditRatio && !isRatioLoading && isAccess ? (
                    <div className="flex flex-wrap gap-4 *:rounded-sm">
                      {!!ratios?.length ? (
                        <>
                          {ratios?.map((el) => (
                            <button
                              key={el.ratio_id}
                              type="button"
                              className="flex items-center gap-[16px] py-2 duration-300 bg-[#ffffff33] hover:bg-lightStale rounded-md px-[10px]"
                              onClick={() => {
                                updateReportRatio({ ratio_id: el.ratio_id });
                              }}>
                              {el?.target_percent || "?"} / {el?.range_percent || "?"}
                            </button>
                          ))}
                          <button
                            type="button"
                            className="px-8 duration-300 hover:bg-lightStale rounded-md"
                            onClick={() => setEditRatio(false)}>
                            <span className="text-yellow-400 text-xl">{`\u00D7`}</span>
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          className="py-4 px-8 text-lightFont"
                          onClick={() => setEditRatio(false)}>
                          Соотношения не добавлены
                        </button>
                      )}
                    </div>
                  ) : isRatioLoading ? (
                    <div className="w-full h-full flex items-center justify-center">
                      <Loader2 className="animate-spin w-[26px] h-[26px] text-[#40CEF0]" />
                    </div>
                  ) : isAccess ? (
                    <button
                      type="button"
                      className="flex gap-3 justify-center items-center w-full h-full text-center gap-[16px] px-[10px] rounded-md font-bold duration-300 hover:bg-glass-md"
                      onClick={() => setEditRatio(true)}>
                      <span>
                        % В ТОПЕ {currentRatio?.target_percent || "?"} /{" "}
                        {currentRatio?.range_percent || "?"}
                      </span>
                      <span className="rotate-[110deg] text-yellow-400">{`\u270E`}</span>
                    </button>
                  ) : (
                    <span>
                      % В ТОПЕ {currentRatio?.target_percent || "?"} /{" "}
                      {currentRatio?.range_percent || "?"}
                    </span>
                  )}
                </div>
                <div className="col-span-2 bg-[#0ea5e933]">КОЛ-ВО НОВЫХ КЛИЕНТОВ С BESTCHANGE</div>
              </div>
              <div className="grid grid-cols-6 items-center *:py-8 *:px-8 divide-x divide-slate-400 *:h-full *:content-center">
                <div>Город</div>
                <div>% 30 МИН</div>
                <div>% {statsReports.report_type === "day" ? "ДЕНЬ" : "НОЧЬ"}</div>
                <div>% ПЛАН</div>
                <div className="bg-[#0ea5e933]">30 МИН</div>
                <div className="bg-[#0ea5e933]">
                  {statsReports.report_type === "day" ? "ДЕНЬ" : "НОЧЬ"}
                </div>
              </div>
            </div>
            {statsReports.cities_reports.map((el) => {
              return (
                <StatsReport
                  key={el.city_id}
                  item={el}
                  ratio_id={statsReports.ratio_id}
                  isAccess={isAccess}
                  selectedPairs={selectedPairs}
                  setSelectedPairs={setSelectedPairs}
                />
              );
            })}
            <div
              className={`grid grid-cols-6 items-center *:py-4 rounded-b-sm divide-x divide-slate-400 font-semibold ${
                statsReports.report_type === "day" ? "bg-slate-100" : "bg-[#33415599] text-white"
              }`}>
              <div className="col-span-5 text-right pr-3">Итого:</div>
              <div className="bg-[#0ea5e933]">{statsReports?.clients_count}</div>
            </div>
          </div>
        ) : (
          <div className="relative flex justify-center items-center w-full min-h-[70vh]">
            <Emptiness message={"Добавьте направления"} />
          </div>
        ))}
      {!!statsReports && !statsReports?.cities_reports?.length && (
        <div className="relative flex justify-center items-center w-full min-h-[70vh]">
          <Emptiness message={"Нет отчетов по выбранным направлениям"} />
        </div>
      )}
    </div>
  );
};
