import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchRequest } from "src/helpers/fetchRequest";
import addApi from "src/api/add";
import { selectAllCities, TGlobalCity } from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { ItemRow } from "src/components/Rows/ItemRow";

type TProps = {
  access: boolean;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  fetchGlobals: () => void;
};

export const Cities = ({ setMessage, setStatus, setIsLoading, access, fetchGlobals }: TProps) => {
  const [isPopup, setPopup] = useState(false);
  const [isGood, setIsGood] = useState(true);
  const [selectedCity, setSelectedCity] = useState<TGlobalCity>();
  const cities = useSelector(selectAllCities);

  const deleteCity = async () => {
    setIsLoading(true);
    const { response, error } = await fetchRequest(addApi.deleteCity(selectedCity?.city_id), {
      request: "Удаление городов",
    });
    if (response) {
      setMessage(`Город ${selectedCity?.city_name} успешно удален`);
      setStatus(true);
      await fetchGlobals();
    }
    if (error) {
      setMessage("Не удалось удалить город");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      setPopup(false);
    }, 4600);
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsGood(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full sm:w-[600px] duration-300 flex flex-col gap-[10px] font-light pl-[20px] pb-[100px]">
      {cities?.map((el) => (
        <ItemRow
          key={el?.city_id}
          title={el?.city_name}
          access={access}
          onDeleteClick={() => {
            setSelectedCity(el);
            setPopup(true);
          }}
        />
      ))}
      {isPopup && (
        <Popup closeModal={() => setPopup(false)} noPadding>
          <div className="table-header px-[30px] flex justify-center">УДАЛИТЬ ГОРОД</div>
          <div className="px-[30px] py-[20px] text-center whitespace-pre flex flex-col gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\nгород `}
              <b>{selectedCity?.city_name}</b> безвозвратно?
            </div>
            <button className="prime-button-sm" onClick={deleteCity}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
      {!isGood && !cities?.length && <Emptiness message={"Ошибка сервиса"} />}
    </div>
  );
};
