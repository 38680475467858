import { ROLE } from "src/api/constants";
import { TPriorityType, TRequest, selectUnreadReqCount, viewAllReq } from "src/store/commonReducer";
import fire from "src/assets/images/fire.svg";
import question from "src/assets/images/question.svg";
import lightning from "src/assets/images/lightning.svg";
import high from "src/assets/images/high.svg";
import fresh from "src/assets/images/fresh.svg";
import search from "src/assets/images/search.svg";
import { useSelector } from "react-redux";
import requestApi from "src/api/requests";
import { useAppDispatch } from "src/store/store";
import { DatePickerWithRange, dateRangePresets } from "src/components/Date/DatePicker";
import { DateRange } from "react-day-picker";
import { useSearchParams } from "react-router-dom";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/ui/tooltip";

export const RequestsFilters = ({ filters, setFilters, count, fetchRequests }) => {
  const dispatch = useAppDispatch();
  const unreadReqCount = useSelector(selectUnreadReqCount);

  const [searchParams, setSearchParams] = useSearchParams();
  const paramFrom = searchParams.get("from");
  const paramTo = searchParams.get("to");

  const onDateChange = async (date: DateRange | undefined) => {
    const from = date?.from || new Date(Date.now());
    const to = date?.to || date?.from || new Date(Date.now());
    searchParams.set("from", String(Math.floor(+from / 1000)));
    searchParams.set("to", String(Math.floor(+to / 1000)));
    setSearchParams(searchParams);
    await fetchRequests();
  };

  const viewAll = async () => {
    const res = await fetchRequests(requestApi.viewAll());
    if (!res?.error) {
      dispatch(viewAllReq());
    }
  };

  return (
    <div className="w-full md:w-[600px] flex flex-col gap-8 mx-auto duration-300 text-xs text-lightGray font-semibold md:pl-[20px] -mt-4 mb-[16px]">
      <div className="flex justify-center md:justify-between items-baseline gap-x-[20px] flex-wrap">
        <div className="text-right">
          {`${count} запрос${count === 1 ? "" : count >= 2 && count <= 4 ? "а" : "ов"}`}
        </div>
        <div className="relative text-xs my-[6px]">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <DatePickerWithRange
                  from={paramFrom ? new Date(+paramFrom * 1000) : dateRangePresets.this_week.from}
                  to={paramTo ? new Date(+paramTo * 1000) : dateRangePresets.this_week.to}
                  maxDate={new Date()}
                  onDateChange={onDateChange}
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>Закрытые запросы\nза выбранный период и\nВсе незакрытые запросы</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="flex justify-between items-baseline gap-x-[20px]">
        <div className="flex items-center gap-4">
          <div className="mr-8">filter:</div>
          <div className="flex w-full relative">
            <input
              className="text-xs w-full border border-[#EEE] rounded-sm outline-none px-8 h-[25px]"
              onChange={(e) =>
                setFilters({ ...filters, filter: { ...filters.filter, subStr: e.target.value } })
              }
            />
            <img
              src={search}
              alt="Найти"
              width={16}
              height={16}
              className="absolute top-4 right-4 opacity-30"
            />
          </div>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  type="button"
                  style={{ background: "#FF1020DD" }}
                  className={`${
                    filters.filter.type === "пожар" ? "" : "grayscale opacity-30"
                  } h-[24px] w-[24px] min-w-[24px] duration-500 rounded-sm justify-center items-center flex`}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      filter: {
                        ...filters.filter,
                        type: filters.filter.type === "пожар" ? "" : "пожар",
                      },
                    })
                  }>
                  <img src={fire} alt="пожар" width={20} height={20} />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Только пожары</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  type="button"
                  style={{ background: "#40AEF0" }}
                  className={`${
                    filters.filter.type === "запрос" ? "" : "grayscale opacity-30"
                  } h-[24px] w-[24px] min-w-[24px] duration-500 rounded-sm justify-center items-center flex text-white font-extrabold`}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      filter: {
                        ...filters.filter,
                        type: filters.filter.type === "запрос" ? "" : "запрос",
                      },
                    })
                  }>
                  <img src={question} alt="запросы" width={14} height={14} />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Только запросы</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  type="button"
                  style={{ background: "#9999FF" }}
                  className={`${
                    filters.filter.type === "задача" ? "" : "grayscale opacity-30"
                  } h-[24px] w-[24px] min-w-[24px] duration-500 rounded-sm justify-center items-center flex text-white font-bold`}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      filter: {
                        ...filters.filter,
                        type: filters.filter.type === "задача" ? "" : "задача",
                      },
                    })
                  }>
                  <img src={lightning} alt="задачи" width={20} height={20} />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Только задачи</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  type="button"
                  style={{ background: "#8A909D" }}
                  className={`${
                    filters.filter.isAdminCamp ? "" : "grayscale opacity-30"
                  } h-[24px] w-[24px] min-w-[24px] duration-500 rounded-sm justify-center items-center flex text-white font-bold`}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      filter: { ...filters.filter, isAdminCamp: !filters.filter.isAdminCamp },
                    })
                  }>
                  <svg
                    width="20"
                    height="20"
                    version="1.1"
                    viewBox="0 0 700 600"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="m350 29.168c-70.84 0-128.33 57.492-128.33 128.33s57.492 128.33 128.33 128.33 128.33-57.492 128.33-128.33-57.492-128.33-128.33-128.33zm0 35c51.52 0 93.332 41.812 93.332 93.332s-41.812 93.332-93.332 93.332-93.332-41.812-93.332-93.332 41.812-93.332 93.332-93.332z" />
                    <path d="m577.5 443.33c0-81.922-54.484-151.11-129.17-173.34-7.8633-2.332-16.309 1.0977-20.301 8.2852l-78.027 140.65-78.027-140.65c-3.9883-7.1875-12.438-10.617-20.301-8.2852-74.691 22.238-129.17 91.422-129.17 173.34v46.668c0 10.828 4.293 21.211 11.969 28.863 7.6523 7.6758 18.035 11.969 28.863 11.969h373.33c10.828 0 21.211-4.293 28.863-11.969 7.6758-7.6523 11.969-18.035 11.969-28.863v-46.668zm-35 0v46.668c0 1.5391-0.60547 3.0352-1.7031 4.1289-1.0977 1.0977-2.5898 1.7031-4.1289 1.7031h-373.33c-1.5391 0-3.0352-0.60547-4.1289-1.7031-1.0977-1.0977-1.7031-2.5898-1.7031-4.1289v-46.668c0-61.156 37.637-113.52 91.023-135.19l86.172 155.35c3.0781 5.5547 8.9375 9.0078 15.309 9.0078 6.3711 0 12.227-3.4531 15.309-9.0078l86.172-155.35c53.387 21.676 91.023 74.035 91.023 135.19z" />
                    <path d="m367.5 431.67v-105c0-9.6602-7.8398-17.5-17.5-17.5s-17.5 7.8398-17.5 17.5v105c0 9.6602 7.8398 17.5 17.5 17.5s17.5-7.8398 17.5-17.5z" />
                    <path d="m443.33 437.5h46.668c9.6602 0 17.5-7.8398 17.5-17.5s-7.8398-17.5-17.5-17.5h-46.668c-9.6602 0-17.5 7.8398-17.5 17.5s7.8398 17.5 17.5 17.5z" />
                  </svg>
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Запросы от разработчиков</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex items-center gap-4">
          <div className="mr-8">sort:</div>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  type="button"
                  style={{ background: "#FAFAFF" }}
                  className={`${
                    filters.sort.priority ? "" : "grayscale opacity-30"
                  } h-[24px] w-[24px] min-w-[24px] duration-500 rounded-sm justify-center items-center flex`}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      sort: {
                        priority: !filters.sort.priority,
                        fresh: false,
                      },
                    })
                  }>
                  <img src={high} alt="priority" width={20} height={20} />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>По приоритету</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  type="button"
                  style={{ background: "#40AEF0" }}
                  className={`${
                    filters.sort.fresh ? "" : "grayscale opacity-30"
                  } h-[24px] w-[24px] min-w-[24px] duration-500 rounded-sm justify-center items-center flex`}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      sort: {
                        priority: false,
                        fresh: !filters.sort.fresh,
                      },
                    })
                  }>
                  <img src={fresh} alt="fresh" width={20} height={20} />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>По времени обновления</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      {unreadReqCount > 0 && (
        <div className="flex justify-end w-full">
          <button
            type="button"
            className="flex items-center gap-8 rounded-lg duration-700 shadow-smooth-out hover:shadow-inset py-[5px] px-[17px] text-lightFont"
            onClick={() => {
              setFilters({
                ...filters,
                filter: { ...filters.filter, isUnread: !filters.filter.isUnread },
              });
            }}>
            {filters.filter.isUnread ? "Показать все" : "Показать непрочитанные"}
          </button>
          <button
            onClick={viewAll}
            type="button"
            className="flex items-center gap-8 rounded-lg duration-700 shadow-smooth-out hover:shadow-inset py-[5px] px-[17px] text-lightFont">
            Прочитать все
            <div className="flex items-center justify-center h-[16px] w-[16px] min-w-[16px] w-fit rounded-[10px] text-white text-[9px] leading-[9px] bg-[#F33]">
              {unreadReqCount}
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export const requestFilter = (item, filters) => {
  const adminCamp = [ROLE.ADMIN, ROLE.SUPPORT, ROLE.MINI_ADMIN];
  return (
    ((!!filters.type.length && item?.type === filters.type) || (!filters.type.length && item)) &&
    JSON.stringify(item)?.includes(filters?.subStr) &&
    (!filters.isAdminCamp || adminCamp?.includes(item?.creator_role)) &&
    (filters.isUnread ? !item.is_viewed : true)
  );
};

export const requestSort = (itemA: TRequest, itemB: TRequest, filters: TRequestFilter["sort"]) => {
  if (filters.fresh) {
    return itemB.updated_timestamp - itemA.updated_timestamp;
  }
  if (filters.priority) {
    const priority: TPriorityType[] = ["low", "medium", "high"];
    return priority.indexOf(itemB.priority) - priority.indexOf(itemA.priority);
  }
};

export type TRequestFilter = {
  filter: {
    type: string;
    subStr: string;
    isAdminCamp: boolean;
    isUnread: boolean;
  };
  sort: {
    priority: boolean;
    fresh: boolean;
  };
};
