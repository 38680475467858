import {
  ICityPairsGroup,
  ICityWithGroups,
  OnOffItemToDeleteType,
} from "src/types/OnOffTimeline/common";
import { CurrencyGroup } from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/CurrencyGroup";
import { Accordion } from "src/pages/Panel/OnOff/OnOffTimeline/components/common/Accordion";
import {
  onOffTimeLineUndo,
  setOnOffConfirmation,
  toggleExpandAllCityContents,
} from "src/store/onOffTimelineReducer/slice";
import { useOnOffToggleExpanded } from "src/hooks/useOnOffToggleExpanded";
import { AccordionLabel } from "src/pages/Panel/OnOff/OnOffTimeline/components/common/AccordionLabel";
import { useSelector } from "react-redux";
import { cn } from "src/helpers/cn";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { memo } from "react";
import { confirmUndo } from "src/pages/Panel/OnOff/OnOffTimeline/components/timeline/TimelineData";
import { OnOffDataEntityType, ExpandActionType } from "src/types/OnOffTimeline/store";
import {
  selectOnOffDeleteMode,
  selectOnOffIsEdited,
  selectOnOffIsNew,
} from "src/store/onOffTimelineReducer/selectors";
import { deleteOnOffEntity } from "src/store/onOffTimelineReducer/thunks/deleteOnOffEntity";
import { DeleteConfirmText } from "src/pages/Panel/OnOff/OnOffTimeline/components/popups/OnOffConfirmPopup";
import { AUTO_CITY_NAME } from "src/api/constants";

interface CityTimelineProps {
  item?: ICityWithGroups;
  width: number;
}

export const CityTimeline = memo(({ item, width }: CityTimelineProps) => {
  const dispatch = useAppDispatch();

  const deleteMode = useSelector(selectOnOffDeleteMode);

  const { isExpanded, isFullyExpanded, toggleIsExpanded } = useOnOffToggleExpanded({
    type: OnOffDataEntityType.CITY,
    selector: item.city_code,
  });

  const isNew = useAppSelector((state) =>
    selectOnOffIsNew(state, { cityCode: item.city_code, type: OnOffDataEntityType.CITY })
  );
  const isEdited = useAppSelector((state) =>
    selectOnOffIsEdited(state, { cityCode: item.city_code, type: OnOffDataEntityType.CITY })
  );

  const onDelete = () => {
    dispatch(
      setOnOffConfirmation({
        title: "Вы уверены, что хотите удалить таймлайны для",
        subtitle: item.city_name + "?",
        content: (
          <DeleteConfirmText text="Удаленные сайты сохранят свои последние данные, которые будут доступны здесь" />
        ),
        cb: () =>
          dispatch(
            deleteOnOffEntity({
              type: OnOffItemToDeleteType.CITY,
              name: item.city_code,
              isNew,
            })
          ),
      })
    );
  };

  const undo = () => {
    dispatch(
      setOnOffConfirmation({
        title: confirmUndo,
        subtitle: item.city_name,
        cb: () => dispatch(onOffTimeLineUndo(item.city_code)),
      })
    );
  };

  return (
    <div
      className={cn(
        "rounded-xl w-fit relative bg-gradient-to-r p-[2px]",
        isEdited && "from-[#FFA500] via-[#ff1111] to-[#FFFF00]",
        isNew && "from-[#0096FF] via-[	#0096FF] to-[#00FFFF]"
      )}>
      <Accordion
        isOpen={isExpanded}
        isExpandable={!deleteMode}
        toggleIsOpen={toggleIsExpanded}
        customLabelComponent={
          <AccordionLabel
            undoable={isEdited && !deleteMode}
            undo={undo}
            label={item.city_name === AUTO_CITY_NAME ? "Авто" : item.city_name}
            containerClassname={cn(
              deleteMode && "border border-red bg-[#D7443E11] hover:bg-[#D7443E33] duration-300",
              isEdited && !isNew && "onOffEdited",
              isNew && "onOffNew"
            )}
            isFullyExpanded={isFullyExpanded}
            isExpandable={!deleteMode}
            onClick={() => {
              dispatch(
                toggleExpandAllCityContents({
                  type: isFullyExpanded ? ExpandActionType.COLLAPSE : ExpandActionType.EXPAND,
                  selector: item.city_code,
                })
              );
            }}
            notExpandableOnClick={onDelete}
          />
        }
        labelContainerClassname={cn(
          "sticky left-0 text-[24px] font-bold bg-bg h-[40px] w-[500px] pl-[20px]",
          !deleteMode && "hover:underline"
        )}
        containerClassname={cn(
          "relative rounded-xl overflow-clip shadow-[0px_0px_30px_-10px_rgba(0,0,0,0.3)] pt-[15px] border border-transparent bg-bg",
          !isExpanded && "pb-[15px]"
        )}
        containerStyles={{ width: width + 20 }}>
        <div className="w-full">
          {item.groups.map((group: ICityPairsGroup) => (
            <CurrencyGroup
              key={group.groupName}
              group={group}
              width={width}
              city={{ city_code: item.city_code, city_name: item.city_name }}
            />
          ))}
        </div>
      </Accordion>
    </div>
  );
});
