import { FormEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { fetchRequest } from "src/helpers/fetchRequest";
import { LS } from "src/api/constants";
import requestApi from "src/api/requests";
import {
  selectRequests,
  setRequests,
  selectTags,
  TRequestType,
  TTypeEditorType,
  TPriorityType,
  setTags,
  TTag,
  selectLastAction,
  TRequest,
  updateRequest,
} from "src/store/commonReducer";
import { CreateRequest } from "./components/CreateRequest";
import { RequestsFilters, TRequestFilter } from "./components/RequestsFilters";
import { Column } from "./Board/Column";
import question from "src/assets/images/question.svg";
import fire from "src/assets/images/fire.svg";
import lightning from "src/assets/images/lightning.svg";
import high from "src/assets/images/high.svg";
import medium from "src/assets/images/medium.svg";
import low from "src/assets/images/low.svg";
import { AssetClient, ContentType, getVideoDuration } from "src/pages/Requests/components/Request";
import { setNotific } from "src/store/mainReducer";
import {
  createNewReqDataSub,
  pushNewReqDataToCentrifuge,
} from "src/api/centrifugo/requestsCentrifugo";
import { createRequest as createRequestRedux } from "src/store/commonReducer";
import { Statistics } from "src/pages/Requests/components/Statistics";
import { dateRangePresets } from "src/components/Date/DatePicker";

const statuses = [
  ["в очереди", "0"],
  ["в работе", "1"],
  ["тестируется", "2"],
  ["готово", "3"],
  ["отклонено", "4"],
];

export const Requests = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const username = localStorage.getItem(LS.USERNAME);
  const role = localStorage.getItem("role");
  const zeroSavedAction = useSelector(selectLastAction);
  const methods = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const requests = useSelector(selectRequests);
  const tags = useSelector(selectTags);
  const [isAdd, setIsAdd] = useState(false);
  const [assets, setAssets] = useState<AssetClient[]>([]);
  const [currentType, setCurrentType] = useState<TRequestType>("пожар");
  const [currentPriority, setCurrentPriority] = useState<TPriorityType>("medium");
  const [selectedTags, setSelectedTags] = useState<Array<TTag>>([]);
  const [savedSites, setSavedSites] = useState<Array<string>>([]);
  const [savedVectors, setSavedVectors] = useState<Array<string>>([]);
  const [currentStatus, setCurrentStatus] = useState("");
  const [isDelay, setDelay] = useState(false);
  const [lastFailedReq, setLastFailedReq] = useState<TRequest | null>(null);

  const from = searchParams.get("from");
  const to = searchParams.get("to");

  const delayTime = 1500;

  const addTags = (e) => {
    if (!selectedTags?.find((el) => el.id === e.value)) {
      setSelectedTags([...selectedTags, { id: e.value, tag: e.label }]);
    }
  };

  const types: TTypeEditorType[] = useMemo(
    () => [
      {
        title: "запрос",
        icon: question,
        color: "#40AEF0",
        size: 12,
      },
      {
        title: "пожар",
        icon: fire,
        color: "#FF1020DD",
        size: 18,
      },
      {
        title: "задача",
        icon: lightning,
        color: "#9999FF",
        size: 18,
      },
    ],
    []
  );

  const priorities: TTypeEditorType[] = useMemo(
    () => [
      {
        title: "high",
        icon: high,
        color: "#FAFAFF00",
        size: 18,
      },
      {
        title: "medium",
        icon: medium,
        color: "#FAFAFF00",
        size: 18,
      },
      {
        title: "low",
        icon: low,
        color: "#FAFAFF00",
        size: 18,
      },
    ],
    []
  );

  const [filters, setFilters] = useState<TRequestFilter>({
    filter: {
      type: "",
      subStr: "",
      isAdminCamp: false,
      isUnread: false,
    },
    sort: {
      priority: false,
      fresh: false,
    },
  });

  const fetchRequests = async () => {
    const { response, error } = await fetchRequest(requestApi.getRequests({ from, to }), {
      request: "Запросы в IT",
    });
    if (response) {
      dispatch(setRequests(response));
    }
    if (error) {
      dispatch(setRequests([]));
    }
  };

  const fetchTags = async () => {
    const { response, error } = await fetchRequest(requestApi.getTags(), {
      request: "Теги",
    });
    if (response) {
      dispatch(setTags(response));
    }
    if (error) {
      dispatch(setTags([]));
    }
  };

  const createRequest = async (e) => {
    setDelay(true);
    const has_assets = assets.length > 0;
    const payload = {
      title: e.title,
      description: e.description,
      assets: [],
      type: currentType,
      priority: currentPriority,
      is_tags: !!selectedTags?.length,
      tags: selectedTags,
      has_assets,
      sites: savedSites,
      is_all_vectors: !savedVectors?.length,
      vectors: savedVectors?.map((el) => ({ vector: el })),
    };
    const { response } = await fetchRequest(requestApi.createRequest(payload), {
      request: "Создание запроса",
    });
    if (response) {
      pushNewReqDataToCentrifuge({ type: "create", item: response }, () =>
        dispatch(createRequestRedux(response))
      );
      if (has_assets) {
        try {
          const assetsFromServer = await uploadAssets(response.id, assets);
          const newReq = { ...response, assets: assetsFromServer };
          pushNewReqDataToCentrifuge({ type: "update", item: newReq }, () =>
            dispatch(createRequestRedux(newReq))
          );
          resetData();
        } catch {
          setLastFailedReq(response);
        }
      } else {
        resetData();
      }
    }
    setTimeout(() => setDelay(false), delayTime);
  };

  const resetData = () => {
    setIsAdd(false);
    methods.setValue("title", "");
    methods.setValue("description", "");
    setAssets([]);
    setLastFailedReq(null);
  };

  const repeatUpload = async (e: FormEvent) => {
    e.preventDefault();

    setDelay(true);
    try {
      const newAssets = await uploadAssets(lastFailedReq.id, assets);
      const newReq = { ...lastFailedReq, assets: newAssets };
      pushNewReqDataToCentrifuge({ type: "create", item: newReq }, () =>
        dispatch(createRequestRedux(newReq))
      );
      resetData();
    } catch (e) {
      console.log(e);
    }
    setTimeout(() => setDelay(false), delayTime);
  };

  const uploadAssets = async (requestId: number, assets: AssetClient[]) => {
    const videos = assets.filter((asset) => asset.content_type === ContentType.VIDEO);
    for (const vid of videos) {
      const duration = await getVideoDuration(vid.file);
      if (duration > 600) {
        dispatch(
          setNotific({
            type: "error",
            message: "Видео не должны быть длиннее 10 минут",
          })
        );
        return;
      }
    }
    const formData = new FormData();
    assets.forEach((asset) => {
      formData.append("files", asset.file);
    });
    const { response, error } = await fetchRequest(requestApi.uploadAssets(requestId, formData));
    if (!response || error) {
      dispatch(
        setNotific({
          type: "error",
          message: "Ошибка при загрузке файлов на сервер, попробуйте снова",
        })
      );
      throw new Error("Ошибка при загрузке файлов на сервер, попробуйте снова");
    }
    setLastFailedReq(null);
    return response;
  };

  const onDragEnd = async (result) => {
    const item = requests?.find((el) => el.id === +result.draggableId);
    if (result.destination.droppableId !== item?.status) {
      const { response } = await fetchRequest(
        requestApi.updateRequest({
          ...item,
          status: result.destination.droppableId,
        }),
        { request: "Не удалось изменить статус" }
      );
      if (response) {
        dispatch(updateRequest(response));
        pushNewReqDataToCentrifuge({ type: "update", item: response }, () =>
          dispatch(createRequestRedux(response))
        );
      }
    }
  };

  useEffect(() => {
    if (userAccess?.includes("it_requests_post")) {
      fetchTags();
    }

    if (!!searchParams?.get("status")?.length) {
      setCurrentStatus(statuses[+searchParams?.get("status")][0]);
    }

    createNewReqDataSub(false, false);

    if (!from && !to) {
      searchParams.set(
        "from",
        String(Math.floor(dateRangePresets.this_week.from.getTime() / 1000))
      );
      searchParams.set("to", String(Math.floor(dateRangePresets.this_week.from.getTime() / 1000)));
    } else if (!from || !to) {
      const val = from || to;
      searchParams.set("from", String(Number(val)));
      searchParams.set("to", String(Number(val)));
    }
    setSearchParams(searchParams);

    return () => createNewReqDataSub(false, true);
  }, []);

  useEffect(() => {
    if (from && to) {
      fetchRequests();
    }
  }, [from, to]);

  return (
    <FormProvider {...methods}>
      <div className="mx-auto pl-[20px] pr-[50px] sm:pr-[80px] relative">
        <Statistics />
        {userAccess?.includes("it_requests_post") && (
          <form onSubmit={lastFailedReq ? repeatUpload : methods.handleSubmit(createRequest)}>
            <div className="w-full md:w-[600px] mx-auto duration-300 flex flex-col gap-[10px] font-light pb-8">
              <CreateRequest
                assets={assets}
                setAssets={setAssets}
                isAdd={isAdd}
                setIsAdd={setIsAdd}
                currentType={currentType}
                setCurrentType={setCurrentType}
                currentPriority={currentPriority}
                setCurrentPriority={setCurrentPriority}
                isDelay={isDelay}
                types={types}
                priorities={priorities}
                tags={tags}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                setTags={addTags}
                savedSites={savedSites}
                setSavedSites={setSavedSites}
                savedVectors={savedVectors}
                setSavedVectors={setSavedVectors}
                lastFailedReq={lastFailedReq}
              />
            </div>
          </form>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <RequestsFilters
            filters={filters}
            setFilters={setFilters}
            count={requests?.length}
            fetchRequests={fetchRequests}
          />
          <div className="duration-300 flex flex-col gap-[10px] font-light pb-[100px]">
            <DragDropContext
              onDragEnd={async (result) => {
                await onDragEnd(result);
              }}>
              <div
                className={`duration-300 ${
                  currentStatus === ""
                    ? "grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8"
                    : "flex justify-center"
                }`}>
                {statuses?.map((el) => (
                  <Droppable droppableId={el[0]} type={"section"} key={el[0]}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Column
                          status={el}
                          requests={requests}
                          username={username || ""}
                          userAccess={userAccess || []}
                          filters={filters}
                          isDelay={isDelay}
                          setDelay={setDelay}
                          delayTime={delayTime}
                          types={types}
                          priorities={priorities}
                          tags={tags}
                          currentStatus={currentStatus}
                          setCurrentStatus={setCurrentStatus}
                          searchParams={searchParams}
                          setSearchParams={setSearchParams}
                          role={role}
                          zeroSavedAction={zeroSavedAction}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>
            </DragDropContext>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};
