import { cn } from "src/helpers/cn";
import { convertToShortTime } from "src/helpers/date";

type TProps = {
  axisX: Array<number>;
  nowTime: number;
  zoom: number;
  step: number;
  containerClassName?: string;
  blockClassName?: string;
};

export const AxisX = ({
  axisX,
  nowTime,
  zoom,
  step,
  containerClassName,
  blockClassName,
}: TProps) => {
  const past = nowTime % 3;
  const nowEl = Math.ceil(nowTime / step);

  const width = axisX.length * zoom + 22;

  return (
    <div
      style={{ width }}
      className={cn(
        `flex justify-start bg-bg h-[29px] max-h-[29px] rounded-md shadow-[0px_0px_30px_-10px_rgba(0,0,0,0.3)]`,
        containerClassName
      )}>
      {axisX &&
        axisX?.map((el, index) => {
          return (
            <div key={index} id={`${index}_axis`}>
              <div
                style={{
                  background:
                    index < nowEl - 1
                      ? "#E4E4EB"
                      : index === nowEl - 1
                      ? `linear-gradient(to right, #E4E4EB ${(100 / 3) * past}%, #FAFAFF ${
                          (100 / 3) * (3 - past)
                        }%)`
                      : "#FAFAFF",
                  width: `${zoom}px`,
                }}
                className={cn(
                  "border-l border-glass-gray text-[8px] text-lightFont h-full hover:border-lightFont hover:text-font cursor-default pb-[5px] pt-[10px]",
                  blockClassName
                )}>
                {el === 0 ? "00:00" : convertToShortTime(el * step * 60)}
              </div>
              <div className="border-b border-glass-gray" />
            </div>
          );
        })}
    </div>
  );
};
