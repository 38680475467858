import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LS } from "src/api/constants";
import { createExpandedState } from "src/helpers/onOffTimeline/createExpandedState";
import { CurrencyPairsGroup, ICity, OnOffItemToDeleteType } from "src/types/OnOffTimeline/common";
import {
  ExpandActionType,
  ExpandedStateLevel,
  InitExpandedStateProps,
  OnOffConfirmation,
  OnOffDeleteItemProps,
  OnOffExpandedState,
  OnOffLastAppliedFilters,
  OnOffSite,
  OnOffTimelineInitialState,
  SetSelectedItemProps,
  ToggleExpandedPayload,
  UpdateSelectedSegmentsPayload,
} from "src/types/OnOffTimeline/store";
import { TCurrencyTypes } from "src/pages/Text/types";
import { compareExpandedStates } from "src/helpers/onOffTimeline/compareExpandedStates";

const initialState: OnOffTimelineInitialState = {
  step: 60,
  zoom: (window.innerWidth - 640) / 24,
  isAddMode: false,
  isSitesMenuOpen: localStorage.getItem(LS.SITES_MENU_OPEN) === "true",
  currencyFilters: {
    from: ["cash", "crypto", "card"],
    to: ["cash", "crypto", "card"],
  },
  expandedState: {},
  allCitiesExpanded: false,
  everythingExpanded: false,
  timelineData: [],
  selectedItem: null,
  copiedItem: null,
  isCopy: false,
  deleteMode: false,
  cityTimeLineWidth: 0,
  timelineContainerScrollLeft: 0,
  axisFixed: false,
  confirmation: null,
  isLoading: false,
  sitesLoading: true,
  errorMessage: null,
  allSites: [],
  lastAppliedFilters: {
    sites: [],
    cities: [],
    from: [],
    to: [],
  },
  pairsNotFresh: false,
  openAllSites: false,
};

const onOffTimelineSlice = createSlice({
  name: "onOffTimelineSlice",
  initialState,
  reducers: {
    setOnOffTimelineData: (state, { payload }: PayloadAction<ICity[]>) => {
      state.timelineData = payload || [];
    },
    setOnOffIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload || false;
    },
    setOnOffSitesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.sitesLoading = payload || false;
    },
    setOnOffSites: (state, { payload }: PayloadAction<OnOffSite[]>) => {
      state.allSites = payload;
    },
    turnOffSite: (state, { payload }: PayloadAction<OnOffSite["site_name"]>) => {
      state.allSites = state.allSites.map((site) =>
        site.site_name === payload ? { ...site, has_segments: false } : site
      );

      state.timelineData = state.timelineData.map((city) => ({
        ...city,
        currency_pairs: city.currency_pairs.map((pair) => ({
          ...pair,
          sites: pair.sites.filter((site) => site.site_name !== payload),
        })),
      }));
    },
    setOnOffLastAppliedFilters: (state, { payload }: PayloadAction<OnOffLastAppliedFilters>) => {
      state.lastAppliedFilters = payload;
    },
    setOnOffConfirmation: (state, { payload }: PayloadAction<OnOffConfirmation | null>) => {
      state.confirmation = payload || null;
    },
    setCityTimelineWidth: (state, { payload }: PayloadAction<number>) => {
      state.cityTimeLineWidth = payload || 0;
    },
    onOffTimeLineUndo: (state, { payload }: PayloadAction<string>) => {
      const username = localStorage.getItem(LS.USERNAME);

      const payloadSplit = payload.split("_");

      const [cityCode, group, from, to, site] = payloadSplit;

      let newData: ICity[];

      switch (payloadSplit.length) {
        case 5:
          newData = state.timelineData.map((city) => {
            if (city.city_code === cityCode) {
              return {
                ...city,
                currency_pairs: city.currency_pairs.map((pair) => {
                  if (
                    pair.currency_from.currency_name === from &&
                    pair.currency_to.currency_name === to
                  ) {
                    return {
                      ...pair,
                      sites: pair.sites.map((item) => {
                        if (item.site_name === site) {
                          return {
                            ...item,
                            unsubmitted_segments_best: item.unsubmitted_segments_best?.filter(
                              (segment) => segment.creator !== username
                            ),
                            unsubmitted_segments_site: item.unsubmitted_segments_site?.filter(
                              (segment) => segment.creator !== username
                            ),
                          };
                        }
                        return item;
                      }),
                    };
                  }

                  return pair;
                }),
              };
            }

            return city;
          });
          break;
        case 4:
          newData = state.timelineData.map((city) => {
            if (city.city_code === cityCode) {
              return {
                ...city,
                currency_pairs: city.currency_pairs.map((pair) => {
                  if (
                    pair.currency_from.currency_name === from &&
                    pair.currency_to.currency_name === to
                  ) {
                    return {
                      ...pair,
                      sites: pair.sites.map((item) => ({
                        ...item,
                        unsubmitted_segments_best: item.unsubmitted_segments_best?.filter(
                          (segment) => segment.creator !== username
                        ),
                        unsubmitted_segments_site: item.unsubmitted_segments_site?.filter(
                          (segment) => segment.creator !== username
                        ),
                      })),
                    };
                  }

                  return pair;
                }),
              };
            }

            return city;
          });
          break;
        case 3:
          newData = state.timelineData.map((city) => {
            if (city.city_code === cityCode) {
              return {
                ...city,
                currency_pairs: city.currency_pairs.map((pair) => {
                  if (pair.currency_from.currency_name === from) {
                    return {
                      ...pair,
                      sites: pair.sites.map((item) => ({
                        ...item,
                        unsubmitted_segments_best: item.unsubmitted_segments_best?.filter(
                          (segment) => segment.creator !== username
                        ),
                        unsubmitted_segments_site: item.unsubmitted_segments_site?.filter(
                          (segment) => segment.creator !== username
                        ),
                      })),
                    };
                  }

                  return pair;
                }),
              };
            }

            return city;
          });
          break;
        case 2:
          newData = state.timelineData.map((city) => {
            if (city.city_code === cityCode) {
              return {
                ...city,
                currency_pairs: city.currency_pairs.map((pair) => {
                  switch (group) {
                    case CurrencyPairsGroup.CASH_CRYPTO:
                      if (
                        pair.currency_from.type === "cash" &&
                        pair.currency_to.type === "crypto"
                      ) {
                        return {
                          ...pair,
                          sites: pair.sites.map((item) => ({
                            ...item,
                            unsubmitted_segments_best: item.unsubmitted_segments_best?.filter(
                              (segment) => segment.creator !== username
                            ),
                            unsubmitted_segments_site: item.unsubmitted_segments_site?.filter(
                              (segment) => segment.creator !== username
                            ),
                          })),
                        };
                      }
                      return pair;
                    case CurrencyPairsGroup.CRYPTO_CASH:
                      if (
                        pair.currency_from.type === "crypto" &&
                        pair.currency_to.type === "cash"
                      ) {
                        return {
                          ...pair,
                          sites: pair.sites.map((item) => ({
                            ...item,
                            unsubmitted_segments_best: item.unsubmitted_segments_best?.filter(
                              (segment) => segment.creator !== username
                            ),
                            unsubmitted_segments_site: item.unsubmitted_segments_site?.filter(
                              (segment) => segment.creator !== username
                            ),
                          })),
                        };
                      }
                      return pair;
                    case CurrencyPairsGroup.AUTO:
                      if (
                        !(
                          (pair.currency_from.type === "crypto" &&
                            pair.currency_to.type === "cash") ||
                          (pair.currency_from.type === "cash" && pair.currency_to.type === "crypto")
                        )
                      ) {
                        return {
                          ...pair,
                          sites: pair.sites.map((item) => ({
                            ...item,
                            unsubmitted_segments_best: item.unsubmitted_segments_best?.filter(
                              (segment) => segment.creator !== username
                            ),
                            unsubmitted_segments_site: item.unsubmitted_segments_site?.filter(
                              (segment) => segment.creator !== username
                            ),
                          })),
                        };
                      }
                      return pair;
                  }
                }),
              };
            }
            return city;
          });
          break;
        case 1:
          newData = state.timelineData.map((city) => {
            if (city.city_code === cityCode) {
              return {
                ...city,
                currency_pairs: city.currency_pairs.map((pair) => ({
                  ...pair,
                  sites: pair.sites.map((item) => ({
                    ...item,
                    unsubmitted_segments_best: item.unsubmitted_segments_best?.filter(
                      (segment) => segment.creator !== username
                    ),
                    unsubmitted_segments_site: item.unsubmitted_segments_site?.filter(
                      (segment) => segment.creator !== username
                    ),
                  })),
                })),
              };
            }
            return city;
          });
          break;
      }

      state.timelineData = newData;
    },
    updateSiteSegments: (state, { payload }: PayloadAction<UpdateSelectedSegmentsPayload>) => {
      const {
        city,
        currencyFrom,
        currencyTo,
        site_name,
        unsubmitted_segments_best,
        unsubmitted_segments_site,
        otherSites,
      } = payload;

      state.timelineData = state.timelineData.map((item) => {
        if (item.city_code === city?.city_code) {
          return {
            ...item,
            currency_pairs: item.currency_pairs.map((pair) => {
              if (
                pair.currency_from.currency_name === currencyFrom.currency_name &&
                pair.currency_to.currency_name === currencyTo.currency_name
              ) {
                return {
                  ...pair,
                  sites: pair.sites.map((site) => {
                    if (site.site_name === site_name) {
                      return {
                        ...site,
                        unsubmitted_segments_site,
                        unsubmitted_segments_best,
                      };
                    }

                    const existingOtherSite = otherSites?.find(
                      (item) => item.site_name === site.site_name
                    );
                    if (existingOtherSite) {
                      return existingOtherSite;
                    }

                    return site;
                  }),
                };
              }

              return pair;
            }),
          };
        }

        return item;
      });
    },
    setSelectedItem(state, { payload }: PayloadAction<SetSelectedItemProps | null>) {
      if (!payload) {
        state.selectedItem = null;
        return;
      }

      const { cityCode, currencyFrom, currencyTo, siteName } = payload;
      const city = state.timelineData.find((item) => item.city_code === cityCode);
      const pair = city?.currency_pairs.find(
        (item) =>
          item.currency_from.currency_name === currencyFrom.currency_name &&
          item.currency_to.currency_name === currencyTo.currency_name &&
          item.currency_from.type === currencyFrom.type &&
          item.currency_to.type === item.currency_to.type
      );
      const site = pair?.sites.find((item) => item.site_name === siteName);
      const otherSites = pair?.sites.filter((item) => item.site_name !== siteName);
      if (site) {
        state.selectedItem = {
          ...site,
          city: {
            city_code: city.city_code,
            city_name: city.city_name,
          },
          currencyFrom,
          currencyTo,
          otherSites,
        };
      }
    },
    setCopiedItem: (state, { payload }: PayloadAction<SetSelectedItemProps | null>) => {
      if (!payload) {
        state.copiedItem = null;
        return;
      }

      const { cityCode, currencyFrom, currencyTo, siteName } = payload;
      const city = state.timelineData.find((item) => item.city_code === cityCode);
      const pair = city?.currency_pairs.find(
        (item) =>
          item.currency_from.currency_name === currencyFrom.currency_name &&
          item.currency_to.currency_name === currencyTo.currency_name &&
          item.currency_from.type === currencyFrom.type &&
          item.currency_to.type === item.currency_to.type
      );
      const site = pair?.sites.find((item) => item.site_name === siteName);
      if (site) {
        state.copiedItem = {
          ...site,
          city: {
            city_code: city.city_code,
            city_name: city.city_name,
          },
          currencyFrom,
          currencyTo,
        };
      }
    },
    resetOnOffUserChanges: (state, { payload }: PayloadAction<string>) => {
      state.timelineData = state.timelineData.map((city) => ({
        ...city,
        currency_pairs: city.currency_pairs.map((pair) => ({
          ...pair,
          sites: pair.sites.map((site) => ({
            ...site,
            unsubmitted_segments_best: site.unsubmitted_segments_best?.filter(
              (segment) => segment.creator !== payload
            ),
            unsubmitted_segments_site: site.unsubmitted_segments_site?.filter(
              (segment) => segment.creator !== payload
            ),
          })),
        })),
      }));
    },
    setIsCopy: (state, { payload }: PayloadAction<boolean>) => {
      state.isCopy = payload;
    },
    setZoom: (state, { payload }: PayloadAction<number>) => {
      state.zoom = payload;
    },
    setStep: (state, { payload }: PayloadAction<number>) => {
      state.step = payload;
    },
    setOnOffCurrencyFilters: (
      state,
      { payload }: PayloadAction<{ type: TCurrencyTypes; side: "from" | "to" }>
    ) => {
      const prev = state.currencyFilters;
      const { side, type } = payload;
      state.currencyFilters = {
        ...prev,
        [side]: prev[side].includes(type)
          ? prev[side].filter((item) => item !== type)
          : [...prev[side], type],
      };
    },
    toggleOnOffIsAddMode: (state, { payload }: PayloadAction<boolean | undefined>) => {
      if (payload) {
        state.isAddMode = payload;
      } else {
        state.isAddMode = !state.isAddMode;
      }
    },
    toggleOnOffIsSitesMenuOpen: (state, { payload }: PayloadAction<boolean | undefined>) => {
      if (payload) {
        localStorage.setItem(LS.SITES_MENU_OPEN, String(payload));
        state.isSitesMenuOpen = payload;
      } else {
        localStorage.setItem(LS.SITES_MENU_OPEN, String(!state.isSitesMenuOpen));
        state.isSitesMenuOpen = !state.isSitesMenuOpen;
      }
    },
    initExpandedState: (
      state,
      { payload: { data, entitiesToExpand = [] } }: PayloadAction<InitExpandedStateProps>
    ) => {
      let expandedState: OnOffExpandedState = {};

      createExpandedState({
        level: ExpandedStateLevel.ROOT,
        current: expandedState,
        data,
        entitiesToExpand,
      });

      // если какое-то состояние уже было, необходимо сначала сравнить его с новым
      if (Object.keys(state.expandedState).length > 0) {
        expandedState = compareExpandedStates(state.expandedState, expandedState);
      }

      state.expandedState = expandedState;
    },
    toggleExpandedEntity: (state, { payload }: PayloadAction<string>) => {
      const levels = payload.split("_");

      const [cityCode, group, currencyFrom, currencyTo] = levels;

      switch (levels.length) {
        case 1:
          state.expandedState[cityCode].isExpanded = !state.expandedState[cityCode].isExpanded;
          break;
        case 2:
          state.expandedState[cityCode].children[group].isExpanded =
            !state.expandedState[cityCode].children[group].isExpanded;
          break;
        case 3:
          state.expandedState[cityCode].children[group].children[currencyFrom].isExpanded =
            !state.expandedState[cityCode].children[group].children[currencyFrom].isExpanded;
          break;
        case 4:
          state.expandedState[cityCode].children[group].children[currencyFrom].children[
            currencyTo
          ].isExpanded =
            !state.expandedState[cityCode].children[group].children[currencyFrom].children[
              currencyTo
            ].isExpanded;
          break;
        default:
          console.error("toggleExpandedEntity reducer: invalid payload");
          return;
      }
    },
    toggleExpandEverything: (state, { payload }: PayloadAction<ExpandActionType>) => {
      const isExpanded = payload === ExpandActionType.EXPAND;
      Object.values(state.expandedState).forEach((city) => {
        city.isExpanded = isExpanded;
        Object.values(city.children).forEach((group) => {
          group.isExpanded = isExpanded;
          Object.values(group.children).forEach((from) => {
            from.isExpanded = isExpanded;
            Object.values(from.children).forEach((to) => {
              to.isExpanded = isExpanded;
            });
          });
        });
      });
    },
    toggleExpandAllGroupCurrencies: (
      state,
      { payload: { type, selector } }: PayloadAction<ToggleExpandedPayload>
    ) => {
      const [cityCode, group] = selector.split("_");
      const isExpanded = type === ExpandActionType.EXPAND;
      state.expandedState[cityCode].children[group].isExpanded = isExpanded;
      Object.values(state.expandedState[cityCode].children[group].children).forEach((from) => {
        from.isExpanded = isExpanded;
        Object.values(from.children).forEach((to) => {
          to.isExpanded = isExpanded;
        });
      });
    },
    toggleExpandAllCityContents: (
      state,
      { payload: { type, selector } }: PayloadAction<ToggleExpandedPayload>
    ) => {
      const isExpanded = type === ExpandActionType.EXPAND;
      state.expandedState[selector].isExpanded = isExpanded;
      Object.values(state.expandedState[selector].children).forEach((group) => {
        group.isExpanded = isExpanded;
        Object.values(group.children).forEach((from) => {
          from.isExpanded = isExpanded;
          Object.values(from.children).forEach((to) => {
            to.isExpanded = isExpanded;
          });
        });
      });
    },
    toggleExpandAllFrom: (
      state,
      { payload: { type, selector } }: PayloadAction<ToggleExpandedPayload>
    ) => {
      const isExpanded = type === ExpandActionType.EXPAND;
      const [cityCode, group, from] = selector.split("_");
      state.expandedState[cityCode].children[group].children[from].isExpanded = isExpanded;
      Object.values(state.expandedState[cityCode].children[group].children[from].children).forEach(
        (pair) => {
          pair.isExpanded = isExpanded;
        }
      );
    },
    setDeleteMode: (state, { payload }: PayloadAction<boolean>) => {
      state.deleteMode = payload;
    },
    onOffDeleteItem: (state, { payload: { type, name } }: PayloadAction<OnOffDeleteItemProps>) => {
      let cityCode: string;
      let group: CurrencyPairsGroup;
      let fromType: TCurrencyTypes;
      let toType: TCurrencyTypes;
      let from: string;
      let to: string;
      let siteName: string;

      switch (type) {
        case OnOffItemToDeleteType.CITY:
          state.timelineData = state.timelineData.filter((city) => city.city_code !== name);
          break;
        case OnOffItemToDeleteType.GROUP:
          cityCode = name.split("_")[0];
          group = name.split("_")[1] as CurrencyPairsGroup;

          switch (group) {
            case CurrencyPairsGroup.CASH_CRYPTO:
              fromType = "cash";
              toType = "crypto";
              break;
            case CurrencyPairsGroup.CRYPTO_CASH:
              fromType = "crypto";
              toType = "cash";
              break;
          }

          state.timelineData = state.timelineData.map((city) =>
            city.city_code === cityCode
              ? {
                  ...city,
                  currency_pairs: city.currency_pairs.filter(
                    (pair) =>
                      !(pair.currency_from.type === fromType && pair.currency_to.type === toType)
                  ),
                }
              : city
          );
          break;
        case OnOffItemToDeleteType.CURRENCY_GROUP:
          cityCode = name.split("_")[0];
          from = name.split("_")[2];

          state.timelineData = state.timelineData.map((city) =>
            city.city_code === cityCode
              ? {
                  ...city,
                  currency_pairs: city.currency_pairs.filter(
                    (pair) => !(pair.currency_from.currency_name === from)
                  ),
                }
              : city
          );
          break;
        case OnOffItemToDeleteType.PAIR:
          cityCode = name.split("_")[0];
          from = name.split("_")[2];
          to = name.split("_")[3];

          state.timelineData = state.timelineData.map((city) =>
            city.city_code === cityCode
              ? {
                  ...city,
                  currency_pairs: city.currency_pairs.filter(
                    (pair) =>
                      !(
                        pair.currency_from.currency_name === from &&
                        pair.currency_to.currency_name === to
                      )
                  ),
                }
              : city
          );
          break;
        case OnOffItemToDeleteType.SITE:
          cityCode = name.split("_")[0];
          from = name.split("_")[2];
          to = name.split("_")[3];
          siteName = name.split("_")[4];

          state.timelineData = state.timelineData.map((city) =>
            city.city_code === cityCode
              ? {
                  ...city,
                  currency_pairs: city.currency_pairs.map((pair) => {
                    if (
                      pair.currency_from.currency_name === from &&
                      pair.currency_to.currency_name === to
                    ) {
                      return {
                        ...pair,
                        sites: pair.sites.filter((site) => site.site_name !== siteName),
                      };
                    }

                    return pair;
                  }),
                }
              : city
          );
          break;
      }
    },
    setTimelineContainerScrollLeft: (state, { payload }: PayloadAction<number>) => {
      state.timelineContainerScrollLeft = payload;
    },
    setOnOffAxisFixed: (state, { payload }: PayloadAction<boolean>) => {
      state.axisFixed = payload;
    },
    setOnOffErrorMessage: (state, { payload }: PayloadAction<string | null>) => {
      state.errorMessage = payload;
    },
    setPairsNotFresh: (state, { payload }: PayloadAction<boolean>) => {
      state.pairsNotFresh = payload;
    },
    setOpenAllSites: (state, { payload }: PayloadAction<boolean>) => {
      state.openAllSites = payload;
    },
  },
});

export default onOffTimelineSlice.reducer;

export const {
  setOnOffTimelineData,
  setZoom,
  setStep,
  setOnOffCurrencyFilters,
  toggleOnOffIsAddMode,
  toggleOnOffIsSitesMenuOpen,
  toggleExpandedEntity,
  initExpandedState,
  toggleExpandEverything,
  toggleExpandAllFrom,
  toggleExpandAllGroupCurrencies,
  toggleExpandAllCityContents,
  setSelectedItem,
  updateSiteSegments,
  setIsCopy,
  setCopiedItem,
  resetOnOffUserChanges,
  setDeleteMode,
  onOffDeleteItem,
  setCityTimelineWidth,
  setTimelineContainerScrollLeft,
  setOnOffAxisFixed,
  onOffTimeLineUndo,
  setOnOffConfirmation,
  setOnOffIsLoading,
  setOnOffSitesLoading,
  setOnOffErrorMessage,
  setOnOffSites,
  setOnOffLastAppliedFilters,
  turnOffSite,
  setPairsNotFresh,
  setOpenAllSites,
} = onOffTimelineSlice.actions;
